import React from 'react'
import { Row } from 'react-bootstrap'
import ContentDetails from '../../../../components/partials/common/content-details';
import AddFile from '../../action/components/add-file';

const UploadContent = () => {
  return (
    <>
      <ContentDetails />
      <AddFile />
    </>
  );
}

export default UploadContent