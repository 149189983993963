import React, {
  Fragment,
  useRef,
  useState,
  useCallback,
  useEffect,
} from "react";
import { Button, Card, Col, Form, Row, Modal, Badge } from "react-bootstrap";
import Select from "react-select";
import { findIcon } from "../../../../dashboard/icons";
import ReactTable from "../../../../../lib/table/react-table";
import postService from "../../../../../api/services/post-service";
import {
  ADD_TARGET_SOURCE,
  ALL_TARGET_SOURCES,
  DELETE_TARGET_SOURCE,
  UPDATE_TARGET_SOURCE,
  TARGET_SOURCE_BY_ID,
} from "../../../../../api/endpoints/target-source-endpoints";
import ViewModal from "../../../../../components/partials/common/view-modal";
import DeleteModal from "../../../../../components/partials/common/delete-modal";
import { getService } from "../../../../../api/services/get-services";
import {
  changeTableStateToPayload,
  statusToBg,
} from "../../../../../common/utils/utils";
import * as yup from "yup";
import { useFormik } from "formik";
import ActionButtonBar from "../../../../../components/partials/common/action-button-bar";
import FilterBar from "../../../../../components/filter-bar";
import toast from "react-hot-toast";
import PageNotFound from "../../../../PageNotFound";
import CommonTableLoader from "../../../../../skeleton/common-table-loader";

const AllTargetSources = () => {
  const [createModalShow, setCreateModalShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [targetSourceList, setTargetSourceList] = useState([]);
  const [viewModalShow, setViewModalShow] = useState(false);
  const [viewTargetSource, setViewTargetSource] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [selectedTargetSource, setSelectedTargetSource] = useState(null);
  const post_json = useRef({
    default: "all",
    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      target_source_id: "desc",
    },
  });
  //yup and formik for validation of form fields of target_source_name

  const validationSchema = yup.object().shape({
    target_source_name: yup.string().required("Target Source Name is required"),
    target_source_description: yup
      .string()
      .required("Target Source Description is required"),
  });
  //formik
  const formik = useFormik({
    initialValues: {
      target_source_name: "",
      target_source_description: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const postValues = {
        target_source_name: values.target_source_name.trim(),
        target_source_description: values.target_source_description.trim(),
      };

      try {
        // setLoading(true);
        const response = await postService(
          isEdit
            ? UPDATE_TARGET_SOURCE + "/" + selectedTargetSource
            : ADD_TARGET_SOURCE,
          {
            ...postValues,
            status: isEdit ? values.status : 1,
          }
        );
        if (response) {
          setLoading(false);
          setCreateModalShow(false);
          fetchTargetSources(post_json.current);
          isEdit && setIsEdit(false);
          toast.success(response?.data?.message);
          // isEdit
          //   ? toast.success("Target Source Updated")
          //   : toast.success("New Target Source Added");
        }
      } catch (error) {
        setLoading(false);
        console.log("TARGET_SOURCE_SERVICE_ERROR", error);
      }
    },
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    resetForm,
  } = formik;

  //fetch target_sources
  const fetchTargetSources = async (json) => {
    try {
      // setLoading(true);
      const response = await postService(ALL_TARGET_SOURCES, json);
      console.log("TARGET_SOURCE_SERVICE_RESPONSE", response.data.data.rows);
      console.log("COLUMNS", Object.keys(response.data.data.rows[0]));
      if (response.data.data.rows.length > 0) {
        setTargetSourceList(response.data.data.rows);
        setTotalCount(response.data.data.totalCount);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("TARGET_SOURCE_SERVICE_ERROR", error);
    }
  };
  const viewModal = async (target_source) => {
    const {
      target_source_id,
      target_source_name,
      target_source_description,
      status,
    } = target_source;
    let dataJson = {
      "Target Source ID": target_source_id,
      "Target Source Name": target_source_name,
      "Target Status": status?.value,
      "Target Source Description": target_source_description,
    };
    setViewTargetSource(dataJson);
    setViewModalShow(true);
  };
  const viewModalClose = () => {
    setViewModalShow(false);
  };
  const deleteTargetSource = async () => {
    try {
      // setLoading(true);
      const response = await getService(
        `${DELETE_TARGET_SOURCE}/${selectedTargetSource}`
      );
      if (response) {
        setLoading(false);
        setDeleteModalShow(false);
        fetchTargetSources(post_json.current);
        toast.success("Target Source Deleted");
      }
    } catch (error) {
      setLoading(false);
      console.log("TARGET_SOURCE_SERVICE_ERROR", error);
    }
  };
  useEffect(() => {
    fetchTargetSources(post_json.current);
  }, []);
  const onTableStateChange = useCallback(
    async (state) => {
      let payload = changeTableStateToPayload(state);
      post_json.current = payload;
      fetchTargetSources(payload);
    },
    [targetSourceList, loading, post_json.current]
  );
  const onDeleteTargetSource = (target_source) => {
    // alert("deleting" + target_source.target_source_id);
    setSelectedTargetSource(target_source.target_source_id);
    setDeleteModalShow(true);
  };
  const columns = [
    {
      Header: "Target Source ID",
      accessor: "target_source_id",
    },
    {
      Header: "Target Source Name",
      accessor: "target_source_name",
    },
    {
      Header: "Target Source Description",
      accessor: "target_source_description",
    },

    {
      Header: "Created by",
      accessor: "created_by",
      Cell: ({ value }) => value?.name,
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => (
        <Badge bg={statusToBg(value.id)} className="me-2">
          <span className="btn-inner">{value.value}</span>
        </Badge>
      ),
    },
    {
      Header: "Action",
      accessor: "action",
      disableFilters: true,
      Cell: ({ row }) => (
        <ActionButtonBar
          row={row}
          onView={(data) => viewModal(data)}
          onEdit={(data) => onEditTargetSourceClick(data)}
          onDelete={(data) => onDeleteTargetSource(data)}
        />
      ),
    },
  ];

  const handleCreate = () => {
    resetForm();
    setCreateModalShow(true);
  };
  const onEditTargetSourceClick = (target_source) => {
    console.log("target_source===>", target_source);
    setIsEdit(true);
    setSelectedTargetSource(target_source.target_source_id);

    const editValues = {
      target_source_name: target_source?.target_source_name,
      target_source_description: target_source?.target_source_description,
      status: target_source?.status?.id,
    };
    //set formik values
    setValues(editValues);
    setCreateModalShow(true);
  };

  const advanceData = [
    {
      value: "Target Source Name",
      label: "Target Source Name",
      type: "string",
    },
    {
      value: "Target Source Desc",
      label: "Target Source Desc",
      type: "string",
      // elements: [
      //   { value: "Content Studio 1", label: "Content Studio 1" },
      //   { value: "Content Studio 2", label: "Content Studio 2" },
      //   { value: "Content Studio 3", label: "Content Studio 3" },
      // ],
    },
    {
      value: "Status",
      label: "Status",
      type: "string",
      elements: [
        { value: "Disabled", label: "Disabled" },
        { value: "Enabled", label: "Enabled" },
      ],
    },
    {
      value: "Created By",
      label: "Created By",
      type: "string",
      // elements: [
      //   { value: "Message Distribution 1", label: "Message Distribution 1" },
      //   { value: "Message Distribution 2", label: "Message Distribution 2" },
      //   { value: "Message Distribution 3", label: "Message Distribution 3" },
      // ],
    },
  ];

  return (
    <Fragment>
      <Button
        variant="success"
        className="d-flex align-items-center justify-content-center add_floating__button position-fixed rounded-circle p-1 avatar-80 lh-1 border border-white border-2"
        onClick={() => handleCreate()}
      >
        {findIcon("Plus", "outline", "32")}
      </Button>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">
              <h4 className="card-title">All Target Sources</h4>
              {/* <Button
                type="button"
                variant="primary"
                className="fw-bold rounded-3"
                onClick={() => handleCreate()}
              >
                {findIcon("Plus", "dual-tone", 32)} Add TargetSource
              </Button> */}
              <FilterBar advanceData={advanceData} />
            </Card.Header>
            <Card.Body className="px-3">
              {!loading ? (
                targetSourceList.length > 0 ? (
                  <ReactTable
                    data={targetSourceList}
                    columns={columns}
                    recordsTotal={totalCount}
                    onTableStateChange={onTableStateChange}
                    initialState={post_json.current}
                    isRowSelectionAvailable={false}
                    recordsPerPage={10}
                  />
                ) : (
                  <PageNotFound />
                )
              ) : (
                <CommonTableLoader />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <DeleteModal
        show={deleteModalShow}
        modalClose={() => setDeleteModalShow(false)}
        onSubmit={deleteTargetSource}
      />
      <ViewModal
        title={"View TargetSource Information"}
        show={viewModalShow}
        modalClose={viewModalClose}
        data={viewTargetSource}
      />
      {/* create a add TargetSource modal with TargetSource name and description */}
      <Modal
        show={createModalShow}
        onHide={() => {
          setCreateModalShow(false);
          setIsEdit(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? "Update" : "Add"} Target Source</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                Target Source Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Target Source Name"
                name="target_source_name"
                onChange={handleChange}
                value={values.target_source_name}
                onBlur={handleBlur}
                isInvalid={
                  touched.target_source_name && errors.target_source_name
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.target_source_name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                Target Source Description <span className="text-danger">*</span>
              </Form.Label>

              <Form.Control
                type="text"
                placeholder="Enter Target Source Description"
                name="target_source_description"
                onChange={handleChange}
                value={values.target_source_description}
                onBlur={handleBlur}
                isInvalid={
                  touched.target_source_description &&
                  errors.target_source_description
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.target_source_description}
              </Form.Control.Feedback>
            </Form.Group>
            {isEdit && (
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  Target Source Status <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  name="status"
                  value={values?.status}
                  isInvalid={touched.status && errors.status}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="">Select Status</option>
                  <option value="1">Enabled</option>
                  <option value="2">Disabled</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors?.status}
                </Form.Control.Feedback>
              </Form.Group>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setCreateModalShow(false);
              setIsEdit(false);
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            {isEdit ? "Update" : "Save Changes"}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default AllTargetSources;
