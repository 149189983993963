import React, { Fragment, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Nav,
  Row,
  Tab,
} from "react-bootstrap";
import CustomToggle from "../../../../components/dropdowns";
// Apexcharts
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { theme_color } from "../../../../store/setting/selectors";
import { findIcon } from "../../../dashboard/icons";
import ReactTable from "../../../../lib/table/react-table";
import { useParams } from "react-router-dom";
const AllPersonaDetails = () => {
  const themeColor = useSelector(theme_color);

  const { name: personaName } = useParams();
  const getVariableColor = () => {
    let prefix =
      getComputedStyle(document.body).getPropertyValue("--prefix") || "bs-";
    if (prefix) {
      prefix = prefix.trim();
    }
    const color1 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}primary`
    );
    const color2 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}info`
    );
    const color3 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}primary-tint-20`
    );
    const color4 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}warning`
    );
    return {
      primary: color1.trim(),
      info: color2.trim(),
      warning: color4.trim(),
      primary_light: color3.trim(),
    };
  };
  const variableColors = getVariableColor();
  const colors = [variableColors.primary, variableColors.info];
  useEffect(() => {
    return () => colors;
  });

  const chart1 = {
    options: {
      colors: colors,
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        width: 3,
        dashArray: 0,
      },
      grid: {
        show: true,
        strokeDashArray: 3,
      },
      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July"],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      markers: {
        size: 6,
        colors: "#FFFFFF",
        strokeColors: colors,
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 0,
        shape: "circle",
        radius: 2,
        offsetX: 0,
        offsetY: 0,
      },
      responsive: [
        {
          breakpoint: 1025,
          options: {
            chart: {
              height: 130,
            },
          },
        },
      ],
    },
    series: [
      {
        name: "Viewed",
        data: [1542, 1634, 1720, 1550, 1800, 1825, 1750],
      },
    ],
  };

  const chart3 = {
    options: {
      chart: {
        height: 385,
        type: "radialBar",
      },
      colors: "red",
      plotOptions: {
        radialBar: {
          dataLabels: {
            total: {
              show: true,
              label: "TOTAL",
            },
          },
        },
      },
      labels: ["TEAM A", "TEAM B", "TEAM C", "TEAM D"],
    },
    series: [67, 84, 97, 61],
  };

  const chart3A = {
    options: {
      chart: {
        height: 100,
        type: "radialBar",
      },
      colors: [variableColors.info],
      plotOptions: {
        radialBar: {
          hollow: {
            size: "75%",
          },
        },
      },
      labels: ["%ge of Segment Engaged"],
    },
    series: [67],
  };

  const chart3B = {
    options: {
      chart: {
        height: 100,
        type: "radialBar",
      },
      colors: [variableColors.primary],
      plotOptions: {
        radialBar: {
          hollow: {
            size: "75%",
          },
        },
      },
      labels: ["Percentage of Engage"],
    },
    series: [27],
  };

  const chart3C = {
    options: {
      chart: {
        height: 100,
        type: "radialBar",
      },
      colors: [variableColors.info],
      plotOptions: {
        radialBar: {
          hollow: {
            size: "75%",
          },
        },
      },
      labels: ["Percentage of Engage"],
    },
    series: [42],
  };

  const chart3D = {
    options: {
      chart: {
        height: 100,
        type: "radialBar",
      },
      colors: [variableColors.info],
      plotOptions: {
        radialBar: {
          hollow: {
            size: "75%",
          },
        },
      },
      labels: ["%ge of Email Bounces"],
    },
    series: [10],
  };

  const chart3E = {
    options: {
      chart: {
        height: 100,
        type: "radialBar",
      },
      colors: [variableColors.primary],
      plotOptions: {
        radialBar: {
          hollow: {
            size: "75%",
          },
        },
      },
      labels: ["%ge  of DND"],
    },
    series: [50],
  };

  const chart3F = {
    options: {
      chart: {
        height: 100,
        type: "radialBar",
      },
      colors: [variableColors.info],
      plotOptions: {
        radialBar: {
          hollow: {
            size: "75%",
          },
        },
      },
      labels: ["This Month"],
    },
    series: [85],
  };
  //
  const userData = [
    {
      name: "Nephrology Doctors in Kolkata",
      persona: "Advocate ",
      ageGroup: "18-20",
      regionType: "Urban",
      location: "Rourkella",
      creator: "Created by",
      speciality: "General Medicine",
      brand: "Medwiki",
      actionDetails: "",
    },
    {
      name: "Oncology Doctors in Chennai",
      persona: "Advocate ",
      ageGroup: "18-20",
      regionType: "Rural",
      location: "Mumbai",
      speciality: "General Medicine",
      brand: "Medwiki",
      actionDetails: "",
    },
    {
      name: "Doctors in Delhi",
      persona: "Advocate ",
      ageGroup: "18-20",
      regionType: "Rural",
      location: "Mumbai",

      speciality: "General Medicine",
      brand: "Medwiki",
      actionDetails: "",
    },
    {
      name: "Nephrology Doctors in Kolkata",
      persona: "Advocate ",
      ageGroup: "18-20",
      regionType: "Urban",
      location: "Goghat",

      speciality: "General Medicine",
      brand: "Medwiki",
      actionDetails: "",
    },
  ];
  const COLUMNS = [
    {
      Header: "Segment",
      accessor: "name",
      // Filter: DefaultColumnFilter,
    },
    {
      Header: "Persona",
      accessor: "persona",
      // Filter: DefaultColumnFilter,
    },
    {
      Header: "Age Group",
      accessor: "ageGroup",
      // Filter: DefaultColumnFilter,
    },
    {
      Header: "Region Type",
      accessor: "regionType",
      // Filter: DefaultColumnFilter,
    },
    {
      Header: "Location",
      accessor: "location",
      // Filter: DefaultColumnFilter,
    },
    {
      Header: "Speciality",
      accessor: "speciality",
      // Filter: DefaultColumnFilter,
    },
    {
      Header: "Brand",
      accessor: "brand",
      // Filter: DefaultColumnFilter,
    },
    {
      Header: "Action",
      accessor: "actionDetails",
      disableFilters: true,
      Cell: ({ row }) => (
        <div className="d-flex justify-content-center">
          <Button
            className="btn btn-icon btn-sm rounded-pill"
            // onClick={onActionBtnClick}
            role="button"
          >
            <span className="btn-inner">{findIcon("Eye", "solid")}</span>
          </Button>
        </div>
      ),

      // Filter: DefaultColumnFilter,
    },
  ];
  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <div className="d-flex flex-column">
          {personaName && <h3 className="m-0">{personaName}</h3>}
        </div>
      </div>
      <Row>
        <Col lg={12} className="d-flex">
          <Card className="flex-grow-1">
            <Tab.Container defaultActiveKey="first">
              <div className="tab-bottom-bordered d-flex justify-content-between px-4 align-items-center">
                <Nav
                  className="m-0 bg-transparent"
                  data-toggle="slider-tab"
                  role="tablist"
                  variant="tabs"
                >
                  <Nav.Item role="presentation">
                    <Nav.Link
                      className="py-3"
                      eventKey="first"
                      variant="  d-flex align-items-center"
                      data-bs-toggle="tab"
                      data-bs-target="#pills-home1"
                      type="button"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      Event Count
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item role="presentation">
                    <Nav.Link
                      className="py-3"
                      eventKey="second"
                      variant=" d-flex align-items-center"
                      data-bs-toggle="tab"
                      data-bs-target="#pills-profile1"
                      type="button"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                    >
                      Unique Users
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item role="presentation">
                    <Nav.Link
                      className="py-3"
                      eventKey="third"
                      id="contact-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#pills-contact1"
                      type="button"
                      role="tab"
                      aria-controls="contact"
                      aria-selected="false"
                    >
                      SIF
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Dropdown>
                  <Dropdown.Toggle
                    as={CustomToggle}
                    variant="text-secondary dropdown-toggle"
                  >
                    This Year
                  </Dropdown.Toggle>
                  <Dropdown.Menu aria-labelledby="dropdownMenuButton1">
                    <Dropdown.Item href="#">Action</Dropdown.Item>
                    <Dropdown.Item href="#">Another action</Dropdown.Item>
                    <Dropdown.Item href="#">Something else here</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <Card.Body>
                <Tab.Content>
                  <Tab.Pane
                    eventKey="first"
                    variant=" fade show active"
                    id="pills-home1"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab1"
                  >
                    <Chart
                      options={chart1.options}
                      series={chart1.series}
                      type="line"
                      height="300"
                    />
                    <Col className="col-12 shadow p-3 bg-white rounded-3 bg-warning">
                      <Row>
                        <div className="col-12 col-md-3 text-center">
                          <h6>Monthwise Segment Use</h6>
                          <Chart
                            options={chart3A.options}
                            series={chart3A.series}
                            type="radialBar"
                            height="300"
                          />
                        </div>
                        {/* <div className="col-12 col-md-4 text-center">
                          <h6>Segment Last Engagement</h6>
                          <Chart
                            options={chart3B.options}
                            series={chart3B.series}
                            type="radialBar"
                            height="300"
                          />
                        </div> */}
                        {/* <div className="col-12 col-md-4 text-center">
                          <h6>Percentage of Engagement</h6>
                          <Chart
                            options={chart3C.options}
                            series={chart3C.series}
                            type="radialBar"
                            height="300"
                          />
                        </div> */}
                        <div className="col-12 col-md-3 text-center">
                          <h6>Percentage of Bounces</h6>
                          <Chart
                            options={chart3D.options}
                            series={chart3D.series}
                            type="radialBar"
                            height="300"
                          />
                        </div>
                        <div className="col-12 col-md-3 text-center">
                          <h6>Percentage of DND</h6>
                          <Chart
                            options={chart3E.options}
                            series={chart3E.series}
                            type="radialBar"
                            height="300"
                          />
                        </div>
                        <div className="col-12 col-md-3 text-center">
                          <h6>Reach</h6>
                          <Chart
                            options={chart3F.options}
                            series={chart3F.series}
                            type="radialBar"
                            height="300"
                          />
                        </div>
                      </Row>
                    </Col>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="second"
                    className="fade"
                    id="pills-profile1"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab1"
                  >
                    <p>
                      Cosby sweater eu banh mi, qui irure terry richardson ex
                      squid. Aliquip placeat salvia cillum iphone. Seitan
                      aliquip quis cardigan american apparel, butcher voluptate
                      nisi qui.
                    </p>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="third"
                    className=" fade"
                    id="pills-contact1"
                    role="tabpanel"
                    aria-labelledby="pills-contact-tab1"
                  >
                    <Row className="p-3">
                      <Col className="col-12 bg-soft-secondary rounded-3 pt-3 w-100 rounded-lg d-flex flex-column flex-sm-row mb-3 mb-sm-5 align-items-start align-items-sm-center">
                        <div class="levelChild flex-1 text-black d-flex align-items-center justify-content-center flex-row flex-sm-column mb-4 mb-sm-0 ml-3 ml-sm-0 mt-4 mt-sm-0">
                          <span class="pl-2 ps-sm-0">
                            <i class="fa-solid fa-angle-down rounded-circle p-1 d-flex align-items-center justify-content-center mb-0 mb-sm-2"></i>
                          </span>
                          <div class="d-flex align-items-start align-items-sm-center justify-content-center flex-column ml-4 ml-sm-0">
                            <span>
                              <strong>T-30 </strong>
                              Active Users
                            </span>
                            <h5>42,987</h5>
                          </div>
                        </div>
                        <div class="levelChild flex-1 text-black d-flex align-items-center justify-content-center flex-row flex-sm-column mb-4 mb-sm-0 ml-3 ml-sm-0">
                          <span class="pl-2 ps-sm-0">
                            <i class="fa-solid fa-angle-down rounded-circle p-1 d-flex align-items-center justify-content-center mb-0 mb-sm-2"></i>
                          </span>
                          <div class="d-flex align-items-start align-items-sm-center justify-content-center flex-column ml-4 ml-sm-0">
                            <span>
                              <strong>T-7 </strong>
                              Active Users
                            </span>
                            <h4 class="font-weight-bold">73,167</h4>
                          </div>
                        </div>
                        <div class="levelChild flex-1 text-black d-flex align-items-center justify-content-center flex-row flex-sm-column mb-4 mb-sm-0 ml-3 ml-sm-0">
                          <span class="pl-2 ps-sm-0">
                            <i class="fa-solid fa-angle-down rounded-circle p-1 d-flex align-items-center justify-content-center mb-0 mb-sm-2"></i>
                          </span>
                          <div class="d-flex align-items-start align-items-sm-center justify-content-center flex-column ml-4 ml-sm-0">
                            <span>
                              <strong>T-1 </strong>
                              Active Users
                            </span>
                            <h2 class="font-weight-bold">71,684</h2>
                          </div>
                        </div>
                        <div class="levelChild flex-1 text-black d-flex align-items-center justify-content-center flex-row flex-sm-column mb-4 mb-sm-0 ml-3 ml-sm-0">
                          <span class="pl-2 ps-sm-0">
                            <i class="fa-solid fa-angle-down rounded-circle p-1 d-flex align-items-center justify-content-center mb-0 mb-sm-2"></i>
                          </span>
                          <div class="d-flex align-items-start align-items-sm-center justify-content-center flex-column ml-4 ml-sm-0">
                            <span>
                              <strong>T-1 </strong>
                              Active Users
                            </span>
                            <h1 class="font-weight-bolder">70,518</h1>
                          </div>
                        </div>
                      </Col>

                      <Col className="col-12 col-md-8 p-0 pe-4" sm={8}>
                        <div class="funnelCurrentUser rounded-3 mb-3 border border-primary rounded-lg d-flex align-items-stretch">
                          <div class="p-2 mr-3 d-flex align-items-center bg-primary text-white">
                            <i class="fa-solid fa-angle-right"></i>
                          </div>
                          <div class="flex-grow-1 d-flex align-items-center flex-column flex-sm-row justify-content-sm-between p-2">
                            <div class="d-flex flex-column text-primary">
                              <span>New User</span>
                              <h1 class="font-weight-bolder">12,646</h1>
                            </div>
                            <div class="d-flex flex-row funnelStatics">
                              <div class="d-flex flex-column mr-3 align-items-center">
                                <span>To (T-1)</span>
                                <h6 class="mt-2">-5%</h6>
                              </div>
                              <div class="d-flex flex-column mr-3 align-items-center">
                                <span>To (T-7)</span>
                                <h6 class="mt-2">0%</h6>
                              </div>
                              <div class="d-flex flex-column mr-3 align-items-center">
                                <span>To (T-30)</span>
                                <h6 class="mt-2">29%</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="funnelCurrentUser rounded-3 mb-3 border border-secondary rounded-lg d-flex align-items-stretch">
                          <div class="p-2 mr-2 d-flex align-items-center bg-pink text-white">
                            <i class="fa-solid fa-angle-right"></i>
                          </div>
                          <div class="flex-grow-1 d-flex align-items-center flex-column flex-sm-row justify-content-sm-between p-2">
                            <div class="d-flex flex-column text-white">
                              <span>New User</span>
                              <h1 class="font-weight-bolder">12,646</h1>
                            </div>
                            <div class="d-flex flex-row text-white funnelStatics">
                              <div class="d-flex flex-column mr-3 align-items-center">
                                <span>To (T-1)</span>
                                <h6 class="mt-2">-5%</h6>
                              </div>
                              <div class="d-flex flex-column mr-3 align-items-center">
                                <span>To (T-7)</span>
                                <h6 class="mt-2">0%</h6>
                              </div>
                              <div class="d-flex flex-column mr-3 align-items-center">
                                <span>To (T-30)</span>
                                <h6 class="mt-2">29%</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="funnelCurrentUser rounded-3 mb-3 border border-secondary rounded-lg d-flex align-items-stretch">
                          <div class="p-2 mr-2 d-flex align-items-center bg-secondary text-white">
                            <i class="fa-solid fa-angle-right"></i>
                          </div>
                          <div class="flex-grow-1 d-flex align-items-center flex-column flex-sm-row justify-content-sm-between p-2">
                            <div class="d-flex flex-column text-secondary">
                              <span>New User</span>
                              <h1 class="font-weight-bolder text-white">
                                12,646
                              </h1>
                            </div>
                            <div class="d-flex flex-row funnelStatics">
                              <div class="d-flex flex-column mr-3 align-items-center">
                                <span>To (T-1)</span>
                                <h6 class="mt-2 text-white">-5%</h6>
                              </div>
                              <div class="d-flex flex-column mr-3 align-items-center">
                                <span>To (T-7)</span>
                                <h6 class="mt-2 text-white">0%</h6>
                              </div>
                              <div class="d-flex flex-column mr-3 align-items-center">
                                <span>To (T-30)</span>
                                <h6 class="mt-2 text-white">29%</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="funnelCurrentUser rounded-3 mb-3 border border-secondary rounded-lg d-flex align-items-stretch">
                          <div class="p-2 mr-2 d-flex align-items-center bg-secondary text-white">
                            <i class="fa-solid fa-angle-right"></i>
                          </div>
                          <div class="flex-grow-1 d-flex align-items-center flex-column flex-sm-row justify-content-sm-between p-2">
                            <div class="d-flex flex-column text-secondary">
                              <span>New User</span>
                              <h1 class="font-weight-bolder">12,646</h1>
                            </div>
                            <div class="d-flex flex-row funnelStatics">
                              <div class="d-flex flex-column mr-3 align-items-center">
                                <span>To (T-1)</span>
                                <h6 class="mt-2">-5%</h6>
                              </div>
                              <div class="d-flex flex-column mr-3 align-items-center">
                                <span>To (T-7)</span>
                                <h6 class="mt-2">0%</h6>
                              </div>
                              <div class="d-flex flex-column mr-3 align-items-center">
                                <span>To (T-30)</span>
                                <h6 class="mt-2">29%</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="funnelCurrentUser rounded-3 mb-3 border border-secondary rounded-lg d-flex align-items-stretch">
                          <div class="p-2 mr-2 d-flex align-items-center bg-secondary text-white">
                            <i class="fa-solid fa-angle-right"></i>
                          </div>
                          <div class="flex-grow-1 d-flex align-items-center flex-column flex-sm-row justify-content-sm-between p-2">
                            <div class="d-flex flex-column text-secondary">
                              <span>New User</span>
                              <h1 class="font-weight-bolder">12,646</h1>
                            </div>
                            <div class="d-flex flex-row funnelStatics">
                              <div class="d-flex flex-column mr-3 align-items-center">
                                <span>To (T-1)</span>
                                <h6 class="mt-2">-5%</h6>
                              </div>
                              <div class="d-flex flex-column mr-3 align-items-center">
                                <span>To (T-7)</span>
                                <h6 class="mt-2">0%</h6>
                              </div>
                              <div class="d-flex flex-column mr-3 align-items-center">
                                <span>To (T-30)</span>
                                <h6 class="mt-2">29%</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="funnelCurrentUser rounded-3 mb-3 border border-secondary rounded-lg d-flex align-items-stretch">
                          <div class="p-2 mr-2 d-flex align-items-center bg-secondary text-white">
                            <i class="fa-solid fa-angle-right"></i>
                          </div>
                          <div class="flex-grow-1 d-flex align-items-center flex-column flex-sm-row justify-content-sm-between p-2">
                            <div class="d-flex flex-column text-secondary">
                              <span>New User</span>
                              <h1 class="font-weight-bolder">12,646</h1>
                            </div>
                            <div class="d-flex flex-row funnelStatics">
                              <div class="d-flex flex-column mr-3 align-items-center">
                                <span>To (T-1)</span>
                                <h6 class="mt-2">-5%</h6>
                              </div>
                              <div class="d-flex flex-column mr-3 align-items-center">
                                <span>To (T-7)</span>
                                <h6 class="mt-2">0%</h6>
                              </div>
                              <div class="d-flex flex-column mr-3 align-items-center">
                                <span>To (T-30)</span>
                                <h6 class="mt-2">29%</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col className="col-12 col-md-4 px-0 pl-sm-5" sm={4}>
                        <div class="rounded-lg d-flex flex-column justify-content-center align-items-center mb-3 mb-sm-5 reUserStatics">
                          <div class="d-flex flex-column align-items-center w-100 py-2">
                            <span class="font-weight-bold">
                              Reactivated Users
                            </span>
                            <h2>15,904</h2>
                          </div>
                          <div class="rounded-lg d-flex flex-row flex-1 statics w-100 py-2 align-items-center justify-content-center">
                            <span class="d-flex flex-column align-items-center px-2 flex-1">
                              <b class="text-black-50">To (T-1)</b>
                              <i class="font-weight-bold text-dark">-2%</i>
                            </span>
                            <span class="d-flex flex-column align-items-center px-2 flex-1">
                              <b class="text-black-50">To (T-1)</b>
                              <i class="font-weight-bold text-dark">-2%</i>
                            </span>
                            <span class="d-flex flex-column align-items-center px-2 flex-1">
                              <b class="text-black-50">To (T-1)</b>
                              <i class="font-weight-bold text-dark">-2%</i>
                            </span>
                          </div>
                        </div>
                        <div class="rounded-lg d-flex flex-column justify-content-center align-items-center mb-3 mb-sm-5 reUserStatics">
                          <div class="d-flex flex-column align-items-center w-100 py-2">
                            <span class="font-weight-bold">
                              Reactivated Users
                            </span>
                            <h2>15,904</h2>
                          </div>
                          <div class="rounded-lg d-flex flex-row flex-1 statics w-100 py-2 align-items-center justify-content-center">
                            <span class="d-flex flex-column align-items-center px-2 flex-1">
                              <b class="text-black-50">To (T-1)</b>
                              <i class="font-weight-bold text-dark">-2%</i>
                            </span>
                            <span class="d-flex flex-column align-items-center px-2 flex-1">
                              <b class="text-black-50">To (T-1)</b>
                              <i class="font-weight-bold text-dark">-2%</i>
                            </span>
                            <span class="d-flex flex-column align-items-center px-2 flex-1">
                              <b class="text-black-50">To (T-1)</b>
                              <i class="font-weight-bold text-dark">-2%</i>
                            </span>
                          </div>
                        </div>
                        <div class="rounded-lg d-flex flex-column justify-content-center align-items-center mb-3 mb-sm-5 reUserStatics">
                          <div class="d-flex flex-column align-items-center w-100 py-2">
                            <span class="font-weight-bold">
                              Reactivated Users
                            </span>
                            <h2>15,904</h2>
                          </div>
                          <div class="rounded-lg d-flex flex-row flex-1 statics w-100 py-2 align-items-center justify-content-center">
                            <span class="d-flex flex-column align-items-center px-2 flex-1">
                              <b class="text-black-50">To (T-1)</b>
                              <i class="font-weight-bold text-dark">-2%</i>
                            </span>
                            <span class="d-flex flex-column align-items-center px-2 flex-1">
                              <b class="text-black-50">To (T-1)</b>
                              <i class="font-weight-bold text-dark">-2%</i>
                            </span>
                            <span class="d-flex flex-column align-items-center px-2 flex-1">
                              <b class="text-black-50">To (T-1)</b>
                              <i class="font-weight-bold text-dark">-2%</i>
                            </span>
                          </div>
                        </div>
                      </Col>

                      <Col className="col-12 px-0 mt-2 mt-sm-5 shadow p-3 bg-white rounded">
                        <div class="color-primary d-flex flex-row col-12 p-0 p-sm-2 mb-3 mb-sm-0">
                          <i class="fa-solid fa-file-arrow-down fs-4 reportIocn me-3"></i>
                          <h5 class="ml-2">Download Cohort Report</h5>
                        </div>
                        <div class="row m-0 downlodReportRow">
                          <div class="col-12 col-md-3 mb-3 mb-md-0">
                            <Form.Select
                              name="type"
                              className="w-100 selectpicker form-control col-12 col-md-4"
                              data-style="py-0"
                            >
                              <option value="volvo">Select Cohort</option>
                              <option value="saab">Saab</option>
                              <option value="mercedes">Mercedes</option>
                              <option value="audi">Audi</option>
                            </Form.Select>
                          </div>
                          <div class="col-12 col-md-3 mb-3 mb-md-0">
                            <Form.Select
                              name="type"
                              className="w-100 selectpicker form-control col-12 col-md-4 w-100"
                              data-style="py-0"
                            >
                              <option value="volvo">Select Channel</option>
                              <option value="saab">Saab</option>
                              <option value="mercedes">Mercedes</option>
                              <option value="audi">Audi</option>
                            </Form.Select>
                          </div>
                          <div class="col-12 col-md-3 mb-3 mb-md-0">
                            <Form.Select
                              name="type"
                              className="w-100 selectpicker form-control col-12 col-md-4 w-100"
                              data-style="py-0"
                            >
                              <option value="volvo">Select User Type</option>
                              <option value="saab">Saab</option>
                              <option value="mercedes">Mercedes</option>
                              <option value="audi">Audi</option>
                            </Form.Select>
                          </div>
                          <div class="col-12 col-md-3 mb-3 mb-md-0">
                            <Button
                              variant="primary"
                              className="flex-fill fw-semibold rounded-3 px-4 py-2 h-100"
                              type="submit"
                            >
                              <span className="">Download</span>
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <ReactTable
                data={userData}
                columns={COLUMNS}
                recordsTotal={userData?.length}
                recordsPerPage={10}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default AllPersonaDetails;
