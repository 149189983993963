import { Fragment, useEffect, memo, createRef, useState, useRef } from "react";
import React from "react";
import CountUp from "react-countup";
import {
  Card,
  Col,
  Form,
  Row,
  Container,
  Button,
  FormGroup,
  FormCheck,
  Modal,
  Tab,
  Nav,
  TabContent,
  NavItem,
  Navbar,
  NavDropdown,
  Dropdown,
} from "react-bootstrap";
import Progress from "../../../../components/progress";
import Flatpickr from "react-flatpickr";
import { findIcon } from "../../../dashboard/icons";
import mobile from "../../../../assets/images/mobile-bg.png";
import mobileWhatsapp from "../../../../assets/images/mobileWhatsapp.png";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  CAMPAIGN_CONTENT_STUDIO_TEMPLATE_PREVIEW_GET,
  CONTENT_STUDIO_GET,
  CAMPAIGN_LIST_POST,
  CAMPAIGN_SEGMENT_LIST_POST,
  CAMPAIGN_ADD_POST,
  CAMPAIGN_STUDIO_CHECK_FIT_GET,
  CAMPAIGN_DETAILS_GET,
  CAMPAIGN_UPDATE_POST,
} from "../../../../api/endpoints/campaign-endpoints";
import postService from "../../../../api/services/post-service";
import { getService } from "../../../../api/services/get-services";
import * as moment from "moment";
import PaginationBar from "../../../../lib/table/pagination-bar";
import sample from "../../../../lib/email-editor/sample";
import ReactEmailEditor from "../../../../lib/email-editor/react-email-editor";
import toast from "react-hot-toast";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import { useParams } from "react-router-dom";

const pageSize = 10;

const CreateCampaign = () => {
  const emailEditorRef = useRef(null);
  const { redirectTo } = useRedirect();

  const [loading, setLoading] = useState(false);
  const [campaignName, setCampaignName] = useState("");
  const [segmentList, setSegmentList] = useState([]);
  const [selectedSegment, setSelectedSegment] = useState("");
  const [segmentListTotalCount, setSegmentListTotalCount] = useState(0);
  const [segmentSearchContent, setSegmentSearchContent] = useState("");
  const [segmentDateRange, setSegmentDateRange] = useState("");
  const [contentStudioList, setContentStudioList] = useState([]);
  const [contentStudioTemplate, setContentStudioTemplate] = useState({});
  const [contentStudioListTotalCount, setContentStudioListTotalCount] =
    useState(0);
  const [contentStudioSearchContent, setContentStudioSearchContent] =
    useState("");
  const [selectedContentStudio, setSelectedContentStudio] = useState("");
  const [fitContentShow, setFitContentShow] = React.useState(false);
  const [checkFitCount, setCheckFitCount] = React.useState("");
  const [segmentTableState, setSegmentTableState] = React.useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    filters: [],
  });
  const [contentStudioTableState, setContentStudioTableState] = React.useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    filters: [],
  });
  const [messageDistribution, setMessageDistribution] = useState("");
  const [messageDistributionABTestData, setMessageDistributionABTestData] =
    useState({
      controlGroup: 0,
      setupTiming: "",
      sendMode: "",
    });
  const [
    messageDistributionSplitDeliveryData,
    setMessageDistributionSplitDeliveryData,
  ] = useState({
    splitRatio: 50,
    splitUser: "",
  });

  const params = useParams();

  useEffect(() => {
    getSegmentList((segmentList) => {
      getContentStudioList((contentStudioList) => {
        if (params.id) {
          getCampaignEditData(segmentList, contentStudioList);
        }
      });
    });
    // getContentStudioList();
  }, []);

  const getCampaignEditData = async (segmentList, contentStudioList) => {
    try {
      setLoading(true);
      const response = await getService(CAMPAIGN_DETAILS_GET + `/${params.id}`);
      if (response.isError) {
        toast.error(response?.error);
        setLoading(false);
      } else {
        if (response.data.data) {
          setLoading(false);
          let segmentId = response.data?.data?.segment?.segment_id
            ? response.data?.data?.segment?.segment_id
            : "";
          let contentId = response.data.data?.contentstudio?.content_studio_id
            ? response.data.data?.contentstudio?.content_studio_id
            : "";
          let name = response.data.data?.campaign_name
            ? response.data.data?.campaign_name
            : "";
          if (segmentList.length > 0) {
            let segmentData = segmentList.filter(
              (item) => item.segment_id === segmentId
            );
            setSelectedSegment(segmentData[0]);
          }
          if (contentStudioList.length > 0) {
            let contentData = contentStudioList.filter(
              (item) => item.content_studio_id === contentId
            );
            setSelectedContentStudio(contentData[0]);
          }
          setCampaignName(name);
          setMessageDistribution("sendAll");
          getContentStudioTemplate(contentId);
        }
      }
    } catch (error) {
      setLoading(false);
      // console.log("USER_SERVICE_ERROR", error);
    }
  };

  const post_json_segment_list = useRef({
    filter: {
      default: "all",
    },
    pagination: {
      limit: 10,
      offset: 0,
    },
  });

  const post_json_content_list = useRef({
    filter: {
      default: "all",
    },
    pagination: {
      limit: 10,
      offset: 0,
    },
  });

  const getSegmentList = async (cb) => {
    try {
      setLoading(true);
      const response = await postService(
        CAMPAIGN_SEGMENT_LIST_POST,
        post_json_segment_list.current
      );
      // console.log("segment list service ", response.data.data);
      // console.log("COLUMNS", Object.keys(response.data.data.rows[0]));
      if (response.data.data?.rows.length > 0) {
        setSegmentList(response.data.data?.rows);
        setSegmentListTotalCount(response.data.data?.totalCount);
        setLoading(false);
        cb && cb(response.data.data?.rows);
      } else {
        setSegmentList([]);
      }
    } catch (error) {
      setLoading(false);
      // console.log("USER_SERVICE_ERROR", error);
    }
  };

  const getContentStudioList = async (cb) => {
    try {
      setLoading(true);
      const response = await postService(
        CONTENT_STUDIO_GET,
        post_json_content_list.current
      );
      // console.log("content studio list service ", response.data.data);
      // console.log("COLUMNS", Object.keys(response.data.data.content_studio[0]));
      if (response?.data?.data?.rows?.length > 0) {
        setContentStudioList(response?.data?.data?.rows);
        setContentStudioListTotalCount(response.data.data?.totalCount);
        setLoading(false);
        cb && cb(response.data.data?.rows);
      } else {
        setContentStudioList([]);
      }
    } catch (error) {
      setLoading(false);
      // console.log("USER_SERVICE_ERROR", error);
    }
  };

  const getContentStudioTemplate = async (id) => {
    try {
      setLoading(true);
      const response = await getService(
        CAMPAIGN_CONTENT_STUDIO_TEMPLATE_PREVIEW_GET + `/${id}`
      );
      console.log(
        "content studio template service ",
        response.data.data.templates
      );
      // console.log("COLUMNS", Object.keys(response.data.data.rows[0]));
      if (response.data.data.templates) {
        setContentStudioTemplate(response.data.data.templates);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      // console.log("USER_SERVICE_ERROR", error);
    }
  };

  const handleContentStudioClick = (item) => {
    // console.log("selected content studio item", item);
    setSelectedContentStudio(item);
    getContentStudioTemplate(item?.content_studio_id);
  };

  const handleSegmentClick = (item) => {
    // console.log("selected segment item", item);
    setSelectedSegment(item);
  };

  const onLoad = (json_content) => {
    if (json_content) {
      emailEditorRef.current?.editor?.loadDesign(json_content);
    }
  };

  const onReady = () => {
    emailEditorRef.current?.editor?.showPreview("desktop");
  };

  const openCheckFitModal = async () => {
    if (
      selectedSegment != "" &&
      selectedContentStudio != "" &&
      "content_studio_id" in selectedContentStudio
    ) {
      try {
        setLoading(true);
        const response = await getService(
          CAMPAIGN_STUDIO_CHECK_FIT_GET +
            `?segment_id=${selectedSegment?.segment_id}&content_studio_id=${selectedContentStudio?.content_studio_id}`
        );
        // console.log("content studio check fit response ", response.data);

        if (response.isError) {
          toast.error(response?.error);
        } else {
          setCheckFitCount(response.data.data.check_fit_count);
          setFitContentShow(true);
        }
      } catch (error) {
        setLoading(false);
        // console.log("USER_SERVICE_ERROR", error);
      }
    } else {
      toast.error("Please select segment");
    }
  };

  const handleSegmentSearch = (e) => {
    setSegmentSearchContent(e.target.value);
    const payload = {
      ...post_json_segment_list.current,
      filter: {
        segment_name: {
          type: "eq",
          value: e.target.value,
        },
      },
    };
    post_json_segment_list.current = payload;
    getSegmentList(payload);
  };

  const handleSegmentDateRange = (date) => {
    console.log("Segment Date Range", date);

    const moment = require("moment-timezone");

    const startDate = new Date(date[0]);
    const formattedStartDateTime = moment(startDate)
      .tz("Asia")
      .format("YYYY-MM-DD HH:mm:ss");
    console.log("formattedStartDateTime", formattedStartDateTime);

    const endDate = new Date(date[1]);
    const formattedEndDateTime = moment(endDate)
      .tz("Asia")
      .format("YYYY-MM-DD HH:mm:ss");
    console.log("formattedEndDateTime", formattedEndDateTime);

    setSegmentDateRange(date);
    const payload = {
      ...post_json_segment_list.current,
      filter: {
        created_at: {
          type: "gteq",
          value: formattedStartDateTime,
        },
        created_at: {
          type: "lteq",
          value: formattedEndDateTime,
        },
      },
    };
    console.log("date length", date.length);
    if (date.length > 1) {
      post_json_segment_list.current = payload;
      getSegmentList(payload);
    }
  };

  const handleContentStudioSearch = (e) => {
    setContentStudioSearchContent(e.target.value);
    const payload = {
      ...post_json_content_list.current,
      filter: {
        content_studio_name: {
          type: "eq",
          value: e.target.value,
        },
      },
    };
    post_json_content_list.current = payload;
    getContentStudioList(payload);
  };

  const onSegmentPageChange = (pageIndex) => {
    // console.log("pageIndex", pageIndex);
    setSegmentTableState((prevState) => ({
      ...prevState,
      pageIndex: pageIndex,
    }));
    post_json_segment_list.current = {
      ...post_json_segment_list.current,
      pagination: {
        ...post_json_segment_list.current.pagination,
        offset: pageIndex * pageSize,
      },
    };
    getSegmentList();
  };

  const onSegmentNextPageClick = () => {
    if (
      segmentTableState.pageIndex <
      Math.ceil(segmentListTotalCount / pageSize) - 1
    ) {
      onSegmentPageChange(segmentTableState.pageIndex + 1);
    }
  };

  const onSegmentPreviousPageClick = () => {
    if (segmentTableState.pageIndex > 0) {
      onSegmentPageChange(segmentTableState.pageIndex - 1);
    }
  };

  const onContentStudioPageChange = (pageIndex) => {
    // console.log("pageIndex", pageIndex);
    setContentStudioTableState((prevState) => ({
      ...prevState,
      pageIndex: pageIndex,
    }));
    post_json_content_list.current = {
      ...post_json_content_list.current,
      pagination: {
        ...post_json_content_list.current.pagination,
        offset: pageIndex * pageSize,
      },
    };
    getContentStudioList();
  };

  const onContentStudioNextPageClick = () => {
    if (
      contentStudioTableState.pageIndex <
      Math.ceil(contentStudioListTotalCount / pageSize) - 1
    ) {
      onContentStudioPageChange(contentStudioTableState.pageIndex + 1);
    }
  };

  const onContentStudioPreviousPageClick = () => {
    if (contentStudioTableState.pageIndex > 0) {
      onContentStudioPageChange(contentStudioTableState.pageIndex - 1);
    }
  };

  const handleABTestSendType = (e) => {
    // console.log("handleABTestSendType", e.target.value);
    setMessageDistributionABTestData({
      ...messageDistributionABTestData,
      sendMode: e.target.value,
    });
  };

  const handleABTestTiming = (e) => {
    // console.log("handleABTestTiming", e.target.value);
    const moment = require("moment-timezone");

    const startDate = new Date(e.target.value);
    const formattedDateTime = moment(startDate)
      .tz("Asia")
      .format("YYYY-MM-DD HH:mm:ss");
    // console.log("formattedDate", formattedDateTime);
    setMessageDistributionABTestData({
      ...messageDistributionABTestData,
      setupTiming: formattedDateTime,
    });
  };

  const handleSplitUserType = (e) => {
    // console.log("handleSplitUserType", e.target.value);
    setMessageDistributionSplitDeliveryData({
      ...messageDistributionSplitDeliveryData,
      splitUser: e.target.value,
    });
  };

  const handleSpiteRange = (e) => {
    // console.log("handleSpiteRange", e.target.value);
    const originalValue = e.target.value;
    const roundedValue = Math.round(parseFloat(originalValue));
    // console.log("roundedValue", roundedValue);
    setMessageDistributionSplitDeliveryData({
      ...messageDistributionSplitDeliveryData,
      splitRatio: roundedValue,
    });
  };

  const handleCreateCampaign = async () => {
    if (
      selectedSegment != "" &&
      selectedContentStudio != "" &&
      messageDistribution != ""
    ) {
      // console.log("handleCreateCampaign");
      let message_distribution_type = "";
      let message_distribution_value = "";
      let message_sending_type = "";
      let campaign_schedule_time = "";
      if (messageDistribution == "sendAll") {
        message_distribution_type = "all";
        message_distribution_value = "";
        message_sending_type = "instant";
        campaign_schedule_time = "";
      }
      if (messageDistribution == "abTest") {
        message_distribution_type = "ab";
        message_distribution_value =
          messageDistributionABTestData?.controlGroup;
        message_sending_type = messageDistributionABTestData?.sendMode;
        campaign_schedule_time = messageDistributionABTestData?.setupTiming;
      }
      if (messageDistribution == "splitDelivery") {
        message_distribution_type = "split";
        message_distribution_value =
          messageDistributionSplitDeliveryData?.splitRatio;
        message_sending_type = "instant";
        campaign_schedule_time = "";
      }

      const payload = {
        campaign_name: campaignName,
        campaign_segment_id: selectedSegment?.segment_id,
        campaign_content_studio_id: selectedContentStudio?.content_studio_id,
        campaign_distribution_type: message_distribution_type,
        campaign_distribution_value: message_distribution_value,
        campaign_sending_type: message_sending_type,
        campaign_schedule_time: campaign_schedule_time,
      };

      // console.log("payload", payload);
      try {
        setLoading(true);
        // const response = await postService(CAMPAIGN_ADD_POST, payload);
        let response = await postService(
          params.id
            ? CAMPAIGN_UPDATE_POST + "/" + params.id
            : CAMPAIGN_ADD_POST,
          payload
        );
        // console.log("add/update campaign response===>", response);
        if (response.isError) {
          // console.log('here1');
          // setTokenValidate(false);
          setLoading(false);
          toast(response.error);
        } else {
          setLoading(false);
          if (response.data.success == true) {
            toast.success(response.data.message);
            redirectTo("campaign/all");
          }
        }
      } catch (err) {
        console.log("err", err);
        setLoading(false);
      }
    }
  };

  return (
    <Fragment>
      <Container fluid>
        <Row>
          {/* Page heading section */}
          <Card>
            <Card.Header className="d-flex flex-row align-items-center justify-content-between px-0 py-3">
              <div className="d-flex flex-row align-items-center">
                <div className="me-3 border border-dashed p-1 rounded-3 bg-soft-primary">
                  {findIcon("Analytics", "dual-tone", "32")}
                </div>
                <h5 className="mb-0">Campaign Studio</h5>
              </div>
            </Card.Header>
            <Col lg="12" className="mb-3 py-2">
              <Form.Group>
                <Form.Label className="form-label">
                  Setup Control group
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Campaign Name"
                  name="campaignName"
                  value={campaignName}
                  onChange={(e) => setCampaignName(e.target.value)}
                />
              </Form.Group>
              {!campaignName && (
                <spam className="text-left text-danger">
                  Please Enter a Campaign Name
                </spam>
              )}
            </Col>
          </Card>

          {/* Segment listing with Search */}
          <Card>
            <Card.Body className="p-0 mb-3">
              <Row>
                {/* segment heading  */}
                <Col lg="12">
                  <h6 className="my-3 text-black">Select Segment</h6>
                </Col>

                {/* search section  */}
                <Col
                  lg="12"
                  className="mb-3 py-3 d-flex flex-row align-items-center justify-content-between bg-soft-secondary"
                >
                  <Form.Floating className="custom-form-floating mb-0 me-4 nav-pills">
                    <Form.Control
                      type="name"
                      className="mb-0 pe-5 position-relative"
                      autoComplete="Name"
                      placeholder="Search"
                      value={segmentSearchContent}
                      onChange={(e) => handleSegmentSearch(e)}
                    />
                    <label htmlFor="floatingInput">Search</label>
                    <span className="position-absolute top-0 end-0 h-100 d-flex align-items-center justify-content-center pe-2">
                      {findIcon("Search", "outline", "22")}
                    </span>
                  </Form.Floating>
                  <div className="form-group m-0 nav-pills position-relative">
                    <Flatpickr
                      options={{ mode: "range" }}
                      className="form-control range_flatpicker"
                      placeholder="Select Date Range"
                      value={segmentDateRange}
                      onChange={(date) => {
                        handleSegmentDateRange(date);
                      }}
                    />
                    <span className="position-absolute top-0 end-0 h-100 d-flex align-items-center justify-content-center pe-2">
                      {findIcon("Calendar", "outline", "22")}
                    </span>
                  </div>
                </Col>

                {/* Listing section */}
                <Col lg="8" className="row p-0 mx-0">
                  <div className="row row-cols-2 flex-row flex-wrap rounded-3 m-0 ps-0">
                    {segmentList?.map((item, idx) => (
                      <Col
                        className="mb-3 pe-0"
                        onClick={(e) => {
                          handleSegmentClick(item);
                        }}
                      >
                        <div
                          className={`d-flex flex-column text-secondary border border-1 p-3 align-items-start justify-content-start rounded-3 ${
                            selectedSegment.segment_id == item.segment_id
                              ? "bg-soft-primary border border-2 border-dashed border-primary text-primary"
                              : ""
                          }`}
                        >
                          <p className="iq-key-metrics mb-2">
                            {item.segment_name ? item.segment_name : ""}
                          </p>
                          <div className="d-flex flex-row align-items-center justify-content-center">
                            <span className="fs-7 text-gray me-1">
                              Created:
                            </span>
                            <span className="fs-7 text-info">
                              {item.created_at
                                ? moment(item.created_at).format("DD MMM YYYY")
                                : ""}
                            </span>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </div>
                </Col>

                {/* Segment Analytics section  */}
                <Col lg="4" className="pb-3">
                  <div className="row row-cols-1 m-0 rounded-3 py-3 border border-1">
                    <Col>
                      <Card className="mb-2 shadow-none rounded-2 border border-2">
                        <Card.Body className="p-2 d-flex flex-row align-items-center justify-content-start gap-3 mx-1">
                          <div className="d-flex align-items-center rounded-3 bg-soft-warning p-1">
                            {findIcon("View Grid", "dual-tone", "20")}
                          </div>
                          <h5 className="m-0 fs-7 fw-normal">Segment Size</h5>
                          <h6 className="m-0 fw-normal text-secondary ms-auto fw-semibold text-black">
                            <CountUp start={3} end={253} duration={3} />
                          </h6>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card className="mb-2 shadow-none rounded-2 border border-2">
                        <Card.Body className="p-2 d-flex flex-row align-items-center justify-content-start gap-3 mx-1">
                          <div className="d-flex align-items-center rounded-3 bg-soft-success p-1">
                            {findIcon("Whatsapp", "dual-tone", "20")}
                          </div>
                          <h5 className="m-0 fs-7 fw-normal">WhatsApp</h5>
                          <h6 className="m-0 fw-normal text-secondary ms-auto fw-semibold text-black">
                            <CountUp start={29} end={439} duration={3} />
                          </h6>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card className="mb-2 shadow-none rounded-2 border border-2">
                        <Card.Body className="p-2 d-flex flex-row align-items-center justify-content-start gap-3 mx-1">
                          <div className="d-flex align-items-center rounded-3 bg-soft-dark p-1">
                            {findIcon("Email", "dual-tone", "20")}
                          </div>
                          <h5 className="m-0 fs-7 fw-normal">Email</h5>
                          <h6 className="m-0 fw-normal text-secondary ms-auto fw-semibold text-black">
                            <CountUp start={29} end={255} duration={3} />
                          </h6>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card className="mb-2 shadow-none rounded-2 border border-2">
                        <Card.Body className="p-2 d-flex flex-row align-items-center justify-content-start gap-3 mx-1">
                          <div className="d-flex align-items-center rounded-3 bg-soft-danger p-1">
                            {findIcon("Message-2", "dual-tone", "20")}
                          </div>
                          <h5 className="m-0 fs-7 fw-normal">SMS</h5>
                          <h6 className="m-0 fw-normal text-secondary ms-auto fw-semibold text-black">
                            <CountUp start={9} end={658} duration={3} />
                          </h6>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card className="mb-2 shadow-none rounded-2 border border-2">
                        <Card.Body className="p-2 d-flex flex-row align-items-center justify-content-start gap-3 mx-1">
                          <div className="d-flex align-items-center rounded-3 bg-soft-primary p-1">
                            {findIcon("Action", "dual-tone", "20")}
                          </div>
                          <h5 className="m-0 fs-7 fw-normal">Touch Point</h5>
                          <h6 className="m-0 fw-normal text-secondary ms-auto fw-semibold text-black">
                            <CountUp start={29} end={345} duration={3} />
                          </h6>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card className="mb-2 shadow-none rounded-2 border border-2">
                        <Card.Body className="p-2 d-flex flex-row align-items-center justify-content-start gap-3 mx-1">
                          <div className="d-flex align-items-center rounded-3 bg-soft-info p-1">
                            {findIcon("Filter", "dual-tone", "20")}
                          </div>
                          <h5 className="m-0 fs-7 fw-normal">Reach</h5>
                          <h6 className="m-0 mb-0 fw-normal text-secondary ms-auto fw-semibold text-black">
                            <CountUp start={2929} end={732} duration={3} />
                          </h6>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card className="mb-2 bg-soft-danger shadow-none rounded-2">
                        <Card.Body className="p-2 d-flex flex-row align-items-center justify-content-start gap-3 mx-1">
                          <div className="d-flex align-items-center rounded-3 text-danger bg-soft-danger p-1">
                            {findIcon(
                              "Exclamation Triangle",
                              "dual-tone",
                              "20"
                            )}
                          </div>
                          <h5 className="m-0 fs-7 fw-normal text-danger">
                            DND Status
                          </h5>
                          <h6 className="m-0 ms-auto fw-semibold text-danger">
                            <CountUp start={29} end={332} duration={3} />
                          </h6>
                        </Card.Body>
                      </Card>
                    </Col>
                  </div>
                </Col>

                {!selectedSegment && (
                  <spam className="text-left text-danger">
                    Please select a segment
                  </spam>
                )}
                {/* Pagination section  */}
                <PaginationBar
                  gotoPage={onSegmentPageChange}
                  onNextPage={() => onSegmentNextPageClick()}
                  onPreviousPage={() => onSegmentPreviousPageClick()}
                  totalPage={segmentListTotalCount}
                  pageIndex={segmentTableState.pageIndex}
                />
              </Row>
            </Card.Body>
          </Card>

          {/* Content Studio listing with Search */}
          <Card>
            <Card.Body className="p-0 mb-3">
              <Row>
                {/* heading & search section  */}
                <Col
                  lg="12"
                  className="d-flex justify-content-between py-3 mb-3 border-bottom border-1"
                >
                  <h6 className="my-3 text-black">
                    Send Content Studio to Segment
                  </h6>
                  <Form.Floating className="custom-form-floating mb-0 nav-pills">
                    <Form.Control
                      type="name"
                      className="mb-0 pe-5 position-relative"
                      autoComplete="Name"
                      placeholder="Search"
                      value={contentStudioSearchContent}
                      onChange={handleContentStudioSearch}
                    />
                    <label htmlFor="floatingInput">Search</label>
                    <span className="position-absolute top-0 end-0 h-100 d-flex align-items-center justify-content-center pe-2">
                      {findIcon("Search", "outline", "22")}
                    </span>
                  </Form.Floating>
                </Col>

                {/* Listing section  */}
                <Col lg="12" className="row p-0 mx-0">
                  <div className="row row-cols-5 flex-row flex-wrap rounded-3 m-0 ps-0">
                    {contentStudioList?.length > 0 &&
                      contentStudioList?.map((item, idx) => (
                        <Col
                          className="mb-3 pe-0 position-relative"
                          onClick={(e) => {
                            handleContentStudioClick(item);
                          }}
                        >
                          <div
                            className={`positon-relative d-flex flex-column text-secondary border border-1 p-3 align-items-start justify-content-start rounded-3 ${
                              selectedContentStudio?.content_studio_id ==
                              item?.content_studio_id
                                ? "border border-0 border-dashed border-orange bg-soft-orange text-black"
                                : ""
                            }`}
                          >
                            <p className="iq-key-metrics mb-2">
                              {item?.content_studio_name
                                ? item?.content_studio_name
                                : ""}
                            </p>
                            <div className="d-flex w-100 gap-3 mt-3">
                              <span
                                className={`flex-1 d-flex align-items-center justify-content-center rounded-3 ${
                                  selectedContentStudio?.content_studio_id ==
                                  item?.content_studio_id
                                    ? "bg-primary text-white border-success"
                                    : "bg-soft-primary border-warning"
                                }`}
                              >
                                {selectedContentStudio?.content_studio_id ==
                                item?.content_studio_id
                                  ? "Selected"
                                  : "Select"}
                              </span>
                              <span
                                className={`flex-1 d-flex align-items-center justify-content-center rounded-3 ${
                                  selectedContentStudio?.content_studio_id ==
                                  item?.content_studio_id
                                    ? "bg-warning text-white border-success"
                                    : "bg-white border border-secodary"
                                }`}
                                onClick={(e) => openCheckFitModal()}
                              >
                                Check Fit
                              </span>
                            </div>
                          </div>
                        </Col>
                      ))}
                  </div>
                </Col>

                {!selectedContentStudio && (
                  <spam className="text-left text-danger">
                    Please select a Content Studio
                  </spam>
                )}

                {/* Pagination section */}
                <PaginationBar
                  gotoPage={onContentStudioPageChange}
                  onNextPage={() => onContentStudioNextPageClick()}
                  onPreviousPage={() => onContentStudioPreviousPageClick()}
                  totalPage={contentStudioListTotalCount}
                  pageIndex={contentStudioTableState.pageIndex}
                />
              </Row>
            </Card.Body>
          </Card>

          {/* Message Distribution Choices */}
          <Card>
            <Card.Body className="p-0">
              <Row>
                <Col lg={12}>
                  <h6 className="my-3 text-black">
                    Configure Message Distribution
                  </h6>
                  <div className="d-flex gap-3 mb-3">
                    <div class="d-flex align-items-start gap-3 w-100">
                      <Tab.Container>
                        <Nav
                          variant="tabs"
                          data-toggle="slider-tab"
                          role="tablist"
                          aria-orientation="vertical"
                          className="nav-pills d-flex flex-column nav-iconly gap-3"
                        >
                          <Nav.Item
                            role="presentation"
                            className="flex-grow-1"
                            onClick={(e) => setMessageDistribution("sendAll")}
                          >
                            <Nav.Link
                              eventKey="first"
                              data-bs-toggle="tab"
                              data-bs-target="#pills-homeA1"
                              type="button"
                              role="tab"
                              aria-controls="home"
                              aria-selected="true"
                              className={
                                messageDistribution == "sendAll"
                                  ? "nav-link d-flex justify-content-start flex-row w-100 bg-soft-success border border-success"
                                  : "nav-link d-flex justify-content-start flex-row w-100"
                              }
                            >
                              <span
                                className={
                                  messageDistribution == "sendAll"
                                    ? "text-success"
                                    : ""
                                }
                              >
                                Send one message to all
                              </span>
                              <span
                                className={
                                  messageDistribution == "sendAll"
                                    ? "ms-auto text-success fw-semibold"
                                    : "ms-auto fw-semibold"
                                }
                              >
                                {findIcon("Arrow Right", "outline", "24")}
                              </span>
                            </Nav.Link>
                          </Nav.Item>

                          <Nav.Item
                            role="presentation"
                            className="flex-grow-1"
                            onClick={(e) => setMessageDistribution("abTest")}
                          >
                            <Nav.Link
                              eventKey="second"
                              data-bs-toggle="tab"
                              data-bs-target="#pills-homeA2"
                              type="button"
                              role="tab"
                              aria-controls="home"
                              aria-selected="true"
                              className={
                                messageDistribution == "abTest"
                                  ? "nav-link d-flex justify-content-start flex-row w-100 bg-soft-success border border-success"
                                  : "nav-link d-flex justify-content-start flex-row w-100"
                              }
                            >
                              <span
                                className={
                                  messageDistribution == "abTest"
                                    ? "text-success"
                                    : ""
                                }
                              >
                                Setup A/B Test to control group
                              </span>
                              <span
                                className={
                                  messageDistribution == "abTest"
                                    ? "ms-auto text-success fw-semibold"
                                    : "ms-auto fw-semibold"
                                }
                              >
                                {findIcon("Arrow Right", "outline", "24")}
                              </span>
                            </Nav.Link>
                          </Nav.Item>

                          <Nav.Item
                            role="presentation"
                            className="flex-grow-1"
                            onClick={(e) =>
                              setMessageDistribution("splitDelivery")
                            }
                          >
                            <Nav.Link
                              eventKey="third"
                              data-bs-toggle="tab"
                              data-bs-target="#pills-homeA2"
                              type="button"
                              role="tab"
                              aria-controls="home"
                              aria-selected="true"
                              className={
                                messageDistribution == "splitDelivery"
                                  ? "nav-link d-flex justify-content-start flex-row w-100 bg-soft-success border border-success"
                                  : "nav-link d-flex justify-content-start flex-row w-100"
                              }
                            >
                              <span
                                className={
                                  messageDistribution == "splitDelivery"
                                    ? "text-success"
                                    : ""
                                }
                              >
                                Split delivery to Fixed Ratio
                              </span>
                              <span
                                className={
                                  messageDistribution == "splitDelivery"
                                    ? "ms-auto text-success fw-semibold"
                                    : "ms-auto fw-semibold"
                                }
                              >
                                {findIcon("Arrow Right", "outline", "24")}
                              </span>
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>

                        <Tab.Content className="flex-fill">
                          <Tab.Pane
                            eventKey="second"
                            variant=" fade show active"
                            id="pills-homeA1"
                            role="tabpanel"
                            aria-labelledby="pills-home-tab1"
                          >
                            <div className="d-flex justify-content-between p-3 align-items-center">
                              <Col lg="3" className="mb-3">
                                <Form.Group>
                                  <Form.Label className="form-label">
                                    Setup Control group
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Setup Control group"
                                    name="setupControlGroup"
                                    value={
                                      messageDistributionABTestData.controlGroup
                                    }
                                    onChange={(e) =>
                                      setMessageDistributionABTestData({
                                        ...messageDistributionABTestData,
                                        controlGroup: e.target.value,
                                      })
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <span className="text-secondary fs-6">
                                {findIcon("Clock Circle", "outline", "24")}{" "}
                                Setup Timing
                              </span>
                              <span>
                                <Form.Floating className="custom-form-floating custom-form-floating-sm form-group m-0">
                                  <Form.Control
                                    type="datetime-local"
                                    id="exampleInputdatetime"
                                    defaultValue="2019-12-19T13:45:00"
                                    value={
                                      messageDistributionABTestData.setupTiming
                                    }
                                    onChange={(e) => handleABTestTiming(e)}
                                  />
                                </Form.Floating>
                              </span>
                            </div>
                            <div className="d-flex flex-row gap-3 p-3">
                              <div className="bg-soft-info p-3 rounded-3 flex-1">
                                <Form.Check className="">
                                  <FormCheck.Input
                                    type="radio"
                                    name="radios"
                                    className=""
                                    id="exampleRadio2"
                                    value="instant"
                                    onChange={(e) => handleABTestSendType(e)}
                                  />
                                  <FormCheck.Label
                                    className=""
                                    htmlFor="exampleRadio1"
                                  >
                                    Send Now
                                  </FormCheck.Label>
                                </Form.Check>
                              </div>
                              <div className="bg-soft-info p-3 rounded-3 flex-1">
                                <Form.Check className="">
                                  <FormCheck.Input
                                    type="radio"
                                    name="radios"
                                    className=""
                                    id="exampleRadio2"
                                    value="schedule"
                                    onChange={(e) => handleABTestSendType(e)}
                                  />
                                  <FormCheck.Label
                                    className=""
                                    htmlFor="exampleRadio2"
                                  >
                                    Schedule for Later
                                  </FormCheck.Label>
                                </Form.Check>
                              </div>
                              <div className="bg-soft-info p-3 rounded-3 flex-1">
                                <Form.Check className="">
                                  <FormCheck.Input
                                    type="radio"
                                    name="radios"
                                    className=""
                                    id="exampleRadio2"
                                    value="prefered"
                                    onChange={(e) => handleABTestSendType(e)}
                                  />
                                  <FormCheck.Label
                                    className=""
                                    htmlFor="exampleRadio2"
                                  >
                                    Preferred Day & Time
                                  </FormCheck.Label>
                                </Form.Check>
                              </div>
                            </div>
                          </Tab.Pane>

                          <Tab.Pane
                            eventKey="third"
                            variant=" fade show active"
                            id="pills-homeA1"
                            role="tabpanel"
                            aria-labelledby="pills-home-tab1"
                          >
                            <span className="text-secondary fs-6">
                              Split by Ratio
                            </span>
                            <div className="d-flex p-3 align-items-center justify-content-between">
                              <div>
                                <h6 className="text-secondary fs-6 ">
                                  {0 +
                                    messageDistributionSplitDeliveryData?.splitRatio}
                                  %
                                </h6>
                              </div>
                              <Form.Range
                                className="px-2 align-self-center flex-grow-1"
                                type="range"
                                min="0"
                                max="100"
                                step="1"
                                // value={messageDistributionABTestData.splitRatio}
                                onChange={(e) => handleSpiteRange(e)}
                              />
                              <div>
                                <h6 className="text-secondary fs-6">
                                  {100 -
                                    messageDistributionSplitDeliveryData?.splitRatio}
                                  %
                                </h6>
                              </div>
                            </div>

                            <div className="d-flex flex-row gap-3 p-3">
                              <div className="bg-soft-info p-3 rounded-3 flex-1">
                                <Form.Check className="">
                                  <FormCheck.Input
                                    type="radio"
                                    name="radios"
                                    className=""
                                    id="exampleRadio2"
                                    value="state"
                                    onChange={(e) => handleSplitUserType(e)}
                                  />
                                  <FormCheck.Label
                                    className=""
                                    htmlFor="exampleRadio1"
                                  >
                                    State
                                  </FormCheck.Label>
                                </Form.Check>
                              </div>
                              <div className="bg-soft-info p-3 rounded-3 flex-1">
                                <Form.Check className="">
                                  <FormCheck.Input
                                    type="radio"
                                    name="radios"
                                    className=""
                                    id="exampleRadio2"
                                    value="speciality"
                                    onChange={(e) => handleSplitUserType(e)}
                                  />
                                  <FormCheck.Label
                                    className=""
                                    htmlFor="exampleRadio2"
                                  >
                                    Speciality
                                  </FormCheck.Label>
                                </Form.Check>
                              </div>
                              <div className="bg-soft-info p-3 rounded-3 flex-1">
                                <Form.Check className="">
                                  <FormCheck.Input
                                    type="radio"
                                    name="radios"
                                    className=""
                                    id="exampleRadio2"
                                    value="division"
                                    onChange={(e) => handleSplitUserType(e)}
                                  />
                                  <FormCheck.Label
                                    className=""
                                    htmlFor="exampleRadio2"
                                  >
                                    Division
                                  </FormCheck.Label>
                                </Form.Check>
                              </div>
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </div>
                  {!messageDistribution && (
                    <spam className="text-left text-danger">
                      Please select Message distribution type
                    </spam>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>

          {/* Template preview section  */}
          <Card className="p-0">
            {/* template content preview section */}
            <Card.Header>
              <Col className="col-12">
                <div className="bg-soft-primary text-muted h-100 p-3 rounded-4 border border-5 border-white d-flex flex-column">
                  <Row className="align-items-center justify-content-center flex-column py-3">
                    <div className="col-12">
                      <Tab.Container defaultActiveKey="first">
                        {/* top toggle section  */}
                        <Row className="m-0">
                          <div className="col-4 mx-auto">
                            <Nav
                              variant="tabs"
                              data-toggle="slider-tab"
                              role="tablist"
                              className="w-100 bg-transparent d-flex flex-row gap-2 mb-3"
                            >
                              {/* sms */}
                              {contentStudioTemplate &&
                                contentStudioTemplate.sms && (
                                  <Nav.Item
                                    role="presentation"
                                    className="flex-grow-1"
                                  >
                                    <Nav.Link
                                      eventKey="first"
                                      data-bs-toggle="tab"
                                      data-bs-target="#pills-home1"
                                      type="button"
                                      role="tab"
                                      aria-controls="home"
                                      aria-selected="true"
                                      className="d-flex align-items-center justify-content-center border border-primary border-1 border-dashed px-2 fs-7"
                                    >
                                      SMS
                                    </Nav.Link>
                                  </Nav.Item>
                                )}

                              {/* whatsapp */}
                              {contentStudioTemplate &&
                                contentStudioTemplate.whatsapp && (
                                  <Nav.Item
                                    role="presentation"
                                    className="flex-grow-1"
                                  >
                                    <Nav.Link
                                      eventKey="second"
                                      data-bs-toggle="tab"
                                      data-bs-target="#pills-profile1"
                                      type="button"
                                      role="tab"
                                      aria-controls="profile"
                                      aria-selected="false"
                                      className="d-flex align-items-center justify-content-center border border-primary border-1 border-dashed px-2 fs-7"
                                    >
                                      WhatsApp
                                    </Nav.Link>
                                  </Nav.Item>
                                )}

                              {/* email */}
                              {contentStudioTemplate &&
                                contentStudioTemplate.email && (
                                  <Nav.Item
                                    role="presentation"
                                    className="flex-grow-1"
                                  >
                                    <Nav.Link
                                      eventKey="third"
                                      id="contact-tab"
                                      data-bs-toggle="tab"
                                      data-bs-target="#pills-contact1"
                                      type="button"
                                      role="tab"
                                      aria-controls="contact"
                                      aria-selected="false"
                                      className="d-flex align-items-center justify-content-center border border-primary border-1 border-dashed px-2 fs-7"
                                    >
                                      E-Mail
                                    </Nav.Link>
                                  </Nav.Item>
                                )}
                            </Nav>
                          </div>
                        </Row>
                        {contentStudioTemplate && (
                          <Tab.Content className="flex-fill">
                            {/* sms tab content  */}
                            {contentStudioTemplate &&
                              contentStudioTemplate.sms && (
                                <Tab.Pane
                                  eventKey="first"
                                  variant=" fade show active"
                                  id="pills-home1"
                                  role="tabpanel"
                                  aria-labelledby="pills-home-tab1"
                                >
                                  <Row>
                                    <div className="col-lg-4 mx-auto">
                                      <div className="position-relative flex-fill">
                                        <img
                                          src={mobile}
                                          alt="mobile"
                                          className="h-auto w-100 d-block position-relative z-0"
                                        />
                                        <div className="position-absolute top-0 w-100 h-auto p-2 z-1">
                                          <div className="h-100 rounded-5 p-4 mobile-preview position-relative">
                                            {contentStudioTemplate &&
                                              contentStudioTemplate.sms.length >
                                                0 && (
                                                <span className="d-block position-relative px-3 py-2 rounded-3 mt-4 text-dark fs-7 lh-base shadow-lg shadow bg-white">
                                                  {
                                                    contentStudioTemplate
                                                      ?.sms[0].sms_content
                                                  }
                                                </span>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Row>
                                </Tab.Pane>
                              )}
                            {/* whatsapp tab content */}
                            {contentStudioTemplate &&
                              contentStudioTemplate.whatsapp && (
                                <Tab.Pane
                                  eventKey="second"
                                  className="fade"
                                  id="pills-profile1"
                                  role="tabpanel"
                                  aria-labelledby="pills-profile-tab1"
                                >
                                  <Row>
                                    <div className="col-lg-4 mx-auto">
                                      <div className="position-relative flex-fill">
                                        <img
                                          src={mobileWhatsapp}
                                          alt="mobile whatsapp"
                                          className="h-auto w-100 d-block position-relative z-0"
                                        />
                                        <div className="position-absolute top-0 w-100 h-auto p-2 z-1">
                                          <div className="h-100 rounded-5 p-4 whatsapp-preview position-relative">
                                            {contentStudioTemplate &&
                                              contentStudioTemplate.whatsapp
                                                .length > 0 && (
                                                <span className="d-block position-relative px-3 py-2 rounded-3 mt-4 text-dark fs-7 lh-base shadow-lg shadow bg-white">
                                                  {
                                                    contentStudioTemplate
                                                      ?.whatsapp[0]
                                                      .whatsapp_content
                                                  }
                                                </span>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Row>
                                </Tab.Pane>
                              )}

                            {/* email tab content */}
                            {contentStudioTemplate &&
                              contentStudioTemplate.email && (
                                <Tab.Pane
                                  eventKey="third"
                                  className="fade h-100"
                                  id="pills-contact1"
                                  role="tabpanel"
                                  aria-labelledby="pills-contact-tab1"
                                >
                                  <div className="position-relative h-100 overflow-scroll bg-white border border-1 border-dotted rounded-3 p-3 border-secondary text-center">
                                    {/* <ReactEmailEditor
                                      ref={emailEditorRef}
                                      onReady={onReady}
                                      onLoad={() =>
                                        onLoad(
                                          contentStudioTemplate?.email[0]
                                            .email_json
                                            ? contentStudioTemplate?.email[0]
                                                .email_json
                                            : sample
                                        )
                                      }
                                    /> */}
                                  </div>
                                </Tab.Pane>
                              )}
                          </Tab.Content>
                        )}
                      </Tab.Container>
                    </div>
                  </Row>
                </div>
              </Col>
            </Card.Header>

            {/* save_draft & send_for_approval button */}
            <Card.Body className="py-0">
              <div className="d-flex align-items-center justify-content-end gap-3">
                <Button
                  className="my-4 w-fit-cotent w-auto py-3 rounded-3 border border-1"
                  variant={`white`}
                  type="submit"
                >
                  <span className="pe-3 text-secondary fw-semibold">
                    {findIcon("Save", "outline", "24")}
                  </span>
                  Save as Draft
                </Button>
                <Button
                  className="my-4 w-fit-cotent w-auto py-3 rounded-3"
                  variant={`success`}
                  type="submit"
                  onClick={() => handleCreateCampaign()}
                >
                  {params.id ? "Update Campaign" : "Send Campaign for Approval"}
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Row>
      </Container>

      {/* Modal to check fit content_studio */}
      {fitContentShow && (
        <Modal
          show={fitContentShow}
          onHide={() => setFitContentShow(false)}
          backdrop="static"
          keyboard={false}
          size="sm"
          centered
        >
          <Modal.Body>
            <Row>
              <Col lg="12" className="mb-2">
                <h6 className="my-3 text-black text-center">
                  You have already sent this Content to {checkFitCount}% of this
                  segment
                </h6>
              </Col>
            </Row>
            <Row lg="12" className="justify-content-center">
              <Col lg="3">
                <Button
                  variant="primary"
                  // onClick={() => setFitContentShow(false)}
                >
                  Yes
                </Button>
              </Col>
              <Col lg="3">
                <Button
                  variant="secondary"
                  onClick={() => setFitContentShow(false)}
                >
                  No
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      )}
    </Fragment>
  );
};

export default CreateCampaign;
