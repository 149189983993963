import { useState, memo, Fragment } from "react";

//react-bootstrap
import {
  Row,
  Col,
  Image,
  Form,
  Nav,
  Dropdown,
  Tab,
  Card,
} from "react-bootstrap";

const CohotHistory = () => {
  return (
    <Row>
      <Col lg="12">
        <Card>
          <Card.Body>
            <div className="iq-timeline m-0 d-flex align-items-center justify-content-between position-relative">
              <ul className="list-inline p-0 m-0 w-100">
                <li>
                  <div className="time">
                    <span>1st Jan 2023</span>
                  </div>
                  <div className="content">
                    <div className="timeline-dots new-timeline-dots"></div>
                    <h6 className="mb-1">Read Medwiki</h6>
                    {/* <div className="d-inline-block w-100">
                      <p>Created by David Janathan Aaron</p>
                    </div> */}
                  </div>
                  <div className="content">
                    <div className="timeline-dots new-timeline-dots"></div>
                    <h6 className="mb-1">Send Engagement for Live CME</h6>
                    <div className="d-inline-block w-100">
                      <p>Sarthaki Banerjee</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="time bg-success">
                    <span>2nd Jan 2023</span>
                  </div>
                  <div className="content">
                    <div className="timeline-dots new-timeline-dots border-success"></div>
                    <h6 className="mb-1">Join Live CME</h6>
                  </div>
                  <div className="content">
                    <div className="timeline-dots new-timeline-dots border-success"></div>
                    <h6 className="mb-1">Read Epaper</h6>
                  </div>
                </li>
                <li>
                  <div className="time">
                    <span>3rd Jan 2023</span>
                  </div>
                  <div className="content">
                    <div className="timeline-dots border-primary"></div>
                    <h6 className="mb-1">Send MasterCast Engagement</h6>
                    <div className="d-inline-block w-100">
                      <p>Pranabesh kumar Nandi</p>
                      <p>
                        "Bonbon macaroon jelly beans gummi bears jelly lollipop
                        apple There are many variations of passages of Lorem
                        Ipsum available"
                      </p>
                    </div>
                  </div>
                  <div className="content">
                    <div className="timeline-dots border-primary"></div>
                    <h6 className="mb-1">Send MedWiki Engagement</h6>
                    <div className="d-inline-block w-100">
                      <p>Marketing and Sales</p>
                    </div>
                  </div>
                  <div className="content">
                    <div className="timeline-dots border-primary"></div>
                    {/* <h6 className="mb-1">Accepted by Vendor</h6>  */}
                    <div className="d-inline-block w-100">
                      <p>Nice Marketing and Sales</p>
                      <p>
                        "Bonbon macaroon jelly beans gummi bears jelly lollipop
                        apple There are many variations of passages of Lorem
                        Ipsum available"
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="time bg-success">
                    <span>6th Jan 2023</span>
                  </div>
                  <div className="content">
                    <div className="timeline-dots border-success"></div>
                    <h6 className="mb-1">Nice Marketing and Sales</h6>
                    <div className="d-inline-block w-100">
                      <p>Added video Files for Approval</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="time">
                    <span>8th Jan 2020</span>
                  </div>
                  <div className="content">
                    <div className="timeline-dots border-primary"></div>
                    <h6 className="mb-1">Commented By Complience</h6>
                    <div className="d-inline-block w-100">
                      <p>
                        "Bonbon macaroon jelly beans gummi bears jelly lollipop
                        apple There are many variations of passages of Lorem
                        Ipsum available, but the majority have suffered
                        alteration in some form, by injected humour, or
                        randomised words which don't look even slightly
                        believable."
                      </p>
                    </div>
                  </div>
                  <div className="content">
                    <div className="timeline-dots border-primary"></div>
                    <h6 className="mb-1">Commented By Marketing</h6>
                    <div className="d-inline-block w-100">
                      <p>
                        "Bonbon macaroon jelly beans gummi bears jelly lollipop
                        apple There are many variations of passages of Lorem
                        Ipsum available, but the majority have suffered
                        alteration in some form, by injected humour, or
                        randomised words which don't look even slightly
                        believable."
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="time bg-success">
                    <span>9th Jan 2020</span>
                  </div>
                  <div className="content">
                    <div className="timeline-dots border-primary"></div>
                    <h6 className="mb-1">Commented By Medical</h6>
                    <div className="d-inline-block w-100">
                      <p>
                        "Bonbon macaroon jelly beans gummi bears jelly lollipop
                        apple There are many variations of passages of Lorem
                        Ipsum available."
                      </p>
                    </div>
                  </div>
                  <div className="content">
                    <div className="timeline-dots new-timeline-dots"></div>
                    <h6 className="mb-1">
                      Comments Approved by Marketing Manager
                    </h6>
                    <div className="d-inline-block w-100">
                      <p>Sarthaki Banerjee</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="time">
                    <span>6th Jan 2023</span>
                  </div>
                  <div className="content">
                    <div className="timeline-dots border-success"></div>
                    <h6 className="mb-1">Nice Marketing and Sales</h6>
                    <div className="d-inline-block w-100">
                      <p>Resubmit video Files for Approval</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default CohotHistory;
