import Skeleton from "../../components/skeleton";
import { Fragment } from "react";

//Componets

import { OverlayTrigger, Card, Tooltip } from "react-bootstrap";

const ContentCardLoader = () => {
  let tableData = [...Array(8).keys()].map((_el) => _el + 1);
  return (
    <Fragment>
      {tableData?.map((_td, indx) => {
        return (
          <>
            <div class="card position-relative overflow-hidden">
              <div class="card-body p-3">
                <div class="p-3 d-flex justify-content-center align-items-center rounded bg-light">
                  <Skeleton variant="rectangular" width={60} height={60} />
                </div>
                <div class="mt-3">
                  <div class="d-flex align-items-start mb-2 gap-2">
                    <div class="avatar-36 bg-soft-primary lh-1 d-flex align-items-center justify-content-center flex-shrink-0">
                      <Skeleton variant="rectangular" width={24} height={24} />
                    </div>
                    <div class="flex-grow-1 fs-6 text-truncate">
                      <h5 class="text-dark mb-1 small text-truncate">
                        <Skeleton variant="text" width={100} height={20} />
                      </h5>
                      <Skeleton variant="text" width={50} height={20} />
                    </div>
                  </div>
                  <div class="d-flex justify-content-between align-items-center mt-3">
                    <div class="d-flex justify-content-end align-items-center gap-2 ms-auto">
                      <button
                        type="button"
                        class="p-0 icon-32 d-flex align-items-center justify-content-center rounded-circle border-0"
                      >
                        <Skeleton variant="circular" width={25} height={25} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </Fragment>
  );
};

// CampaignStatsCard.displayName = "CampaignStatsCard";
export default ContentCardLoader;
