import React from "react";
import { Row } from "react-bootstrap";
import ReviewApproval from "../components/review-approval";
import { getService } from "../../../../api/services/get-services";
import { EDIT_ACTION_GET } from "../../../../api/endpoints/action-endpoints";
import { useNavigate, useParams } from "react-router-dom";
import SmsWpCreator from "../../template-manager/components/sms-wp-creator";
import {
  EMAIL_TEMPLATE_UPDATE,
  SMS_TEMPLATE_UPDATE,
  WHATSAPP_TEMPLATE_UPDATE,
} from "../../../../api/endpoints/template-management-endpoints";
import postService from "../../../../api/services/post-service";
import EmailTemplateCreator from "../../template-manager/components/email-template-creator";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import TemplateReviewApprovalLoader from "../../../../skeleton/action/template-review-approval-loader";
import useBackdrop from "../../../../components/custom-hooks/use-backdrop";

const ActionDetails = () => {
  const { hideBackdrop } = useBackdrop();
  const [actionData, setActionData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const { redirectTo } = useRedirect();
  const navigate = useNavigate();
  // const [presetValues, setPresetValues] = React.useState({});
  const { type, id: actionId } = useParams();
  async function getData() {
    let response = await getService(EDIT_ACTION_GET + actionId);
    if (response) {
      if (response.data.success) {
        let actionEditResponse = response.data?.data;

        setActionData(actionEditResponse);
        setLoading(false);
      }
    }
  }

  React.useEffect(() => {
    getData();
  }, []);

  const templateMedium = actionData?.origin?.sms_id
    ? "sms"
    : actionData?.origin?.whatsapp_id
    ? "whatsapp"
    : "email";

  const templateId =
    actionData?.origin?.sms_id ||
    actionData?.origin?.whatsapp_id ||
    actionData?.origin?.email_id ||
    "";

  //construct presetValues from origin that is available from action
  const presetValuesSms = actionData?.origin?.sms_id
    ? {
        template_name: actionData?.origin?.sms_name,
        template_category: actionData?.origin?.sms_category_id?.category_id,
        template_type: actionData?.origin.sms_template_type.id,
        sender: actionData?.origin.sms_sender,
        vendor_id: actionData?.origin.sms_dlt_id,
      }
    : {};

  const presetValuesWhatsapp = actionData?.origin?.whatsapp_id
    ? {
        template_name: actionData?.origin.whatsapp_name,
        template_category: actionData?.origin?.whatsapp_category_id.category_id,
        template_type: actionData?.origin.whatsapp_template_type.id,
        sender: actionData?.origin.whatsapp_sender,
        vendor_id: actionData?.origin.whatsapp_dlt_id,
      }
    : {};

  const presetValuesEmail = actionData?.origin?.email_id
    ? {
        category_id: actionData?.origin?.email_category_id?.category_id,
        email_template_type: actionData?.origin?.email_template_type?.id,
        name: actionData?.origin?.email_name,
        email_subject: actionData?.origin?.email_subject,
        from_name: actionData?.origin?.email_from_name,
        from_email: actionData?.origin?.email_from_email,
        status: actionData?.origin?.status?.id,
      }
    : {};

  const smsPostObj = {
    sms_name: "",
    sms_content: "",
    sms_category_id: "",
    sms_template_type: "",
    sms_sender: "",
    sms_dlt_id: "",
    //need to make dynamic
    sms_duedate: "2023-06-09",
    sms_priority_id: 1,
    sms_workflow_id: 5,
  };

  const whatsappPostObj = {
    whatsapp_name: "",
    whatsapp_content: "",
    whatsapp_category_id: "",
    whatsapp_template_type: "",
    whatsapp_sender: "",
    whatsapp_dlt_id: "",
    //need to make dynamic
    whatsapp_duedate: "2023-06-09",
    whatsapp_priority_id: 1,
    whatsapp_workflow_id: 5,
  };

  const handleSubmitForm = async (data) => {
    console.log("data", data);
    let postObj = {};
    if (templateMedium === "sms") {
      postObj = {
        ...smsPostObj,
        sms_name: data.template_name,
        sms_content: data.template_content,
        sms_category_id: data.template_category,
        sms_template_type: data.template_type,
        sms_sender: data.sender,
        sms_dlt_id: data.vendor_id,
      };
      console.log("smsPostObj", smsPostObj);
    } else if (templateMedium === "whatsapp") {
      postObj = {
        ...whatsappPostObj,
        whatsapp_name: data.template_name,
        whatsapp_content: data.template_content,
        whatsapp_category_id: data.template_category,
        whatsapp_template_type: data.template_type,
        whatsapp_sender: data.sender,
        whatsapp_dlt_id: data.vendor_id,
      };

      console.log("whatsappPostObj", whatsappPostObj);
    } else if (templateMedium === "email") {
      postObj = {
        ...data,
      };

      console.log("emailPostObj", postObj);
    }

    let postUrl = "";
    if (templateMedium === "sms") {
      postUrl = SMS_TEMPLATE_UPDATE + "/" + templateId;
    } else if (templateMedium === "whatsapp") {
      postUrl = WHATSAPP_TEMPLATE_UPDATE + "/" + templateId;
    } else if (templateMedium === "email") {
      postUrl = EMAIL_TEMPLATE_UPDATE + "/" + templateId;
    }

    let response = await postService(postUrl, postObj);
    console.log("response", response);

    hideBackdrop();

    redirectTo("action/all-action");
  };

  if (loading || !actionData) {
    return <TemplateReviewApprovalLoader />;
  }

  return (
    <Row>
      {(type === "template-approval" || type === "template-edit") &&
      (templateMedium === "sms" || templateMedium === "whatsapp") ? (
        <SmsWpCreator
          actionType={type}
          actionId={actionId}
          handleCloseClick={() => {
            navigate(-1);
          }}
          id={templateId}
          onSubmitForm={(data) => {
            handleSubmitForm(data);
          }}
          presetValues={
            templateMedium === "sms"
              ? presetValuesSms
              : templateMedium === "whatsapp"
              ? presetValuesWhatsapp
              : presetValuesEmail
          }
          presetContent={
            actionData?.origin?.sms_content ||
            actionData?.origin?.whatsapp_content ||
            actionData?.origin?.email_content
          }
          isEdit={true}
          type={templateMedium}
          comments={actionData?.comments}
          isApprovalTask={type === "template-approval"}
        />
      ) : (type === "template-approval" || type === "template-edit") &&
        templateMedium === "email" ? (
        <EmailTemplateCreator
          handleCloseClick={() => {
            navigate(-1);
          }}
          id={templateId}
          onSubmitForm={(data) => {
            handleSubmitForm(data);
          }}
          presetValues={presetValuesEmail}
          presetContent={actionData?.origin?.email_content}
          key={templateId}
          comments={actionData?.comments}
          actionType={type}
          actionId={actionId}
          isApprovalTask={type === "template-approval"}
        />
      ) : type === "template-second-step-approval" ? (
        <ReviewApproval
          actionEditData={actionData}
          actionId={actionId}
          actionType={type}
          key={actionId}
        />
      ) : null}
    </Row>
  );
};

export default ActionDetails;
