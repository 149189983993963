import Default from "../../../../layouts/dashboard/default";
import CohotHistory from "../pages/hcp-profile-cohotHistory";
import HCP_Profile from "../pages/hcp-profile";
import HCP_ProfileDetails from "../pages/hcp-profile-details";
import HCP_ProfileListing from "../pages/hcp-profile-listing";
import HCPProfileListWithType from "../pages/hcp-profile-listing-with-type";
import AllPersonaListing from "../pages/all-persona-listing";
import AllPersonaDetails from "../pages/all-persona-details";
import HcpMetaProfile from "../pages/hcp-meta-profile";
import HcpDashboard from "../pages/hcp-dashboard";
//
export const HCP_ProfileRouter = [
  {
    path: "hcp-profile",
    element: <Default />,
    children: [
      {
        path: "listing",
        element: <HCP_ProfileListing />,
      },
      {
        path: "all/:type",
        element: <HCPProfileListWithType />,
      },
      {
        path: "profile",
        element: <HCP_Profile />,
      },
      {
        path: "details/:id",
        element: <HCP_ProfileDetails />,
      },
      {
        path: "cohothistory",
        element: <CohotHistory />,
      },
      {
        path: "meta-profile/:id",
        element: <HcpMetaProfile />,
      },
      {
        path: "all-persona-listing",
        element: <AllPersonaListing />,
      },
      {
        path: "all-persona-details/:name",
        element: <AllPersonaDetails />,
      },
      {
        path: "dashboard",
        element: <HcpDashboard />,
      },
    ],
  },
];
