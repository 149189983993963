import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Row, Form, Col } from "react-bootstrap";
import Select from "react-select";

export default function UserWhoActiveForm({
  onFormDataChange,
  isEditMode = false,
  editData = "",
}) {
  const initialValues = {
    name: "",
    clause: "",
    value: "",
    operator: "and",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string(),
    clause: Yup.string(),
    value: Yup.string(),
  });
  //using useFormik hook

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    handleReset,
    setValues,
    setFieldValue,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      console.log(values);
    },
  });
  useEffect(() => {
    console.log(values.name, values.clause, values.value);
    if (values.name && values.clause && values.value) {
      console.log("form data change!");
      onFormDataChange(values);
    }
  }, [values]);

  useEffect(() => {
    console.log(isEditMode, ":user who active:", editData);
    if (isEditMode && editData) {
      setValues({
        name: editData.name,
        clause: editData.clause,
        value: editData.value,
      });
    }
  }, [editData, isEditMode]);

  const options = [
    {
      label: "Select an Option",
      value: "",
    },
    {
      label: "3 Days",
      value: 3,
    },
    {
      label: "7 Days",
      value: 7,
    },
    {
      label: "15 Days",
      value: 15,
    },
    {
      label: "30 Days",
      value: 30,
    },
  ];

  const clauseOptions = [
    {
      label: "Between",
      value: "between",
    },
    {
      label: "Not Between",
      value: "not_between",
    },
  ];

  const activeOptions = [
    {
      label: "User Last Active",
      value: "activity_date_time",
    },
    // {
    //   label: "User Not Active",
    //   value: "not_active",
    // },
  ];
  return (
    <div className="mb-4">
      <h6 className="mb-3">User Who Are Active In:</h6>
      <Row className="mt-n4">
        <Col lg={4} className="mt-4">
          <Select
            onChange={(e) => setFieldValue("name", e.value)}
            options={activeOptions}
          />
        </Col>
        <Col lg={4} className="mt-4">
          <Select
            onChange={(e) => setFieldValue("clause", e.value)}
            options={clauseOptions}
          />
        </Col>
        <Col lg={4} className="mt-4">
          <Select
            onChange={(e) => setFieldValue("value", e.value)}
            options={options}
          />
        </Col>
      </Row>
    </div>
  );
}
