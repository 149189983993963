import { useState, memo, Fragment } from "react";
import {
  Row,
  Col,
  Image,
  Form,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { Card } from "react-bootstrap";
import FsLightbox from "fslightbox-react";
import { findIcon } from "../../../dashboard/icons";

export default function TemplateSuggestionCard({
  type = "",
  data = "",
  click,
  selectedSms,
}) {
  return (
    <>
      {type == "content-studio" ? (
        <Card
          onClick={() => click(data)}
          className={`shadow-none border transition ${
            selectedSms.id === data.id ? "bg-soft-primary border-primary" : ""
          }`}
          style={{ cursor: "pointer" }}
        >
          <Card.Body className="p-3 d-flex">
            <h6 className="mb-0 flex-grow-1 d-flex align-items-center">
              {data.name}
            </h6>
            <Button
              variant={`${
                selectedSms.id === data.id ? "primary" : "soft-primary"
              }`}
              size="sm"
              className="rounded-circle btn-icon flex-shrink-0 ms-3"
            >
              <div className="btn-inner">
                {findIcon("Check", "outline", "16")}
              </div>
            </Button>
          </Card.Body>
        </Card>
      ) : (
        <>
          {data && (
            <Card
              onClick={() => click(data)}
              className={`shadow-none border transition ${
                selectedSms == data ? "bg-soft-primary border-primary" : ""
              }`}
              style={{ cursor: "pointer" }}
            >
              <Card.Body className="p-3 d-flex">
                <h6 className="mb-0 flex-grow-1 d-flex align-items-center">
                  {data}
                </h6>
                <Button
                  variant={`${
                    selectedSms == data ? "primary" : "soft-primary"
                  }`}
                  size="sm"
                  className="rounded-circle btn-icon flex-shrink-0 ms-3"
                >
                  <div className="btn-inner">
                    {findIcon("Check", "outline", "16")}
                  </div>
                </Button>
              </Card.Body>
            </Card>
          )}
        </>
      )}
    </>
  );
}
