import React, { memo, useRef, useState } from "react";
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";

const CreateTelegramTemplate = () => {


  const [cameFrom, setCameFrom] = useState('view')

  return (
    // <div>CreateTelegramTemplate</div>
    <>
      <div className='d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3'>
        <div className='d-flex flex-column'>
          <h3 className='m-0'>{cameFrom == 'edit' && "Template: Edit Telegram"}{cameFrom == 'view' && "Template: View Telegram"} </h3>
        </div>
      </div>
      <Row>
        <Col lg='12'>
          <Card>
            <Card.Body>
              <Form>
                <Row>
                  <Col lg='12'>
                    {cameFrom == 'edit' &&
                      <>
                        <Form.Floating className='col-12 mb-4 custom-form-floating'>
                          <Form.Select className='' id='floatingInput1'>
                            <option value=''>Bulk Engagement</option>

                            <option value='text'>Bulk Engagement</option>

                            <option value='video'>Bulk Engagement</option>

                            <option value='audio'>Bulk Engagement</option>
                          </Form.Select>
                          <Form.Label htmlFor='floatingInput'>
                            Category
                          </Form.Label>
                        </Form.Floating>

                        <Form.Floating className='col-12 mb-4 custom-form-floating'>
                          <Form.Select className='' id='floatingInput1'>
                            <option value='text'>Promotional</option>

                            <option value='video'>Transactional</option>
                          </Form.Select>
                          <Form.Label htmlFor='floatingInput'>
                            Type
                          </Form.Label>
                        </Form.Floating>

                        <Form.Floating className='col-12 mb-4 custom-form-floating'>
                          <Form.Control
                            type='text'
                            className=''
                            id='floatingInput1'
                            autoComplete='Name'
                            placeholder='Name'
                          />
                          <Form.Label htmlFor='floatingInput'>
                            Name
                          </Form.Label>
                        </Form.Floating>

                        <Form.Floating className='col-12 mb-4 custom-form-floating'>
                          <Form.Control
                            type='text'
                            className=''
                            id='floatingInput1'
                            autoComplete='Flow/Template Id'
                            placeholder='Flow/Template Id'
                          />
                          <Form.Label htmlFor='floatingInput'>
                            Flow/Template Id
                          </Form.Label>
                        </Form.Floating>

                        <Form.Floating className='col-12 mb-4 custom-form-floating'>
                          <Form.Control
                            type='text'
                            className=''
                            id='floatingInput1'
                            autoComplete='DLT ID'
                            placeholder='DLT ID'
                          />
                          <Form.Label htmlFor='floatingInput'>
                            DLT ID
                          </Form.Label>
                        </Form.Floating>

                        <Form.Floating className='col-12 mb-4 custom-form-floating'>
                          <Form.Control
                            type='text'
                            className=''
                            id='floatingInput1'
                            autoComplete='Sender'
                            placeholder='Sender'
                          />
                          <Form.Label htmlFor='floatingInput'>
                            Sender
                          </Form.Label>
                        </Form.Floating>

                        <Form.Floating className='col-12 mb-4 custom-form-floating'>
                          <Form.Select className='' id='floatingInput1'>
                            <option value='text'>MedWiki</option>

                            <option value='video'>Digital CME</option>
                          </Form.Select>
                          <Form.Label htmlFor='floatingInput'>
                            Service type
                          </Form.Label>
                        </Form.Floating>

                        <Form.Floating className='col-12 mb-4 custom-form-floating'>
                          <Form.Control
                            type='textArea'
                            className=''
                            id='floatingInput1'
                            autoComplete='Content'
                            placeholder='Content'
                          />
                          <Form.Label htmlFor='floatingInput'>
                            Content
                          </Form.Label>
                        </Form.Floating>

                        <Form.Floating className='col-12 mb-4 custom-form-floating'>
                          <Form.Select className='' id='floatingInput1'>
                            <option value='text'>Enable</option>

                            <option value='video'>Disable</option>
                          </Form.Select>
                          <Form.Label htmlFor='floatingInput'>
                            Status
                          </Form.Label>
                        </Form.Floating>

                        <Form.Floating className='col-12 mb-4 custom-form-floating'>
                          <Form.Control
                            type='textArea'
                            className=''
                            id='floatingInput1'
                            autoComplete='Params'
                            placeholder='Params'
                          />
                          <Form.Label htmlFor='floatingInput'>
                            Params
                          </Form.Label>
                        </Form.Floating>
                      </>
                    }
                    {cameFrom == 'view' &&
                      <>
                        {/* <div className="table-responsive">
                          <ReactTable data={ViewData} columns={columns} />
                        </div> */}
                        <Table>
                          <tbody>
                            <tr>
                              <td>
                                <h6 className="mb-0">Category</h6>
                              </td>
                              <td className="text-left">Bulk Engagement</td>
                            </tr>
                            <tr>
                              <td>
                                <h6 className="mb-0">Type</h6>
                              </td>
                              <td className="text-left">Promotional</td>
                            </tr>
                            <tr>
                              <td>
                                <h6 className="mb-0">Name</h6>
                              </td>
                              <td className="text-left">SR_MAY8_TBRECUPD_01</td>
                            </tr>
                            <tr>
                              <td>
                                <h6 className="mb-0">Flow/Template Id</h6>
                              </td>
                              <td className="text-left">6458c155d6fc0572c63b6883</td>
                            </tr>
                            <tr>
                              <td>
                                <h6 className="mb-0">DLT ID</h6>
                              </td>
                              <td className="text-left">1107164726356426759</td>
                            </tr>
                            <tr>
                              <td>
                                <h6 className="mb-0">Sender</h6>
                              </td>
                              <td className="text-left">CIPLA</td>
                            </tr>
                            <tr>
                              <td>
                                <h6 className="mb-0">Service type</h6>
                              </td>
                              <td className="text-left">MedWiki</td>
                            </tr>
                            <tr>
                              <td>
                                <h6 className="mb-0">Status</h6>
                              </td>
                              <td className="text-left">Enable</td>
                            </tr>
                            <tr>
                              <td>
                                <h6 className="mb-0">Params</h6>
                              </td>
                              <td className="text-left">Recent Updates on TB Treatment Dear Doctor, Click here to register -CIPLA</td>
                            </tr>
                          </tbody>
                        </Table>
                      </>
                    }
                  </Col>
                  {cameFrom == 'edit' &&
                    <Col lg='12' className='mt-4 d-flex gap-2 justify-content-end' >
                      <a
                        className='btn btn-primary px-3 py-2'
                        type='submit'
                      >
                        Submit
                      </a>
                      <a
                        className='btn btn-secondary px-3 py-2'
                        type='submit'
                      >
                        Cancel
                      </a>
                    </Col>
                  }
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default CreateTelegramTemplate