import React, { memo, forwardRef } from "react";
import EmailEditor from "react-email-editor";

const ReactEmailEditor = forwardRef(
  ({ onReady, onLoad, style = {}, mergeTags }, ref) => {
    return (
      <div className="email-editor w-100">
        <EmailEditor
          projectId={1071}
          ref={ref}
          onReady={onReady}
          onLoad={onLoad}
          style={style}
          tools={{
            // features: {
            //   sendTestEmail: true,
            // },
            button: {
              enabled: true,
            },
            video: {
              enabled: true,
            },
            features: {
              textEditor: {
                tables: true,
              },
            },
          }}
          appearance={{
            // displayMode: "email",
            // customCSS: ["body {background-color: yellow;}"],
            // customJS: ["console.log('I am custom JS!');"],
            // theme: "dark",
            panels: {
              tools: {
                dock: "left",
              },
            },
          }}
          options={{
            mergeTags: mergeTags,
          }}
          // options={{
          //   // customCSS: [
          //   //   window.location.protocol +
          //   //     "//" +
          //   //     window.location.host +
          //   //     "/custom.css",
          //   // ],
          //   customJS: [
          //     // window.location.protocol + "//" + window.location.host + "/custom.js",
          //   ],
          // }}
        />
      </div>
    );
  }
);
export default memo(ReactEmailEditor);
