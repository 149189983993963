import React from "react";
import parse from "html-react-parser";

export default function HtmlParser({ html }) {
  const options = {
    replace: (domNode) => {
      if (domNode.attribs && domNode.attribs.class === "remove") {
        return <></>;
      }
    },
  };

  return parse(html, options);
}
