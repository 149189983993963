import { memo, Fragment, useState, useEffect, useRef } from "react";

// React-bootstrap
import { Dropdown, Image, Button, Form, Modal } from "react-bootstrap";

//Router
import { Link } from "react-router-dom";
//Component
import CustomToggle from "../../../../../components/dropdowns";
import Card from "../../../../../components/bootstrap/card";
//Masonry
import Masonry from "react-masonry-css";
import img6 from "../../../../../assets/modules/blog/images/new_template.svg";
import TemplateCard from "./component/template-card";
import useRedirect from "../../../../../components/custom-hooks/use-redirect";
import { findIcon } from "../../../../dashboard/icons";
import postService from "../../../../../api/services/post-service";
import { EMAIL_TEMPLATE_BANK_LIST } from "../../../../../api/endpoints/template-management-endpoints";
import GrapejsEditor from "../../../../../lib/email-editor/grapejs-editor";

import toast from "react-hot-toast";

const EmailTemplateBank = memo((props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [loading, setLoading] = useState(false);
  const [templateBankList, setTemplateBankList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [presetHTML, setPresetHTML] = useState(null);
  const [editor, setEditor] = useState(null);

  const { redirectTo } = useRedirect();

  useEffect(() => {
    getBankListing();
  }, []);

  const post_json = useRef({
    filter: [
      {
        status: {
          type: "eq",
          value: [1],
        },
      },
    ],
    pagination: {
      limit: 4,
      offset: 0,
    },
    sort: {
      bank_id: "desc",
    },
  });
  const getBankListing = async () => {
    try {
      setLoading(true);
      const response = await postService(
        EMAIL_TEMPLATE_BANK_LIST,
        post_json.current
      );
      // console.log("email template bank response ", response);
      // setLoading(false);

      if (response.data.data.rows.length > 0) {
        setTemplateBankList(response.data.data.rows);
        setTotalCount(response.data.data.totalCount);
        setLoading(false);
      }
      if (response.isError) {
        toast.error(response?.error);
      }
    } catch (error) {
      setLoading(false);
      // console.log("TEMPLATE BANK LIST ERROR", error);
    }
  };
  const onTemplateClick = (id, name) => {
    redirectTo("template-management/email-template/create", { bank_id: id });
  };
  const getEmailTemplateName = (name) => {
    setTemplateName(name);
  };
  const handleTemplateNameSubmission = () => {
    setModalOpen(false);
    // console.log("name==>", templateName);
    redirectTo("template-management/email-template/create", {
      template_name: templateName,
    });
  };

  useEffect(() => {
    console.log("template bank list", templateBankList);
  }, [templateBankList]);
  return (
    // <div>EmailTemplateBank</div>
    <Fragment>
      <div className="mb-5">
        <div className="d-flex justify-content-between mb-4">
          <h4 className="mb-0">Template Bank</h4>
          <Dropdown className="d-flex align-items-center">
            Sorting By
            <Dropdown.Toggle
              as={CustomToggle}
              variant="text-secondary dropdown-toggle"
            >
              Name
            </Dropdown.Toggle>
            <Dropdown.Menu aria-labelledby="dropdownMenuButton1">
              <li>
                <Dropdown.Item href="#">Recent</Dropdown.Item>
              </li>
              <li>
                <Dropdown.Item href="#">Mostly Used</Dropdown.Item>
              </li>
              <li>
                <Dropdown.Item href="#">Most Popular</Dropdown.Item>
              </li>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <Masonry
        breakpointCols={3}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        <Card className={props.classname}>
          <Card.Body>
            <div
              className="d-flex align-items-center justify-content-center flex-column mt-4"
              onClick={() => setModalOpen(true)}
            >
              <div className="mb-4 mx-auto avatar-100 bg-soft-primary rounded-circle">
                {findIcon("Plus", "solid", "30")}
              </div>
              <Button
                type="button"
                variant="primary"
                className="fs-5 rounded-3"
              >
                Create New Template
              </Button>
            </div>
          </Card.Body>
          <Card.Footer className="p-0">
            <Image
              src={img6}
              onClick={() => setModalOpen(true)}
              alt="02"
              className="img-fluid w-100 rounded object-cover "
              loading="lazy"
            />
          </Card.Footer>
        </Card>

        {templateBankList.map((item, index) => (
          <TemplateCard
            data={item}
            key={index}
            onClick={() => onTemplateClick(item.bank_id, item.bank_name)}
          />
        ))}
        {/* <TemplateCard data={htmlData} onClick={() => onTemplateClick(1)} />
        <TemplateCard data={htmlData} onClick={() => onTemplateClick(2)} />
        <TemplateCard data={htmlData} onClick={() => onTemplateClick(3)} /> */}
      </Masonry>
      <div className="text-center">
        <Link to="" className="btn btn-primary mb-3">
          Load More
        </Link>
      </div>

      <Modal
        show={modalOpen}
        //   size="sm"
        onHide={() => setModalOpen(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="mb-0">Create New Email Template</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="position-relative d-flex flex-column gap-3">
            <Form.Floating className="custom-form-floating">
              <Form.Control
                type="text"
                className=""
                id="floatingInput1"
                autoComplete="Enter Email Template Name"
                placeholder="Enter Email Template Name"
                onChange={(e) => getEmailTemplateName(e.target.value)}
              />
              <Form.Label htmlFor="floatingInput">
                Enter Email Template Name
              </Form.Label>
            </Form.Floating>
            <Button
              className=""
              variant="primary"
              disabled={templateName === ""}
              onClick={() => {
                handleTemplateNameSubmission();
              }}
            >
              Submit & Select Name
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
});

export default EmailTemplateBank;
