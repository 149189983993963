import React from "react";

// layout

import Default from "../../../../../layouts/dashboard/default";
import AllDepartments from "../pages/all-departments";

// pages

export const DeparmentManagementRouter = [
  {
    path: "/master-management/department",
    element: <Default />,
    children: [
      {
        path: "all",
        element: <AllDepartments />,
      },
    ],
  },
];
