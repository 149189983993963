import React, { memo, useEffect, useRef, useState } from "react";
import { Button, Row, Form, Table, Col, Card, Image } from "react-bootstrap";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import { useLocation, useParams } from "react-router-dom";
import {
  TEMPLATE_BANK_ADD,
  TEMPLATE_BANK_CREATE_GET,
  TEMPLATE_BANK_EDIT_GET,
  TEMPLATE_BANK_IMAGE_UPLOAD,
  TEMPLATE_BANK_UPDATE,
  TEMPLATE_VARIABLE_LIST_GET,
} from "../../../../api/endpoints/template-management-endpoints";
import { getService } from "../../../../api/services/get-services";
import * as yup from "yup";
import { useFormik } from "formik";
import postService from "../../../../api/services/post-service";
import GrapejsEditor from "../../../../lib/email-editor/grapejs-editor";

import toast from "react-hot-toast";
import uploadService from "../../../../api/services/upload-service";
import AutoGenerateFrom from "../../template-manager/pages/bank/component/AutoGenerateFrom";
import PaginationLoader from "../../../../components/loader/pagination-loader";
const EDIT_TYPE_AUTO = "AUTO";
const EDIT_TYPE_MANUAL = "MANUAL";
const EmbeddedEmailCreate = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const emailEditorRef = useRef(null);
  const { redirectTo } = useRedirect();
  const [edit, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mergeTags, setMergeTags] = useState("");
  const [bankPreview, setBankPreview] = useState("");
  const [presetHTML, setPresetHTML] = useState(null);
  const [editor, setEditor] = useState(null);
  const [editType, setEditType] = useState(EDIT_TYPE_AUTO);
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);

  useEffect(() => {
    if (id) {
      setEditMode(true);
      setEditMode(EDIT_TYPE_MANUAL);
      getBankDetails();
    } else {
      createTemplate();
    }
  }, [id]);

  useEffect(() => {
    getVariableList();
  }, [edit]);

  const initialValues = {
    bank_image: "",
    bank_name: "",
  };

  let myValidationSchema = {
    bank_name: yup.string().required("Bank Name is required"),
    bank_image: yup.array(),
  };

  //yup and formik for validation of form fields of priority_name
  const validationSchema = yup.object().shape(myValidationSchema);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    handleReset,
    setValues,
    values,
    errors,
    touched,
    setErrors,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      addEditTemplate({ html: editor.getHtml(), css: editor.getCss() });
    },
  });

  const addEditTemplate = async (Data) => {
    setLoading(true);
    const { bank_name, bank_image } = values;
    let postObj = {
      bank_name: bank_name,
      bank_images: bank_image ? bank_image[0] : "",
      bank_html: JSON.stringify(Data),
      bank_jsons: Data,
    };

    if (values.bank_image == "") {
      setErrors({ ...errors, bank_image: "Bank Image is required" });
    }
    try {
      let response = await postService(
        edit ? TEMPLATE_BANK_UPDATE + "/" + id : TEMPLATE_BANK_ADD,
        postObj
      );
      if (response) {
        console.log("response", response);
        if (response.isError) {
          toast.error(response?.error);
          // return false;
          setLoading(false);
        } else {
          if (response.data.success) {
            handleReset();
            setLoading(false);
            toast.success(response.data.message);
            redirectTo("template-management/bank");
          }
        }
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const post_json_variable = useRef({
    default: "all",

    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      variable_id: "desc",
    },
  });

  const getVariableList = async () => {
    try {
      setLoading(true);
      const response = await postService(
        TEMPLATE_VARIABLE_LIST_GET,
        post_json_variable.current
      );
      // console.log("Variable template service", response.data.data);
      if (response.data.data.rows.length > 0) {
        arrangeVariableData(response.data.data.rows);
        setLoading(false);
      }
      if (response.isError) {
        setLoading(false);
        toast.error(response?.error);
      }
    } catch (error) {
      setLoading(false);
      // console.log("USER_SERVICE_ERROR", error);
    }
  };

  const arrangeVariableData = (data) => {
    const variableObjectField = [];
    let modifiedVariableArray = [];
    let requiredArrayObjectForEditor = [];
    data.forEach((obj) => {
      const { variable_name, variable_type, variable_id } = obj;
      variableObjectField.push({ variable_name, variable_type, variable_id });
    });
    variableObjectField.forEach((obj, index) => {
      const {
        variable_name,
        variable_type: { id: variable_type_id, value: variable_type_value },
        variable_id,
      } = obj;
      const modifiedObject = {
        variable_name,
        variable_type_id,
        variable_type_value,
        variable_id,
      };
      modifiedVariableArray.push(modifiedObject);
    });
    // console.log("modifiedVariableArray", modifiedVariableArray);

    let allVariableType = modifiedVariableArray.map(
      (item) => item.variable_type_value
    );
    const uniqueSet = new Set(allVariableType.map((item) => item));
    const uniqueVariableType = [...uniqueSet];
    // console.log("uniqueVariableType ==>", uniqueVariableType);

    let variableTypeList = [];
    uniqueVariableType.forEach((item) => {
      let temp = modifiedVariableArray.filter(
        (item1) => item1.variable_type_value == item
      );
      variableTypeList.push(temp);
    });
    // console.log("variableTypeList:: ==>", variableTypeList);

    let temp = {};
    let myObjectNeeded = {};
    uniqueVariableType.map((item) => {
      variableTypeList.forEach((item1) => {
        if (item1[0].variable_type_value == item) {
          let temp1 = {};
          item1.forEach((item2) => {
            temp1 = {
              ...temp1,
              ...{
                name: item2.variable_type_value,
                mergeTags: {
                  ...temp1.mergeTags,
                  ...{
                    [item2.variable_name.replace(/ /g, "_")]: {
                      name: item2.variable_name,
                      value: `{{${item2.variable_name}}}`,
                    },
                  },
                },
              },
            };
          });
          temp[item] = {
            ...temp[item],
            ...temp1,
          };
        }
      });
    });
    setMergeTags(temp);
  };

  const getBankDetails = async () => {
    setLoading(true);
    const response = await getService(TEMPLATE_BANK_EDIT_GET + "/" + id);
    if (response.isError) {
      setLoading(false);
      toast.error(response?.error);
    } else {
      const { data } = response;
      // console.log("edit data", data);
      setBankPreview(data?.data?.bank_image);
      setValues({
        bank_name: data?.data?.bank_name,
        // bank_image: data?.data?.bank_image,
      });

      setLoading(false);
      onLoad(data?.data?.bank_json);
      // console.log("bankPreview", bankPreview);
    }
  };

  useEffect(() => {
    if (editor && selectedSuggestion) {
      editor.setComponents(presetHTML);
      setPresetHTML(presetHTML);
    }
  }, [selectedSuggestion, editor, presetHTML]);

  const onReady = () => {
    if (selectedSuggestion) {
      editor.setComponents(selectedSuggestion);
    }
  };

  const onLoad = (json_content) => {
    // emailEditorRef.current?.editor?.loadDesign(data);
    if (json_content) {
      setPresetHTML(JSON.stringify(json_content));
      //emailEditorRef.current?.editor?.loadDesign(json_content);
    } else {
      console.log("no json content");
    }
  };

  function uploadFiles(files) {
    return new Promise(async (resolve, reject) => {
      // Check if files array is empty
      if (files.length === 0) {
        reject("No files to upload.");
        return;
      }
      // console.log("uploading files", files);
      let formdata = new FormData();
      // console.log("FORMDATA", files);
      files.forEach((file) => {
        formdata.append("files[]", file);
      });

      // 1. Upload files to server
      let response = await uploadService(TEMPLATE_BANK_IMAGE_UPLOAD, formdata);
      // console.log("image", response);
      if (response.data?.success) {
        // console.log("RESPONSE", response);
        // 2. Simulating successful upload and generating URLs
        const urls = response.data.data.map((file) => file.name);
        const previewUrl = response.data.data.map((file) => file.url);
        //console.log("URLS", urls);
        setBankPreview(previewUrl);
        resolve(urls);
      } else {
        reject("Something went wrong");
      }
    });
  }

  const handleChangeReferenceFiles = async (e) => {
    const { files } = e.target;
    // console.log("FILES", files);

    const urls = await uploadFiles([...files]);
    // console.log("URLS", urls);
    if (urls) {
      setValues({
        ...values,
        bank_image: urls,
      });
    }
  };

  const createTemplate = async () => {
    try {
      // setLoading(true);
      const response = await getService(TEMPLATE_BANK_CREATE_GET);
      if (response.data.data.category.length > 0) {
        // setLoading(false);
      }
      if (response.isError) {
        toast.error(response?.error);
        // setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
    }
  };

  const onAutoGenerateSkip = async () => {
    setEditType(EDIT_TYPE_MANUAL);
  };

  const onContinueClick = async (data) => {
    // setEditType(EDIT_TYPE_MANUAL);
    redirectTo("template-management/email-template/create", {
      editType: "autogenerated",
      suggestionContent: data,
    });
  };

  const onGenerateClick = () => {
    setEditType(EDIT_TYPE_AUTO);
  };

  return (
    <>
      {editType == EDIT_TYPE_AUTO ? (
        <AutoGenerateFrom
          generation_type="email_new"
          onSkip={onAutoGenerateSkip}
          onContinue={onContinueClick}
        />
      ) : (
        <>
          <Form>
            {/* Variable Name */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Bank Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Bank Name"
                name="bank_name"
                onChange={handleChange}
                value={values.bank_name}
                onBlur={handleBlur}
                maxLength={255}
                isInvalid={touched.bank_name && errors.bank_name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.bank_name}
              </Form.Control.Feedback>
            </Form.Group>
            {bankPreview && (
              <Row>
                <Col lg={12} className="mb-2">
                  <spam>Bank Image Preview</spam>
                </Col>
                <Col lg={12} className="mb-2">
                  <Image
                    src={bankPreview && bankPreview}
                    className="theme-color-default-img  img-fluid  avatar-100"
                  />
                </Col>
              </Row>
            )}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Bank Image</Form.Label>
              <Form.Control
                type="file"
                placeholder="Enter Bank Image"
                name="bank_image"
                onChange={handleChangeReferenceFiles}
                onBlur={handleBlur}
                isInvalid={
                  !bankPreview && values.bank_image == "" && touched.bank_image
                }
              />
            </Form.Group>
            {!bankPreview && values.bank_image == "" && touched.bank_image && (
              <span className="text-danger fs-7">Please Choose an Image</span>
            )}
          </Form>
          <Col md={12} className="mb-4">
            <div className="boredr-2 border border-dashed p-4 rounded-3">
              <GrapejsEditor
                ref={emailEditorRef}
                onReady={onReady}
                onLoad={onLoad}
                mergeTags={mergeTags && mergeTags}
                presetData={presetHTML}
                setEditor={(e) => setEditor(e)}
                editor={editor}
              />
            </div>
          </Col>
        </>
      )}

      {editType != EDIT_TYPE_AUTO ? (
        <div className="d-flex justify-content-end mb-4">
          <Button variant="info " onClick={onGenerateClick}>
            Generate With AI
          </Button>
        </div>
      ) : null}
    </>
  );
};

export default EmbeddedEmailCreate;
