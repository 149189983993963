import React from "react";

// layout

import Default from "../../../../../layouts/dashboard/default";
import AllState from "../pages/all-state";
// pages

export const StateRouter = [
  {
    path: "/master-management/state",
    element: <Default />,
    children: [
      {
        path: "all",
        element: <AllState />,
      },
    ],
  },
];
