import React from "react";

import Horizontal from "../layouts/dashboard/horizontal";
import Boxed from "../layouts/dashboard/boxed";
import DualHorizontal from "../layouts/dashboard/dual-horizontal";
import DualCompact from "../layouts/dashboard/dual-compact";
import BoxedFancy from "../layouts/dashboard/boxed-fancy";
import { DefaultRouter } from "./default-router";

// project imports
import { SimpleRouter } from "./simple-router";
import { SimpleAuthRouter } from "../views/modules/auth/router/simple-router";
import { SocialRouter } from "../views/modules/social/router/social-router";
import { CreativeLibraryRouter } from "../views/modules/creative-library/router/creative-library-router";
import { Plugingrouter } from "../views/modules/plugins/router/plugins-router";
import { UserRouter } from "../views/modules/user-activity/router/user-router";
import { CreativeApprovalRouter } from "../views/modules/creative-approval/router/creative-approval-router";
import { TemplateManagerRouter } from "../views/modules/template-manager/router/template-manager-router";
// import { MasterManagementRouter } from "../views/modules/master-management/router/master-management-router";
import { SegmentRouter } from "../views/modules/campaign-segments/router/segment-router";
import { EmbeddedToolsRouter } from "../views/modules/embedded-tools/router/embedded-tools-router";
import { HCP_ProfileRouter } from "../views/modules/hcp-profile/router/hcp-profile-router";
import { OnboardingRouter } from "../views/modules/onboarding/router/onboarding-router";
import { RoleManagementRouter } from "../views/modules/role-management/router/role-management-router";
import { CampaignRouter } from "../views/modules/campaign/router/campaign-router";
import { ContentStudioRouter } from "../views/modules/content-studio/router/content-studio-router";
import { UserManagementRouter } from "../views/modules/user-management/router/user-management-router";
import { ActionRouter } from "../views/modules/action/router/action-router";
import { HelpAndSupportRouter } from "../views/modules/help-and-support/router/help-and-support-router";
import { ActionTypeRouter } from "../views/modules/master-management/action-type-management/router/actiontype-management-router";
import { PriorityRouter } from "../views/modules/master-management/priority-management/router/priority-router";
import { MenuManagement } from "../views/modules/master-management/menu-management/router/menu-management-router";
import { SpecialityManagementRouter } from "../views/modules/master-management/speciality-management/router/speciality-management-router";
import { CouncilRouter } from "../views/modules/master-management/council-management/router/council-router";
import { CountryRouter } from "../views/modules/master-management/country-management/router/country-router";
import { CityRouter } from "../views/modules/master-management/city-management/router/city-router";
import { StateRouter } from "../views/modules/master-management/state-management/router/state-router";
import { ZoneRouter } from "../views/modules/master-management/zone-management/router/zone-router";
import { DeparmentManagementRouter } from "../views/modules/master-management/deparment-management/router/deparment-router";
import { TargetManagementRouter } from "../views/modules/master-management/target-management/router/target-source-router";
import { FileTypeManagementRouter } from "../views/modules/master-management/file-type-management/router/file-type-router";
import { ContentCategoryRouter } from "../views/modules/master-management/content-category-management/router/content-category-router";
import { ClientRouter } from "../views/modules/master-management/client-management.js/router/client-router";
import { PrincipleEntityManagementRouter } from "../views/modules/master-management/principle-entity-management.js/router/principle-entity-router";
import { AssociationRouter } from "../views/modules/master-management/association-manage/router/association-router";
import { MasterVendorRouter } from "../views/modules/master-management/master-vendor-management/router/master-vendor-router";
import { ChannelRouter } from "../views/modules/master-management/Channel/router/channel-router";
import { SocialLinkRouter } from "../views/modules/master-management/social-link/router/social-link-router";
import { ContentLibraryRouter } from "../views/modules/content-library/router/content-library-router";
import { UserUserRouter } from "../views/modules/user-profile/router/user-profile-router";
import { NotificationRouter } from "../views/modules/notifications/router/notification-router";
import { ShortLinkRouter } from "../views/modules/short-link/router/short-link-router";

export const IndexRouters = [
  {
    path: "horizontal",
    element: <Horizontal />,
  },
  {
    path: "dual-horizontal",
    element: <DualHorizontal />,
  },
  {
    path: "dual-compact",
    element: <DualCompact />,
  },
  {
    path: "boxed",
    element: <Boxed />,
  },
  {
    path: "boxedFancy",
    element: <BoxedFancy />,
  },
  ...DefaultRouter,
  ...SocialRouter,
  ...CampaignRouter,
  ...CreativeLibraryRouter,
  ...CreativeApprovalRouter,
  ...SimpleRouter,
  ...SimpleAuthRouter,
  ...Plugingrouter,
  ...UserRouter,
  ...TemplateManagerRouter,
  ...SegmentRouter,
  ...EmbeddedToolsRouter,
  ...HCP_ProfileRouter,
  ...OnboardingRouter,
  ...RoleManagementRouter,
  ...ContentStudioRouter,
  ...UserManagementRouter,
  ...ActionRouter,
  ...ActionTypeRouter,
  ...PriorityRouter,
  ...MenuManagement,
  ...SpecialityManagementRouter,
  ...CouncilRouter,
  ...CountryRouter,
  ...CityRouter,
  ...StateRouter,
  ...ZoneRouter,
  ...AssociationRouter,
  ...DeparmentManagementRouter,
  ...TargetManagementRouter,
  ...FileTypeManagementRouter,
  ...ContentCategoryRouter,
  ...ClientRouter,
  ...PrincipleEntityManagementRouter,
  ...MasterVendorRouter,
  ...ChannelRouter,
  ...SocialLinkRouter,
  ...ContentLibraryRouter,
  ...HelpAndSupportRouter,
  ...NotificationRouter,
  ...ShortLinkRouter,
  ...UserUserRouter,
];
