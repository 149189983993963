import React from "react";
import { Card } from "react-bootstrap";
import CountUp from "react-countup";
const AnalyticsCard = ({ start, end, color, icon, title }) => {
  return (
    <Card className="flex-grow-1 mb-0">
      <Card.Body className="p-3 h-100">
        <div className="d-flex gap-3">
          <div
            className={`bg-soft-${color} d-inline-block avatar-50 rounded-3 flex-shrink-0`}
          >
            {icon}
          </div>

          <div className="flex-grow-1">
            <h2 className="counter mb-0">
              <CountUp start={start} end={end} duration={3} />
            </h2>
            <h6 className={`mb-0 text-${color}`}>{title}</h6>
          </div>
        </div>
      </Card.Body>
      {/* <Chart  options={chart3.options} series={chart3.series} type="area" height="80"  /> */}
    </Card>
  );
};

export default AnalyticsCard;
