import { Fragment, useEffect, memo, createRef, useState } from "react";
import React from "react";
import {
  Card,
  Col,
  Form,
  Row,
  Container,
  Button,
  Table,
} from "react-bootstrap";
import Flatpickr from "react-flatpickr";

const AddUser = () => {
  const [cameFrom, setCameFrom] = useState("viewuser");

  return (
    <Fragment>
      <Container fluid>
        <Row>
          <Col lg="12">
            {cameFrom == "adduser" && (
              <>
                <Card>
                  <Card.Header className="d-flex justify-content-between">
                    <div className="header-title">
                      <h4 className="card-title">Add User</h4>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <div className="form-group mb-3">
                      <Form.Label htmlFor="userName">Name</Form.Label>
                      <Form.Control
                        type="text"
                        id="userName"
                        aria-describedby="userNameBlock"
                      />
                    </div>
                    <div className="form-group mb-3">
                      <Form.Label htmlFor="contact">Contact</Form.Label>
                      <Form.Control
                        type="number"
                        className="form-control"
                        id="contact"
                      />
                    </div>
                    <div className="form-group mb-3">
                      <Form.Label htmlFor="userEmail">Email</Form.Label>
                      <Form.Control
                        type="email"
                        id="userEmail"
                        aria-describedby="userEmailBlock"
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="userStatus" className="mb-2">
                        Status
                      </label>
                      <Form.Select className="form-select" id="userStatus">
                        <option defaultValue>Select status</option>
                        <option value="1">Active</option>
                        <option value="2">Inactive</option>
                        <option value="3">Hold</option>
                        <option value="4">Pending</option>
                        <option value="5">Complete</option>
                      </Form.Select>
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="company" className="mb-2">
                        Company
                      </label>
                      <Form.Select className="form-select" id="company">
                        <option defaultValue>Select Company</option>
                        <option value="1">Fisrt company</option>
                        <option value="2">Second company</option>
                        <option value="3">Third company</option>
                      </Form.Select>
                    </div>
                    <div className="form-group mb-3">
                      <Form.Label htmlFor="joingDate">Joining Date</Form.Label>
                      <Flatpickr
                        options={{ enableTime: true }}
                        className="flatepickr_datetime form-control"
                        placeholder="Select Date time.."
                        id="joingDate"
                      />
                    </div>
                  </Card.Body>
                  <Card.Footer className="d-flex align-items-center justify-content-end">
                    <div className="d-flex align-itemscenter gap-2">
                      {/* <Button
                        onClick={(e) => { e.preventDefault(); setCameFrom("viewuser") }}
                      >
                        View User
                      </Button> */}
                      <Button>Add User</Button>
                      <Button variant="danger">Cancel</Button>{" "}
                    </div>
                  </Card.Footer>
                </Card>
              </>
            )}
            {cameFrom == "edituser" && (
              <>
                <Card>
                  <Card.Header className="d-flex justify-content-between">
                    <div className="header-title">
                      <h4 className="card-title">Edit User</h4>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <div className="form-group mb-3">
                      <Form.Label htmlFor="userName">Name</Form.Label>
                      <Form.Control
                        type="text"
                        id="userName"
                        aria-describedby="userNameBlock"
                        value="User asdasdasdasd"
                      />
                    </div>
                    <div className="form-group mb-3">
                      <Form.Label htmlFor="contact">Contact</Form.Label>
                      <Form.Control
                        type="number"
                        className="form-control"
                        id="contact"
                        value="80269889999"
                      />
                    </div>
                    <div className="form-group mb-3">
                      <Form.Label htmlFor="userEmail">Email</Form.Label>
                      <Form.Control
                        type="email"
                        id="userEmail"
                        value="samiran.das@clirnet.com"
                        aria-describedby="userEmailBlock"
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="userStatus" className="mb-2">
                        Status
                      </label>
                      <Form.Select className="form-select" id="userStatus">
                        <option defaultValue>Select status</option>
                        <option value="1" selected>
                          Active
                        </option>
                        <option value="2">Inactive</option>
                        <option value="3">Hold</option>
                        <option value="4">Pending</option>
                        <option value="5">Complete</option>
                      </Form.Select>
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="company" className="mb-2">
                        Company
                      </label>
                      <Form.Select className="form-select" id="company">
                        <option defaultValue>Select Company</option>
                        <option value="1">Fisrt company</option>
                        <option value="2">Second company</option>
                        <option value="3" selected>
                          Third company
                        </option>
                      </Form.Select>
                    </div>
                    <div className="form-group mb-3">
                      <Form.Label htmlFor="joingDate">Joining Date</Form.Label>
                      <Flatpickr
                        options={{ enableTime: true }}
                        className="flatepickr_datetime form-control"
                        placeholder="Select Date time.."
                        id="joingDate"
                      />
                    </div>
                  </Card.Body>
                  <Card.Footer className="d-flex align-items-center justify-content-end">
                    <div className="d-flex align-itemscenter gap-2">
                      <Button>Edit User</Button>
                      <Button variant="danger">Cancel</Button>{" "}
                    </div>
                  </Card.Footer>
                </Card>
              </>
            )}
            {cameFrom == "viewuser" && (
              <>
                <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
                  <div className="d-flex flex-column">
                    <h3 className="m-0">{"User Details"} </h3>
                  </div>
                </div>
                <Row>
                  <Col lg="12">
                    <Card>
                      <Card.Body>
                        <Form>
                          <Row>
                            <Col lg="12">
                              <Table>
                                <tbody>
                                  {/* <tr>
                                    <td>
                                      <h6 className="mb-0">Category</h6>
                                    </td>
                                    <td className="text-left">
                                      Bulk Engagement
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <h6 className="mb-0">Type</h6>
                                    </td>
                                    <td className="text-left">Promotional</td>
                                  </tr> */}
                                  <tr>
                                    <td>
                                      <h6 className="mb-0">Name</h6>
                                    </td>
                                    <td className="text-left">
                                      User asdasdasdasd
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <h6 className="mb-0">Contact number</h6>
                                    </td>
                                    <td className="text-left">80269889999</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <h6 className="mb-0">Email</h6>
                                    </td>
                                    <td className="text-left">
                                      samiran.das@clirnet.com
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <h6 className="mb-0">Status</h6>
                                    </td>
                                    <td className="text-left">Complete</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <h6 className="mb-0">Company</h6>
                                    </td>
                                    <td className="text-left">Clirnet</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <h6 className="mb-0">Joining date</h6>
                                    </td>
                                    <td className="text-left">12-2-2000</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <h6 className="mb-0">Params</h6>
                                    </td>
                                    <td className="text-left">
                                      Recent Updates on TB Treatment Dear
                                      Doctor, Click here to register -CIPLA
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </Col>
                            <Col
                              lg="12"
                              className="mt-4 d-flex gap-2 justify-content-end"
                            >

                              <button
                                className="btn btn-primary px-3 py-2"
                                type="submit"
                                onClick={(e) => { e.preventDefault(); setCameFrom("adduser") }}
                              >
                                Add User
                              </button>
                              <a
                                className="btn btn-danger px-3 py-2"
                                type="submit"
                              >
                                Cancel
                              </a>
                            </Col>
                          </Row>
                        </Form>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AddUser;
