import * as dayjs from "dayjs";

export function extractNextDay(dateString, nextDays) {
  const results = [];
  for (let i = 1; i <= nextDays; i++) {
    const inputDate = dayjs(dateString);
    const resultDate = inputDate.add(i, "day");
    const output = {
      date: resultDate.format("YYYY-MM-DD HH:mm:ss"),
      dayOfWeek: `In ${i} Buisness Day (${resultDate.format("dddd")})`,
    };
    results.push(output);
  }
  return results;
}
