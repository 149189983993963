import React, { useState, useEffect, useCallback } from "react";
import UserWhoDidFilter from "./user-who-did-filter";
import { Col, Row, Form, FormCheck } from "react-bootstrap";

const innitialFilterData = [
  {
    id: 1,
    name: "",
    operator: "",
    clause: "",
    value: "",
  },
];

const AND = "and";
const OR = "or";

/**
 *   title="User Who did:"
     contentCategoryList={contentCategoryList}
     activityList={activityList}
     payload={segment_add_payload.current}
     onFromDataChange={onUserWhoDidChnage}
 * @param {*}  
 * @returns 
 */
export default function UserWhoDidForm({
  title = "",
  onFromDataChange,
  contentCategoryList = [],
  activityList = [],
  isEditMode = false,
  editData = "",
}) {
  const [firstFilterData, setFirstFilterData] = useState([]);
  const [secondFilterData, setSecondFilterData] = useState([]);
  const [segemntFilter, setSegemntFilter] = useState(innitialFilterData);
  const [selectedOperator, setSelectedOperator] = useState();
  useEffect(() => {
    console.log(isEditMode, "user who did data:", editData);
    if (isEditMode && editData) {
      setSegemntFilter(editData);
    }
  }, [editData, isEditMode]);

  const handleCreateForm = (e) => {
    console.log("handleCreateForm", segemntFilter);
    e.preventDefault();
    setSegemntFilter([
      ...segemntFilter,
      {
        id: segemntFilter.length + 1,
        name: "",
        operator: "",
        value: "",
        clause: "",
      },
    ]);
  };

  const onDeleteFilter = useCallback(
    (id) => {
      const resultItems = segemntFilter.filter((el) => el.id !== id);
      if (resultItems.length > 0) {
        setSegemntFilter(resultItems);
      }
    },
    [segemntFilter]
  );

  function replaceObjectInArray(targetObject, objectArray, modifiedObject) {
    for (let i = 0; i < objectArray.length; i++) {
      if (isEqual(targetObject, objectArray[i])) {
        objectArray[i] = modifiedObject;
        break;
      }
    }
    return objectArray;
  }

  function isEqual(obj1, obj2) {
    // Compare the properties of the two objects
    for (let key in obj1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
    return true;
  }

  const onselectFilter = useCallback(
    (data) => {
      const filterData = segemntFilter.filter((el) => el.id === data.id);
      const updatedArray = replaceObjectInArray(
        filterData[0],
        segemntFilter,
        data
      );
      const tempSegment = [...updatedArray];

      console.group("on select Filter");
      console.log("temp segment", tempSegment);
      console.groupEnd("on select Filter");
      onFromDataChange(tempSegment);
      setSegemntFilter(tempSegment);
    },
    [segemntFilter]
  );

  function updateFilterOperator(key, filter, value) {
    return filter.map((item) => {
      return {
        ...item,
        [key]: value,
      };
    });
  }

  const onToogleOperator = (e) => {
    console.group("toogle operator");
    const { value } = e;
    if (selectedOperator == AND) {
      const updatedFilter = updateFilterOperator("operator", segemntFilter, OR);
      console.log("updated", updatedFilter);
      setSegemntFilter(updatedFilter);
      setSelectedOperator(OR);
    } else {
      const updatedFilter = updateFilterOperator(
        "operator",
        segemntFilter,
        AND
      );
      console.log("updated", updatedFilter);
      setSegemntFilter(updatedFilter);
      setSelectedOperator(AND);
    }

    console.groupEnd("toogle operator");
  };

  return (
    <>
      <div className="mb-4">
        <Row className="">
          <Col lg={6} className="mt-2 float-start">
            {title && <h6 className="mb-0">{title}</h6>}
          </Col>
          {segemntFilter?.length > 1 && (
            <Col lg={6} className="mt-2 ">
              <div className="d-flex flex-row gap-3 float-end">
                <Form.Check className="fs-6 form-switch">
                  <FormCheck.Input
                    className="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    name={AND}
                    onChange={onToogleOperator}
                    checked={selectedOperator == AND}
                  />
                  <FormCheck.Label
                    className="form-check-label text-black lh-base"
                    htmlFor="flexSwitchCheckChecked"
                  >
                    And
                  </FormCheck.Label>
                </Form.Check>
                <Form.Check className="fs-6 form-switch">
                  <FormCheck.Input
                    className="form-check-input"
                    type="checkbox"
                    name={OR}
                    id="flexSwitchCheckChecked"
                    checked={selectedOperator == OR}
                    onChange={onToogleOperator}
                    // disabled={selectedJson[index]?.operator == "and"}
                  />
                  <FormCheck.Label
                    className="form-check-label text-black lh-base"
                    htmlFor="flexSwitchCheckChecked"
                  >
                    Or
                  </FormCheck.Label>
                </Form.Check>
              </div>
            </Col>
          )}
        </Row>

        {segemntFilter?.length > 0 &&
          segemntFilter.map((seg, ind) => (
            <>
              <UserWhoDidFilter
                index={ind}
                selectedJson={segemntFilter}
                id={seg.id}
                contentCategoryList={contentCategoryList}
                activityList={activityList}
                onDeleteItem={onDeleteFilter}
                onCreateBtnClick={handleCreateForm}
                onItemSelect={onselectFilter}
                isDeleteBtn={ind > 0 ? true : false}
                isAddBtn={ind == segemntFilter.length - 1 ? true : false}
              />
            </>
          ))}
      </div>
    </>
  );
}
