import React from "react";
import { Badge, Button, Col, Row } from "react-bootstrap";
import Card from "../../../../components/bootstrap/card";
import ReactTable from "../../../../lib/table/react-table";
import { findIcon } from "../../../dashboard/icons";
import Flatpickr from "react-flatpickr";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import FilterBar from "../../../../components/filter-bar";
import CommonTableLoader from "../../../../skeleton/common-table-loader";

const CampaignApproval = () => {
  const { redirectTo } = useRedirect();

  const redirectionHandler = (type) => {
    if (type === "A/B Test Approval") {
      return "action/ab-test-approval-details";
    } else if (type === "A/B Test Final Approval") {
      return "action/ab-test-final-approval-details";
    } else {
      return "action/campaign-approval-details";
    }
  };

  const vendorActionListTable = {
    data: [
      {
        checkBox: "",
        taskName: "Campaign For ENT Doctors",
        taskType: "A/B Test Approval",
        deadline: "2023-05-31",
        priority: "High",
        status: { status: "Pending", color: "danger" },
        taskId: findIcon("Eye Fish", "dual-tone"),
        assignedBy: "Arnab Saha",
      },
      {
        checkBox: "",
        taskName: "Campaign For Kolkata Doctors",
        taskType: "A/B Test Final Approval",
        deadline: "2023-07-18",
        priority: "Medium",
        status: { status: "Completed", color: "primary" },
        taskId: findIcon("Eye Fish", "dual-tone"),
        assignedBy: "Sumit Mandal",
      },
      {
        checkBox: "",
        taskName: "Campaign For Cardio in Kolkata Doctors",
        taskType: "A/B Test Approval",
        deadline: "2023-06-03",
        priority: "Low",
        status: { status: "In Progress", color: "primary" },
        taskId: findIcon("Eye Fish", "dual-tone"),
        assignedBy: "Indranil Kundu",
      },
      {
        checkBox: "",
        taskName: "Campaign For Blood Donation Camp",
        taskType: "Campaign Approval",
        deadline: "2023-05-15",
        priority: "Low",
        status: { status: "In Progress", color: "primary" },
        taskId: findIcon("Eye Fish", "dual-tone"),
        assignedBy: "Rathindra Guha",
      },
      {
        checkBox: "",
        taskName: "Campaign For COVID awareness",
        taskType: "A/B Test Final Approval",
        deadline: "2023-08-15",
        priority: "Low",
        status: { status: "In Progress", color: "primary" },
        taskId: findIcon("Eye Fish", "dual-tone"),
        assignedBy: "Samar Mondal",
      },
    ],
    columns: [
      //   {
      //     Header: "",
      //     accessor: "checkBox",
      //     Cell: ({ row }) => <input type="checkbox" />,
      //   },
      {
        Header: "Action Name",
        accessor: "taskName",
      },
      {
        Header: "Approval Type",
        accessor: "taskType",
      },
      {
        Header: "Deadline",
        accessor: "deadline",
      },
      {
        Header: "Priority",
        accessor: "priority",
        Cell: ({ row }) => (
          // console.log("row", row, row.values.status.status),
          <div className="d-flex align-items-center justify-content-center">
            <Badge
              bg={
                row.values.priority == "Low"
                  ? "soft-success"
                  : row.values.priority == "Medium"
                  ? "soft-warning"
                  : row.values.priority == "High"
                  ? "soft-danger"
                  : ""
              }
              className="py-2"
            >
              <span className="btn-inner">{row.values.priority}</span>
            </Badge>
          </div>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => (
          // console.log("row", row, row.values.status.status),
          <div className="d-flex align-items-center justify-content-center">
            <Badge
              bg={
                row.values.status.status == "In Progress"
                  ? "soft-primary"
                  : row.values.status.status == "Completed"
                  ? "soft-success"
                  : row.values.status.status == "Pending"
                  ? "soft-danger"
                  : ""
              }
              className="py-2"
            >
              <span className="btn-inner">{row.values.status.status}</span>
            </Badge>
          </div>
        ),
      },
      {
        Header: "Assigned by",
        accessor: "assignedBy",
      },
      {
        Header: "Action",
        accessor: "taskId",
        disableFilters: true,
        Cell: ({ row }) => (
          <div className="d-flex justify-content-center">
            <Button
              className="btn btn-icon btn-sm rounded-pill"
              to="#"
              role="button"
              onClick={() => {
                let data = redirectionHandler(row.values.taskType);
                console.log("test data", data);
                redirectTo(data);
              }}
            >
              <span className="btn-inner">{row.values.taskId}</span>
            </Button>
          </div>
        ),
      },
    ],
  };

  const abTestActionListTable = {
    data: [
      {
        checkBox: "",
        taskName: "Approval for Dr Arnab Saha",
        taskType: "Onboarding Approval",
        deadline: "2023-05-31",
        priority: "High",
        status: { status: "Pending", color: "danger" },
        taskId: findIcon("Eye Fish", "dual-tone"),
        assignedBy: "Arnab Saha",
      },
      {
        checkBox: "",
        taskName: "Approval for Dr Saif Imroz",
        taskType: "Content Collection Approval",
        deadline: "2023-06-15",
        priority: "Medium",
        status: { status: "Completed", color: "primary" },
        taskId: findIcon("Eye Fish", "dual-tone"),
        assignedBy: "Sumit Mandal",
      },
      {
        checkBox: "",
        taskName: "Approval for Dr Indranil Kundu",
        taskType: "Onboarding Approval",
        deadline: "2023-06-15",
        priority: "Low",
        status: { status: "In Progress", color: "primary" },
        taskId: findIcon("Eye Fish", "dual-tone"),
        assignedBy: "Indranil Kundu",
      },
      {
        checkBox: "",
        taskName: "Approval for Dr Sumit Mandol",
        taskType: "Content Collection Approval",
        deadline: "2023-06-15",
        priority: "Low",
        status: { status: "In Progress", color: "primary" },
        taskId: findIcon("Eye Fish", "dual-tone"),
        assignedBy: "Indranil Kundu",
      },
      {
        checkBox: "",
        taskName: "Approval for Dr Sanjay Dey",
        taskType: "Onboarding Approval",
        deadline: "2023-06-15",
        priority: "Low",
        status: { status: "In Progress", color: "primary" },
        taskId: findIcon("Eye Fish", "dual-tone"),
        assignedBy: "Indranil Kundu",
      },
    ],
    columns: [
      //   {
      //     Header: "",
      //     accessor: "checkBox",
      //     Cell: ({ row }) => <input type="checkbox" />,
      //   },
      {
        Header: "Action Name",
        accessor: "taskName",
      },
      {
        Header: "Approval Type",
        accessor: "taskType",
      },
      {
        Header: "Deadline",
        accessor: "deadline",
      },
      {
        Header: "Priority",
        accessor: "priority",
        Cell: ({ row }) => (
          // console.log("row", row, row.values.status.status),
          <div className="d-flex align-items-center justify-content-center">
            <Badge
              bg={
                row.values.priority == "Low"
                  ? "success"
                  : row.values.priority == "Medium"
                  ? "warning"
                  : row.values.priority == "High"
                  ? "danger"
                  : ""
              }
              className="me-2"
            >
              <span className="btn-inner">{row.values.priority}</span>
            </Badge>
          </div>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => (
          // console.log("row", row, row.values.status.status),
          <div className="d-flex align-items-center justify-content-center">
            <Badge
              bg={
                row.values.status.status == "In Progress"
                  ? "primary"
                  : row.values.status.status == "Completed"
                  ? "success"
                  : row.values.status.status == "Pending"
                  ? "danger"
                  : ""
              }
              className="me-2"
            >
              <span className="btn-inner">{row.values.status.status}</span>
            </Badge>
          </div>
        ),
      },
      {
        Header: "Assigned by",
        accessor: "assignedBy",
      },
      {
        Header: "Action",
        accessor: "taskId",
        // disableSortBy: true,
        Cell: ({ row }) => (
          <div className="d-flex justify-content-center">
            <Button
              className="btn btn-icon btn-sm rounded-pill"
              to="#"
              role="button"
              onClick={() => {
                redirectTo("action/ab-test-approval-details");
              }}
            >
              <span className="btn-inner">{row.values.taskId}</span>
            </Button>
          </div>
        ),
      },
    ],
  };

  const abTestFinalActionListTable = {
    data: [
      {
        checkBox: "",
        taskName: "Approval for Dr Arnab Saha",
        taskType: "Onboarding Approval",
        deadline: "2023-05-31",
        priority: "High",
        status: { status: "Pending", color: "danger" },
        taskId: findIcon("Eye Fish", "dual-tone"),
        assignedBy: "Arnab Saha",
      },
      {
        checkBox: "",
        taskName: "Approval for Dr Saif Imroz",
        taskType: "Content Collection Approval",
        deadline: "2023-06-15",
        priority: "Medium",
        status: { status: "Completed", color: "primary" },
        taskId: findIcon("Eye Fish", "dual-tone"),
        assignedBy: "Sumit Mandal",
      },
      {
        checkBox: "",
        taskName: "Approval for Dr Indranil Kundu",
        taskType: "Onboarding Approval",
        deadline: "2023-06-15",
        priority: "Low",
        status: { status: "In Progress", color: "primary" },
        taskId: findIcon("Eye Fish", "dual-tone"),
        assignedBy: "Indranil Kundu",
      },
      {
        checkBox: "",
        taskName: "Approval for Dr Sumit Mandol",
        taskType: "Content Collection Approval",
        deadline: "2023-06-15",
        priority: "Low",
        status: { status: "In Progress", color: "primary" },
        taskId: findIcon("Eye Fish", "dual-tone"),
        assignedBy: "Indranil Kundu",
      },
      {
        checkBox: "",
        taskName: "Approval for Dr Sanjay Dey",
        taskType: "Onboarding Approval",
        deadline: "2023-06-15",
        priority: "Low",
        status: { status: "In Progress", color: "primary" },
        taskId: findIcon("Eye Fish", "dual-tone"),
        assignedBy: "Indranil Kundu",
      },
    ],
    columns: [
      //   {
      //     Header: "",
      //     accessor: "checkBox",
      //     Cell: ({ row }) => <input type="checkbox" />,
      //   },
      {
        Header: "Action Name",
        accessor: "taskName",
      },
      {
        Header: "Approval Type",
        accessor: "taskType",
      },
      {
        Header: "Deadline",
        accessor: "deadline",
      },
      {
        Header: "Priority",
        accessor: "priority",
        Cell: ({ row }) => (
          // console.log("row", row, row.values.status.status),
          <div className="d-flex align-items-center justify-content-center">
            <Badge
              bg={
                row.values.priority == "Low"
                  ? "success"
                  : row.values.priority == "Medium"
                  ? "warning"
                  : row.values.priority == "High"
                  ? "danger"
                  : ""
              }
              className="me-2"
            >
              <span className="btn-inner">{row.values.priority}</span>
            </Badge>
          </div>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => (
          // console.log("row", row, row.values.status.status),
          <div className="d-flex align-items-center justify-content-center">
            <Badge
              bg={
                row.values.status.status == "In Progress"
                  ? "primary"
                  : row.values.status.status == "Completed"
                  ? "success"
                  : row.values.status.status == "Pending"
                  ? "danger"
                  : ""
              }
              className="me-2"
            >
              <span className="btn-inner">{row.values.status.status}</span>
            </Badge>
          </div>
        ),
      },
      {
        Header: "Assigned by",
        accessor: "assignedBy",
      },
      {
        Header: "Action",
        accessor: "taskId",
        // disableSortBy: true,
        Cell: ({ row }) => (
          <div className="d-flex justify-content-center">
            <Button
              className="btn btn-icon btn-sm rounded-pill"
              to="#"
              role="button"
              onClick={() => {
                redirectTo("action/ab-test-final-approval-details");
              }}
            >
              <span className="btn-inner">{row.values.taskId}</span>
            </Button>
          </div>
        ),
      },
    ],
  };

  const advanceData = [
    { value: "Segment ID", label: "Segment ID", type: "number" },
    {
      value: "Created by",
      label: "Created by",
      elements: [
        { value: "Creator 1", label: "Creator 1" },
        { value: "Creator 2", label: "Creator 2" },
        { value: "Creator 3", label: "Creator 3" },
      ],
      type: "string",
    },
    {
      value: "Modified by",
      label: "Modified by",
      elements: [
        { value: "Modifier 1", label: "Modifier 1" },
        { value: "Modifier 2", label: "Modifier 2" },
        { value: "Modifier 3", label: "Modifier 3" },
      ],
      type: "string",
    },
    // { value: "User Age", label: "User Age" },
  ];

  return (
    <>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <div className="d-flex flex-column">
          <h3 className="m-0">Campaign Approval</h3>
        </div>
        <div className="d-flex justify-content-between align-items-center rounded flex-wrap gap-3">
          <div className="form-group mb-0 ">
            <select className="form-select">
              <option defaultValue>Sort By</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>
          <div className="form-group mb-0 ">
            <Flatpickr
              options={{ mode: "range", minDate: "today" }}
              className="form-control range_flatpicker"
              placeholder="24 Jan 2022 to 23 Feb 2022"
            />
          </div>
          <FilterBar advanceData={advanceData} />
        </div>
      </div>
      <Row>
        <Col lg="12">
          <Card>
            <Card.Body>
              <ReactTable
                data={vendorActionListTable.data}
                columns={vendorActionListTable.columns}
                recordsPerPage={10}
                recordsTotal={vendorActionListTable?.data?.length}
              />
              {/* <CommonTableLoader /> */}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CampaignApproval;
