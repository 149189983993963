import { memo, Fragment, useState, useEffect, useMemo } from "react";
// React-bootstrap
import { Row, Tab, Col, Nav, Form, Button } from "react-bootstrap";
import Card from "../../../../components/bootstrap/card";
import { getService } from "../../../../api/services/get-services";
import {
  ADD_ROLE_POST,
  ROLE_CREATE_GET,
  UPDATE_ROLE_POST,
  EDIT_ROLE_GET,
} from "../../../../api/endpoints/role-management-endpoints";
import { Formik, useFormik } from "formik";
import * as yup from "yup";
import postService from "../../../../api/services/post-service";
import { useLocation, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import PaginationLoader from "../../../../components/loader/pagination-loader";
import AddRoleLoader from "../../../../skeleton/role-management/add-role-loader";

const AddRole = () => {
  //usestate for active key
  const [key, setKey] = useState(0);
  const [permissions, setPermissions] = useState([]);
  const [permissionToShow, setPermissionToShow] = useState([]); // [{}
  const [loader, showLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [permissionError, setPermissionError] = useState("");
  const [parentPermission, setParentPermission] = useState({
    id: "",
    name: "",
  }); // [{}
  const [selectedParentPermission, setSelectedParentPermission] = useState([]);
  const [childPermissionView, setChildPermissionView] = useState("");
  const { redirectTo } = useRedirect();
  const params = useParams();

  const initialValues = {
    name: "",
    description: "",
  };
  const schema = yup.object().shape({
    name: yup.string().required("Please enter your role name"),
    description: yup.string().required("Please enter your role description"),
  });
  const getCheckedPermissionsId = () => {
    let checked_permissions = [];
    permissions.map((item) => {
      item.permissions.map((permission) => {
        if (permission.checked) {
          checked_permissions.push(permission.id);
        }
      });
    });
    return checked_permissions;
  };

  const checkPermissionItemStatus = () => {
    let checked_permissions = permissions;
    let newArray = [];
    let checkedData = [];
    checked_permissions.map((item) => {
      newArray.push(item.permissions);
    });
    let checked = newArray.flat();
    checkedData = checked.find((item) => item.checked == true);
    if (checkedData == undefined) {
      return false;
    } else {
      return true;
    }
  };

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: initialValues,
      validationSchema: schema,
      enableReinitialize: true,
      onSubmit: async (value, action) => {
        let itemChecked = checkPermissionItemStatus();
        if (itemChecked) {
          if (location.pathname.includes("edit")) {
            showLoader(true);
            let response = await postService(
              `${UPDATE_ROLE_POST}/${params.id}`,
              {
                ...value,
                permission_ids: getCheckedPermissionsId(),
              }
            );

            if (response.isError) {
              toast(response.error);
              showLoader(false);
            } else {
              showLoader(false);
              setFieldValue("name", "");
              setFieldValue("description", "");
              setPermissions([]);
              toast(response.data?.message);
              redirectTo("roles/listing");
            }
          } else {
            // console.log(value);
            showLoader(true);
            let response = await postService(ADD_ROLE_POST, {
              ...value,
              permission_ids: getCheckedPermissionsId(),
            });

            if (response.isError) {
              toast(response.error);
              showLoader(false);
            } else {
              showLoader(false);
              setFieldValue("name", "");
              setFieldValue("description", "");
              setPermissions([]);
              toast(response.data?.message);
              redirectTo("roles/listing");
            }
          }
        } else {
          setPermissionError("Please select at-least one permission");
        }
      },
    });

  const fetchRole = async () => {
    const response = await getService(ROLE_CREATE_GET);
    let data = response?.data?.data?.permissions;
    setPermissions(data);
    setPermissionToShow(data);
    setKey(data[0].module_id);
    setLoading(false);
  };

  const editRole = async () => {
    let roleId = params.id;
    const response = await getService(`${EDIT_ROLE_GET}/${roleId}`);
    let data = response?.data?.data?.permissions;
    setPermissions(data);
    setPermissionToShow(data);
    setKey(data[0].module_id);
    setFieldValue("name", response?.data?.data?.role?.name);
    setFieldValue("description", response?.data?.data?.role?.description);
    setPermissionChecked(response?.data?.data);
    setLoading(false);
  };

  const setPermissionChecked = (data) => {
    let api_checked_permissions = data?.role.permissions;
    let permissionArray = data?.permissions;
    let selectedData = [];
    permissionArray.map((item) => {
      item.permissions.map((item) => {
        if (api_checked_permissions.includes(item.id)) {
          item.checked = true;
          permissionArray.filter((data) => {
            if (item.module_id == data.module_id) {
              selectedData.push(data);
            }
            const uniqueArray = removeDuplicates(selectedData, "module_id");
            setSelectedParentPermission(uniqueArray);
            setChildPermissionView(uniqueArray[0]);
          });
        }
      });
    });
  };

  const removeDuplicates = (array, field) => {
    const uniqueValues = new Set();
    const uniqueArray = [];

    for (let obj of array) {
      const value = obj[field];
      if (!uniqueValues.has(value)) {
        uniqueValues.add(value);
        uniqueArray.push(obj);
      }
    }

    return uniqueArray;
  };

  let location = useLocation();
  useEffect(() => {
    setLoading(true);
    if (location.pathname.includes("edit")) {
      editRole();
    } else {
      fetchRole();
    }
    return () => {};
  }, []);

  const onSelectTab = (key) => {
    setKey(key);
  };

  const handlePermissionCheck = (e, permission_id) => {
    setPermissions((prev) => {
      let temp_permissions = [...prev];
      let index = temp_permissions.findIndex(
        (item) => item.module_id == childPermissionView.module_id
      );
      console.log(index);
      console.log(temp_permissions[index]);
      let permission_index = temp_permissions[index].permissions.findIndex(
        (item) => item.id == permission_id
      );

      temp_permissions[index].permissions[permission_index].checked =
        e.target.checked;
      return [...temp_permissions];
    });
  };

  const handleCheckAll = (e) => {
    setPermissions((prev) => {
      let temp_permissions = [...prev];
      let index = temp_permissions.findIndex(
        (item) => item.module_id == childPermissionView.module_id
      );
      temp_permissions[index].permissions.map((item) => {
        item.checked = e.target.checked;
        return item;
      });
      return [...temp_permissions];
    });
  };
  const getIsAllChecked = () => {
    if (permissions?.length == 0 || !childPermissionView.module_id) {
      return false;
    }
    let index = permissions.findIndex(
      (item) => item.module_id == childPermissionView.module_id
    );
    let checked = permissions[index].permissions.filter(
      (item) => item.checked == true
    );
    if (checked?.length == permissions[index].permissions?.length) {
      return true;
    }
    return false;
  };
  // const isAllChecked = useMemo(
  //     () => getIsAllChecked()
  //     [permissions, childPermissionView, selectedParentPermission]
  // );

  const handleParentPermissionAdd = () => {
    // console.log("value==>", parentPermission);
    if (parentPermission != "") {
      permissions.map((item) => {
        if (item.module_id == parentPermission?.id) {
          setChildPermissionView(item);
          const updatedArray = [...selectedParentPermission, item];
          setSelectedParentPermission(updatedArray);
          const filteredArray = permissionToShow.filter(
            (obj) => obj.module_id != parentPermission?.id
          );
          // console.log("filteredArray", filteredArray);
          setPermissionToShow(filteredArray);
        }
      });
    }
    setParentPermission({
      id: "",
      name: "",
    });
  };
  const handleParentPermissionClick = (e) => {
    let name = "";
    let id = e.target.value;
    permissionToShow.map((permission, index) => {
      if (permission.module_id == e.target.value) {
        name = permission.module_title;
      }
    });

    setParentPermission({
      ...parentPermission,
      id: id,
      name: name,
    });
  };

  const viewChildPermission = (data) => {
    console.log("viewChildPermission", data);
    setChildPermissionView(data);
  };

  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <div className="d-flex flex-column">
          <h3 className="m-0">
            {location.pathname.includes("edit")
              ? "Edit Your Role & Permission"
              : "Create New Role & Permission"}
          </h3>
        </div>
      </div>
      <Row>
        <Col sm="12">
          {!loading ? (
            <Card>
              <Card.Body>
                <Row>
                  <Col lg="6" className="d-flex flex-column">
                    <h5 className="mb-3">Role Details</h5>
                    <Form.Floating className="custom-form-floating mb-3">
                      <Form.Control
                        type="name"
                        placeholder="Role Name"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        isInvalid={touched.name && !!errors.name}
                      />
                      <Form.Label>Role Name</Form.Label>
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    </Form.Floating>
                    <Form.Floating className="custom-form-floating flex-grow-1">
                      <Form.Control
                        as={"textarea"}
                        rows={3}
                        className="form-control h-100"
                        placeholder="Role Description"
                        id="floatingTextarea2"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        isInvalid={touched.description && !!errors.description}
                      ></Form.Control>
                      <Form.Label htmlFor="fname">Description</Form.Label>
                      <Form.Control.Feedback type="invalid">
                        {errors.description}
                      </Form.Control.Feedback>
                    </Form.Floating>
                  </Col>
                  <Col lg="6" className="d-flex flex-column">
                    <h5 className="mb-3">Add Role Permission</h5>
                    <div className="d-flex mb-4">
                      <Form.Floating className="custom-form-floating mb-md-0 flex-grow-1">
                        <Form.Select
                          className=""
                          id="floatingInput1"
                          value={parentPermission?.id}
                          onChange={handleParentPermissionClick}
                        >
                          <option value="">Select Permission</option>
                          {permissionToShow?.length > 0 &&
                            permissionToShow.map((permission, index) => (
                              <option value={permission.module_id}>
                                {permission.module_title}
                              </option>
                            ))}
                        </Form.Select>
                        <Form.Label htmlFor="floatingInput">Fetures</Form.Label>
                      </Form.Floating>
                      <Button
                        variant="info"
                        className="px-4 ms-3"
                        onClick={handleParentPermissionAdd}
                      >
                        Add
                      </Button>
                    </div>

                    <div className="bd-example">
                      <div
                        className="gap-3 d-flex flex-wrap"
                        id="nav-tab"
                        role="tablist"
                      >
                        {selectedParentPermission.length > 0 &&
                          selectedParentPermission.map((item, index) => (
                            <Button
                              className={
                                childPermissionView?.module_id ==
                                item?.module_id
                                  ? "px-3 py-1 rounded-pill active"
                                  : "px-3 py-1 rounded-pill"
                              }
                              variant="outline-primary"
                              id="pro-nav-contact-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#pro-nav-contact"
                              aria-selected="false"
                              onClick={() => viewChildPermission(item)}
                            >
                              {item?.module_title}
                            </Button>
                          ))}
                      </div>
                      <div
                        className="tab-content iq-tab-fade-up mt-4"
                        id="simple-tab-content"
                      >
                        {childPermissionView && (
                          <div
                            className="tab-pane fade show active"
                            id="pro-nav-home"
                            role="tabpanel"
                            aria-labelledby="pro-nav-home-tab"
                          >
                            <div className="d-flex flex-wrap gap-3 border border-dashed border-primary rounded-3 p-3">
                              {childPermissionView && (
                                <Form.Check className="d-flex align-items-lg-center gap-2">
                                  <Form.Check.Input
                                    type="checkbox"
                                    defaultValue=""
                                    id="flexCheckDefault3"
                                    onChange={handleCheckAll}
                                    checked={getIsAllChecked()}
                                  />
                                  <Form.Check.Label htmlFor="flexCheckDefault3">
                                    All Privileges
                                  </Form.Check.Label>
                                </Form.Check>
                              )}

                              {childPermissionView &&
                                childPermissionView?.permissions.map((item) => (
                                  <Form.Check className="d-flex align-items-lg-center gap-2">
                                    <Form.Check.Input
                                      type="checkbox"
                                      defaultValue=""
                                      id="flexCheckDefault3"
                                      onChange={(e) =>
                                        handlePermissionCheck(e, item.id)
                                      }
                                      checked={item.checked}
                                    />
                                    <Form.Check.Label htmlFor="flexCheckDefault3">
                                      {item.label}
                                    </Form.Check.Label>
                                  </Form.Check>
                                ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col lg="12" className="d-flex justify-content-end mt-4">
                    <button
                      type="button"
                      className="btn btn-secondary me-3"
                      onClick={() => redirectTo("roles/listing")}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary  "
                      onClick={handleSubmit}
                    >
                      {location.pathname.includes("edit") ? (
                        loader ? (
                          <PaginationLoader />
                        ) : (
                          "Update"
                        )
                      ) : loader ? (
                        <PaginationLoader />
                      ) : (
                        "Create"
                      )}
                    </button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          ) : (
            <AddRoleLoader />
          )}
        </Col>
      </Row>
    </Fragment>
  );
};

export default AddRole;
