import React, { useState, useEffect, useCallback } from "react";
import UserWhoDidFilter from "./user-who-did-filter";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { debounce } from "lodash";
import postService from "../../../../../api/services/post-service";
import toast from "react-hot-toast";
import { Col, Row } from "react-bootstrap";

const innitialFilterData = [
  {
    id: 1,
    name: "",
    operator: "",
    clause: "",
    value: "",
  },
];
const clauseOptions = [
  {
    label: "In",
    value: "in",
  },
];

const activeOptions = [
  {
    label: "Topic",
    value: "topic",
  },
];
/**
 *   title="User Who did:"
     contentCategoryList={contentCategoryList}
     activityList={activityList}
     payload={segment_add_payload.current}
     onFromDataChange={onUserWhoDidChnage}
 * @param {*}  
 * @returns 
 */
export default function UserInterestedIn({
  title = "",
  onUserInterestSelected,
}) {
  const [name, setName] = useState();
  const [clause, setClause] = useState();

  const _loadSuggestions = (inputValue) => {
    return new Promise(async (resolve) => {
      const response = await postService("/segment/search", {
        filter: {
          content_tag_name: {
            type: "like",
            value: inputValue,
          },
        },
        pagination: {
          limit: 10,
          offset: 0,
        },
        sort: {
          content_tag_id: "asc",
        },
      });

      if (!response.isError) {
        const searchData = [];

        response?.data?.data?.rows.forEach((elem) => {
          searchData.push({
            value: elem.content_tag_name,
            label: elem.content_tag_name,
          });
        });
        resolve(searchData);
      } else {
        toast.error("Error fetching data!");
      }
    });
  };

  const filterOptions = (options = [], inputValue) => {
    console.group("filter");
    console.log(
      options.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      )
    );
    console.groupEnd("filter");
    return options.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadOptions = useCallback(
    debounce((inputText, callback) => {
      _loadSuggestions(inputText)
        .then((options) => {
          console.log("fetched data", options);
          callback(filterOptions(options, inputText));
        })
        .catch((err) => {
          console.log(err);
        });
    }, 300),
    []
  );

  return (
    <>
      <div className="mb-4">
        {title && <h6 className="mb-3">{title}</h6>}

        <Row className="mt-n3">
          <Col lg={4} className="mt-4">
            <Select
              onChange={(e) => setName(e.value)}
              options={activeOptions}
            />
          </Col>
          <Col lg={4} className="mt-4">
            <Select
              onChange={(e) => setClause(e.value)}
              options={clauseOptions}
            />
          </Col>
          <Col lg={4} className="mt-4">
            <AsyncSelect
              cacheOptions
              loadOptions={loadOptions}
              noOptionsMessage={() => "Type to search"}
              onChange={onUserInterestSelected}
            />
          </Col>
        </Row>
      </div>
    </>
  );
}
