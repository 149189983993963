
export const EMAIL_TEMPLATE_LIST_GET = "/template_email/list";
export const EMAIL_TEMPLATE_CREATE_GET = "/template_email/create";
export const EMAIL_TEMPLATE_ADD = "/template_email/add";
export const EMAIL_TEMPLATE_DELETE = "/template_email/delete";
export const EMAIL_TEMPLATE_DETAILS_GET = "/template_email/detail";
export const EMAIL_TEMPLATE_EDIT = "/template_email/edit";
export const EMAIL_TEMPLATE_UPDATE = "/template_email/update";
export const EMAIL_TEMPLATE_BANK_LIST = "/template_email/bank";
export const EMAIL_TEMPLATE_BANK_DETAILS = "/template_email/bank_detail";


export const SMS_TEMPLATE_LIST_GET = "/template_sms/list";
export const SMS_TEMPLATE_DETAILS_GET = "/template_sms/detail";
export const SMS_TEMPLATE_EDIT_GET = "/template_sms/edit";
export const SMS_TEMPLATE_CREATE_GET = "/template_sms/create";
export const SMS_TEMPLATE_ADD = "/template_sms/add";
export const SMS_TEMPLATE_UPDATE = "/template_sms/update";
export const SMS_TEMPLATE_DELETE = "/template_sms/delete";

export const WHATSAPP_TEMPLATE_LIST_GET = "/template_whatsapp/list";
export const WHATSAPP_TEMPLATE_ADD = "/template_whatsapp/add";
export const WHATSAPP_TEMPLATE_UPDATE = "/template_whatsapp/update";
export const WHATSAPP_TEMPLATE_DELETE = "/template_whatsapp/delete";
export const WHATSAPP_TEMPLATE_DETAILS_GET = "/template_whatsapp/detail";
export const WHATSAPP_TEMPLATE_CREATE_GET = "/template_whatsapp/create";
export const WHATSAPP_TEMPLATE_EDIT = "/template_whatsapp/edit";

export const TEMPLATE_CATEGORY_LIST = "/template_category/list";
export const TEMPLATE_CATEGORY_CREATE_GET = "/template_category/create";
export const TEMPLATE_CATEGORY_ADD = "/template_category/add";
export const TEMPLATE_CATEGORY_EDIT_GET = "/template_category/edit";
export const TEMPLATE_CATEGORY_UPDATE = "/template_category/update";
export const TEMPLATE_CATEGORY_DETAILS_GET = "template_category/detail";
export const TEMPLATE_CATEGORY_DELETE_GET = "/template_category/delete";


export const TEMPLATE_VARIABLE_LIST_GET = "/template_variable/list";
export const TEMPLATE_VARIABLE_ADD = "/template_variable/add";
export const TEMPLATE_VARIABLE_DELETE = "/template_variable/delete";
export const TEMPLATE_VARIABLE_DETAILS_GET = "/template_variable/detail";
export const TEMPLATE_VARIABLE_CREATE = "/template_variable/create";
export const TEMPLATE_VARIABLE_UPDATE = "/template_variable/update";


export const TEMPLATE_BANK_LIST = "/template_bank/list";
export const TEMPLATE_BANK_CREATE_GET = "/template_bank/create";
export const TEMPLATE_BANK_ADD = "/template_bank/add";
export const TEMPLATE_BANK_EDIT_GET = "/template_bank/edit";
export const TEMPLATE_BANK_UPDATE = "/template_bank/update";
export const TEMPLATE_BANK_DETAILS_GET = "/template_bank/detail";
export const TEMPLATE_BANK_DELETE_GET = "/template_bank/delete";
export const TEMPLATE_BANK_IMAGE_UPLOAD = "/template_bank/fileupload";

