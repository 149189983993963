import React from "react";
import { Dropdown, Button } from "react-bootstrap";
import { Views } from "react-big-calendar";
export const CalendarViewModeButtons = ({ viewMode, setViewMode }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle className="px-3">
        {(viewMode === Views.MONTH && "Month") ||
          (viewMode === Views.WEEK && "Week") ||
          (viewMode === Views.WORK_WEEK && "Work Week") ||
          (viewMode === Views.DAY && "Day") ||
          "Agenda"}
      </Dropdown.Toggle>
      <Dropdown.Menu isAlignmentEnd>
        <Dropdown.Item
          className="btn btn-primary d-flex align-items-center gap-2 justify-content-start px-3 mb-2"
          icon="calendar_view_month"
          isActive={viewMode === Views.MONTH}
          onClick={() => setViewMode(Views.MONTH)}>
          <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="icon-20"
            width="32"
            height="32"
            viewBox="0 0 24 24">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3 6.5C3 3.87479 3.02811 3 6.5 3C9.97189 3 10 3.87479 10 6.5C10 9.12521 10.0111 10 6.5 10C2.98893 10 3 9.12521 3 6.5Z"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14 6.5C14 3.87479 14.0281 3 17.5 3C20.9719 3 21 3.87479 21 6.5C21 9.12521 21.0111 10 17.5 10C13.9889 10 14 9.12521 14 6.5Z"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3 17.5C3 14.8748 3.02811 14 6.5 14C9.97189 14 10 14.8748 10 17.5C10 20.1252 10.0111 21 6.5 21C2.98893 21 3 20.1252 3 17.5Z"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14 17.5C14 14.8748 14.0281 14 17.5 14C20.9719 14 21 14.8748 21 17.5C21 20.1252 21.0111 21 17.5 21C13.9889 21 14 20.1252 14 17.5Z"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"></path>
          </svg>
          Month
        </Dropdown.Item>
        <Dropdown.Item
          className="btn bg-soft-primary d-flex align-items-center gap-2 justify-content-start px-3  mb-2"
          color="link"
          icon="calendar_view_week"
          isActive={viewMode === Views.WEEK}
          onClick={() => setViewMode(Views.WEEK)}>
          <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="icon-20"
            width="32"
            height="32"
            viewBox="0 0 24 24">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3 6.5C3 3.87479 3.02811 3 6.5 3C9.97189 3 10 3.87479 10 6.5C10 9.12521 10.0111 10 6.5 10C2.98893 10 3 9.12521 3 6.5Z"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14 6.5C14 3.87479 14.0281 3 17.5 3C20.9719 3 21 3.87479 21 6.5C21 9.12521 21.0111 10 17.5 10C13.9889 10 14 9.12521 14 6.5Z"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3 17.5C3 14.8748 3.02811 14 6.5 14C9.97189 14 10 14.8748 10 17.5C10 20.1252 10.0111 21 6.5 21C2.98893 21 3 20.1252 3 17.5Z"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14 17.5C14 14.8748 14.0281 14 17.5 14C20.9719 14 21 14.8748 21 17.5C21 20.1252 21.0111 21 17.5 21C13.9889 21 14 20.1252 14 17.5Z"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"></path>
          </svg>
          Week
        </Dropdown.Item>
        <Dropdown.Item
          className="btn bg-soft-primary d-flex align-items-center gap-2 justify-content-start px-3  mb-2"
          color="link"
          icon="view_week"
          isActive={viewMode === Views.WORK_WEEK}
          onClick={() => setViewMode(Views.WORK_WEEK)}>
          <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="icon-20"
            width="32"
            height="32"
            viewBox="0 0 24 24">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3 6.5C3 3.87479 3.02811 3 6.5 3C9.97189 3 10 3.87479 10 6.5C10 9.12521 10.0111 10 6.5 10C2.98893 10 3 9.12521 3 6.5Z"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14 6.5C14 3.87479 14.0281 3 17.5 3C20.9719 3 21 3.87479 21 6.5C21 9.12521 21.0111 10 17.5 10C13.9889 10 14 9.12521 14 6.5Z"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3 17.5C3 14.8748 3.02811 14 6.5 14C9.97189 14 10 14.8748 10 17.5C10 20.1252 10.0111 21 6.5 21C2.98893 21 3 20.1252 3 17.5Z"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14 17.5C14 14.8748 14.0281 14 17.5 14C20.9719 14 21 14.8748 21 17.5C21 20.1252 21.0111 21 17.5 21C13.9889 21 14 20.1252 14 17.5Z"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"></path>
          </svg>
          Work Week
        </Dropdown.Item>
        <Dropdown.Item
          className="btn bg-soft-primary d-flex align-items-center gap-2 justify-content-start px-3  mb-2"
          color="link"
          isActive={viewMode === Views.DAY}
          onClick={() => setViewMode(Views.DAY)}>
          <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="icon-20"
            width="32"
            height="32"
            viewBox="0 0 24 24">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3 6.5C3 3.87479 3.02811 3 6.5 3C9.97189 3 10 3.87479 10 6.5C10 9.12521 10.0111 10 6.5 10C2.98893 10 3 9.12521 3 6.5Z"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14 6.5C14 3.87479 14.0281 3 17.5 3C20.9719 3 21 3.87479 21 6.5C21 9.12521 21.0111 10 17.5 10C13.9889 10 14 9.12521 14 6.5Z"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3 17.5C3 14.8748 3.02811 14 6.5 14C9.97189 14 10 14.8748 10 17.5C10 20.1252 10.0111 21 6.5 21C2.98893 21 3 20.1252 3 17.5Z"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14 17.5C14 14.8748 14.0281 14 17.5 14C20.9719 14 21 14.8748 21 17.5C21 20.1252 21.0111 21 17.5 21C13.9889 21 14 20.1252 14 17.5Z"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"></path>
          </svg>
          Day
        </Dropdown.Item>
        <Dropdown.Item
          className="btn bg-soft-primary d-flex align-items-center gap-2 justify-content-start px-3 "
          color="link"
          isActive={viewMode === Views.AGENDA}
          onClick={() => setViewMode(Views.AGENDA)}>
          <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="icon-20"
            width="32"
            height="32"
            viewBox="0 0 24 24">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3 6.5C3 3.87479 3.02811 3 6.5 3C9.97189 3 10 3.87479 10 6.5C10 9.12521 10.0111 10 6.5 10C2.98893 10 3 9.12521 3 6.5Z"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14 6.5C14 3.87479 14.0281 3 17.5 3C20.9719 3 21 3.87479 21 6.5C21 9.12521 21.0111 10 17.5 10C13.9889 10 14 9.12521 14 6.5Z"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3 17.5C3 14.8748 3.02811 14 6.5 14C9.97189 14 10 14.8748 10 17.5C10 20.1252 10.0111 21 6.5 21C2.98893 21 3 20.1252 3 17.5Z"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14 17.5C14 14.8748 14.0281 14 17.5 14C20.9719 14 21 14.8748 21 17.5C21 20.1252 21.0111 21 17.5 21C13.9889 21 14 20.1252 14 17.5Z"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"></path>
          </svg>
          Agenda
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CalendarViewModeButtons;
