import {
  memo,
  Fragment,
  useState,
  useRef,
  useEffect,
  useCallback,
} from "react";

//react-bootstrap
import { Badge, Button, Card, Col, Row } from "react-bootstrap";

//components

import ReactTable from "../../../../../lib/table/react-table";
import { findIcon } from "../../../../dashboard/icons";
import TemplateFilter from "../../../../../components/common-components/template-filter";
import useRedirect from "../../../../../components/custom-hooks/use-redirect";
import postService from "../../../../../api/services/post-service";
import {
  EMAIL_TEMPLATE_DELETE,
  EMAIL_TEMPLATE_LIST_GET,
} from "../../../../../api/endpoints/template-management-endpoints";
import ActionButtonBar from "../../../../../components/partials/common/action-button-bar";
import {
  changeTableStateToPayload,
  statusToBg,
} from "../../../../../common/utils/utils";
import DeleteModal from "../../../../../components/partials/common/delete-modal";
import { getService } from "../../../../../api/services/get-services";
import ViewModal from "../../../../../components/partials/common/view-modal";
import EditModal from "../../../../../components/partials/common/edit-modal";
import toast from "react-hot-toast";
import Filter from "../../../content-studio/component/filter";
import FilterBar from "../../../../../components/filter-bar";
import CommonTableLoader from "../../../../../skeleton/common-table-loader";
import PageNotFound from "../../../../PageNotFound";
import {
  FILTER_TYPES,
  OPERATOR_TYPES,
} from "../../../../../lib/table/constants";
import Skeleton from "../../../../../components/skeleton";

const AllEmailTemplates = () => {
  const [modalShow, setModalShow] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const columns = [
    { Header: "Name", accessor: "email_name" },
    // {
    //   Header: "Content",
    //   accessor: "email_content",
    //   Cell: ({ value }) => {
    //     return (
    //       <div
    //         className="text-start text-truncate"
    //         style={{ maxWidth: "20rem" }}
    //       >
    //         {value}
    //       </div>
    //     );
    //   },
    // },
    // { Header: "Brand", accessor: "brand" },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => (
        <div className="d-flex align-items-center justify-content-center">
          <Badge bg={statusToBg(row?.values?.status?.value)} className="me-2">
            {row?.values?.status?.value ? (
              <span className="btn-inner">{row.values.status.value}</span>
            ) : (
              <span className="btn-inner">Unable To Find</span>
            )}
          </Badge>
        </div>
      ),
    },
    // {
    //   Header: "Email Subject",
    //   accessor: "email_subject",
    // },
    // {
    //   Header: "Email Template Type",
    //   accessor: "email_template_type",
    //   Cell: ({ value }) => value?.value ?? "N/A",
    // },
    {
      Header: "Email Template Category",
      accessor: "email_category_id",
      Cell: ({ value }) => {
        return value && value?.category_name ? value?.category_name : "N/A";
      },
    },
    // {
    //   Header: "Approved By",
    //   accessor: "approved_by",
    //   Cell: ({ value }) => {
    //     return value && value?.name ? value?.name : "N/A";
    //   },
    // },
    {
      Header: "Creation By",
      accessor: "created_by",
      Cell: ({ row }) => {
        return row?.original.created_by?.name;
      },
    },
    {
      Header: "Action",
      accessor: "iconAction",
      disableFilters: true,
      Cell: ({ row }) => (
        <ActionButtonBar
          row={row}
          onView={() =>
            // viewEmailModal(row?.original)
            {
              redirectTo(
                "template-management/email-template/details/" +
                  row.original.email_id
              );
            }
          }
          onEdit={() =>
            // onEmailEdit(row.original)
            {
              redirectTo(
                "template-management/email-template/create/" +
                  row.original.email_id
              );
            }
          }
          onDelete={() => {
            setSelectedTemplate(row?.original?.email_id);
            modalOpen();
          }}
        />
      ),
    },
  ];
  const dropdownList = [
    "Title",
    "Approved By",
    "Content Name",
    "Brand",
    "Status",
    "Approved By",
    "Creation Date",
    "Action",
  ];
  const { redirectTo } = useRedirect();
  const post_json = useRef({
    default: "all",
    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      email_id: "desc",
    },
  });

  const fetchTemplate = async () => {
    try {
      // setLoading(true);
      const response = await postService(
        EMAIL_TEMPLATE_LIST_GET,
        post_json.current
      );
      console.log("email template service ", response.data.data);
      // console.log("COLUMNS", Object.keys(response.data.data.rows[0]));
      if (response.isError) {
        toast.error(response?.error);
      } else {
        if (response.data.data.rows.length > 0) {
          setTemplateList(response.data.data.rows);
          setTotalCount(response.data.data.totalCount);
          setLoading(false);
        } else {
          setTemplateList([]);
          setTotalCount(0);
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
      // console.log("USER_SERVICE_ERROR", error);
    }
  };
  useEffect(() => {
    fetchTemplate();
  }, []);

  const onTableStateChange = useCallback(
    async (state) => {
      let paylod = changeTableStateToPayload(state);
      post_json.current = paylod;
      fetchTemplate();
    },
    [templateList, loading, post_json.current]
  );

  const modalOpen = () => {
    setModalShow(true);
  };
  const modalClose = useCallback(() => {
    setSelectedTemplate(null);
    setModalShow(false);
  }, [modalShow]);

  const deleteTemplate = async () => {
    try {
      setLoading(true);
      const response = await getService(
        `${EMAIL_TEMPLATE_DELETE}/${selectedTemplate}`,
        {}
      );
      console.log(response);
      if (response) {
        setLoading(false);
        setModalShow(false);
        if (response.data.success) {
          toast.success(response.data.message);
        }
        if (response.isError) {
          toast.error(response?.error);
        }
        setTimeout(() => {
          fetchTemplate();
        }, 500);
      }
    } catch (error) {
      setLoading(false);
      // console.log("USER_SERVICE_ERROR", error);
    }
  };

  const onRefreshClick = () => {
    setTemplateList([]);
    fetchTemplate();
  };

  const onEditBtnClick = () => {
    redirectTo("template-management/email-template/create/1");
  };
  const inputFilters = [
    {
      name: "email_name",
      type: FILTER_TYPES.TEXT,
      placeholder: "Email Name",
      label: "Email Name",
    },
    {
      name: "email_subject",
      type: FILTER_TYPES.TEXT,
      placeholder: "Email Subject",
      label: "Email Subject",
    },
    {
      name: "email_template_type",

      type: FILTER_TYPES.SELECT,
      placeholder: "Email Template",
      label: "Email Template",
      options: [
        { value: "1", label: "Template 1" },
        { value: "2", label: "Template 2" },
        { value: "3", label: "Template 3" },
      ],
    },
    {
      name: "status",
      type: FILTER_TYPES.RADIO,
      placeholder: "Status",
      label: "Status",
      options: [
        { value: "1", label: "Active" },
        { value: "2", label: "Inactive" },
      ],
    },
  ];
  const advancedFilters = [
    {
      name: "email_name",
      type: FILTER_TYPES.TEXT,
      placeholder: "Email Name",
      label: "Email Name",
      operators: OPERATOR_TYPES.IS_OR_IS_NOT,
    },
    {
      name: "email_subject",
      type: FILTER_TYPES.TEXT,
      placeholder: "Email Subject",
      label: "Email Subject",
      operators: OPERATOR_TYPES.IS_OR_IS_NOT,
    },
  ];

  return (
    <Fragment>
      {/* <Button
        variant="success"
        className="d-flex align-items-center justify-content-center add_floating__button position-fixed rounded-circle p-1 avatar-80 lh-1 border border-white border-2"
        onClick={() => redirectTo("template-management/email-template/bank")}
      >
        {findIcon("Plus", "outline", "32")}
      </Button> */}
      <Row>
        <Col sm="12" className="p-0 px-md-3">
          <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
            <div className="d-flex flex-column">
              <h3 className="m-0">Email Templates</h3>
            </div>
            <FilterBar />
            <Button
              variant="success"
              className="d-flex align-items-center justify-content-center add_floating__button position-fixed rounded-circle p-1 avatar-80 lh-1 border border-white border-2"
              onClick={() =>
                redirectTo("template-management/email-template/bank")
              }
            >
              {findIcon("Plus", "outline", "32")}
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm="12" className="p-0 px-md-3">
          <Card>
            {/* {!loading ? (
              templateList?.length > 0 ? (
                <Card.Header className="d-flex justify-content-between">
                  <Row className="w-100 m-0">
                    <div className="header-title mb-5 col-12 p-0">
                      <h4 className="card-title">Email Templates</h4>
                    </div>
                    <FilterBar
                      inputs={inputFilters}
                      advancedFilters={advancedFilters}
                    />
                  </Row>
                </Card.Header>
              ) : (
                <></>
              )
            ) : (
              <div className="header-title mt-5 ms-5 col-2 p-0">
                <Skeleton width="10" height="20" />
              </div>
            )} */}
            <Card.Body className="p-2 p-md-4">
              <div className="table-responsive">
                {!loading ? (
                  templateList?.length > 0 ? (
                    <ReactTable
                      data={templateList}
                      columns={columns}
                      recordsTotal={totalCount}
                      onTableStateChange={onTableStateChange}
                      recordsPerPage={post_json.current.pagination.limit}
                      initialState={post_json.current}
                    />
                  ) : (
                    <PageNotFound />
                  )
                ) : (
                  <CommonTableLoader />
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <DeleteModal
        show={modalShow}
        modalClose={modalClose}
        onSubmit={deleteTemplate}
      />
      {/* <EditModal
        title={"Edit Email Template"}
        show={showEditModal}
        modalClose={editModalclose}
        data={editableData}
      /> */}
    </Fragment>
  );
};

export default AllEmailTemplates;
