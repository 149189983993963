import React from "react";
import { Row, Button, Col } from "react-bootstrap";
import AnalyticsCard from "../analytics-card";
import { findIcon } from "../../../../dashboard/icons";

export default function SegmentAnalyticsSection({
  segmentSize = "",
  preferEmail = "",
  preferWhatsApp = "",
  preferSms = "",
  excludingDnd = "",
  availableTouchPoint = "",
  barredByDndStatus = "",
  totalReach = "",
}) {
  return (
    <>
      <Row className="mt-n4 flex-wrap">
        <Col lg={3} md={6} className="mt-4 d-flex">
          <AnalyticsCard
            start={segmentSize > 0 ? segmentSize - 20 : 0}
            end={segmentSize > 0 ? segmentSize : 0}
            color="primary"
            title="Segment Size"
            icon={findIcon("Infographic", "dual-tone", "24")}
          />
        </Col>
        <Col lg={3} md={6} className="mt-4 d-flex">
          <AnalyticsCard
            start={preferEmail > 0 ? preferEmail - 20 : 0}
            end={preferEmail > 0 ? preferEmail : 0}
            color="warning"
            title="Prefer Email"
            icon={findIcon("Email", "dual-tone", "24")}
          />
        </Col>
        <Col lg={3} md={6} className="mt-4 d-flex">
          <AnalyticsCard
            start={preferWhatsApp > 0 ? preferWhatsApp - 20 : 0}
            end={preferWhatsApp > 0 ? preferWhatsApp : 0}
            color="info"
            title="Prefer WhatsApp"
            icon={findIcon("Whatsapp", "dual-tone", "24")}
          />
        </Col>
        <Col lg={3} md={6} className="mt-4 d-flex">
          <AnalyticsCard
            start={preferSms > 0 ? preferSms - 20 : 0}
            end={preferSms > 0 ? preferSms : 0}
            color="secondary"
            title="Prefer SMS
            "
            icon={findIcon("Message-2", "dual-tone", "24")}
          />
        </Col>
        <Col lg={3} md={6} className="mt-4 d-flex">
          <AnalyticsCard
            start={excludingDnd > 0 ? excludingDnd - 20 : 0}
            end={excludingDnd > 0 ? excludingDnd : 0}
            color="info"
            title="Excluding DND"
            icon={findIcon("Shield Check", "dual-tone", "24")}
          />
        </Col>

        <Col lg={3} md={6} className="mt-4 d-flex">
          <AnalyticsCard
            start={availableTouchPoint > 0 ? availableTouchPoint - 20 : 0}
            end={availableTouchPoint > 0 ? availableTouchPoint : 0}
            color="primary"
            title="Avg Available Touch Points
            "
            icon={findIcon("Action", "dual-tone", "24")}
          />
        </Col>
        <Col lg={3} md={6} className="mt-4 d-flex">
          <AnalyticsCard
            start={barredByDndStatus > 0 ? barredByDndStatus - 20 : 0}
            end={barredByDndStatus > 0 ? barredByDndStatus : 0}
            color="danger"
            title="Barred By DND status

            "
            icon={findIcon("Shield X", "dual-tone", "24")}
          />
        </Col>
        <Col lg={3} md={6} className="mt-4 d-flex">
          <AnalyticsCard
            start={totalReach > 0 ? totalReach - 20 : 0}
            end={totalReach > 0 ? totalReach : 0}
            color="success"
            title="Total Reach"
            icon={findIcon("Star", "dual-tone", "24")}
          />
        </Col>
      </Row>
    </>
  );
}
