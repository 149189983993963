import React, { useEffect, useRef, useState } from "react";
import Card from "../../../../components/bootstrap/card";
import { Accordion, Form } from "react-bootstrap";
import postService from "../../../../api/services/post-service";
import { ALL_FAQ_LIST } from "../../../../api/endpoints/help-and-support-endpoints";
import FaqLoader from "../../../../skeleton/helpsupport/faq-loader";

const FAQ = () => {
  const [faqList, setFaqList] = useState([]);
  const [userSearchSuggestions, setUserSearchSuggestions] = useState([]);
  const [activeKey, setActiveKey] = useState("");
  const [searchData, setSearchData] = useState("");
  const [loading, setLoading] = useState(false);
  const post_json = useRef({
    default: "all",

    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      faq_order: "desc",
    },
  });
  useEffect(() => {
    setLoading(true);
    fetchPriorities(post_json.current);
  }, []);
  const fetchPriorities = async (data) => {
    try {
      const response = await postService(ALL_FAQ_LIST, data);
      console.log("RESPONSE", typeof response.data.data.rows[0].faq_id);
      if (response?.data?.data?.rows?.length > 0) {
        setFaqList(response?.data?.data?.rows);
        setActiveKey(
          response?.data?.data?.rows?.length > 0
            ? `${response?.data?.data?.rows[0]?.faq_id}`
            : ""
        );
        setLoading(false);
      }

      // setUserSearchSuggestions(
      //   response.data.data.rows.map((i) => {
      //     return { value: i.faq_id, label: i.faq_name };
      //   })
      // );
    } catch (error) {
      console.log("FAQ_ERROR", error);
      setLoading(false);
    }
  };
  const getUserSearchData = (e) => {
    setSearchData(e.target.value);
    setUserSearchSuggestions(
      faqList && faqList.length > 0 && e.target.value !== ""
        ? faqList.filter((_li) =>
            _li.faq_name.toLowerCase().includes(e.target.value)
          )
        : []
    );
    setLoading(false);
  };
  const takeToSearchedFaq = (id, name) => {
    setActiveKey(id);
    setSearchData(name);
    setUserSearchSuggestions([]);
  };
  const setAcordianActiveKey = (key) => setActiveKey(key);
  const defaultKey = faqList.length > 0 ? `${faqList[0]?.faq_id}` : "";
  console.log("USER_SEARCH_DATA", userSearchSuggestions);
  return (
    <>
      <h3 className="mb-4">FAQ</h3>
      {!loading ? (
        <Card>
          <Card.Header className="d-flex flex-column flex-sm-row justify-content-between">
            <h5>Frequently Asked Questions</h5>
            <div className="form-group input-group mb-0 search-input position-relative ms-md-auto w-auto">
              <Form.Control
                type="text"
                placeholder="Search..."
                value={searchData}
                onChange={getUserSearchData}
              />
              <span className="input-group-text">
                <svg
                  width="20"
                  className="icon-20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="11.7669"
                    cy="11.7666"
                    r="8.98856"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinejoin="round"
                  ></circle>
                  <path
                    d="M18.0186 18.4851L21.5426 22"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </span>
              {userSearchSuggestions && userSearchSuggestions.length > 0 && (
                <div className="searchhint rounded-2 d-flex p-3 position-absolute mt-2 start-3 bg-primary w-100">
                  <ul className="ps-0 mb-0">
                    {userSearchSuggestions &&
                      userSearchSuggestions.length > 0 &&
                      userSearchSuggestions.map((i) => (
                        <li
                          className="list-unstyled mb-2 fs-5 text-white"
                          key={i.faq_id}
                          onClick={() =>
                            takeToSearchedFaq(`${i.faq_id}`, i.faq_name)
                          }
                        >
                          {i?.faq_name}
                        </li>
                      ))}
                  </ul>
                </div>
              )}
            </div>
          </Card.Header>
          <Card.Body>
            <div className="">
              <Accordion activeKey={activeKey} id={`_${defaultKey}`}>
                {faqList &&
                  faqList.length > 0 &&
                  faqList.map((_li) => (
                    <Accordion.Item
                      eventKey={`${_li.faq_id}`}
                      key={_li.faq_id}
                      id={`r_${_li.faq_id}`}
                      onClick={() => setAcordianActiveKey(`${_li.faq_id}`)}
                    >
                      <Accordion.Header>
                        <h5>{_li.faq_name}</h5>
                      </Accordion.Header>
                      <Accordion.Body>{_li.faq_description}</Accordion.Body>
                    </Accordion.Item>
                  ))}
              </Accordion>
            </div>
          </Card.Body>
        </Card>
      ) : (
        <FaqLoader />
      )}
    </>
  );
};

export default FAQ;
