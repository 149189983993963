import { Fragment, memo, useRef, useState } from "react";

//React-bootstrap
import { Button, Card, Image } from "react-bootstrap";

//Router

//Components

//Sweet alert
import * as dayjs from "dayjs";
import { CONTENT_STUDIO_FILE_DETAILS } from "../../api/endpoints/content-studio-endpoints";
import { getService } from "../../api/services/get-services";
import { findIcon } from "../../views/dashboard/icons";
import FilePreviewModal from "../partials/common/file-preview-modal";

const ContentCard = memo((props) => {
  const {
    id,
    fileName,
    fileTypeIcon,
    fileDescription,
    fileType,
    createdBy,
    publicationDate,
    selectFunction,
  } = props;
  const [showFilePreview, setShowFilePreview] = useState("");
  const fileUrl = useRef("");
  const handlePreviewClose = () => setShowFilePreview("");

  const getFileDetails = async (type = "view") => {
    const response = await getService(CONTENT_STUDIO_FILE_DETAILS + id);
    if (response && response?.data?.success) {
      let fileResponse = response?.data?.data?.detail;
      let {
        content_to_file = {},
        content_to_av = {},
        content_to_text = {},
        content_brand_id = {},
        content_file_type_id = {},
        created_by = {},
        content_to_image = {},
      } = fileResponse;
      let content = content_to_file || content_to_av || content_to_text;
      let {
        title_html,
        description_html,
        storage_link = "",
        path = "",
      } = content;
      let file_url = storage_link || path || "";

      fileUrl.current = file_url;
    }
    if (type == "view") {
      setShowFilePreview(true);
    } else {
      selectFunction({
        id: id,
        fileName: fileName,
        fileTypeIcon: fileTypeIcon,
        fileDescription: fileDescription,
        fileType: fileType,
        createdBy: createdBy,
        fileLink: fileUrl.current,
        publishedDate: publicationDate,
      });
    }
  };

  console.log("response fileDetails", fileUrl.current);
  return (
    <Fragment>
      <Card className="position-relative overflow-hidden">
        <Card.Body className="p-3">
          {!(fileType == "Image" || fileType == "Video") ? (
            <>
              {" "}
              {/* for doc , xmlx and pdf */}
              {fileTypeIcon && (
                <div className="p-3 d-flex justify-content-center align-items-center rounded bg-light contentCard__img">
                  <Image
                    src={fileTypeIcon}
                    className="img-fluid icon-60"
                    alt=""
                  />
                </div>
              )}
            </>
          ) : (
            <>
              {/* for image and video */}
              {fileTypeIcon && (
                <div className="p-3 d-flex justify-content-center align-items-center rounded bg-light contentCard__img">
                  <Image
                    src={fileTypeIcon}
                    className="img-fluid icon-60"
                    alt=""
                  />
                </div>
              )}
            </>
          )}

          <div className="mt-3">
            <div className="d-flex align-items-start mb-2 gap-2">
              {fileTypeIcon && (
                <Image
                  src={fileTypeIcon}
                  className="img-fluid avatar-36 lh-1 d-flex align-items-center justify-content-center flex-shrink-0"
                  alt=""
                />
              )}
              <div className="flex-grow-1 fs-6 text-truncate">
                {fileName && (
                  <h5 className="text-dark mb-1 small text-truncate">
                    {fileName}
                  </h5>
                )}

                {publicationDate && (
                  <p className="fs-7 mb-0 lh-sm">
                    {dayjs(publicationDate).format("DD MMM YYYY")}
                  </p>
                )}
              </div>
            </div>

            <div className="d-flex justify-content-end align-items-center mt-3">
              {/* <span className="small mb-0 me-2 text-info">{props.imgMb}</span> */}
              <div className="d-flex justify-content-end align-items-center gap-2">
                <Button
                  variant="outline-primary"
                  className="p-0 icon-32 d-flex align-items-center justify-content-center rounded-circle"
                  // onClick={viewContent}
                  onClick={() => getFileDetails("view")}
                >
                  {findIcon("Eye", "solid", "16")}
                </Button>
                <Button
                  variant="primary"
                  className="p-0 icon-32 d-flex align-items-center justify-content-center rounded-circle"
                  // onClick={selectContent}
                  onClick={() => getFileDetails("select")}
                >
                  {findIcon("Arrow Right", "solid", "16")}
                </Button>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
      <FilePreviewModal
        mediaType={fileType}
        show={showFilePreview}
        viewModalClose={handlePreviewClose}
        title={fileName}
        brand=""
        createdBy=""
        createdOn=""
        description=""
        icon=""
        file_url={fileUrl.current}
        titleHtml=""
        coverImage=""
        descriptionHtml=""
        citation=""
      />
    </Fragment>
  );
});

ContentCard.displayName = "ContentCard";
export default ContentCard;
