import Skeleton from "../../components/skeleton";
import { Fragment } from "react";

//Componets

import {
  OverlayTrigger,
  Card,
  Tooltip,
  Dropdown,
  Button,
  Col,
} from "react-bootstrap";

const ResourcesLoader = () => {
  let tableData = [...Array(5).keys()].map((_el) => _el + 1);
  return (
    <Fragment>
      {tableData?.map((_td, indx) => {
        return (
          <>
            <Col key={indx + 1}>
              <Card>
                <Card.Body className="card-thumbnail">
                  <div
                    className="position-relative iq-video-hover"
                    key={indx + 1}
                  >
                    <div>
                      <div className="p-3 d-flex justify-content-center align-items-center rounded bg-light contentCard__img">
                        <Skeleton
                          variant="rectangular"
                          width={100}
                          height={100}
                          className="img-fluid icon-60"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    <div className="d-flex align-items-center mb-2 text-primary gap-2">
                      {/* <Skeleton variant="circular" width={20} height={20} /> */}
                      <h6 className="ms-2 mb-0">
                        <Skeleton variant="text" width={100} height={20} />
                      </h6>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </>
        );
      })}
    </Fragment>
  );
};

export default ResourcesLoader;
