import { useState, useEffect, memo, Fragment, createRef, useRef } from "react";

import {
  Row,
  Col,
  Form,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  FormCheck,
  OverlayTrigger,
  Table,
} from "react-bootstrap";

import Card from "../../../../components/bootstrap/card";
import dayjs from "dayjs";
import { Calendar, dayjsLocalizer, Views } from "react-big-calendar";
import CalendarTodayButton from "../components/calendar-today-button";
import { CalendarViewModeButtons } from "../components/calendar-view-mode-buttons";
import { Popover } from "react-bootstrap";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import { CAMPAIGN_LIST_POST } from "../../../../api/endpoints/campaign-endpoints";
import postService from "../../../../api/services/post-service";
import { SEGMENT_ANALYTICS_GET, SEGMENT_DETAILS_GET, SEGMENT_LIST_POST } from "../../../../api/endpoints/campaign-segment-management-endpoints";
import { getService } from "../../../../api/services/get-services";
const localizer = dayjsLocalizer(dayjs);
const now = new Date();

const CampaignCalendar = memo(() => {
  const [show, setShow] = useState(false);
  const [viewMode, setViewMode] = useState(Views.MONTH);
  const [date, setDate] = useState(dayjs().toDate());
  const [campaignList, setCampaignList] = useState([]);
  const [segmentList, setSegmentList] = useState([]);
  const [selectedSegment, setSelectedSegment] = useState([]);
  const [filteredDate, setFilteredDate] = useState({ startDate: "", endDate: "", });
  const [segmentDetails, setSegmentDetails] = useState({});
  const [analyticsData, setAnalyticsData] = useState({});
  const { redirectTo } = useRedirect();

  useEffect(() => {
    fetchSegmentList();
  }, []);

  useEffect(() => {
    if (selectedSegment.length > 0) {
      handleCampaignSearchBySegment();
    }
  }, [selectedSegment]);

  useEffect(() => {
    getCurrentDate();
  }, [date]);

  useEffect(() => {
    getCurrentDate();
  }, [viewMode]);

  useEffect(() => {
    if (filteredDate.startDate != "" && filteredDate.endDate != "") {
      const payload = {
        ...post_json.current,
        filter: {
          campaign_schedule_time: {
            type: "between",
            value: [filteredDate.startDate, filteredDate.endDate],
          },
        },
      };
      post_json.current = payload;
      fetchCampaignList(payload);
    }
  }, [filteredDate]);


  const post_json = useRef({
    filter: {
      default: "all",
    },
    pagination: {
      limit: 10,
      offset: 0,
    },
  });

  const post_json_segment_list = useRef({
    default: "all",
    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      segment_id: "desc",
    },
  });

  const fetchSegmentList = async () => {
    try {
      const response = await postService(
        SEGMENT_LIST_POST,
        post_json_segment_list.current
      );
      if (response.data.data.rows.length > 0) {
        setSegmentList(response.data.data.rows);
      }
    } catch (error) {
      // console.log("fetch segment error", error);
    }
  };

  const fetchCampaignList = async () => {
    setCampaignList([]);
    try {
      const response = await postService(CAMPAIGN_LIST_POST, post_json.current);
      if (response.data.data.rows.length > 0) {
        updatedRequiredFormat(response.data.data.rows);
      }
    } catch (error) {
      // console.log("USER_SERVICE_ERROR", error);
    }
  };

  const updatedRequiredFormat = (data) => {
    let tempCampaignArray = [];
    const outputFormat = 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (India Standard Time)';
    data.map((item) => {
      if (item?.campaign_schedule_time) {
        let startDate = dayjs(item?.campaign_schedule_time)
          .locale('en')
          .set('hour', 0)
          .set('minute', 0)
          .set('second', 0)
          .format(outputFormat)
        let endDate = dayjs(item?.campaign_schedule_time)
          .locale('en')
          .set('hour', 23)
          .set('minute', 59)
          .set('second', 59)
          .format(outputFormat)

        const parsedStartDate = dayjs(startDate, outputFormat).toDate();
        const parsedEndDate = dayjs(endDate, outputFormat).toDate();


        let obj = {
          id: item?.campaign_id,
          campaignName: item?.campaign_name,
          segmentId: item?.segment?.segment_id,
          segmentName: item?.segment?.segment_name,
          start: parsedStartDate,
          end: parsedEndDate
        };
        tempCampaignArray.push(obj);
      }
      else {
        let startDate = dayjs()
          .locale('en')
          .set('hour', 0)
          .set('minute', 0)
          .set('second', 0)
          .format(outputFormat)
        let endDate = dayjs()
          .locale('en')
          .set('hour', 23)
          .set('minute', 59)
          .set('second', 59)
          .format(outputFormat)

        const parsedStartDate = dayjs(startDate, outputFormat).toDate();
        const parsedEndDate = dayjs(endDate, outputFormat).toDate();


        let obj = {
          id: item?.campaign_id,
          campaignName: item?.campaign_name,
          segmentId: item?.segment?.segment_id,
          segmentName: item?.segment?.segment_name,
          start: parsedStartDate,
          end: parsedEndDate
        };
        tempCampaignArray.push(obj);
      }
    });
    setCampaignList(tempCampaignArray);
  };

  const handleCampaignSearchBySegment = () => {
    let selectedSegmentIds = selectedSegment.map((item) => item.segment_id);
    let payload = {};
    if (filteredDate.startDate != "" && filteredDate.endDate != "") {
      payload = {
        ...post_json.current,
        filter: {
          campaign_segment_id: {
            type: "eq",
            "value": selectedSegmentIds
          },
          campaign_schedule_time: {
            type: "between",
            value: [filteredDate.startDate, filteredDate.endDate],
          },
        },
      };
    }
    post_json.current = payload;
    fetchCampaignList(payload);
  };

  const handleSegmentClick = (item) => {
    setSelectedSegment((prevSelectedSegment) => [...prevSelectedSegment, item]);
  };

  const getCurrentDate = () => {
    let currentDate = date && date;

    if (currentDate) {
      const date = dayjs(currentDate);
      let filterStartDate = "";
      let filterEndDate = "";
      let dateFormat = "YYYY-MM-DD HH:mm:ss";
      if (viewMode === "month") {
        filterStartDate = date.startOf("month").format(dateFormat);
        filterEndDate = date.endOf("month").format(dateFormat);
      }
      if (viewMode === "week") {
        filterStartDate = date.startOf("week").format(dateFormat);
        filterEndDate = date.endOf("week").format(dateFormat);
      }
      if (viewMode === "day") {
        filterStartDate = date.startOf("day").format(dateFormat);
        filterEndDate = date.endOf("day").format(dateFormat);
      }
      if (viewMode === "agenda") {
        filterStartDate = date.startOf("day").format(dateFormat);
        filterEndDate = date.endOf("month").format(dateFormat);
      }
      if (viewMode === "work_week") {
        filterStartDate = date.startOf("week").startOf("day").add(1, "day").format(dateFormat);
        filterEndDate = date.endOf("week").startOf("day").subtract(1, "day").format(dateFormat);
      }
      setFilteredDate({
        startDate: filterStartDate,
        endDate: filterEndDate,
      });
    }
  };

  const goToDate = (data) => {
    setDate(data);
    setViewMode("day");
  };

  const onSelectEvent = (event) => {
    getSegmentDetails(event)
  };

  const getSegmentDetails = async (item) => {
    let response = await getService(
      `${SEGMENT_DETAILS_GET}/${item?.segmentId}`,
      {}
    );
    if (response) {
      getSegmentAnalyticsById(item?.segmentId);
      if (response.isError) {
        return false;
      }
      const responseData = response.data.data;
      console.log("segment details response", responseData);
      if (responseData) {
        setSegmentDetails(responseData);
        setShow(true);
      }
    }
  }

  const getSegmentAnalyticsById = async (_payload_id) => {
    console.log("getSegmentAnalyticsById", _payload_id);
    // setSegmentDetailsIsLoading(true);
    let response = await getService(`${SEGMENT_ANALYTICS_GET}/${183}`);
    if (response) {
      // setSegmentDetailsIsLoading(false);
      if (response.isError) {
        return false;
      }
      const responseData = response.data.data;
      console.log("segment analytics response", responseData);
      if (responseData) {
        setAnalyticsData(responseData);
      }
      // setSegmentCreateDataIsLoading(false);
    }
  };

  const handleClose = () => setShow(false);

  const getUnitType = (viewMode) => {
    let unitType = null;
    switch (viewMode) {
      case Views.WEEK:
      case Views.WORK_WEEK:
        unitType = Views.WEEK;
        break;
      case Views.MONTH:
      case Views.AGENDA:
        unitType = Views.MONTH;
        break;
      default:
        unitType = Views.DAY;
    }
    return unitType;
  };

  const getViews = () => {
    // @ts-ignore
    return Object.keys(Views).map((k) => Views[k]);
  };

  // Calendar Unit Type
  const unitType = getUnitType(viewMode);

  // View modes; Month, Week, Work Week, Day and Agenda
  const views = getViews();

  const MyEvent = (data) => {
    const { event } = data;
    return (
      <div className="row g-2">
        <div className="col text-truncate fs-6 fw-normal">
          {event?.campaignName}
        </div>
        <div className="col">
          <div className="row g-2 align-items-center">
            <div className="d-flex align-items-center justify-content-center text-uppercase rounded-circle overflow-hidden icon-24 bg-white">
              {event?.campaignName.charAt(0)}
            </div>
            <small className="col text-truncate text-dark">
              {event?.segmentName}
            </small>
          </div>
        </div>
        {/* {event?.users && (
          <div className="col-auto">
            <AvatarGroup size={18}>
              {event.users.map((user) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <Avatar key={user.src} {...user} />
              ))}
            </AvatarGroup>
          </div>
        )} */}
      </div>
    );
  };

  const MyWeekEvent = (data) => {
    const { event } = data;
    return (
      <div className="row g-2">
        <div className="col text-truncate fs-6 fw-normal">
          {event?.campaignName}
        </div>
        <div className="col-12">
          <div className="row g-1 align-items-baseline">
            <div className="d-flex align-items-center justify-content-center text-uppercase rounded-circle overflow-hidden icon-24 bg-white">
              {event?.campaignName.charAt(0)}
            </div>
            <small className="col text-truncate text-dark">
              {event?.segmentName}
            </small>
          </div>
        </div>
        {/* {event?.users && (
          <div className="col-12">
            <AvatarGroup size={18}>
              {event.users.map((user) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <Avatar key={user.src} {...user} />
              ))}
            </AvatarGroup>
          </div>
        )} */}
      </div>
    );
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    const isActiveEvent = start <= now && end >= now;
    const isPastEvent = end < now;
    const color = isActiveEvent ? "success" : event.color;
    return {
      className: `bg-soft-${color} text-${color} ${isActiveEvent ? "border border-success" : ""
        } ${isPastEvent ? "opacity-50" : ""}`,
    };
  };



  return (
    <Fragment>
      <Row>
        {/* Campaign Calender Heading  */}
        <Col lg="12">
          <Card>
            <Card.Body className=" d-flex align-items-center justify-content-between flex-wrap">
              <div className="card-title mb-0">
                <h4 className="mb-0">Campaign Calendar</h4>
              </div>
            </Card.Body>
          </Card>
        </Col>

        {/* Calender Segment Details View */}
        <Offcanvas
          id="segment-details"
          data-bs-keyboard="true"
          tabIndex="-1"
          aria-hidden="true"
          placement="end"
          show={show}
          onHide={handleClose}
        >
          <OffcanvasHeader closeButton>
            <Offcanvas.Title as="h5">
              Segment Details
            </Offcanvas.Title>
          </OffcanvasHeader>
          <OffcanvasBody>
            {/* <Form>
              <div className="row">
                <Form.Group className="col-12 form-group">
                  <Form.Label>
                    Select <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <Form.Select
                    name="type"
                    className="selectpicker form-control"
                    data-style="py-0"
                  >
                    <option value="">Please select</option>

                    <option value="text">Text</option>

                    <option value="video">Video</option>

                    <option value="audio">Audio</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="col-12 form-group">
                  <Form.Label>Text Box</Form.Label>
                  <Form.Control type="text" id="fname" placeholder="Topic" />
                </Form.Group>
                <Form.Group className="col-12 form-group">
                  <Form.Label>Text Box</Form.Label>
                  <Form.Control type="text" id="fname" placeholder="Topic" />
                </Form.Group>
                <Form.Group className="col-12 form-group">
                  <Form.Label>
                    Select <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <Form.Select
                    name="type"
                    className="selectpicker form-control"
                    data-style="py-0"
                  >
                    <option value="">Please select</option>

                    <option value="text">Text</option>

                    <option value="video">Video</option>

                    <option value="audio">Audio</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="col-12 form-group">
                  <Form.Label>
                    Select <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <Form.Select
                    name="type"
                    className="selectpicker form-control"
                    data-style="py-0"
                  >
                    <option value="">Please select</option>

                    <option value="text">Text</option>

                    <option value="video">Video</option>

                    <option value="audio">Audio</option>
                  </Form.Select>
                  <a
                    href=""
                    className="btn btn-sm bg-soft-primary rounded-3 mt-2"
                  >
                    Preview Template
                  </a>
                </Form.Group>
                <Form.Group className="col-12 form-group">
                  <Form.Label>Schedule Delivery</Form.Label>
                  <div>
                    <Form.Check className="form-check-inline">
                      <FormCheck.Input
                        type="radio"
                        name="radios"
                        className=""
                        id="exampleRadio1"
                      />
                      <FormCheck.Label className="" htmlFor="exampleRadio1">
                        Default radio
                      </FormCheck.Label>
                    </Form.Check>
                    <Form.Check className="form-check-inline">
                      <FormCheck.Input
                        type="radio"
                        name="radios"
                        className=""
                        id="exampleRadio2"
                      />
                      <FormCheck.Label className="" htmlFor="exampleRadio2">
                        Another radio
                      </FormCheck.Label>
                    </Form.Check>
                  </div>
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="exampleInputdate">Date Input</Form.Label>
                  <Form.Control
                    type="date"
                    id="exampleInputdate"
                    defaultValue="2019-12-18"
                  />
                </Form.Group>
              </div>
            </Form> */}
            <Table>
              <tbody>
                <tr>
                  <td>
                    <h6 className="mb-0">Segment Name</h6>
                  </td>
                  <td className="text-left">{segmentDetails?.segment_name}</td>
                </tr>
                <tr>
                  <td>
                    <h6 className="mb-0 align-top">Segment Description</h6>
                  </td>
                  <td className="text-left text-break-spaces" >{segmentDetails?.segment_description}</td>
                </tr>
                <tr>
                  <td>
                    <h6 className="mb-0">Total Reach</h6>
                  </td>
                  <td className="text-left">{analyticsData?.total_reach}</td>
                </tr>
                <tr>
                  <td>
                    <h6 className="mb-0">Segment Size</h6>
                  </td>
                  <td className="text-left">{analyticsData?.segment_size}</td>
                </tr>
                <tr>
                  <td>
                    <h6 className="mb-0">Email Preference</h6>
                  </td>
                  <td className="text-left">{analyticsData?.prefer_email}</td>
                </tr>
                <tr>
                  <td>
                    <h6 className="mb-0">SMS Preference</h6>
                  </td>
                  <td className="text-left">{analyticsData?.prefer_sms}</td>
                </tr>
                <tr>
                  <td>
                    <h6 className="mb-0">Whatsapp Preference</h6>
                  </td>
                  <td className="text-left">{analyticsData?.prefer_whatsapp}</td>
                </tr>
                <tr>
                  <td>
                    <h6 className="mb-0">Created By</h6>
                  </td>
                  <td className="text-left">{segmentDetails?.created_by?.name}</td>
                </tr>
                <tr>
                  <td>
                    <h6 className="mb-0">Modified By</h6>
                  </td>
                  <td className="text-left">{segmentDetails?.modified_by?.name}</td>
                </tr>
                <tr>
                  <td>
                    <h6 className="mb-0">Status</h6>
                  </td>
                  <td className={segmentDetails?.status?.value == "Active" ? "text-left text-success" : "text-left text-danger"}>{segmentDetails?.status?.value}</td>
                </tr>
              </tbody>
            </Table>
          </OffcanvasBody>
        </Offcanvas>

        {/* Segment List & Calendar View  */}
        <Col className="calendar-s" id="bookingcalendar">
          <Row>
            <Col lg="12">

              {/* Segment List Section */}
              <div className="row mb-4 g-3">
                {segmentList.map((item) => (
                  <div className="col-auto">
                    <OverlayTrigger
                      trigger="hover"
                      overlay={
                        <Popover>
                          <div className="h6">{`${item.segment_name}`}</div>
                        </Popover>
                      }
                    >
                      <div className="position-relative">
                        <a
                          href="javascript:void(0)"
                          className={
                            selectedSegment.includes(item)
                              ? "d-flex align-items-center justify-content-center fs-2 fw-bold rounded-circle overflow-hidden border border-3 text-uppercase icon-60 bg-white border-primary text-primary"
                              : "d-flex align-items-center justify-content-center fs-2 fw-bold rounded-circle overflow-hidden border border-3 text-uppercase icon-60 bg-white border-secondary text-secondary"
                          }
                          onClick={() => handleSegmentClick(item)}
                        >
                          {item.segment_name.charAt(0)}
                        </a>
                      </div>
                    </OverlayTrigger>
                  </div>
                ))}
              </div>

              {/* Calender Listing Section */}
              <Row>
                <Col lg="12" className="col-lg-12">
                  {/* Calendar Filter button  */}
                  <div className="d-flex justify-content-between align-items-end mb-3">
                    <CalendarTodayButton
                      unitType={unitType}
                      date={date}
                      setDate={setDate}
                      viewMode={viewMode}
                    />
                    <CalendarViewModeButtons
                      setViewMode={setViewMode}
                      viewMode={viewMode}
                    />
                  </div>
                  {/* Calender Listing  */}
                  {campaignList && campaignList?.length > 0 && (
                    <Card>
                      <Card.Body style={{ height: "600px" }}>
                        <Calendar
                          toolbar={false}
                          localizer={localizer}
                          events={campaignList}
                          defaultView={Views.MONTH}
                          views={views}
                          view={viewMode}
                          date={date}
                          onNavigate={(date) => goToDate(date)}
                          defaultDate={dayjs().toDate()}
                          onSelectEvent={onSelectEvent}
                          components={{
                            event: MyEvent, // used by each view (Month, Day, Week)
                            week: {
                              event: MyWeekEvent,
                            },

                            work_week: {
                              event: MyWeekEvent,
                            },
                          }}
                          eventPropGetter={eventStyleGetter}
                        />
                      </Card.Body>
                    </Card>
                  )}
                </Col>
              </Row>

            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
});

CampaignCalendar.displayName = "CampaignCalendar";
export default CampaignCalendar;
