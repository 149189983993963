export const contentStudioInnitialState = {
    contentStudioGetData: {
      content_studio_name: "",
      content_studio_content_id: "",
      content_studio_content_name: "",
      content_studio_content_icon: "",
      content_studio_content_publish_date: "",
      content_studio_content_description: "",
      content_studio_content_type: "",
      content_studio_content_created_by: "",
      content_studio_content_url: "",
      variations: [
        {
          id: 0,
          variation_name: "variation 1",
          templates: {
            whatsapp: {
              id: "",
              name: "",
              content: "",
              approve: "",
            },
            email: {
              id: "",
              name: "",
              content: "",
              approve: "",
            },
            sms: {
              id: "",
              name: "",
              content: "",
              approve: "",
            },
          },
          spam_status: 0,
          is_checkSpam_disable: false,
          is_variation_name_edit: false,
        },
      ],
      is_content_name_edit: false,
      channelStatus: { whatsapp: true, email: true, sms: true },
    },
  };