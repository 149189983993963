import { memo, Fragment } from "react";

//react-bootstrap
import { Badge, Button, Card, Col, Row } from "react-bootstrap";
// import FilterBar from "../../../../../components/filter-bar";

//components
// import Card from "../../../../../components/bootstrap/card";

// img
import shap1 from "../../../../../assets/images/shapes/01.png";
import shap2 from "../../../../../assets/images/shapes/02.png";
import shap3 from "../../../../../assets/images/shapes/03.png";
import shap4 from "../../../../../assets/images/shapes/04.png";
import shap5 from "../../../../../assets/images/shapes/05.png";
import shap6 from "../../../../../assets/images/shapes/06.png";

import Flatpickr from "react-flatpickr";
import ReactTable from "../../../../../lib/table/react-table";
import FilterBar from "../../../../../components/filter-bar";

const AllTelegramTemplates = () => {
  const actionView = (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="icon-32"
      width="32"
      height="32"
      viewBox="0 0 24 24"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.1614 12.0531C15.1614 13.7991 13.7454 15.2141 11.9994 15.2141C10.2534 15.2141 8.83838 13.7991 8.83838 12.0531C8.83838 10.3061 10.2534 8.89111 11.9994 8.89111C13.7454 8.89111 15.1614 10.3061 15.1614 12.0531Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.998 19.355C15.806 19.355 19.289 16.617 21.25 12.053C19.289 7.48898 15.806 4.75098 11.998 4.75098H12.002C8.194 4.75098 4.711 7.48898 2.75 12.053C4.711 16.617 8.194 19.355 12.002 19.355H11.998Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
    </svg>
  );

  const actionEdit = (
    <svg
      width="32"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="icon-32"
      height="32"
    >
      <path
        opacity="0.4"
        d="M19.9927 18.9534H14.2984C13.7429 18.9534 13.291 19.4124 13.291 19.9767C13.291 20.5422 13.7429 21.0001 14.2984 21.0001H19.9927C20.5483 21.0001 21.0001 20.5422 21.0001 19.9767C21.0001 19.4124 20.5483 18.9534 19.9927 18.9534Z"
        fill="currentColor"
      ></path>
      <path
        d="M10.309 6.90385L15.7049 11.2639C15.835 11.3682 15.8573 11.5596 15.7557 11.6929L9.35874 20.0282C8.95662 20.5431 8.36402 20.8344 7.72908 20.8452L4.23696 20.8882C4.05071 20.8903 3.88775 20.7613 3.84542 20.5764L3.05175 17.1258C2.91419 16.4915 3.05175 15.8358 3.45388 15.3306L9.88256 6.95545C9.98627 6.82108 10.1778 6.79743 10.309 6.90385Z"
        fill="currentColor"
      ></path>
      <path
        opacity="0.4"
        d="M18.1208 8.66544L17.0806 9.96401C16.9758 10.0962 16.7874 10.1177 16.6573 10.0124C15.3927 8.98901 12.1545 6.36285 11.2561 5.63509C11.1249 5.52759 11.1069 5.33625 11.2127 5.20295L12.2159 3.95706C13.126 2.78534 14.7133 2.67784 15.9938 3.69906L17.4647 4.87078C18.0679 5.34377 18.47 5.96726 18.6076 6.62299C18.7663 7.3443 18.597 8.0527 18.1208 8.66544Z"
        fill="currentColor"
      ></path>
    </svg>
  );

  const data = [
    {
      img: `${shap1}`,
      name: "Unlayer is an email editor and page builder to build beautiful...",
      phone: "(760) 756 7568",
      email: "annasthesia@gmail.com",
      country: "USA",
      contentName: "Product brochures",
      brand: "Budecort",
      action: {
        status: "Approved",
        color: "primary",
      },
      company: "Acme Corporation",
      joindate: "2019/12/01",
      iconAction: {
        iconedit: actionEdit,
        iconview: actionView,
        // iconremove: remove,
      },
    },
    {
      img: `${shap2}`,
      name: "Unlayer is an email editor...",
      phone: "+62 5689 458 658",
      email: "brocklee@gmail.com",
      country: "Indonesia",
      contentName: `Whitepapers on the company's research and development`,
      brand: "Montair FX",
      action: {
        status: "Approved",
        color: "primary",
      },
      company: "Soylent Corp",
      joindate: "2019/12/01",
      iconAction: {
        iconedit: actionEdit,
        iconview: actionView,
        // iconremove: remove,
      },
    },
    {
      img: `${shap3}`,
      name: "Unlayer is an email editor and page builder to build beautiful...and page builder...",
      phone: "+55 6523 456 856",
      email: "dandruff@gmail.com",
      country: "Brazil",
      contentName: "Case studies of successful treatments",
      brand: "Glygard",
      action: {
        status: "Pending",
        color: "warning",
      },
      company: "Acme Corporation",
      joindate: "2019/12/01",
      iconAction: {
        iconedit: actionEdit,
        iconview: actionView,
        // iconremove: remove,
      },
    },
    {
      img: `${shap4}`,
      name: "Unlayer is an email editor and page builder to build beautiful...and page builder to...",
      phone: "+91 2586 253 125",
      email: "hansolo@gmail.com",
      country: "India",
      contentName: "Infographics on the pharmaceutical industry",
      brand: "Metfor",
      action: {
        status: "Rejected",
        color: "danger",
      },
      company: "Vehement Capital",
      joindate: "2019/12/01",
      iconAction: {
        iconedit: actionEdit,
        iconview: actionView,
        // iconremove: remove,
      },
    },
    {
      img: `${shap5}`,
      name: "Unlayer is an email...",
      phone: "+27 2563 456 589",
      email: "lynnguini@gmail.com",
      country: "Africa",
      contentName: "Social media posts and ads",
      brand: "Amlopres",
      action: {
        status: "Approved",
        color: "primary",
      },
      company: "Massive Dynamic",
      joindate: "2019/12/01",
      iconAction: {
        iconedit: actionEdit,
        iconview: actionView,
        // iconremove: remove,
      },
    },
    {
      img: `${shap6}`,
      name: "Unlayer is an email...",
      phone: "+55 25685 256 589",
      email: "ericshun@gmail.com",
      country: "Brazil",
      contentName: "Video testimonials from patients and healthcare providers",
      brand: "Metolar",
      action: {
        status: "Pending",
        color: "warning",
      },
      company: "Globex Corporation",
      joindate: "2019/12/01",
      iconAction: {
        iconedit: actionEdit,
        iconview: actionView,
        // iconremove: remove,
      },
    },
    {
      img: `${shap3}`,
      name: "Unlayer is an email editor and page builder to build beautiful...and page...",
      phone: "(760) 756 7568",
      email: "budwiser@ymail.com",
      country: "USA",
      contentName: "Email newsletters and campaigns",
      brand: "Glygard",
      action: {
        status: "Hold",
        color: "info",
      },
      company: "Acme Corporation",
      joindate: "2019/12/01",
      iconAction: {
        iconedit: actionEdit,
        iconview: actionView,
        // iconremove: remove,
      },
    },
    {
      img: `${shap5}`,
      name: "Unlayer is an email editor and page builder to build beautiful...and page builder to build...",
      phone: "+27 5625 456 589",
      email: "margearita@gmail.com",
      country: "Africa",
      contentName: "Webinars on industry topics or product",
      brand: "Montair FX",
      action: {
        status: "Draft",
        color: "success",
      },
      company: "Vehement Capital",
      joindate: "2019/12/01",
      iconAction: {
        iconedit: actionEdit,
        iconview: actionView,
        // iconremove: remove,
      },
    },
    {
      img: `${shap2}`,
      name: "Unlayer is an email editor...",
      phone: "+55 2563 456 589",
      email: "billdabear@gmail.com",
      country: "Brazil",
      contentName: "Infographics on the pharmaceutical industry",
      brand: "Metfor",
      action: { status: "Approved", color: "primary" },
      company: "Massive Dynamic",
      joindate: "2019/12/01",
      iconAction: {
        iconedit: actionEdit,
        iconview: actionView,
        // iconremove: remove,
      },
    },
  ];

  const columns = [
    { Header: "Title", accessor: "name" },
    { Header: "Content Name", accessor: "contentName" },
    { Header: "Brand", accessor: "brand" },
    {
      Header: "Status",
      accessor: "action",
      Cell: ({ row }) => (
        <div className="d-flex align-items-center justify-content-center">
          <Badge
            bg={
              row.values.action.status == "Approved"
                ? "primary"
                : row.values.action.status == "Draft"
                ? "success"
                : row.values.action.status == "Hold"
                ? "info"
                : row.values.action.status == "Pending"
                ? "warning"
                : row.values.action.status == "Rejected"
                ? "danger"
                : "primary"
            }
            className="me-2"
          >
            {row.values.action.status ? (
              <span className="btn-inner">{row.values.action.status}</span>
            ) : (
              <span className="btn-inner">Not Found</span>
            )}
          </Badge>
        </div>
      ),
    },
    { Header: "Approved By", accessor: "company" },
    { Header: "Creation Date", accessor: "joindate" },
    {
      Header: "Action",
      accessor: "iconAction",
      disableFilters: true,
      Cell: ({ row }) => (
        <div className="d-flex justify-content-between">
          <Button
            className="btn btn-icon btn-sm rounded-pill"
            to="#"
            role="button"
          >
            <span className="btn-inner">{row.values.iconAction.iconview}</span>
          </Button>

          <Button
            className="btn btn-icon btn-sm rounded-pill"
            to="#"
            role="button"
          >
            <span className="btn-inner">{row.values.iconAction.iconedit}</span>
          </Button>
        </div>
      ),
    },
  ];

  return (
    // <div>AllTelegramTemplates</div>
    <Fragment>
      <Row>
        <Col sm="12" className="p-0 px-md-3">
          <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
            <div className="d-flex flex-column">
              <h4 className="m-0">Telegram Templates</h4>
            </div>
            <FilterBar />
            {/* <Button
              type="button"
              variant="success"
              className="d-flex align-items-center justify-content-center add_floating__button position-fixed rounded-circle p-1 avatar-80 lh-1 border border-white border-2"
              onClick={() => handleCreate()}
            >
              {findIcon("Plus", "outline", "32")}
            </Button> */}
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm="12" className="p-0 px-md-3">
          <Card>
            {/* <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Telegram Templates</h4>
              </div>
            </Card.Header> */}
            <Card.Body className="p-2 p-md-4">
              <div className="table-responsive">
                <ReactTable data={data} columns={columns} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default AllTelegramTemplates;
