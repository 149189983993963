import { Modal } from "react-bootstrap";
import CreateEmailTemplate from "../../../views/modules/template-manager/pages/email/create-template";
import CreateSmsTemplate from "../../../views/modules/template-manager/pages/sms/create-template";
import CreateWhatsappTemplate from "../../../views/modules/template-manager/pages/whatsapp/create-template";

const CreateTemplateModal = ({ show, modalClose, type }) => {
  console.log('show_data',show, modalClose, type);
  return (
    <>
      <Modal
        show={show}
        // size="lg"
        fullscreen={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        {/* <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Create {type} template
          </Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          {type == "WhatsApp" && (
            <>
              <CreateWhatsappTemplate modalClose={modalClose} type = {"content-studio"}/>
            </>
          )}
          {type == "Email" && (
            <>
              <CreateEmailTemplate modalClose={modalClose} type = {"content-studio"}/>
            </>
          )}
          {type == "Message" && (
            <>
              <CreateSmsTemplate modalClose={modalClose} type = {"content-studio"}/>
            </>
          )}
        </Modal.Body>
        {/* <Modal.Footer>
          <Button onClick={modalClose}>Cancel</Button>
          <Button type="button" variant="danger" onClick={modalClose}>
            Create {type} template
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default CreateTemplateModal;
