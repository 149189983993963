export const ONBOARDING_CREATE_TASK = "onboarding/create_task";
export const RE_ONBOARDING_CREATE_TASK = "reonboarding/create_re_task";
export const ONBOARDING_CREATE_TASK_DATA = "onboarding/create";
export const ONBOARDING_EDIT_TASK_DATA = "onboarding/edit";
export const GET_ONBOARDING_ZONES = "onboarding/zone/";
export const GET_ONBOARDING_STATES = "onboarding/state/";
export const GET_ONBOARDING_CITIES = "onboarding/city/";

export const ONBOARDING_ADD_LOG = "onboarding/add_log";

export const ONBOARDING_LOG_TYPES = "onboarding/log_type";
