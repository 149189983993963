import React, { memo, useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import TemplateSuggestionCard from "./template-suggestion-card";
import Masonry from "react-masonry-css";
import toast from "react-hot-toast";
import { useLocation, useParams } from "react-router-dom";

import mobile from "../../../../../../assets/images/mobile-bg.png";
import * as Yup from "yup";
import { useFormik } from "formik";
import postServiceSmsGenarator from "../../../../../../api/services/post-service-sms-generator";
import PaginationLoader from "../../../../../../components/loader/pagination-loader";

const breakpointColumnsObj = {
  default: 3,
  1100: 2,
  500: 1,
};

export default function AutoGenerateFrom({
  generation_type = "",
  onSkip,
  onContinue,
  skipButton = false,
}) {
  const emailEditorRef = useRef(null);
  const [templateTone, setTemplateTone] = useState("");
  const [suggestionIsLoading, setSuggestionIsLoading] = useState(false);
  const [presetHTML, setPresetHTML] = useState(null);
  const [editor, setEditor] = useState(null);
  const [generatedSuggestionList, setGeneratedSuggestionList] = useState([]);
  const [selectedSms, setSelectedSms] = useState("-1");

  const initialValues = {
    name: "",
    description: "",
    tone: "",
  };

  const validationSchema = Yup.object().shape({
    category_name: Yup.string().required(" Select a Category"),
    sub_category_name: Yup.string().required("Select a Sub Category"),
    short_description: Yup.string().required("Enter Short Description"),
    highlights: Yup.string().required("Enter Highlights"),
    tone: Yup.string(),
    name: Yup.string().required(" Enter Template Name For Geneartion"),
    description: Yup.string().required(" Enter Template Name For Genearte"),
  });

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    handleReset,
    values,
    errors,
    touched,
    setErrors,
    setValues,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      let {
        template_name,
        sms_category,
        sms_template_type,
        template_id,
        sender,
        sms_dlt_id,
      } = values;
      //
    },
  });

  useEffect(() => {
    return () => {
      setTemplateTone("");
      setSuggestionIsLoading(false);
      setPresetHTML(null);
      setEditor(null);
      setGeneratedSuggestionList([]);
      setSelectedSms("-1");
    };
  }, []);

  const handleEmojiClick = (data) => {
    setTemplateTone(data);
  };

  const getSmsTemplateSuggestion = async () => {
    setSuggestionIsLoading(true);
    const templateSuggestionPayload = {
      action: values.name + " " + values.description,
      tone: templateTone,
      type: generation_type,
    };
    try {
      // setVariableIsLoading(true);
      const response = await postServiceSmsGenarator(templateSuggestionPayload);

      setSuggestionIsLoading(false);
      parseGeneratedSmsList(response.data.output);
    } catch (error) {
      setSuggestionIsLoading(false);
      console.log("USER_SERVICE_ERROR", error);
    }
  };

  const parseGeneratedSmsList = (data) => {
    console.log("parseGeneratedSmsList", data);
    setGeneratedSuggestionList(data);
    // setGeneratedSuggestionList(JSON.parse(data));
  };

  const checkValidation = () => {
    if (values.name == "") {
      setErrors({ ...errors, name: "Please enter template name" });
      return;
    }
    if (values.description == "") {
      setErrors({ ...errors, description: "Please enter description" });
      return;
    }
    if (!errors.name && !errors.description) {
      getSmsTemplateSuggestion();
    }
  };

  const onTemplateSuggestionClick = (data) => {
    setSelectedSms(data);
  };

  const onContinueClick = () => {
    const selectedSuggestion = generatedSuggestionList[selectedSms];
    console.log("selectedSuggestion", selectedSuggestion);
    onContinue(selectedSuggestion);
  };

  return (
    <>
      <div className="mb-5">
        <div className="d-flex justify-content-between mb-4">
          <h4 className="mb-0">Generate Template With AI</h4>
        </div>
      </div>
      <Card.Body>
        <div className="p-4 bg-soft-secondary rounded-3">
          <Row className="justify-content-center align-items-start">
            <Col lg={12} xs={12} className="position-relative">
              <div className="p-4 bg-white rounded-3 h-100 d-flex flex-column justify-content-between">
                <Row>
                  <Col md={4} className="mb-4">
                    <Form.Floating className="custom-form-floating">
                      <Form.Select
                        id="category_id"
                        name="category_id"
                        value={values?.category_name}
                        isInvalid={
                          touched.category_name && !!errors.category_name
                        }
                        onChange={handleChange}
                      >
                        <option value="">Select Category</option>
                        <option key={1} value={"value"}>
                          buisness
                        </option>
                      </Form.Select>
                      <Form.Label htmlFor="category_id">Category</Form.Label>
                      <Form.Control.Feedback type="invalid">
                        {errors.category_name}
                      </Form.Control.Feedback>
                    </Form.Floating>
                  </Col>

                  <Col md={4} className="mb-4">
                    <Form.Floating className="custom-form-floating">
                      <Form.Select
                        id="category_id"
                        name="category_id"
                        value={values?.category_name}
                        isInvalid={
                          touched.category_name && !!errors.category_name
                        }
                        onChange={handleChange}
                      >
                        <option value="">Select Sub Category</option>
                        <option key={1} value={"value"}>
                          sub category
                        </option>
                      </Form.Select>
                      <Form.Label htmlFor="category_id">
                        Sub Category
                      </Form.Label>
                      <Form.Control.Feedback type="invalid">
                        {errors.category_name}
                      </Form.Control.Feedback>
                    </Form.Floating>
                  </Col>

                  <Col md={4} className="mb-4">
                    <Form.Floating className="custom-form-floating">
                      <Form.Select
                        id="category_id"
                        name="category_id"
                        value={values?.category_name}
                        isInvalid={
                          touched.category_name && !!errors.category_name
                        }
                        onChange={handleChange}
                      >
                        <option value="">Select Tone</option>
                        <option key={1} value={"value"}>
                          sub category
                        </option>
                      </Form.Select>
                      <Form.Label htmlFor="category_id">Tone</Form.Label>
                      <Form.Control.Feedback type="invalid">
                        {errors.category_name}
                      </Form.Control.Feedback>
                    </Form.Floating>
                  </Col>

                  <Col lg={6} className="mb-3">
                    <Form.Floating className="custom-form-floating">
                      <Form.Control
                        as="textarea"
                        type="text"
                        className="h-100"
                        id="floatingInput1"
                        autoComplete="Name"
                        placeholder="Name"
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        isInvalid={errors.name}
                        disabled={suggestionIsLoading}
                      />
                      <Form.Label htmlFor="floatingInput">
                        Short Description
                      </Form.Label>
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    </Form.Floating>
                  </Col>
                  <Col lg={6} className="mb-3">
                    <Form.Floating className="custom-form-floating">
                      <Form.Control
                        as="textarea"
                        type="text"
                        className="h-100"
                        id="floatingInput1"
                        autoComplete="Name"
                        placeholder="Name"
                        name="description"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.description}
                        isInvalid={errors.description}
                        disabled={suggestionIsLoading}
                      />
                      <Form.Label htmlFor="floatingInput">
                        Highlights
                      </Form.Label>
                      <Form.Control.Feedback type="invalid">
                        {errors.description}
                      </Form.Control.Feedback>
                    </Form.Floating>
                  </Col>
                </Row>
                <Col lg={12}>
                  <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                  >
                    {generatedSuggestionList?.length > 0 &&
                      generatedSuggestionList.map((item, index) => (
                        <>
                          <TemplateSuggestionCard
                            id={index}
                            subject={item.subject}
                            body={item.body}
                            click={onTemplateSuggestionClick}
                            selectedItem={selectedSms}
                          />
                        </>
                      ))}
                  </Masonry>
                </Col>
                <Col className="flex-grow-0">
                  <div className="d-flex gap-3 justify-content-end">
                    {skipButton && (
                      <Button
                        variant="info"
                        onClick={onSkip}
                        disabled={suggestionIsLoading}
                      >
                        Skip
                      </Button>
                    )}
                    <Button
                      variant="primary"
                      onClick={checkValidation}
                      disabled={suggestionIsLoading}
                    >
                      {!suggestionIsLoading ? (
                        generatedSuggestionList?.length > 0 ? (
                          "Re-Generate"
                        ) : (
                          "Auto Generate"
                        )
                      ) : (
                        <PaginationLoader />
                      )}
                    </Button>
                    {selectedSms >= 0 && (
                      <Button variant="primary" onClick={onContinueClick}>
                        Continue To Edit
                      </Button>
                    )}
                  </div>
                </Col>
              </div>
            </Col>
          </Row>
        </div>
      </Card.Body>
    </>
  );
}
