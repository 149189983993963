import { memo, Fragment, useEffect, useState } from "react";
// React-bootstrap
import { Row, Col, Form, Image, Button, Container } from "react-bootstrap";

// Router
import { Link, Navigate, useLocation } from "react-router-dom";

//Img
import ciplaUnityLogo from "../../../../assets/images/client-logo/ciplaUnityLogo.svg";

//Component
import Autheffect from "../components/auth-effect";
import Card from "../../../../components/bootstrap/card";

import postService from "../../../../api/services/post-service";
import {
  GET_GOOGLE_LOGIN_LINK,
  LOGIN_POST,
} from "../../../../api/endpoints/auth-endpoints";
import PaginationLoader from "../../../../components/loader/pagination-loader";
import { updateStorage } from "../../../../utilities/setting";
import { storages } from "../../../../common/utils/utils";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { loginSuccess } from "../../../../store/auth/actions";
import { getService } from "../../../../api/services/get-services";
import * as authSelectors from "../../../../store/auth/selectors";
const Signin = memo(() => {
  const isAuthenticated = useSelector(authSelectors.isAuthenticated);

  const location = useLocation();
  const [loader, showLoader] = useState(false);
  const { redirectTo } = useRedirect();
  const dispatch = useDispatch();
  const initialValues = {
    email: "",
    password: "",
  };
  const schema = yup.object().shape({
    // email: yup.string().email().required("Please enter your email"),
    password: yup.string().min(6).required("Please enter your password"),
    email: yup
      .string()
      .required("Please enter your email")
      .test("Validate Email", "Please enter valid email", (value) => {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(value).toLowerCase());
      }),
  });
  const { values, errors, handleChange, handleSubmit, touched } = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (value, action) => {
      // redirectTo('dashboard')
      loginEmail(value, action);
    },
  });
  if (isAuthenticated) {
    return <Navigate to={"/dashboard"} />;
  }
  const loginEmail = async (value, action) => {
    showLoader(true);

    let formData = new FormData();
    formData.append("email", value.email);
    formData.append("password", value.password);

    let response = await postService(LOGIN_POST, formData);
    console.log("LOGIN DATA", response);
    showLoader(false);
    if (response.isError) {
      toast.error(response?.error);
    } else {
      console.log("login response", response.data);
      if (response.data?.user?.two_factor_enabled) {
        redirectTo("auth/two-factor", {
          user: response.data?.user ?? {},
          userRoles: response.data?.userRoles ?? [],
          token: response.data?.access_token ?? "",
        });
        return;
      }
      let access_token = response.data?.access_token;
      updateStorage(storages.local, "access_token", access_token);
      //sync the values to store
      dispatch(
        loginSuccess({
          user: response.data?.user ?? {},
          userRoles: response.data?.userRoles ?? [],
          token: access_token,
          isAuthenticated: true,
        })
      );
      action.resetForm();
      redirectTo("dashboard");
    }
  };
  const handleGoogleSignIn = async () => {
    console.log("google sign in");
    let location = window.location;
    let response = await getService(GET_GOOGLE_LOGIN_LINK, {
      redirect_url:
        location.origin +
        process.env.REACT_APP_SUB_PATH +
        "login/callback/google",
    });
    if (response.isError) {
      toast.error(response?.error);
    } else {
      console.log("google login response", response.data);
      let linkResponse = response.data;
      if (linkResponse?.success) {
        let googleOAuthLink = linkResponse?.data;
        // alert(linkResponse?.data);
        window.location.href = googleOAuthLink;
      }
    }
  };

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     redirectTo("dashboard");
  //   }
  // }, [isAuthenticated]);

  return (
    <Fragment>
      <div className="iq-auth-page">
        <Autheffect />
        <Container>
          <Row className="d-flex align-items-center iq-auth-container justify-content-center">
            <Col lg="7" className="py-3 py-md-4 text-center text-lg-start">
              <Image
                src={ciplaUnityLogo}
                alt="Engage logo"
                className="ciplaLogo mb-2"
              />
              {/* <h6>Engage</h6> */}
              <h5 className="h5 lh-base">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s,
              </h5>
            </Col>
            <Col lg="4">
              <Card>
                <Card.Body>
                  <div className="mb-2">
                    <h3 className="text-center">Sign In</h3>
                    <p className="text-center">Sign in to stay connected</p>
                  </div>
                  <Row>
                    <Form onSubmit={handleSubmit}>
                      <Col lg="12" className="mb-3">
                        <Form.Group>
                          <Form.Label>Email address</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Enter your email address"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            isInvalid={!!errors.email}
                          />

                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col lg="12" className="mb-3">
                        <Form.Group>
                          <Form.Label>Enter password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Enter your password here"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            isInvalid={!!errors.password}
                          />

                          <Form.Control.Feedback type="invalid">
                            {errors.password}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col
                        lg="12"
                        className="mb-3 d-flex justify-content-between align-items-center"
                      >
                        <Link to="/auth/reset-password">Forget password</Link>
                      </Col>

                      <Col lg="12" className="mb-3">
                        <Button
                          className="w-100 rounded-pill"
                          type="submit"
                          onClick={handleSubmit}
                        >
                          {loader ? <PaginationLoader /> : "Sign in"}
                        </Button>
                        {/*Add a google sign in button*/}
                        <Button
                          variant="danger"
                          className="w-100 mt-3 rounded-pill"
                          onClick={handleGoogleSignIn}
                        >
                          Sign in with Google
                        </Button>
                      </Col>
                    </Form>
                  </Row>
                  {/* <p className="text-center">
                    Don't have account?
                    <Link to="/auth/sign-up" className="ms-2">
                      {" "}
                      Click here to sign up.
                    </Link>
                  </p> */}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
});

Signin.displayName = "Signin";
export default Signin;
