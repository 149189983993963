import React, { Fragment, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Accordion,
  Table,
} from "react-bootstrap";
import ContentDetails from "../../../../components/partials/common/content-details";
import ReactTable from "../../../../lib/table/react-table";
import OnboardingLogModal from "../../../../components/partials/common/onboarding-log-modal";

const OnboardingTaskDetails = () => {
  const [showModal, setShowModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const closeModal = () => setShowModal(false);
  const log_json = {
    columns: [
      {
        Header: "Sl No",
        accessor: "slNo",
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Out Come",
        accessor: "outcome",
      },
      {
        Header: "Call Back",
        accessor: "callback",
      },
      {
        Header: "Note",
        accessor: "note",
      },
      {
        Header: "Added At",
        accessor: "addedAt",
        Cell: ({ value }) => new Date(value).toLocaleString(),
      },
    ],
    data: [
      {
        slNo: 1,
        type: "Email",
        outcome: "Sent",
        callback: "2023-05-10T10:00:00",
        note: "Sent project proposal to client",
        addedAt: "2023-05-10T10:05:00",
      },
      {
        slNo: 2,
        type: "Phone",
        outcome: "Answered",
        callback: "2023-05-12T13:00:00",
        note: "Discussed project timeline with client",
        addedAt: "2023-05-12T13:30:00",
      },
      {
        slNo: 3,
        type: "Meeting",
        outcome: "Scheduled",
        callback: null,
        note: "Scheduled project kickoff meeting with team",
        addedAt: "2023-05-15T09:00:00",
      },
    ],
  };

  return (
    <Fragment>
      <Container fluid>
        <ContentDetails />
        <Row>
          <Col lg="12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">User Details</h4>
                </div>
                <div className="d-flex justify-content-center gap-2">
                  {!isEdit && (
                    <Button variant="primary" onClick={() => setIsEdit(true)}>
                      Edit
                    </Button>
                  )}
                  <Button variant="primary" onClick={() => setShowModal(true)}>
                    Add Log
                  </Button>
                </div>
              </Card.Header>
              <Card.Body>
                {isEdit && (
                  <div className="acc-edit">
                    <Form>
                      <div className="form-group">
                        <Form.Label htmlFor="uname">User Name:</Form.Label>
                        <Form.Control
                          type="text"
                          id="uname"
                          defaultValue="Barry@01"
                        />
                      </div>
                      <div className="form-group">
                        <Form.Label htmlFor="email">Email Id:</Form.Label>
                        <Form.Control
                          type="email"
                          id="email"
                          defaultValue="Barryjohn@gmail.com"
                        />
                      </div>
                      <div className="form-group">
                        <Form.Label htmlFor="altemail">
                          Alternate Email:
                        </Form.Label>
                        <Form.Control
                          type="email"
                          id="altemail"
                          defaultValue="designtheme@gmail.com"
                        />
                      </div>
                      {/* Form fields of medical degree */}
                      <div className="form-group">
                        <Form.Label htmlFor="meddegree">
                          Medical Degree
                        </Form.Label>
                        <Form.Control
                          type="text"
                          id="meddegree"
                          defaultValue="
                        Bachelor of Medicine, Bachelor of Surgery (MBBS)"
                        />
                      </div>
                      {/* Form fields of registration certificate */}
                      <div className="form-group">
                        <Form.Label htmlFor="regcert">
                          Registration Certificate:
                        </Form.Label>
                        <Form.Control type="file" id="regcert" />
                      </div>
                      {/* Form fields of medical council */}
                      <div className="form-group">
                        <Form.Label htmlFor="medcouncil">
                          Medical Council:
                        </Form.Label>
                        <Form.Control
                          type="text"
                          id="medcouncil"
                          defaultValue="Medical Council of India"
                        />
                      </div>

                      <Accordion className="mb-3">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            Other Details of the user
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="form-group">
                              <Form.Label htmlFor="uname">
                                User Name:
                              </Form.Label>
                              <Form.Control
                                type="text"
                                id="uname"
                                defaultValue="Barry@01"
                              />
                            </div>
                            <div className="form-group">
                              <Form.Label htmlFor="email">Email Id:</Form.Label>
                              <Form.Control
                                type="email"
                                id="email"
                                defaultValue="Barryjohn@gmail.com"
                              />
                            </div>
                            <div className="form-group">
                              <Form.Label htmlFor="altemail">
                                Alternate Email:
                              </Form.Label>
                              <Form.Control
                                type="email"
                                id="altemail"
                                defaultValue="designtheme@gmail.com"
                              />
                            </div>
                            {/* Form fields of medical degree */}
                            <div className="form-group">
                              <Form.Label htmlFor="meddegree">
                                Alternate Email:
                              </Form.Label>
                              <Form.Control
                                type="text"
                                id="meddegree"
                                defaultValue="
                        Bachelor of Medicine, Bachelor of Surgery (MBBS)"
                              />
                            </div>
                            {/* Form fields of registration certificate */}
                            <div className="form-group">
                              <Form.Label htmlFor="regcert">
                                Registration Certificate:
                              </Form.Label>
                              <Form.Control type="file" id="regcert" />
                            </div>
                            {/* Form fields of medical council */}
                            <div className="form-group">
                              <Form.Label htmlFor="medcouncil">
                                Medical Council:
                              </Form.Label>
                              <Form.Control
                                type="text"
                                id="medcouncil"
                                defaultValue="Medical Council of India"
                              />
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      <div className="d-flex justify-content-center gap-2">
                        <Button>Submit</Button>
                        <Button
                          type="reset"
                          variant="danger"
                          onClick={() => setIsEdit(false)}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Form>
                  </div>
                )}
                {!isEdit && (
                  <>
                    <Table>
                      <tbody>
                        <tr>
                          <td>
                            <h6 className="mb-0">Category</h6>
                          </td>
                          <td className="text-left">Bulk Engagement</td>
                        </tr>
                        <tr>
                          <td>
                            <h6 className="mb-0">Type</h6>
                          </td>
                          <td className="text-left">Promotional</td>
                        </tr>
                        <tr>
                          <td>
                            <h6 className="mb-0">Name</h6>
                          </td>
                          <td className="text-left">SR_MAY8_TBRECUPD_01</td>
                        </tr>
                        <tr>
                          <td>
                            <h6 className="mb-0">Flow/Template Id</h6>
                          </td>
                          <td className="text-left">
                            6458c155d6fc0572c63b6883
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h6 className="mb-0">DLT ID</h6>
                          </td>
                          <td className="text-left">1107164726356426759</td>
                        </tr>
                        <tr>
                          <td>
                            <h6 className="mb-0">Sender</h6>
                          </td>
                          <td className="text-left">CIPLA</td>
                        </tr>
                        <tr>
                          <td>
                            <h6 className="mb-0">Service type</h6>
                          </td>
                          <td className="text-left">MedWiki</td>
                        </tr>
                        <tr>
                          <td>
                            <h6 className="mb-0">Status</h6>
                          </td>
                          <td className="text-left">Enable</td>
                        </tr>
                        <tr>
                          <td>
                            <h6 className="mb-0">Params</h6>
                          </td>
                          <td className="text-left">
                            Recent Updates on TB Treatment Dear Doctor, Click
                            here to register -CIPLA
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>

          {/* react-table with log json */}
          <Col lg="12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">Log</h4>
                </div>
              </Card.Header>
              <Card.Body>
                <div className="table-responsive">
                  <ReactTable columns={log_json.columns} data={log_json.data} />
                </div>
              </Card.Body>

              {/* button Add log */}
              {/* <Card.Footer className="d-flex justify-content-end">
                <Button variant="primary" onClick={() => setShowModal(true)}>
                  Add Log
                </Button>
              </Card.Footer> */}
            </Card>
          </Col>
        </Row>
      </Container>
      <OnboardingLogModal show={showModal} modalClose={closeModal} />
    </Fragment>
  );
};

export default OnboardingTaskDetails;
