import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import Chart from "react-apexcharts";
import ReactTable from "../../../../lib/table/react-table";
import { findIcon } from "../../../dashboard/icons";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
// import TableLoader from "../../../../skeleton/template/analytics/table-loader";
// import TemplateUsageLoader from "../../../../skeleton/template/analytics/template-usage-loader";
// import TemplateChannelLoader from "../../../../skeleton/template/analytics/template-channel-loader";
// import TemplateEngageLoader from "../../../../skeleton/template/analytics/template-engage-loader";

const Analytics = () => {
  const getVariableColor = () => {
    let prefix =
      getComputedStyle(document.body).getPropertyValue("--prefix") || "bs-";
    if (prefix) {
      prefix = prefix.trim();
    }
    const color1 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}primary`
    );
    const color2 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}info`
    );
    const color3 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}primary-tint-20`
    );
    const color4 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}warning`
    );
    const color5 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}success`
    );
    return {
      primary: color1.trim(),
      info: color2.trim(),
      warning: color4.trim(),
      primary_light: color3.trim(),
      success: color5.trim(),
    };
  };
  const variableColors = getVariableColor();
  const colors = [
    variableColors.primary,
    variableColors.success,
    variableColors.warning,
    variableColors.info,
    variableColors.primary_light,
  ];

  const { redirectTo } = useRedirect();
  // const chart1 = {
  //   series: [
  //     {
  //       name: "Number of Templates",
  //       data: [10, 41, 35, 51, 49],
  //     },
  //   ],
  //   options: {
  //     chart: {
  //       height: 350,
  //       type: "line",
  //       zoom: {
  //         enabled: false,
  //       },
  //       dropShadow: {
  //         enabled: true,
  //         color: "#000",
  //         top: 18,
  //         left: 7,
  //         blur: 10,
  //         opacity: 0.2,
  //       },
  //       toolbar: {
  //         show: false,
  //       },
  //     },
  //     dataLabels: {
  //       enabled: true,
  //     },
  //     stroke: {
  //       curve: "straight",
  //     },
  //     //   title: {
  //     //     text: "Top 5 Used Templates",
  //     //     align: "left",
  //     //   },
  //     grid: {
  //       row: {
  //         colors: ["#FFFFFF", "transparent"], // takes an array which will be repeated on columns
  //         opacity: 0.5,
  //       },
  //     },
  //     xaxis: {
  //       categories: [
  //         "Template A",
  //         "Template B",
  //         "Template C",
  //         "Template D",
  //         "Template E",
  //       ],
  //       title: {
  //         text: "Template Names",
  //       },
  //     },
  //     yaxis: {
  //       title: {
  //         text: "Number of Templates",
  //       },
  //     },
  //   },
  // };
  const chart2 = {
    series: [44, 55, 13],
    options: {
      chart: {
        width: 380,
        type: "pie",
      },
      labels: ["SMS", "Whatsapp", "Email"],
      colors: colors,
      plotOptions: {
        pie: {
          size: 200,
          dataLabels: {
            offset: -30,
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],

      //   dataLabels: {
      //     enabled: true,
      //     offsetX: 50,
      //     offsetY: 50
      //   },
      //   title: {
      //     text: "Template as Per Channels",
      //     align: "left",
      //   },
      legend: {
        position: "bottom",
      },
    },
  };
  const chart3 = {
    series: [
      {
        name: "Engagement",
        data: [55, 30, 10, 40, 20, 42, 18, 32, 80, 60],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      //   title: {
      //     text: "Top 5 Templates as per Engagement",
      //     align: "left",
      //   },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val;
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },

      xaxis: {
        categories: [
          "Template A",
          "Template B",
          "Template C",
          "Template D",
          "Template E",
          "Template F",
          "Template G",
          "Template H",
          "Template I",
          "Template J",
        ],
        position: "bottom",
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        title: {
          text: "Templates",
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        title: {
          text: "Engagement",
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val;
          },
        },
      },
    },
  };
  const chart4 = {
    series: [
      {
        data: [400, 430, 448, 470, 540],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 380,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          barHeight: "100%",
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: "bottom",
          },
        },
      },
      colors: colors,
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        style: {
          colors: ["#fff"],
        },
        formatter: function (val, opt) {
          return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
        },
        offsetX: 0,
        dropShadow: {
          enabled: true,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      xaxis: {
        categories: ["Brand A", "Brand B", "Brand C", "Brand D", "Brand E"],
        title: {
          text: "Number of Templates",
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
        title: {
          text: "Brands",
        },
      },

      legend: {
        show: false, // Set show property to false to hide the legends
      },
      //   title: {
      //     text: "Template usage as per Brand",
      //     align: "left",
      //     floating: true,
      //   },
      tooltip: {
        theme: "dark",
        x: {
          show: false,
        },
        y: {
          title: {
            formatter: function () {
              return "";
            },
          },
        },
      },
    },
  };
  // const chart5 = {
  //   series: [
  //     {
  //       data: [
  //         {
  //           x: "Template A",
  //           y: 218,
  //         },
  //         {
  //           x: "Template B",
  //           y: 149,
  //         },
  //         {
  //           x: "Template C",
  //           y: 184,
  //         },
  //         {
  //           x: "Template D",
  //           y: 55,
  //         },
  //         {
  //           x: "Template E",
  //           y: 84,
  //         },
  //         {
  //           x: "Template F",
  //           y: 31,
  //         },
  //         {
  //           x: "Template G",
  //           y: 70,
  //         },
  //         {
  //           x: "Template H",
  //           y: 30,
  //         },
  //         {
  //           x: "Template I",
  //           y: 44,
  //         },
  //         {
  //           x: "Template J",
  //           y: 68,
  //         },
  //         {
  //           x: "Template K",
  //           y: 28,
  //         },
  //         {
  //           x: "Template L",
  //           y: 19,
  //         },
  //         {
  //           x: "Template M",
  //           y: 29,
  //         },
  //       ],
  //     },
  //   ],
  //   options: {
  //     legend: {
  //       show: false,
  //     },
  //     chart: {
  //       height: 350,
  //       type: "treemap",
  //       toolbar: {
  //         show: false,
  //       },
  //     },
  //     title: {
  //       text: "Recent Templates",
  //       align: "left",
  //     },
  //     colors: [
  //       "#3B93A5",
  //       "#F7B844",
  //       "#ADD8C7",
  //       "#EC3C65",
  //       "#CDD7B6",
  //       "#C1F666",
  //       "#D43F97",
  //       "#1E5D8C",
  //       "#421243",
  //       "#7F94B0",
  //       "#EF6537",
  //       "#C0ADDB",
  //     ],
  //     plotOptions: {
  //       treemap: {
  //         distributed: true,
  //         enableShades: false,
  //       },
  //     },
  //   },
  // };
  const tableData = [
    {
      name: "Template A",
      content: "Template A Content",
      addedOn: "24-06-2023 at 09:15 am",
    },
    {
      name: "Template B",
      content: "Template B Content",
      addedOn: "26-06-2023 at 07:35 am",
    },
    {
      name: "Template C",
      content: "Template C Content",
      addedOn: "27-06-2023 at 08:15 pm",
    },
    {
      name: "Template D",
      content: "Template D Content",
      addedOn: "28-06-2023 at 12:15 pm",
    },
    {
      name: "Template E",
      content: "Template E Content",
      addedOn: "29-06-2023 at 10:00 am",
    },
  ];
  const columnData = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Content",
      accessor: "content",
    },
    {
      Header: "Added on",
      accessor: "addedOn",
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => (
        <div className="d-flex justify-content-center align-items-center gap">
          <div className="d-flex justify-content-center">
            <Button
              className="btn btn-icon btn-sm rounded-pill"
              // to="#"
              role="button"
              onClick={() => {
                redirectTo("template-management/email-template/details/117");
              }}
            >
              <span className="btn-inner">
                {findIcon("Eye", "dual-tone", 32)}
              </span>
            </Button>
          </div>
        </div>
      ),
    },
  ];
  return (
    <>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <div className="d-flex flex-column">
          <h3 className="m-0">Analytics</h3>
        </div>
      </div>
      <Row>
        <Col lg="12" className="d-flex p-0 px-md-3">
          <Card className="flex-grow-1">
            <Card.Header className="p-2 p-md-3 pb-md-0">
              <h5 className="mx-0 mx-md-2 my-0">5 Most Recent Templates</h5>
            </Card.Header>
            <Card.Body className="p-2 p-md-4">
              <ReactTable
                data={tableData}
                columns={columnData}
                recordsTotal={5}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg="6" className="d-flex p-0 px-md-3">
          <Card className="flex-grow-1">
            <Card.Header className="p-2 p-md-3 pb-md-0">
              <h5 className="mx-0 mx-md-2 my-0">Template usage as per Brand</h5>
            </Card.Header>
            <Card.Body className="p-2 p-md-4">
              <Chart
                options={chart4.options}
                series={chart4.series}
                type="bar"
                height="250"
              />
            </Card.Body>
          </Card>
        </Col>
        <Col lg="6" className="d-flex p-0 px-md-3">
          <Card className="flex-grow-1">
            <Card.Header className="p-2 p-md-3 pb-md-0">
              <h5 className="mx-0 mx-md-2 my-0">Template as Per Channels</h5>
            </Card.Header>
            <Card.Body className="p-2 p-md-4 d-flex justify-content-center">
              <Chart
                options={chart2.options}
                series={chart2.series}
                type="pie"
                height="300"
              />
            </Card.Body>
          </Card>
        </Col>
        <Col lg="12" className="d-flex p-0 px-md-3">
          <Card className="flex-grow-1">
            <Card.Header className="p-2 p-md-3 pb-md-0">
              <h5 className="mx-0 mx-md-2 my-0">
                Top 10 Templates as per Engagement
              </h5>
            </Card.Header>
            <Card.Body className="p-2 p-md-4">
              <Chart
                options={chart3.options}
                series={chart3.series}
                type="bar"
                height="250"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Analytics;
