//create authSlice
import { createSlice } from "@reduxjs/toolkit";
import { userInitialState } from "./state";
import { getStorage } from "../../utilities/setting";

export const authSlice = createSlice({
  name: "auth",
  initialState: getStorage("authState") || userInitialState,
  reducers: {
    loginSuccess: (state, action) => {
      const { user, userRoles, token, isAuthenticated } = action.payload;
      state.user = user;
      state.userRoles = userRoles;
      state.token = token;
      state.isAuthenticated = isAuthenticated || true;
    },
    logoutSuccess: (state, action) => {
      state.user = null;
      state.userRoles = [];
      state.token = null;
      state.isAuthenticated = false;
    },
  },
});
export default authSlice.reducer;
