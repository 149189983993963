import {
  Fragment,
  useState,
  useEffect,
  useRef,
  useCallback,
  memo,
} from "react";

// React-bootstrap
import { Row, Nav, Tab, Dropdown, Button, Card, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
//Img
import img1 from "../../../../assets/images/avatars/avtar_6.png";
import toast from "react-hot-toast";
import postService from "../../../../api/services/post-service";
import {
  SEGMENT_ADD_POST,
  SEGMENT_ANALYTICS_GET,
  SEGMENT_EDIT_GET,
  SEGMENT_SEGMENT_POST,
  SEGMENT_UPDATE_POST,
  SEGMENT_HCP_LIST_GET,
} from "../../../../api/endpoints/campaign-segment-management-endpoints";
import { getValuesByKey } from "../../../../common/utils/utils";
import { useParams, useLocation } from "react-router-dom";
import { segmentAddData } from "../../../../store/segment/segment.selector";
import { addSegment } from "../../../../store/segment/segment.action";
import UserWhoActiveForm from "../components/create-from-component/user-who-active-form";
import UserPersonaCard from "../components/create-from-component/user-persona-card";
import UserWhoDidForm from "../components/create-from-component/user-who-did-form";
import UserDemograpicForm from "../components/create-from-component/user-demographic-form";
import SegmentAnalyticsSection from "../components/create-from-component/segment-analytics-section";
import { getService } from "../../../../api/services/get-services";
import UserInterestedIn from "../components/create-from-component/user-interested-in-form";
import { findIcon } from "../../../dashboard/icons";
import ReactTable from "../../../../lib/table/react-table";
import ActionButtonBar from "../../../../components/partials/common/action-button-bar";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import SubmitButton from "../../../../components/form-components/submit-button";

const innitialPayload = {
  segment_name: "",
  segment_description: "",
  options: {
    persona: [],
    who_active: [],
    engaged_with: [],
    // who_did: [],
    // who_not_did: [],
    demographic: [],
    other: [],
  },
};

const DEFAULT_SEGMENT_TYPE = "dynamic";
const LIST_SEGMENT_TYPE = "list";
const CreateSegment = () => {
  const location = useLocation();
  const { redirectTo } = useRedirect();
  const { id: segment_id } = useParams();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [segmentName, setSegmentName] = useState("");
  const [segmentDescription, setSegmentDescription] = useState("");
  const [userPersonaList, setUserPersonaList] = useState([]);
  const [contentCategoryList, setContentCategoryList] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [segementAddPostLoader, setSegementAddPostLoader] = useState(false);
  const [analyticsData, setAnalyticsData] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [userWhoActiveEditData, setUserWhoActiveEditData] = useState([]);
  const [userWhoDidEditData, setUserWhoDidEditData] = useState([]);
  const [userWhoNotDidEditData, setUserWhoNotDidEditData] = useState([]);
  const [userDemographicEditData, setUserDemographicEditData] = useState([]);
  const [segmentType, setSegmentType] = useState(DEFAULT_SEGMENT_TYPE);
  const [getAnanlyticsDataLoader, setGetAnanlyticsDataLoader] = useState(false);
  const [getHcpListLoader, setGetHcpListLoader] = useState(false);

  const [hcpListData, setHcpListData] = useState([]);
  const segment_add_payload = useRef(innitialPayload);
  const segmentPayload = useSelector(segmentAddData);

  const [segmentDetails, setSegmentDetails] = useState({
    isNameEdit: false,
    isDescriptionEdit: false,
    name: "",
    // name: segmentPayload?.segment_name,
    description: "",
    // description: segmentPayload?.segment_description,
  });

  const timeFormat = "YYYY-MM-DD HH:mm:ss";
  const getCurrentTime = () => dayjs(new Date().getTime()).format(timeFormat);

  const hcpColumns = [
    {
      Header: "Name",
      accessor: "hcp_first_name",
      // Filter: DefaultColumnFilter,
    },
    {
      Header: "UTM",
      accessor: "hcp_utmsource",
      // Filter: DefaultColumnFilter,
    },
    {
      Header: "Age ",
      accessor: "hcp_age",
      // Filter: DefaultColumnFilter,
    },
    {
      Header: "Location",
      accessor: "hcp_to_city",
      Cell: ({ value }) => (value ? value.city_name : "-"),
    },
    {
      Header: "Speciality",
      accessor: "speciality",
      Cell: ({ value }) => (value ? value.speciality_name : "-"),
    },
    {
      Header: "Updated on",
      accessor: "updated_at",
      Cell: ({ value }) => (value ? value : "-"),
    },
    {
      Header: "Action",
      accessor: "actionDetails",
      disableFilters: true,
      Cell: ({ row }) => (
        <ActionButtonBar
          buttons={["view"]}
          row={row}
          onView={(data) => {
            console.log("row", row);
            redirectTo("segment/details/" + row.original.segment_id);
          }}
        />
        // <div className="d-flex justify-content-center">
        //   <Button className="btn btn-icon btn-sm rounded-pill" role="button">
        //     <span className="btn-inner">{row.original.actionDetails}</span>
        //   </Button>
        // </div>
      ),
      // Filter: DefaultColumnFilter,
    },
  ];

  const editSegmentName = () =>
    setSegmentDetails({ ...segmentDetails, isNameEdit: true });

  const editSegmentDescription = () =>
    setSegmentDetails({ ...segmentDetails, isDescriptionEdit: true });

  const saveSegmentName = () =>
    setSegmentDetails({ ...segmentDetails, isNameEdit: false });

  const saveSegmentDescription = () =>
    setSegmentDetails({ ...segmentDetails, isDescriptionEdit: false });

  const CancelEditSegmentName = () =>
    setSegmentDetails({
      ...segmentDetails,
      isNameEdit: false,
      name: segmentPayload?.segment_name,
    });

  const CancelEditSegmentDescription = () =>
    setSegmentDetails({
      ...segmentDetails,
      isDescriptionEdit: false,
      description: segmentPayload?.segment_description,
    });
  const getSegmentName = (e) => {
    setSegmentDetails({ ...segmentDetails, name: e.target.value });
  };
  const getSegmentDescription = (e) => {
    setSegmentDetails({ ...segmentDetails, description: e.target.value });
  };
  useEffect(() => {
    if (
      segmentPayload &&
      (segmentPayload?.segment_name || segmentPayload?.segment_description)
    ) {
      segment_add_payload.current.segment_name = segmentPayload?.segment_name;
      segment_add_payload.current.segment_description =
        segmentPayload?.segment_description;
      setSegmentDetails({
        ...segmentDetails,
        name: segmentPayload?.segment_name,
        description: segmentPayload?.segment_description,
      });
    }
  }, [segmentPayload]);

  useEffect(() => {
    if (location.state) {
      dispatch(addSegment(location.state));
    }
  }, [location]);

  useEffect(() => {
    if (segment_id > 0) {
      getEditSegmentData(segment_id);
    }

    getCreateSegmentData();

    return () => {
      segment_add_payload.current = innitialPayload;
    };
  }, [segment_id]);

  const onUserPersonaSelect = (id) => {
    let newPersonaList = [];
    console.log("on user persona select=>", id);
    if (id === "all") {
      newPersonaList = userPersonaList.map((el) => {
        if (el?.status?.value == "active") {
          return { ...el, status: { value: "active" } };
        } else {
          return { ...el, status: { value: "active" } };
        }
      });

      const payload = [];
      userPersonaList.forEach((elem) => {
        if (elem.persona_id !== "all")
          payload.push({
            name: "persona_id",
            operator: "or",
            clause: "is",
            value: elem?.persona_id,
          });
      });

      segment_add_payload.current.options.persona = payload;
    } else {
      newPersonaList = userPersonaList.map((el) =>
        el.persona_id == id
          ? el?.status?.value == "active"
            ? { ...el, status: "" }
            : { ...el, status: { value: "active" } }
          : el
      );
    }

    const selectedUserPersona = userPersonaList.filter(
      (el) => el.persona_id == id
    );

    const generatePayload = {
      name: "persona_id",
      operator: "and",
      clause: "is",
      value: selectedUserPersona[0]?.persona_id,
    };

    const isIdAvailable = segment_add_payload.current.options.persona.filter(
      (el) => el.value == id
    );

    if (isIdAvailable.length <= 0) {
      segment_add_payload.current.options.persona.push(generatePayload);
    } else {
      const index = segment_add_payload.current.options.persona.findIndex(
        (el) => el.value == id
      );
      segment_add_payload.current.options.persona.splice(index, 1);
    }
    console.log("persona list::::::::", segment_add_payload.current.options);
    if (step <= 1) {
      setStep((prev) => prev + 1);
    }
    setUserPersonaList(newPersonaList);
  };

  const getCreateSegmentData = async () => {
    let response = await getService(SEGMENT_SEGMENT_POST, {});
    if (response) {
      if (response.isError) {
        return false;
      }
      const responseData = response.data.data;

      if (responseData) {
        const personaData = getValuesByKey(responseData, "persona");
        const contentCategoryData = getValuesByKey(
          responseData,
          "content_category"
        );

        const activityData = getValuesByKey(responseData, "activity");

        if (personaData?.length > 0) {
          setUserPersonaList([
            {
              persona_icon: "/storage/icons/persona/avtar_1.png",
              persona_id: "all",
              persona_name: "All",
            },
            ...personaData[0],
          ]);
          setActivityList(activityData[0]);
          setContentCategoryList(contentCategoryData[0]);
        }
        if (Object.keys(responseData).length > 0) {
          delete responseData.persona;
          delete responseData.content_category;

          console.log("segment data", responseData);
          setFilterData(responseData);
        }
      }
      // setSegmentCreateDataIsLoading(false);
    }
  };

  const getEditSegmentData = async (_payload_id) => {
    let response = await getService(`${SEGMENT_EDIT_GET}/${_payload_id}`);
    if (response) {
      if (response.isError) {
        return false;
      }
      const responseData = response.data.data;
      console.log("segment edit response", responseData);
      if (responseData) {
        const data = responseData.persona;
        const previous_segment_data = responseData.segment;
        const contentCategoryData = responseData.content_category;

        const activityData = getValuesByKey(responseData, "activity");
        console.log("previous_segment_data", responseData.segment);
        if (data?.length > 0) {
          setUserPersonaList(data);
          setActivityList(activityData);
          setStep(2);
          setContentCategoryList(contentCategoryData);
        }
        if (
          previous_segment_data &&
          Object.keys(previous_segment_data).length > 0
        ) {
          presetFormData(previous_segment_data, responseData);
        }
        if (Object.keys(responseData).length > 0) {
          console.log("responseData:", responseData);
          delete responseData.persona;
          delete responseData.content_category;
          delete responseData.segment;
          delete responseData.activity;
          setFilterData(responseData);
        }
      }
      // setSegmentCreateDataIsLoading(false);
    }
  };

  const presetFormData = (prev_data, filterData) => {
    console.group("presetFormData1");
    console.log("prev data", prev_data, "filter data:", filterData);
    console.groupEnd("presetFormData1");
    const {
      segment_to_persona,
      segment_rules: { options, segment_name, segment_description },
    } = prev_data;
    console.log("preset", options);
    const { who_active, engaged_with, who_not_did, demographic } =
      options ?? {};
    console.group("presetFormData");
    console.log("who_active", who_active);
    console.log("engaged_with", engaged_with);
    console.log("who_not_did", who_not_did);
    console.groupEnd("presetFormData");

    const tempData = {};
    tempData.segment_name = segment_name;
    tempData.segment_description = segment_description;

    setSegmentDescription(segment_description);
    setSegmentName(segment_name);
    dispatch(addSegment(tempData));

    const tempUserPersonaList = segment_to_persona;
    const tempContentCategoryList = filterData.content_category;
    const tempActivityList = filterData.activity;
    const tempWhoActiveEditData = who_active;
    const tempWhoDidEditData = engaged_with;
    const tempWhoNotDidEditData = who_not_did;
    const tempDemographicEditData = demographic;

    const tempFilterData = filterData;

    if (tempFilterData && Object.keys(tempFilterData).length > 0) {
      delete tempFilterData.persona;
      delete tempFilterData.content_category;
      delete tempFilterData.activity;
      setFilterData(tempFilterData);
    }

    if (tempUserPersonaList.length > 0 && segment_to_persona.length > 0) {
      segment_to_persona.map((el) => {
        const index = tempUserPersonaList.findIndex(
          (item) => item.persona_id == el.persona_id
        );
        if (index > -1) {
          tempUserPersonaList[index].status = { value: "active" };
        }
      });
    }

    console.log("tempWhoDidEditData", tempWhoDidEditData);

    setUserWhoActiveEditData(tempWhoActiveEditData);
    setUserWhoDidEditData(tempWhoDidEditData);
    setUserWhoNotDidEditData(tempWhoNotDidEditData);
    setUserDemographicEditData(tempDemographicEditData);
    setContentCategoryList(tempContentCategoryList);
    setActivityList(tempActivityList);
    setUserPersonaList(tempUserPersonaList);
  };

  const onPreviewSegmentClick = () => {
    const payloadData = segment_add_payload.current;
    // if (
    //   !segment_add_payload.current ||
    //   !segment_add_payload.current.hasOwnProperty("segment_name") ||
    //   !segment_add_payload.current?.segment_name
    // ) {
    //   toast.error("Please enter segment name");
    //   return false;
    // } else if (
    //   !segment_add_payload.current ||
    //   !segment_add_payload.current.hasOwnProperty("options") ||
    //   !segment_add_payload.current?.options ||
    //   !segment_add_payload.current?.options?.length > 0
    // ) {
    //   toast.error("Please select atleast one filter");
    // } else {
    // }
    if (segment_id) {
      updateSegmentSend(payloadData);
    } else {
      addSegmentSend(payloadData);
      // addSegmentSend(segmentPayloadDummy);
    }
  };

  const addSegmentSend = async (data) => {
    setSegementAddPostLoader(true);
    let response = await postService(SEGMENT_ADD_POST, data);
    if (response) {
      if (response.isError) {
        return false;
      } else {
        console.log("segment added", response);
        if (response.data) {
          setSegementAddPostLoader(false);
          getSegmentAnalyticsById(response?.data?.data?.segment_id);
          getSegmentHcpListById(response?.data?.data?.segment_id);
          if (step == 3) {
            setStep(1);
            setAnalyticsData("");
            setShowTable(false);
            toast.success("Segment created successfully");
          }
        } else {
          toast.error("Something error happen. please try again");
        }
      }
    }
  };

  const updateSegmentSend = async (data) => {
    setSegementAddPostLoader(true);
    let response = await postService(SEGMENT_UPDATE_POST, data);
    if (response) {
      if (response.isError) {
        return false;
      } else {
        // console.log("response", response);
        setSegementAddPostLoader(false);
        if (step < 3) {
          setAnalyticsData(response.data.data);
          setStep((prev) => prev + 1);
        } else if (step == 3) {
          setStep(1);
          setAnalyticsData("");

          toast.success("Segment created successfully");
        } else {
          toast.error("Something went wrong");
        }
      }
    }
  };

  const getSegmentAnalyticsById = async (_payload_id) => {
    console.log("getSegmentAnalyticsById", _payload_id);
    setGetAnanlyticsDataLoader(true);
    let response = await getService(`${SEGMENT_ANALYTICS_GET}/${_payload_id}`);
    if (response) {
      setGetAnanlyticsDataLoader(false);
      if (response.isError) {
        return false;
      }
      const responseData = response.data.data;
      if (responseData) {
        setAnalyticsData(responseData);
        setStep((prev) => prev + 1);
      }
    }
  };

  const getSegmentHcpListById = async (_payload_id) => {
    let payloadData = {
      segment_id: 3,
      filter: {
        status: {
          type: "eq",
          value: 1,
        },
      },
      pagination: {
        limit: 15,
        offset: 0,
      },
      sort: {
        segment_id: "desc",
      },
    };
    setGetHcpListLoader(true);
    let response = await postService(`${SEGMENT_HCP_LIST_GET}`, payloadData);
    if (response) {
      setGetHcpListLoader(false);
      if (response.isError) {
        return false;
      }
      const responseData = response.data.data;
      if (responseData && responseData?.rows?.length > 0) {
        const { rows } = responseData;
        console.log("segment_to_hcp", responseData, "\n", rows);
        let hcpList = [];
        hcpList = getFormatHcpListData(rows);
        setHcpListData(hcpList);
        console.log("hcpListdata", hcpList);
      }
    }
  };

  const getFormatHcpListData = (data) => {
    console.log("getFormatHcpListData", data);
    if (data.length > 0) {
      const { segment_to_hcp } = data[0];
      console.log("segment_to_hcp", segment_to_hcp);
      let hcpList = [];
      segment_to_hcp.map(function (hcp) {
        hcpList.push(hcp.hcp);
      });
      console.log("modified value is ", hcpList);
      return hcpList;
    }
  };

  const onDemograpicFilterChange = useCallback((data) => {
    segment_add_payload.current.options.demographic = data;

    console.log("onDemographic", data);
    console.log("onDemographicPayload", segment_add_payload.current);
  }, []);

  const onUserWhoActiveChnage = useCallback((data) => {
    segment_add_payload.current.options.who_active[0] = data;
    console.log("onUserWhoActiveChnage", segment_add_payload.current);
  }, []);

  const onUserWhoDidChnage = useCallback((data) => {
    segment_add_payload.current.options.engaged_with = data;
    console.log("onUserWhoDidChnage", data);
    console.log("onUserWhoDidChnagePayload", segment_add_payload.current);
  }, []);

  const onUserInterestSelected = (val) => {
    segment_add_payload.current.options.other = {
      name: "content_tag_name",
      operator: "and",
      clause: "is",
      value: val.value,
    };
    console.log("user interest select::", val, segment_add_payload.current);
  };

  const onSegmentTypeChange = (type) => {
    setSegmentType(type);
  };
  console.group("segment");
  console.log("Add payload", segment_add_payload.current);
  console.log("step", step);
  console.groupEnd("segment");
  return (
    <Fragment>
      <div className="col-12 col-md-10 mb-4 d-flex justify-content-between w-100">
        <div className="d-flex justify-content-between flex-column">
          {!segment_id &&
            segmentPayload &&
            segmentPayload?.segment_name &&
            (segmentDetails.isNameEdit ? (
              <div className="d-flex justify-content-start align-items-center gap-2">
                <input
                  type="text"
                  className="border-0 rounded-3 px-2 py-1"
                  value={segmentDetails.name}
                  onChange={getSegmentName}
                />
                <Button
                  variant="success"
                  className="p-0 icon-32 rounded-circle d-flex align-items-center justify-content-center"
                  type="submit"
                  onClick={saveSegmentName}
                >
                  {findIcon("Check", "outline", "18")}
                </Button>
                <Button
                  variant="danger"
                  className="p-0 icon-32 rounded-circle d-flex align-items-center justify-content-center"
                  type="submit"
                  onClick={CancelEditSegmentName}
                >
                  {findIcon("Close-2", "outline", "14")}
                </Button>
              </div>
            ) : (
              <div className="d-flex justify-content-start align-items-center gap-2">
                {/* <h4 className="mb-0 mt-2">{segmentPayload?.segment_name}</h4> */}
                <h4 className="mb-0 mt-2">{segmentDetails.name}</h4>
                <span
                  className="cursor-pointer d-inline-flex"
                  onClick={editSegmentName}
                >
                  {findIcon("Pencil", "dual-tone", "20")}
                </span>
              </div>
            ))}
          {segmentPayload &&
            segmentPayload?.segment_description &&
            (segmentDetails.isDescriptionEdit ? (
              <div className="d-flex justify-content-start align-items-center gap-2">
                <input
                  type="text"
                  className="border-0 rounded-3 px-2 py-1"
                  value={segmentDetails.description}
                  onChange={getSegmentDescription}
                />
                <Button
                  variant="success"
                  className="p-0 icon-32 rounded-circle d-flex align-items-center justify-content-center"
                  type="submit"
                  onClick={saveSegmentDescription}
                >
                  {findIcon("Check", "outline", "18")}
                </Button>
                <Button
                  variant="danger"
                  className="p-0 icon-32 rounded-circle d-flex align-items-center justify-content-center"
                  type="submit"
                  onClick={CancelEditSegmentDescription}
                >
                  {findIcon("Close-2", "outline", "14")}
                </Button>
              </div>
            ) : (
              <div className="d-flex justify-content-start align-items-center gap-2">
                <p className="mb-0 mt-1">
                  {/* {segmentPayload?.segment_description} */}
                  {segmentDetails?.description}
                </p>
                <span
                  className="cursor-pointer d-inline-flex"
                  onClick={editSegmentDescription}
                >
                  {findIcon("Pencil", "dual-tone", "20")}
                </span>
              </div>
            ))}
        </div>
        <Dropdown className="me-4">
          <Dropdown.Toggle
            as={Button}
            variant="primary"
            type="button"
            id="dropdownMenuButtonSM"
          >
            Segment Type
          </Dropdown.Toggle>
          <Dropdown.Menu aria-labelledby="dropdownMenuButtonSM">
            <Dropdown.Item
              onClick={() => onSegmentTypeChange(DEFAULT_SEGMENT_TYPE)}
            >
              Dynamic
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => onSegmentTypeChange(LIST_SEGMENT_TYPE)}
            >
              List
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <Card>
        <Card.Body>
          <Row>
            {
              <Col lg={12}>
                <h5>Select Persona(s)</h5>
                <div className="d-flex flex-row flex-wrap gap-3 mb-1 mt-4">
                  {userPersonaList?.length > 0 &&
                    userPersonaList?.map((persona) => (
                      <UserPersonaCard
                        name={persona.persona_name}
                        image={img1}
                        id={persona.persona_id}
                        active={persona?.status?.value == "active"}
                        click={onUserPersonaSelect}
                      />
                    ))}
                </div>
              </Col>
            }

            {step >= 2 && (
              <Col lg={12} className="mt-4">
                <UserWhoActiveForm
                  onFormDataChange={onUserWhoActiveChnage}
                  isEditMode={segment_id > 0}
                  editData={
                    userWhoActiveEditData &&
                    userWhoActiveEditData?.length > 0 &&
                    userWhoActiveEditData[0]
                  }
                />
                <UserWhoDidForm
                  title="Users Engaged with:"
                  contentCategoryList={contentCategoryList}
                  activityList={activityList}
                  onFromDataChange={onUserWhoDidChnage}
                  isEditMode={segment_id > 0}
                  editData={
                    userWhoDidEditData && userWhoDidEditData?.length > 0
                      ? userWhoDidEditData
                      : []
                  }
                />

                <UserInterestedIn
                  title="User Interesed In"
                  onUserInterestSelected={onUserInterestSelected}
                />

                <UserDemograpicForm
                  filterData={filterData}
                  payload={segment_add_payload.current}
                  onFromDataChange={onDemograpicFilterChange}
                  isEditMode={segment_id > 0}
                  editData={
                    userDemographicEditData &&
                    userDemographicEditData?.length > 0
                      ? userDemographicEditData
                      : []
                  }
                />
              </Col>
            )}
            {step == 2 && (
              <Col
                lg={12}
                className="d-flex flex-row gap-3 justify-content-md-center justify-content-center"
              >
                <Button
                  type="button"
                  className=""
                  onClick={onPreviewSegmentClick}
                  disabled={segementAddPostLoader}
                >
                  {!segementAddPostLoader
                    ? "Preview Segment"
                    : "Collecting Segment..."}
                </Button>
              </Col>
            )}
          </Row>
        </Card.Body>
      </Card>

      {analyticsData && step >= 3 && (
        <Card>
          <Card.Body>
            <Row>
              <Col lg="12" md="12">
                <Tab.Container defaultActiveKey="first">
                  <div className="tab-bottom-bordered">
                    <Nav
                      className="mb-0 nav-tabs justify-content-around d-flex"
                      id="nav-tab1"
                      role="tablist"
                    >
                      <Nav.Link
                        className="py-3 "
                        eventKey="first"
                        data-bs-toggle="tab"
                        data-bs-target="#analytics"
                        type="button"
                        role="tab"
                        aria-controls="analytics"
                        aria-selected="true"
                      >
                        Analytics
                      </Nav.Link>
                      <Nav.Link
                        className="py-3"
                        eventKey="second"
                        data-bs-toggle="tab"
                        data-bs-target="#hcp-listing"
                        type="button"
                        role="tab"
                        aria-controls="hcp-listing"
                        aria-selected="false"
                      >
                        Hcp Listing
                      </Nav.Link>
                    </Nav>
                  </div>
                  <Tab.Content className="iq-tab-fade-up" id="nav-tabContent">
                    <Tab.Pane
                      eventKey="first"
                      id="analytics"
                      role="tabpanel"
                      aria-labelledby="analytics"
                    >
                      <>
                        <div className="d-flex justify-content-between pt-4">
                          <p>Last refreshed on 06-06-2023 15:25</p>
                          <div className="d-flex justify-content-end mb-3 gap-3 align-items-center">
                            {/* <p class="p-0 m-0">
                              Last refreshed on 06-06-2023 15:25
                            </p> */}
                            <span className="animate_spinner">
                              {findIcon("Refresh", "outline", "20")}
                            </span>
                          </div>
                        </div>
                        <SegmentAnalyticsSection
                          segmentSize={analyticsData?.segment_size}
                          preferEmail={analyticsData?.prefer_email}
                          preferWhatsApp={analyticsData?.prefer_whatsapp}
                          preferSms={analyticsData?.prefer_sms}
                          excludingDnd={analyticsData?.exclcuding_dnd}
                          availableTouchPoint={
                            analyticsData?.avg_available_touch_point
                          }
                          barredByDndStatus={
                            analyticsData?.barred_by_dnd_status
                          }
                          totalReach={analyticsData?.total_reach}
                        />
                      </>
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="second"
                      id="hcp-listing"
                      role="tabpanel"
                      aria-labelledby="hcp-listing"
                    >
                      <div className="d-flex justify-content-between pt-4">
                        <p>Last refreshed on 06-06-2023 15:25</p>
                        <div className="d-flex justify-content-end mb-3 gap-3 align-items-center">
                          <span className="animate_spinner">
                            {findIcon("Refresh", "outline", "20")}
                          </span>
                        </div>
                      </div>
                      {hcpListData && hcpListData?.length > 0 && (
                        <ReactTable
                          data={hcpListData}
                          columns={hcpColumns}
                          tdClass={"threeColTable"}
                          thClass={"threeColTable"}
                          recordsTotal={60}
                        />
                      )}
                      <Col
                        lg={12}
                        className="d-flex justify-content-end mb-3 gap-3 align-items-center mb-5 mt-5"
                      >
                        <Button type="button" variant="secondary" className="">
                          Save as Draft
                        </Button>
                        <Button type="button" className="">
                          {segementAddPostLoader
                            ? "Creating Segment..."
                            : "Create Segment"}
                        </Button>
                        <SubmitButton variant="primary" />
                      </Col>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}
    </Fragment>
  );
};
export default memo(CreateSegment);
