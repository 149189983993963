import React, { useRef } from "react";
import { useDrag } from "react-dnd";

const KanbanItemTag = ({ tag, children }) => {
  const ref = useRef(null);
  const [{ isDragging }, drag] = useDrag({
    type: "Our First type",
    item: { id: tag._id, name: tag.tags },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(ref);
  return (
    <>
      <div ref={ref} style={{ opacity }}>
        {children}
      </div>
    </>
  );
};

export default KanbanItemTag;
