import { memo, Fragment } from "react";

//react-bootstrap
import { Image, Container } from "react-bootstrap";

//router
import { Link } from "react-router-dom";

// Import selectors & action from setting store
import * as SettingSelector from "../../../../store/setting/selectors";

// Redux Selector / Action
import { useSelector } from "react-redux";

//img

const Autheffect = memo(() => {
  const appName = useSelector(SettingSelector.app_name);
  return (
    <Fragment>
      <div className="container-inside">
        <div className="main-circle circle-small"></div>
        <div className="main-circle circle-medium"></div>
        <div className="main-circle circle-large"></div>
        <div className="main-circle circle-xlarge"></div>
        <div className="main-circle circle-xxlarge"></div>
      </div>
    </Fragment>
  );
});

Autheffect.displayName = "Autheffect";
export default Autheffect;
