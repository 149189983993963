import Skeleton from "../../components/skeleton";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";

const AddContentAreaLoader = () => {
  return (
    <>
      <Col lg="12" className="d-flex">
        <Card className="w-100">
          <Card.Body>
            <div className="h-100">
              <Row className="gap-4 gap-lg-0">
                <Col lg="6">
                  <Skeleton variant="rounded" width="100%" height={160} />
                  <Row className="gap-4 gap-lg-0 mt-4">
                    <Col lg="6">
                      <Skeleton variant="rounded" width="100%" height={150} />
                    </Col>
                    <Col lg="6">
                      <Skeleton variant="rounded" width="100%" height={150} />
                    </Col>
                  </Row>
                </Col>
                <Col lg="6">
                  <Row className="h-100 flex-column flex-nowrap">
                    <Col lg="12" className="mb-3">
                      <Skeleton variant="rounded" width="100%" height={50} />
                    </Col>
                    <Col lg="12" className="mb-4">
                      <Skeleton variant="text" width={60} className="mb-2" />
                      <Skeleton
                        variant="rounded"
                        width="100%"
                        height={100}
                        className="mb-3"
                      />
                      <Skeleton variant="text" width={60} className="mb-2" />
                      <Skeleton variant="rounded" width="100%" height={100} />
                    </Col>

                    <Col lg="12" className="d-flex justify-content-end">
                      <Skeleton variant="rounded" width={110} height={40} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

export default AddContentAreaLoader;
