import React from "react";

// layout

import Default from "../../../../../layouts/dashboard/default";
import AllTargetSources from "../pages/all-target-sources";

export const TargetManagementRouter = [
  {
    path: "/master-management/target-source",
    element: <Default />,
    children: [
      {
        path: "all",
        element: <AllTargetSources />,
      },
    ],
  },
];
