import React from "react";
import { Button, Modal, Col, Row, Card, Form } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { useRecommendationStatus } from "../../../../../queries/query-hooks/hcp-profile-hook";
export default function ChnageRecommendationModal({
  show,
  onClose,
  onSubmit,
  title,
  body,
  data,
}) {
  const initialValues = {
    hcp_to_recommendation_status: data?.status_name ?? "",
  };

  const validationSchema = Yup.object().shape({
    hcp_to_recommendation_status: Yup.number().required("Select Status"),
  });

  const onRequestSuccess = (response) => {
    toast.success(`${response.data.message}`);
    onSubmit();
  };
  const { mutate: updateRecommendationStatus, isLoading } =
    useRecommendationStatus(data?.hcp_id, onRequestSuccess);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    handleReset,
    values,
    errors,
    touched,
    setErrors,
    setValues,
    isSubmitting,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      let payload = { ...values, hcp_id: data.hcp_id };
      updateRecommendationStatus(payload);
    },
  });
  return (
    <Modal show={show} onHide={onClose} centered={true}>
      <Modal.Header>
        <Modal.Title as="h5">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <Row className="justify-content-center align-items-start">
            <Col lg={12} xs={12} className="position-relative">
              <div className="p-4 bg-white rounded-3 h-100 d-flex flex-column justify-content-between">
                <Row>
                  <Col md={12} className="mb-4">
                    <Form.Floating className="custom-form-floating">
                      <Form.Select
                        id="hcp_to_recommendation_status"
                        name="hcp_to_recommendation_status"
                        value={values?.hcp_to_recommendation_status}
                        isInvalid={
                          touched.hcp_to_recommendation_status &&
                          !!errors.hcp_to_recommendation_status
                        }
                        onChange={handleChange}
                      >
                        <option value="">
                          Select Recommendation Status
                          {values.hcp_to_recommendation_status}
                        </option>
                        <option key={1} value={1}>
                          Default
                        </option>
                        <option key={2} value={2}>
                          AWS Personalise
                        </option>
                        <option key={2} value={3}>
                          Mesh Recommendation
                        </option>
                      </Form.Select>
                      <Form.Label htmlFor="manager_id">Status</Form.Label>
                      <Form.Control.Feedback type="invalid">
                        {errors.hcp_to_recommendation_status}
                      </Form.Control.Feedback>
                    </Form.Floating>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Create Request
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
