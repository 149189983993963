import React, { memo, Fragment, useEffect } from "react";

import { Button, Card, Col, Dropdown, Nav, Row, Tab } from "react-bootstrap";
import CampaignStatsCard from "../components/campaign-stats-card";
import { findIcon } from "../../../dashboard/icons";
import Progress from "../../../../components/progress";
import CustomToggle from "../../../../components/dropdowns";
import { theme_color } from "../../../../store/setting/selectors";
import { useSelector } from "react-redux";
import ReactTable from "../../../../lib/table/react-table";

import Chart from "react-apexcharts";
import CountUp from "react-countup";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
// import CampaignStatCardLoader from "../../../../skeleton/campaign/dashboard/campaign-stat-card-loader";
// import PerformanceTrendLoader from "../../../../skeleton/campaign/dashboard/performance-trend-loader";
// import CommonTableSkeleton from "../../../../skeleton/common-table-skeleton";

const svg1 = findIcon("Eye", "solid");
const svg2 = findIcon("Action", "dual-tone");
const svg3 = findIcon("Users", "dual-tone");
const svg4 = findIcon("Users", "dual-tone");
const recently_used_segment_data = [
  {
    segment_name: "Blood Donation Camp",
    total_reach: "380",
    Preffer_email: "105",
    Preffer_whatsapp: "112",
    Preffer_SMS: "132",
    created_by: { name: "Indranil Kundu" },
    created_on: { date: "03-06-23", time: "17:05" },
    modified_on: { date: "03-06-23", time: "17:40" },
    status: { id: 1, value: "Draft" },
  },
  {
    segment_name: "Campaign 2",
    total_reach: "470",
    Preffer_email: "155",
    Preffer_whatsapp: "119",
    Preffer_SMS: "156",
    created_by: { name: "Saif Imroz" },
    created_on: { date: "03-06-23", time: "18:20" },
    modified_on: { date: "03-06-23", time: "18:40" },
    status: { id: 1, value: "Draft" },
  },
  {
    segment_name: "Campaign C",
    total_reach: "550",
    Preffer_email: "255",
    Preffer_whatsapp: "110",
    Preffer_SMS: "106",
    created_by: { name: "Sumit Mondal" },
    created_on: { date: "03-06-23", time: "22:20" },
    modified_on: { date: "03-06-23", time: "22:40" },
    status: { id: 1, value: "Draft" },
  },
  {
    segment_name: "Campaign Ganesh Puja",
    total_reach: "350",
    Preffer_email: "105",
    Preffer_whatsapp: "170",
    Preffer_SMS: "136",
    created_by: { name: "Sumit Mondal" },
    created_on: { date: "05-06-23", time: "09:20" },
    modified_on: { date: "05-06-23", time: "09:40" },
    status: { id: 1, value: "Draft" },
  },
  {
    segment_name: "Campaign World Doctor Day",
    total_reach: "550",
    Preffer_email: "135",
    Preffer_whatsapp: "241",
    Preffer_SMS: "131",
    created_by: { name: "Sumit Mondal" },
    created_on: { date: "05-06-23", time: "10:20" },
    modified_on: { date: "05-06-23", time: "10:40" },
    status: { id: 1, value: "Draft" },
  },
];

const recomended_segment_demo_data = [
  {
    segment_name: "Doctors in Mumbai",
    total_reach: "380",
    Preffer_email: "105",
    Preffer_whatsapp: "112",
    Preffer_SMS: "132",
    created_by: { name: "Indranil Kundu" },
    created_on: { date: "01-09-23", time: "21:10" },
    modified_on: { date: "01-09-23", time: "21:40" },
    status: { id: 1, value: "Draft" },
  },
  {
    segment_name: "Doctos in Kolkata",
    total_reach: "470",
    Preffer_email: "155",
    Preffer_whatsapp: "119",
    Preffer_SMS: "156",
    created_by: { name: "Sumit Mondal" },
    created_on: { date: "01-09-23", time: "22:20" },
    modified_on: { date: "01-09-23", time: "22:40" },
    status: { id: 1, value: "Draft" },
  },
  {
    segment_name: "ENT doctors in Mumbai",
    total_reach: "550",
    Preffer_email: "255",
    Preffer_whatsapp: "110",
    Preffer_SMS: "109",
    created_by: { name: "Swagata Das" },
    created_on: { date: "02-09-23", time: "12:20" },
    modified_on: { date: "02-09-23", time: "12:40" },
    status: { id: 1, value: "Draft" },
  },
  {
    segment_name: "Cardiology doctors in Delhi",
    total_reach: "350",
    Preffer_email: "105",
    Preffer_whatsapp: "170",
    Preffer_SMS: "136",
    created_by: { name: "Saif Imroz" },
    created_on: { date: "02-09-23", time: "13:00" },
    modified_on: { date: "02-09-23", time: "13:40" },
    status: { id: 1, value: "Draft" },
  },
  {
    segment_name: "Doctors in Chennai",
    total_reach: "550",
    Preffer_email: "135",
    Preffer_whatsapp: "241",
    Preffer_SMS: "131",
    created_by: { name: "Sumit Mondal" },
    created_on: { date: "03-09-23", time: "16:20" },
    modified_on: { date: "03-09-23", time: "16:40" },
    status: { id: 1, value: "Draft" },
  },
];
const largest_segment_demo_data = [
  {
    segment_name: "Nephrology Doctors in Kolkata",
    total_reach: "380",
    Preffer_email: "105",
    Preffer_whatsapp: "112",
    Preffer_SMS: "132",
    created_by: { name: "Indranil Kundu" },
    created_on: { date: "03-06-23", time: "17:05" },
    modified_on: { date: "03-06-23", time: "17:40" },
    status: { id: 1, value: "Draft" },
  },
  {
    segment_name: "Oncology Doctors in Chennai",
    total_reach: "470",
    Preffer_email: "155",
    Preffer_whatsapp: "119",
    Preffer_SMS: "156",
    created_by: { name: "Saif Imroz" },
    created_on: { date: "03-06-23", time: "18:20" },
    modified_on: { date: "03-06-23", time: "18:40" },
    status: { id: 1, value: "Draft" },
  },
  {
    segment_name: "ENT Doctors in Bangalore",
    total_reach: "550",
    Preffer_email: "255",
    Preffer_whatsapp: "110",
    Preffer_SMS: "106",
    created_by: { name: "Sumit Mondal" },
    created_on: { date: "03-06-23", time: "22:20" },
    modified_on: { date: "03-06-23", time: "22:40" },
    status: { id: 1, value: "Draft" },
  },
  {
    segment_name: "Doctors in Delhi",
    total_reach: "350",
    Preffer_email: "105",
    Preffer_whatsapp: "170",
    Preffer_SMS: "136",
    created_by: { name: "Sumit Mondal" },
    created_on: { date: "05-06-23", time: "09:20" },
    modified_on: { date: "05-06-23", time: "09:40" },
    status: { id: 1, value: "Draft" },
  },
  {
    segment_name: "Cardiology Doctors in Mumbai",
    total_reach: "550",
    Preffer_email: "135",
    Preffer_whatsapp: "241",
    Preffer_SMS: "131",
    created_by: { name: "Sumit Mondal" },
    created_on: { date: "05-06-23", time: "10:20" },
    modified_on: { date: "05-06-23", time: "10:40" },
    status: { id: 1, value: "Draft" },
  },
];
const CampaignDashboard = () => {
  const themeColor = useSelector(theme_color);
  const getVariableColor = () => {
    let prefix =
      getComputedStyle(document.body).getPropertyValue("--prefix") || "bs-";
    if (prefix) {
      prefix = prefix.trim();
    }
    const color5 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}primary`
    );
    const color1 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}success`
    );
    const color2 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}info`
    );
    const color3 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}primary-tint-20`
    );
    const color4 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}warning`
    );
    return {
      success: color1.trim(),
      info: color2.trim(),
      warning: color4.trim(),
      primary_light: color3.trim(),
      primary: color5.trim(),
    };
  };
  const variableColors = getVariableColor();
  const colors = [
    variableColors.primary,
    variableColors.info,
    variableColors.success,
    variableColors.warning,
  ];

  const { redirectTo } = useRedirect();
  useEffect(() => {
    return () => colors;
  });

  const chartdata = {
    series: [
      {
        name: "Users",
        data: [28, 29, 33, 36, 32, 32, 33, 28, 23, 29, 33, 36],
      },
      {
        name: "New Users",
        data: [12, 11, 14, 18, 17, 13, 13, 18, 17, 12, 21, 10],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          show: false,
        },
      },
      // colors: ["#77B6EA", "#545454"],
      colors: [variableColors.info, variableColors.warning],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      title: {
        text: "Users 36K",
        align: "left",
      },
      grid: {
        borderColor: "#e7e7e7",
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      markers: {
        size: 1,
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        // title: {
        //   text: "Month",
        // },
      },
      // yaxis: {
      //   title: {
      //     text: "Temperature",
      //   },
      //   min: 5,
      //   max: 40,
      // },
      legend: {
        position: "top",
        horizontalAlign: "right",
        floating: true,
        offsetY: -25,
        offsetX: -5,
      },
    },
  };

  const chart1 = {
    options: {
      colors: colors,
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        width: 3,
        dashArray: 0,
      },
      grid: {
        show: true,
        strokeDashArray: 3,
      },
      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July"],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      markers: {
        size: 6,
        colors: "#FFFFFF",
        strokeColors: colors,
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 0,
        shape: "circle",
        radius: 2,
        offsetX: 0,
        offsetY: 0,
      },
      responsive: [
        {
          breakpoint: 1025,
          options: {
            chart: {
              height: 130,
            },
          },
        },
      ],
    },
    series: [
      {
        name: "WhatsApp",
        data: [7, 4, 9, 4, 7, 3, 8],
      },
      {
        name: "SMS",
        data: [3, 5, 3, 7, 4, 6, 9],
      },
      {
        name: "Emial",
        data: [5, 6, 7, 2, 5, 8, 3],
      },
    ],
  };
  const chart2 = {
    options: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      colors: colors,
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 10,
            size: "50%",
          },
          track: {
            margin: 10,
            strokeWidth: "50%",
          },
          dataLabels: {
            show: false,
          },
        },
      },
      labels: ["Apples", "Oranges"],
    },
    series: [85, 75],
  };

  const columns = [
    {
      Header: "Name",
      accessor: "segment_name",
      disableFilters: true,
      isVisible: true,
      Cell: ({ value }) => <p className="text-truncate m-0">{value}</p>,
    },
    {
      Header: "Created by",
      accessor: "created_by",
      disableFilters: true,
      Cell: ({ value }) => {
        console.log("row");
        return value.name;
      },
    },

    {
      Header: "Used on",
      accessor: "modified_on",
      disableFilters: true,
      Cell: ({ value }) => (
        <p className="m-0">
          {value.date} {value.time}
        </p>
      ),
    },
  ];

  const columns_largest = [
    {
      Header: "Name",
      accessor: "segment_name",
      disableFilters: true,
      isVisible: true,
      Cell: ({ value }) => <p className="text-truncate m-0">{value}</p>,
    },
    {
      Header: "Created by",
      accessor: "created_by",
      disableFilters: true,
      Cell: ({ value }) => {
        console.log("row");
        return value.name;
      },
    },
    {
      Header: "Reach",
      accessor: "total_reach",
      disableFilters: true,
    },
    // {
    //   Header: "Status",
    //   accessor: "status",
    //   disableFilters: true,
    //   Cell: ({ value }) => (
    //     <Badge bg={statusToBg(value.id)} className="me-2">
    //       <span className="btn-inner">{value.value}</span>
    //     </Badge>
    //   ),
    // },
  ];

  const columns_recently_used = [
    {
      Header: "Name",
      accessor: "segment_name",
      disableFilters: true,
      isVisible: true,
      Cell: ({ value }) => <p className="text-truncate m-0">{value}</p>,
    },
    {
      Header: "Created by",
      accessor: "created_by",
      disableFilters: true,
      Cell: ({ value }) => {
        console.log("row");
        return value.name;
      },
    },
    {
      Header: "Created on",
      accessor: "created_on",
      disableFilters: true,
      Cell: ({ value }) => (
        <p className="m-0">
          {value.date} {value.time}
        </p>
      ),
    },
  ];

  const onSentClick = () => {
    redirectTo("campaign/all/sent");
  };

  const onDeliveredClick = () => {
    redirectTo("campaign/all/delivered");
  };

  const onViewedClick = () => {
    redirectTo("campaign/all/viewed");
  };
  const onClicksClick = () => {
    redirectTo("campaign/all/clicks");
  };

  const onRecentClick = () => {
    redirectTo("campaign/all");
  };

  const onFrequentlyUsedClick = () => {
    redirectTo("segment/all/frequent_used");
  };

  const onLargestClick = () => {
    redirectTo("segment/all/largest");
  };

  return (
    <Fragment>
      <Row className="row-cols-1 row-cols-md-2 row-cols-lg-4">
        <Col className="d-flex" onClick={onSentClick}>
          <CampaignStatsCard
            color="danger"
            svg={svg4}
            percentage="86%"
            counter="440"
            content="Sent"
            tooltipContent="Total number of times the campaign has been delivered to users."
          />

          {/* <CampaignStatCardLoader /> */}
        </Col>
        <Col className="d-flex" onClick={onDeliveredClick}>
          <CampaignStatsCard
            color="success"
            svg={svg4}
            percentage="10%"
            counter="440"
            content="Delivered"
            tooltipContent="Total number of times the campaign has been delivered to users."
          />
        </Col>
        <Col className="d-flex" onClick={onViewedClick}>
          <CampaignStatsCard
            color="warning"
            svg={svg1}
            percentage="9%"
            counter="1206"
            content="Viewed"
            tooltipContent="Total number of times the campaign has been viewed by users."
          />
        </Col>
        <Col className="d-flex" onClick={onClicksClick}>
          <CampaignStatsCard
            color="primary"
            svg={svg2}
            counter="1,187"
            percentage="27%"
            content="Clicks"
            tooltipContent="Total number of times the campaign has been clicked by users."
          />
        </Col>
        {/* <Col className="d-flex">
          <CampaignStatsCard
            color="info"
            svg={svg3}
            counter="238"
            percentage="43%"
            tooltipContent="Total number of times the campaign has been clicked by users."
            content="CTR"
          />
        </Col> */}
      </Row>
      <Row>
        <Col lg={6} className="d-flex">
          <Card className="flex-grow-1">
            <Card.Header className="flex-wrap d-flex align-items-center justify-content-between">
              <Card.Title>
                <h4 className="mb-0">Performance Trend</h4>
                <p className="mb-0 fs-7 text-secondary mt-2">Last 30 Days</p>
              </Card.Title>

              <Dropdown>
                <Dropdown.Toggle
                  as={CustomToggle}
                  variant="text-secondary dropdown-toggle"
                >
                  30 Days
                </Dropdown.Toggle>
                <Dropdown.Menu aria-labelledby="dropdownMenuButton1">
                  <Dropdown.Item href="#">Last 3 Months</Dropdown.Item>
                  <Dropdown.Item href="#">Last 6 Years</Dropdown.Item>
                  <Dropdown.Item href="#">This Year</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Card.Header>
            <Card.Body>
              <Chart
                options={chart1.options}
                series={chart1.series}
                type="line"
                height="300"
              />
              <div className="d-flex align-items-center justify-content-center mt-2 mt-sm-0">
                <div className="d-flex align-items-center text-success">
                  {findIcon("Whatsapp", "dual-tone", "20")}
                  <div className="ms-2">
                    <span className="fs-7">WhatsApp</span>
                  </div>
                </div>
                <div className="d-flex align-items-center ms-3 text-warning">
                  {findIcon("Email", "dual-tone", "20")}
                  <div className="ms-2">
                    <span className="fs-7">SMS</span>
                  </div>
                </div>
                <div className="d-flex align-items-center ms-3 text-info">
                  {findIcon("Message-2", "dual-tone", "20")}
                  <div className="ms-2">
                    <span className="fs-7">E-Mail</span>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
          {/* <PerformanceTrendLoader /> */}
        </Col>
        <Col lg={6} className="d-flex">
          <Card className="flex-grow-1">
            <Card.Header className="flex-wrap d-flex align-items-center justify-content-between">
              <Card.Title>
                <h4 className="mb-0">Conversion performance</h4>
                <p className="mb-0 fs-7 text-secondary mt-2">Last 30 Days</p>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Chart
                options={chart2.options}
                series={chart2.series}
                type="radialBar"
                height="300"
              />
              <div className="d-flex align-items-center justify-content-center mt-2 mt-sm-0">
                <div className="d-flex align-items-center text-primary">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <g>
                      <circle
                        cx="12"
                        cy="12"
                        r="8"
                        fill="currentColor"
                      ></circle>
                    </g>
                  </svg>
                  <div className="ms-2">
                    <span className="text-secondary">Viewed</span>
                  </div>
                </div>
                <div className="d-flex align-items-center ms-3 text-info">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <g>
                      <circle
                        cx="12"
                        cy="12"
                        r="8"
                        fill="currentColor"
                      ></circle>
                    </g>
                  </svg>
                  <div className="ms-2">
                    <span className="text-secondary">Clicked</span>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
          {/* <PerformanceTrendLoader /> */}
        </Col>
      </Row>
      <Row>
        <Col lg="12" md="12">
          <Card className="card-block">
            <Tab.Container defaultActiveKey="first">
              <div className="tab-bottom-bordered">
                <Nav
                  className="mb-0 nav-tabs justify-content-around d-flex"
                  id="nav-tab1"
                  role="tablist"
                >
                  <Nav.Link
                    className="py-3 "
                    eventKey="first"
                    data-bs-toggle="tab"
                    data-bs-target="#requently-used"
                    type="button"
                    role="tab"
                    aria-controls="requently-used"
                    aria-selected="true"
                  >
                    Frequently Used Segment
                  </Nav.Link>
                  <Nav.Link
                    className="py-3"
                    eventKey="second"
                    data-bs-toggle="tab"
                    data-bs-target="#largest-campaign"
                    type="button"
                    role="tab"
                    aria-controls="largest-campaign"
                    aria-selected="false"
                  >
                    5 Largest Segment
                  </Nav.Link>
                  <Nav.Link
                    className="py-3"
                    eventKey="three"
                    data-bs-toggle="tab"
                    data-bs-target="#requently-created"
                    type="button"
                    role="tab"
                    aria-controls="requently-created"
                    aria-selected="false"
                  >
                    Recently Created Campaign
                  </Nav.Link>
                </Nav>
              </div>
              <Tab.Content className="iq-tab-fade-up" id="nav-tabContent">
                <Tab.Pane
                  eventKey="first"
                  id="requently-used"
                  role="tabpanel"
                  aria-labelledby="requently-used"
                >
                  <div className="table-responsive p-3">
                    <ReactTable
                      data={recomended_segment_demo_data}
                      columns={columns}
                      recordsTotal={10}
                    />
                    <div className="d-flex justify-content-start mt-4">
                      <Button
                        type="button"
                        variant="outline-info btn-sm rounded-2"
                        onClick={onFrequentlyUsedClick}
                      >
                        {" "}
                        View More
                      </Button>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="second"
                  id="largest-campaign"
                  role="tabpanel"
                  aria-labelledby="largest-campaign"
                >
                  <div className="table-responsive p-3">
                    <ReactTable
                      data={largest_segment_demo_data}
                      columns={columns_largest}
                      recordsTotal={10}
                    />
                    <div className="d-flex justify-content-start mt-4">
                      <Button
                        type="button"
                        variant="outline-info btn-sm rounded-2"
                        onClick={onLargestClick}
                      >
                        View More
                      </Button>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="three"
                  id="requently-created"
                  role="tabpanel"
                  aria-labelledby="requently-created"
                >
                  <div className="table-responsive p-3">
                    <ReactTable
                      data={recently_used_segment_data}
                      columns={columns_recently_used}
                      recordsTotal={10}
                    />
                    <div className="d-flex justify-content-start mt-4">
                      <Button
                        type="button"
                        variant="outline-info btn-sm rounded-2"
                        onClick={onRecentClick}
                      >
                        {" "}
                        View More
                      </Button>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Card>
          {/* <CommonTableSkeleton /> */}
        </Col>
      </Row>
    </Fragment>
  );
};

export default CampaignDashboard;
