import React from "react";
import { Col, Row } from "react-bootstrap";
// import Card from "../../../../components/bootstrap/card";
import CountUp from "react-countup";
import { Card } from "react-bootstrap";
import useRedirect from "../../../../components/custom-hooks/use-redirect";

const HcpDashboard = () => {
  const { redirectTo } = useRedirect();

  const onAssignedClick = () => {
    redirectTo("hcp-profile/all/assigned");
  };
  const onPendingClick = () => {
    redirectTo("hcp-profile/all/pending");
  };
  const onApprovedClick = () => {
    redirectTo("hcp-profile/all/approved");
  };
  const onReviewClick = () => {
    redirectTo("hcp-profile/all/review");
  };
  const onOverDueClick = () => {
    redirectTo("hcp-profile/all/overdue");
  };
  return (
    <>
      <div className="d-flex mb-4">
        <h3>HCP Dashboard</h3>
      </div>
      <Col lg="12">
        <Card>
          <Card.Body>
            <Row>
              <Col>
                <Card onClick={onAssignedClick}>
                  <Card.Body className="bg-soft-danger rounded-3 cursor-pointer">
                    <div className="d-flex justify-content-between ">
                      <div className="bg-soft-danger rounded p-3 d-flex align-items-center">
                        <svg width="24px" height="24px" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M13,2.05C18.05,2.55 22,6.82 22,12C22,13.45 21.68,14.83 21.12,16.07L18.5,14.54C18.82,13.75 19,12.9 19,12C19,8.47 16.39,5.57 13,5.08V2.05M12,19C14.21,19 16.17,18 17.45,16.38L20.05,17.91C18.23,20.39 15.3,22 12,22C6.47,22 2,17.5 2,12C2,6.81 5.94,2.55 11,2.05V5.08C7.61,5.57 5,8.47 5,12A7,7 0 0,0 12,19M12,6A6,6 0 0,1 18,12C18,14.97 15.84,17.44 13,17.92V14.83C14.17,14.42 15,13.31 15,12A3,3 0 0,0 12,9L11.45,9.05L9.91,6.38C10.56,6.13 11.26,6 12,6M6,12C6,10.14 6.85,8.5 8.18,7.38L9.72,10.05C9.27,10.57 9,11.26 9,12C9,13.31 9.83,14.42 11,14.83V17.92C8.16,17.44 6,14.97 6,12Z"
                          />
                        </svg>
                      </div>
                      <div className="d-flex flex-column justify-content-end align-items-end cursorPointer">
                        <span className="fs-5">Assigned HCPs</span>
                        <h4 className="mt-4 fw-semibold">
                          <CountUp start={789} end={18378} duration={3} />K
                        </h4>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card onClick={onPendingClick}>
                  <Card.Body className="bg-soft-success rounded-3 cursor-pointer">
                    <div className="d-flex justify-content-between">
                      <div className="bg-soft-success rounded p-3 d-flex align-items-center">
                        <svg width="24px" height="24px" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M13,2.05C18.05,2.55 22,6.82 22,12C22,13.45 21.68,14.83 21.12,16.07L18.5,14.54C18.82,13.75 19,12.9 19,12C19,8.47 16.39,5.57 13,5.08V2.05M12,19C14.21,19 16.17,18 17.45,16.38L20.05,17.91C18.23,20.39 15.3,22 12,22C6.47,22 2,17.5 2,12C2,6.81 5.94,2.55 11,2.05V5.08C7.61,5.57 5,8.47 5,12A7,7 0 0,0 12,19M12,6A6,6 0 0,1 18,12C18,14.97 15.84,17.44 13,17.92V14.83C14.17,14.42 15,13.31 15,12A3,3 0 0,0 12,9L11.45,9.05L9.91,6.38C10.56,6.13 11.26,6 12,6M6,12C6,10.14 6.85,8.5 8.18,7.38L9.72,10.05C9.27,10.57 9,11.26 9,12C9,13.31 9.83,14.42 11,14.83V17.92C8.16,17.44 6,14.97 6,12Z"
                          />
                        </svg>
                      </div>
                      <div className="d-flex flex-column justify-content-end align-items-end cursorPointer">
                        <span className="fs-5">Pending HCPs</span>
                        <h4 className="mt-4 fw-semibold">
                          <CountUp start={3000} end={8400} duration={3} />K
                        </h4>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card onClick={onApprovedClick}>
                  <Card.Body className="bg-soft-warning rounded-3 cursor-pointer">
                    <div className="d-flex justify-content-between">
                      <div className="bg-soft-warning rounded p-3 d-flex align-items-center">
                        <svg width="24px" height="24px" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M13,2.05C18.05,2.55 22,6.82 22,12C22,13.45 21.68,14.83 21.12,16.07L18.5,14.54C18.82,13.75 19,12.9 19,12C19,8.47 16.39,5.57 13,5.08V2.05M12,19C14.21,19 16.17,18 17.45,16.38L20.05,17.91C18.23,20.39 15.3,22 12,22C6.47,22 2,17.5 2,12C2,6.81 5.94,2.55 11,2.05V5.08C7.61,5.57 5,8.47 5,12A7,7 0 0,0 12,19M12,6A6,6 0 0,1 18,12C18,14.97 15.84,17.44 13,17.92V14.83C14.17,14.42 15,13.31 15,12A3,3 0 0,0 12,9L11.45,9.05L9.91,6.38C10.56,6.13 11.26,6 12,6M6,12C6,10.14 6.85,8.5 8.18,7.38L9.72,10.05C9.27,10.57 9,11.26 9,12C9,13.31 9.83,14.42 11,14.83V17.92C8.16,17.44 6,14.97 6,12Z"
                          />
                        </svg>
                      </div>
                      <div className="d-flex flex-column justify-content-end align-items-end">
                        <span className="fs-5">Approved HCPs</span>
                        <h4 className="mt-4 fw-semibold">
                          <CountUp start={2000} end={3750} duration={3} />K
                        </h4>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card onClick={onReviewClick}>
                  <Card.Body className="bg-soft-primary rounded-3 cursor-pointer">
                    <div className="d-flex justify-content-between">
                      <div className="bg-soft-primary rounded p-3 d-flex align-items-center">
                        <svg width="24px" height="24px" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M13,2.05C18.05,2.55 22,6.82 22,12C22,13.45 21.68,14.83 21.12,16.07L18.5,14.54C18.82,13.75 19,12.9 19,12C19,8.47 16.39,5.57 13,5.08V2.05M12,19C14.21,19 16.17,18 17.45,16.38L20.05,17.91C18.23,20.39 15.3,22 12,22C6.47,22 2,17.5 2,12C2,6.81 5.94,2.55 11,2.05V5.08C7.61,5.57 5,8.47 5,12A7,7 0 0,0 12,19M12,6A6,6 0 0,1 18,12C18,14.97 15.84,17.44 13,17.92V14.83C14.17,14.42 15,13.31 15,12A3,3 0 0,0 12,9L11.45,9.05L9.91,6.38C10.56,6.13 11.26,6 12,6M6,12C6,10.14 6.85,8.5 8.18,7.38L9.72,10.05C9.27,10.57 9,11.26 9,12C9,13.31 9.83,14.42 11,14.83V17.92C8.16,17.44 6,14.97 6,12Z"
                          />
                        </svg>
                      </div>
                      <div className="d-flex flex-column justify-content-end align-items-end">
                        <span className="fs-5">In-Review HCPs</span>
                        <h4 className="mt-4 fw-semibold">
                          <CountUp start={4000} end={6228} duration={3} />K
                        </h4>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="mb-0" onClick={onOverDueClick}>
                  <Card.Body className="bg-soft-primary rounded-3 cursor-pointer">
                    <div className="d-flex justify-content-between">
                      <div className="bg-soft-primary rounded p-3 d-flex align-items-center">
                        <svg width="24px" height="24px" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M13,2.05C18.05,2.55 22,6.82 22,12C22,13.45 21.68,14.83 21.12,16.07L18.5,14.54C18.82,13.75 19,12.9 19,12C19,8.47 16.39,5.57 13,5.08V2.05M12,19C14.21,19 16.17,18 17.45,16.38L20.05,17.91C18.23,20.39 15.3,22 12,22C6.47,22 2,17.5 2,12C2,6.81 5.94,2.55 11,2.05V5.08C7.61,5.57 5,8.47 5,12A7,7 0 0,0 12,19M12,6A6,6 0 0,1 18,12C18,14.97 15.84,17.44 13,17.92V14.83C14.17,14.42 15,13.31 15,12A3,3 0 0,0 12,9L11.45,9.05L9.91,6.38C10.56,6.13 11.26,6 12,6M6,12C6,10.14 6.85,8.5 8.18,7.38L9.72,10.05C9.27,10.57 9,11.26 9,12C9,13.31 9.83,14.42 11,14.83V17.92C8.16,17.44 6,14.97 6,12Z"
                          />
                        </svg>
                      </div>
                      <div className="d-flex flex-column justify-content-end align-items-end">
                        <span className="fs-5">Over-due HCPs</span>
                        <h4 className="mt-4 fw-semibold">
                          <CountUp start={1000} end={2554} duration={3} />K
                        </h4>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

export default HcpDashboard;
