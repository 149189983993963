import React from "react";

// layout

import Default from "../../../../../layouts/dashboard/default";
import AllFileTypes from "../pages/all-file-types";

export const FileTypeManagementRouter = [
  {
    path: "/master-management/file-type",
    element: <Default />,
    children: [
      {
        path: "all",
        element: <AllFileTypes />,
      },
    ],
  },
];
