import React, { memo, Fragment, useEffect } from "react";

// React-bootstrap
import { Row, Col } from "react-bootstrap";

//Component
// import Notificationcard from "../components/notification"
import Notificationcard from "../components/notification-card";

//Img
import img1 from "../../../../assets/modules/social/images/avatar/01.png";
import img2 from "../../../../assets/modules/social/images/avatar/02.png";
import img3 from "../../../../assets/modules/social/images/avatar/03.png";
import img4 from "../../../../assets/modules/social/images/avatar/04.png";
import img5 from "../../../../assets/modules/social/images/avatar/05.png";
import img6 from "../../../../assets/modules/social/images/avatar/06.png";
import img7 from "../../../../assets/modules/social/images/avatar/07.png";
import img8 from "../../../../assets/modules/social/images/avatar/08.png";
import img9 from "../../../../assets/modules/social/images/avatar/09.png";
import img10 from "../../../../assets/modules/social/images/avatar/10.png";
import img11 from "../../../../assets/modules/social/images/avatar/11.png";
import img12 from "../../../../assets/modules/social/images/avatar/12.png";
import img13 from "../../../../assets/modules/social/images/avatar/13.png";
import img14 from "../../../../assets/modules/social/images/avatar/14.png";
import img16 from "../../../../assets/modules/social/images/avatar/16.png";

const Notification = memo(() => {
  const superAdminNotifications = [
    {
      image: img2,
      title: "Sumit Mandol commented on Requisition-2",
      svg: "comment",
      color: "primary",
      date: "30 mins ago",
      read: true,
    },
    {
      image: img3,
      title: "PDF content added by Indranil Kundu",
      svg: "add",
      color: "warning",
      date: " 35 mins ago",
      read: true,
    },
    {
      image: img4,
      title: "PPT content uploaded by Arnab Saha",
      svg: "upload",
      color: "danger",
      date: "1 hour ago",
      read: false,
    },
    {
      image: img5,
      title: "Requisition added by Saif Imroz",
      svg: "add",
      color: "warning",
      date: "2 hour ago",
      read: false,
    },
    {
      image: img6,
      title: "Requisition added by Sanjoy Dey",
      svg: "add",
      color: "warning",
      date: " 3 hour ago",
      read: true,
    },
    {
      image: img7,
      title: "Infographic content uploaded by Swagata Das",
      svg: "upload",
      color: "danger",
      date: "4 hour ago",
      read: true,
    },
    {
      image: img8,
      title: "Article added by Shivam Roy",
      svg: "add",
      color: "warning",
      date: "5 hour ago",
      read: false,
    },
    {
      image: img9,
      title: "Video content added by Indranil Kundu",
      svg: "add",
      color: "warning",
      date: "6 hour ago",
      read: false,
    },
    {
      image: img10,
      title: "HTML content uploaded by Sanjoy Dey",
      svg: "upload",
      color: "danger",
      date: "7 hour ago",
      read: false,
    },
    {
      image: img12,
      title: "Requisition added by Saif Imroz",
      svg: "add",
      color: "warning",
      date: "9 hour ago",
      read: false,
    },
    {
      image: img13,
      title: "PDF content added by Indranil Kundu",
      svg: "add",
      color: "warning",
      date: "10 hour ago",
      read: false,
    },
    {
      image: img14,
      title: "Sumit Mandol commented on Requisition-2",
      svg: "comment",
      color: "primary",
      date: "1 day ago",
      read: false,
    },
    {
      image: img16,
      title: "Sumit Mandol commented on Requisition-3",
      svg: "comment",
      color: "primary",
      date: "1 day ago",
      read: false,
    },
  ];
  const teamNotifications = [
    {
      image: img2,
      title: "Sumit Mandol commented on Requisition-2",
      svg: "comment",
      color: "primary",
      date: "30 mins ago",
      read: false,
    },
    {
      image: img3,
      title: "PDF content added by Indranil Kundu",
      svg: "add",
      color: "warning",
      date: " 35 mins ago",
      read: false,
    },
    {
      image: img4,
      title: "PPT content uploaded by Arnab Saha",
      svg: "upload",
      color: "danger",
      date: "1 hour ago",
      read: true,
    },
    {
      image: img5,
      title: "Saif Imroz commented on Requisition-2",
      svg: "comment",
      color: "primary",
      date: "2 hour ago",
      read: true,
    },
    {
      image: img6,
      title: "Sanjoy Dey commented on Requisition-2",
      svg: "comment",
      color: "primary",
      date: " 3 hour ago",
      read: true,
    },
    {
      image: img7,
      title: "Infographic content added by Swagata Das",
      svg: "add",
      color: "warning",
      date: "4 hour ago",
      read: true,
    },
    {
      image: img8,
      title: "Article added by Shivam Roy",
      svg: "add",
      color: "warning",
      date: "5 hour ago",
      read: false,
    },
    {
      image: img9,
      title: "Video content added by Indranil Kundu",
      svg: "add",
      color: "warning",
      date: "6 hour ago",
      read: true,
    },
    {
      image: img10,
      title: "HTML content uploaded by Sanjoy Dey",
      svg: "upload",
      color: "danger",
      date: "7 hour ago",
      read: true,
    },
    {
      image: img11,
      title: "Arnab Saha commented on Requisition-2",
      svg: "comment",
      color: "primary",
      date: "8 hour ago",
      read: false,
    },
    {
      image: img12,
      title: "Audio content added by Saif Imroz",
      svg: "add",
      color: "warning",
      date: "9 hour ago",
      read: true,
    },
    {
      image: img13,
      title: "PDF content added by Indranil Kundu",
      svg: "add",
      color: "warning",
      date: "10 hour ago",
      read: true,
    },
    {
      image: img14,
      title: "Sumit Mandol commented on Requisition-2",
      svg: "comment",
      color: "primary",
      date: "1 day ago",
      read: true,
    },
    {
      image: img16,
      title: "Sumit Mandol commented on Requisition-3",
      svg: "comment",
      color: "primary",
      date: "1 day ago",
      read: true,
    },
  ];
  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <h3 className="card-title my-3">Notification</h3>
        </Col>
        <Col sm="12">
          {superAdminNotifications.map((_n) => (
            <Notificationcard
              image={_n.image}
              title={_n.title}
              svg={_n.svg}
              color={_n.color}
              date={_n.date}
              read={_n.read}
            />
          ))}
        </Col>
      </Row>
    </Fragment>
  );
});
Notification.displayName = "Notification";
export default Notification;
