import dayjs from "dayjs";
import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { Views } from "react-big-calendar";
export const CalendarTodayButton = ({ setDate, date, unitType, viewMode }) => {
  const getTodayButtonLabel = (viewMode, date) => {
    if (viewMode === Views.MONTH || viewMode === Views.AGENDA) {
      // const monthName = dayjs(date).locale('en').format('MMMM')
      // if (monthName === dayjs().locale('en').format('MMMM')) {
      //   return "This month";
      // }
      // else {
      //   return monthName;
      // }
      return "This month"
    }
    if (viewMode === Views.WEEK || viewMode === Views.WORK_WEEK)
      return "This week";
    return "Today";
  };

  return (
    <ButtonGroup>
      <Button
        color="info"
        className="btn px-2 py-2 d-flex align-items-center justify-content-center"
        onClick={() => setDate(dayjs(date).add(-1, unitType).toDate())}
        icon="ChevronLeft"
        aria-label="Prev">
        <svg
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="icon-20"
          width="32"
          height="32"
          viewBox="0 0 24 24">
          <path
            d="M15.5 19L8.5 12L15.5 5"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"></path>
        </svg>
      </Button>
      {/* @ts-ignore*/}
      <Button
        className="btn p-2"
        isLight
        onClick={() => setDate(dayjs().toDate())}>
        {getTodayButtonLabel(viewMode, date)}
      </Button>
      <Button
        className="btn px-2 py-2 d-flex align-items-center justify-content-center"
        // @ts-ignore
        onClick={() => setDate(dayjs(date).add(1, unitType).toDate())}
        icon="ChevronRight"
        aria-label="Next">
        <svg
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="icon-20"
          width="32"
          height="32"
          viewBox="0 0 24 24">
          <path
            d="M8.5 5L15.5 12L8.5 19"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"></path>
        </svg>
      </Button>
    </ButtonGroup>
  );
};

export default CalendarTodayButton;
