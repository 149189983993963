import React from "react";
import SidebarBorderProfileCard from "../../../../components/partials/components/sidebar/sidebar-border-profile-card";
import { Button, Col, Card, Form, Row } from "react-bootstrap";
import FilePicker from "../../../../components/form-components/file-picker";
import ContentDetails from "../../../../components/partials/common/content-details";
import { useFormik } from "formik";
import {
  addFileInitialValues,
  addFileSchema,
  addPDFInitialValues,
  addPDFSchema,
  addPPTInitialValues,
  addPPTSchema,
  adddocxInitialValues,
  adddocxSchema,
  addzipInitialValues,
  addzipSchema,
} from "../constants/add-file";
import uploadService from "../../../../api/services/upload-services";
import {
  ADD_FILE,
  UPLOAD_FILE,
} from "../../../../api/endpoints/add-content-endpoints";
import { BasicQuill } from "../../plugins/pages/quill-editor";
import toast from "react-hot-toast";
import postService from "../../../../api/services/post-service";
import { useParams } from "react-router-dom";
import { getFormatedDate } from "../../../../common/utils/utils";

const AddFile = ({
  type = "",
  formFields,
  content_file_type_id,
  accepts,
  actionData = null,
  onCreateContent,
}) => {
  const { actionId } = useParams();
  function getInitialValues() {
    if (type === "pdf") return addPDFInitialValues;
    else if (type === "ppt") return addPPTInitialValues;
    else if (type === "docx") return adddocxInitialValues;
    else if (type === "zip") return addzipInitialValues;
    else return addFileInitialValues;
  }

  function getFileSchema() {
    if (type === "pdf") return addPDFSchema;
    else if (type === "ppt") return addPPTSchema;
    else if (type === "docx") return adddocxSchema;
    else if (type === "zip") return addzipSchema;
    else return addFileSchema;
  }
  const {
    touched,
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: getInitialValues(),
    validationSchema: getFileSchema(),
    onSubmit: (value, action) => {
      const payload = {
        speciality_id: value.speciality,
        content_title: value.title,
        content_description: value.body,
        content_file_type_id: content_file_type_id,
        title_html: value.title_html,
        description_html: value.body_html,
        main_file: value.file.name,
        cleaned_text: "",
        reference_files: "",
        thumbnail: value.thumbnail.name,
        cover_image: value.base.name,
      };
      alert(JSON.stringify(payload));
      onCreateContent(payload);
    },
  });

  function setFile(files, keyName) {
    if (files.length > 0) {
      let formdata = new FormData();
      [...files].forEach((file) => {
        formdata.append("files[]", file);
      });
      uploadService(UPLOAD_FILE, formdata)
        .then((data) => {
          if (data !== null)
            setFieldValue(keyName, {
              name: data.data.data[0].name,
              url: data.data.data[0].url,
            });
          else toast.error("File upload Failed");
        })
        .catch((err) => console.log(err));
    } else {
      setFieldValue(keyName, null);
    }
  }
  return (
    <>
      {actionData && (
        <ContentDetails
          brand={
            actionData?.origin_id?.requisition_brand_id?.principal_entity_name
          }
          date={getFormatedDate(actionData?.created_at)}
          department=""
          title={actionData?.action_name}
          description={actionData?.origin_id?.requisition_description}
          fileTypes={[]}
          userName={actionData?.action_assign_by?.name}
        />
      )}
      <Col lg="12" className="d-flex">
        <Card className="w-100">
          <Card.Body>
            <Form className="h-100" onSubmit={handleSubmit}>
              <Row className="gap-4 gap-lg-0">
                <Col lg="6">
                  <FilePicker
                    title={
                      values.file !== null
                        ? values.file?.name
                        : type == "file"
                        ? "Add Infographic"
                        : type == "ppt"
                        ? "Add PPT"
                        : type == "pdf"
                        ? "Add PDF"
                        : type == "zip"
                        ? "Add ZIP"
                        : type == "docx"
                        ? "Add DOCX"
                        : type == "xlsx"
                        ? "Add XLSX"
                        : ""
                    }
                    onUpdate={(val) => setFile(val, "file")}
                    accepts={accepts}
                    fileName={values.file?.name}
                    type={type}
                    source={values.file?.url}
                  />
                  {touched.file && "file" in errors && (
                    <div style={{ color: "red" }}>{errors.file}</div>
                  )}
                  <Row className="gap-4 gap-lg-0 mt-4">
                    <Col lg="6">
                      <FilePicker
                        title="Add Cover Photo"
                        onUpdate={(val) => setFile(val, "base")}
                        accepts="image/png, image/gif, image/jpeg"
                        source={values?.base?.url}
                        fileName={values?.base?.name}
                        type="image"
                      />
                      {touched.base && "base" in errors && (
                        <div style={{ color: "red" }}>{errors.base}</div>
                      )}
                    </Col>
                    <Col lg="6">
                      <FilePicker
                        title="Add Thumbnail"
                        onUpdate={(val) => setFile(val, "thumbnail")}
                        accepts="image/png, image/gif, image/jpeg"
                        source={values?.thumbnail?.url}
                        fileName={values?.thumbnail?.name}
                        type="image"
                      />
                      {touched.thumbnail && "thumbnail" in errors && (
                        <div style={{ color: "red" }}>{errors.thumbnail}</div>
                      )}
                    </Col>
                  </Row>

                  {/* <div className="d-flex justify-content-center align-items-center mt-4">
										<Button
											className="btn btn-info flex-grow-1 flex-sm-grow-0"
											type="btn"
										>
											Preview
										</Button>
									</div> */}
                </Col>
                <Col lg="6">
                  <Row className="h-100 flex-column flex-nowrap">
                    <Col lg="12" className="mb-4">
                      <Form.Floating className="custom-form-floating">
                        <Form.Select
                          className=""
                          id="floatingInput1"
                          name="speciality"
                          value={values.speciality}
                          onChange={handleChange}
                          isInvalid={touched.speciality && !!errors.speciality}
                        >
                          <option value="" selected hidden>
                            {" "}
                            Select Speciality
                          </option>
                          {formFields &&
                            formFields?.specialities &&
                            formFields?.specialities.map((item) => (
                              <option
                                value={item?.speciality_id}
                                key={item?.speciality_id}
                              >
                                {item?.speciality_name}
                              </option>
                            ))}
                        </Form.Select>
                        <Form.Label htmlFor="floatingInput">
                          Select Speciality{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                    <Form.Group className="col-12 form-group">
                      <Form.Label>
                        Title <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <div>
                        <BasicQuill
                          name="title"
                          value={values.title}
                          updateData={(value) => {
                            setFieldValue("title", value.rawText);
                            setFieldValue("title_html", value.html);
                          }}

                          // updateData={(e) => setformData(prev => { return { ...prev, article:{...prev.article,title : e} } })}
                        ></BasicQuill>
                        {touched.title && "title" in errors && (
                          <div style={{ color: "red" }}>{errors.title}</div>
                        )}
                      </div>
                    </Form.Group>
                    <Form.Group className="col-12 form-group">
                      <Form.Label>
                        Body <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <div>
                        <BasicQuill
                          name="body"
                          value={values.body}
                          updateData={(value) => {
                            setFieldValue("body", value.rawText);
                            setFieldValue("body_html", value.html);
                          }}
                        ></BasicQuill>
                        {touched.body && "body" in errors && (
                          <div style={{ color: "red" }}>{errors.body}</div>
                        )}
                      </div>
                    </Form.Group>

                    {/* <Col lg="12" className="mb-4 flex-grow-1">
											<Form.Floating className="custom-form-floating h-100">
												<textarea
													class="form-control h-100"
													placeholder="Leave a comment here"
													id="floatingTextarea2"
												></textarea>
												<Form.Label htmlFor="fname">Add Comment</Form.Label>
											</Form.Floating>
										</Col> */}
                    {/* <Col lg="6" className="mb-4">
                            <Form.Floating className="custom-form-floating">
                              <Form.Select className="" id="floatingInput1">
                                <option value="">Select Department</option>

                                <option value="text">Text</option>

                                <option value="video">Video</option>

                                <option value="audio">Audio</option>
                              </Form.Select>
                              <Form.Label htmlFor="floatingInput">
                                Select Department
                              </Form.Label>
                            </Form.Floating>
                          </Col>
                          <Col lg="6" className="mb-4">
                            <Form.Floating className="custom-form-floating">
                              <Form.Select className="" id="floatingInput1">
                                <option value="">Select User to Assign</option>

                                <option value="text">Text</option>

                                <option value="video">Video</option>

                                <option value="audio">Audio</option>
                              </Form.Select>
                              <Form.Label htmlFor="floatingInput">
                                Select User to Assign
                              </Form.Label>
                            </Form.Floating>
                          </Col> */}
                    <Col lg="12" className="d-flex justify-content-end">
                      <Button
                        className="btn btn-primary flex-grow-1 flex-sm-grow-0"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

export default AddFile;
