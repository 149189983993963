import React from "react";
import { Badge, Button } from "react-bootstrap";
import { findIcon } from "../../views/dashboard/icons";
const details = findIcon("Arrow Box Left", "solid")
export const DATA = [
  {
    title: "Clara Mist",
    requestedOn: "17 Mar, 2023",
    fileType: "file Type",
    department: "Department Name",
    brand: "Brand Name",
    creator: "Created by",
    statusColor: "warning",
    bgColor: "warning",
    status: "Pending",
    actionDetails: details,
  },
  {
    title: "Clara Mist",
    requestedOn: "17 Mar, 2023",
    fileType: "file Type",
    department: "Department Name",
    brand: "Brand Name",
    creator: "Created by",
    statusColor: "warning",
    bgColor: "warning",
    status: "Pending",
    actionDetails: details,
  },
  {
    title: "Clara Mist",
    requestedOn: "17 Mar, 2023",
    fileType: "file Type",
    department: "Department Name",
    brand: "Brand Name",
    creator: "Created by",
    statusColor: "warning",
    bgColor: "warning",
    status: "Pending",
    actionDetails: details,
  },
  {
    title: "Clara Mist",
    requestedOn: "17 Mar, 2023",
    fileType: "file Type",
    department: "Department Name",
    brand: "Brand Name",
    creator: "Created by",
    statusColor: "warning",
    bgColor: "warning",
    status: "Pending",
    actionDetails: details,
  },
  {
    title: "Clara Mist",
    requestedOn: "17 Mar, 2023",
    fileType: "file Type",
    department: "Department Name",
    brand: "Brand Name",
    creator: "Created by",
    statusColor: "warning",
    bgColor: "warning",
    status: "Pending",
    actionDetails: details,
  },
];
export const COLUMNS = [
  {
    Header: "Title",
    accessor: "title",
    // Filter: DefaultColumnFilter,
  },
  {
    Header: "Requested On",
    accessor: "requestedOn",
    // Filter: DefaultColumnFilter,
  },
  {
    Header: "File Type",
    accessor: "fileType",
    // Filter: DefaultColumnFilter,
  },
  {
    Header: "Department",
    accessor: "department",
    // Filter: DefaultColumnFilter,
  },
  {
    Header: "Brand",
    accessor: "brand",
    // Filter: DefaultColumnFilter,
  },
  {
    Header: "Creator",
    accessor: "creator",
    // Filter: DefaultColumnFilter,
  },
  {
    Header: "Status",
    accessor: "status",
    // Filter: DefaultColumnFilter,
    Cell: ({ row }) => (
      <div className="d-flex align-items-center justify-content-center">
        <Badge variant={"primary"} className="me-2">
          {row.original.status}
        </Badge>
      </div>
    ),
  },
  {
    Header: "Action",
    accessor: "actionDetails",
    Cell: ({ row }) => (
      <div className="d-flex justify-content-center">
        <Button
          className="btn btn-icon btn-sm rounded-pill"
          to="#"
          role="button"
        >
          <span className="btn-inner">{findIcon('Arrow Box Up', 'dual-tone')}</span>
        </Button>
      </div>
    ),

    // Filter: DefaultColumnFilter,
  },
];
