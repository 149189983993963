import { memo, Fragment, useState } from "react";

//img

//react-bootstrap
import { Row, Col, Container, Form, Tab, Nav } from "react-bootstrap";

//components
import Card from "../../../components/bootstrap/card";

//components
import { Link } from "react-router-dom";

const userProfileEdit = memo(() => {
  const [imageController, setImageController] = useState({
    toggler: false,
    slide: 1,
  });
  function imageOnSlide(number) {
    setImageController({
      toggler: !imageController.toggler,
      slide: number,
    });
  }
  return (
    <Fragment>
      <Container fluid>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row>
            <Col lg="3" className="col-lg-3">
              <Nav
                as="ul"
                className="d-flex flex-column nav-pills mb-0 text-center profile-tab"
                data-toggle="slider-tab"
                id="profile-pills-tab"
                role="tablist"
              >
                <Nav.Item as="li">
                  <Nav.Link eventKey="first">Feed</Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link eventKey="second">Activity</Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link eventKey="third">Friends</Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link eventKey="fourth">Profile</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col lg="9" className="col-lg-9">
              <Tab.Content className="profile-content">
                <Tab.Pane eventKey="first" id="profile-feed">
                  <button className="primary">A</button>
                </Tab.Pane>
                <Tab.Pane eventKey="second" id="profile-activity">
                  <button className="primary">A</button>
                </Tab.Pane>
                <Tab.Pane eventKey="third" id="profile-friends">
                  C
                </Tab.Pane>
                <Tab.Pane eventKey="fourth" id="profile-profile">
                  D
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </Fragment>
  );
});

userProfileEdit.displayName = "userProfileEdit";
export default userProfileEdit;
