import React from "react";
import { Button } from "react-bootstrap";
import { findIcon } from "../../../views/dashboard/icons";
const defaultButtons = ["view", "edit", "delete"];
const ActionButtonBar = ({
  buttons = defaultButtons,
  row,
  onView,
  onEdit,
  onDelete,
  onSelect,
}) => {
  return (
    <div className="d-flex justify-content-center align-items-center gap-2">
      {buttons.includes("view") && (
        <Button
          className="btn-icon btn-sm rounded-pill"
          variant="info"
          to="#"
          role="button"
          onClick={() => onView(row.original)}
        >
          <span className="btn-inner">{findIcon("Eye", "solid", 32)}</span>
        </Button>
      )}
      {buttons.includes("edit") && (
        <Button
          className="btn-icon btn-sm rounded-pill"
          to="#"
          role="button"
          onClick={() => onEdit(row.original)}
        >
          <span className="btn-inner">{findIcon("Pencil", "dual-tone")}</span>
        </Button>
      )}
      {buttons.includes("delete") && (
        <Button
          className="btn-icon btn-sm rounded-pill"
          variant="danger"
          to="#"
          role="button"
          onClick={() => onDelete(row.original)}
        >
          <span className="btn-inner">
            {findIcon("Trash", "dual-tone", 32)}
          </span>
        </Button>
      )}
      {buttons.includes("select") && (
        <Button
          className="btn-icon btn-sm rounded-pill"
          variant="danger"
          to="#"
          role="button"
          onClick={() => onSelect(row.original)}
        >
          <span className="btn-inner">
            {findIcon("Arrow Right", "dual-tone", 32)}
          </span>
        </Button>
      )}
    </div>
  );
};

export default ActionButtonBar;
