import Skeleton from "../../components/skeleton";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";

const AddRoleLoader = () => {
  return (
    <>
      <Card>
        <Card.Body>
          <Row>
            <Col lg="6" className="d-flex flex-column">
              <Skeleton
                variant="text"
                width="15%"
                height={20}
                className="mb-3"
              />
              <Skeleton
                variant="rounded"
                width="100%"
                height={45}
                className="mb-3"
              />
              <Skeleton variant="rounded" width="100%" height={100} />
            </Col>
            <Col lg="6" className="d-flex flex-column">
              <Skeleton
                variant="text"
                width="15%"
                height={20}
                className="mb-3"
              />
              <div className="d-flex mb-4 gap-2">
                <Skeleton
                  variant="rounded"
                  width="88%"
                  height={45}
                  className="mb-3"
                />
                <Skeleton
                  variant="rounded"
                  width="12%"
                  height={45}
                  className="mb-3"
                />
              </div>
            </Col>
            <Col lg="12" className="d-flex justify-content-end mt-4 gap-2">
              <Skeleton
                variant="rounded"
                width="10%"
                height={45}
                className="mb-3"
              />
              <Skeleton
                variant="rounded"
                width="10%"
                height={45}
                className="mb-3"
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default AddRoleLoader;
