import React, { useState, memo } from "react";
import { Button, Modal } from "react-bootstrap";
import WhatsappTemplatePreview from "./whatsapp-template-preview";
import SmsTemplatePreview from "./sms-template-preview";
import EmailTemplatePreview from "./email-template-preview";
import { findIcon } from "../../../dashboard/icons";

const TemplateListModal = ({
  showTemplates,
  modalClose,
  type,
  options,
  getSelectedTemplate,
  selectedTemplateData,
}) => {
  const [emailTemplatePreview, setEmailTemplatePreview] = useState(false);
  const [emailViewDetails, setEmailViewDetails] = useState(false);
  const isEmailTemplatePreview = () => setEmailTemplatePreview(true);
  const isEmailViewDetails = () => setEmailViewDetails(true);

  const selectTemplate = () => {
    modalClose();
    setEmailTemplatePreview(false);
  };
  const closeCreateModal = () => {
    modalClose();
    getSelectedTemplate({
      id: "",
      name: "",
      content: "",
      approve: "",
    });
    setEmailTemplatePreview(false);
  };

  console.log("options", options);
  return (
    <Modal
      show={showTemplates}
      onHide={modalClose}
      fullscreen={true}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {type} Templates
        </Modal.Title>
        <div className="d-flex justify-content-center align-items-center gap-3">
          <div className="d-flex justify-content-start gap-4">
            {/* {!emailViewDetails && (
              <a
                href="javascript:void(0)"
                eventKey="link-1"
                className="d-inline-flex align-items-center gap-1">
                {findIcon("Save", "dual-tone", 22)}
                Save Draft
              </a>
            )} */}
            {type == "Email" && emailTemplatePreview && (
              <a
                href="javascript:void(0)"
                eventKey="link-1"
                className="d-inline-flex align-items-center gap-1 link-primary"
                onClick={selectTemplate}
              >
                Select
                {findIcon("Arrow Circle Right", "dual-tone", 22)}
              </a>
            )}
            <a
              href="javascript:void(0)"
              eventKey="link-1"
              className="d-inline-flex align-items-center gap-1 link-danger"
              onClick={closeCreateModal}
            >
              {findIcon("Square X", "dual-tone", 22)}
              Close
            </a>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column">
        {type == "WhatsApp" && (
          <WhatsappTemplatePreview
            suggestionlist={options}
            modalClose={modalClose}
            getSelectedTemplate={getSelectedTemplate}
            selectedTemplateData={selectedTemplateData}
          />
        )}
        {type == "Message" && (
          <SmsTemplatePreview
            suggestionlist={options}
            modalClose={modalClose}
            getSelectedTemplate={getSelectedTemplate}
            selectedTemplateData={selectedTemplateData}
          />
        )}
        {type == "Email" && (
          <EmailTemplatePreview
            suggestionlist={options}
            modalClose={modalClose}
            getSelectedTemplate={getSelectedTemplate}
            isEmailTemplatePreview={isEmailTemplatePreview}
            emailTemplatePreview={emailTemplatePreview}
            isEmailViewDetails={isEmailViewDetails}
          />
        )}
      </Modal.Body>
      {/* <Modal.Footer>
            <Button>Close</Button>
          </Modal.Footer> */}
    </Modal>
  );
};

export default memo(TemplateListModal);
