import React from "react";

// medwiki layout

import Default from "../../../../layouts/dashboard/default";
import UserList from "../pages/user-list";
import UserProfile from "../pages/user-profile";
import AddUser from "../pages/user-add";
import UserDashboard from "../pages/user-dashboard";

// pages

export const UserRouter = [
  {
    path: "/user",
    element: <Default />,
    children: [
      {
        path: "list",
        element: <UserList />,
      },
      {
        path: "profile",
        element: <UserProfile />,
      },
      {
        path: "user-add",
        element: <AddUser />,
      },
      {
        path: "dashboard/:type",
        element: <UserDashboard />,
      },
    ],
  },
];
