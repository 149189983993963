import React from "react";

const FormSteps = ({ steps, onStepClick }) => {
  return (
    <ul className="text-center iq-product-tracker mb-0 py-4" id="progressbar">
      {steps.map((step, index) => {
        return (
          <li
            key={index}
            className={`${
              step.active ? "active" : ""
            } iq-tracker-position-0 cursor-pointer`}
            id={"iq-tracker-position-" + (index + 1)}
            onClick={() => onStepClick(index + 1)}
          >
            {step.name}
          </li>
        );
      })}
    </ul>
  );
};

export default FormSteps;
