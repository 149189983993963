import React from "react";
import { Image } from "react-bootstrap";
export default function UserPersonaCard({ image, name, id, click, active }) {
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center"
      onClick={() => click(id)}
    >
      {image && (
        <div className="avatar-80 rounded-circle overflow-hidden border border-secondary border-0">
          <Image
            src={image}
            alt="02"
            className={`img-fluid ${
              active ? "bg-soft-primary" : "satuarate-0 opacity-75"
            }`}
            loading="lazy"
          />
        </div>
      )}
      {name && <div className="fs-7 mt-2">{name}</div>}
    </div>
  );
}
