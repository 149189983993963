import React, { useRef, memo, useEffect } from "react";
import { Col, Modal } from "react-bootstrap";
import whatsappMobile from "../../../../assets/images/mobileWhatsapp.png";
import smsMobile from "../../../../assets/images/mobile-bg.png";
import ReactEmailEditor from "../../../../lib/email-editor/react-email-editor";
import sample from "../../../../lib/email-editor/sample.json";
import { getService } from "../../../../api/services/get-services";
import { EMAIL_TEMPLATE_DETAILS_GET } from "../../../../api/endpoints/template-management-endpoints";
import toast from "react-hot-toast";

const TemplatePreviewModal = ({
  show,
  modalClose,
  type,
  selectedTemplateData = {},
}) => {
  const emailEditorRef = useRef(null);

  // useEffect(() => {
  //   if (type === "Email" && selectedTemplateData.id != "") {
  //     fetchEmailTemplateDetails(selectedTemplateData?.id);
  //     alert("hi");
  //   }
  // }, [selectedTemplateData, type]);
  const onLoad = () => {
    console.log("selectedTemplateData in onLoad", type, selectedTemplateData);
    if (selectedTemplateData?.content) {
      emailEditorRef.current?.editor?.loadDesign(selectedTemplateData?.content);
    }
  };

  const onReady = () => {
    emailEditorRef.current?.editor?.showPreview("desktop");
  };
  // const fetchEmailTemplateDetails = async (id) => {
  //   try {
  //     // setLoading(true);
  //     const response = await getService(EMAIL_TEMPLATE_DETAILS_GET + `/${id}`);
  //     console.log("EMAIL_TEMPLATE_DETAILS_GET", response.data);
  //     if (response.data.success == true) {
  //       // setTemplateDetails(response.data.data);
  //       // setLoading(false);
  //       let htmlContent = response.data.data?.email_content;
  //       let json_content = response.data.data?.email_json;
  //       console.log("json_content preview", json_content);
  //       // if (emailEditorRef.current) {
  //       //     emailEditorRef.current.editor.loadDesign(template);
  //       // }
  //       onLoad(json_content);
  //     }
  //     if (response.isError) {
  //       toast.error(response?.error);
  //     }
  //   } catch (error) {
  //     // setLoading(false);
  //     // console.log("TemplateField", error);
  //   }
  // };
  console.log("selectedTemplateData in preview", selectedTemplateData);
  return (
    <>
      <Modal
        show={show}
        // size="lg"
        size={`${type === "Email" ? "" : "lg"}`}
        fullscreen={type === "Email" ? true : false}
        onHide={modalClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="mb-0">{selectedTemplateData?.name ?? ""}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex">
          {(type === "WhatsApp" || type === "Message") && (
            <Col lg={3} xs={12} className="mx-auto">
              <div className="bg-soft-info h-100 p-2 rounded-5 border border-2 border-dashed border-info d-flex flex-column">
                {/* <h5>Preview</h5> */}
                <div className="position-relative flex-fill">
                  <img
                    src={type === "WhatsApp" ? whatsappMobile : smsMobile}
                    alt="mobile"
                    className="h-auto w-100 d-block position-relative z-0"
                  />
                  <div className="position-absolute top-0 w-100 h-auto p-2 z-1">
                    {/* {((previewData && previewData.length > 0) ||
                      templateView) && ( */}
                    <div className="h-100 rounded-5 p-3 mobile-preview position-relative mt-4">
                      <span className="d-block position-relative px-3 py-2 rounded-3 mt-4 text-secondary fs-7 lh-base shadow-lg shadow bg-white">
                        {/* {selectedSms.content} */}
                        {selectedTemplateData?.content ?? ""}
                      </span>
                    </div>
                    {/* )} */}
                  </div>
                </div>
              </div>
            </Col>
          )}
          {type === "Email" && (
            <div className="flex-grow-1 reactEmailEditorPrrnt">
              <ReactEmailEditor
                ref={emailEditorRef}
                onReady={onReady}
                onLoad={onLoad}
              />
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default memo(TemplatePreviewModal);
