import React, {
  Fragment,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import {
  Button,
  Card,
  Col,
  Row,
  Badge,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";

import { Link } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import ReactTable from "../../../../lib/table/react-table";
import dayjs from "dayjs";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import { findIcon } from "../../../dashboard/icons";
import { SEGMENT_LIST_POST } from "../../../../api/endpoints/campaign-segment-management-endpoints";
import postService from "../../../../api/services/post-service";
import ActionButtonBar from "../../../../components/partials/common/action-button-bar";
import CreateSegmentModal from "../components/create-segment-modal";
import {
  changeTableStateToPayload,
  getValuesByKey,
  statusToBg,
} from "../../../../common/utils/utils";
import { all } from "axios";
import Filter from "../../content-studio/component/filter";
import FilterBar from "../../../../components/filter-bar";
import CommonTableLoader from "../../../../skeleton/common-table-loader";
import PageNotFound from "../../../PageNotFound";

const advanceData = [
  { value: "Segment ID", label: "Segment ID", type: "number" },
  {
    value: "Created by",
    label: "Created by",
    elements: [
      { value: "Creator 1", label: "Creator 1" },
      { value: "Creator 2", label: "Creator 2" },
      { value: "Creator 3", label: "Creator 3" },
    ],
    type: "string",
  },
  {
    value: "Modified by",
    label: "Modified by",
    elements: [
      { value: "Modifier 1", label: "Modifier 1" },
      { value: "Modifier 2", label: "Modifier 2" },
      { value: "Modifier 3", label: "Modifier 3" },
    ],
    type: "string",
  },
  // { value: "User Age", label: "User Age" },
];
const RecommendedSegments = () => {
  const [createModalShow, setCreateModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [segmentList, setSegmentList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedSegment, setSelectedSegment] = useState(null);
  const modalClose = () => setCreateModalShow(false);
  const post_json = useRef({
    default: "all",
    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      segment_id: "desc",
    },
  });
  const { redirectTo } = useRedirect();
  const view = (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="icon-32"
      width="32"
      height="32"
      viewBox="0 0 24 24"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.1614 12.0531C15.1614 13.7991 13.7454 15.2141 11.9994 15.2141C10.2534 15.2141 8.83838 13.7991 8.83838 12.0531C8.83838 10.3061 10.2534 8.89111 11.9994 8.89111C13.7454 8.89111 15.1614 10.3061 15.1614 12.0531Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.998 19.355C15.806 19.355 19.289 16.617 21.25 12.053C19.289 7.48898 15.806 4.75098 11.998 4.75098H12.002C8.194 4.75098 4.711 7.48898 2.75 12.053C4.711 16.617 8.194 19.355 12.002 19.355H11.998Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
    </svg>
  );
  const edit = (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="icon-32"
      width="32"
      height="32"
      viewBox="0 0 24 24"
    >
      <path
        d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <path
        d="M15.1655 4.60254L19.7315 9.16854"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
    </svg>
  );
  // table data with createdOn as date in UTC
  const onSegementDeleteModal = () => {
    setDeleteModalShow(true);
  };
  //   Doctors in Mumbai
  // Pharmacy Owners in Delhi
  // Medical Representatives in Bangalore
  // Hospital Administrators in Chennai
  // Medical Students in Kolkata
  const segment_demo_data = [
    {
      segment_name: "Doctors in Mumbai",
      total_reach: "380",
      Preffer_email: "105",
      Preffer_whatsapp: "112",
      Preffer_SMS: "132",
      created_by: { name: "Indranil Kundu" },
      created_on: { date: "01-06-2023", time: "21:10" },
      modified_on: { date: "01-06-2023", time: "21:40" },
      status: { id: 1, value: "Draft" },
    },
    {
      segment_name: "Dermatology Doctors in Delhi",
      total_reach: "470",
      Preffer_email: "155",
      Preffer_whatsapp: "119",
      Preffer_SMS: "156",
      created_by: { name: "Sumit Mandol" },
      created_on: { date: "01-06-2023", time: "22:20" },
      modified_on: { date: "01-06-2023", time: "22:40" },
      status: { id: 1, value: "Draft" },
    },
    {
      segment_name: "Doctors in Bangalore",
      total_reach: "550",
      Preffer_email: "255",
      Preffer_whatsapp: "110",
      Preffer_SMS: "106",
      created_by: { name: "Swagata Das" },
      created_on: { date: "02-06-2023", time: "12:20" },
      modified_on: { date: "02-06-2023", time: "12:40" },
      status: { id: 1, value: "Draft" },
    },
    {
      segment_name: "ENT Doctors in Chennai",
      total_reach: "350",
      Preffer_email: "105",
      Preffer_whatsapp: "170",
      Preffer_SMS: "136",
      created_by: { name: "Saif Imroz" },
      created_on: { date: "02-06-2023", time: "13:00" },
      modified_on: { date: "02-06-2023", time: "13:40" },
      status: { id: 1, value: "Draft" },
    },
    {
      segment_name: "Doctors in Ranchi",
      total_reach: "550",
      Preffer_email: "135",
      Preffer_whatsapp: "241",
      Preffer_SMS: "131",
      created_by: { name: "Sumit Mandol" },
      created_on: { date: "03-06-2023", time: "16:20" },
      modified_on: { date: "03-06-2023", time: "16:40" },
      status: { id: 1, value: "Draft" },
    },
    {
      segment_name: "Cardiology Doctors in Mumbai",
      total_reach: "380",
      Preffer_email: "105",
      Preffer_whatsapp: "112",
      Preffer_SMS: "132",
      created_by: { name: "Indranil Kundu" },
      created_on: { date: "03-06-2023", time: "17:05" },
      modified_on: { date: "03-06-2023", time: "17:40" },
      status: { id: 1, value: "Draft" },
    },
    {
      segment_name: "Doctors in Delhi",
      total_reach: "470",
      Preffer_email: "155",
      Preffer_whatsapp: "119",
      Preffer_SMS: "156",
      created_by: { name: "Saif Imroz" },
      created_on: { date: "03-06-2023", time: "18:20" },
      modified_on: { date: "03-06-2023", time: "18:40" },
      status: { id: 1, value: "Draft" },
    },
    {
      segment_name: "ENT Doctors in Bangalore",
      total_reach: "550",
      Preffer_email: "255",
      Preffer_whatsapp: "110",
      Preffer_SMS: "106",
      created_by: { name: "Sumit Mandol" },
      created_on: { date: "03-06-2023", time: "22:20" },
      modified_on: { date: "03-06-2023", time: "22:40" },
      status: { id: 1, value: "Draft" },
    },
    {
      segment_name: "Oncology Doctors in Chennai",
      total_reach: "350",
      Preffer_email: "105",
      Preffer_whatsapp: "170",
      Preffer_SMS: "136",
      created_by: { name: "Sumit Mandol" },
      created_on: { date: "05-06-2023", time: "09:20" },
      modified_on: { date: "05-06-2023", time: "09:40" },
      status: { id: 1, value: "Draft" },
    },
    {
      segment_name: "Nephrology Doctors in Kolkata",
      total_reach: "550",
      Preffer_email: "135",
      Preffer_whatsapp: "241",
      Preffer_SMS: "131",
      created_by: { name: "Sumit Mandol" },
      created_on: { date: "05-06-2023", time: "10:20" },
      modified_on: { date: "05-06-2023", time: "10:40" },
      status: { id: 1, value: "Draft" },
    },
  ];
  const columns = [
    { Header: "Segment name", accessor: "segment_name", isVisible: true },
    // {
    //   Header: "Type",
    //   accessor: "segment_type",
    //   Cell: ({ row }) => (
    //     <>
    //       <Badge
    //         bg={
    //           "success"
    //           // row.values.type == "Custom"
    //           //   ? "primary"
    //           //   : row.values.type == "Topic"
    //           //   ? "warning"
    //           //   : row.values.type == "Speciality"
    //           //   ? "success"
    //           //   : ""
    //         }
    //         className="me-2"
    //       >
    //         {/* <span className="btn-inner">{row.values.type}</span> */}
    //       </Badge>
    //     </>
    //   ),
    // },
    // {
    //   Header: "Persona",
    //   accessor: "segment_to_persona",
    //   Cell: ({ value }) => {
    //     const allPersona = getValuesByKey(value, "persona");
    //     console.log("persona value", value, "\n", allPersona);
    //     return allPersona && allPersona.length > 0 ? (
    //       <>
    //         <div className="iq-media-group iq-media-group-1">
    //           {/* <div className="iq-media-1"> */}
    //           {allPersona.map((persona) => (
    //             <>
    //               {persona.persona_name ? (
    //                 <>
    //                   <div className="iq-media-1">
    //                     <div className="icon iq-icon-box-3 rounded-pill">
    //                       {/* show two character of the persona_name */}
    //                       {persona.persona_name.length > 2
    //                         ? persona.persona_name.substring(0, 2)
    //                         : persona.persona_name}
    //                     </div>
    //                   </div>
    //                 </>
    //               ) : (
    //                 "N/A"
    //               )}
    //             </>
    //           ))}
    //           {/* </div> */}
    //         </div>
    //       </>
    //     ) : null;
    //     // return allPersona && allPersona.length > 0
    //     //   ? allPersona.map((persona) =>
    //     // persona.persona_name ? (
    //     //   <>
    //     //     <div className="icon iq-icon-box-3 rounded-pill">
    //     //       {/* show two character of the persona_name */}
    //     //       {persona.persona_name.length > 2
    //     //         ? persona.persona_name.substring(0, 2)
    //     //         : persona.persona_name}
    //     //     </div>
    //     //   </>
    //     // ) : (
    //     //   "N/A"
    //     // )
    //     //     )
    //     //   : "N/A";
    //   },
    // },
    {
      Header: "Reach",
      accessor: "total_reach",
    },

    {
      Header: "Email",
      accessor: "Preffer_email",
    },
    {
      Header: "WhatsApp",
      accessor: "Preffer_whatsapp",
    },
    {
      Header: "SMS",
      accessor: "Preffer_SMS",
    },
    // {
    //   Header: "Created by",
    //   accessor: "created_by",
    //   Cell: ({ value }) => {
    //     console.log("row");
    //     return value.name;
    //   },
    // },
    // {
    //   Header: "Created on",
    //   accessor: "created_on",
    //   Cell: ({ value }) => (
    //     <p className="m-0">
    //       {value.date} {value.time}
    //     </p>
    //   ),
    // },
    // {
    //   Header: "Modified on",
    //   accessor: "modified_on",
    //   Cell: ({ value }) => (
    //     <p className="m-0">
    //       {value.date} {value.time}
    //     </p>
    //   ),
    // },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => (
        <Badge bg={statusToBg(value.id)} className="me-2">
          <span className="btn-inner">{value.value}</span>
        </Badge>
      ),
    },
    {
      Header: "Actions",
      accessor: "actions",
      disableFilters: true,
      Cell: ({ row }) => (
        <ActionButtonBar
          buttons={["view", "delete"]}
          row={row}
          onView={(data) => {
            console.log("row", row);
            redirectTo("segment/details/" + row.original.segment_id);
          }}
          onEdit={() => {
            redirectTo("segment/create/" + row.original.segment_id);
          }}
          onDelete={() => {
            setSelectedSegment(row.original.segment_id);
            onSegementDeleteModal();
          }}
        />
      ),
    },
  ];

  const fetchSegmentList = async () => {
    try {
      const response = await postService(SEGMENT_LIST_POST, post_json.current);

      console.log("COLUMNS", Object.keys(response.data.data.rows[0]));
      if (response.data.data.rows.length > 0) {
        setSegmentList(response.data.data.rows);
        setTotalCount(response.data.data.totalCount);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("fetch segment error", error);
    }
  };
  useEffect(() => {
    fetchSegmentList();
  }, []);

  const fetchAllSegmentList = useCallback(
    async (state) => {
      post_json.current = changeTableStateToPayload(state);
      fetchSegmentList();
    },
    [segmentList, loading, post_json.current]
  );

  const onDeleteModalClose = useCallback(() => {
    setSelectedSegment(null);
    setDeleteModalShow(false);
  }, [deleteModalShow]);

  // const deleteSegment = async () => {
  //   try {
  //     setLoading(true);
  //     const response = await getService(
  //       `${EMAIL_TEMPLATE_DELETE}/${selectedSegment}`,
  //       {}
  //     );
  //     console.log(response);
  //     if (response) {
  //       setLoading(false);
  //       setCreateModalShow(false);
  //       if (response.data.success) {
  //         toast.success(response.data.message);
  //       }
  //       setTimeout(() => {
  //         fetchTemplate();
  //       }, 500);
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     // console.log("USER_SERVICE_ERROR", error);
  //   }
  // };
  const inputFilters = [
    {
      name: "segment_name",

      type: "text",
      placeholder: "Please Enter Segment Name",
      label: "Segment Name",
    },
    {
      name: "reach_from",

      type: "text",
      placeholder: "Enter Reach From",
      label: "Reach From",
    },
    {
      name: "reach_to",

      type: "text",
      placeholder: "Enter Reach To",
      label: "Reach To",
    },
    {
      name: "channel",

      type: "select",
      placeholder: "Channel",
      label: "Channels",
      options: [
        { value: "1", label: "Email" },
        { value: "2", label: "WhatsApp" },
        { value: "3", label: "Sms" },
      ],
    },
    {
      name: "start_date",

      type: "date",
      placeholder: "Enter Start Date",
      label: "Start Date",
    },
    {
      name: "end_date",

      type: "date",
      placeholder: "Enter End Date",
      label: "End Date",
    },
    {
      name: "status",

      type: "select",
      placeholder: "Status",
      label: "Status",
      options: [
        { value: "1", label: "In Progress" },
        { value: "2", label: "Pending" },
        { value: "3", label: "Completed" },
      ],
    },
    // {
    //   name: "status",
    //   type: "radio",
    //   placeholder: "Status",
    //   label: "Status",
    //   options: [
    //     { value: "1", label: "Active" },
    //     { value: "2", label: "Inactive" },
    //   ],
    // },
  ];

  console.log("createModalShow", segmentList, totalCount);
  return (
    <Fragment>
      <Button
        variant="success"
        className="d-flex align-items-center justify-content-center add_floating__button position-fixed rounded-circle p-1 avatar-80 lh-1 border border-white border-2"
        onClick={() => setCreateModalShow(true)}
      >
        {findIcon("Plus", "outline", "32")}
      </Button>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <div className="d-flex flex-column">
          <h3 className="m-0">Recommended Segments</h3>
        </div>
        {/* <Filter type="Segment" /> */}
        <FilterBar advanceData={advanceData} />
        <div className="d-flex justify-content-between align-items-center rounded flex-wrap gap-3">
          {/* <Button
              className="btn btn-primary flex-grow-1 flex-sm-grow-0"
              type="submit"
              onClick={() => redirectTo("segment/create")}
            >
              Create Segment
            </Button> */}
          {/* <div className="form-group mb-0 ">
              <select className="form-select">
                <option defaultValue>Sort By</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
            {/* <div className="form-group mb-0 ">
              <Flatpickr
                options={{ mode: "range", minDate: "today" }}
                className="form-control range_flatpicker"
                placeholder="24 Jan 2022 to 23 Feb 2022"
              />
            </div> */}
        </div>
      </div>
      <Row>
        <Col lg="12">
          <Card>
            <Card.Body>
              {/* <div className="fancy-table table-responsive rounded"> */}
              {!loading ? (
                segmentList && segmentList?.length > 0 ? (
                  <ReactTable
                    // data={segmentList}
                    data={segment_demo_data}
                    columns={columns}
                    // recordsTotal={totalCount}
                    recordsTotal={10}
                    onTableStateChange={fetchAllSegmentList}
                    initialState={post_json.current}
                    recordsPerPage={10}
                  />
                ) : (
                  <PageNotFound />
                )
              ) : (
                <CommonTableLoader />
              )}
              {/* </div> */}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* <DeleteModal
          show={deleteModalShow}
          modalClose={onDeleteModalClose}
          onSubmit={deleteSegment}
        /> */}
      <CreateSegmentModal show={createModalShow} modalClose={modalClose} />
    </Fragment>
  );
};

export default RecommendedSegments;
