import React from "react";

// layout

import Default from "../../../../../layouts/dashboard/default";
import AllCity from "../pages/all-city";
// pages

export const CityRouter = [
  {
    path: "/master-management/city",
    element: <Default />,
    children: [
      {
        path: "all",
        element: <AllCity />,
      },
    ],
  },
];
