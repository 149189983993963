import { memo, Fragment } from "react";

// React-bootstrap
import { Nav, OverlayTrigger, Tooltip } from "react-bootstrap";

// Router
import { Link, useLocation } from "react-router-dom";

// components
import SidebarMenu from "../../../../../components/partials/components/sidebar/sidebar-menu";

const Filemanagervarticalnav = memo(() => {
  let location = useLocation();

  return (
    <Fragment>
      <ul className='navbar-nav iq-main-menu' id='sidebar-menu'>

        <Nav.Item as='li' className='static-item'>
          <Nav.Link className='static-item disabled' href='#' tabIndex='-1'>
            <span className='default-icon'>Pages</span>
            <span className='mini-icon'>-</span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as='li'>
          <Link
            className={`${
              location.pathname === "/creative-library/listing" ? "active" : ""
            } nav-link`}
            to='/creative-library/listing'>
            <i className='icon'>
              <svg
                className='icon-20'
                width='20'
                viewBox='0 0 24 24'
                fill='currentColor'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  opacity='0.4'
                  d='M16.8843 5.11485H13.9413C13.2081 5.11969 12.512 4.79355 12.0474 4.22751L11.0782 2.88762C10.6214 2.31661 9.9253 1.98894 9.19321 2.00028H7.11261C3.37819 2.00028 2.00001 4.19201 2.00001 7.91884V11.9474C1.99536 12.3904 21.9956 12.3898 21.9969 11.9474V10.7761C22.0147 7.04924 20.6721 5.11485 16.8843 5.11485Z'
                  fill='currentColor'
                />
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M20.8321 6.54346C21.1521 6.91754 21.3993 7.34785 21.5612 7.81235C21.8798 8.76704 22.0273 9.77029 21.9969 10.7761V16.0291C21.9956 16.4716 21.963 16.9134 21.8991 17.3512C21.7775 18.124 21.5057 18.8655 21.0989 19.5341C20.9119 19.8571 20.6849 20.1552 20.4231 20.4214C19.2383 21.5089 17.665 22.0749 16.0574 21.992H7.93061C6.32049 22.0743 4.74462 21.5085 3.55601 20.4214C3.2974 20.1547 3.07337 19.8566 2.88915 19.5341C2.48475 18.866 2.21869 18.1237 2.1067 17.3512C2.03549 16.9141 1.99981 16.472 2 16.0291V10.7761C1.99983 10.3373 2.02357 9.89895 2.07113 9.4628C2.08113 9.38628 2.09614 9.31101 2.11098 9.23652C2.13573 9.11233 2.16005 8.99031 2.16005 8.86829C2.25031 8.34196 2.41496 7.83108 2.64908 7.35094C3.34261 5.86908 4.76525 5.11484 7.09481 5.11484H16.8754C18.1802 5.01393 19.4753 5.40673 20.5032 6.21514C20.6215 6.31552 20.7316 6.42532 20.8321 6.54346ZM6.97033 15.5411H17.0355H17.0533C17.2741 15.5507 17.4896 15.4716 17.6517 15.3216C17.8137 15.1715 17.9088 14.963 17.9157 14.7425C17.9282 14.5487 17.8644 14.3576 17.7379 14.2101C17.5924 14.0118 17.3618 13.8934 17.1155 13.8906H6.97033C6.51365 13.8906 6.14343 14.2601 6.14343 14.7159C6.14343 15.1716 6.51365 15.5411 6.97033 15.5411Z'
                  fill='currentColor'
                />
              </svg>
            </i>
            <OverlayTrigger
              placement='right'
              overlay={<Tooltip>All Creatives</Tooltip>}>
              <i className='sidenav-mini-icon'> AF </i>
            </OverlayTrigger>
            <span className='item-name'>All Creatives</span>
            <div>
              <span className='badge bg-warning d-inline-block rounded-pill'>
                21
              </span>
            </div>
          </Link>
        </Nav.Item>
        <SidebarMenu
          isTag='false'
          minititle='A'
          pathname='/creative-library/approve'
          title='Approve'>
          <i className='icon'>
            <svg
              className='icon-20'
              width='20'
              viewBox='0 0 24 24'
              fill='currentColor'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                opacity='0.4'
                d='M19.6433 9.48844C19.6433 9.55644 19.1103 16.2972 18.8059 19.1341C18.6153 20.875 17.493 21.931 15.8095 21.961C14.516 21.99 13.2497 22 12.0039 22C10.6812 22 9.38772 21.99 8.13215 21.961C6.50507 21.922 5.38177 20.845 5.20088 19.1341C4.88772 16.2872 4.36448 9.55644 4.35476 9.48844C4.34503 9.28345 4.41117 9.08846 4.54538 8.93046C4.67765 8.78447 4.86827 8.69647 5.06861 8.69647H18.9392C19.1385 8.69647 19.3194 8.78447 19.4624 8.93046C19.5956 9.08846 19.6627 9.28345 19.6433 9.48844Z'
                fill='currentColor'
              />
              <path
                d='M21 5.97686C21 5.56588 20.6761 5.24389 20.2871 5.24389H17.3714C16.7781 5.24389 16.2627 4.8219 16.1304 4.22692L15.967 3.49795C15.7385 2.61698 14.9498 2 14.0647 2H9.93624C9.0415 2 8.26054 2.61698 8.02323 3.54595L7.87054 4.22792C7.7373 4.8219 7.22185 5.24389 6.62957 5.24389H3.71385C3.32386 5.24389 3 5.56588 3 5.97686V6.35685C3 6.75783 3.32386 7.08982 3.71385 7.08982H20.2871C20.6761 7.08982 21 6.75783 21 6.35685V5.97686Z'
                fill='currentColor'
              />
            </svg>
          </i>
        </SidebarMenu>
        <li>
          <hr className='hr-horizontal' />
        </li>
        <li className='nav-item static-item'>
          <Link className='nav-link static-item disabled' to='#' tabIndex='-1'>
            <span className='default-icon'>Other</span>
            <span className='mini-icon'>-</span>
          </Link>
        </li>
        <SidebarMenu isTag='true' pathname='/auth/sign-in' title='Sign Out'>
          <i className='icon'>
            <svg
              width='20'
              height='20'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                opacity='0.4'
                d='M2 6.447C2 3.996 4.03024 2 6.52453 2H11.4856C13.9748 2 16 3.99 16 6.437V17.553C16 20.005 13.9698 22 11.4744 22H6.51537C4.02515 22 2 20.01 2 17.563V16.623V6.447Z'
                fill='currentColor'></path>
              <path
                d='M21.7787 11.4548L18.9329 8.5458C18.6388 8.2458 18.1655 8.2458 17.8723 8.5478C17.5802 8.8498 17.5811 9.3368 17.8743 9.6368L19.4335 11.2298H17.9386H9.54826C9.13434 11.2298 8.79834 11.5748 8.79834 11.9998C8.79834 12.4258 9.13434 12.7698 9.54826 12.7698H19.4335L17.8743 14.3628C17.5811 14.6628 17.5802 15.1498 17.8723 15.4518C18.0194 15.6028 18.2113 15.6788 18.4041 15.6788C18.595 15.6788 18.7868 15.6028 18.9329 15.4538L21.7787 12.5458C21.9199 12.4008 21.9998 12.2048 21.9998 11.9998C21.9998 11.7958 21.9199 11.5998 21.7787 11.4548Z'
                fill='currentColor'></path>
            </svg>
          </i>
        </SidebarMenu>
        <SidebarMenu isTag='true' pathname='' title='Help'>
          <i className='icon'>
            <svg
              width='20'
              height='20'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                opacity='0.4'
                d='M22 11.9998C22 17.5238 17.523 21.9998 12 21.9998C6.477 21.9998 2 17.5238 2 11.9998C2 6.47776 6.477 1.99976 12 1.99976C17.523 1.99976 22 6.47776 22 11.9998Z'
                fill='currentColor'></path>
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12.8701 12.6307C12.8701 13.1127 12.4771 13.5057 11.9951 13.5057C11.5131 13.5057 11.1201 13.1127 11.1201 12.6307V8.21069C11.1201 7.72869 11.5131 7.33569 11.9951 7.33569C12.4771 7.33569 12.8701 7.72869 12.8701 8.21069V12.6307ZM11.1251 15.8035C11.1251 15.3215 11.5161 14.9285 11.9951 14.9285C12.4881 14.9285 12.8801 15.3215 12.8801 15.8035C12.8801 16.2855 12.4881 16.6785 12.0051 16.6785C11.5201 16.6785 11.1251 16.2855 11.1251 15.8035Z'
                fill='currentColor'></path>
            </svg>
          </i>
        </SidebarMenu>
      </ul>
    </Fragment>
  );
});

Filemanagervarticalnav.displayName = "Filemanagervarticalnav";
export default Filemanagervarticalnav;
