import * as yup from "yup";

const addArticleInitialValues = {
	speciality: "",
	category: "",
	tags: [],
	body: "",
	body_html: "",
	notes: "",
	reference: "",
	reference_html: "",
	referenceFile: null,
	title: "",
	title_html: "",
	coverPhoto: null,
	thumbnail: null,
	brand: "",
};

const articleSchema = yup.object().shape({
	speciality: yup.string().trim().required("Please select speciality"),
	title: yup.string().trim().required("Please Enter a title"),
	body: yup.string().trim().required("Please Enter a body"),
	reference: yup.string().trim().required("Please Enter a references"),
	coverPhoto: yup.mixed().required("Please add a cover photo"),
	thumbnail: yup.mixed().required("Please add a thumbnail"),
	brand: yup.string().trim().required("Please select the brand"),
});

export { addArticleInitialValues, articleSchema };
