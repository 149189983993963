import Skeleton from "../../components/skeleton";
import { Fragment } from "react";

//Componets

import { OverlayTrigger, Card, Tooltip } from "react-bootstrap";

const ContentLibraryTableLoader = () => {
  return (
    <Fragment>
      {/* <Card className="flex-grow-1">
        <Card.Header className="d-flex justify-content-between">
          <Card.Title>
            <h5 className="mb-0">
              <Skeleton variant="text" width={120} height={20} />
            </h5>
          </Card.Title>

          <Skeleton variant="text" width={100} height={20} />
        </Card.Header>
        <Card.Body className="flex-grow-1">
          <div className="table-responsive">
            <table className="table table-bordered mb-0">
              <thead>
                <tr className="border-bottom bg-transparent text-dark">
                  <th scope="col"><Skeleton variant="text" width={100} height={25} /></th>
                  <th scope="col"><Skeleton variant="text" width={100} height={25} /></th>
                  <th scope="col"><Skeleton variant="text" width={100} height={25} /></th>
                  <th scope="col"><Skeleton variant="text" width={100} height={25} /></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="d-flex align-items-center gap-3">
                      <span className="avatar-40 bg-soft-primary rounded-circle d-flex align-items-center justify-content-center">
                        <Skeleton variant="circular" width={20} height={20} />
                      </span>
                      <h6 className="mb-0">
                        <Skeleton variant="text" width={100} height={20} />
                      </h6>
                    </div>
                  </td>
                  <td>
                    <small className="text-muted">
                      <Skeleton variant="text" width={100} height={20} />
                    </small>
                  </td>
                  <td>
                    <small className="text-primary">
                      <Skeleton variant="text" width={100} height={20} />
                    </small>
                  </td>
                  <td>
                    <div className="d-flex align-items-center text-danger">
                      <span className="btn-inner">
                        <Skeleton variant="circular" width={20} height={20} />
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="d-flex align-items-center gap-3">
                      <span className="avatar-40 bg-soft-primary rounded-circle d-flex align-items-center justify-content-center">
                        <Skeleton variant="circular" width={20} height={20} />
                      </span>
                      <h6 className="mb-0">
                        <Skeleton variant="text" width={100} height={20} />
                      </h6>
                    </div>
                  </td>
                  <td>
                    <small className="text-muted">
                      <Skeleton variant="text" width={100} height={20} />
                    </small>
                  </td>
                  <td>
                    <small className="text-primary">
                      <Skeleton variant="text" width={100} height={20} />
                    </small>
                  </td>
                  <td>
                    <div className="d-flex align-items-center text-danger">
                      <span className="btn-inner">
                        <Skeleton variant="circular" width={20} height={20} />
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="d-flex align-items-center gap-3">
                      <span className="avatar-40 bg-soft-primary rounded-circle d-flex align-items-center justify-content-center">
                        <Skeleton variant="circular" width={20} height={20} />
                      </span>
                      <h6 className="mb-0">
                        <Skeleton variant="text" width={100} height={20} />
                      </h6>
                    </div>
                  </td>
                  <td>
                    <small className="text-muted">
                      <Skeleton variant="text" width={100} height={20} />
                    </small>
                  </td>
                  <td>
                    <small className="text-primary">
                      <Skeleton variant="text" width={100} height={20} />
                    </small>
                  </td>
                  <td>
                    <div className="d-flex align-items-center text-danger">
                      <span className="btn-inner">
                        <Skeleton variant="circular" width={20} height={20} />
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="d-flex align-items-center gap-3">
                      <span className="avatar-40 bg-soft-primary rounded-circle d-flex align-items-center justify-content-center">
                        <Skeleton variant="circular" width={20} height={20} />
                      </span>
                      <h6 className="mb-0">
                        <Skeleton variant="text" width={100} height={20} />
                      </h6>
                    </div>
                  </td>
                  <td>
                    <small className="text-muted">
                      <Skeleton variant="text" width={100} height={20} />
                    </small>
                  </td>
                  <td>
                    <small className="text-primary">
                      <Skeleton variant="text" width={100} height={20} />
                    </small>
                  </td>
                  <td>
                    <div className="d-flex align-items-center text-danger">
                      <span className="btn-inner">
                        <Skeleton variant="circular" width={20} height={20} />
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="d-flex align-items-center gap-3">
                      <span className="avatar-40 bg-soft-primary rounded-circle d-flex align-items-center justify-content-center">
                        <Skeleton variant="circular" width={20} height={20} />
                      </span>
                      <h6 className="mb-0">
                        <Skeleton variant="text" width={100} height={20} />
                      </h6>
                    </div>
                  </td>
                  <td>
                    <small className="text-muted">
                      <Skeleton variant="text" width={100} height={20} />
                    </small>
                  </td>
                  <td>
                    <small className="text-primary">
                      <Skeleton variant="text" width={100} height={20} />
                    </small>
                  </td>
                  <td>
                    <div className="d-flex align-items-center text-danger">
                      <span className="btn-inner">
                        <Skeleton variant="circular" width={20} height={20} />
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Card.Body>
      </Card> */}
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between">
          <h4>
            <Skeleton variant="text" width={100} height={20} />
          </h4>
          {/* <Link to="/content-library/listing" className="text-primary">
            View all
          </Link> */}
          <Skeleton variant="text" width={90} height={20} />
        </Card.Header>
        <div className="table-responsive">
          <table className="table table-borderless iq-file-manager-table mb-0">
            <thead>
              <tr className="border-bottom bg-transparent text-dark">
                <th scope="col">
                  <Skeleton variant="text" width={100} height={25} />
                </th>
                <th scope="col">
                  <Skeleton variant="text" width={100} height={25} />
                </th>
                <th scope="col">
                  <Skeleton variant="text" width={100} height={25} />
                </th>
                <th scope="col">
                  {/* <Skeleton variant="text" width={100} height={25} /> */}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="d-flex align-items-center gap-2">
                    <Skeleton variant="circular" width={25} height={25} />
                    <h6 className="mb-0">
                      <Skeleton variant="text" width={100} height={20} />
                    </h6>
                  </div>
                </td>
                <td>
                  <small className="text-muted">
                    <Skeleton variant="text" width={100} height={20} />
                  </small>
                </td>
                <td>
                  <small className="text-primary">
                    <Skeleton variant="text" width={100} height={20} />
                  </small>
                </td>
                <td>
                  <div className="d-flex align-items-center text-danger rounded-circle">
                    <span className="btn-inner">
                      <Skeleton variant="circular" width={20} height={20} />
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="d-flex align-items-center gap-2">
                    <Skeleton variant="circular" width={25} height={25} />
                    <h6 className="mb-0">
                      <Skeleton variant="text" width={100} height={20} />
                    </h6>
                  </div>
                </td>
                <td>
                  <small className="text-muted">
                    <Skeleton variant="text" width={100} height={20} />
                  </small>
                </td>
                <td>
                  <small className="text-primary">
                    <Skeleton variant="text" width={100} height={20} />
                  </small>
                </td>
                <td>
                  <div className="d-flex align-items-center text-danger rounded-circle">
                    <span className="btn-inner">
                      <Skeleton variant="circular" width={20} height={20} />
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="d-flex align-items-center gap-2">
                    <Skeleton variant="circular" width={25} height={25} />
                    <h6 className="mb-0">
                      <Skeleton variant="text" width={100} height={20} />
                    </h6>
                  </div>
                </td>
                <td>
                  <small className="text-muted">
                    <Skeleton variant="text" width={100} height={20} />
                  </small>
                </td>
                <td>
                  <small className="text-primary">
                    <Skeleton variant="text" width={100} height={20} />
                  </small>
                </td>
                <td>
                  <div className="d-flex align-items-center text-danger rounded-circle">
                    <span className="btn-inner">
                      <Skeleton variant="circular" width={20} height={20} />
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="d-flex align-items-center gap-2">
                    <Skeleton variant="circular" width={25} height={25} />
                    <h6 className="mb-0">
                      <Skeleton variant="text" width={100} height={20} />
                    </h6>
                  </div>
                </td>
                <td>
                  <small className="text-muted">
                    <Skeleton variant="text" width={100} height={20} />
                  </small>
                </td>
                <td>
                  <small className="text-primary">
                    <Skeleton variant="text" width={100} height={20} />
                  </small>
                </td>
                <td>
                  <div className="d-flex align-items-center text-danger rounded-circle">
                    <span className="btn-inner">
                      <Skeleton variant="circular" width={20} height={20} />
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Card>
    </Fragment>
  );
};

// CampaignStatsCard.displayName = "CampaignStatsCard";
export default ContentLibraryTableLoader;
