import React from "react";
// layout
import Default from "../../../../layouts/dashboard/default";
import Notification from "../pages/notification";
import TestLogs from "../pages/test-logs";
import Testing from "../pages/testing";
import RequestLogs from "../pages/request-logs";
import Schedules from "../pages/schedules";
import LiveLogs from "../pages/live-logs";
import Dashboard from "../pages/dashboard";

// pages

export const NotificationRouter = [
  {
    path: "/notification",
    element: <Default />,
    children: [
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "testing",
        element: <Testing />,
      },
      {
        path: "list",
        element: <Notification />,
      },
      {
        path: "test-logs",
        element: <TestLogs />,
      },
      {
        path: "request-logs",
        element: <RequestLogs />,
      },
      {
        path: "schedules",
        element: <Schedules />,
      },
      {
        path: "live-logs",
        element: <LiveLogs />,
      },
    ],
  },
];
