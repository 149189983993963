import React from "react";

// layout

import Default from "../../../../../layouts/dashboard/default";
import AllPrincipleEntities from "../pages/all-principle-entities";

export const PrincipleEntityManagementRouter = [
  {
    path: "/master-management/principle-entity",
    element: <Default />,
    children: [
      {
        path: "all",
        element: <AllPrincipleEntities />,
      },
      {
        path: ":id",
        element: <AllPrincipleEntities />,
      },
    ],
  },
];
