import React from "react";

// layout

import Default from "../../../../layouts/dashboard/default";
import UserProfile from "../pages/user-profile";

// pages

export const UserUserRouter = [
  {
    path: "/profile",
    element: <Default />,
    children: [
      {
        path: "/profile",
        element: <UserProfile />,
      },
    ],
  },
];
