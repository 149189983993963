import { Fragment, memo } from "react";
import pdf from "../../assets/modules/file-manager/images/pdf.svg";
import ppt from "../../assets/modules/file-manager/images/ppt.svg";
import audio from "../../assets/modules/file-manager/images/audio_1.jpg";

const FilePicker = ({
  title = "",
  result = [],
  onUpdate,
  accepts,
  source = null,
  type = null,
  fileName = "",
}) => {
  const res = result?.find((res) => res.review == title);

  function renderElement() {
    if (type !== null) {
      if (type === "image") return <img src={source} width="100%" />;
      else if (type === "pdf")
        return (
          <div class="card-body ">
            <div class="p-3 d-flex justify-content-center align-items-center iq-document rounded bg-body">
              <img src={pdf} />
            </div>
            <div class="mt-2">
              <div class="d-flex align-items-center mb-2 text-primary gap-2">
                <svg
                  width="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M16.191 2H7.81C4.77 2 3 3.78 3 6.83V17.16C3 20.26 4.77 22 7.81 22H16.191C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2Z"
                    fill="currentColor"
                  ></path>
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.07996 6.6499V6.6599C7.64896 6.6599 7.29996 7.0099 7.29996 7.4399C7.29996 7.8699 7.64896 8.2199 8.07996 8.2199H11.069C11.5 8.2199 11.85 7.8699 11.85 7.4289C11.85 6.9999 11.5 6.6499 11.069 6.6499H8.07996ZM15.92 12.7399H8.07996C7.64896 12.7399 7.29996 12.3899 7.29996 11.9599C7.29996 11.5299 7.64896 11.1789 8.07996 11.1789H15.92C16.35 11.1789 16.7 11.5299 16.7 11.9599C16.7 12.3899 16.35 12.7399 15.92 12.7399ZM15.92 17.3099H8.07996C7.77996 17.3499 7.48996 17.1999 7.32996 16.9499C7.16996 16.6899 7.16996 16.3599 7.32996 16.1099C7.48996 15.8499 7.77996 15.7099 8.07996 15.7399H15.92C16.319 15.7799 16.62 16.1199 16.62 16.5299C16.62 16.9289 16.319 17.2699 15.92 17.3099Z"
                    fill="currentColor"
                  ></path>
                </svg>
                <h6 class="text-dark mb-0">{fileName}</h6>
              </div>
            </div>{" "}
          </div>
        );
      else if (type === "docx")
        return (
          <div class="card-body ">
            <div class="p-3 d-flex justify-content-center align-items-center iq-document rounded bg-body">
              <svg
                width="100"
                viewBox="0 0 113 146"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M110.667 47.9166L64.5833 1.83333C63.4124 0.660896 61.8237 0.00145877 60.1667 0H22.9167C16.8388 0 11.0098 2.41443 6.71213 6.71213C2.41443 11.0098 0 16.8388 0 22.9167V122.917C0 128.995 2.41443 134.823 6.71213 139.121C11.0098 143.419 16.8388 145.833 22.9167 145.833H89.5833C95.6612 145.833 101.49 143.419 105.788 139.121C110.086 134.823 112.5 128.995 112.5 122.917V52.0833C112.434 50.5133 111.78 49.0257 110.667 47.9166ZM66.6667 16.6822L95.8178 45.8333H66.6667V16.6822ZM89.5833 137.984H22.9167C20.154 137.984 14.0175 133.01 12.064 131.056C10.1104 129.103 7.84884 125.679 7.84884 122.917V22.9167C7.84884 20.154 10.1104 14.405 12.064 12.4515C14.0175 10.4979 20.154 7.84883 22.9167 7.84883H58.8178V47.4322C58.8394 49.083 59.7337 50.8189 60.9012 51.9863C62.0686 53.1538 63.4169 53.6606 65.0678 53.6822H104.651V122.917C104.651 125.679 102.39 131.428 100.436 133.382C98.4825 135.335 92.346 137.984 89.5833 137.984Z"
                  fill="white"
                />
                <path
                  d="M34.9912 119.603L24.25 78.9857V78.2934C24.25 77.6459 24.4595 77.0158 24.8472 76.4971L25.8764 75.1204C26.4428 74.3628 27.3333 73.9167 28.2792 73.9167H33.1098C33.9071 73.9167 34.6716 74.2341 35.2345 74.7988L36.2531 75.8207C36.6632 76.2321 36.9451 76.7538 37.0644 77.3224L43.6184 108.555L50.9481 77.5069C51.1092 76.8247 51.504 76.2204 52.0642 75.799L53.7647 74.5195C54.2846 74.1283 54.9177 73.9167 55.5684 73.9167H58.3729C59.1703 73.9167 59.9348 74.2341 60.4977 74.7988L61.5449 75.8494C61.9371 76.2429 62.2123 76.7374 62.3399 77.278L69.7237 108.555L76.2777 77.3224C76.397 76.7538 76.6789 76.2321 77.089 75.8207L78.1076 74.7988C78.6705 74.2341 79.435 73.9167 80.2323 73.9167H84.0223C85.0776 73.9167 86.0553 74.4712 86.597 75.3769L87.61 77.0707C88.0197 77.7557 88.1395 78.5757 87.9429 79.3493L77.6401 119.899C77.4258 120.742 76.8556 121.451 76.0776 121.842L74.5691 122.598C74.1516 122.808 73.691 122.917 73.224 122.917H66.4239C65.8301 122.917 65.2497 122.74 64.7561 122.41L63.9601 121.878C63.3342 121.459 62.8887 120.821 62.7118 120.089L56.25 93.3477L49.7882 120.089C49.6113 120.821 49.1658 121.459 48.5399 121.878L47.7439 122.41C47.2503 122.74 46.6699 122.917 46.0761 122.917H38.9692C38.1718 122.917 37.4073 122.599 36.8444 122.035L35.7667 120.953C35.3938 120.579 35.1262 120.113 34.9912 119.603Z"
                  fill="#096BEE"
                />
              </svg>
            </div>
            <div class="mt-2">
              <div class="d-flex align-items-center mb-2 text-primary gap-2">
                <svg
                  width="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M16.191 2H7.81C4.77 2 3 3.78 3 6.83V17.16C3 20.26 4.77 22 7.81 22H16.191C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2Z"
                    fill="currentColor"
                  ></path>
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.07996 6.6499V6.6599C7.64896 6.6599 7.29996 7.0099 7.29996 7.4399C7.29996 7.8699 7.64896 8.2199 8.07996 8.2199H11.069C11.5 8.2199 11.85 7.8699 11.85 7.4289C11.85 6.9999 11.5 6.6499 11.069 6.6499H8.07996ZM15.92 12.7399H8.07996C7.64896 12.7399 7.29996 12.3899 7.29996 11.9599C7.29996 11.5299 7.64896 11.1789 8.07996 11.1789H15.92C16.35 11.1789 16.7 11.5299 16.7 11.9599C16.7 12.3899 16.35 12.7399 15.92 12.7399ZM15.92 17.3099H8.07996C7.77996 17.3499 7.48996 17.1999 7.32996 16.9499C7.16996 16.6899 7.16996 16.3599 7.32996 16.1099C7.48996 15.8499 7.77996 15.7099 8.07996 15.7399H15.92C16.319 15.7799 16.62 16.1199 16.62 16.5299C16.62 16.9289 16.319 17.2699 15.92 17.3099Z"
                    fill="currentColor"
                  ></path>
                </svg>
                <h6 class="text-dark mb-0">{fileName}</h6>
              </div>
            </div>{" "}
          </div>
        );
      else if (type === "zip")
        return (
          <div class="card-body ">
            <div class="p-3 d-flex justify-content-center align-items-center iq-document rounded bg-body">
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEBklEQVR4nO2cTYiVVRyHn5GJQAMXCUFBH7jTCoQIAvtSF0GgjUZEhAvBsYLATQhi4EKkxaAuBiLapIsEt4KkuHHRJpB2JQiaQaNi+YHV6OR45MA7cDmc6d73ve/c3/+c9zxwNvfc+d/f+3vux3A/XigUCoVCYfS4xNbPwCoyxiW4fgGeJlNcousC8AwZ4hJel4EXyIzwIK3iFllXgNVkROpCHDADrCETchDigGvAi2RAqkKORS67DrxM4qQqZAyYjlx+E3iVhElVCJWUI5G928BrJErKQhY4ENn/G3ibBMlBiGd/5Dr/AJtIjFyEePZErncP2ExC5CTE80XkuveBCRIhNyGeT4GHwfXngPdJgByFeHYC88HfPAC2Y5xchXg+Av6LSNmBYXIW4vkwIsU/nX2OUXIXQvXaMReRshuDdEGI511gNjJnH8boihDPO8C/kVlfYYguCfG8Cdy1LKVrQjzrgTuRmVPVG5ZSuijE8wrwV2Tu18AyhHRViGcdcCMy+1ullC4LofosfiYy/3tgHAGpCnEjWMctHKhVnGjJD9QqrgixhStCuolTP2PIAxhD3oc8gDHkfcgDGEPehzyAMeR9yAMYQ96HPIAx5H3IAxhD3oc8gDHkfcgDGEPehzyAMeR9yAMMiD9ZwFvAx8An1RerJ4GtwPNk1EdbAYZ9W9sFK/zErt+888B7Q+Rf7HZGTi5CXLUODnEMsdvphJC5FoT8AJwAfo/sbWnxOEZOWwE2/c86FNzGdAtCFhiP/PjTP33RdSGLMQ782jPff0ntqRaFeB4Hrgb7TU9Ok72QyWD+/gFzjNXM+U2wv7Fh3qyFLAf+CM628MQSCfky2N/WMHPWQvYGsz+rkaOukMPB/oaGmbMV8iRwq2fuReCxJRRyOth/rmHubIVMBXM/qJmjjpC1wa+k/MnNmpKlkGeDH8f8NMC3ypsIGauemn4L9nYNkT1LId8FMwc5xUVdIZeAPyOXnx3yi9LZCXmp+qXrwrxTDXP0ExJbJ4EVQ+bPTsjJnlnzNc5f1VSIf+041+KZGrIS8kYw6+gQOfoJeb16NK4cMnO/HCOnzQA/9syZrfmvZ78coyoqGyETwZwzfd5wrJujCKlJ7xuIboBVhCxCW/e8OjJcETJ4kUUIebyG5IJT9yEPYAx5H/IAxpD3IQ9gDHkf8gDGkPchD2AMeR/yAMaQ9yEPYAx5H/IAxpD3IQ9gDHkf8gDGkPchD2AMeR/yAMaQ9yEPYAx5H/IAxpD3IQ9gDHkf8gDGkPchD2AMeR/yAMaQ9yEPYAx5H/IAxpD3IQ9gDHkf8gDGkPchD2AMeR/yAMaQ9yEPYAx5H2GAsihCnOE7QnmEoJdQhKAv3oyQQqFQKNBpHgFsPbxyzVc0sQAAAABJRU5ErkJggg==" />
            </div>
            <div class="mt-2">
              <div class="d-flex align-items-center mb-2 text-primary gap-2">
                <svg
                  width="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M16.191 2H7.81C4.77 2 3 3.78 3 6.83V17.16C3 20.26 4.77 22 7.81 22H16.191C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2Z"
                    fill="currentColor"
                  ></path>
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.07996 6.6499V6.6599C7.64896 6.6599 7.29996 7.0099 7.29996 7.4399C7.29996 7.8699 7.64896 8.2199 8.07996 8.2199H11.069C11.5 8.2199 11.85 7.8699 11.85 7.4289C11.85 6.9999 11.5 6.6499 11.069 6.6499H8.07996ZM15.92 12.7399H8.07996C7.64896 12.7399 7.29996 12.3899 7.29996 11.9599C7.29996 11.5299 7.64896 11.1789 8.07996 11.1789H15.92C16.35 11.1789 16.7 11.5299 16.7 11.9599C16.7 12.3899 16.35 12.7399 15.92 12.7399ZM15.92 17.3099H8.07996C7.77996 17.3499 7.48996 17.1999 7.32996 16.9499C7.16996 16.6899 7.16996 16.3599 7.32996 16.1099C7.48996 15.8499 7.77996 15.7099 8.07996 15.7399H15.92C16.319 15.7799 16.62 16.1199 16.62 16.5299C16.62 16.9289 16.319 17.2699 15.92 17.3099Z"
                    fill="currentColor"
                  ></path>
                </svg>
                <h6 class="text-dark mb-0">{fileName}</h6>
              </div>
            </div>{" "}
          </div>
        );
      else if (type === "xlsx") {
        return (
          <div class="card-body ">
            <div class="p-3 d-flex justify-content-center align-items-center iq-document rounded bg-body">
              <svg
                width="100"
                viewBox="0 0 113 146"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M110.667 47.9166L64.5833 1.83333C63.4124 0.660896 61.8237 0.00145877 60.1667 0H22.9167C16.8388 0 11.0098 2.41443 6.71213 6.71213C2.41443 11.0098 0 16.8388 0 22.9167V122.917C0 128.995 2.41443 134.823 6.71213 139.121C11.0098 143.419 16.8388 145.833 22.9167 145.833H89.5833C95.6612 145.833 101.49 143.419 105.788 139.121C110.086 134.823 112.5 128.995 112.5 122.917V52.0833C112.434 50.5133 111.78 49.0257 110.667 47.9166ZM66.6667 16.6822L95.8178 45.8333H66.6667V16.6822ZM89.5833 137.984H22.9167C20.154 137.984 14.0175 133.01 12.064 131.056C10.1104 129.103 7.84884 125.679 7.84884 122.917V22.9167C7.84884 20.154 10.1104 14.405 12.064 12.4515C14.0175 10.4979 20.154 7.84883 22.9167 7.84883H58.8178V47.4322C58.8394 49.083 59.7337 50.8189 60.9012 51.9863C62.0686 53.1538 63.4169 53.6606 65.0678 53.6822H104.651V122.917C104.651 125.679 102.39 131.428 100.436 133.382C98.4825 135.335 92.346 137.984 89.5833 137.984Z"
                  fill="white"
                />
                <path
                  d="M76.8256 72.1666C75.509 71.1605 73.8542 70.7027 72.2077 70.8891C70.5612 71.0755 69.0507 71.8916 67.9923 73.1666L56.2423 87.9166L44.4923 72.9166C43.9451 72.271 43.2761 71.7394 42.5235 71.3523C41.7709 70.9651 40.9494 70.7301 40.1059 70.6604C39.2625 70.5908 38.4135 70.6879 37.6076 70.9464C36.8017 71.2048 36.0546 71.6195 35.4089 72.1666C34.7633 72.7138 34.2317 73.3828 33.8446 74.1354C33.4575 74.888 33.2224 75.7095 33.1527 76.553C33.0831 77.3964 33.1802 78.2453 33.4387 79.0513C33.6971 79.8572 34.1118 80.6043 34.6589 81.25L47.9089 97.9166L34.6589 114.583C33.6202 115.887 33.142 117.55 33.3295 119.207C33.517 120.864 34.355 122.378 35.6589 123.417C36.9629 124.455 38.6261 124.934 40.2827 124.746C41.9393 124.559 43.4535 123.721 44.4923 122.417L56.2423 107.917L67.9923 122.917C68.5907 123.642 69.3414 124.227 70.191 124.63C71.0406 125.034 71.9685 125.245 72.9089 125.25C74.187 125.356 75.4666 125.066 76.5743 124.42C77.682 123.774 78.5641 122.802 79.1009 121.638C79.6377 120.473 79.8033 119.171 79.5752 117.909C79.347 116.647 78.7362 115.486 77.8256 114.583L64.5756 97.9166L78.159 81.25C78.6776 80.5698 79.0549 79.7927 79.2689 78.9646C79.4829 78.1364 79.5291 77.2738 79.4049 76.4275C79.2807 75.5813 78.9885 74.7683 78.5455 74.0366C78.1026 73.3049 77.5178 72.6691 76.8256 72.1666V72.1666Z"
                  fill="#56AA76"
                />
              </svg>
            </div>
            <div class="mt-2">
              <div class="d-flex align-items-center mb-2 text-primary gap-2">
                <svg
                  width="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M16.191 2H7.81C4.77 2 3 3.78 3 6.83V17.16C3 20.26 4.77 22 7.81 22H16.191C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2Z"
                    fill="currentColor"
                  ></path>
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.07996 6.6499V6.6599C7.64896 6.6599 7.29996 7.0099 7.29996 7.4399C7.29996 7.8699 7.64896 8.2199 8.07996 8.2199H11.069C11.5 8.2199 11.85 7.8699 11.85 7.4289C11.85 6.9999 11.5 6.6499 11.069 6.6499H8.07996ZM15.92 12.7399H8.07996C7.64896 12.7399 7.29996 12.3899 7.29996 11.9599C7.29996 11.5299 7.64896 11.1789 8.07996 11.1789H15.92C16.35 11.1789 16.7 11.5299 16.7 11.9599C16.7 12.3899 16.35 12.7399 15.92 12.7399ZM15.92 17.3099H8.07996C7.77996 17.3499 7.48996 17.1999 7.32996 16.9499C7.16996 16.6899 7.16996 16.3599 7.32996 16.1099C7.48996 15.8499 7.77996 15.7099 8.07996 15.7399H15.92C16.319 15.7799 16.62 16.1199 16.62 16.5299C16.62 16.9289 16.319 17.2699 15.92 17.3099Z"
                    fill="currentColor"
                  ></path>
                </svg>
                <h6 class="text-dark mb-0">{fileName}</h6>
              </div>
            </div>{" "}
          </div>
        );
      } else if (type === "ppt" || type === "pptx")
        return (
          <div class="card-body ">
            <div class="p-3 d-flex justify-content-center align-items-center iq-document rounded bg-body">
              <img src={ppt} />
            </div>
            <div class="mt-2">
              <div class="d-flex align-items-center mb-2 text-primary gap-2">
                <svg
                  width="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M16.191 2H7.81C4.77 2 3 3.78 3 6.83V17.16C3 20.26 4.77 22 7.81 22H16.191C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2Z"
                    fill="currentColor"
                  ></path>
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.07996 6.6499V6.6599C7.64896 6.6599 7.29996 7.0099 7.29996 7.4399C7.29996 7.8699 7.64896 8.2199 8.07996 8.2199H11.069C11.5 8.2199 11.85 7.8699 11.85 7.4289C11.85 6.9999 11.5 6.6499 11.069 6.6499H8.07996ZM15.92 12.7399H8.07996C7.64896 12.7399 7.29996 12.3899 7.29996 11.9599C7.29996 11.5299 7.64896 11.1789 8.07996 11.1789H15.92C16.35 11.1789 16.7 11.5299 16.7 11.9599C16.7 12.3899 16.35 12.7399 15.92 12.7399ZM15.92 17.3099H8.07996C7.77996 17.3499 7.48996 17.1999 7.32996 16.9499C7.16996 16.6899 7.16996 16.3599 7.32996 16.1099C7.48996 15.8499 7.77996 15.7099 8.07996 15.7399H15.92C16.319 15.7799 16.62 16.1199 16.62 16.5299C16.62 16.9289 16.319 17.2699 15.92 17.3099Z"
                    fill="currentColor"
                  ></path>
                </svg>
                <h6 class="text-dark mb-0">{fileName}</h6>
              </div>
            </div>{" "}
          </div>
        );
      else if (type === "audio")
        return (
          <div class="card-body ">
            <div class="p-3 d-flex justify-content-center align-items-center iq-document rounded bg-body">
              <img src={audio} height={100} />
            </div>
            {console.log(source)}
            <div class="mt-2">
              <audio controls>
                <source src={source.url} />
              </audio>
            </div>
          </div>
        );
    }

    return (
      <div class="card-body ">
        <div class="p-3 d-flex justify-content-center align-items-center iq-document rounded bg-body">
          <svg
            width="100"
            viewBox="0 0 113 146"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M110.667 47.9166L64.5833 1.83333C63.4124 0.660896 61.8237 0.00145877 60.1667 0H22.9167C16.8388 0 11.0098 2.41443 6.71213 6.71213C2.41443 11.0098 0 16.8388 0 22.9167V122.917C0 128.995 2.41443 134.823 6.71213 139.121C11.0098 143.419 16.8388 145.833 22.9167 145.833H89.5833C95.6612 145.833 101.49 143.419 105.788 139.121C110.086 134.823 112.5 128.995 112.5 122.917V52.0833C112.434 50.5133 111.78 49.0257 110.667 47.9166ZM66.6667 16.6822L95.8178 45.8333H66.6667V16.6822ZM89.5833 137.984H22.9167C20.154 137.984 14.0175 133.01 12.064 131.056C10.1104 129.103 7.84884 125.679 7.84884 122.917V22.9167C7.84884 20.154 10.1104 14.405 12.064 12.4515C14.0175 10.4979 20.154 7.84883 22.9167 7.84883H58.8178V47.4322C58.8394 49.083 59.7337 50.8189 60.9012 51.9863C62.0686 53.1538 63.4169 53.6606 65.0678 53.6822H104.651V122.917C104.651 125.679 102.39 131.428 100.436 133.382C98.4825 135.335 92.346 137.984 89.5833 137.984Z"
              fill="white"
            />
            <path
              d="M34.9912 119.603L24.25 78.9857V78.2934C24.25 77.6459 24.4595 77.0158 24.8472 76.4971L25.8764 75.1204C26.4428 74.3628 27.3333 73.9167 28.2792 73.9167H33.1098C33.9071 73.9167 34.6716 74.2341 35.2345 74.7988L36.2531 75.8207C36.6632 76.2321 36.9451 76.7538 37.0644 77.3224L43.6184 108.555L50.9481 77.5069C51.1092 76.8247 51.504 76.2204 52.0642 75.799L53.7647 74.5195C54.2846 74.1283 54.9177 73.9167 55.5684 73.9167H58.3729C59.1703 73.9167 59.9348 74.2341 60.4977 74.7988L61.5449 75.8494C61.9371 76.2429 62.2123 76.7374 62.3399 77.278L69.7237 108.555L76.2777 77.3224C76.397 76.7538 76.6789 76.2321 77.089 75.8207L78.1076 74.7988C78.6705 74.2341 79.435 73.9167 80.2323 73.9167H84.0223C85.0776 73.9167 86.0553 74.4712 86.597 75.3769L87.61 77.0707C88.0197 77.7557 88.1395 78.5757 87.9429 79.3493L77.6401 119.899C77.4258 120.742 76.8556 121.451 76.0776 121.842L74.5691 122.598C74.1516 122.808 73.691 122.917 73.224 122.917H66.4239C65.8301 122.917 65.2497 122.74 64.7561 122.41L63.9601 121.878C63.3342 121.459 62.8887 120.821 62.7118 120.089L56.25 93.3477L49.7882 120.089C49.6113 120.821 49.1658 121.459 48.5399 121.878L47.7439 122.41C47.2503 122.74 46.6699 122.917 46.0761 122.917H38.9692C38.1718 122.917 37.4073 122.599 36.8444 122.035L35.7667 120.953C35.3938 120.579 35.1262 120.113 34.9912 119.603Z"
              fill="#096BEE"
            />
          </svg>
        </div>
        <div class="mt-2">
          <div class="d-flex align-items-center mb-2 text-primary gap-2">
            <svg
              width="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.4"
                d="M16.191 2H7.81C4.77 2 3 3.78 3 6.83V17.16C3 20.26 4.77 22 7.81 22H16.191C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2Z"
                fill="currentColor"
              ></path>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.07996 6.6499V6.6599C7.64896 6.6599 7.29996 7.0099 7.29996 7.4399C7.29996 7.8699 7.64896 8.2199 8.07996 8.2199H11.069C11.5 8.2199 11.85 7.8699 11.85 7.4289C11.85 6.9999 11.5 6.6499 11.069 6.6499H8.07996ZM15.92 12.7399H8.07996C7.64896 12.7399 7.29996 12.3899 7.29996 11.9599C7.29996 11.5299 7.64896 11.1789 8.07996 11.1789H15.92C16.35 11.1789 16.7 11.5299 16.7 11.9599C16.7 12.3899 16.35 12.7399 15.92 12.7399ZM15.92 17.3099H8.07996C7.77996 17.3499 7.48996 17.1999 7.32996 16.9499C7.16996 16.6899 7.16996 16.3599 7.32996 16.1099C7.48996 15.8499 7.77996 15.7099 8.07996 15.7399H15.92C16.319 15.7799 16.62 16.1199 16.62 16.5299C16.62 16.9289 16.319 17.2699 15.92 17.3099Z"
                fill="currentColor"
              ></path>
            </svg>
            <h6 class="text-dark mb-0">{fileName}</h6>
          </div>
        </div>{" "}
      </div>
    );
  }

  return (
    <Fragment>
      <div className="sidebar-border-profile-card overflow-hidden position-relative m-0">
        <input
          type="file"
          name=""
          value=""
          className="position-absolute start-0 top-0 z-1 w-100 h-100 opacity-0"
          style={{ cursor: "pointer" }}
          onChange={(e) => onUpdate(e.target.files)}
          accept={accepts}
        />
        {result.length > 0 && res?.review == title ? (
          <>
            {result.map((res) => (
              <h2>{res.tags}</h2>
            ))}
          </>
        ) : (
          <div className="sidebar-border-profile-body">
            {source && type ? (
              renderElement()
            ) : (
              <>
                <div className="sidebar-btn">
                  <span className="sidebar-btn-icon">
                    <svg
                      width="40"
                      viewBox="0 0 24 24"
                      className="text-primary"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.4"
                        d="M16.6667 2H7.33333C3.92889 2 2 3.92889 2 7.33333V16.6667C2 20.0622 3.92 22 7.33333 22H16.6667C20.0711 22 22 20.0622 22 16.6667V7.33333C22 3.92889 20.0711 2 16.6667 2Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M15.3205 12.7083H12.7495V15.257C12.7495 15.6673 12.4139 16 12 16C11.5861 16 11.2505 15.6673 11.2505 15.257V12.7083H8.67955C8.29342 12.6687 8 12.3461 8 11.9613C8 11.5765 8.29342 11.2539 8.67955 11.2143H11.2424V8.67365C11.2824 8.29088 11.6078 8 11.996 8C12.3842 8 12.7095 8.29088 12.7495 8.67365V11.2143H15.3205C15.7066 11.2539 16 11.5765 16 11.9613C16 12.3461 15.7066 12.6687 15.3205 12.7083Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </span>
                </div>
                <div className="sidebar-border-user-data d-flex flex-column">
                  <span className="sidebar-border-data sidebar-border-title mb-1">
                    {title}
                  </span>
                  {/* <small className="sidebar-border-data">by @Musk</small> */}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default memo(FilePicker);
