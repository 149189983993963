import Default from "../../../../layouts/dashboard/default";
import ShortLinkCreate from "../pages/short-link-create";
import ShortLinkDetails from "../pages/short-link-details";
import ShortLinkListing from "../pages/short-link-listing";

export const ShortLinkRouter = [
    {
        path: "/short-link",
        element: <Default />,
        children: [
            {
                path: "create",
                element: <ShortLinkCreate />,
            },
            {
                path: "listing",
                element: <ShortLinkListing />,
            },
            {
                path: "details/:url_key",
                element: <ShortLinkDetails />,
            },
        ],
    },
];
