import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Form, FormCheck, Row } from "react-bootstrap";
import { getValuesByKey } from "../../../../../common/utils/utils";
import { findIcon } from "../../../../dashboard/icons";

const innitialFilterData = [
  {
    id: 1,
    name: "",
    operator: "",
    clause: "",
    value: "",
  },
];

const UserWhoDidFilter = ({
  id,
  selectedJson: selectedJsonProp,
  onDeleteItem,
  onCreateBtnClick,
  isDeleteBtn,
  isAddBtn,
  onItemSelect,
  index,
  contentCategoryList,
  activityList,
}) => {
  const [firstFilterData, setFirstFilterData] = useState([]);
  const [secondFilterData, setSecondFilterData] = useState([]);
  const [selectedJson, setSelectedJson] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState("");
  const tempPayload = useRef(innitialFilterData);

  useEffect(() => {
    if (contentCategoryList?.length > 0) {
      setFirstFilterData(contentCategoryList);
    }
  }, [contentCategoryList]);

  useEffect(() => {
    if (activityList?.length > 0) {
      setSecondFilterData(activityList);
    }
  }, [activityList]);

  useEffect(() => {
    if (selectedJsonProp) {
      setSelectedJson(selectedJsonProp);
      tempPayload.current = selectedJson;
      console.log("selectedJson", selectedJsonProp);
    }
  }, [selectedJsonProp]);

  const onCategoryFilterSelect = (e) => {
    console.group("onCategoryFilterSelect");
    const { value } = e.target;
    let tempData = innitialFilterData[0];
    const availableFilterData =
      selectedJson && selectedJson?.length > 0
        ? selectedJson.filter((item) => item.id == id)
        : [];
    console.log(index, "availableFilterData", availableFilterData);
    if (
      selectedJson &&
      selectedJson?.length > 0 &&
      availableFilterData?.length > 0
    ) {
      tempData = { ...availableFilterData[0], name: value };
      const index = tempPayload.current.findIndex((item) => item.id == id);
      tempPayload.current[index] = tempData;
      console.log("if:", tempData, "::", tempPayload.current);
    } else {
      console.log("else:", tempPayload.current);
      tempData.name = value;
      tempPayload.current[id] = tempData;
    }
    console.log(
      "exit:",
      tempPayload.current,
      availableFilterData,
      selectedJson
    );
    console.groupEnd("onCategoryFilterSelect");
    onItemSelect(tempData);
  };

  const onActivityFilterSelect = (e) => {
    console.group("onActivityFilterSelect");
    const { value } = e.target;
    let tempData = innitialFilterData[0];
    const availableFilterData =
      selectedJson && selectedJson?.length > 0
        ? selectedJson.filter((item) => item.id == id)
        : [];
    console.log(index, "availableFilterData", availableFilterData);
    if (
      selectedJson &&
      selectedJson?.length > 0 &&
      availableFilterData?.length > 0
    ) {
      tempData = { ...availableFilterData[0], value: value };
      const index = tempPayload.current.findIndex((item) => item.id == id);
      tempPayload.current[index] = tempData;
      console.log("if:", tempData, "::", tempPayload.current);
    } else {
      console.log("else:", tempPayload.current);
      tempData.value = value;
      tempPayload.current[id] = tempData;
    }
    console.log(
      "exit:",
      tempPayload.current,
      availableFilterData,
      selectedJson
    );
    console.groupEnd("onActivityFilterSelect");
    onItemSelect(tempData);
  };

  const onClauseSelect = (e) => {
    console.group("onActivityFilterSelect");
    const { value } = e.target;
    let tempData = innitialFilterData[0];
    const availableFilterData =
      selectedJson && selectedJson?.length > 0
        ? selectedJson.filter((item) => item.id == id)
        : [];
    console.log(index, "availableFilterData", availableFilterData);
    if (
      selectedJson &&
      selectedJson?.length > 0 &&
      availableFilterData?.length > 0
    ) {
      tempData = { ...availableFilterData[0], clause: value };
      const index = tempPayload.current.findIndex((item) => item.id == id);
      tempPayload.current[index] = tempData;
      console.log("if:", tempData, "::", tempPayload.current);
    } else {
      console.log("else:", tempPayload.current);
      tempData.clause = value;
      tempPayload.current[id] = tempData;
    }
    console.log(
      "exit:",
      tempPayload.current,
      availableFilterData,
      selectedJson
    );
    console.groupEnd("onActivityFilterSelect");
    onItemSelect(tempData);
  };

  const onToogleOperator = (e) => {
    console.group("onActivityFilterSelect");
    const { value, name } = e.target;
    let tempData = innitialFilterData[0];
    const availableFilterData =
      selectedJson && selectedJson?.length > 0
        ? selectedJson.filter((item) => item.id == id)
        : [];
    console.log(index, "availableFilterData", availableFilterData);
    if (
      selectedJson &&
      selectedJson?.length > 0 &&
      availableFilterData?.length > 0
    ) {
      tempData = { ...availableFilterData[0], operator: name };
      const index = tempPayload.current.findIndex((item) => item.id == id);
      tempPayload.current[index] = tempData;
      console.log("if:", tempData, "::", tempPayload.current);
    } else {
      console.log("else:", tempPayload.current);
      tempData.operator = name;
      tempPayload.current[id] = tempData;
    }
    console.log(
      "exit:",
      tempPayload.current,
      availableFilterData,
      selectedJson
    );
    console.groupEnd("onActivityFilterSelect");
    onItemSelect(tempData);

    console.log("onToogleOperator=====", name, tempPayload.current);
  };

  return (
    <>
      <div>
        <Row className="">
          {(index > 0 || isDeleteBtn) && (
            <Col lg={12} className="d-flex justify-content-end mt-4">
              {/* filter section */}
              {/* {index > 0 && (
                <div className="d-flex flex-row gap-3">
                  <Form.Check className="fs-6 form-switch">
                    <FormCheck.Input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      name="and"
                      onChange={onToogleOperator}
                      checked={selectedJson[index]?.operator == "and"}
                      // disabled={selectedJson[index]?.operator == "or"}
                    />
                    <FormCheck.Label
                      className="form-check-label text-black lh-base"
                      htmlFor="flexSwitchCheckChecked"
                    >
                      And
                    </FormCheck.Label>
                  </Form.Check>
                  <Form.Check className="fs-6 form-switch">
                    <FormCheck.Input
                      className="form-check-input"
                      type="checkbox"
                      name="or"
                      id="flexSwitchCheckChecked"
                      checked={selectedJson[index]?.operator == "or"}
                      onChange={onToogleOperator}
                      // disabled={selectedJson[index]?.operator == "and"}
                    />
                    <FormCheck.Label
                      className="form-check-label text-black lh-base"
                      htmlFor="flexSwitchCheckChecked"
                    >
                      Or
                    </FormCheck.Label>
                  </Form.Check>
                </div>
              )} */}
              {/** delete section */}
              {isDeleteBtn && (
                <Button
                  variant="danger"
                  className="btn-icon btn-sm rounded-circle"
                  onClick={(e) => onDeleteItem(id)}
                >
                  <div className="btn-inner">
                    {findIcon("Trash", "dual-tone")}
                  </div>
                </Button>
              )}
            </Col>
          )}

          <Col lg={4} className="mt-4">
            <Form.Select
              name="type"
              className="selectpicker form-control"
              data-style="py-0"
              onChange={onCategoryFilterSelect}
              value={selectedJson[index]?.name}
            >
              <option value="">Select a Filter</option>
              {firstFilterData?.length > 0 &&
                firstFilterData?.map((spec) => (
                  <option value={spec.content_category_id}>
                    {spec.content_category_name}
                  </option>
                ))}
            </Form.Select>
          </Col>
          <Col lg={4} className="mt-4">
            <Form.Select
              name="type"
              className="selectpicker form-control"
              data-style="py-0"
              onChange={onClauseSelect}
              value={selectedJson[index]?.clause}
            >
              <option value="">Select Clause</option>
              <option value="is">Is</option>
              <option value="is_not">Is Not</option>
            </Form.Select>
          </Col>
          <Col lg={4} className="mt-4">
            <Form.Select
              name="type"
              className="selectpicker form-control"
              data-style="py-0"
              onChange={onActivityFilterSelect}
              value={selectedJson[index]?.value}
            >
              <option value="">Select an Option</option>
              {secondFilterData &&
                secondFilterData?.length > 0 &&
                secondFilterData.map((el, index) => (
                  <option value={el.activity_type_id}>
                    {el.activity_type_name}
                  </option>
                ))}
            </Form.Select>
          </Col>
        </Row>
      </div>
      {isAddBtn && (
        <Col lg={12} className="d-flex justify-content-center my-3">
          <Button
            variant="outline-info"
            className="d-flex align-items-center border-dashed justify-content-center gap-2"
            onClick={onCreateBtnClick}
          >
            {findIcon("Plus", "solid", "20")}
            Add More
          </Button>
        </Col>
      )}
    </>
  );
};

export default UserWhoDidFilter;
