import { memo, Fragment } from "react";

// Components
import Autheffect from "../components/auth-effect";

// React-bootstrap
import { Row, Form, Button, Col, Card, Image } from "react-bootstrap";

import ciplaUnityLogo from "../../../../assets/images/client-logo/ciplaUnityLogo.svg";

const Accountdeactivate = memo(() => {
  return (
    <Fragment>
      <div className="iq-auth-page">
        <Autheffect />
        <Row className="d-flex align-items-center iq-auth-container justify-content-center">
          <Col lg="7" className="py-3 py-md-4 text-center text-lg-start">
            <Image
              src={ciplaUnityLogo}
              alt="CLIRNET Unity"
              className="ciplaLogo mb-2"
            />
            <h5 className="h5 lh-base">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s,
            </h5>
          </Col>
          <Col lg="4">
            <Card>
              <Card.Body>
                <div className="mb-2">
                  <h4>Account De-activate</h4>
                  <p>Enter your details to de-activate your account</p>
                </div>
                <Form>
                  <Row>
                    <Col lg="12" className="mb-3">
                      <Form.Floating className="custom-form-floating">
                        <Form.Control
                          type="email"
                          className=""
                          id="floatingInput1"
                          autoComplete="Email address"
                          placeholder="Email address"
                        />
                        <Form.Label htmlFor="fname">Enter Email</Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col lg="12" className="mb-3">
                      <Form.Floating className="custom-form-floating">
                        <Form.Control
                          type="text"
                          className=""
                          id="floatingInput1"
                          autoComplete="User Name"
                          placeholder="User Name"
                        />
                        <Form.Label htmlFor="fname">User Name</Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col lg="12" className="mb-3">
                      <Button className="btn btn-primary w-100">
                        De-activate
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
});

Accountdeactivate.displayName = "Accountdeactivate";
export default Accountdeactivate;
