//create authSlice
import { createSlice } from "@reduxjs/toolkit";
import { commonInitialState } from "./state";

export const commonSlice = createSlice({
  name: "common",
  initialState: commonInitialState,
  reducers: {
    toogleBackdrop: (state, action) => {
      state.isBackdropShown = action.payload;
    },
  },
});
export default commonSlice.reducer;
