import React, { useState, useEffect } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { getFormatedDate } from "../../../../common/utils/utils";
import mobile from "../../../../assets/images/mobile-bg.png";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import uploadService from "../../../../api/services/upload-service";

import {
  UPDATE_ACTION_POST,
  UPLOAD_FILES_POST,
} from "../../../../api/endpoints/action-endpoints";
import postService from "../../../../api/services/post-service";
import toast from "react-hot-toast";
import app_config from "../../../../common/data/app_config";
import { findIcon } from "../../../dashboard/icons";
import { STATUS_CONSTANTS } from "../constants/action-constants";
import GrapejsEditor from "../../../../lib/email-editor/grapejs-editor";
import SubmitButton from "../../../../components/form-components/submit-button";
import useBackdrop from "../../../../components/custom-hooks/use-backdrop";
const ReviewApproval = ({ actionEditData, actionType, actionId }) => {
  const { origin, comments } = actionEditData;
  const emailObjectKeyMapping = {
    email_id: "id",
    email_name: "name",
    email_content: "content",
    email_duedate: "duedate",
    email_priority_id: "priority",
    email_category_id: "category",
    email_from_email: "from_email",
    email_from_name: "from_name",
  };
  const modifyKeyNames = (obj, mapping) => {
    let modifiedObj = {};
    Object.keys(obj).forEach((key) => {
      if (mapping[key]) {
        modifiedObj[mapping[key]] = obj[key];
      } else {
        modifiedObj[key] = obj[key];
      }
    });
    return modifiedObj;
  };

  const templateMedium = origin?.whatsapp_id
    ? "whatsapp"
    : origin?.sms_id
    ? "sms"
    : "email";
  const whatsappObjectKeyMapping = {
    whatsapp_id: "id",
    whatsapp_name: "name",
    whatsapp_content: "content",
    whatsapp_duedate: "duedate",
    whatsapp_priority_id: "priority",
    whatsapp_category_id: "category",
  };
  const smsObjectKeyMapping = {
    sms_id: "id",
    sms_name: "name",
    sms_content: "content",
    sms_duedate: "duedate",
    sms_priority_id: "priority",
    sms_category_id: "category",
  };

  const [originInfo, setOriginInfo] = useState({});
  useEffect(() => {
    const getOriginInformation = () => {
      let mapping = {};
      if (templateMedium == "whatsapp") {
        mapping = whatsappObjectKeyMapping;
      } else if (templateMedium == "sms") {
        mapping = smsObjectKeyMapping;
      } else if (templateMedium == "email") {
        mapping = emailObjectKeyMapping;
      }

      let modifiedInfo = modifyKeyNames(origin, mapping);
      return modifiedInfo;
    };

    setOriginInfo(getOriginInformation());
  }, [origin]);

  return (
    <Row className="mb-3">
      <div className="col-12 col-md-8">
        <Card className="p-3 mb-3">
          <HeroSection
            title={originInfo?.name ?? ""}
            content={originInfo?.content ?? ""}
            createdBy={originInfo?.created_by?.name ?? ""}
            category={originInfo?.category?.category_name ?? ""}
            deliveryDate={getFormatedDate(originInfo?.duedate) ?? ""}
            priority={originInfo?.priority?.priority_name ?? "Medium"}
            createdOn={getFormatedDate(originInfo?.created_at) ?? ""}
            templateMedium={templateMedium}
          />
        </Card>
      </div>
      <div className="col-12 col-md-4 bg-white p-3 rounded-3">
        <Reviews comments={comments ?? []} />
        <Col lg="12 border border-1 rounded-2 mt-3 p-3">
          <AddApprovalForm actionType={actionType} actionId={actionId} />
        </Col>
      </div>
    </Row>
  );
};

export default ReviewApproval;

function HeroSection({
  title,
  createdBy,
  category,
  deliveryDate,
  priority,
  createdOn,
  content,
  templateMedium,
}) {
  const [presetHTML, setPresetHTML] = useState(null);
  const [editor, setEditor] = useState(null);
  const emailEditorRef = React.useRef(null);
  useEffect(() => {
    if (templateMedium == "email") {
      setPresetHTML(content);
    }
  }, [content]);

  return (
    <>
      <h5 className="mb-3 pt-3 ps-3">
        {templateMedium == "whatsapp"
          ? "Whatsapp Template"
          : templateMedium == "sms"
          ? "SMS Template"
          : "Email Template"}
        : {title}
      </h5>
      <Row>
        {templateMedium !== "email" ? (
          <div
            className="mx-auto position-sticky top-0"
            style={{ width: "42%" }}
          >
            <div className="bg-soft-info h-100 p-2 rounded-5 border border-2 border-dashed border-info d-flex flex-column">
              <div className="d-flex position-relative mobile-preview">
                {/* <p>Preview is not available for SMS channel</p> */}
                <img
                  src={mobile}
                  alt="mobile"
                  className="mobile h-auto w-100 position-relative"
                />
                <div className="p-2 h-100 w-100 position-absolute d-flex pt-5 pb-4">
                  <div className="px-5 mx-100 overflow-auto w-100 flex-grow-1 mt-3">
                    <span className="d-block position-relative px-3 py-2 rounded-3 text-dark fs-7 lh-base shadow-lg shadow bg-white">
                      {content}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : presetHTML ? (
          <GrapejsEditor
            ref={emailEditorRef}
            onReady={() => {}}
            onLoad={() => {}}
            previewMode
            presetData={presetHTML}
            setEditor={(e) => setEditor(e)}
            editor={editor}
          />
        ) : null}
      </Row>
      <div className="d-flex flex-row flex-wrap px-3 mt-4">
        <span className="rounded-3 border border-1 px-3 py-2 me-3 mb-3 fs-7">
          Created By: <strong>{createdBy}</strong>
        </span>
        <span className="rounded-3 border border-1 px-3 py-2 me-3 mb-3 fs-7">
          Template Category: <strong>{category}</strong>
        </span>

        <span className="rounded-3 border bg-soft-primary px-3 py-2 me-3 mb-3 fs-7">
          Exp Delivery Date : <strong>{deliveryDate}</strong>
        </span>
        <span className="rounded-3 bg-soft-warning border px-3 py-2 me-3 mb-3 fs-7">
          Priority :{" "}
          <strong className="bg-soft-warning px-2 py-1">{priority}</strong>
        </span>
        <span className="rounded-3 bg-soft-info border px-3 py-2 me-3 mb-3 fs-7">
          Created On: <strong>{createdOn}</strong>
        </span>
      </div>
    </>
  );
}
function Reviews({ comments }) {
  //console.log("comments", comments);
  return (
    <div className="reviews">
      {comments?.length > 0 &&
        comments?.map((_comment, _idx) => {
          let { created_at, comment, user_id } = _comment;
          return (
            <div
              className="d-flex flex-column justify-content-between shadow-sm p-3 mb-3 rounded-3 bg-white"
              key={_idx + 1}
            >
              <div className="d-flex flex-row justify-content-between mb-2">
                <div className="d-flex flex-column">
                  <span>{user_id?.name ?? ""}</span>
                  <span className="fs-7 text-black">
                    {getFormatedDate(created_at)}
                  </span>
                </div>
              </div>
              <div>{comment || ""}</div>
            </div>
          );
        })}
    </div>
  );
}
function AddApprovalForm({ actionId, actionType }) {
  const { redirectTo } = useRedirect();
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const [values, setValues] = useState({ reference_files: [], input: "" });
  const [disabled, setDisabled] = useState(false);
  const uploadFiles = (files) => {
    return new Promise(async (resolve, reject) => {
      // Check if files array is empty
      if (files.length === 0) {
        reject("No files to upload.");
        return;
      }
      let formdata = new FormData();
      files.forEach((file) => {
        formdata.append("files[]", file);
      });

      // 1. Upload files to server
      let response = await uploadService(UPLOAD_FILES_POST, formdata);
      if (response.data?.success) {
        // 2. Simulating successful upload and generating URLs
        const urls = response.data.data.map((file) => file.name);
        resolve(urls);
      } else {
        reject("Something went wrong");
      }
    });
  };
  const handleChangeReferenceFiles = async (e) => {
    const { files } = e.target;
    const urls = await uploadFiles([...files]);
    if (urls) {
      setValues({
        ...values,
        reference_files: [...values.reference_files, ...urls],
      });
    }
  };

  const submitReview = async (status) => {
    if (status == STATUS_CONSTANTS.REJECTED && !values.input) {
      toast.error("Please enter comment");
      return;
    }
    showBackdrop();
    setDisabled(true);
    let response = await postService(UPDATE_ACTION_POST + actionId, {
      action_id: actionId,
      status,
      comment: values.input,
      // reference_files: values.reference_files,
    });

    if (response.isError) {
      hideBackdrop();
      setDisabled(false);
      toast.error(response.error);
      return;
    }

    if (response.data.success) {
      hideBackdrop();
      setDisabled(false);
      toast.success("Your action has been submitted successfully");
      redirectTo("action/all-action");
    }
  };
  const onCrossImageClick = (e) => {
    let tempRequisitionFiles = values.reference_files
      ? [...values.reference_files]
      : [];
    tempRequisitionFiles = tempRequisitionFiles.filter(
      (file, idx) => idx !== e
    );
    setValues({
      ...values,
      reference_files: tempRequisitionFiles,
    });
  };
  return (
    <Form>
      <Col lg="12" className="mb-2 mt-0">
        <Form.Floating className="custom-form-floating">
          <textarea
            className="form-control"
            placeholder="Leave a comment here"
            id="floatingTextarea2"
            name="input"
            onChange={(e) => {
              setValues({ ...values, input: e.target.value });
            }}
          ></textarea>
          <Form.Label htmlFor="fname">Write your comments</Form.Label>
        </Form.Floating>
      </Col>
      <Col lg="12" className="mb-2">
        <h6 className="mb-2 mt-3 fw-normal">Add Reference Files</h6>
        <Form.Control
          type="file"
          id="customFile"
          multiple
          name="reference_files"
          onChange={handleChangeReferenceFiles}
        />
        <div className="d-flex flex-wrap flex-row gap-3 mt-3">
          {values.reference_files?.map((file, _idx) => (
            <div className="position-relative" key={_idx + 1}>
              <img
                className="rounded img-fluid avatar-120"
                src={app_config.fileStoragePath + file}
                alt="profile"
                loading="lazy"
                onError={(e) => {
                  e.target.onerror = null;
                  //   e.target.src = article_1;
                }}
              />
              <span
                className="position-absolute top-0 end-0"
                onClick={() => onCrossImageClick(_idx)}
              >
                {findIcon("Square X", "solid", 20)}
              </span>
            </div>
          ))}
        </div>
      </Col>
      <Col lg="12" className="d-flex gap-3">
        <SubmitButton
          type="button"
          variant="warning"
          disabled={disabled}
          onClick={() => submitReview(STATUS_CONSTANTS.REJECTED)}
          className="w-100 py-3 px-1 mt-2 fs-6 rounded-3"
        >
          Reject
        </SubmitButton>
        <SubmitButton
          type="button"
          variant="primary"
          disabled={disabled}
          onClick={() => submitReview(STATUS_CONSTANTS.COMPLETED)}
          className="w-100 py-3 px-1 mt-2 fs-6 fw-semibold rounded-3"
        >
          Approve
        </SubmitButton>
      </Col>
    </Form>
  );
}
