export const GET_HCP_DETAILS = "GET_HCP_DETAILS";
export const GET_HCP_ACTIVITY_LIST = "GET_HCP_ACTIVITY_LIST";
export const GET_HCP_ENGAGEMENT_METER_DATA = "GET_HCP_ENGAGEMENT_METER_DATA";
// export const POST_HCP_SPECIALITY_DASHBOARD_STATUS =
//   "GET_HCP_CHANGE_SPECIALITY_DASHBOARD_STATUS";
// export const POST_HCP_RECOMMENDATION_STATUS = "POST_HCP_RECOMMENDATION_STATUS";
// export const POST_HCP_REQUEST_DATA_CHNAGE = "POST_HCP_REQUEST_DATA_CHNAGE";
export const GET_HCP_RECOMMENDATION_STATUS = "GET_HCP_RECOMMENDATION_STATUS";
export const GET_HCP_ALL_ENGAGEMENT_DATA = "GET_HCP_ALL_ENGAGEMENT_DATA";
export const GET_HCP_ACTIVITY_TIMELINE_DATA = "GET_HCP_ACTIVITY_TIMELINE_DATA";
export const GET_HCP_TIME_OF_DAY_DATA = "GET_HCP_TIME_OF_DAY_DATA";
export const GET_HCP_DAY_OF_WEEK_DATA = "GET_HCP_DAY_OF_WEEK_DATA";
export const GET_HCP_NBA_VIEW_DATA = "GET_HCP_NBA_VIEW_DATA";
