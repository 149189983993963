import React from "react";

//FileManager layout
import Dashboard from "../pages/dashboard";

// pages
import AllCreatives from "../pages/content-listing";
import UploadCreative from "../pages/upload-content";
import Default from "../../../../layouts/dashboard/default";
import ContentSearch from "../pages/search";
import MyContent from "../pages/my-content";
import Category from "../pages/category";
import Content from "../pages/content";
import Brand from "../pages/brand";
import ContentTree from "../pages/content-tree";
import Research from "../pages/research";
import AiEmailGenerator from "../pages/ai-email-generator";

export const ContentLibraryRouter = [
  {
    path: "content-library",
    element: <Default />,
    children: [
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "my-content",
        element: <MyContent />,
      },
      {
        path: "research",
        element: <Research />,
      },
      {
        path: "listing",
        element: <AllCreatives />,
      },
      {
        path: "upload",
        element: <UploadCreative />,
      },
      {
        path: "search",
        element: <ContentSearch />,
      },
      {
        path: "category/:file_type",
        element: <MyContent />,
      },
      {
        path: "advanced/content/:type",
        element: <Content />,
      },
      {
        path: "advanced/brand/:type",
        element: <Brand />,
      },
      {
        path: "advanced/content-tree",
        element: <ContentTree />,
      },
      {
        path: "ai-email-generator",
        element: <AiEmailGenerator />,
      },
    ],
  },
];
