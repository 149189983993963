import { useState, memo, Fragment, useEffect } from "react";

// React-boostrap
import { Row, Col, Modal, Button, Form } from "react-bootstrap";

// React-dropzone
import "react-dropzone-uploader/dist/styles.css";

// Componet
import Document from "../components/document.js";
import Images from "../components/images.js";
import Video from "../components/video.js";
import Folder from "../components/folder";

//Img
import img1 from "../../../../assets/modules/file-manager/images/5.png";
import img2 from "../../../../assets/modules/file-manager/images/6.png";
import img3 from "../../../../assets/modules/file-manager/images/7.png";
import img4 from "../../../../assets/modules/file-manager/images/8.png";
import pdf from "../../../../assets/modules/file-manager/images/pdf.svg";
import excel from "../../../../assets/modules/file-manager/images/excel.svg";
import word from "../../../../assets/modules/file-manager/images/word.svg";
import img9 from "../../../../assets/modules/file-manager/images/svg/14.svg";
import img10 from "../../../../assets/modules/file-manager/images/svg/15.svg";
import Masonry from "react-masonry-css";
import LibraryLoader from "../../../../skeleton/content-library/library-loader.js";
// import CreateFolder from "../components/create-folder.js";
const breakpointColumnsObj = {
  default: 4,
  1100: 3,
  768: 2,
  500: 1,
};
const AllContent = memo(() => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loading, setLoading] = useState(true);
  let tableData = [...Array(8).keys()].map((_el) => _el + 1);
  // console.log("tableData", tableData, tableData.length, loading);

  const handleSubmit = (files, allFiles) => {
    allFiles.forEach((f) => f.remove());
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 800);
  }, []);

  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <h3 className="m-0">All Contents</h3>
        <ul
          className="nav nav-pills mb-0 iq-product-filter d-flex bg-transparent align-items-center flex-nowrap"
          id="pills-tab"
          role="tablist"
        >
          <li className="nav-item dropdown flex-grow-1">
            <Form.Group className="input-group mb-0 search-input w-100">
              <Form.Control
                type="text"
                id="disabledTextInput"
                className=""
                placeholder="Search..."
              />
              <span className="input-group-text">
                <svg
                  width="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="11.7669"
                    cy="11.7666"
                    r="8.98856"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></circle>
                  <path
                    d="M18.0186 18.4851L21.5426 22"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </span>
            </Form.Group>
          </li>
          <li className="nav-item dropdown ms-2">
            <Form.Group>
              <Form.Select>
                <option>Sort By</option>
              </Form.Select>
            </Form.Group>
          </li>
          <li className="nav-item ms-2" role="presentation">
            <Button
              bsPrefix="nav-link btn btn-sm btn-icon rounded-pill "
              id="grid-view-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-grid-view"
              to="#"
              role="tab"
              aria-controls="pills-grid-view"
              aria-selected="true"
            >
              <span className="btn-inner">
                <svg
                  width="32"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M16.0756 2H19.4616C20.8639 2 22.0001 3.14585 22.0001 4.55996V7.97452C22.0001 9.38864 20.8639 10.5345 19.4616 10.5345H16.0756C14.6734 10.5345 13.5371 9.38864 13.5371 7.97452V4.55996C13.5371 3.14585 14.6734 2 16.0756 2Z"
                    fill="currentColor"
                  ></path>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.53852 2H7.92449C9.32676 2 10.463 3.14585 10.463 4.55996V7.97452C10.463 9.38864 9.32676 10.5345 7.92449 10.5345H4.53852C3.13626 10.5345 2 9.38864 2 7.97452V4.55996C2 3.14585 3.13626 2 4.53852 2ZM4.53852 13.4655H7.92449C9.32676 13.4655 10.463 14.6114 10.463 16.0255V19.44C10.463 20.8532 9.32676 22 7.92449 22H4.53852C3.13626 22 2 20.8532 2 19.44V16.0255C2 14.6114 3.13626 13.4655 4.53852 13.4655ZM19.4615 13.4655H16.0755C14.6732 13.4655 13.537 14.6114 13.537 16.0255V19.44C13.537 20.8532 14.6732 22 16.0755 22H19.4615C20.8637 22 22 20.8532 22 19.44V16.0255C22 14.6114 20.8637 13.4655 19.4615 13.4655Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </span>
            </Button>
          </li>
          <li className="nav-item ms-2" role="presentation">
            <Button
              bsPrefix="nav-link btn btn-sm btn-icon rounded-pill active"
              id="list-view-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-list-view"
              to="#"
              role="tab"
              aria-controls="pills-list-view"
              aria-selected="false"
            >
              <span className="btn-inner">
                <svg
                  width="32"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M10.0833 15.958H3.50777C2.67555 15.958 2 16.6217 2 17.4393C2 18.2559 2.67555 18.9207 3.50777 18.9207H10.0833C10.9155 18.9207 11.5911 18.2559 11.5911 17.4393C11.5911 16.6217 10.9155 15.958 10.0833 15.958Z"
                    fill="currentColor"
                  ></path>
                  <path
                    opacity="0.4"
                    d="M22.0001 6.37867C22.0001 5.56214 21.3246 4.89844 20.4934 4.89844H13.9179C13.0857 4.89844 12.4102 5.56214 12.4102 6.37867C12.4102 7.1963 13.0857 7.86 13.9179 7.86H20.4934C21.3246 7.86 22.0001 7.1963 22.0001 6.37867Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M8.87774 6.37856C8.87774 8.24523 7.33886 9.75821 5.43887 9.75821C3.53999 9.75821 2 8.24523 2 6.37856C2 4.51298 3.53999 3 5.43887 3C7.33886 3 8.87774 4.51298 8.87774 6.37856Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M21.9998 17.3992C21.9998 19.2648 20.4609 20.7777 18.5609 20.7777C16.6621 20.7777 15.1221 19.2648 15.1221 17.3992C15.1221 15.5325 16.6621 14.0195 18.5609 14.0195C20.4609 14.0195 21.9998 15.5325 21.9998 17.3992Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </span>
            </Button>
          </li>
        </ul>
      </div>

      {!loading ? (
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {/* <CreateFolder /> */}
          {/* <Folder foldername="Image" foldersvg="1" img={img3} id="1" />
          <Folder foldername="Video" foldersvg="2" img={img2} id="2" />
          <Folder foldername="Documents" foldersvg="3" img={img1} id="3" />
          <Folder foldername="Audio" foldersvg="4" img={img9} id="4" />
          <Folder foldername="Movies" foldersvg="5" img={img2} id="5" />
          <Folder foldername="Assignment" foldersvg="6" img={img10} id="6" />
          <Folder foldername="Ui-Kit" foldersvg="7" img={img3} id="7" />
          <Folder foldername="Design" foldersvg="8" img={img3} id="8" />
          <Row className="row-cols-lg-4 row-cols-md-4 row-cols-1"> */}
          <Folder
            foldername="Infographic"
            foldersvg="1"
            img={img3}
            id="1"
            // bgColor='warning'
            redirection={"/content-library/category/infographic"}
          />
          <Folder
            foldername="Ad Board"
            foldersvg="2"
            img={img2}
            id="2"
            // bgColor='info'
            redirection={"/content-library/category/ad-board"}
          />
          <Folder
            foldername="Podcast"
            foldersvg="3"
            img={img1}
            id="3"
            // bgColor='danger'
            redirection={"/content-library/category/podcast"}
          />
          <Folder
            foldername="LBL"
            foldersvg="4"
            img={img9}
            id="4"
            // bgColor='success'
            redirection={"/content-library/category/lbl"}
          />
          <Folder
            foldername="HTML"
            foldersvg="5"
            img={img2}
            id="5"
            // bgColor='warning'
            redirection={"/content-library/category/html"}
          />
          <Folder
            foldername="Presentations"
            foldersvg="6"
            img={img10}
            id="6"
            // bgColor='secondary'
            redirection={"/content-library/category/presentations"}
          />
          {/* <Folder
              foldername="Ui-Kit"
              foldersvg="7"
              img={img3}
              id="7"
              // bgColor='info'
              redirection={"/content-library/category/etc"}
            />
            <Folder
              foldername="Design"
              foldersvg="8"
              img={img3}
              id="8"
              // bgColor='danger'
              redirection={"/content-library/category/etc"}
            /> */}
          {/* </Row> */}
          <Document
            imageDate="Dec 13, 2020"
            media="pdf"
            imgName="Doc-123472578.pdf"
            imgLink="just now"
            imgChange={pdf}
            imgMb="5mb"
            docType="PDF"
            docName="demo.pdf"
          ></Document>
          <Images
            imageDate="Dec 13, 2020"
            imgName="1234725783"
            imgLink="just now"
            imgChange={img1}
            id={6}
          ></Images>
          <Video
            imageDate="Dec 13, 2020"
            imgName="1234725783.mp4"
            imgLink="just now"
            imgChange={img1}
            id={6}
          ></Video>
          <Document
            imageDate="Dec 13, 2020"
            media="xlxs"
            imgName="Doc-25783.xlsx"
            imgLink="2 days ago"
            imgChange={excel}
            imgMb="2mb"
            docType="XLSX"
            docName="demo.xlsx"
          ></Document>
          <Document
            imageDate="Dec 13, 2020"
            media="word"
            imgName="abc-25783.doc"
            imgLink="a month ago"
            imgChange={word}
            imgMb="7mb"
            docType="DOCX"
            docName="demo.docx"
          ></Document>
        </Masonry>
      ) : (
        <Row className="row-cols-lg-4 row-cols-md-4 row-cols-1">
          <LibraryLoader />
        </Row>
      )}
    </Fragment>
  );
});

AllContent.displayName = "AllContent";
export default AllContent;
