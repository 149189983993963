import React, { memo, useEffect, useRef, useState } from "react";
import { Button, Row, Form, Table, Col, Card } from "react-bootstrap";
import ReactEmailEditor from "../../../../../lib/email-editor/react-email-editor";
import sample from "../../../../../lib/email-editor/sample.json";
import useRedirect from "../../../../../components/custom-hooks/use-redirect";
import { EMAIL_TEMPLATE_DETAILS_GET } from "../../../../../api/endpoints/template-management-endpoints";
import { getService } from "../../../../../api/services/get-services";
import { findIcon } from "../../../../dashboard/icons";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import GrapejsEditor from "../../../../../lib/email-editor/grapejs-editor";

const EmailTemplateDetails = () => {
  const { redirectTo } = useRedirect();

  const [loading, setLoading] = useState(false);
  const [isInPreviewMode, setIsInPreviwewMode] = useState(true);
  const [templateId, setTemplateId] = useState("");
  const [templateDetails, setTemplateDetails] = useState({});
  const emailEditorRef = useRef(null);
  const [templateContent, setTemplateContent] = useState("");
  const [presetHTML, setPresetHTML] = useState(null);
  const [editor, setEditor] = useState(null);
  const params = useParams();

  useEffect(() => {
    const id = params.id;
    setTemplateId(id);
    // console.log(id);
    fetchEmailTemplateDetails(id);
  }, []);

  const fetchEmailTemplateDetails = async (id) => {
    try {
      setLoading(true);
      const response = await getService(EMAIL_TEMPLATE_DETAILS_GET + `/${id}`);
      console.log("EMAIL_TEMPLATE_DETAILS_GET", response.data);
      if (response.data.success == true) {
        handleDetailsResponse(response);
      }
      if (response.isError) {
        toast.error(response?.error);
      }
    } catch (error) {
      setLoading(false);
      // console.log("TemplateField", error);
    }
  };

  const handleDetailsResponse = (response) => {
    setTemplateDetails(response.data.data);
    setLoading(false);
    let htmlContent = response.data.data?.email_content;
    let json_content = response.data.data?.email_json;
    setPresetHTML(htmlContent);
  };
  const onEditClick = () => {
    redirectTo("template-management/email-template/create/" + templateId);
  };
  const onCloseClick = () => {
    window.history.go(-1);
  };

  const onLoad = (json_content) => {
    return false;
    if (json_content) {
      emailEditorRef.current?.editor?.loadDesign(json_content);
    }
  };

  const onReady = () => {
    return false;
    emailEditorRef.current?.editor?.showPreview("desktop");
  };

  const togglePreview = () => {
    if (isInPreviewMode) {
      emailEditorRef.current?.editor?.hidePreview();
      setIsInPreviwewMode(false);
    } else {
      emailEditorRef.current?.editor?.showPreview("desktop");
      setIsInPreviwewMode(true);
    }
  };
  return (
    <>
      <Row>
        <Col lg="12">
          <Card>
            <Card.Body>
              <Row className="m-0">
                <Col
                  lg="12"
                  className="border border-1 rounded-3 p-0 d-flex flex-column"
                >
                  <Table>
                    <tbody>
                      {templateDetails?.created_by ? (
                        <tr>
                          <td>
                            <h6 className="mb-0">Created By</h6>
                          </td>
                          <td className="text-left">
                            {templateDetails?.created_by?.name}
                          </td>
                        </tr>
                      ) : null}
                      {templateDetails?.email_name ? (
                        <tr>
                          <td>
                            <h6 className="mb-0">Name</h6>
                          </td>
                          <td className="text-left">
                            {templateDetails.email_name}
                          </td>
                        </tr>
                      ) : null}
                      {templateDetails?.email_subject ? (
                        <tr>
                          <td>
                            <h6 className="mb-0">Subject</h6>
                          </td>
                          <td className="text-left">
                            {templateDetails.email_subject}
                          </td>
                        </tr>
                      ) : null}
                      {templateDetails?.approve ? (
                        <tr>
                          <td>
                            <h6 className="mb-0">Approve</h6>
                          </td>
                          <td
                            className={
                              templateDetails.approve?.value == "Disapproved"
                                ? "text-left text-danger"
                                : "text-left text-success"
                            }
                          >
                            {templateDetails.approve?.value}
                          </td>
                        </tr>
                      ) : null}
                      {templateDetails?.email_category_id ? (
                        <tr>
                          <td>
                            <h6 className="mb-0">Category</h6>
                          </td>
                          <td className="text-left">
                            {templateDetails.email_category_id?.category_name}
                          </td>
                        </tr>
                      ) : null}
                      {templateDetails?.email_from_email ? (
                        <tr>
                          <td>
                            <h6 className="mb-0">Sender_Email</h6>
                          </td>
                          <td className="text-left">
                            {templateDetails.email_from_email}
                          </td>
                        </tr>
                      ) : null}
                      {templateDetails?.email_from_name ? (
                        <tr>
                          <td>
                            <h6 className="mb-0">Sender_Name</h6>
                          </td>
                          <td className="text-left">
                            {templateDetails.email_from_name}
                          </td>
                        </tr>
                      ) : null}
                      {templateDetails?.email_template_type ? (
                        <tr>
                          <td>
                            <h6 className="mb-0">Template_Type</h6>
                          </td>
                          <td className="text-left">
                            {templateDetails.email_template_type?.value}
                          </td>
                        </tr>
                      ) : null}
                      {templateDetails?.status ? (
                        <tr>
                          <td>
                            <h6 className="mb-0">Status</h6>
                          </td>
                          <td
                            className={
                              templateDetails.status?.value == "Enabled"
                                ? "text-left text-success"
                                : "text-left text-danger"
                            }
                          >
                            {templateDetails.status?.value}
                          </td>
                        </tr>
                      ) : null}
                    </tbody>
                  </Table>
                  {presetHTML ? (
                    <Col lg={12}>
                      <h6 className="mb-2 px-4">Content</h6>
                      <GrapejsEditor
                        ref={emailEditorRef}
                        onReady={onReady}
                        onLoad={onLoad}
                        previewMode
                        presetData={presetHTML}
                        setEditor={(e) => setEditor(e)}
                        editor={editor}
                      />
                    </Col>
                  ) : null}
                </Col>
                <div className="d-flex justify-content-between py-4">
                  <Button variant="secondary" onClick={onCloseClick}>
                    {findIcon("Arrow Left", "outline", "32")}
                  </Button>
                  <Button variant="primary" onClick={onEditClick}>
                    Edit
                  </Button>
                </div>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default EmailTemplateDetails;
