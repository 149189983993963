import Skeleton from "../../components/skeleton";
import { Fragment } from "react";

//Componets

import {
  OverlayTrigger,
  Card,
  Tooltip,
  Dropdown,
  Button,
} from "react-bootstrap";

const MycontentLoader = () => {
  return (
    <Fragment>
      <ul
        class="tree list-unstyled flex-grow-1 custom-TreeView overflow-y-scroll"
        role="tree"
        aria-multiselectable="false"
        aria-label="Checkbox tree"
      >
        <li role="treeitem" class="tree-branch-wrapper">
          <div class="tree-node tree-node__branch">
            <div>
              <span class="folder-icon position-absolute top-50 translate-middle-y">
                <Skeleton variant="rectangular" width={15} height={15} />
              </span>
              <span class="down-arrow-icon position-absolute transition top-50 translate-middle-y">
                <Skeleton variant="rectangular" width={15} height={15} />
              </span>
              <Skeleton variant="text" width={"50%"} height={30} />
            </div>
          </div>
          <ul role="group" class="tree-node-group"></ul>
        </li>
        <li role="treeitem" class="tree-branch-wrapper">
          <div class="tree-node tree-node__branch">
            <div>
              <span class="folder-icon position-absolute top-50 translate-middle-y">
                <Skeleton variant="rectangular" width={15} height={15} />
              </span>
              <span class="down-arrow-icon position-absolute transition top-50 translate-middle-y">
                <Skeleton variant="rectangular" width={15} height={15} />
              </span>
              <Skeleton variant="text" width={"50%"} height={30} />
            </div>
          </div>
          <ul role="group" class="tree-node-group"></ul>
        </li>
        <li role="treeitem" class="tree-branch-wrapper">
          <div class="tree-node tree-node__branch">
            <div>
              <span class="folder-icon position-absolute top-50 translate-middle-y">
                <Skeleton variant="rectangular" width={15} height={15} />
              </span>
              <span class="down-arrow-icon position-absolute transition top-50 translate-middle-y">
                <Skeleton variant="rectangular" width={15} height={15} />
              </span>
              <Skeleton variant="text" width={"50%"} height={30} />
            </div>
          </div>
          <ul role="group" class="tree-node-group"></ul>
        </li>
        <li role="treeitem" class="tree-branch-wrapper">
          <div class="tree-node tree-node__branch">
            <div>
              <span class="folder-icon position-absolute top-50 translate-middle-y">
                <Skeleton variant="rectangular" width={15} height={15} />
              </span>
              <span class="down-arrow-icon position-absolute transition top-50 translate-middle-y">
                <Skeleton variant="rectangular" width={15} height={15} />
              </span>
              <Skeleton variant="text" width={"50%"} height={30} />
            </div>
          </div>
          <ul role="group" class="tree-node-group"></ul>
        </li>
        <li role="treeitem" class="tree-branch-wrapper">
          <div class="tree-node tree-node__branch">
            <div>
              <span class="folder-icon position-absolute top-50 translate-middle-y">
                <Skeleton variant="rectangular" width={15} height={15} />
              </span>
              <span class="down-arrow-icon position-absolute transition top-50 translate-middle-y">
                <Skeleton variant="rectangular" width={15} height={15} />
              </span>
              <Skeleton variant="text" width={"50%"} height={30} />
            </div>
          </div>
          <ul role="group" class="tree-node-group"></ul>
        </li>
        <li role="treeitem" class="tree-branch-wrapper">
          <div class="tree-node tree-node__branch">
            <div>
              <span class="folder-icon position-absolute top-50 translate-middle-y">
                <Skeleton variant="rectangular" width={15} height={15} />
              </span>
              <span class="down-arrow-icon position-absolute transition top-50 translate-middle-y">
                <Skeleton variant="rectangular" width={15} height={15} />
              </span>
              <Skeleton variant="text" width={"50%"} height={30} />
            </div>
          </div>
          <ul role="group" class="tree-node-group"></ul>
        </li>
        <li role="treeitem" class="tree-branch-wrapper">
          <div class="tree-node tree-node__branch">
            <div>
              <span class="folder-icon position-absolute top-50 translate-middle-y">
                <Skeleton variant="rectangular" width={15} height={15} />
              </span>
              <span class="down-arrow-icon position-absolute transition top-50 translate-middle-y">
                <Skeleton variant="rectangular" width={15} height={15} />
              </span>
              <Skeleton variant="text" width={"50%"} height={30} />
            </div>
          </div>
          <ul role="group" class="tree-node-group"></ul>
        </li>
        <li role="treeitem" class="tree-branch-wrapper">
          <div class="tree-node tree-node__branch">
            <div>
              <span class="folder-icon position-absolute top-50 translate-middle-y">
                <Skeleton variant="rectangular" width={15} height={15} />
              </span>
              <span class="down-arrow-icon position-absolute transition top-50 translate-middle-y">
                <Skeleton variant="rectangular" width={15} height={15} />
              </span>
              <Skeleton variant="text" width={"50%"} height={30} />
            </div>
          </div>
          <ul role="group" class="tree-node-group"></ul>
        </li>
        <li role="treeitem" class="tree-branch-wrapper">
          <div class="tree-node tree-node__branch">
            <div>
              <span class="folder-icon position-absolute top-50 translate-middle-y">
                <Skeleton variant="rectangular" width={15} height={15} />
              </span>
              <span class="down-arrow-icon position-absolute transition top-50 translate-middle-y">
                <Skeleton variant="rectangular" width={15} height={15} />
              </span>
              <Skeleton variant="text" width={"50%"} height={30} />
            </div>
          </div>
          <ul role="group" class="tree-node-group"></ul>
        </li>
        <li role="treeitem" class="tree-branch-wrapper">
          <div class="tree-node tree-node__branch">
            <div>
              <span class="folder-icon position-absolute top-50 translate-middle-y">
                <Skeleton variant="rectangular" width={15} height={15} />
              </span>
              <span class="down-arrow-icon position-absolute transition top-50 translate-middle-y">
                <Skeleton variant="rectangular" width={15} height={15} />
              </span>
              <Skeleton variant="text" width={"50%"} height={30} />
            </div>
          </div>
          <ul role="group" class="tree-node-group"></ul>
        </li>
      </ul>
    </Fragment>
  );
};

export default MycontentLoader;
