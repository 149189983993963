import { useState, memo, Fragment } from "react";

//React-bootstrap
import { Image, Dropdown, Form, FormCheck, Button } from "react-bootstrap";

//Router
import { Link } from "react-router-dom";

//Components
import Card from "../../../../components/bootstrap/card";
import { findIcon } from "../../../dashboard/icons";
import article_1 from "../../../../assets/modules/file-manager/images/article_1.jpg";
import dayjs from "dayjs";
import FilePreviewModal from "../../../../components/partials/common/file-preview-modal";
import { getService } from "../../../../api/services/get-services";
import { CONTENT_DETAILS_GET } from "../../../../api/endpoints/content-endpoints";
import app_config from "../../../../common/data/app_config";
import toast from "react-hot-toast";
const ContentCard = ({
  extraClass,
  id,
  src,
  date,
  title,
  type,
  onSelect,
  checked = false,
  icon = "",
}) => {
  const fileTypes = [
    "pdf",
    "doc",
    "article",
    "audio",
    "zip",
    "html",
    "ppt",
    "email",
    "docx",
    "video",
  ];
  //format date with dayjs
  const formatDate = (date) => {
    return dayjs(date).format("DD MMM YYYY");
  };
  const [showFilePreview, setShowFilePreview] = useState(false);
  const [fileData, setFileData] = useState({});
  const onClickContent = async () => {
    let response = await getService(CONTENT_DETAILS_GET + id);
    if (response.isError) {
      console.log(response);
    } else {
      console.log("CONTENT_RESPONSE", response);
      let fileResponse = response.data.data.detail;
      if (fileResponse?.storage_link || fileResponse?.path) {
        setFileData(fileResponse);
        setShowFilePreview(true);
      } else {
        toast.error("File not found");
      }
    }
  };
  return (
    <Fragment>
      <Card className="position-relative overflow-hidden">
        <Card.Body className="p-3">
          {onSelect && typeof onSelect === "function" && (
            <div className="position-absolute avatar-60 lh-1 fileselect-check-box">
              <Form.Check className="m-0 p-0 w-100 h-100 rounded-circle overflow-hidden form-check">
                <span className="position-absolute translate-middle top-50 start-50  text-secondary check-mark">
                  {findIcon("Check", "outline", "16")}
                </span>

                <FormCheck.Input
                  type="checkbox"
                  className=""
                  id="exampleCheck1"
                  onChange={() => onSelect(id)}
                  checked={checked}
                />
              </Form.Check>
            </div>
          )}
          {!(type == "image" || type == "video") ? (
            <>
              {/* for doc , xmlx and pdf */}
              <div className="p-3 d-flex justify-content-center align-items-center rounded bg-light">
                <Image
                  src={src || icon || article_1}
                  className="img-fluid icon-60"
                  alt=""
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = article_1;
                  }}
                />
              </div>
            </>
          ) : (
            <>
              {/* for image and video */}
              <div className="p-3 d-flex justify-content-center align-items-center rounded bg-light">
                <Image
                  src={src || icon || article_1}
                  className="img-fluid icon-60"
                  alt=""
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = article_1;
                  }}
                />
              </div>
            </>
          )}

          <div className="mt-3">
            <div className="d-flex align-items-start mb-2 gap-2">
              <div className="avatar-36 bg-soft-primary lh-1 d-flex align-items-center justify-content-center flex-shrink-0">
                {type == "image"
                  ? findIcon("Photograph", "dual-tone", "24")
                  : type == "video"
                  ? findIcon("Start", "dual-tone", "24")
                  : findIcon("Document", "dual-tone", "24")}
              </div>
              <div className="flex-grow-1 fs-6 text-truncate">
                <h5 className="text-dark mb-1 small text-truncate">
                  {title || "Title"}
                </h5>

                <p className="fs-7 mb-0 lh-sm">
                  {date ? dayjs(date).format("DD MMM YYYY") : ""}
                </p>
              </div>
            </div>

            <div className="d-flex justify-content-between align-items-center mt-3">
              {/* <span className="small mb-0 me-2 text-info">{props.imgMb}</span> */}
              <div className="d-flex justify-content-end align-items-center gap-2 ms-auto">
                <Button
                  variant="outline-primary"
                  className="p-0 icon-32 d-flex align-items-center justify-content-center rounded-circle"
                  onClick={onClickContent}
                >
                  {findIcon("Eye", "solid", "16")}
                </Button>
                {/* <Button
                  variant="primary"
                  className="p-0 icon-32 d-flex align-items-center justify-content-center rounded-circle"
                  onClick={() => window.open(deeplink, "_blank")}
                >
                  {findIcon("Arrow Right", "solid", "16")}
                </Button> */}
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
      {(fileData?.storage_link || fileData.path) && (
        <FilePreviewModal
          mediaType={type}
          show={showFilePreview}
          viewModalClose={() => setShowFilePreview(false)}
          title={title}
          file_url={
            app_config.fileStoragePath +
            (fileData?.storage_link || fileData.path)
          }
        />
      )}
    </Fragment>
  );
};

ContentCard.displayName = "ContentCard";
export default memo(ContentCard);
