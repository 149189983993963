import React, { useState } from "react";
import { Button, Col, Form, FormCheck, Modal, Row } from "react-bootstrap";
import Select from "react-select";

const OnboardingLogModal = ({ show, modalClose }) => {
  const [step, setStep] = useState({ slide: 1, type: "" });
  // const [typeSelected, setTypeSelected] = useState("");
  // const [phoneType, setPhoneType] = useState("");
  // const options = [
  //   { value: "Email", label: "Email" },
  //   { value: "Whatsapp", label: "Whatsapp" },
  //   { value: "Phone", label: "Phone" },
  //   { value: "Meeting", label: "Meeting" },
  // ];
  // const whatsappStatus = [
  //   { value: "Delivered", label: "Delivered" },
  //   { value: "Sent", label: "Sent" },
  //   { value: "Seen", label: "Seen" },
  // ];
  // const phoneStatus = [
  //   { value: "Connected", label: "Connected" },
  //   { value: "Not connected", label: "Not connected" },
  //   { value: "Call back", label: "Call back" },
  //   { value: "DNP", label: "DNP" },
  // ];
  //   type=> email,wp,phone,meeting
  //
  return (
    <>
      <Modal
        show={show}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Please Confirm
          </Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          {/* <Row className="mb-4">
            <Col lg="2">Type</Col>
            <Col lg="10">
              <Select
                name="Template"
                options={options}
                placeholder="Select status"
                onChange={(e) => setTypeSelected(e.value)}
              />
            </Col>
          </Row>
          {(typeSelected == "Whatsapp" || typeSelected == "Phone") && (
            <Row>
              <Col lg="2">Status</Col>
              <Col lg="10">
                <Select
                  name="Template"
                  options={
                    typeSelected == "Whatsapp" ? whatsappStatus : phoneStatus
                  }
                  placeholder="Select status"
                  onChange={(e) => setPhoneType(e.value)}
                />
              </Col>
            </Row>
          )}
          {phoneType == "Call back" && (
            <Row className="mt-4">
              <Col lg="12">
                <Form.Control type="datetime-local"/>
              </Col>
            </Row>
          )} */}
          {step.slide == 1 && (
            <div className="d-flex justify-content-center gap-2">
              <Button onClick={() => setStep({ slide: 2, type: "recived" })}>
                Received Consent{" "}
              </Button>
              <Button
                type="button"
                variant="danger"
                onClick={() => setStep({ slide: 2, type: "notRecived" })}
              >
                Couldn't Contact
              </Button>
            </div>
          )}
          {step.type == "recived" && (
            <Form.Check className="mb-3 form-check">
              <FormCheck.Input
                type="checkbox"
                className=""
                id="exampleCheck1"
              />
              <FormCheck.Label className="" htmlFor="exampleCheck1">
                I have recived the concent from the doctor
              </FormCheck.Label>
            </Form.Check>
          )}
          {step.type == "notRecived" && (
            <Form.Check className="mb-3 form-check">
              <FormCheck.Input
                type="checkbox"
                className=""
                id="exampleCheck1"
              />
              <FormCheck.Label className="" htmlFor="exampleCheck1">
                Send Consent message through WhatsApp
              </FormCheck.Label>
            </Form.Check>
          )}
        </Modal.Body>
        {step.slide == 2 && (
          <Modal.Footer>
            <Button
              onClick={() => {
                modalClose();
                setStep({ slide: 1, type: "" });
              }}
            >
              {step.type == "recived" ? 'Submit' : 'Await for Response'}
            </Button>
            {/* <Button
              type="button"
              variant="danger"
              onClick={() => {
                modalClose();
                setStep({ slide: 1, type: "" });
              }}
            >
              Submit
            </Button> */}
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

export default OnboardingLogModal;
