import * as yup from "yup";

const addPPTInitialValues = {
	file: null,
	comment: "",
	title: "",
	title_html: "",
	body: "",
	body_html: "",
	brand: "",
	thumbnail: null,
	base: null,
};

const addPPTSchema = yup.object().shape({
	file: yup.mixed().required("Please add a PPT"),
	title: yup.string().trim().required("Please Enter a title"),
	body: yup.string().trim().required("Please Enter a body"),
	brand: yup.string().trim().required("Please select the brand"),
	thumbnail: yup.mixed().required("Please add a Thumbnail"),
	base: yup.mixed().required("Please add a Cover Photo"),
});

const addPDFInitialValues = {
	file: null,
	comment: "",
	title: "",
	title_html: "",
	body: "",
	body_html: "",
	brand: "",
	thumbnail: null,
	base: null,
};

const addPDFSchema = yup.object().shape({
	file: yup.mixed().required("Please add a PDF"),
	title: yup.string().trim().required("Please Enter a title"),
	body: yup.string().trim().required("Please Enter a body"),
	brand: yup.string().trim().required("Please select the brand"),
	thumbnail: yup.mixed().required("Please add a Thumbnail"),
	base: yup.mixed().required("Please add a Cover Photo"),
});

const addFileInitialValues = {
	file: null,
	comment: "",
	title: "",
	title_html: "",
	body: "",
	body_html: "",
	brand: "",
	thumbnail: null,
	base: null,
};

const addFileSchema = yup.object().shape({
	file: yup.mixed().required("Please add a File"),
	title: yup.string().trim().required("Please Enter a title"),
	body: yup.string().trim().required("Please Enter a body"),
	brand: yup.string().trim().required("Please select the brand"),
	thumbnail: yup.mixed().required("Please add a Thumbnail"),
	base: yup.mixed().required("Please add a Cover Photo"),
});

const adddocxInitialValues = {
	file: null,
	comment: "",
	title: "",
	title_html: "",
	body: "",
	body_html: "",
	brand: "",
	thumbnail: null,
	base: null,
};

const adddocxSchema = yup.object().shape({
	file: yup.mixed().required("Please add a DOCX file"),
	title: yup.string().trim().required("Please Enter a title"),
	body: yup.string().trim().required("Please Enter a body"),
	brand: yup.string().trim().required("Please select the brand"),
	thumbnail: yup.mixed().required("Please add a Thumbnail"),
	base: yup.mixed().required("Please add a Cover Photo"),
});

const addzipInitialValues = {
	file: null,
	comment: "",
	title: "",
	title_html: "",
	body: "",
	body_html: "",
	brand: "",
	thumbnail: null,
	base: null,
};

const addzipSchema = yup.object().shape({
	file: yup.mixed().required("Please add a ZIP file"),
	title: yup.string().trim().required("Please Enter a title"),
	body: yup.string().trim().required("Please Enter a body"),
	brand: yup.string().trim().required("Please select the brand"),
	thumbnail: yup.mixed().required("Please add a Thumbnail"),
	base: yup.mixed().required("Please add a Cover Photo"),
});

export {
	addPDFInitialValues,
	addPDFSchema,
	addPPTInitialValues,
	addPPTSchema,
	addFileInitialValues,
	addFileSchema,
	adddocxInitialValues,
	adddocxSchema,
	addzipInitialValues,
	addzipSchema,
};
