import React, { useEffect, useCallback } from "react";
import { findIcon } from "../views/dashboard/icons";
import { Col, Form, Button, Modal, Row } from "react-bootstrap";
import { useState } from "react";
import { FILTER_TYPES, FILTER_VALUE_SEPARATOR } from "../lib/table/constants";
import { useDispatch } from "react-redux";
import { addFilter } from "../store/react-table/react-table.action";
import toast from "react-hot-toast";
import useFilterBarErrors from "./custom-hooks/use-filter-bar-errors";

const FilterBar = ({ inputs, advancedFilters }) => {
  const dispatch = useDispatch();

  const [input, setInput] = useState(inputs);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [isAdvancedSearchActive, setIsAdvancedSearchActive] = useState(false);
  const openAdvancedSearch = () => setIsAdvancedSearchActive(true);
  const closeAdvancedSearch = () => setIsAdvancedSearchActive(false);
  // useFilterBarErrors(input);
  const handleFilterChange = useCallback(
    (e, operator = "") => {
      setInput((prev) => {
        return prev.map((item) => {
          if (item.name === e.target.name) {
            return { ...item, value: e.target.value, operator: operator };
          } else {
            return item;
          }
        });
      });
    },
    [input]
  );
  const toggleFilter = () => setIsFilterActive(!isFilterActive);
  const onSearchBtnClick = () => {
    //check in the input array if all the values are empty then return
    if (input.every((item) => !item.value)) {
      toast.error("Please enter atleast one filter value");
      return;
    }
    //change the input to the react table filter format

    let filter = [];
    input.forEach((item) => {
      if (item.value) {
        filter.push({
          id: item.name,
          value: item.operator
            ? item.operator + FILTER_VALUE_SEPARATOR + item.value
            : item.value,
        });
      }
    });

    console.log("filter", filter);
    dispatch(addFilter(filter));
  };
  const onResetClick = () => {
    setInput((prev) => {
      return prev.map((item) => {
        return { ...item, value: "" };
      });
    });
    setTimeout(() => {
      console.log("set Input", (prev) => {
        return prev.map((item) => {
          return { ...item, value: "" };
        });
      });
    }, 5000);
  };
  const onAdvancedSearchBtnClick = () => {
    if (isAdvancedSearchActive) {
      closeAdvancedSearch();
    } else {
      openAdvancedSearch();
    }
  };
  const onApplyAdvancedFiltersClick = (filter) => {
    console.log("filter", filter);
    dispatch(addFilter(filter));
    closeAdvancedSearch();
  };

  return (
    <>
      <Col className="d-flex justify-content-between flex-column flex-lg-row align-items-center">
        <div className="form-group input-group search-input mb-3 mb-lg-0 w-auto">
          {/* <input
            name="search"
            type="search"
            className="form-control"
            placeholder="Search Template..."
          />
          <span className="input-group-text">
            {findIcon("Search", "outline", "20")}
          </span> */}
        </div>
        <div className="ms-lg-auto">
          <div className="d-flex justify-content-between align-items-center px-md-2 ms-auto gap-2">
            <Button
              type="button"
              variant="outline-info"
              className="d-flex justify-content-center align-items-center gap-2 position-relative"
              onClick={toggleFilter}
            >
              <span
                className={`notification-alert ${
                  !isFilterActive ? "d-none" : ""
                }`}
              ></span>
              {findIcon("Filter", "dual-tone", "18")}
              Filter
            </Button>{" "}
            <Button variant={"outline-primary"} onClick={onResetClick}>
              {findIcon("Refresh", "dual-tone", "18")}
            </Button>
          </div>
        </div>
      </Col>
      {isFilterActive && (
        <div className="col-12 border bordr-1 p-4 rounded-3 mt-4">
          <div className="d-flex flex-row mb-5">
            {input.map((item, index) => {
              const {
                name = "",
                value = "",
                type = "",
                placeholder = "",
                label = "",
                operator = "",
                Component,
                options = [],
                ...rest
              } = item;
              return (
                <Form.Floating
                  className="custom-form-floating mb-0 flex-grow-1 me-2"
                  key={index + 2}
                >
                  <FormElement
                    item={item}
                    handleFilterChange={handleFilterChange}
                  />
                  {item.type !== "checkbox" && item.type !== "radio" && (
                    <label htmlFor={item.name}>{item?.label ?? ""}</label>
                  )}
                </Form.Floating>
              );
            })}
          </div>

          <div className="d-flex flex-row-reverse mt-3">
            <Button variant="primary" onClick={onSearchBtnClick}>
              Search
            </Button>{" "}
            <Button
              variant="info"
              className="me-3"
              onClick={onAdvancedSearchBtnClick}
            >
              Open Advanced Search
            </Button>
          </div>
        </div>
      )}
      <AdvancedSearchModal
        advancedFilters={advancedFilters}
        show={isAdvancedSearchActive}
        onHide={closeAdvancedSearch}
        onApplyFilterClick={onApplyAdvancedFiltersClick}
      />
    </>
  );
};

export default FilterBar;
function FormElement({ item, handleFilterChange }) {
  const {
    name = "",
    value = "",
    type = "",
    placeholder = "",
    label = "",
    operator = "",
    Component,
    options = [],
    ...rest
  } = item;
  return (
    <>
      {item.Component ? (
        <item.Component
          name={name}
          value={value || ""}
          onChange={(e) => handleFilterChange(e, operator)}
          {...rest}
        />
      ) : type === FILTER_TYPES.SELECT ? (
        <Form.Select
          name={name}
          onChange={(e) => handleFilterChange(e, operator)}
          value={value || ""}
          {...rest}
        >
          <option defaultValue="">-- Select Option--</option>
          {options.map((option, index) => (
            <option value={option.value} key={option.label}>
              {option.label}
            </option>
          ))}
        </Form.Select>
      ) : type === FILTER_TYPES.RANGE ? (
        <Form.Range
          name={name}
          value={value || ""}
          type={type}
          placeholder={placeholder}
          onChange={(e) => handleFilterChange(e, operator)}
          {...rest}
        />
      ) : type === FILTER_TYPES.RADIO ? (
        <div className="d-flex">
          {options.map((option, index) => (
            <div
              className={index !== options.length - 1 ? "me-3" : ""}
              key={option.label}
            >
              <Form.Check
                name={name}
                value={option.value}
                type={type}
                placeholder={placeholder}
                label={option.label}
                checked={option.value === value}
                onChange={(e) => handleFilterChange(e, operator)}
                {...rest}
              />
            </div>
          ))}
        </div>
      ) : (
        <Form.Control
          type={type}
          name={name}
          value={value || ""}
          placeholder={placeholder}
          onChange={(e) => handleFilterChange(e, operator)}
          {...rest}
        />
      )}
    </>
  );
}
function AdvancedSearchModal({
  show,
  onHide,
  advancedFilters,
  onApplyFilterClick,
}) {
  const [advancedSearch, setAdvancedSearch] = useState([]);
  const handleRemoveRow = (index) => {
    //at least one row should be there
    if (advancedSearch.length === 1) {
      toast.error("Atleast one row should be there");
      return;
    }
    //remove the row from the advancedSearch array
    setAdvancedSearch((prev) => {
      return prev.filter((item, i) => i !== index);
    });
  };
  const handleAddRow = () => {
    //add a new row to the advancedSearch array
    let newSearchElement = [];
    newSearchElement.push({
      name: "label_name",
      type: FILTER_TYPES.SELECT,
      placeholder: "Enter Label Name",
      label: "Label Name",
      options: advancedFilters?.map((item) => ({
        label: item.label,
        value: item.name,
      })),
    });
    setAdvancedSearch((prev) => [...prev, newSearchElement]);
  };
  const handleApplySearch = () => {
    // create the filter array and dispatch addFilter action
    let filter = [];
    advancedSearch.forEach((item) => {
      if (item[0].value && item[1].value && item[2].value) {
        filter.push({
          id: item[0].value,
          value: item[1].value + FILTER_VALUE_SEPARATOR + item[2].value,
        });
      } else {
        toast.error("Please enter all the values");
        return;
      }
    });
    console.log("filter", filter);
    onApplyFilterClick(filter);
  };

  useEffect(() => {
    let firstElement = advancedFilters?.length > 0 ? advancedFilters[0] : [];
    let firstSearchElement = [];
    if (firstElement?.length > 0) {
      firstSearchElement.push({
        name: "label_name",
        type: FILTER_TYPES.SELECT,
        placeholder: "Enter Label Name",
        label: "Label Name",
        options: advancedFilters?.map((item) => ({
          label: item.label,
          value: item.name,
        })),
      });
      setAdvancedSearch([firstSearchElement]);
    }
  }, [advancedFilters]);
  const handleLabelNameChange = (e, index) => {
    let filterElement = advancedFilters.find(
      (item) => item.name === e.target.value
    );
    console.log("filterElement", filterElement);
    console.log("index", index);

    setAdvancedSearch((prev) => {
      return prev.map((item, i) => {
        if (i === index) {
          return [
            {
              name: "label_name",
              type: FILTER_TYPES.SELECT,
              placeholder: "Enter Label Name",
              label: "Label Name",
              options: advancedFilters?.map((item) => ({
                label: item.label,
                value: item.name,
              })),
              value: e.target.value,
            },
            {
              name: "operator",
              type: FILTER_TYPES.SELECT,
              placeholder: "Enter Operator",
              label: "Operator",
              options: filterElement?.operators.map((item) => ({
                label: item.label,
                value: item.value,
              })),
              value: item[1]?.value || "",
            },
            {
              name: "value",
              type: FILTER_TYPES.TEXT,
              placeholder: "Enter Value",
              label: "Value",
              value: item[2]?.value || "",
            },
          ];
        } else {
          return item;
        }
      });
    });
  };
  const handleOperatorChange = (e, index) => {
    setAdvancedSearch((prev) => {
      return prev.map((item, i) => {
        if (i === index) {
          return [
            {
              name: "label_name",
              type: FILTER_TYPES.SELECT,
              placeholder: "Enter Label Name",
              label: "Label Name",
              options: advancedFilters?.map((item) => ({
                label: item.label,
                value: item.name,
              })),
              value: item[0]?.value || "",
            },
            {
              name: "operator",
              type: FILTER_TYPES.SELECT,
              placeholder: "Enter Operator",
              label: "Operator",
              options: item[1].options,
              value: e.target.value,
            },
            {
              name: "value",
              type: FILTER_TYPES.TEXT,
              placeholder: "Enter Value",
              label: "Value",
              value: item[2]?.value || "",
            },
          ];
        } else {
          return item;
        }
      });
    });
  };
  const handleValueChange = (e, index) => {
    setAdvancedSearch((prev) => {
      return prev.map((item, i) => {
        if (i === index) {
          return [
            {
              name: "label_name",
              type: FILTER_TYPES.SELECT,
              placeholder: "Enter Label Name",
              label: "Label Name",
              options: advancedFilters?.map((item) => ({
                label: item.label,
                value: item.name,
              })),
              value: item[0]?.value ?? "",
            },
            {
              name: "operator",
              type: FILTER_TYPES.SELECT,
              placeholder: "Enter Operator",
              label: "Operator",
              options: item[1]?.options ?? [],
              value: item[1]?.value ?? "",
            },
            {
              name: "value",
              type: FILTER_TYPES.TEXT,
              placeholder: "Enter Value",
              label: "Value",
              value: e.target.value,
            },
          ];
        } else {
          return item;
        }
      });
    });
  };

  const isOperatorDisabled = (index) => {
    let labelName = advancedSearch[index][0]?.value ?? "";
    let filterElement = advancedFilters.find((item) => item.name === labelName);
    return !filterElement?.operators?.length;
  };
  const isValueDisabled = (index) => {
    let labelName = advancedSearch[index][0]?.value ?? "";
    let operator = advancedSearch[index][1]?.value ?? "";
    return !labelName || !operator;
  };

  const isOperatorOptionDisabled = (index, option) => {
    // if any row above the current row has the same operator selected with same label name
    // then disable the option
    let labelName = advancedSearch[index][0]?.value ?? "";
    let operator = option.value;
    let isDisabled = false;
    advancedSearch.forEach((item, i) => {
      if (i < index) {
        if (item[0]?.value === labelName && item[1]?.value === operator) {
          isDisabled = true;
        }
      }
    });
    return isDisabled;
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>Apply Searches</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {advancedSearch.map((row, index) => {
            console.log("row", row);
            const [labelNameItem = {}, operatorItem = {}, valueItem = {}] = row;

            return (
              <Row key={index} className="mb-3">
                <Form.Floating
                  className="custom-form-floating mb-2 flex-grow-1 col"
                  key="labelNameItem"
                >
                  <Form.Select
                    name={labelNameItem?.name ?? ""}
                    onChange={(e) => handleLabelNameChange(e, index)}
                    value={labelNameItem?.value || ""}
                    disabled={!labelNameItem?.name}
                    {...labelNameItem}
                  >
                    <option defaultValue="">-- Select Option--</option>
                    {labelNameItem?.options?.length > 0 &&
                      labelNameItem?.options.map((option, _index) => (
                        <option value={option.value} key={option.label}>
                          {option.label}
                        </option>
                      ))}
                  </Form.Select>

                  <label htmlFor={labelNameItem?.name ?? ""}>
                    {labelNameItem?.label ?? ""}
                  </label>
                </Form.Floating>

                <Form.Floating
                  className="custom-form-floating mb-2 flex-grow-1 col"
                  key="operatorItem"
                >
                  <Form.Select
                    name={operatorItem?.name ?? ""}
                    onChange={(e) => handleOperatorChange(e, index)}
                    value={operatorItem?.value || ""}
                    disabled={isOperatorDisabled(index)}
                    {...operatorItem}
                  >
                    <option defaultValue="">-- Select Option--</option>
                    {operatorItem?.options?.length > 0 &&
                      operatorItem?.options?.map((option, _index) => (
                        <option
                          value={option?.value}
                          key={option?.label}
                          disabled={isOperatorOptionDisabled(index, option)}
                        >
                          {option?.label}
                        </option>
                      ))}
                  </Form.Select>

                  <label htmlFor={operatorItem?.name}>
                    {operatorItem?.label ?? ""}
                  </label>
                </Form.Floating>

                <Form.Floating
                  className="custom-form-floating mb-2 flex-grow-1 col"
                  key="valueItem"
                >
                  <Form.Control
                    name={valueItem?.name}
                    onChange={(e) => handleValueChange(e, index)}
                    value={valueItem?.value || ""}
                    disabled={isValueDisabled(index)}
                    {...valueItem}
                  />

                  <label htmlFor={valueItem?.name}>
                    {valueItem?.label ?? ""}
                  </label>
                </Form.Floating>

                <a
                  className="d-flex align-items-center text-danger col justify-content-center"
                  onClick={() => handleRemoveRow(index)}
                >
                  <span className="btn-inner">
                    {findIcon("Trash", "dual-tone", "36", "text-danger")}
                  </span>
                </a>
              </Row>
            );
          })}
        </Form>
        <Button variant="primary" onClick={handleAddRow}>
          Add Row {findIcon("Plus", "dual-tone", "18")}
        </Button>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={handleApplySearch}>
          Apply
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
