import { useState, memo, Fragment } from "react";

//React-bootstrap
import { Image, Dropdown } from "react-bootstrap";

//Router
import { Link } from "react-router-dom";

//Components
import Card from "../../../../components/bootstrap/card";

// Fsloghtbox
import FsLightbox from "fslightbox-react";

//Sweet alert
import Swal from "sweetalert2";

// Img
import img1 from "../../../../assets/modules/file-manager/images/8.png";
import img2 from "../../../../assets/modules/file-manager/images/7.png";
import img3 from "../../../../assets/modules/file-manager/images/2.png";
import img4 from "../../../../assets/modules/file-manager/images/1.png";
import img5 from "../../../../assets/modules/file-manager/images/6.png";
import img6 from "../../../../assets/modules/file-manager/images/5.png";
import img7 from "../../../../assets/modules/file-manager/images/4.png";

const Images = memo((props) => {
  const [imageController, setImageController] = useState({
    toggler: false,
    slide: 1,
  });

  function imageOnSlide(number) {
    setImageController({
      toggler: !imageController.toggler,
      slide: number,
    });
  }
  function Sweetalert() {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "You want to restore this item",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Restore!", "Your item has been restore.", "success");
      }
    });
  }
  function Sweetalert1() {
    Swal.fire({
      icon: "error",
      title: "Are you sure?",
      text: "You want to delete this item",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  }

  return (
    <Fragment>
      <FsLightbox
        toggler={imageController.toggler}
        sources={[
          img1,
          img2,
          img3,
          img4,
          img5,
          img6,
          img7,
          img1,
          img6,
          img5,
          img2,
          img1,
          img4,
          img3,
          img1,
          img7,
        ]}
        slide={imageController.slide}
      />
      <Card className="iq-file-manager">
        <Card.Body className="card-thumbnail">
          <Link onClick={() => imageOnSlide(props.id)} to="#">
            <Image src={props.imgChange} className="img-fluid" alt="" />
          </Link>
        </Card.Body>
      </Card>
    </Fragment>
  );
});

Images.displayName = "Images";
export default Images;
