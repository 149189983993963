import { useState, useContext, memo, Fragment } from "react";

//Router
import { Link, useLocation } from "react-router-dom";

//React-bootstrap
import {
  Accordion,
  useAccordionButton,
  AccordionContext,
  Nav,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";

//Componets
import SidebarMenu from "../../components/sidebar/sidebar-menu";
import { findIcon } from "../../../../views/dashboard/icons";
import { useGetMenuList } from "../../../../queries/query-hooks/common-hook";
import MenuSkeleton from "../../../../skeleton/menu/menu-skeleton";

function CustomToggle({ children, eventKey, onClick }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, (active) =>
    onClick({ state: !active, eventKey: eventKey })
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <div
      aria-expanded={isCurrentEventKey ? "true" : "false"}
      className="nav-link"
      role="button"
      onClick={(e) => {
        decoratedOnClick(isCurrentEventKey);
      }}
    >
      {children}
    </div>
  );
}

const VerticalNav = memo(() => {
  const [activeMenu, setActiveMenu] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [active, setActive] = useState("");

  const { data: menus, isLoading: isMenuFetching } = useGetMenuList();

  //location
  let location = useLocation();

  const customToogleClick = (activeKey) => {
    setActiveMenu(activeKey);
  };

  const onAccordianClick = (accordian_key) => {
    setActive(accordian_key);
  };

  return (
    <Fragment>
      <Accordion as="ul" className="navbar-nav iq-main-menu">
        {menus?.length > 0 ? (
          menus.map((data, ind) => (
            <>
              {ind > 0 && (
                <li>
                  <hr className="hr-horizontal" />
                </li>
              )}
              {data.menu_name && (
                <li className="nav-item static-item">
                  <Link
                    className="nav-link static-item disabled"
                    to="#"
                    tabIndex="-1"
                  >
                    <span className="default-icon">{data.menu_name}</span>
                    <span className="mini-icon">-</span>
                  </Link>
                </li>
              )}
              {data?.sub_menu?.length > 0 ? (
                data?.sub_menu?.map((_data, sub_index) => (
                  <>
                    <SubMenuItem
                      depth={1}
                      item={_data}
                      key={sub_index}
                      type={"min"}
                      active={active}
                      onCustomToogleClick={customToogleClick}
                      onAccordianClick={onAccordianClick}
                    />
                  </>
                ))
              ) : (
                <>
                  <SidebarMenu
                    isTag="true"
                    key={ind}
                    pathname={`/${data.menu_code}`}
                    title={data.menu_name}
                  >
                    <div className="icon">
                      {findIcon(`${data.menu_icon}`, "dual-tone", "20")}
                    </div>
                  </SidebarMenu>
                </>
              )}
            </>
          ))
        ) : isMenuFetching ? (
          <>
            {[...Array(20).keys()].map((_i, index) => (
              <MenuSkeleton key={index} />
            ))}
          </>
        ) : null}
      </Accordion>
    </Fragment>
  );
});
VerticalNav.displayName = "VerticalNav";
export default VerticalNav;
const SubMenuItem = ({
  type,
  active,
  depth,
  key,
  item,
  onCustomToogleClick,
  onAccordianClick,
}) => {
  // console.log("sub-nav:", type, "::", depth, "::", item.menu_name);//
  return item?.sub_menu?.length > 0 ? (
    <>
      {
        <Accordion as="ul" className="navbar-nav iq-main-menu">
          <Accordion.Item
            as="li"
            eventKey={`${item.menu_name}`}
            bsPrefix={`nav-item  ${active === item.menu_name ? "active" : ""} `}
            onClick={() => onAccordianClick(item.menu_name)}
          >
            {depth > 2 ? (
              <CustomToggle
                className="nav-link"
                eventKey={`${item.menu_name}`}
                onClick={onCustomToogleClick}
                active={active === item.menu_name ? true : false}
              >
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>{item?.menu_name}</Tooltip>}
                >
                  <i className="icon">
                    {findIcon(`${item.menu_icon}`, "dual-tone", "20")}
                  </i>
                </OverlayTrigger>
                <span className="item-name">{item?.menu_name}</span>
                <i className="right-icon">
                  <svg
                    className="submit icon-18"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </i>
              </CustomToggle>
            ) : (
              <CustomToggle
                // className="nav-link"
                eventKey={`${item.menu_name}`}
                onClick={onCustomToogleClick}
                // active={active === item.menu_name ? true : false}
              >
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>{item?.menu_name}</Tooltip>}
                >
                  <i className="icon">
                    {findIcon(`${item.menu_icon}`, "dual-tone", "20")}
                  </i>
                </OverlayTrigger>
                <span className="item-name">{item?.menu_name}</span>
                <i className="right-icon">
                  <svg
                    className="submit icon-18"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </i>
              </CustomToggle>
            )}
            <Accordion.Collapse eventKey={`${item.menu_name}`}>
              <ul className="sub-nav">
                {item?.sub_menu?.length > 0 &&
                  item?.sub_menu.map((__data, _key) => (
                    <>
                      <SubMenuItem
                        item={__data}
                        key={key}
                        depth={depth + 1}
                        type="sub-nav"
                        active={active}
                        onCustomToogleClick={onCustomToogleClick}
                        onAccordianClick={onAccordianClick}
                      />
                    </>
                  ))}
              </ul>
            </Accordion.Collapse>
          </Accordion.Item>
        </Accordion>
      }
    </>
  ) : (
    <>
      <SidebarMenu
        isTag="true"
        key={key}
        pathname={`/${item.menu_code}`}
        title={item.menu_name}
      >
        <div className="icon">
          {findIcon(`${item.menu_icon}`, "dual-tone", "20")}
        </div>
      </SidebarMenu>
    </>
  );
};
