import React from "react";

// layout

import Default from "../../../../layouts/dashboard/default";
import AllUser from "../pages/all-user";
import AddUser from "../pages/add-user";

// pages

export const UserManagementRouter = [
  {
    path: "/user-management",
    element: <Default />,
    children: [
      {
        path: "listing",
        element: <AllUser />,
      },
      {
        path: "create",
        element: <AddUser />,
      },
      {
        path: "edit-user/:id",
        element: <AddUser />,
      },
    ],
  },
];
