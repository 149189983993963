import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Form,
  Row,
  Toast,
} from "react-bootstrap";
import Select from "react-select";
import { BasicQuill } from "../../plugins/pages/quill-editor";
import FilePicker from "../../../../components/form-components/file-picker";
// import KanbanItem from "../../../../components/partials/kanban/kanban-item";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import KanbanColumn from "../../../../components/partials/kanban/kanban-column";
import ContentDetails from "../../../../components/partials/common/content-details";
import update from "immutability-helper";
import KanbanReviewTag from "../../../../components/partials/kanban/kanban-review-tag";
import toast from "react-hot-toast";
import { medicalreview } from "./data";
import KanbanNonmedItem from "../../../../components/partials/kanban/kanban-nonmed-item";
// import Multiselect from "multiselect-react-dropdown";
import KanbanItemTag from "../../../../components/partials/kanban/kanban-item-tag";
import { useFormik } from "formik";
import {
  addArticleInitialValues,
  articleSchema,
} from "../constants/add-article";
import { getService } from "../../../../api/services/get-services";
import {
  ADD_TEXT,
  CREATE_CONTENT,
  UPLOAD_FILE,
} from "../../../../api/endpoints/add-content-endpoints";
import postService from "../../../../api/services/post-service";
import uploadService from "../../../../api/services/upload-service";
import { cmsGetService } from "../../../../api/services/cms-get-services";
import cmsUploadService from "../../../../api/services/cms-upload-service";
import cmsPostService from "../../../../api/services/cms-post-service";

const medicalTags = [
  { _id: 1, tags: "Dyspnea", bgClass: "soft-danger" },
  {
    _id: 2,
    tags: "Pulmonary Atelectasis",
    bgClass: "soft-secondary",
  },
  { _id: 3, tags: "Skin", bgClass: "soft-warning" },
  {
    _id: 4,
    tags: "Therapeutics",
    bgClass: "soft-danger",
  },
  { _id: 5, tags: "Face", bgClass: "soft-warning" },
  // { _id: 6, tags: "Trachea", bgClass: "soft-secondary" },
  // { _id: 7, tags: "Hyperemia", bgClass: "soft-primary" },
  // { _id: 8, tags: "Thyrotoxicosis", bgClass: "soft-secondary" },
  // { _id: 9, tags: "Goiter", bgClass: "soft-warning" },
  // { _id: 10, tags: "Nodular", bgClass: "soft-danger" },
  // { _id: 11, tags: "Facial Dermatoses", bgClass: "soft-secondary" },
  // { _id: 12, tags: "Rheumatic Nodule", bgClass: "soft-primary" },
  // { _id: 13, tags: "Neoplasms", bgClass: "soft-danger" },
  // { _id: 14, tags: "Hyperthyroidism", bgClass: "soft-secondary" },
  // { _id: 15, tags: "Antithyroid Agents", bgClass: "soft-primary" },
  // { _id: 16, tags: "Thyroid Gland", bgClass: "soft-danger" },
  // { _id: 17, tags: "Surgical Procedures", bgClass: "soft-secondary" },
  // { _id: 18, tags: "Operative", bgClass: "soft-primary" },
  // { _id: 19, tags: "Therapeutics", bgClass: "soft-danger" },
];
const nonMedicaltags = [
  { _id: 1, tags: "Management", bgClass: "soft-primary" },
  { _id: 2, tags: "Monitor", bgClass: "soft-danger" },
  { _id: 3, tags: "Lupin", bgClass: "soft-warning" },
  { _id: 4, tags: "Single nodule", bgClass: "soft-secondary" },
  { _id: 5, tags: "Human", bgClass: "soft-warning" },
  { _id: 6, tags: "ICMR", bgClass: "soft-secondary" },
  { _id: 7, tags: "GINA", bgClass: "soft-primary" },
];
const AddArticle = () => {
  const [step, setStep] = useState(1);
  const [formData, setformData] = useState({ article: {} });
  const options = [
    { value: "Alabama", label: "TDM1" },
    { value: "Amalapuram", label: "Hypoglycemia" },
    { value: "Amalapuram3", label: "Diarrhea" },
  ];

  const nonmedicalreview = [
    { id: 1, name: "Brand" },
    { id: 2, name: "Location" },
    { id: 3, name: "Person" },
    { id: 4, name: "Organization" },
    { id: 5, name: "Event" },
    { id: 6, name: "Event2" },
  ];
  const [tasks, setTaskStatus] = useState(medicalTags);
  const [resultReview, setResultreview] = useState([]);
  const [singleObject, setSingleobject] = useState({});
  const [loading, setLoading] = useState(true);
  const [formFields, setFormFields] = useState(true);

  // const [showDrpresult, setShowdroresult] = useState(medicalreview);
  const addReviewtoInput = (id, title) => {
    const medReview = medicalreview.find((med) => med.name == title);

    if (!medReview.medicalTagids.includes(id)) {
      setSingleobject({
        ...medReview,
        medicalTagids: id,
      });
    } else {
      console.log("ID is in medicalTagids of medReview");
    }

    const review = tasks.find((task) => task._id === id);

    const updateReview = { ...review, review: title };

    if (resultReview.some((r) => r._id == id)) {
      toast.error("Already exists");
    } else {
      setResultreview((res) => [...res, updateReview]);
    }
  };

  const changeTaskStatus = useCallback(
    (id, status) => {
      let task = tasks.find((task) => task._id === id);
      const taskIndex = tasks.indexOf(task);
      task = { ...task, status };
      let newTasks = update(tasks, {
        [taskIndex]: { $set: task },
      });
      setTaskStatus(newTasks);
    },
    [tasks]
  );

  const {
    touched,
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: addArticleInitialValues,
    validationSchema: articleSchema,
    onSubmit: (value, action) => {
      const payload = {
        content_category_id: formFields.category[0].content_category_id,
        content_brand_id: value.brand,
        content_file_type_id: 2,
        file_type_code: "article",
        content_title: value.title,
        content_description: value.body,
        title_html: value.title_html,
        description_html: value.body_html,
        citation: value.reference,
        citation_html: value.reference_html,
        reference_files: value.referenceFile,
        base: value.coverPhoto,
        thumbnail: value.thumbnail,
      };
      cmsPostService(ADD_TEXT, payload).then((data) => {
        if (!data.isError) {
          if (data.data?.success) {
            toast.success(data.data.message);
            resetForm();
          } else {
            toast.error("Error Adding content!");
          }
        }
      });
    },
  });

  function getData() {
    cmsGetService(CREATE_CONTENT)
      .then((data) => {
        setFormFields(data.data.data);
        setLoading(false);
      })
      .catch((e) => console.log("error", e));
  }
  useEffect(() => {
    getData();
  }, []);

  function uploadFile(files, keyName) {
    if (files.length > 0) {
      let formdata = new FormData();
      [...files].forEach((file) => {
        formdata.append("files[]", file);
      });
      cmsUploadService(UPLOAD_FILE, formdata)
        .then((data) => {
          setFieldValue(keyName, data.data.data[0].url);
        })
        .catch((err) => console.log(err));
    } else {
      setFieldValue(keyName, null);
    }
  }

  return (
    <>
      {step == 1 && !loading && (
        <>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg={6}>
                {/* <ContentDetails type={"Article"} /> */}
                {/* card for article meta */}
                <Card>
                  <Card.Header className="d-flex justify-content-between">
                    <div className="header-title">
                      <h4 className="card-title">Article Meta</h4>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Col lg="12" className="mb-4">
                      <Form.Floating className="custom-form-floating">
                        <Form.Select
                          className=""
                          id="floatingInput1"
                          name="speciality"
                          value={values.speciality}
                          onChange={handleChange}
                          isInvalid={touched.speciality && !!errors.speciality}
                        >
                          <option value="" selected hidden>
                            {" "}
                            Select Speciality
                          </option>
                          {formFields &&
                            formFields?.specialty &&
                            formFields?.specialty.map((item) => (
                              <option value={item?.speciality_id}>
                                {item?.speciality_name}
                              </option>
                            ))}
                        </Form.Select>
                        <Form.Label htmlFor="floatingInput">
                          Select Speciality{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col lg="12" className="mb-4">
                      <Form.Floating className="custom-form-floating">
                        <Form.Select
                          className=""
                          id="floatingInput1"
                          name="brand"
                          value={values.brand}
                          onChange={handleChange}
                          isInvalid={touched.brand && !!errors.brand}
                        >
                          <option value="" selected hidden>
                            {" "}
                            Select Brand
                          </option>
                          {formFields &&
                            formFields?.brands &&
                            formFields?.brands.map((item) => (
                              <option value={item?.principal_entity_id}>
                                {item?.principal_entity_name}
                              </option>
                            ))}
                        </Form.Select>
                        <Form.Label htmlFor="floatingInput">
                          Select Brand <span className="text-danger">*</span>
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6}>
                <Card>
                  <Card.Header className="d-flex justify-content-between">
                    <div className="header-title">
                      <h4 className="card-title">Add Article</h4>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col lg="12" className="mb-4">
                        <FilePicker
                          title={"Add Cover Picture"}
                          source={values.coverPhoto}
                          onUpdate={(val) => uploadFile(val, "coverPhoto")}
                          accepts="image/*"
                          type="image"
                        />
                        {touched.coverPhoto && "coverPhoto" in errors && (
                          <div style={{ color: "red" }}>
                            {errors.coverPhoto}
                          </div>
                        )}
                      </Col>
                      <Col lg="12" className="mb-4">
                        <FilePicker
                          title={"Add Thumbnail"}
                          source={values.thumbnail}
                          onUpdate={(val) => uploadFile(val, "thumbnail")}
                          accepts="image/*"
                          type="image"
                        />
                        {touched.thumbnail && "thumbnail" in errors && (
                          <div style={{ color: "red" }}>{errors.thumbnail}</div>
                        )}
                      </Col>

                      {/* <FilePicker title="Add Cover Picture" /> */}
                      <Form.Group className="col-12 form-group">
                        <Form.Label>
                          Title <span className="text-danger">*</span>{" "}
                        </Form.Label>
                        <div>
                          <BasicQuill
                            name="title"
                            value={values.title}
                            updateData={(value) => {
                              setFieldValue("title", value.rawText);
                              setFieldValue("title_html", value.html);
                            }}
                          ></BasicQuill>
                          {touched.title && "title" in errors && (
                            <div style={{ color: "red" }}>{errors.title}</div>
                          )}
                        </div>
                      </Form.Group>
                      <Form.Group className="col-12 form-group">
                        <Form.Label>
                          Body <span className="text-danger">*</span>{" "}
                        </Form.Label>
                        <div>
                          <BasicQuill
                            name="body"
                            value={values.body}
                            updateData={(value) => {
                              setFieldValue("body", value.rawText);
                              setFieldValue("body_html", value.html);
                            }}
                          ></BasicQuill>
                          {touched.body && "body" in errors && (
                            <div style={{ color: "red" }}>{errors.body}</div>
                          )}
                        </div>
                      </Form.Group>
                      <Form.Group className="col-12 form-group">
                        <Form.Label>
                          Citation <span className="text-danger">*</span>{" "}
                        </Form.Label>
                        <div>
                          <BasicQuill
                            name="reference"
                            value={values.reference}
                            updateData={(value) => {
                              setFieldValue("reference", value.rawText);
                              setFieldValue("reference_html", value.html);
                            }}
                          ></BasicQuill>
                          {touched.reference && "reference" in errors && (
                            <div style={{ color: "red" }}>
                              {errors.reference}
                            </div>
                          )}
                        </div>
                      </Form.Group>
                      <Form.Group className="col-12 form-group">
                        <Form.Label>Add Reference Files</Form.Label>
                        <Form.Control
                          type="file"
                          id="customFile"
                          name="referenceFile"
                          isInvalid={!!errors.referenceFile}
                          onChange={(e) =>
                            uploadFile(e.target.files, "referenceFile")
                          }
                        />
                      </Form.Group>
                    </Row>

                    <div className="d-flex gap-2 gap-md-3 justify-content-end flex-wrap flex-md-nowrap">
                      <Button
                        type="btn"
                        variant="btn btn-primary flex-md-grow-0 flex-grow-1 text-nowrap"
                      >
                        Submit
                      </Button>

                      <Button
                        type="button"
                        variant="btn btn-info flex-md-grow-0 flex-grow-1 text-nowrap"
                      >
                        Save as Draft
                      </Button>
                      <Button
                        type="button"
                        variant="btn btn-light flex-md-grow-0 flex-grow-1 text-nowrap"
                      >
                        Cancel
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Form>
        </>
      )}
      {step == 2 && (
        <>
          <Row>
            <Col lg="6">
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">Medical Tags</h4>
                  </div>
                </Card.Header>
                <Card.Body>
                  <DndProvider backend={HTML5Backend}>
                    <div className="d-flex gap-2">
                      {tasks &&
                        tasks.length > 0 &&
                        tasks.map((tag, index) => {
                          return (
                            <KanbanItemTag tag={tag} key={tag._id}>
                              <Badge
                                pill
                                bg={tag?.bgClass}
                                className="fw-normal d-block py-2 px-3 flex-wrap fs-6"
                              >
                                {tag?.tags}
                              </Badge>
                            </KanbanItemTag>
                          );
                        })}
                    </div>
                    <Row>
                      {medicalreview.map((medReview) => {
                        return (
                          <Col lg="4" className="mt-4" key={medReview.id}>
                            <KanbanReviewTag
                              addReviewtoInput={addReviewtoInput}
                              reviewtitle={medReview.name}
                            >
                              <FilePicker
                                title={medReview?.name}
                                result={resultReview}
                              />
                            </KanbanReviewTag>
                          </Col>
                        );
                      })}
                    </Row>
                  </DndProvider>
                </Card.Body>
              </Card>
            </Col>
            <Col lg="6">
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">Non Medical tags</h4>
                  </div>
                </Card.Header>
                <Card.Body>
                  <DndProvider backend={HTML5Backend}>
                    <KanbanColumn>
                      <div className="d-flex gap-2">
                        {nonMedicaltags &&
                          nonMedicaltags.length > 0 &&
                          nonMedicaltags.slice(0, 5).map((tag) => {
                            return (
                              <KanbanNonmedItem key={tag._id} id={tag._id}>
                                <Badge
                                  pill
                                  bg={tag.bgClass}
                                  className="fw-normal d-block py-2 px-3 flex-wrap fs-6"
                                >
                                  {tag.tags}
                                </Badge>
                              </KanbanNonmedItem>
                            );
                          })}
                      </div>
                      <Row>
                        {nonmedicalreview.map((nonmedRev) => {
                          return (
                            <Col lg="4" className="mt-4">
                              <FilePicker title={nonmedRev.name} />
                            </Col>
                          );
                        })}
                      </Row>
                    </KanbanColumn>
                  </DndProvider>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Card>
              <Card.Body>
                <Col lg="12">
                  <div className="d-flex gap-2 gap-md-3 justify-content-end flex-wrap flex-md-nowrap">
                    <Button
                      type="button"
                      variant="btn btn-primary flex-md-grow-0 flex-grow-1 text-nowrap"
                      onClick={() => setStep(2)}
                    >
                      Review Tags and Submit
                    </Button>

                    <Button
                      type="button"
                      variant="btn btn-info flex-md-grow-0 flex-grow-1 text-nowrap"
                    >
                      Save as Draft
                    </Button>
                    <Button
                      type="button"
                      variant="btn btn-light flex-md-grow-0 flex-grow-1 text-nowrap"
                    >
                      Cancel
                    </Button>
                  </div>
                </Col>
              </Card.Body>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export default AddArticle;
