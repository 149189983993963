import { createSlice } from "@reduxjs/toolkit";
import { reactTableInitialState } from "./react-table.state";
export const reactTableSlice = createSlice({
  name: "reactTable",
  initialState: reactTableInitialState,
  reducers: {
    addFilter: (state, action) => {
      state.table_filters = action.payload;
    },
  },
});

export default reactTableSlice.reducer;
