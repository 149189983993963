import { Fragment, memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AddLogModal from "../components/add-log-modal";
import ConsentCollectionView from "../components/consent-collection-view";
import OnboardingConsentModal from "../components/onboarding-consent-modal";
import OnboardingView from "../components/onboarding-view";
import { useFetchActionDetails } from "../../../../queries/query-hooks/action-hook";
import { useOnboardingEdit } from "../../../../queries/query-hooks/onboarding-hook";
import dayjs from "dayjs";
import postService from "../../../../api/services/post-service";
import { ONBOARDING_ADD_LOG } from "../../../../api/endpoints/onboarding-endpoints";
import { UPDATE_ACTION_POST } from "../../../../api/endpoints/action-endpoints";
import { STATUS_CONSTANTS } from "../constants/action-constants";
import { toast } from "react-hot-toast";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import useBackdrop from "../../../../components/custom-hooks/use-backdrop";

const OnboardingTaskDetails = () => {
  const { redirectTo } = useRedirect();
  const { type, actionId } = useParams();
  const { data: actionDetails } = useFetchActionDetails(actionId);
  const { data: onboardingDropdownValues } = useOnboardingEdit();
  const { origin: hcpInformation } = actionDetails || { origin: null };

  console.log("actionDetails", actionDetails);

  const actionInfo = {
    priority: actionDetails?.action_priority_id?.priority_name,
    status: actionDetails?.status?.value ?? "Pending",
    deadline: dayjs(actionDetails?.action_due_date).format("DD MMM YYYY"),
    name: actionDetails?.action_name,
    description: actionDetails?.action_description,
    type: actionDetails?.action_type_id?.action_type_name,
    createdBy: actionDetails?.action_assign_by?.name,
  };

  const consentBtn = [
    {
      id: 1,
      btnText: "Received Consent",
      variant: "primary",
      value: "I have received consent from the doctor",
      submit: "Submit",
    },
    {
      id: 2,
      btnText: "Couldn't Connect",
      variant: "danger",
      value: "Send Consent message through WhatsApp",
      submit: "Await Response",
    },
  ];

  const [modalopen, setModalopen] = useState(false);
  const [showAddlogModal, setShowAddlogModal] = useState(false);
  const [addedLogs, setaddedLogs] = useState([]);

  const [isConsentAreaOpen, setIsConsentAreaOpen] = useState(false);
  const [consentLogs, setConsentLogs] = useState([]);

  const [consentButtons, setConsentButtons] = useState(consentBtn);
  const closeConsentModal = () => setModalopen(false);
  const closeAddLogModal = () => setShowAddlogModal(false);

  const addedLogColumns = [
    {
      Header: "Log ID",
      accessor: "id",
    },
    {
      Header: "Log Type",
      accessor: "onboarding_log_type",
      Cell: ({ value }) => value.name,
    },
    // {
    //   Header: "Status",
    //   accessor: "onboarding_sub_log_type",
    //   Cell: ({ value }) => {
    //     console.log("onboarding_sub_log_type", value);
    //     // return value?.name ?? "";
    //   },
    // },
    {
      Header: "Comments",
      accessor: "comment",
    },
    // {
    //   Header: "Follow up",
    //   Cell: ({ row }) => {
    //     return (
    //       (row.original.call_back_date
    //         ? dayjs(row.call_back_date).format("DD MMM YYYY")
    //         : "") +
    //       " " +
    //       (row.original.call_back_time
    //         ? dayjs(row.call_back_time).format("HH:mm:ss")
    //         : "")
    //     );
    //   },
    // },
    {
      Header: "Added On",
      accessor: "created_at",
      Cell: ({ value }) => dayjs(value).format("DD MMM YYYY"),
    },
  ];

  const consentLogColumns = [
    {
      Header: "SL No",
      accessor: "id",
    },
    {
      Header: "Log Type",
      accessor: "btntext",
    },
    {
      Header: "Tickbox Value",
      accessor: "value",
    },
    {
      Header: "Comments",
      accessor: "comment",
    },
    {
      Header: "Commentor Name",
      accessor: "commentsname",
    },
    {
      Header: "Added On",
      accessor: "createdAt",
    },
  ];

  const handleSubmit = async (data) => {
    let payload = {
      ...data,
      action_id: actionId,
      hcp_id: hcpInformation?.hcp_id,
    };
    let response = await postService(ONBOARDING_ADD_LOG, payload);
    if (response.isError) {
      toast.error(response.error);
    } else {
      toast.success("Log added successfully");
      let newData = response?.data?.data ?? {};
      setaddedLogs((prev) => [...prev, newData]);
      closeAddLogModal();
    }
  };

  const handleAddlog = (e) => {
    e.preventDefault();
    if (type == "onboarding") {
      setShowAddlogModal(true);
    } else {
      setShowAddlogModal(false);
    }
  };

  const handleModalShow = (id) => {
    // const newState = consentBtn.map((obj) => {
    //   if (obj.id === id) {
    //     return { ...obj, modalShow: true };
    //   }
    //   return obj;
    // });
    // setConsentButtons(newState);
    // setModalopen(true);
  };

  const { showBackdrop, hideBackdrop } = useBackdrop();

  const handleOnboardingUpdate = async (values) => {
    showBackdrop();
    let onboardingDetailsPayload = {
      hcp_first_name: values.firstName,
      hcp_middle_name: values.middleName,
      hcp_last_name: values.lastName,
      hcp_age: values.age ? values.age : 0,
      hcp_gender: values.gender ? values.gender : 0,
      hcp_email: values.email,
      hcp_mobile: values.mobileNo,
      hcp_register_number: values.registrationNo ? values.registrationNo : 0,
      hcp_medical_degree_id: values.degree,
      hcp_association_id: values.association ? values.association : 0,
      hcp_primary_speciality_id: values.primarySpeciality
        ? values.primarySpeciality
        : 0,
      hcp_interest_speciality_id: values.interestSpeciality,
      hcp_medical_council_id: values.council ? values.council : 0,
      hcp_country_id: values.country ? values.country : 0,
      hcp_zone_id: values.zone ? values.zone : 0,
      hcp_state_id: values.state ? values.state : 0,
      hcp_city_id: values.city ? values.city : 0,
      hcp_type_id: values.typeOfPractice ? values.typeOfPractice : 0,
      hcp_utmsource: values.utmSource ? values.utmSource : 0,
      hcp_document_others: values.documentOthers ? values.documentOthers : 0,
      hcp_social_links:
        values.socialLinks?.length > 0
          ? values.socialLinks.filter(
              (item) => item.link !== "" && item.type !== ""
            )
          : 0,
      hcp_document_speciality: values.documentSpecialities,
      hcp_document_registration: values.documentRegistrations,
      hcp_document_recording: values.callRecording,
      medium: values.medium,
    };

    let payload = {
      status: values.status, // STATUS_CONSTANTS.COMPLETED,
      action_id: actionId,
      onboarding_details: onboardingDetailsPayload,
    };

    if (
      addedLogs?.length <= 0 &&
      payload?.status == STATUS_CONSTANTS.REJECTED
    ) {
      hideBackdrop();
      toast.error("Please add log");
      return false;
    }

    let response = await postService(UPDATE_ACTION_POST + actionId, payload);
    console.log("response", response);
    if (response.isError) {
      hideBackdrop();
      toast.error(response.error);
      return;
    } else {
      hideBackdrop();
      if (response.data.success) {
        toast.success(response.data.message);
        redirectTo("action/all-action");
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <>
      <Fragment>
        {type === "onboarding" &&
          hcpInformation &&
          onboardingDropdownValues && (
            <OnboardingView
              hcpInformation={hcpInformation}
              addedLogColumns={addedLogColumns}
              consentLogColumns={consentLogColumns}
              consentBtn={consentBtn}
              isConsentAreaOpen={isConsentAreaOpen}
              addedLogs={addedLogs}
              handleAddlog={handleAddlog}
              handleModalShow={handleModalShow}
              consentLogs={consentLogs}
              onboardingDropdownValues={onboardingDropdownValues}
              handleOnboardingUpdate={handleOnboardingUpdate}
              handleOnboardingReject={handleOnboardingUpdate}
              actionInfo={actionInfo}
              key={"onboarding"}
            />
          )}
        {type === "consent-collection" && <ConsentCollectionView />}

        <OnboardingConsentModal
          show={modalopen}
          modalClose={closeConsentModal}
          consentButtons={consentButtons}
          setIsConsentAreaOpen={setIsConsentAreaOpen}
          setConsentLogs={setConsentLogs}
          consentLogs={consentLogs}
        />

        <AddLogModal
          show={showAddlogModal}
          modalClose={closeAddLogModal}
          handleSubmit={handleSubmit}
        />
      </Fragment>
    </>
  );
};

export default memo(OnboardingTaskDetails);
