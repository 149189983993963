import cx from "classnames";
import { memo, useEffect, useRef, useState } from "react";
import TreeView, { flattenTree } from "react-accessible-treeview";
import { Button, Form, Modal } from "react-bootstrap";
import Masonry from "react-masonry-css";
import Card from "../../../../components/bootstrap/card";
import { findIcon } from "../../../dashboard/icons";
import { CONTENT_FILE_LIST } from "../../../../api/endpoints/content-studio-endpoints";
import postService from "../../../../api/services/post-service";
import ContentCard from "../../../../components/file-manager/content-card";
import Filter from "./filter";

const breakpointColumnsObj = {
  default: 4,
  1100: 4,
  768: 3,
  540: 2,
  479: 1,
};
const brandOptions = [
  { value: "brand 1", label: "Brand 1" },
  { value: "brand 2", label: "Brand 2" },
];
const fileTypeOptions = [
  { value: "Nevada", label: "Gujarat Medical Council (GJMC)" },
  { value: "Oregon", label: "Delhi Medical Council (DMC)" },
  { value: "Washington", label: "Madhya Pradesh Medical Council (MPMC)" },
  { value: "Colorada", label: "Chattisgarh Medical Council (CGMC)" },
  { value: "Idaho", label: "Bihar Medical Council (BMC)" },
];
const tagOptions = [
  { value: "Nevada", label: "India" },
  { value: "Oregon", label: "China" },
  { value: "Washington", label: "Indonesia" },
  { value: "Colorada", label: "Iceland" },
  { value: "Idaho", label: "Hong Kong" },
];

const CreateContentStudioModal = ({
  show,
  handleCreateModalClose,
  contentStudioDetails,
  getContentStudioName,
  selectTemplateForContent,
  stepToShow,
}) => {
  const [step, setStep] = useState(stepToShow ?? 1);
  const [contentFileList, setContentFileList] = useState([]);
  //   const [folder, setFolder] = React.useState(null);
  const post_json = useRef({
    default: "all",
    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      content_id: "desc",
    },
  });
  const folder = {
    name: "",
    children: [
      {
        name: "CLIRNET",
        children: [
          {
            name: "MedWiki",
            children: [],
          },
          {
            name: "Clinical Video",
            children: [
              {
                name: "CLIRNET",
                children: [
                  {
                    name: "MedWiki",
                    children: [],
                  },
                  {
                    name: "Clinical Video",
                    children: [],
                  },
                ],
              },
              {
                name: "DocTube",
                children: [
                  {
                    name: "Long Video",
                    children: [],
                  },
                  {
                    name: "Shorts",
                    children: [],
                  },
                ],
              },
              {
                name: "MasterCast",
                children: [
                  {
                    name: "Ad Board",
                    children: [],
                  },
                  {
                    name: "RTM",
                    children: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "DocTube",
        children: [
          {
            name: "Long Video",
            children: [],
          },
          {
            name: "Shorts",
            children: [],
          },
        ],
      },
      {
        name: "MasterCast",
        children: [
          {
            name: "Ad Board",
            children: [],
          },
          {
            name: "RTM",
            children: [],
          },
        ],
      },
    ],
  };

  const ArrowIcon = ({ isOpen, className }) => {
    const baseClass = "arrow";
    const classes = cx(
      baseClass,
      { [`${baseClass}--closed`]: !isOpen },
      { [`${baseClass}--open`]: isOpen },
      className
    );
    return (
      <>
        <span className="folder-icon position-absolute">
          {findIcon("Folder", "dual-tone", "18")}
        </span>
        <span className="down-arrow-icon position-absolute transition">
          {findIcon("Chevron Down", "outline", "18")}
        </span>
      </>
    );
  };
  const fetchFileListing = async () => {
    const response = await postService(CONTENT_FILE_LIST, post_json.current);
    console.log("response_entity", response.data?.data);
    setContentFileList(response.data?.data?.rows);
    // const file_types = await getService(FILE_TYPES_GET);
    // console.log("file_types_file_types", file_types?.data?.data);
    // setFileType(file_types?.data?.data);
    // const formattedJSON = formatJSON(
    //   dummy_principle_entity.map((item) => {
    //     return {
    //       principal_entity_id: item.principal_entity_id,
    //       principal_entity_name: item.principal_entity_name,
    //       principal_entity_parent_id: item.principal_entity_parent_id,
    //     };
    //   })
    // );

    // console.log("formatted", formattedJSON);
    // traverseTree(formattedJSON);
    // const tree = flattenTree({ name: "", children: formattedJSON });
    // console.log("FLATTEN_TREE", tree);
    // setFolder(tree);
  };
  useEffect(() => {
    if (show && step === 2) {
      fetchFileListing();
    }
  }, [show, step]);
  return (
    <Modal
      show={show}
      className={`${step === 2 ? "find-file-modal" : ""}`}
      onHide={handleCreateModalClose}
      fullscreen={step === 2 ? true : false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {step === 1 && (
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="mb-0">Create New Content Studio</h5>
          </Modal.Title>
        </Modal.Header>
      )}

      {step === 1 && (
        <Modal.Body>
          <div className="position-relative d-flex flex-column gap-3">
            <Form.Floating className="custom-form-floating">
              <Form.Control
                type="text"
                className=""
                id="floatingInput1"
                autoComplete="Enter Content studio Name"
                placeholder="Enter Content studio Name"
                onChange={(e) => getContentStudioName(e)}
              />
              <Form.Label htmlFor="floatingInput">
                Enter Content studio Name
              </Form.Label>
            </Form.Floating>
            <Button
              className=""
              variant="primary"
              disabled={contentStudioDetails?.content_studio_name === ""}
              onClick={() => setStep(2)}
            >
              Submit & Select Content
            </Button>
          </div>
        </Modal.Body>
      )}
      {step === 2 && (
        <Modal.Body
          className="p-0 d-flex align-items-start"
          style={{ "overflow-x": "hidden" }}
        >
          <div className="h-100 flex-shrink-0 find-file-modal__left position-sticky top-0">
            <Card className="h-100 m-0 rounded-0">
              <Card.Body className="d-flex flex-column">
                <h5 className="mb-3">Find in folder</h5>
                {folder && (
                  <TreeView
                    className="list-unstyled flex-grow-1 custom-TreeView"
                    data={flattenTree(folder)}
                    aria-label="Checkbox tree"
                    multiSelect
                    propagateSelect
                    propagateSelectUpwards
                    togglableSelect
                    nodeRenderer={({
                      element,
                      isBranch,
                      isExpanded,
                      getNodeProps,
                      level,
                      handleExpand,
                      className,
                    }) => {
                      return (
                        <div
                          {...getNodeProps({ onClick: handleExpand })}
                          // style={{ marginLeft: 5 * (level - 1) }}
                        >
                          {isBranch ? (
                            <ArrowIcon isOpen={isExpanded} />
                          ) : (
                            <span className="folder-icon position-absolute">
                              {findIcon("Photograph", "dual-tone", "18")}
                            </span>
                          )}
                          <span>{element.name}</span>
                        </div>
                      );
                    }}
                  />
                )}
              </Card.Body>
            </Card>
          </div>
          <div className="flex-grow-1 p-5">
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
              <div className="d-flex flex-column">
                <h6 className="m-0 text-secondary">Find Your Content For</h6>
                <h3 className="mb-0 mt-1 ">
                  {/* Content studio Name Goes Here */}
                  {contentStudioDetails?.content_studio_name}
                </h3>
              </div>
              <div className="d-flex justify-content-between align-items-center rounded flex-wrap gap-3">
                <Button variant="danger" onClick={handleCreateModalClose}>
                  Cancel
                </Button>
              </div>
            </div>
            <Card>
              <Card.Body className="p-3">
                {/* <Row>
                  <Col lg={12} className="mb-3">
                    <div className="form-group input-group search-input mb-0 w-auto">
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Search..."
                      />
                      <span className="input-group-text">
                        {findIcon("Search", "outline", "20")}
                      </span>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <h6 className="mb-0">
                      <Link
                        className="d-inline-flex align-items-center gap-1"
                        onClick={() => setShowAdvancesearch(!showAdvanceSearch)}
                      >
                        {findIcon("Chevron Down", "outline", "18")} Advance
                        Search
                      </Link>
                    </h6>
                  </Col>
                </Row>
                {showAdvanceSearch && (
                  <div className="mt-2">
                    <Row>
                      <Col>
                        <Select
                          className="react-select-customize"
                          options={brandOptions}
                          placeholder="Brand"
                        />
                      </Col>
                      <Col>
                        <Select
                          className="react-select-customize"
                          options={tagOptions}
                          placeholder="Tag"
                        />
                      </Col>
                      <Col>
                        <Select
                          className="react-select-customize"
                          options={fileTypeOptions}
                          placeholder="File Type"
                        />
                      </Col>
                      <Col>
                        <div className="form-group mb-0 ">
                          <Flatpickr
                            options={{ mode: "range", minDate: "today" }}
                            className="form-control range_flatpicker"
                            placeholder="Upload date"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                )} */}
                <Filter />
              </Card.Body>
            </Card>

            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {contentFileList.map((i, index) => (
                <ContentCard
                  key={index + 1}
                  id={i.content_id}
                  fileName={i.content_title}
                  fileTypeIcon={i.content_file_type_id.file_type_icon}
                  fileDescription={i.content_description}
                  fileType={i.content_file_type_id.file_type_code}
                  createdBy={i.created_by.name}
                  publicationDate={i.content_publication_date}
                  selectFunction={selectTemplateForContent}
                />
              ))}
            </Masonry>
          </div>
        </Modal.Body>
      )}
    </Modal>
  );
};

export default memo(CreateContentStudioModal);
