import {
  memo,
  Fragment,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";

//react-bootstrap
import { Badge, Button, Card, Col, Row } from "react-bootstrap";

// img
import shap1 from "../../../../../assets/images/shapes/01.png";
import shap2 from "../../../../../assets/images/shapes/02.png";
import shap3 from "../../../../../assets/images/shapes/03.png";
import shap4 from "../../../../../assets/images/shapes/04.png";
import shap5 from "../../../../../assets/images/shapes/05.png";
import shap6 from "../../../../../assets/images/shapes/06.png";

import ReactTable from "../../../../../lib/table/react-table";
import TemplateFilter from "../../../../../components/common-components/template-filter";
import { findIcon } from "../../../../dashboard/icons";
import useRedirect from "../../../../../components/custom-hooks/use-redirect";
import {
  WHATSAPP_TEMPLATE_DELETE,
  WHATSAPP_TEMPLATE_LIST_GET,
} from "../../../../../api/endpoints/template-management-endpoints";
import postService from "../../../../../api/services/post-service";
import {
  changeTableStateToPayload,
  statusToBg,
} from "../../../../../common/utils/utils";
import ActionButtonBar from "../../../../../components/partials/common/action-button-bar";
import { getService } from "../../../../../api/services/get-services";
import DeleteModal from "../../../../../components/partials/common/delete-modal";
import ViewModal from "../../../../../components/partials/common/view-modal";
import toast from "react-hot-toast";
import FilterBar from "../../../../../components/filter-bar";
import CommonTableLoader from "../../../../../skeleton/common-table-loader";
import PageNotFound from "../../../../PageNotFound";

const AllWhatsappTemplates = () => {
  const actionView = findIcon("Eye", "solid");

  const [modalShow, setModalShow] = useState(false);
  const [whatsapptemplateList, setWhatsappTemplateList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [viewWhatsappData, setViewWhatsappdata] = useState({});
  const [viewWhatsModalShow, setWhatsViewModalShow] = useState(false);

  const actionEdit = findIcon("Pencil", "dual-tone");

  const data = [
    {
      img: `${shap1}`,
      name: "Unlayer is an email editor and page builder to build beautiful...",
      phone: "(760) 756 7568",
      email: "annasthesia@gmail.com",
      country: "USA",
      contentName: "Product brochures",
      brand: "Budecort",
      action: {
        status: "Approved",
        color: "primary",
      },
      company: "Acme Corporation",
      joindate: "2019/12/01",
      iconAction: {
        iconedit: actionEdit,
        iconview: actionView,
        // iconremove: remove,
      },
    },
    {
      img: `${shap2}`,
      name: "Unlayer is an email editor...",
      phone: "+62 5689 458 658",
      email: "brocklee@gmail.com",
      country: "Indonesia",
      contentName: `Whitepapers on the company's research and development`,
      brand: "Montair FX",
      action: {
        status: "Approved",
        color: "primary",
      },
      company: "Soylent Corp",
      joindate: "2019/12/01",
      iconAction: {
        iconedit: actionEdit,
        iconview: actionView,
        // iconremove: remove,
      },
    },
    {
      img: `${shap3}`,
      name: "Unlayer is an email editor and page builder to build beautiful...and page builder...",
      phone: "+55 6523 456 856",
      email: "dandruff@gmail.com",
      country: "Brazil",
      contentName: "Case studies of successful treatments",
      brand: "Glygard",
      action: {
        status: "Pending",
        color: "warning",
      },
      company: "Acme Corporation",
      joindate: "2019/12/01",
      iconAction: {
        iconedit: actionEdit,
        iconview: actionView,
        // iconremove: remove,
      },
    },
    {
      img: `${shap4}`,
      name: "Unlayer is an email editor and page builder to build beautiful...and page builder to...",
      phone: "+91 2586 253 125",
      email: "hansolo@gmail.com",
      country: "India",
      contentName: "Infographics on the pharmaceutical industry",
      brand: "Metfor",
      action: {
        status: "Rejected",
        color: "danger",
      },
      company: "Vehement Capital",
      joindate: "2019/12/01",
      iconAction: {
        iconedit: actionEdit,
        iconview: actionView,
        // iconremove: remove,
      },
    },
    {
      img: `${shap5}`,
      name: "Unlayer is an email...",
      phone: "+27 2563 456 589",
      email: "lynnguini@gmail.com",
      country: "Africa",
      contentName: "Social media posts and ads",
      brand: "Amlopres",
      action: {
        status: "Approved",
        color: "primary",
      },
      company: "Massive Dynamic",
      joindate: "2019/12/01",
      iconAction: {
        iconedit: actionEdit,
        iconview: actionView,
        // iconremove: remove,
      },
    },
    {
      img: `${shap6}`,
      name: "Unlayer is an email...",
      phone: "+55 25685 256 589",
      email: "ericshun@gmail.com",
      country: "Brazil",
      contentName: "Video testimonials from patients and healthcare providers",
      brand: "Metolar",
      action: {
        status: "Pending",
        color: "warning",
      },
      company: "Globex Corporation",
      joindate: "2019/12/01",
      iconAction: {
        iconedit: actionEdit,
        iconview: actionView,
        // iconremove: remove,
      },
    },
    {
      img: `${shap3}`,
      name: "Unlayer is an email editor and page builder to build beautiful...and page...",
      phone: "(760) 756 7568",
      email: "budwiser@ymail.com",
      country: "USA",
      contentName: "Email newsletters and campaigns",
      brand: "Glygard",
      action: {
        status: "Hold",
        color: "info",
      },
      company: "Acme Corporation",
      joindate: "2019/12/01",
      iconAction: {
        iconedit: actionEdit,
        iconview: actionView,
        // iconremove: remove,
      },
    },
    {
      img: `${shap5}`,
      name: "Unlayer is an email editor and page builder to build beautiful...and page builder to build...",
      phone: "+27 5625 456 589",
      email: "margearita@gmail.com",
      country: "Africa",
      contentName: "Webinars on industry topics or product",
      brand: "Montair FX",
      action: {
        status: "Draft",
        color: "success",
      },
      company: "Vehement Capital",
      joindate: "2019/12/01",
      iconAction: {
        iconedit: actionEdit,
        iconview: actionView,
        // iconremove: remove,
      },
    },
    {
      img: `${shap2}`,
      name: "Unlayer is an email editor...",
      phone: "+55 2563 456 589",
      email: "billdabear@gmail.com",
      country: "Brazil",
      contentName: "Infographics on the pharmaceutical industry",
      brand: "Metfor",
      action: { status: "Approved", color: "primary" },
      company: "Massive Dynamic",
      joindate: "2019/12/01",
      iconAction: {
        iconedit: actionEdit,
        iconview: actionView,
        // iconremove: remove,
      },
    },
  ];

  const columns = [
    {
      Header: "Name",
      accessor: "whatsapp_name",
      Cell: ({ value }) => (
        <div className="text-start text-truncate" style={{ maxWidth: "20rem" }}>
          {value}
        </div>
      ),
    },
    // {
    //   Header: "Content",
    //   accessor: "whatsapp_content",
    //   Cell: ({ value }) => (
    //     <div className="text-start text-truncate" style={{ maxWidth: "20rem" }}>
    //       {value}
    //     </div>
    //   ),
    // },
    // { Header: "Brand", accessor: "brand" },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => (
        <div className="d-flex align-items-center justify-content-center">
          <Badge bg={statusToBg(row.values.status.value)} className="me-2">
            {row.values.status.value ? (
              <span className="btn-inner">{row.values.status.value}</span>
            ) : (
              <span className="btn-inner">Not Found</span>
            )}
          </Badge>
        </div>
      ),
    },
    {
      Header: "Approved By",
      accessor: "approved_by",
      Cell: ({ value }) => {
        return value && value?.name ? value?.name : "N/A";
      },
    },
    {
      Header: "Creation By",
      accessor: "created_by",
      Cell: ({ value }) => {
        return value && value.name ? value.name : "";
      },
    },
    {
      Header: "Action",
      accessor: "iconAction",
      disableFilters: true,
      Cell: ({ row }) => (
        <ActionButtonBar
          row={row}
          // onView={() => { viewWhatsappModal(row?.original) }
          onView={() => {
            redirectTo(
              "template-management/whatsapp-template/details/" +
                row.original.whatsapp_id
            );
            // viewWhatsappModal(row?.original)
            // alert('asd');
          }}
          onEdit={() => {
            redirectTo(
              "template-management/whatsapp-template/create/" +
                row.original.whatsapp_id
            );
          }}
          onDelete={() => {
            setSelectedTemplate(row.original.whatsapp_id);
            modalOpen();
          }}
        />
      ),
    },
  ];
  const dropdownList = [
    "Title",
    "Approved By",
    "Content Name",
    "Brand",
    "Status",
    "Approved By",
    "Creation Date",
    "Action",
  ];
  const { redirectTo } = useRedirect();

  const post_json = useRef({
    // default: "all",
    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      whatsapp_id: "desc",
    },
  });
  const onTableStateChange = useCallback(
    async (state) => {
      let paylod = changeTableStateToPayload(state);
      post_json.current = paylod;
      fetchTemplate();
    },
    [whatsapptemplateList, loading, post_json.current]
  );
  const fetchTemplate = async () => {
    try {
      // setLoading(true);
      const response = await postService(
        WHATSAPP_TEMPLATE_LIST_GET,
        post_json.current
      );
      if (response.isError) {
        toast.error(response?.error);
      } else {
        // console.log("email template service ", response.data.data.rows);
        // console.log("COLUMNS", Object.keys(response.data.data.rows[0]));
        if (response.data.data.rows.length > 0) {
          setWhatsappTemplateList(response.data.data.rows);
          setTotalCount(response.data.data.totalCount);
          setLoading(false);
        } else {
          setWhatsappTemplateList([]);
          setTotalCount(0);
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
      // console.log("USER_SERVICE_ERROR", error);
    }
  };

  useEffect(() => {
    fetchTemplate();
  }, []);

  const modalOpen = () => {
    setModalShow(true);
  };

  const modalClose = useCallback(() => {
    setSelectedTemplate(null);
    setModalShow(false);
  }, [modalShow]);

  const deleteWhatsappTemplate = async () => {
    // alert("delete");
    try {
      setLoading(true);
      const response = await getService(
        `${WHATSAPP_TEMPLATE_DELETE}/${selectedTemplate}`,
        {}
      );
      if (response) {
        setModalShow(false);
        if (response.data.success) {
          toast.success(response.data.message);
        }
        if (response.isError) {
          toast.error(response?.error);
        }
        fetchTemplate();
      }
    } catch (error) {
      // console.log("USER_SERVICE_ERROR", error);
    }
  };

  const viewWhatsappModal = async (data) => {
    // console.log("data123==>", data);
    // setViewModalShow(true);
    let {
      created_by,
      whatsapp_content,
      whatsapp_id,
      whatsapp_name,
      status,
      approve,
      whatsapp_category_id,
      whatsapp_sender,
      whatsapp_template_type,
      approved_by,
      whatsapp_call_to_action,
      whatsapp_header_type,
    } = data;
    let whatsData = {
      Id: whatsapp_id ?? "",
      Name: whatsapp_name ?? "",
      "Created By": created_by?.name ?? "",
      "Approved By": approved_by?.name ?? "",
      Description: whatsapp_content ?? "",
      Approve: approve?.value,
      Category: whatsapp_category_id?.category_name,
      "Sender Name": whatsapp_sender,
      "Template Type": whatsapp_template_type?.value,
      "Whatsapp Action": whatsapp_call_to_action?.value ?? "",
      "Header Type": whatsapp_header_type?.value ?? "",
      Status: status?.value ?? "",
    };

    setViewWhatsappdata(whatsData);
    setWhatsViewModalShow(true);
  };

  const viewWhatsModalClose = () => {
    setWhatsViewModalShow(false);
  };

  useEffect(() => {
    console.log("total count whatsapp", totalCount);
    console.log("whatsapptemplateList length", whatsapptemplateList.length);
  }, [totalCount]);

  const advanceData = [
    {
      value: "Title",
      label: "Title",
      type: "string",
      // elements: [
      //   { value: "Sumit Mandol", label: "Sumit Mandol" },
      //   { value: "Indranil Kundu", label: "Indranil Kundu" },
      //   { value: "Swagata Das", label: "Swagata Das" },
      // ],
      placeholder: "Enter Title",
    },
    {
      value: "Content Name",

      type: "string",

      label: "Content Name",
      // elements: [
      //   { value: "1", label: "Brand 1" },
      //   { value: "2", label: "Brand 2" },
      // ],

      placeholder: "Enter Content",
    },
    // {
    //   value: "Registration Date",
    //   label: "Registration Date",
    //   type: "string",
    //   elements: [
    //     {
    //       name: "start_date",
    //       type: "date",
    //       placeholder: "Enter Start Date",
    //       label: "Start Date",
    //     },
    //     {
    //       name: "end_date",
    //       type: "date",
    //       placeholder: "Enter End Date",
    //       label: "End Date",
    //     },
    //   ],
    //   placeholder: "",
    // },
    {
      value: "status",

      type: "string",

      label: "Status",
      elements: [
        { value: "1", label: "Enabled" },
        { value: "2", label: "Disabled" },
      ],
      placeholder: "publishing status",
    },
    {
      value: "Template type",

      type: "string",

      label: "Template Type",
      elements: [
        { value: "1", label: "Transaction" },
        { value: "2", label: "Promotional" },
      ],
      placeholder: "Template type",
    },
  ];
  return (
    // <div>AllWhatsappTemplates</div>

    <Fragment>
      {/* <Button
        variant="success"
        className="d-flex align-items-center justify-content-center add_floating__button position-fixed rounded-circle p-1 avatar-80 lh-1 border border-white border-2"
        onClick={() =>
          redirectTo("template-management/whatsapp-template/create")
        }
      >
        {findIcon("Plus", "outline", "32")}
      </Button> */}
      <Row>
        <Col sm="12" className="p-0 px-md-3">
          <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
            <div className="d-flex flex-column">
              <h3 className="m-0">Whatsapp Templates</h3>
            </div>
            <FilterBar advanceData={advanceData} pageType="whatsapp" />
            <Button
              variant="success"
              className="d-flex align-items-center justify-content-center add_floating__button position-fixed rounded-circle p-1 avatar-80 lh-1 border border-white border-2"
              onClick={() =>
                redirectTo("template-management/whatsapp-template/create")
              }
            >
              {findIcon("Plus", "outline", "32")}
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm="12" className="p-0 px-md-3">
          <Card>
            {/* <Card.Header className="d-flex justify-content-between">
              <Row className="w-100 m-0">
                <Col md="6" className="header-title mb-5 mb-md-0 p-0">
                  <h4 className="card-title">Whatsapp Templates</h4>
                </Col>
                <Col md="6" className="d-flex justify-content-end">
                  <FilterBar advanceData={advanceData} pageType="whatsapp" />
                </Col>
              </Row>
            </Card.Header> */}
            <Card.Body className="p-2 p-md-4">
              <div className="table-responsive">
                {!loading ? (
                  whatsapptemplateList?.length > 0 ? (
                    <ReactTable
                      data={whatsapptemplateList}
                      columns={columns}
                      recordsTotal={totalCount}
                      onTableStateChange={onTableStateChange}
                      initialState={post_json.current}
                      recordsPerPage={10}
                      isRowSelectionAvailable={false}
                    />
                  ) : (
                    <PageNotFound />
                  )
                ) : (
                  <CommonTableLoader />
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <DeleteModal
        show={modalShow}
        modalClose={modalClose}
        onSubmit={deleteWhatsappTemplate}
      />
      <ViewModal
        title={"View Whatsapp Template Information"}
        show={viewWhatsModalShow}
        modalClose={viewWhatsModalClose}
        data={viewWhatsappData}
      />
    </Fragment>
  );
};

export default AllWhatsappTemplates;
