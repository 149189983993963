import { createSlice } from "@reduxjs/toolkit";
import { segmentInnitialState } from "./segment.state";
export const segmentSlice = createSlice({
  name: "segment",
  initialState: segmentInnitialState,
  reducers: {
    addSegment: (state, action) => {
      state.segmentAddData = action.payload;
    },
  },
});

export default segmentSlice.reducer;
