import React from "react";

// layout

import Default from "../../../../../layouts/dashboard/default";
import AllSpecialities from "../pages/all-specialities";

// pages

export const SpecialityManagementRouter = [
  {
    path: "/master-management/speciality",
    element: <Default />,
    children: [
      {
        path: "all",
        element: <AllSpecialities />,
      },
    ],
  },
];
