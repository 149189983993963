import React from "react";
// React-bootstrap
import { Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "../../../../../../components/bootstrap/card";
import ReactEmailEditor from "../../../../../../lib/email-editor/react-email-editor";
import HtmlParser from "../../../../../../lib/html-parser/react-html-parser";

import img5 from "../../../../../../assets/modules/blog/images/tem-E.png";
export default function TemplateCard({
  data = "",
  onClick = "",
  className = "",
}) {

  return (
    <Card className={className}>
      <Card.Header className="card-thumbnail">
        <Image
          src={data?.bank_image}
          alt="02"
          className="img-fluid w-100 rounded object-cover "
          loading="lazy"
        />
      </Card.Header>
      <Card.Body className="card-thumbnail">
        <div className="d-flex flex-column align-items-center justify-content-center">
          {/* <small className="text-primary">
            <span className="text-secondary">Added :</span> 22-03-2023
          </small> */}
          <Link to="/blog/blog-details" className="iq-title">
            <h4 className="mt-2 mb-3 text-ellipsis short-2">
              {data?.bank_name}
            </h4>
          </Link>
          <div>
            <Button type="button" variant="outline-success" onClick={onClick}>
              View Details
            </Button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}
