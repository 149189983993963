import React, { memo, Fragment, useEffect } from "react";

// React-bootstrap
import { Button, Card, Col, Form, Nav, Row, Tab } from "react-bootstrap";

//Component
// import Notificationcard from "../components/notification"
import Notificationcard from "../components/notification-card";

//Img
import img1 from "../../../../assets/modules/social/images/avatar/01.png";
import img2 from "../../../../assets/modules/social/images/avatar/02.png";
import img3 from "../../../../assets/modules/social/images/avatar/03.png";
import img4 from "../../../../assets/modules/social/images/avatar/04.png";
import img5 from "../../../../assets/modules/social/images/avatar/05.png";
import img6 from "../../../../assets/modules/social/images/avatar/06.png";
import img7 from "../../../../assets/modules/social/images/avatar/07.png";
import img8 from "../../../../assets/modules/social/images/avatar/08.png";
import img9 from "../../../../assets/modules/social/images/avatar/09.png";
import img10 from "../../../../assets/modules/social/images/avatar/10.png";
import img11 from "../../../../assets/modules/social/images/avatar/11.png";
import img12 from "../../../../assets/modules/social/images/avatar/12.png";
import img13 from "../../../../assets/modules/social/images/avatar/13.png";
import img14 from "../../../../assets/modules/social/images/avatar/14.png";
import img16 from "../../../../assets/modules/social/images/avatar/16.png";

const Testing = memo(() => {
  const superAdminNotifications = [
    {
      image: img2,
      title: "Sumit Mandol commented on Requisition-2",
      svg: "comment",
      color: "primary",
      date: "30 mins ago",
      read: true,
    },
    {
      image: img3,
      title: "PDF content added by Indranil Kundu",
      svg: "add",
      color: "warning",
      date: " 35 mins ago",
      read: true,
    },
    {
      image: img4,
      title: "PPT content uploaded by Arnab Saha",
      svg: "upload",
      color: "danger",
      date: "1 hour ago",
      read: false,
    },
    {
      image: img5,
      title: "Requisition added by Saif Imroz",
      svg: "add",
      color: "warning",
      date: "2 hour ago",
      read: false,
    },
    {
      image: img6,
      title: "Requisition added by Sanjoy Dey",
      svg: "add",
      color: "warning",
      date: " 3 hour ago",
      read: true,
    },
    {
      image: img7,
      title: "Infographic content uploaded by Swagata Das",
      svg: "upload",
      color: "danger",
      date: "4 hour ago",
      read: true,
    },
    {
      image: img8,
      title: "Article added by Shivam Roy",
      svg: "add",
      color: "warning",
      date: "5 hour ago",
      read: false,
    },
    {
      image: img9,
      title: "Video content added by Indranil Kundu",
      svg: "add",
      color: "warning",
      date: "6 hour ago",
      read: false,
    },
    {
      image: img10,
      title: "HTML content uploaded by Sanjoy Dey",
      svg: "upload",
      color: "danger",
      date: "7 hour ago",
      read: false,
    },
    {
      image: img12,
      title: "Requisition added by Saif Imroz",
      svg: "add",
      color: "warning",
      date: "9 hour ago",
      read: false,
    },
    {
      image: img13,
      title: "PDF content added by Indranil Kundu",
      svg: "add",
      color: "warning",
      date: "10 hour ago",
      read: false,
    },
    {
      image: img14,
      title: "Sumit Mandol commented on Requisition-2",
      svg: "comment",
      color: "primary",
      date: "1 day ago",
      read: false,
    },
    {
      image: img16,
      title: "Sumit Mandol commented on Requisition-3",
      svg: "comment",
      color: "primary",
      date: "1 day ago",
      read: false,
    },
  ];
  const teamNotifications = [
    {
      image: img2,
      title: "Sumit Mandol commented on Requisition-2",
      svg: "comment",
      color: "primary",
      date: "30 mins ago",
      read: false,
    },
    {
      image: img3,
      title: "PDF content added by Indranil Kundu",
      svg: "add",
      color: "warning",
      date: " 35 mins ago",
      read: false,
    },
    {
      image: img4,
      title: "PPT content uploaded by Arnab Saha",
      svg: "upload",
      color: "danger",
      date: "1 hour ago",
      read: true,
    },
    {
      image: img5,
      title: "Saif Imroz commented on Requisition-2",
      svg: "comment",
      color: "primary",
      date: "2 hour ago",
      read: true,
    },
    {
      image: img6,
      title: "Sanjoy Dey commented on Requisition-2",
      svg: "comment",
      color: "primary",
      date: " 3 hour ago",
      read: true,
    },
    {
      image: img7,
      title: "Infographic content added by Swagata Das",
      svg: "add",
      color: "warning",
      date: "4 hour ago",
      read: true,
    },
    {
      image: img8,
      title: "Article added by Shivam Roy",
      svg: "add",
      color: "warning",
      date: "5 hour ago",
      read: false,
    },
    {
      image: img9,
      title: "Video content added by Indranil Kundu",
      svg: "add",
      color: "warning",
      date: "6 hour ago",
      read: true,
    },
    {
      image: img10,
      title: "HTML content uploaded by Sanjoy Dey",
      svg: "upload",
      color: "danger",
      date: "7 hour ago",
      read: true,
    },
    {
      image: img11,
      title: "Arnab Saha commented on Requisition-2",
      svg: "comment",
      color: "primary",
      date: "8 hour ago",
      read: false,
    },
    {
      image: img12,
      title: "Audio content added by Saif Imroz",
      svg: "add",
      color: "warning",
      date: "9 hour ago",
      read: true,
    },
    {
      image: img13,
      title: "PDF content added by Indranil Kundu",
      svg: "add",
      color: "warning",
      date: "10 hour ago",
      read: true,
    },
    {
      image: img14,
      title: "Sumit Mandol commented on Requisition-2",
      svg: "comment",
      color: "primary",
      date: "1 day ago",
      read: true,
    },
    {
      image: img16,
      title: "Sumit Mandol commented on Requisition-3",
      svg: "comment",
      color: "primary",
      date: "1 day ago",
      read: true,
    },
  ];
  return (
    <Fragment>
      <Row>
        <Col lg="12" md="12">
          <Card className="card-block">
            <Tab.Container defaultActiveKey="first">
              <div className="tab-bottom-bordered">
                <Nav
                  className="mb-0 nav-tabs justify-content-around d-flex"
                  id="nav-tab1"
                  role="tablist"
                >
                  <Nav.Link
                    className="py-3 "
                    eventKey="first"
                    data-bs-toggle="tab"
                    data-bs-target="#for-custom-user"
                    type="button"
                    role="tab"
                    aria-controls="for-custom-user"
                    aria-selected="true"
                  >
                    For Custom User
                  </Nav.Link>
                  <Nav.Link
                    className="py-3"
                    eventKey="second"
                    data-bs-toggle="tab"
                    data-bs-target="#for-platform-user"
                    type="button"
                    role="tab"
                    aria-controls="for-platform-user"
                    aria-selected="false"
                  >
                    For Clirnet User
                  </Nav.Link>
                </Nav>
              </div>
              <Tab.Content className="iq-tab-fade-up" id="nav-tabContent">
                <Tab.Pane
                  eventKey="first"
                  id="for-custom-user"
                  role="tabpanel"
                  aria-labelledby="for-custom-user"
                >
                  <Card.Body>
                    <div className=" bg-soft-secondary rounded-3">
                      <Row className="justify-content-center align-items-start">
                        <Col lg={12} xs={12} className="position-relative">
                          <div className="p-4 bg-white rounded-3 h-100 d-flex flex-column justify-content-between">
                            <Row>
                              <Col md={4} className="mb-4">
                                <Form.Floating className="custom-form-floating">
                                  <Form.Select
                                    id="category_id"
                                    name="category_id"
                                    // value={values?.category_name}
                                    // isInvalid={
                                    //   touched.category_name &&
                                    //   !!errors.category_name
                                    // }
                                    // onChange={handleChange}
                                  >
                                    <option value="">Select Channel</option>
                                    <option key={1} value={"value"}>
                                      buisness
                                    </option>
                                  </Form.Select>
                                  <Form.Label htmlFor="category_id">
                                    Channel
                                  </Form.Label>
                                  <Form.Control.Feedback type="invalid">
                                    {/* {errors.category_name} */}
                                  </Form.Control.Feedback>
                                </Form.Floating>
                              </Col>

                              <Col md={4} className="mb-4">
                                <Form.Floating className="custom-form-floating">
                                  <Form.Select
                                    id="category_id"
                                    name="category_id"
                                    // value={values?.category_name}
                                    // isInvalid={
                                    //   touched.category_name &&
                                    //   !!errors.category_name
                                    // }
                                    // onChange={handleChange}
                                  >
                                    <option value="">Select Vendor</option>
                                    <option key={1} value={"value"}>
                                      Select Vendor
                                    </option>
                                  </Form.Select>
                                  <Form.Label htmlFor="category_id">
                                    Vendor
                                  </Form.Label>
                                  <Form.Control.Feedback type="invalid">
                                    {/* {errors.category_name} */}
                                  </Form.Control.Feedback>
                                </Form.Floating>
                              </Col>

                              <Col md={4} className="mb-4">
                                <Form.Floating className="custom-form-floating">
                                  <Form.Select
                                    id="category_id"
                                    name="category_id"
                                    // value={values?.category_name}
                                    // isInvalid={
                                    //   touched.category_name &&
                                    //   !!errors.category_name
                                    // }
                                    // onChange={handleChange}
                                  >
                                    <option value="">Select Template</option>
                                    <option key={1} value={"value"}>
                                      Template
                                    </option>
                                  </Form.Select>
                                  <Form.Label htmlFor="category_id">
                                    Template
                                  </Form.Label>
                                  <Form.Control.Feedback type="invalid">
                                    {/* {errors.category_name} */}
                                  </Form.Control.Feedback>
                                </Form.Floating>
                              </Col>

                              <Col lg={6} className="mb-3">
                                <Form.Floating className="custom-form-floating">
                                  <Form.Control
                                    // as="textarea"
                                    type="text"
                                    className="h-100"
                                    id="floatingInput1"
                                    autoComplete="Name"
                                    placeholder="Name"
                                    name="name"
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    // value={values.name}
                                    // isInvalid={errors.name}
                                    // disabled={suggestionIsLoading}
                                  />
                                  <Form.Label htmlFor="floatingInput">
                                    Reciver's Name
                                  </Form.Label>
                                  <Form.Control.Feedback type="invalid">
                                    {/* {errors.name} */}
                                  </Form.Control.Feedback>
                                </Form.Floating>
                              </Col>
                              <Col lg={6} className="mb-3">
                                <Form.Floating className="custom-form-floating">
                                  <Form.Control
                                    // as="textarea"
                                    type="text"
                                    className="h-100"
                                    id="floatingInput1"
                                    autoComplete="Name"
                                    placeholder="Name"
                                    name="description"
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    // value={values.description}
                                    // isInvalid={errors.description}
                                    // disabled={suggestionIsLoading}
                                  />
                                  <Form.Label htmlFor="floatingInput">
                                    Reciver's Email
                                  </Form.Label>
                                  <Form.Control.Feedback type="invalid">
                                    {/* {errors.description} */}
                                  </Form.Control.Feedback>
                                </Form.Floating>
                              </Col>
                              <Col lg={12} className="mb-3">
                                <Form.Floating className="custom-form-floating">
                                  <Form.Control
                                    as="textarea"
                                    type="text"
                                    className="h-100"
                                    id="floatingInput1"
                                    autoComplete="Name"
                                    placeholder="Name"
                                    name="description"
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    // value={values.description}
                                    // isInvalid={errors.description}
                                    // disabled={suggestionIsLoading}
                                  />
                                  <Form.Label htmlFor="floatingInput">
                                    Template Params *
                                  </Form.Label>
                                  <Form.Control.Feedback type="invalid">
                                    {/* {errors.description} */}
                                  </Form.Control.Feedback>
                                </Form.Floating>
                              </Col>
                              <Col
                                lg={12}
                                className="mb-3 d-flex justify-content-end"
                              >
                                <Button variant="info ">Send</Button>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Card.Body>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="second"
                  id="for-platform-user"
                  role="tabpanel"
                  aria-labelledby="for-platform-user"
                >
                  <Card.Body>
                    <div className=" bg-soft-secondary rounded-3">
                      <Row className="justify-content-center align-items-start">
                        <Col lg={12} xs={12} className="position-relative">
                          <div className="p-4 bg-white rounded-3 h-100 d-flex flex-column justify-content-between">
                            <Row>
                              <Col lg={3} className="mb-3">
                                <Form.Floating className="custom-form-floating">
                                  <Form.Control
                                    // as="textarea"
                                    type="text"
                                    className="h-100"
                                    id="floatingInput1"
                                    autoComplete="Name"
                                    placeholder="Name"
                                    name="name"
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    // value={values.name}
                                    // isInvalid={errors.name}
                                    // disabled={suggestionIsLoading}
                                  />
                                  <Form.Label htmlFor="floatingInput">
                                    User Id
                                  </Form.Label>
                                  <Form.Control.Feedback type="invalid">
                                    {/* {errors.name} */}
                                  </Form.Control.Feedback>
                                </Form.Floating>
                              </Col>
                              <Col md={3} className="mb-4">
                                <Form.Floating className="custom-form-floating">
                                  <Form.Select
                                    id="category_id"
                                    name="category_id"
                                    // value={values?.category_name}
                                    // isInvalid={
                                    //   touched.category_name &&
                                    //   !!errors.category_name
                                    // }
                                    // onChange={handleChange}
                                  >
                                    <option value="">Select Channel</option>
                                    <option key={1} value={"value"}>
                                      buisness
                                    </option>
                                  </Form.Select>
                                  <Form.Label htmlFor="category_id">
                                    Channel
                                  </Form.Label>
                                  <Form.Control.Feedback type="invalid">
                                    {/* {errors.category_name} */}
                                  </Form.Control.Feedback>
                                </Form.Floating>
                              </Col>

                              <Col md={3} className="mb-4">
                                <Form.Floating className="custom-form-floating">
                                  <Form.Select
                                    id="category_id"
                                    name="category_id"
                                    // value={values?.category_name}
                                    // isInvalid={
                                    //   touched.category_name &&
                                    //   !!errors.category_name
                                    // }
                                    // onChange={handleChange}
                                  >
                                    <option value="">Select Vendor</option>
                                    <option key={1} value={"value"}>
                                      Select Vendor
                                    </option>
                                  </Form.Select>
                                  <Form.Label htmlFor="category_id">
                                    Vendor
                                  </Form.Label>
                                  <Form.Control.Feedback type="invalid">
                                    {/* {errors.category_name} */}
                                  </Form.Control.Feedback>
                                </Form.Floating>
                              </Col>

                              <Col md={3} className="mb-4">
                                <Form.Floating className="custom-form-floating">
                                  <Form.Select
                                    id="category_id"
                                    name="category_id"
                                    // value={values?.category_name}
                                    // isInvalid={
                                    //   touched.category_name &&
                                    //   !!errors.category_name
                                    // }
                                    // onChange={handleChange}
                                  >
                                    <option value="">Select Template</option>
                                    <option key={1} value={"value"}>
                                      Template
                                    </option>
                                  </Form.Select>
                                  <Form.Label htmlFor="category_id">
                                    Template
                                  </Form.Label>
                                  <Form.Control.Feedback type="invalid">
                                    {/* {errors.category_name} */}
                                  </Form.Control.Feedback>
                                </Form.Floating>
                              </Col>

                              <Col lg={6} className="mb-3">
                                <Form.Floating className="custom-form-floating">
                                  <Form.Control
                                    as="textarea"
                                    type="text"
                                    className="h-100"
                                    id="floatingInput1"
                                    autoComplete="Name"
                                    placeholder="Name"
                                    name="description"
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    // value={values.description}
                                    // isInvalid={errors.description}
                                    // disabled={suggestionIsLoading}
                                  />
                                  <Form.Label htmlFor="floatingInput">
                                    Template Params *
                                  </Form.Label>
                                  <Form.Control.Feedback type="invalid">
                                    {/* {errors.description} */}
                                  </Form.Control.Feedback>
                                </Form.Floating>
                              </Col>
                              {/* <Col lg={12} className="mb-3">
                                <Form.Floating className="custom-form-floating">
                                  <Form.Control
                                    as="textarea"
                                    type="text"
                                    className="h-100"
                                    id="floatingInput1"
                                    autoComplete="Name"
                                    placeholder="Name"
                                    name="description"
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    // value={values.description}
                                    // isInvalid={errors.description}
                                    // disabled={suggestionIsLoading}
                                  />
                                  <Form.Label htmlFor="floatingInput">
                                    Template Params *
                                  </Form.Label>
                                  <Form.Control.Feedback type="invalid">
                                    {/* {errors.description} 
                                  </Form.Control.Feedback>
                                </Form.Floating>
                              </Col> */}
                              <Col
                                lg={6}
                                className="mb-3 d-flex justify-content-end"
                              >
                                <Button variant="info h-50 align-bottom">
                                  Send
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Card.Body>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="three"
                  id="requently-created"
                  role="tabpanel"
                  aria-labelledby="requently-created"
                >
                  <div className="table-responsive p-3">
                    {/* <ReactTable
                      data={recently_used_segment_data}
                      columns={columns_recently_used}
                      recordsTotal={10}
                    /> */}
                    <div className="d-flex justify-content-start mt-4">
                      <Button
                        type="button"
                        variant="outline-info btn-sm rounded-2"
                        // onClick={onRecentClick}
                      >
                        {" "}
                        View More
                      </Button>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Card>
          {/* <CommonTableSkeleton /> */}
        </Col>
      </Row>
    </Fragment>
  );
});
Testing.displayName = "Testing";
export default Testing;
