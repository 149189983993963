import React, { memo, useEffect, useRef, useState } from "react";
import { Badge, Button, Card, Col, Form, Row } from "react-bootstrap";
import { findIcon } from "../../../../dashboard/icons";
import mobile from "../../../../../assets/images/mobile-bg.png";
import postService from "../../../../../api/services/post-service";
import {
  SMS_TEMPLATE_CREATE_GET,
  SMS_TEMPLATE_EDIT_GET,
  SMS_TEMPLATE_UPDATE,
  SMS_TEMPLATE_ADD,
} from "../../../../../api/endpoints/template-management-endpoints";
import { getService } from "../../../../../api/services/get-services";
import * as Yup from "yup";
import { useFormik } from "formik";
import postServiceSmsGenarator from "../../../../../api/services/post-service-sms-generator";
import TemplateSuggestionCard from "../../components/template-suggestion-card";
import Masonry from "react-masonry-css";
import useRedirect from "../../../../../components/custom-hooks/use-redirect";
import toast from "react-hot-toast";
import { useLocation, useParams } from "react-router-dom";
import PaginationLoader from "../../../../../components/loader/pagination-loader";
import SubmitButton from "../../../../../components/form-components/submit-button";

const breakpointColumnsObj = {
  default: 3,
  1100: 2,
  // 768: 2,
  500: 1,
};

const CreateSmsTemplate = ({ modalClose, type }) => {
  const [cameFrom, setCameFrom] = useState("edit");
  const [editType, setEditType] = useState("");
  const [smsText, setSmsText] = useState("");
  const [openSmsSugession, setOpenSmsSugession] = useState(false);
  const [loading, setLoading] = useState(false);
  const [suggestionIsLoading, setSuggestionIsLoading] = useState(false);
  const [selectedSms, setSelectedSms] = useState("");
  const [templateTone, setTemplateTone] = useState("");
  const [TemplateVariableType, setTemplateVariableType] = useState([]);
  const [templateVariableList, setTemplateVariableList] = useState([]);
  const [selectedVariableType, setSelectedVariableType] = useState("");
  const [generatedSmsList, setGeneratedSmsList] = useState([]);
  const [templateCategory, setTemplateCategory] = useState([]);
  const [templateId, setTemplateId] = useState("");
  const params = useParams();
  const templateValues = useRef({});
  const templateContentRef = useRef(null);
  const { state } = useLocation();
  const initialValues = {
    name: "",
    description: "",
    template_name: "",
    sms_category: "",
    sms_template_type: "",
    // template_id: "",
    sender: "",
    sms_dlt_id: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string(),
    description: Yup.string(),
    template_name: Yup.string().required("Please Enter Template Name"),
    sms_category: Yup.string().required("Please select category"),
    sms_template_type: Yup.string().required("Please Select Template Type"),
    sender: Yup.string().required("Please Enter Sender"),
    sms_dlt_id: Yup.string(),
  });
  //using useFormik hook

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    handleReset,
    values,
    errors,
    touched,
    setErrors,
    setValues,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      let {
        template_name,
        sms_category,
        sms_template_type,
        // template_id,
        sender,
        sms_dlt_id,
      } = values;

      let obj = {
        sms_name: template_name,
        sms_content: smsText,
        sms_category_id: sms_category,
        sms_template_type: sms_template_type,
        // sms_flow_id: template_id,
        sms_sender: sender,
        sms_dlt_id: sms_dlt_id,
        //need to make dynamic
        sms_duedate: "2023-06-09",
        sms_priority_id: 1,
        sms_workflow_id: 5,
      };

      const newObj = validateObject(obj);
      // console.log("returned object ==>", newObj);

      if (templateId == "") {
        handleTemplateCreate(newObj);
      } else {
        handleTemplateEdit(newObj);
      }
    },
  });

  const validateObject = (obj) => {
    // console.log("obj to validate ==>", obj);
    const newObj = {};

    for (const [key, value] of Object.entries(obj)) {
      if (typeof value === "string") {
        newObj[key] = value.trimStart(); // Remove leading spaces
      } else {
        newObj[key] = value;
      }
    }

    return newObj;
  };

  const handleTemplateCreate = async (obj) => {
    try {
      setLoading(true);
      const response = await postService(SMS_TEMPLATE_ADD, obj);
      if (response.isError) {
        toast.error(response?.error);
        setLoading(false);
      }
      if (response?.data?.success == true) {
        toast.success(response.data.message);
        let sms_id = response.data.data.sms_id;
        let actionAddResponse = await postService(
          "/template_sms/second-step-add/" + sms_id,
          {}
        );
        console.log("actionAddResponse", actionAddResponse);
        if (actionAddResponse?.data?.success == true) {
          // setLoading(false);
          redirectTo("template-management/sms-template");
        }
      } else {
        toast.success("Failed To Create Template.");
        setLoading(false);
      }
    } catch (error) {
      console.log("error while create template ==>", error);
      setLoading(false);
    }
  };

  const fetchSmsCreateData = async () => {
    try {
      setLoading(true);
      const response = await getService(SMS_TEMPLATE_CREATE_GET);
      console.log("sms-TemplateField", response.data.data);
      if (response.data.data.variable) {
        console.log("sms Field data", response.data.data.variable);
        let data = response.data.data.variable;
        let allVariableType = data.map((item) => item.variable_type);
        console.log("allVariableType ==>", allVariableType);
        const uniqueSet = new Set(allVariableType.map((item) => item));
        const uniqueVariableType = [...uniqueSet];
        console.log("uniqueVariableType ==>", uniqueVariableType);
        setTemplateVariableType(uniqueVariableType);
        setSelectedVariableType(uniqueVariableType[0]);
        let variableTypeList = [];
        uniqueVariableType.forEach((item) => {
          let temp = data.filter((item1) => item1.variable_type == item);
          variableTypeList.push(temp);
        });
        console.log("variableTypeList:: ==>", variableTypeList);
        setTemplateVariableList(variableTypeList);

        setLoading(false);
      }
      if (response.data.data.category) {
        setTemplateCategory(response.data.data.category);
        setLoading(false);
      }
      if (response.isError) {
        toast.error(response?.error);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      // console.log("TemplateField", error);
    }
  };

  const handleTemplateEdit = async (obj) => {
    try {
      setLoading(true);
      const response = await postService(
        SMS_TEMPLATE_UPDATE + `/${templateId}`,
        obj
      );
      if (response.isError) {
        toast.error(response?.error);
        setLoading(false);
      }
      // console.log("response after create template ==>", response);
      if (response.data.success == true) {
        setLoading(false);
        toast.success(response.data.message);
        redirectTo("template-management/sms-template");
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log("error while create template ==>", error);
      setLoading(false);
    }
  };

  const checkValidation = () => {
    if (values.name == "") {
      setErrors({ ...errors, name: "Please enter template name" });
      return;
    }
    if (values.description == "") {
      setErrors({ ...errors, description: "Please enter description" });
      return;
    }
    if (!errors.name && !errors.description) {
      getSmsTemplateSuggestion();
    }
  };

  const handleSmsText = (e) => {
    if (e.target.value.length > 160) {
      toast.error("SMS content should be less than 160 characters");
      return false;
    }
    setSmsText(e.target.value);
  };

  const handleSmsSugession = (e) => {
    setSmsText(`${smsText} ${e.target.outerText}`);
  };

  const handleEmojiClick = (data) => {
    // alert("handleEmojiClick" + data);
    setTemplateTone(data);
  };

  useEffect(() => {
    // console.log("state::==>", state);
    fetchSmsCreateData();
    const id = params.id;
    // console.log("id===>", id);
    if (!id) {
      if (state?.smsEditType) {
        setEditType(state?.smsEditType);
        setSmsText(state?.suggestionSmsContent);
      } else {
        setEditType("autoGenerateFrom");
        setTemplateId("");
      }
    } else {
      setEditType("newTemplate");
      setTemplateId(id);
      fetchSmsTemplateDetails(id);
    }
  }, []);

  const fetchSmsTemplateDetails = async (id) => {
    try {
      setLoading(true);
      const response = await getService(SMS_TEMPLATE_EDIT_GET + `/${id}`);
      // console.log("SMS_TEMPLATE_DETAILS_GET", response.data);
      if (response.data.success == true) {
        const content = response.data.data.template.sms_content.replace(
          /^<p>(.*)<\/p>$/,
          "$1"
        );
        // console.log("content", content);
        setSmsText(content);
        let myObj = {
          template_name: response.data.data.template.sms_name,
          sms_category:
            response.data.data.template.sms_category_id?.category_id,
          sms_template_type: response.data.data.template.sms_template_type?.id,
          // template_id: response.data.data.template.sms_flow_id,
          sender: response.data.data.template.sms_sender,
          sms_dlt_id: response.data.data.template.sms_dlt_id
            ? response.data.data.template.sms_dlt_id
            : "",
        };
        setValues({
          ...values,
          ...myObj,
        });
        // templateValues.current.value = myObj;
        templateValues.current = myObj;
        setTemplateCategory(response.data.data.category);
        setLoading(false);
      }
      if (response.isError) {
        toast.error(response?.error);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      // console.log("TemplateField", error);
    }
  };

  const getSmsTemplateSuggestion = async () => {
    // const data = {
    //   output:
    //     '{"sms1":"Dear [NAME], don\'t miss out on our latest content update! Discover new insights and stay ahead of the competition. Click [LINK] to read now!","sms2":"Attention [NAME], our platform just got even better! Check out our latest content and gain a competitive edge. Click [LINK] to read now!","sms3":"Exciting news, [NAME]! We\'ve added new content to our platform that you won\'t want to miss. Stay ahead of the game and click [LINK] to read now!","sms4":"[NAME], you don\'t want to miss out on our latest content update. Gain valuable insights and stay ahead of the curve. Click [LINK] to read now!","sms5":"Stay ahead of the competition, [NAME]! Our latest content update is now live. Don\'t miss out on valuable insights. Click [LINK] to read now!"}',
    // };
    // parseGeneratedSmsList(data.output);
    setSuggestionIsLoading(true);
    const templateSuggestionPayload = {
      action: values.name + " " + values.description,
      tone: templateTone,
      type: "sms",
    };
    try {
      // setVariableIsLoading(true);
      const response = await postServiceSmsGenarator(templateSuggestionPayload);
      console.log("suggestion list ", response.data);
      setSuggestionIsLoading(false);
      parseGeneratedSmsList(response.data.output);
    } catch (error) {
      setSuggestionIsLoading(false);
      console.log("USER_SERVICE_ERROR", error);
    }
  };

  const parseGeneratedSmsList = (data) => {
    console.log("parseGeneratedSmsList", data);
    if (data) {
      const smsList = JSON.parse(data);
      let generatedList = smsList.map((item) => {
        return Object.values(item)[0];
      });
      setGeneratedSmsList(generatedList);
    }
  };

  const onCreateBtnClick = () => {
    setEditType("newTemplate");
  };

  const onTemplateSuggestionClick = (data) => {
    setSelectedSms(data);
    // setEditType("templateSuggestion");
  };

  const onContinueToEditClick = () => {
    setEditType("newTemplate");
    setSmsText(selectedSms);
  };

  const { redirectTo } = useRedirect();

  const handleCloseClick = () => {
    if (type === "content-studio") {
      modalClose();
    } else {
      redirectTo("template-management/sms-template");
    }
  };

  const handleTemplateVariableClick = (e) => {
    const textarea = templateContentRef.current;
    if (textarea) {
      const textToAdd = e.target.outerText;
      const startPos = textarea.selectionStart;
      const endPos = textarea.selectionEnd;
      const textBefore = textarea.value.substring(0, startPos);
      const textAfter = textarea.value.substring(endPos);
      const newText = textBefore + textToAdd + textAfter;

      setSmsText(newText);
    }
  };

  return (
    <>
      {cameFrom == "edit" && (
        <Card>
          <Card.Header className="py-3">
            <Row className="align-items-center">
              <div className="d-flex justify-content-between">
                <h3 className="m-0">
                  {/* {cameFrom == "edit" && "SMS: Create Template"} */}
                  Create SMS Template
                  {cameFrom == "view" && "SMS: View SMS"}{" "}
                </h3>
                <div className="d-flex justify-content-start gap-4">
                  <a
                    href="javascript:void(0)"
                    eventKey="link-1"
                    className="d-inline-flex align-items-center gap-1"
                  >
                    {findIcon("Setting", "dual-tone", 22)}
                    Save Draft
                  </a>
                  <a
                    href="javascript:void(0)"
                    eventKey="link-1"
                    className="d-inline-flex align-items-center gap-1 link-danger"
                    onClick={handleCloseClick}
                  >
                    {findIcon("Square X", "dual-tone", 22)}
                    Close
                  </a>
                </div>
              </div>
            </Row>
          </Card.Header>

          {editType == "newTemplate" && (
            <Card.Body className="pt-2">
              <div className="p-4 bg-soft-secondary rounded-3">
                <Row className="justify-content-center align-items-start">
                  <Col lg={9} className="position-relative mt-3">
                    <div className="p-4 bg-white rounded-3 d-flex flex-column">
                      <Row>
                        {/* name  */}
                        <Col lg={4} md={6} className="mb-4">
                          <Form.Floating className="custom-form-floating">
                            <Form.Control
                              type="text"
                              className=""
                              id="template_name"
                              autoComplete="Name"
                              placeholder="Name"
                              name="template_name"
                              onChange={handleChange}
                              value={values.template_name}
                              isInvalid={
                                touched.template_name && !!errors.template_name
                              }
                            />
                            <Form.Label htmlFor="template_namename">
                              Name
                            </Form.Label>
                            <Form.Control.Feedback type="invalid">
                              {errors.template_name}
                            </Form.Control.Feedback>
                          </Form.Floating>
                        </Col>

                        {/* sender  */}
                        <Col lg={4} md={6} className="mb-4">
                          <Form.Floating className="custom-form-floating">
                            <Form.Control
                              type="text"
                              className=""
                              id="sender"
                              autoComplete="Name"
                              placeholder="Sender"
                              name="sender"
                              onChange={handleChange}
                              value={values.sender}
                              isInvalid={touched.sender && !!errors.sender}
                            />
                            <Form.Label htmlFor="sender">Sender</Form.Label>
                            <Form.Control.Feedback type="invalid">
                              {errors.sender}
                            </Form.Control.Feedback>
                          </Form.Floating>
                        </Col>

                        {/* category  */}
                        <Col lg={4} md={6} className="mb-4">
                          <Form.Floating className="custom-form-floating form-floating">
                            <Form.Select
                              name="sms_category"
                              value={values.sms_category}
                              isInvalid={
                                touched.sms_category && !!errors.sms_category
                              }
                              onChange={handleChange}
                            >
                              <option value="">Select Category</option>
                              {templateCategory?.map((item, index) => (
                                <option key={index} value={item.category_id}>
                                  {item.category_name}
                                </option>
                              ))}
                            </Form.Select>
                            <Form.Label htmlFor="sms_category">
                              Category
                            </Form.Label>
                            <Form.Control.Feedback type="invalid">
                              {errors.sms_category}
                            </Form.Control.Feedback>
                          </Form.Floating>
                        </Col>

                        {/* template type  */}
                        <Col lg={4} md={6} className="mb-4">
                          <Form.Floating className="custom-form-floating">
                            <Form.Select
                              id="sms_template_type"
                              name="sms_template_type"
                              value={values.sms_template_type}
                              isInvalid={
                                touched.sms_template_type &&
                                !!errors.sms_template_type
                              }
                              onChange={handleChange}
                            >
                              <option value="">Select Template Type</option>
                              <option value="0">Promotional</option>
                              <option value="1">Transactional</option>
                            </Form.Select>
                            <Form.Label htmlFor="sms_template_type">
                              Template Type
                            </Form.Label>
                            <Form.Control.Feedback type="invalid">
                              {errors.sms_template_type}
                            </Form.Control.Feedback>
                          </Form.Floating>
                        </Col>

                        {/* template id  */}

                        {/* dlt id  */}
                        <Col lg={4} md={6} className="mb-4">
                          <Form.Floating className="custom-form-floating">
                            <Form.Control
                              type="text"
                              className=""
                              id="sms_dlt_id"
                              autoComplete="Name"
                              placeholder="DLT ID"
                              name="sms_dlt_id"
                              onChange={handleChange}
                              value={values.sms_dlt_id}
                              isInvalid={
                                touched.sms_dlt_id && !!errors.sms_dlt_id
                              }
                            />
                            <Form.Label htmlFor="template_namename">
                              DLT ID
                            </Form.Label>
                            <Form.Control.Feedback type="invalid">
                              {errors.sms_dlt_id}
                            </Form.Control.Feedback>
                          </Form.Floating>
                        </Col>

                        {/* content  */}
                        <Col lg={12}>
                          <Form.Floating className="custom-form-floating position-relative d-flex flex-column">
                            <textarea
                              ref={templateContentRef}
                              className={
                                smsText?.length <= 150
                                  ? "form-control border border-1 border-danger"
                                  : "form-control"
                              }
                              aria-label="With textarea"
                              value={smsText}
                              onChange={handleSmsText}
                            ></textarea>
                            <Form.Label htmlFor="floatingInput">
                              Message
                            </Form.Label>
                            <Button
                              onClick={() =>
                                setOpenSmsSugession(!openSmsSugession)
                              }
                              variant="warning"
                              className="btn-icon w-auto px-2 position-absolute sms-suggestion__button"
                            >
                              {findIcon("Suggestion", "dual-tone", "24")}
                            </Button>
                          </Form.Floating>
                          <div className="d-flex justify-content-between align-items-center mt-1">
                            <div className="ms-auto">{smsText?.length} sms</div>
                          </div>
                        </Col>
                      </Row>
                    </div>

                    {openSmsSugession == true ? (
                      <Col lg={12} className="mt-4">
                        <div className="w-100 rounded-3 sms-suggestion mt-3 p-3 h-auto bg-white z-index-2">
                          <div className="justify-content-between align-items-center mb-3 d-flex">
                            <h5 className="mb-0">Message Suggestion</h5>
                            <a
                              href="javascript:void(0)"
                              onClick={() => setOpenSmsSugession(false)}
                              className="d-inline-flex align-items-center gap-1 link-danger"
                            >
                              {findIcon("Square X", "dual-tone", 22)}
                              Close
                            </a>
                          </div>
                          <div className="d-flex gap-3 p-2 border border-1 rounded-3">
                            <div
                              className="nav sms-suggestion__tabNav gap-2 overflow-auto flex-shrink-0 flex-column"
                              id="v-pills-tab"
                              role="tablist"
                              aria-orientation="vertical"
                            >
                              {TemplateVariableType.map((item) => (
                                <a
                                  href="javascript:void(0)"
                                  className={`position-relative nav-link flex-grow-1 rounded-3  ${
                                    selectedVariableType == item
                                      ? "bg-primary text-white active"
                                      : "bg-soft-primary text-dark"
                                  }`}
                                  id="v-pills-home-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#v-pills-home"
                                  type="button"
                                  role="tab"
                                  aria-controls="v-pills-home"
                                  aria-selected="true"
                                  onClick={(e) => setSelectedVariableType(item)}
                                >
                                  {item}
                                  <div className="sms-suggestion__tabNav__icon position-absolute transition">
                                    {findIcon("Arrow Right", "solid", "16")}
                                  </div>
                                </a>
                              ))}
                            </div>

                            <div
                              className="tab-content flex-grow-1 p-2"
                              id="v-pills-tabContent"
                            >
                              <div
                                className="tab-pane d-flex gap-2 fade show active flex-wrap "
                                role="tabpanel"
                                aria-labelledby="v-pills-home-tab"
                              >
                                {templateVariableList.map((item) =>
                                  item.map((item1) =>
                                    item1.variable_type ==
                                    selectedVariableType ? (
                                      <Badge
                                        pill
                                        bg="soft-info"
                                        className="fw-normal px-3 border border-1 border-info cursor-pointer"
                                        onClick={(e) => {
                                          handleTemplateVariableClick(e);
                                          setOpenSmsSugession(false);
                                        }}
                                      >
                                        {item1.variable_value}
                                      </Badge>
                                    ) : null
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    ) : null}

                    <Col lg={12} className="mt-5 d-flex justify-content-end">
                      <SubmitButton
                        variant="primary"
                        onClick={handleSubmit}
                        disabled={smsText.length == 0}
                      >
                        {templateId ? (
                          loading ? (
                            <PaginationLoader />
                          ) : (
                            "Update"
                          )
                        ) : loading ? (
                          <PaginationLoader />
                        ) : (
                          "Send for Approval"
                        )}
                      </SubmitButton>
                    </Col>
                  </Col>
                  <Col lg={3} xs={12} className="position-sticky top-0">
                    <div className="bg-soft-info h-100 p-2 rounded-5 border border-2 border-dashed border-info d-flex flex-column">
                      <div className="d-flex position-relative mobile-preview">
                        {/* <p>Preview is not available for SMS channel</p> */}
                        <img
                          src={mobile}
                          alt="mobile"
                          className="mobile h-auto w-100 position-relative"
                        />
                        <div className="p-2 h-100 w-100 position-absolute d-flex pt-5 pb-4">
                          <div className="px-5 mx-100 overflow-auto w-100 flex-grow-1 mt-3">
                            <span className="d-block position-relative px-3 py-2 rounded-3 text-dark fs-7 lh-base shadow-lg shadow bg-white">
                              {smsText}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card.Body>
          )}
          {editType == "autoGenerateFrom" && (
            <Card.Body>
              <div className="p-4 bg-soft-secondary rounded-3">
                <Row className="justify-content-center align-items-start">
                  <Col lg={9} xs={12} className="position-relative">
                    <div className="p-4 bg-white rounded-3 h-100 d-flex flex-column justify-content-between">
                      <div>
                        <Row>
                          <Col lg={9} className="mb-3">
                            <Form.Floating className="custom-form-floating">
                              <Form.Control
                                type="text"
                                className=""
                                id="floatingInput1"
                                autoComplete="Name"
                                placeholder="Name"
                                name="name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                                isInvalid={errors.name}
                                disabled={suggestionIsLoading}
                              />
                              <Form.Label htmlFor="floatingInput">
                                Name
                              </Form.Label>
                              <Form.Control.Feedback type="invalid">
                                {errors.name}
                              </Form.Control.Feedback>
                            </Form.Floating>
                          </Col>
                          <Col lg={9} className="mb-3">
                            <Form.Floating className="h-100 custom-form-floating">
                              <Form.Control
                                as="textarea"
                                type="text"
                                className="h-100"
                                id="floatingInput1"
                                autoComplete="Name"
                                placeholder="Name"
                                name="description"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.description}
                                isInvalid={errors.description}
                                disabled={suggestionIsLoading}
                              />
                              <Form.Label htmlFor="floatingInput">
                                Prompt for Template Creation
                              </Form.Label>
                              <Form.Control.Feedback type="invalid">
                                {errors.description}
                              </Form.Control.Feedback>
                            </Form.Floating>
                          </Col>
                          <Col lg={9} className="mb-4 mt-auto ">
                            <p>Tone Selector:</p>
                            <div className="col-12 align-item-between d-flex gap-3">
                              <span
                                aria-label="Professional"
                                onClick={() => handleEmojiClick("Professional")}
                                className={`${
                                  templateTone == "Professional"
                                    ? "border-warning fw-semibold"
                                    : "text-secondary"
                                } border border-1 rounded-3 px-3 py-2 bg-soft-warning `}
                              >
                                Professional
                              </span>
                              <span
                                aria-label="Casual"
                                onClick={() => handleEmojiClick("Casual")}
                                className={`${
                                  templateTone == "Casual"
                                    ? "border-warning fw-semibold"
                                    : "text-secondary"
                                } border border-1 rounded-3 px-3 py-2 bg-soft-warning `}
                              >
                                Casual
                              </span>
                              <span
                                aria-label="Serious"
                                onClick={() => handleEmojiClick("Serious")}
                                className={`${
                                  templateTone == "Serious"
                                    ? "border-warning fw-semibold"
                                    : "text-secondary"
                                } border border-1 rounded-3 px-3 py-2 bg-soft-warning `}
                              >
                                Serious
                              </span>
                              <span
                                aria-label="Humorous"
                                onClick={() => handleEmojiClick("Humorous")}
                                className={`${
                                  templateTone == "Humorous"
                                    ? "border-warning fw-semibold"
                                    : "text-secondary"
                                } border border-1 rounded-3 px-3 py-2 bg-soft-warning `}
                              >
                                Humorous
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <Col lg={12}>
                        <Masonry
                          breakpointCols={breakpointColumnsObj}
                          className="my-masonry-grid"
                          columnClassName="my-masonry-grid_column"
                        >
                          {generatedSmsList?.length > 0 &&
                            generatedSmsList.map((item, index) => (
                              <>
                                <TemplateSuggestionCard
                                  data={item}
                                  click={onTemplateSuggestionClick}
                                  selectedSms={selectedSms}
                                  type=""
                                />
                              </>
                            ))}
                        </Masonry>
                      </Col>
                      <Col className="flex-grow-0">
                        <div className="d-flex gap-3 justify-content-end">
                          <Button
                            variant="info"
                            onClick={onCreateBtnClick}
                            disabled={suggestionIsLoading}
                          >
                            Skip
                          </Button>
                          <Button
                            variant="primary"
                            onClick={checkValidation}
                            disabled={suggestionIsLoading}
                          >
                            {!suggestionIsLoading
                              ? "Auto Generate"
                              : "Generating Suggestion..."}
                          </Button>
                          {selectedSms && (
                            <Button
                              variant="primary"
                              onClick={onContinueToEditClick}
                            >
                              Continue To Edit
                            </Button>
                          )}
                        </div>
                      </Col>
                    </div>
                  </Col>
                  <Col lg={3} xs={12} className="position-sticky top-0">
                    <div className="bg-soft-info h-100 p-2 rounded-5 border border-2 border-dashed border-info d-flex flex-column">
                      {/* <h5>Preview</h5> */}
                      <div className="position-relative flex-fill">
                        <img
                          src={mobile}
                          alt="mobile"
                          className="h-auto w-100 d-block position-relative z-0"
                        />
                        <div className="position-absolute top-0 w-100 h-auto p-2 z-1">
                          {selectedSms && (
                            <div className="h-100 rounded-5 p-4 mobile-preview position-relative ">
                              <span className="d-block position-relative px-3 py-2 rounded-3 mt-4 text-secondary fs-7 lh-base shadow-lg shadow bg-white">
                                {selectedSms}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card.Body>
          )}
        </Card>
      )}
    </>
  );
};

export default CreateSmsTemplate;
