export const actionStatus = {
  1: "Draft",
  2: "Pending",
  3: "Deferred",
  4: "In Progress",
  5: "Completed/Approved",
  6: "Rejected",
  7: "Cancelled",
};
export const actionStatusCode = {
  1: "draft",
  2: "pending",
  3: "deffered",
  4: "in-progress",
  5: "completed",
  6: "rejected",
  7: "cancelled",
};
export const STATUS_CONSTANTS = {
  DRAFT: 1,
  PENDING: 2,
  DEFERRED: 3,
  IN_PROGRESS: 4,
  COMPLETED: 5,
  APPROVED: 5,
  REJECTED: 6,
  CANCELLED: 7,
};

export const actionStatusColor = {
  1: "warning",
  2: "primary",
  3: "danger",
  4: "info",
  5: "success",
  6: "danger",
  7: "danger",
};
export const getStatusId = (status) => {
  let statusId = 0;
  Object.keys(actionStatusCode).forEach((key) => {
    if (actionStatusCode[key] === status) {
      statusId = key;
    }
  });
  return statusId;
};
