import React from "react";

// layout

import Default from "../../../../../layouts/dashboard/default";
import AllPriorities from "../pages/all-priorities";
// pages

export const PriorityRouter = [
  {
    path: "/master-management/priority",
    element: <Default />,
    children: [
      {
        path: "all",
        element: <AllPriorities />,
      },
    ],
  },
];
