import React, { useState, useRef, memo } from "react";
import Card from "../../../../components/bootstrap/card";
import { Button, Col, Image, Row } from "react-bootstrap";
import Masonry from "react-masonry-css";
import TemplateSuggestionCard from "../../template-manager/components/template-suggestion-card";
import mobile from "../../../../assets/images/mobile-bg.png";
import img5 from "../../../../assets/modules/blog/images/tem-E.png";
import { Link } from "react-router-dom";
import ReactEmailEditor from "../../../../lib/email-editor/react-email-editor";
import sample from "../../../../lib/email-editor/sample.json";
import Filter from "./filter";
import toast from "react-hot-toast";
import HtmlParser from "../../../../lib/html-parser/react-html-parser";
import GrapejsEditor from "../../../../lib/email-editor/grapejs-editor";
import { STATUS_CONSTANTS } from "../../action/constants/action-constants";

const breakpointColumnsObj = {
  default: 3,
  1100: 2,
  // 768: 2,
  500: 1,
};

const EmailTemplatePreview = ({
  suggestionlist,
  modalClose,
  getSelectedTemplate,
  isEmailTemplatePreview,
  emailTemplatePreview,
  buttonName = "",
  // isEmailViewDetails,
}) => {
  const [presetHTML, setPresetHTML] = useState(null);
  const [editor, setEditor] = useState(null);
  const emailEditorRef = useRef(null);

  const onEmailTemplateViewDetails = (item) => {
    if (item?.approve?.id !== STATUS_CONSTANTS.APPROVED) {
      toast.error("Template is not approved yet");
      return;
    }
    getSelectedTemplate(item);
    setPresetHTML(item.email_content);
    isEmailTemplatePreview();
    // isEmailViewDetails();
  };

  const isJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  return (
    <>
      {!emailTemplatePreview ? (
        <div className="p-2 p-md-4 bg-soft-secondary rounded-3">
          <Row className="justify-content-center align-items-start">
            <Col>
              <Card>
                <Card.Body className="p-2">
                  <Filter />
                </Card.Body>
              </Card>
            </Col>
            <Col lg={12}>
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                {suggestionlist?.length > 0 &&
                  suggestionlist.map((item, index) => (
                    <Card>
                      {item?.email_content &&
                        isJsonString(item?.email_content) && (
                          <div className="card-thumbnail">
                            {console.log(
                              "item?.email_content",
                              typeof item?.email_content,
                              item?.email_content
                            )}
                            {/* <div className="w-100 rounded object-cover "></div> */}

                            {HtmlParser(JSON.parse(item?.email_content))}
                          </div>
                        )}
                      {console.log("item list", item)}
                      <Card.Body className="card-thumbnail">
                        <div className="d-flex flex-column align-items-center justify-content-center">
                          {item?.created_at ? (
                            <small className="text-primary">
                              <span className="text-secondary">Added On:</span>{" "}
                              {item.created_at}
                            </small>
                          ) : null}

                          {item.name ? (
                            <h4 className="mt-2 mb-3 text-ellipsis short-2">
                              {item.name}
                            </h4>
                          ) : null}

                          <div>
                            <Button
                              type="button"
                              variant="outline-success"
                              onClick={() => onEmailTemplateViewDetails(item)}
                            >
                              View Details
                            </Button>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  ))}
              </Masonry>
            </Col>
          </Row>
        </div>
      ) : (
        <div className="flex-grow-1 reactEmailEditorPrrnt">
          <GrapejsEditor
            ref={emailEditorRef}
            previewMode
            presetData={presetHTML}
            setEditor={(e) => setEditor(e)}
            editor={editor}
          />
        </div>
      )}
    </>
  );
};

export default memo(EmailTemplatePreview);
