import * as Yup from "yup";
export const chnagePasswordInitialValues = {
  old_password: "",
  password: "",
  password_confirmation: "",
};
const passwordSchema = {
  old_password: Yup.string().required("Required"),
  password: Yup.string()
    .required("Required")
    .min(8, "Must be 8 characters or more")
    .matches(/[a-z]+/, "One lowercase character")
    .matches(/[A-Z]+/, "One uppercase character")
    .matches(/[@$!%*#?&]+/, "One special character")
    .matches(/\d+/, "One number"),
  password_confirmation: Yup.string()
    .required("Required")
    .min(8, "Must be 8 characters or more")
    .matches(/[a-z]+/, "One lowercase character")
    .matches(/[A-Z]+/, "One uppercase character")
    .matches(/[@$!%*#?&]+/, "One special character")
    .matches(/\d+/, "One number")
    .test(
      "password_confirmation",
      "Password and confirm password must be same",
      function (value) {
        return this.parent.password_confirmation === value;
      }
    ),
};
export const chnagePasswordSchema = Yup.object(passwordSchema);
