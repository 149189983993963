import Skeleton from "../../components/skeleton";
import { Fragment } from "react";

//Componets

import { OverlayTrigger, Card, Tooltip, Row, Col } from "react-bootstrap";

const EmailGenaratorLoader = () => {
  return (
    <Fragment>
      <Card>
        <Card.Body>
          <Row>
            <Col md={6} className="mb-4">
              <Skeleton variant="rounded" width="100%" height={45} />
            </Col>

            <Col md={6} className="mb-4">
              <Skeleton variant="rounded" width="100%" height={45} />
            </Col>
            <Col md={6} className="mb-4">
              <Skeleton variant="rounded" width="100%" height={45} />
            </Col>
            <Col md={6} className="mb-4">
              <Skeleton variant="rounded" width="100%" height={45} />
            </Col>
            <Col md={12} className="mb-4">
              <Skeleton variant="rounded" width="100%" height={80} />
            </Col>
            <div className="d-flex justify-content-end">
              <Skeleton
                variant="rounded"
                width="10%"
                height={40}
                className="mb-4"
              />
            </div>
          </Row>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default EmailGenaratorLoader;
