import { createSlice } from "@reduxjs/toolkit";
import { contentStudioInnitialState } from "./content-studio.state";
import { getStorage } from "../../utilities/setting";
export const contentStudioSlice = createSlice({
  name: "contentStudio",
  initialState: getStorage("contentStudioState") || contentStudioInnitialState,
  reducers: {
    getContentStudio: (state, action) => {
      state.contentStudioGetData = action.payload;
    },
  },
});

export default contentStudioSlice.reducer;
