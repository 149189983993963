import { getStorage } from "../../utilities/setting";
import cmsAxiosInstance from "../instances/cms-api-instance";

export const cmsGetService = async (endpoint, data = "") => {
  const searchString = data ? "?" + new URLSearchParams(data).toString() : "";
  try {
    let response = await cmsAxiosInstance({
      method: "GET",
      url: endpoint + searchString,
    });
    if (response) {
      switch (response.status) {
        case 200:
          return { data: response.data, isError: false, error: "" };
        case 201:
          return { data: response.data, isError: false, error: "" };
        case 203:
          return { data: response.data, isError: false, error: "" };
        case 204:
          return { data: response.data, isError: false, error: "" };

        default:
          return {
            data: null,
            isError: true,
            error: "Something went wrong. Please try again",
          };
      }
    }
  } catch (error) {
    switch (error.response.status) {
      case 400:
        return {
          data: null,
          isError: true,
          error: "Something went wrong. Please try again",
        };
      case 401:
        return {
          data: null,
          isError: true,
          error: "Please check your credentials and try again",
        };
      case 403:
        return {
          data: null,
          isError: true,
          error: "Something went wrong. Please try again",
        };
      case 404:
        return {
          data: null,
          isError: true,
          error: "Something went wrong. Please try again",
        };
      case 500:
        return {
          data: null,
          isError: true,
          error: "Something went wrong. Please try again",
        };

      default:
        return {
          data: null,
          isError: true,
          error: "Something went wrong. Please try again",
        };
    }
  }
};
