import { useFormik } from "formik";
import { memo, useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import * as Yup from "yup";
import {
  UPDATE_ACTION_POST,
  UPLOAD_FILES_POST,
} from "../../../../api/endpoints/action-endpoints";
import {
  EMAIL_TEMPLATE_CREATE_GET,
  TEMPLATE_VARIABLE_LIST_GET,
} from "../../../../api/endpoints/template-management-endpoints";
import { getService } from "../../../../api/services/get-services";
import postService from "../../../../api/services/post-service";
import uploadService from "../../../../api/services/upload-service";
import app_config from "../../../../common/data/app_config";
import { getFormatedDate } from "../../../../common/utils/utils";
import PaginationLoader from "../../../../components/loader/pagination-loader";
import GrapejsEditor from "../../../../lib/email-editor/grapejs-editor";
import { findIcon } from "../../../dashboard/icons";
import { STATUS_CONSTANTS } from "../../action/constants/action-constants";
import SubmitButton from "../../../../components/form-components/submit-button";
import useBackdrop from "../../../../components/custom-hooks/use-backdrop";

const EmailTemplateCreator = ({
  id,
  onSubmitForm,
  handleCloseClick,
  presetValues,
  presetContent,
  comments,
  actionType,
  actionId,
  isApprovalTask,
}) => {
  const { showBackdrop } = useBackdrop();
  const [presetHTML, setPresetHTML] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editor, setEditor] = useState(null);

  const emailEditorRef = useRef(null);

  const [mergeTags, setMergeTags] = useState("");

  useEffect(() => {
    createTemplate(false);
    getVariableList();

    if (presetContent) {
      setPresetHTML(presetContent);
    }
    if (presetValues) {
      setValues({
        ...values,
        ...presetValues,
      });
    }
  }, []);

  const initialValues = {
    category_id: "",
    email_template_type: "",
    name: "",
    email_subject: "",
    from_name: "",
    from_email: "",
    status: "",
  };

  const validationSchema = Yup.object().shape({
    category_id: Yup.string().required("Please select category"),
    email_template_type: Yup.string().required("Please select template type"),
    name: Yup.string().required("Please enter template name"),
    email_subject: Yup.string().required("Please enter email subject"),
    // email_services_id: Yup.string().required("Please select service type"),
    from_name: Yup.string().required("Please enter from name"),
    from_email: Yup.string().required("Please enter from email"),
  });
  //using useFormik hook

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    handleReset,
    setValues,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      addEditTemplate({ html: editor.getHtml(), css: editor.getCss() });
    },
  });

  const addEditTemplate = async (Data) => {
    // setLoading(true);

    let postObj = {
      email_name: values.name,
      email_content: JSON.stringify(Data),
      email_jsons: Data,
      email_subject: values.email_subject,
      email_category_id: values.category_id,
      email_template_type: values.email_template_type,
      email_from_name: values.from_name,
      email_from_email: values.from_email,
      //need to make dynamic
      email_duedate: "2023-06-09",
      email_priority_id: 1,
      email_workflow_id: 5,
    };

    if (!isApprovalTask) {
      showBackdrop();
      let response = await postService(UPDATE_ACTION_POST + actionId, {
        action_id: actionId,
        status: STATUS_CONSTANTS.COMPLETED,
        comment: "",
      });
      if (response) {
        onSubmitForm(postObj);
      }
    } else {
      showBackdrop();
      onSubmitForm(postObj);
    }
  };

  const onReady = () => {};

  const onLoad = () => {
    if (editor !== null && presetHTML !== null) {
      editor.setComponents(JSON.parse(presetHTML).html);
      editor.setStyle(JSON.parse(presetHTML).css);
    }
  };

  const createTemplate = async (isEdit = false) => {
    try {
      setLoading(true);
      const response = await getService(EMAIL_TEMPLATE_CREATE_GET);
      if (response.data.data.category.length > 0) {
        setCategoryList(response.data.data.category);

        setLoading(false);
      }
      if (response.isError) {
        toast.error(response?.error);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const post_json_variable = useRef({
    default: "all",

    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      variable_id: "desc",
    },
  });

  const getVariableList = async () => {
    try {
      setLoading(true);
      const response = await postService(
        TEMPLATE_VARIABLE_LIST_GET,
        post_json_variable.current
      );
      if (response.data.data.rows.length > 0) {
        arrangeVariableData(response.data.data.rows);
        setLoading(false);
      }
      if (response.isError) {
        toast.error(response?.error);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const arrangeVariableData = (data) => {
    const variableObjectField = [];
    let modifiedVariableArray = [];

    data.forEach((obj) => {
      const { variable_name, variable_type, variable_id } = obj;
      variableObjectField.push({ variable_name, variable_type, variable_id });
    });
    variableObjectField.forEach((obj, index) => {
      const {
        variable_name,
        variable_type: { id: variable_type_id, value: variable_type_value },
        variable_id,
      } = obj;
      const modifiedObject = {
        variable_name,
        variable_type_id,
        variable_type_value,
        variable_id,
      };
      modifiedVariableArray.push(modifiedObject);
    });

    let allVariableType = modifiedVariableArray.map(
      (item) => item.variable_type_value
    );
    const uniqueSet = new Set(allVariableType.map((item) => item));
    const uniqueVariableType = [...uniqueSet];

    let variableTypeList = [];
    uniqueVariableType.forEach((item) => {
      let temp = modifiedVariableArray.filter(
        (item1) => item1.variable_type_value == item
      );
      variableTypeList.push(temp);
    });

    let temp = [];

    uniqueVariableType.map((item) => {
      variableTypeList.forEach((item1) => {
        if (item1[0].variable_type_value == item) {
          temp.push({
            name: item,
            tags: item1,
          });
        }
      });
    });
    setMergeTags(temp);
  };
  return (
    <Card>
      <Card.Header className="mb-3">
        <div className="d-flex justify-content-between">
          <h3 className="m-0">Template: Edit Email Template</h3>
          <div className="d-flex justify-content-start gap-4">
            <a
              href="javascript:void(0)"
              eventKey="link-1"
              className="d-inline-flex align-items-center gap-1"
            >
              {findIcon("Save", "dual-tone", 22)}
              Save Draft
            </a>
            <a
              href="javascript:void(0)"
              eventKey="link-1"
              className="d-inline-flex align-items-center gap-1 link-danger"
              onClick={handleCloseClick}
            >
              {findIcon("Square X", "dual-tone", 22)}
              Close
            </a>
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md={6} className="mb-4">
            <Form.Floating className="custom-form-floating">
              <Form.Select
                id="category_id"
                name="category_id"
                value={values?.category_id}
                isInvalid={touched.category_id && !!errors.category_id}
                onChange={handleChange}
              >
                <option value="">Select Category</option>
                {!loading && categoryList?.length > 0 ? (
                  categoryList.map((item, index) => (
                    <option key={index} value={item.category_id}>
                      {item.category_name}
                    </option>
                  ))
                ) : loading ? (
                  <option value="">Loading...</option>
                ) : null}
              </Form.Select>
              <Form.Label htmlFor="category_id">Category</Form.Label>
              <Form.Control.Feedback type="invalid">
                {errors.category_id}
              </Form.Control.Feedback>
            </Form.Floating>
          </Col>
          <Col md={6} className="mb-4">
            <Form.Floating className="custom-form-floating">
              <Form.Select
                id="email_template_type"
                name="email_template_type"
                value={values.email_template_type}
                isInvalid={
                  touched.email_template_type && !!errors.email_template_type
                }
                onChange={handleChange}
              >
                <option value="">Select Template Type</option>
                <option value="0">Promotional</option>
                <option value="1">Transactional</option>
              </Form.Select>
              <Form.Label htmlFor="email_template_type">Select Type</Form.Label>
              <Form.Control.Feedback type="invalid">
                {errors.email_template_type}
              </Form.Control.Feedback>
            </Form.Floating>
          </Col>

          <Col md={6} className="mb-4">
            <Form.Floating className="custom-form-floating">
              <Form.Control
                type="text"
                className=""
                id="name"
                autoComplete="Name"
                placeholder="Name"
                name="name"
                onChange={handleChange}
                value={values.name}
                isInvalid={touched.name && !!errors.name}
              />
              <Form.Label htmlFor="name">Name</Form.Label>
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Floating>
          </Col>

          <Col md={6} className="mb-4">
            <Form.Floating className="custom-form-floating">
              <Form.Control
                type="text"
                className=""
                id="email_subject"
                autoComplete="Subject"
                placeholder="Subject"
                name="email_subject"
                value={values.email_subject}
                isInvalid={touched.email_subject && !!errors.email_subject}
                onChange={handleChange}
              />
              <Form.Label htmlFor="email_subject">Subject</Form.Label>
              <Form.Control.Feedback type="invalid">
                {errors.email_subject}
              </Form.Control.Feedback>
            </Form.Floating>
          </Col>
          <Col md={6} className="mb-4">
            <Form.Floating className="custom-form-floating">
              <Form.Control
                type="text"
                className=""
                id="from_name"
                autoComplete="From Name"
                placeholder="From Name"
                name="from_name"
                value={values.from_name}
                isInvalid={touched.from_name && !!errors.from_name}
                onChange={handleChange}
              />
              <Form.Label htmlFor="from_name">From Name</Form.Label>
              <Form.Control.Feedback type="invalid">
                {errors.from_name}
              </Form.Control.Feedback>
            </Form.Floating>
          </Col>
          <Col md={6} className="mb-4">
            <Form.Floating className="custom-form-floating">
              <Form.Control
                type="text"
                id="from_email"
                autoComplete="From Email"
                placeholder="From Email"
                name="from_email"
                onChange={handleChange}
                value={values.from_email}
                isInvalid={touched.from_email && !!errors.from_email}
              />
              <Form.Label htmlFor="from_email">From Email</Form.Label>
              <Form.Control.Feedback type="invalid">
                {errors.from_email}
              </Form.Control.Feedback>
            </Form.Floating>
          </Col>
          <Col md={12} className="mb-3 mt-2">
            <h5 className="mb-0">Content</h5>
          </Col>
          <Col md={12} className="mb-4">
            <div className="boredr-2 border border-dashed p-4 rounded-3">
              {mergeTags && (
                // <ReactEmailEditor
                //   ref={emailEditorRef}
                //   onReady={onReady}
                //   onLoad={onLoad}
                //   mergeTags={mergeTags && mergeTags}
                // />

                <GrapejsEditor
                  ref={emailEditorRef}
                  onReady={onReady}
                  onLoad={onLoad}
                  mergeTags={mergeTags ? mergeTags : ""}
                  presetData={presetHTML}
                  setEditor={(e) => setEditor(e)}
                  editor={editor}
                />
              )}
            </div>
          </Col>
          {!isApprovalTask && (
            <div className="d-flex justify-content-end">
              <SubmitButton variant="primary" onClick={handleSubmit}>
                {id ? (
                  loading ? (
                    <PaginationLoader />
                  ) : (
                    "Update Template for Approval"
                  )
                ) : loading ? (
                  <PaginationLoader />
                ) : (
                  "Create Template for Approval"
                )}
              </SubmitButton>
            </div>
          )}
        </Row>

        {isApprovalTask && (
          <div className="p-4 bg-soft-secondary rounded-3 mt-4">
            <Row>
              <Col lg={6}>
                <h5 className="mb-3">Reviews</h5>
                <Reviews comments={comments} />
              </Col>
              <Col lg={6}>
                <AddApprovalForm
                  actionId={actionId}
                  actionType={actionType}
                  onAddApproval={handleSubmit}
                />
              </Col>
            </Row>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default memo(EmailTemplateCreator);
function Reviews({ comments }) {
  //console.log("comments", comments);
  return (
    <div className="reviews">
      {comments?.length > 0 &&
        comments?.map((_comment, _idx) => {
          let { created_at, comment, user_id } = _comment;
          return (
            <div
              className="d-flex flex-column justify-content-between shadow-sm p-3 mb-3 rounded-3"
              key={_idx + 1}
            >
              <div className="d-flex flex-row justify-content-between mb-2">
                <div className="d-flex flex-column">
                  <span>{user_id?.name ?? ""}</span>
                  <span className="fs-7 text-black">
                    {getFormatedDate(created_at)}
                  </span>
                </div>
              </div>
              <div>{comment || ""}</div>
            </div>
          );
        })}
    </div>
  );
}
function AddApprovalForm({ actionId, onAddApproval }) {
  const [values, setValues] = useState({ reference_files: [], input: "" });
  const [loading, setLoading] = useState(false);
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const uploadFiles = (files) => {
    return new Promise(async (resolve, reject) => {
      // Check if files array is empty
      if (files.length === 0) {
        reject("No files to upload.");
        return;
      }
      let formdata = new FormData();
      files.forEach((file) => {
        formdata.append("files[]", file);
      });

      // 1. Upload files to server
      let response = await uploadService(UPLOAD_FILES_POST, formdata);
      if (response.data?.success) {
        // 2. Simulating successful upload and generating URLs
        const urls = response.data.data.map((file) => file.name);
        resolve(urls);
      } else {
        reject("Something went wrong");
      }
    });
  };
  const handleChangeReferenceFiles = async (e) => {
    const { files } = e.target;
    const urls = await uploadFiles([...files]);
    if (urls) {
      setValues({
        ...values,
        reference_files: [...values.reference_files, ...urls],
      });
    }
  };

  const submitReview = async (status) => {
    if (status == STATUS_CONSTANTS.REJECTED && !values.input) {
      toast.error("Please enter comment");
      return;
    }
    showBackdrop();
    setLoading(true);
    let response = await postService(UPDATE_ACTION_POST + actionId, {
      action_id: actionId,
      status,
      comment: values.input,
      // reference_files: values.reference_files,
    });

    if (response.isError) {
      setLoading(false);
      hideBackdrop();
      toast.error(response.error);
      return;
    }

    if (response.data.success) {
      setLoading(false);

      toast.success("Your action has been submitted successfully");

      onAddApproval();
    }
  };
  const onCrossImageClick = (e) => {
    let tempRequisitionFiles = values.reference_files
      ? [...values.reference_files]
      : [];
    tempRequisitionFiles = tempRequisitionFiles.filter(
      (file, idx) => idx !== e
    );
    setValues({
      ...values,
      reference_files: tempRequisitionFiles,
    });
  };
  return (
    <Form>
      <Col lg="12" className="mb-2 mt-0">
        <Form.Floating className="custom-form-floating">
          <textarea
            className="form-control"
            placeholder="Leave a comment here"
            id="floatingTextarea2"
            name="input"
            onChange={(e) => {
              setValues({ ...values, input: e.target.value });
            }}
          ></textarea>
          <Form.Label htmlFor="fname">Write your comments</Form.Label>
        </Form.Floating>
      </Col>
      <Col lg="12" className="mb-2">
        <h6 className="mb-2 mt-3 fw-normal">Add Reference Files</h6>
        <Form.Control
          type="file"
          id="customFile"
          multiple
          name="reference_files"
          onChange={handleChangeReferenceFiles}
        />
        <div className="d-flex flex-wrap flex-row gap-3 mt-3">
          {values.reference_files?.map((file, _idx) => (
            <div className="position-relative" key={_idx + 1}>
              <img
                className="rounded img-fluid avatar-120"
                src={app_config.fileStoragePath + file}
                alt="profile"
                loading="lazy"
                onError={(e) => {
                  e.target.onerror = null;
                  //   e.target.src = article_1;
                }}
              />
              <span
                className="position-absolute top-0 end-0"
                onClick={() => onCrossImageClick(_idx)}
              >
                {findIcon("Square X", "solid", 20)}
              </span>
            </div>
          ))}
        </div>
      </Col>
      <Col lg="12" className="d-flex gap-3">
        <SubmitButton
          type="button"
          variant="warning"
          disabled={loading}
          onClick={() => submitReview(STATUS_CONSTANTS.REJECTED)}
          className="w-100 py-3 px-1 mt-2 fs-6 rounded-3"
        >
          Reject
        </SubmitButton>
        <SubmitButton
          type="button"
          variant="primary"
          disabled={loading}
          onClick={() => submitReview(STATUS_CONSTANTS.COMPLETED)}
          className="w-100 py-3 px-1 mt-2 fs-6 fw-semibold rounded-3"
        >
          Approve
        </SubmitButton>
      </Col>
    </Form>
  );
}
