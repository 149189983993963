import { memo, Fragment, useMemo } from "react";

// React-bootstrap
import { Nav, OverlayTrigger, Tooltip } from "react-bootstrap";

// Router
import { Link, useLocation } from "react-router-dom";

// components
import SidebarMenu from "../../../../../components/partials/components/sidebar/sidebar-menu";

const TemplateManagerVerticalNav = memo(() => {
  let location = useLocation();
  let templateVendorHandler = useMemo(() => {
    if (location?.pathname?.includes("email-template")) {
      return "email-template";
    } else if (location?.pathname?.includes("sms-template")) {
      return "sms-template";
    } else if (location?.pathname?.includes("telegram-template")) {
      return "telegram-template";
    } else if (location?.pathname?.includes("whatsapp-template")) {
      return "whatsapp-template";
    } else return "";
  }, [location?.pathname]);

  // console.log("location", templateVendorHandler);

  return (
    <Fragment>
      <ul className="navbar-nav iq-main-menu" id="sidebar-menu">
        <Nav.Item as="li" className="static-item">
          <Nav.Link className="static-item disabled" href="#" tabIndex="-1">
            <span className="default-icon">Pages</span>
            <span className="mini-icon">-</span>
          </Nav.Link>
        </Nav.Item>
        <SidebarMenu
          isTag="false"
          minititle="I"
          pathname={`/template-management/${templateVendorHandler}`}
          title="Template Listing"
        >
          <i className="icon">
            <svg
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.7379 2.76175H8.08493C6.00493 2.75375 4.29993 4.41175 4.25093 6.49075V17.2037C4.20493 19.3167 5.87993 21.0677 7.99293 21.1147C8.02393 21.1147 8.05393 21.1157 8.08493 21.1147H16.0739C18.1679 21.0297 19.8179 19.2997 19.8029 17.2037V8.03775L14.7379 2.76175Z"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M14.4751 2.75V5.659C14.4751 7.079 15.6231 8.23 17.0431 8.234H19.7981"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M14.2882 15.3584H8.88818"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M12.2432 11.606H8.88721"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </i>
        </SidebarMenu>
        <SidebarMenu
          isTag="false"
          minititle="I"
          pathname={`/template-management/${templateVendorHandler}/create`}
          title="Template Creation"
        >
          <i className="icon">
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.5 4C2.5 3.17157 3.17157 2.5 4 2.5H9C9.82845 2.5 10.5 3.17157 10.5 4V20C10.5 20.8285 9.82846 21.5 9 21.5H4C3.17157 21.5 2.5 20.8285 2.5 20V4Z"
                stroke="currentColor"
              ></path>
              <path
                d="M13.5 4C13.5 3.17157 14.1715 2.5 15 2.5H20C20.8285 2.5 21.5 3.17157 21.5 4V9C21.5 9.82846 20.8285 10.5 20 10.5H15C14.1715 10.5 13.5 9.82846 13.5 9V4Z"
                stroke="currentColor"
              ></path>
              <path
                d="M13.5 15C13.5 14.1715 14.1715 13.5 15 13.5H20C20.8285 13.5 21.5 14.1715 21.5 15V20C21.5 20.8285 20.8285 21.5 20 21.5H15C14.1715 21.5 13.5 20.8285 13.5 20V15Z"
                stroke="currentColor"
              ></path>
            </svg>
          </i>
        </SidebarMenu>
        {/* <SidebarMenu
          isTag="false"
          minititle="I"
          pathname="/template-management/email-template/approval"
          title="Template Approval"
        >
          <i className="icon">
            <svg fill="none" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.9846 21.606C11.9846 21.606 19.6566 19.283 19.6566 12.879C19.6566 6.474 19.9346 5.974 19.3196 5.358C18.7036 4.742 12.9906 2.75 11.9846 2.75C10.9786 2.75 5.26557 4.742 4.65057 5.358C4.03457 5.974 4.31257 6.474 4.31257 12.879C4.31257 19.283 11.9846 21.606 11.9846 21.606Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M9.38574 11.8746L11.2777 13.7696L15.1757 9.86963" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
          </i>
        </SidebarMenu> */}
        <SidebarMenu
          isTag="false"
          minititle="I"
          pathname={`/template-management/${templateVendorHandler}/bank`}
          title="Template Bank"
        >
          <i className="icon">
            <svg
              className="icon-20"
              width="20"
              viewBox="0 0 24 24"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.9999 14.7024V16.0859C21.9999 16.3155 21.9899 16.5471 21.9698 16.7767C21.6893 19.9357 19.4949 22 16.3286 22H7.67125C6.06804 22 4.71534 21.4797 3.74339 20.5363C3.36263 20.1864 3.04199 19.7753 2.79149 19.3041C3.12215 18.9021 3.49289 18.462 3.85361 18.0208C4.46484 17.289 5.05602 16.5661 5.42676 16.0959C5.97786 15.4142 7.43077 13.6196 9.4448 14.4617C9.85562 14.6322 10.2163 14.8728 10.547 15.0833C11.3586 15.6247 11.6993 15.7851 12.2704 15.4743C12.9017 15.1335 13.3125 14.4617 13.7434 13.76C13.9739 13.388 14.2043 13.0281 14.4548 12.6972C15.547 11.2736 17.2304 10.8926 18.6332 11.7348C19.3346 12.1559 19.9358 12.6872 20.4969 13.2276C20.6171 13.3479 20.7374 13.4592 20.8476 13.5695C20.9979 13.7198 21.4989 14.2211 21.9999 14.7024Z"
                fill="currentColor"
              />
              <path
                opacity="0.4"
                d="M16.3387 2H7.67134C4.27455 2 2 4.37607 2 7.91411V16.086C2 17.3181 2.28056 18.4119 2.79158 19.3042C3.12224 18.9022 3.49299 18.4621 3.85371 18.0199C4.46493 17.2891 5.05611 16.5662 5.42685 16.096C5.97796 15.4143 7.43086 13.6197 9.44489 14.4618C9.85571 14.6323 10.2164 14.8729 10.5471 15.0834C11.3587 15.6248 11.6994 15.7852 12.2705 15.4734C12.9018 15.1336 13.3126 14.4618 13.7435 13.759C13.9739 13.3881 14.2044 13.0282 14.4549 12.6973C15.5471 11.2737 17.2305 10.8927 18.6333 11.7349C19.3347 12.1559 19.9359 12.6873 20.497 13.2277C20.6172 13.348 20.7375 13.4593 20.8477 13.5696C20.998 13.7189 21.499 14.2202 22 14.7025V7.91411C22 4.37607 19.7255 2 16.3387 2Z"
                fill="currentColor"
              />
              <path
                d="M11.4544 8.79665C11.4544 10.2053 10.2811 11.3782 8.87325 11.3782C7.46644 11.3782 6.29309 10.2053 6.29309 8.79665C6.29309 7.38906 7.46644 6.21506 8.87325 6.21506C10.2811 6.21506 11.4544 7.38906 11.4544 8.79665Z"
                fill="currentColor"
              />
            </svg>
          </i>
        </SidebarMenu>
        <li>
          <hr className="hr-horizontal" />
        </li>
        <li className="nav-item static-item">
          <Link className="nav-link static-item disabled" to="#" tabIndex="-1">
            <span className="default-icon">Other</span>
            <span className="mini-icon">-</span>
          </Link>
        </li>
        <SidebarMenu isTag="true" pathname="/auth/sign-in" title="Sign Out">
          <i className="icon">
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.4"
                d="M2 6.447C2 3.996 4.03024 2 6.52453 2H11.4856C13.9748 2 16 3.99 16 6.437V17.553C16 20.005 13.9698 22 11.4744 22H6.51537C4.02515 22 2 20.01 2 17.563V16.623V6.447Z"
                fill="currentColor"
              ></path>
              <path
                d="M21.7787 11.4548L18.9329 8.5458C18.6388 8.2458 18.1655 8.2458 17.8723 8.5478C17.5802 8.8498 17.5811 9.3368 17.8743 9.6368L19.4335 11.2298H17.9386H9.54826C9.13434 11.2298 8.79834 11.5748 8.79834 11.9998C8.79834 12.4258 9.13434 12.7698 9.54826 12.7698H19.4335L17.8743 14.3628C17.5811 14.6628 17.5802 15.1498 17.8723 15.4518C18.0194 15.6028 18.2113 15.6788 18.4041 15.6788C18.595 15.6788 18.7868 15.6028 18.9329 15.4538L21.7787 12.5458C21.9199 12.4008 21.9998 12.2048 21.9998 11.9998C21.9998 11.7958 21.9199 11.5998 21.7787 11.4548Z"
                fill="currentColor"
              ></path>
            </svg>
          </i>
        </SidebarMenu>
        <SidebarMenu isTag="true" pathname="" title="Help">
          <i className="icon">
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.4"
                d="M22 11.9998C22 17.5238 17.523 21.9998 12 21.9998C6.477 21.9998 2 17.5238 2 11.9998C2 6.47776 6.477 1.99976 12 1.99976C17.523 1.99976 22 6.47776 22 11.9998Z"
                fill="currentColor"
              ></path>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.8701 12.6307C12.8701 13.1127 12.4771 13.5057 11.9951 13.5057C11.5131 13.5057 11.1201 13.1127 11.1201 12.6307V8.21069C11.1201 7.72869 11.5131 7.33569 11.9951 7.33569C12.4771 7.33569 12.8701 7.72869 12.8701 8.21069V12.6307ZM11.1251 15.8035C11.1251 15.3215 11.5161 14.9285 11.9951 14.9285C12.4881 14.9285 12.8801 15.3215 12.8801 15.8035C12.8801 16.2855 12.4881 16.6785 12.0051 16.6785C11.5201 16.6785 11.1251 16.2855 11.1251 15.8035Z"
                fill="currentColor"
              ></path>
            </svg>
          </i>
        </SidebarMenu>
      </ul>
    </Fragment>
  );
});

TemplateManagerVerticalNav.displayName = "TemplateManagerVerticalNav";
export default TemplateManagerVerticalNav;
