import React from "react";
import { Button, Modal } from "react-bootstrap";

const CommonModal = ({
  show,
  modalClose,
  onSubmit,
  title,
  body,
  isLoading,
  loaderText,
}) => {
  const onConfirm = () => {
    onSubmit();
    // modalClose();
  };
  return (
    <>
      <Modal
        show={show}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header onClick={modalClose}>
          <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{body}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={modalClose}>Cancel</Button>
          <Button
            type="button"
            variant="danger"
            disabled={isLoading}
            onClick={onConfirm}
          >
            {isLoading ? loaderText : "Yes I'm sure"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CommonModal;
