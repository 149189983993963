import { useState, memo, Fragment, useEffect, forwardRef, useRef } from "react";

import {
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  FormControl,
  Card,
  Badge,
  Dropdown,
  Image,
  Nav,
  Table,
} from "react-bootstrap";
// import * as settingSelector from '../../../../store/setting/selectors'
//Chat
import Chart from "react-apexcharts";
import { Link, useLocation, useParams } from "react-router-dom";
import Circularprogressbar from "../../../../components/circularprogressbar";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import CountUp from "react-countup";
import ReactTable from "../../../../lib/table/react-table";
import Images from "../components/images";
import Leaflet from "../../../../components/leaflet";

import avatars11 from "../../../../assets/images/avatars/01.png";

//Masonry
import Masonry from "react-masonry-css";
// import LeafletMap from "../components/leafletMap";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import OnboardingLogModal from "../../../../components/partials/common/onboarding-log-modal";
import {
  useChangeSpecialityDashboardStatus,
  useEngagementMeterData,
  useGetActivityTimelineData,
  useGetAllEngagementData,
  useGetDayOfWeekData,
  useGetNbaViewData,
  useGetTimeOfDayData,
  useHcpActivityList,
  useHcpDetails,
} from "../../../../queries/query-hooks/hcp-profile-hook";
import DeleteModal from "../../../../components/partials/common/delete-modal";
import CommonModal from "../../../../components/partials/common/common-modal";
import RequestDataChnageModal from "../components/modal/request-data-chnage-modal";
import ChnageRecommendationModal from "../components/modal/chnage-recommendation-modal";
import toast from "react-hot-toast";
import Skeleton from "../../../../components/skeleton";
import { ONBOARDING_STATUS_CONSTANTS } from "../constant/hcp-constatnt";
// import Flatpickrr from "../../plugins/pages/flatpickr";
const details = (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-22"
    width="32"
    height="32"
    viewBox="0 0 24 24"
  >
    <path
      d="M12.1415 6.5899C11.9075 6.71614 11.7616 6.95618 11.7616 7.21726V11.2827H3.73429C3.32896 11.2827 3 11.604 3 12C3 12.3959 3.32896 12.7172 3.73429 12.7172H11.7616V16.7827C11.7616 17.0447 11.9075 17.2848 12.1415 17.4101C12.3755 17.5372 12.6614 17.5286 12.8875 17.39L20.6573 12.6073C20.8708 12.4753 21 12.2467 21 12C21 11.7532 20.8708 11.5247 20.6573 11.3927L12.8875 6.60998C12.7681 6.5373 12.632 6.5 12.4959 6.5C12.3745 6.5 12.2521 6.5306 12.1415 6.5899Z"
      fill="currentColor"
    ></path>
  </svg>
);
var hospitaldays = ["Monday", "Wednesday", "Friday"];
var clincdays = ["Tuesday", "Thursday", "Saturday"];

const HCP_ProfileDetails = () => {
  const [showModal, setShowModal] = useState(false);
  const [hcpActivityList, sethcpActivityList] = useState(true);

  const [speciality_dashboard_modal, set_speciality_dashboard_modal] =
    useState(false);
  const [recommendation_status_modal, set_recommendation_status_modal] =
    useState(false);

  const [request_data_change_modal, set_request_data_change_modal] =
    useState(false);

  const [
    change_recommendation_modal_status,
    set_change_recommendation_modal_status,
  ] = useState(false);

  const [nbaTotalViewCount, setnbaTotalViewCount] = useState(0);

  const [engagementViewCount, setEngagementTotalViewCount] = useState(0);
  const closeModal = () => setShowModal(false);
  const map_one = useRef(null);
  const map_two = useRef(null);
  const { redirectTo } = useRedirect();

  const { id: hcpId } = useParams();

  const onSuccess = (response) => {
    //response
    console.log("reponse", response);
  };

  const onActivitySuccess = (response) => {
    console.log("onActivitySuccess", response);
    console.log("hcpData", generateActivityChartData(response));
  };
  const {
    data: hcpDetail,
    refetch: refetchHcpDetails,
    isLoading,
  } = useHcpDetails(hcpId, !!hcpId, onSuccess);

  const { data: activityList, isLoading: isActivityLoading } =
    useHcpActivityList(hcpId, !!hcpId, onActivitySuccess);

  const onEngagaeSuccess = (res) => {
    if (res.success) {
      setEngagementTotalViewCount(res.totalCount);
    }
  };
  const { data: allEngagement, isLoading: isAllEngagementLoading } =
    useGetAllEngagementData(hcpId, !!hcpId, onEngagaeSuccess);

  const { data: activityTimeline, isLoading: isActivityTimelineLoading } =
    useGetActivityTimelineData(hcpId, !!hcpId);

  const { data: timeOfDay, isLoading: istimeOfDayLoading } =
    useGetTimeOfDayData(hcpId, !!hcpId);

  const { data: dayOfWeek, isLoading: isdayOfWeekLoading } =
    useGetDayOfWeekData(hcpId, !!hcpId);
  const { data: engagementMeterData, isLoading: isEngagementMeterDataLoading } =
    useEngagementMeterData(hcpId, !!hcpId);

  const onNbaSuccess = (res) => {
    if (res?.success) {
      setnbaTotalViewCount(res?.totalCount);
    }
  };
  const { data: nbaViewData, isLoading: isNbaViewDataLoading } =
    useGetNbaViewData(hcpId, !!hcpId, onNbaSuccess);

  const onDashboardUpdateSucccess = (response) => {
    console.log("::::", response?.data.message);
    toast.success(`${response.data.message}`);
    set_speciality_dashboard_modal(!speciality_dashboard_modal);
    refetchHcpDetails();
  };
  const {
    mutate: updateSpecialityDashboardStatus,
    isLoading: specDashboardUpdateLoading,
  } = useChangeSpecialityDashboardStatus(hcpId, onDashboardUpdateSucccess);

  const engagementColumn = [
    { Header: "Sl No", accessor: "sl_no" },
    { Header: "Page Id", accessor: "page_id" },
    { Header: "Service", accessor: "service" },
    { Header: "Channel", accessor: "channel" },
    { Header: "Template", accessor: "template" },
    { Header: "Short Link", accessor: "short_link" },
    { Header: "Sending Time", accessor: "sending_time" },
    { Header: "View Time", accessor: "view_time" },
    { Header: "Status", accessor: "status" },
  ];

  const CustomToggle = forwardRef(({ children, variant, onClick }, ref) => (
    <Link
      to="/"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className={variant}
      style={{ color: "unset" }}
    >
      {children}
    </Link>
  ));
  const getVariableColor = () => {
    let prefix =
      getComputedStyle(document.body).getPropertyValue("--prefix") || "bs-";
    if (prefix) {
      prefix = prefix.trim();
    }
    const color1 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}primary`
    );
    const color2 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}info`
    );
    const color3 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}primary-tint-20`
    );
    const color4 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}warning`
    );
    return {
      primary: color1.trim(),
      info: color2.trim(),
      warning: color4.trim(),
      primary_light: color3.trim(),
    };
  };
  const variableColors = getVariableColor();
  const colors = [
    variableColors.primary,
    variableColors.info,
    variableColors.warning,
  ];

  useEffect(() => {
    if (activityList && typeof activityList == "object") {
      sethcpActivityList(generateActivityChartData(activityList));
    }
  }, [activityList]);

  useEffect(() => {
    return () => colors;
  });

  const weekTimePreferences = {
    width: "100%",
    options: {
      colors: colors,
      chart: {
        toolbar: {
          show: false,
        },
      },
      forecastDataPoints: {
        count: 0,
      },
      stroke: {
        width: 3,
      },
      grid: {
        show: true,
        strokeDashArray: 7,
      },
      markers: {
        size: 6,
        colors: "#FFFFFF",
        strokeColors: colors,
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 0,
        shape: "circle",
        radius: 2,
        offsetX: 0,
        offsetY: 0,
      },
      xaxis: {
        categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
    },
    series: [],
  };

  const engagementMeterChart = {
    options: {
      colors: colors,
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 10,
            size: "50%",
          },
          track: {
            margin: 10,
            strokeWidth: "80%",
          },
          dataLabels: {
            show: false,
          },
        },
      },
      labels:
        !isEngagementMeterDataLoading && engagementMeterData?.labels?.length > 0
          ? engagementMeterData?.labels
          : ["Voice Call Default", "Comments def", "Engagement"],
    },
    series:
      !isEngagementMeterDataLoading && engagementMeterData?.series?.length > 0
        ? engagementMeterData?.series
        : [76, 55, 100],
  };

  const nbaColumn = [
    // {
    //   Header: "Channel",
    //   accessor: "channel",
    //   // Filter: DefaultColumnFilter,
    // },
    {
      Header: "Content ID",
      accessor: "content_id",
      // Filter: DefaultColumnFilter,
    },
    {
      Header: "Content Type",
      accessor: "content_type",
      // Filter: DefaultColumnFilter,
    },
    {
      Header: "Title",
      accessor: "content_title",
      // Filter: DefaultColumnFilter,
    },
    {
      Header: "Action",
      accessor: "action",
      disableFilters: true,
      Cell: ({ row }) => (
        <div className="d-flex justify-content-center">
          <Button
            className="btn btn-icon btn-sm rounded-pill"
            to="#"
            role="button"
          >
            <span className="btn-inner">{row.original.actionDetails}</span>
          </Button>
        </div>
      ),

      // Filter: DefaultColumnFilter,
    },
  ];

  const dayTimePreferences = {
    width: "100%",
    options: {
      colors: colors,
      chart: {
        toolbar: {
          show: false,
        },
      },
      forecastDataPoints: {
        count: 0,
      },
      stroke: {
        width: 3,
      },
      grid: {
        show: true,
        strokeDashArray: 7,
      },
      markers: {
        size: 6,
        colors: "#FFFFFF",
        strokeColors: colors,
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 0,
        shape: "circle",
        radius: 2,
        offsetX: 0,
        offsetY: 0,
      },
      xaxis: {
        categories: timeOfDay?.data?.categories
          ? timeOfDay?.data?.categories
          : [],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
    },
    series: [],
  };

  function generateActivityChartData(input) {
    const output = [];

    const totalValue = Object.values(input).reduce(
      (acc, value) => acc + value,
      0
    );
    let start = 1;

    for (const key in input) {
      if (input.hasOwnProperty(key)) {
        const value = input[key];
        const name = key.charAt(0).toUpperCase() + key.slice(1); // Capitalize the name

        const item = {
          hcp_id: input.hcp_id,
          color: variableColors.primary,
          value,
          id: "circle-progress-01",
          svg: <>{value}</>,
          name,
          start,
          end: (start + value / totalValue) * 100,
        };

        output.push(item);
        start = item.end;
      }
    }

    return output;
  }

  const onRequestDataChange = () => {
    redirectTo("hcp-profile/cohothistory");
  };
  const onMetabtnClick = () => {
    redirectTo("hcp-profile/meta-profile/150784");
  };

  function extractDegreeNames(data) {
    const degreeNames = data.map((item) => item.medical_degree.degree_name);
    const result = degreeNames.join(", ");
    return result;
  }

  function extractInterestSpecNames(data) {
    const degreeNames = data.map((item) => item.speciality.speciality_name);
    const result = degreeNames.join(", ");
    return result;
  }

  const onChangeSpecDashboardClick = () => {
    set_speciality_dashboard_modal(!speciality_dashboard_modal);
  };

  const onSubmitSpecialityStatus = () => {
    let payload = {
      hcp_id: hcpId,
      hcp_to_speciality_eligiblity_status:
        hcpDetail?.hcp_to_speciality_eligiblity_status?.value == 1 ? 1 : 2,
    };
    updateSpecialityDashboardStatus(payload);
    // set_speciality_dashboard_modal(!speciality_dashboard_modal);
  };

  const onRequestDataChnageClick = () => {
    set_request_data_change_modal(!request_data_change_modal);
  };

  const onRequestDataChnageSubmit = () => {
    onRequestDataChnageClick();
  };

  const onRecommendationDataChnageClick = () => {
    set_change_recommendation_modal_status(!change_recommendation_modal_status);
  };
  const onSubmitRecommendationStatus = () => {
    onRecommendationDataChnageClick();
  };

  const getDndChannelNames = (input) => {
    const channelNames = input.map((item) => item.dnd.channel_name);
    return channelNames.join(", ");
  };

  function extractDegreeNames(input) {
    return input.map((item) => item.medical_degree.degree_name).join(",");
  }

  const transformSocialLinks = (input) => {
    if (!input) {
      return [];
    }
    const output = input.map((item) => ({
      platform: item.social_link_type.social_link_type_name,
      link: item.social_link,
    }));
    if (output?.length > 0) {
      return output;
    } else {
      return [];
    }
  };

  const getHcpDocument = (alldata, value) => {
    const filteredData = alldata.filter(
      (item) => item.hcp_document_type === value
    );
    console.log("filter data", filteredData);
    const output = filteredData.map((item) => item.hcp_document_path);
    return output;
  };
  return (
    <Fragment>
      <Row>
        <Col lg={12} md={12}>
          <div className="d-flex justify-content-end align-items-center flex-wrap gap-3 mb-4">
            <div className="d-flex align-items-center gap-3">
              <Button
                type="button"
                className="primary"
                onClick={onMetabtnClick}
              >
                Meta Profile
              </Button>
              {/* {hcpDetail?.hcp_onboarding_status?.id ==
                ONBOARDING_STATUS_CONSTANTS.ONBOARDED && (
                <Button
                  type="button"
                  className="bg-secondary"
                  onClick={onRequestDataChnageClick}
                >
                  Re-Onboard
                </Button>
              )} */}
              <Button
                type="button"
                className="bg-info"
                onClick={onChangeSpecDashboardClick}
                title={`Current Status: ${hcpDetail?.hcp_to_speciality_eligiblity_status?.value}`}
              >
                Speciality Dashboard Status
              </Button>
              <Button
                type="button"
                className=" bg-warning"
                title={`Current Status: ${hcpDetail?.hcp_to_recommendation_status?.value}`}
                onClick={onRecommendationDataChnageClick}
              >
                Recommendation Status
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Masonry
        breakpointCols={1}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        <Col>
          <Card className="h-100">
            <Row>
              <Col lg="12">
                <Card className="mb-3">
                  <Card.Body className="p-3">
                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                      <div className="d-flex flex-wrap align-items-center">
                        <div className="position-relative me-3 mb-3 mb-lg-0 profile-logo profile-logo1">
                          <Image
                            className="theme-color-default-img  img-fluid rounded-pill avatar-100"
                            src={avatars11}
                            alt="profile-pic"
                          />
                          {/* <Image
                            className="theme-color-default-img  img-fluid rounded-pill avatar-60"
                            src={
                              "https://img.freepik.com/free-photo/portrait-white-man-isolated_53876-40306.jpg?q=10&h=300"
                            }
                            alt="profile-pic"
                          /> */}
                        </div>
                        {(hcpDetail?.hcp_first_name ||
                          hcpDetail?.hcp_to_primary_speciality) && (
                          <div className="d-flex flex-wrap flex-column align-items-start mb-3 mb-sm-0">
                            {
                              <h4 className="me-2 mb-0 fs-5">
                                {hcpDetail?.hcp_first_name +
                                  " " +
                                  hcpDetail.hcp_middle_name +
                                  " " +
                                  hcpDetail?.hcp_last_name}
                              </h4>
                            }
                            {hcpDetail?.hcp_to_primary_speciality && (
                              <span>
                                {
                                  hcpDetail?.hcp_to_primary_speciality
                                    ?.speciality_name
                                }
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                      {/* <div className="d-flex flex-column align-items-center">
                        <div className="d-flex flex-row gap-3">
                          <div className="text-center bg-soft-primary rounded-3 px-3 py-2">
                            <h5 className="mb-1">4</h5>
                            <p className="m-0 fs-7">Annual Login</p>
                          </div>
                          <div className="text-center bg-soft-secondary rounded-3 px-3 py-2">
                            <h5 className="mb-1">35 Mins</h5>
                            <p className="m-0 fs-7">Per Session</p>
                          </div>
                          <div className="text-center bg-soft-success rounded-3 px-3 py-2">
                            <h5 className="mb-1">24.05.23</h5>
                            <p className="m-0 fs-7">Last Click</p>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={12} md={12}>
                <div className="overflow-hidden d-slider1">
                  <Swiper
                    as="ul"
                    className="p-0 m-0 mb-2 list-inline swiper-wrapper"
                    slidesPerView={5}
                    spaceBetween={15}
                    modules={[Navigation]}
                    navigation={{
                      nextEl: ".swiper-button-next",
                      prevEl: ".swiper-button-prev",
                    }}
                    breakpoints={{
                      320: { slidesPerView: 1 },
                      550: { slidesPerView: 2 },
                      991: { slidesPerView: 8 },
                      1400: { slidesPerView: 8 },
                      1500: { slidesPerView: 8 },
                      1920: { slidesPerView: 10 },
                      2040: { slidesPerView: 12 },
                      2440: { slidesPerView: 14 },
                    }}
                    data-aos="fade-up"
                    data-aos-delay="700"
                  >
                    {hcpActivityList &&
                      hcpActivityList?.length > 0 &&
                      hcpActivityList.map((item, index) => {
                        return (
                          <SwiperSlide
                            className="card card-slide mb-2"
                            key={index}
                          >
                            <Card.Body className="p-3">
                              <div className="progress-widget flex-column">
                                <Circularprogressbar
                                  stroke={variableColors.primary}
                                  width="60px"
                                  height="60px"
                                  Linecap="rounded"
                                  trailstroke="#ddd"
                                  strokewidth="6px"
                                  style={{ width: 60, height: 60 }}
                                  value={item.value}
                                  id={`circle-progress-${index}}`}
                                >
                                  {item.svg}
                                </Circularprogressbar>
                                <div className="progress-detail ms-0 mt-3 fs-6">
                                  <p className="mb-0">{item.name}</p>
                                </div>
                              </div>
                            </Card.Body>
                          </SwiperSlide>
                        );
                      })}
                  </Swiper>
                  <div className="swiper-button swiper-button-next"></div>
                  <div className="swiper-button swiper-button-prev"></div>
                </div>
              </Col>
            </Row>
            <Masonry
              // breakpointCols={3}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              <Col>
                <Card className="h-100">
                  <Card.Header className="d-flex justify-content-between">
                    <div className="header-title">
                      <h4 className="card-title">HCP Details</h4>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <div className="d-inline-flex flex-wrap justify-content-start align-items-center w-100">
                      <Row>
                        <div className="col-7 mb-3">HCP Id</div>

                        <div className="col-5 mb-3">
                          {hcpDetail?.hcp_id ?? "NA"}
                        </div>
                        <div className="col-7 mb-3">User Type</div>
                        <div className="col-4 mb-3">
                          {hcpDetail?.hcp_to_type?.hcp_type_name ?? "NA"}
                        </div>
                        <div className="col-7 mb-3">Age</div>
                        <div className="col-4 mb-3">
                          {hcpDetail?.hcp_age ?? "NA"}
                        </div>
                        <div className="col-7 mb-3">Gender</div>
                        <div className="col-4 mb-3">
                          {hcpDetail?.hcp_gender ?? "NA"}
                        </div>
                        <div className="col-7 mb-3">Email</div>
                        <div className="col-4 mb-3">
                          {hcpDetail?.hcp_email ?? "NA"}
                        </div>
                        <div className="col-7 mb-3">Phone Number</div>
                        <div className="col-4 mb-3">
                          {hcpDetail?.hcp_mobile ?? "NA"}
                        </div>
                        {/* <div className="col-7 mb-3">Degree</div>
                        <div className="col-4 mb-3">
                          {hcpDetail?.hcp_to_medical_degrees?.length > 0
                            ? extractDegreeNames(
                                hcpDetail?.hcp_to_medical_degrees
                              )
                            : "NA"}
                        </div> */}
                        <div className="col-7 mb-3">Country</div>
                        <div className="col-4 mb-3">
                          {" "}
                          {hcpDetail?.hcp_to_country?.country_name ?? "NA"}
                        </div>
                        <div className="col-7 mb-3">Zone </div>
                        <div className="col-4 mb-3">
                          {" "}
                          {hcpDetail?.hcp_to_zone?.zone_name ?? "NA"}
                        </div>
                        <div className="col-7 mb-3">State </div>
                        <div className="col-4 mb-3">
                          {hcpDetail?.hcp_to_state?.state_name ?? "NA"}
                        </div>
                        <div className="col-7 mb-3">City </div>
                        <div className="col-4 mb-3">
                          {hcpDetail?.hcp_to_city?.city_name ?? "NA"}
                        </div>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card data-aos="fade-up" data-aos-delay="900" className="h-100">
                  <Card.Header className="flex-wrap d-flex justify-content-between">
                    <div className="header-title">
                      <h4 className="card-title">Engagement Meter</h4>
                    </div>
                    {/* <Dropdown>
                      <Dropdown.Toggle
                        as={CustomToggle}
                        href="#"
                        variant="text-secondary dropdown-toggle"
                        size="sm"
                        id="dropdownMenuButton1"
                      >
                        This Week
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="#">This Week</Dropdown.Item>
                        <Dropdown.Item href="#">This Month</Dropdown.Item>
                        <Dropdown.Item href="#">This Year</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown> */}
                  </Card.Header>
                  <Card.Body>
                    {!isEngagementMeterDataLoading && (
                      <div className="flex-wrap d-flex align-items-center justify-content-between">
                        <Chart
                          className="col-md-8 col-lg-8"
                          options={engagementMeterChart.options}
                          series={engagementMeterChart.series}
                          type="radialBar"
                          height="300"
                        />
                        <Col md={4} lg={4} className="d-grid gap">
                          {!isEngagementMeterDataLoading &&
                            engagementMeterChart?.options?.labels?.length > 0 &&
                            engagementMeterChart?.series?.length > 0 &&
                            engagementMeterChart?.options?.labels.map(
                              (key, ind) => (
                                <div className="d-flex align-items-start">
                                  <svg
                                    className="mt-0"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    viewBox="0 0 24 24"
                                    fill="#1a5aa7"
                                  >
                                    <g>
                                      <circle
                                        cx="12"
                                        cy="12"
                                        r="8"
                                        fill="#1a5aa7"
                                      ></circle>
                                    </g>
                                  </svg>
                                  <div className="ms-3">
                                    <h4 className="m-0">
                                      {engagementMeterChart?.series[ind]} %
                                    </h4>
                                    <span className="text-secondary">
                                      {key}
                                    </span>
                                  </div>
                                </div>
                              )
                            )}
                        </Col>
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card className="h-100 bg-soft-info">
                  <Card.Header className="d-flex justify-content-between">
                    <div className="header-title">
                      <h4 className="card-title">HCP's Medical Information</h4>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <div className="d-inline-flex flex-wrap justify-content-start align-items-center w-100">
                      <Row>
                        <div className="col-7 mb-3">Degree</div>
                        <div className="col-4 mb-3">
                          {hcpDetail?.hcp_to_medical_degrees?.length > 0
                            ? extractDegreeNames(
                                hcpDetail?.hcp_to_medical_degrees
                              )
                            : "NA"}
                        </div>

                        <div className="col-7 mb-3">Practice Speciality</div>
                        <div className="col-5 mb-3">
                          {hcpDetail?.hcp_to_primary_speciality
                            ?.speciality_name ?? "NA"}
                        </div>
                        <div className="col-7 mb-3">Interest Specialty</div>
                        <div className="col-4 mb-3">
                          {hcpDetail?.hcp_to_interest_speciality?.length > 0
                            ? extractInterestSpecNames(
                                hcpDetail?.hcp_to_interest_speciality
                              )
                            : "NA"}
                        </div>
                        <div className="col-7 mb-3">Council</div>
                        <div className="col-4 mb-3">
                          {hcpDetail?.hcp_to_medical_council?.council_name ??
                            "NA"}
                        </div>
                        <div className="col-7 mb-3">Association </div>
                        <div className="col-4 mb-3">
                          {hcpDetail?.hcp_to_association
                            ? hcpDetail?.hcp_to_association.association_name
                            : "NA"}
                        </div>
                        <div className="col-7 mb-3">Register Number</div>
                        <div className="col-4 mb-3">
                          {hcpDetail?.hcp_register_number
                            ? hcpDetail?.hcp_register_number
                            : "NA"}
                        </div>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card className="h-100 bg-soft-warning">
                  <Card.Header className="d-flex justify-content-between">
                    <div className="header-title">
                      <h4 className="card-title">Onboarding Information</h4>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <div className="d-inline-flex flex-wrap justify-content-start align-items-center w-100">
                      <Row>
                        <div className="col-7 mb-3">User Type:</div>
                        <div className="col-4 mb-3">
                          {hcpDetail?.hcp_to_type?.hcp_type_name
                            ? hcpDetail?.hcp_to_type?.hcp_type_name
                            : "NA"}
                        </div>

                        <div className="col-7 mb-3">Status:</div>
                        <div className="col-5 mb-3">
                          {hcpDetail?.hcp_onboarding_status?.value ?? "NA"}
                        </div>
                        <div className="col-7 mb-3">Medium:</div>
                        <div className="col-5 mb-3">
                          {hcpDetail?.medium ?? "NA"}
                        </div>
                        <div className="col-7 mb-3"> Speciality Document :</div>
                        <div className="col-4 mb-3">
                          {" "}
                          {hcpDetail?.hcp_to_document?.length > 0 &&
                          getHcpDocument(
                            hcpDetail?.hcp_to_document,
                            "speciality"
                          )?.length > 0
                            ? getHcpDocument(
                                hcpDetail?.hcp_to_document,
                                "speciality"
                              ).map((data) => (
                                <a
                                  className=""
                                  href={data ?? "javacript:void(0)"}
                                  target="_blank"
                                >
                                  View{" "}
                                </a>
                              ))
                            : "N/A"}
                        </div>

                        <div className="col-7 mb-3">
                          Registration Document :
                        </div>
                        <div className="col-4 mb-3">
                          {hcpDetail?.hcp_to_document?.length > 0 &&
                          getHcpDocument(
                            hcpDetail?.hcp_to_document,
                            "registration"
                          )?.length > 0
                            ? getHcpDocument(
                                hcpDetail?.hcp_to_document,
                                "registration"
                              ).map((data) => (
                                <a
                                  className=""
                                  href={data ?? "javacript:void(0)"}
                                  target="_blank"
                                >
                                  View
                                </a>
                              ))
                            : "N/A"}
                        </div>

                        <div className="col-7 mb-3">Other Document:</div>
                        <div className="col-4 mb-3">
                          {hcpDetail?.hcp_to_document?.length > 0 &&
                          getHcpDocument(hcpDetail?.hcp_to_document, "other")
                            ?.length > 0
                            ? getHcpDocument(
                                hcpDetail?.hcp_to_document,
                                "other"
                              ).map((data) => (
                                <a
                                  className=""
                                  href={data ?? "javacript:void(0)"}
                                  target="_blank"
                                >
                                  View
                                </a>
                              ))
                            : "N/A"}
                        </div>
                        <div className="col-7 mb-3">Call Recording :</div>
                        <div className="col-4 mb-3">
                          {hcpDetail?.hcp_to_document?.length > 0 &&
                          getHcpDocument(
                            hcpDetail?.hcp_to_document,
                            "recording"
                          )?.length > 0
                            ? getHcpDocument(
                                hcpDetail?.hcp_to_document,
                                "recording"
                              ).map((data) => (
                                <a
                                  className=""
                                  href={data ?? "javacript:void(0)"}
                                  target="_blank"
                                >
                                  View
                                </a>
                              ))
                            : "N/A"}
                        </div>
                        <div className="col-7 mb-3">Social Links:</div>
                        <div className="col-4 mb-3">
                          {transformSocialLinks(hcpDetail?.hcp_to_social_links)
                            ?.length > 0 ? (
                            transformSocialLinks(
                              hcpDetail?.hcp_to_social_links
                            )?.map((data, ind) => (
                              <>
                                <a
                                  href={data?.link ?? "javacript:void(0)"}
                                  target="_blank"
                                >
                                  {data?.platform}
                                </a>
                              </>
                            ))
                          ) : (
                            <>NA</>
                          )}
                        </div>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card className="h-100">
                  <Card.Header className="d-flex justify-content-between">
                    <div className="header-title">
                      <h4 className="card-title">Day of Week Analysis</h4>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <div className="">
                      {!isdayOfWeekLoading && dayOfWeek?.data ? (
                        <Chart
                          options={weekTimePreferences.options}
                          series={dayOfWeek?.data?.series}
                          type="line"
                          height="350"
                        />
                      ) : isdayOfWeekLoading ? (
                        <Skeleton variant="text" width={"100%"} height={350} />
                      ) : (
                        <Chart
                          options={weekTimePreferences.options}
                          series={weekTimePreferences.series}
                          type="line"
                          height="350"
                        />
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card className="h-100">
                  <Card.Header className="d-flex justify-content-between">
                    <div className="header-title">
                      <h4 className="card-title">Time Of Day Analysis</h4>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <div className="">
                      {!istimeOfDayLoading && timeOfDay?.data?.series ? (
                        <Chart
                          options={dayTimePreferences.options}
                          series={timeOfDay?.data?.series}
                          type="line"
                          height="350"
                        />
                      ) : istimeOfDayLoading ? (
                        <Skeleton
                          variant="text"
                          width={"100%"}
                          height={350}
                          className="mb-2"
                        />
                      ) : (
                        <Chart
                          options={dayTimePreferences.options}
                          series={dayTimePreferences.series}
                          type="line"
                          height="350"
                        />
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card data-aos="fade-up" data-aos-delay="600" className="h-100">
                  <Card.Header className="flex-wrap d-flex justify-content-between">
                    <div className="header-title">
                      <h4 className="mb-2 card-title">Activity Timeline</h4>
                      {!isActivityTimelineLoading &&
                        activityTimeline.data?.length > 0 && (
                          <p className="mb-0">
                            <svg
                              className="me-2"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#17904b"
                                d="M13,20H11V8L5.5,13.5L4.08,12.08L12,4.16L19.92,12.08L18.5,13.5L13,8V20Z"
                              />
                            </svg>
                            This Month
                          </p>
                        )}
                    </div>
                  </Card.Header>
                  <Card.Body>
                    {!isActivityTimelineLoading &&
                    activityTimeline.data?.length > 0 ? (
                      activityTimeline.data.map((data, ind) => (
                        <div className="mb-2 d-flex profile-media align-items-top">
                          <div className="mt-1 profile-dots-pills rounded-circle border-primary"></div>
                          <div className="ms-4">
                            <h6 className="mb-1 ">
                              {data?.page_id}-
                              <span className="text-primary">
                                {data?.page_title}
                              </span>
                            </h6>
                            <h6 className="mb-1 ">
                              Source:
                              <span className="text-primary">
                                {data?.utm_source}
                              </span>
                            </h6>

                            <span className="mb-0 fs-7">{`Activity Time- ${
                              data.duration
                            } Sec ${
                              data.date_time ? `On ${data.date_time}` : ""
                            }`}</span>
                          </div>
                        </div>
                      ))
                    ) : isActivityTimelineLoading ? (
                      <>Loading...</>
                    ) : (
                      <>No Activity found for this user</>
                    )}
                  </Card.Body>
                  {/* <Card.Footer>
                    <div className="d-flex justify-content-between cursor-pointer">
                      View All
                    </div>
                  </Card.Footer> */}
                </Card>
              </Col>
              <Col>
                <Card className="h-100">
                  <Card.Header className="d-flex justify-content-between">
                    <div className="header-title">
                      <h4 className="card-title">NBA's View:</h4>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <div className="d-inline-flex flex-wrap justify-content-start align-items-center w-100 overflow-auto">
                      {!isNbaViewDataLoading &&
                      nbaViewData?.rows?.length > 0 ? (
                        <ReactTable
                          data={nbaViewData.rows}
                          columns={nbaColumn}
                          tdClass={"threeColTable"}
                          thClass={"threeColTable"}
                          // onTableStateChange={onTableStateChange}
                          recordsTotal={nbaTotalViewCount}
                          // initialState={post_json.current}
                          // isRowSelectionAvailable={true}
                          // onRowSelectionChange={(selectedList) =>
                          //   onRowSelectionChange(selectedList)
                          // }
                          // recordsPerPage={post_json.current.pagination.limit}
                        />
                      ) : isNbaViewDataLoading ? (
                        <>Loading...</>
                      ) : (
                        <>No Data Found</>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              {/* <Col>
                <Card>
                  <Card.Header className="d-flex justify-content-between">
                    <div className="header-title">
                      <h4 className="card-title">Location</h4>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <div className="position-relative overflow-hidden lefletArea">
                      <Leaflet ref={map_one} />
                    </div>
                  </Card.Body>
                </Card>
              </Col> */}

              <Col>
                <Card className="h-100 bg-soft-warning">
                  <Card.Header className="d-flex justify-content-between">
                    <div className="header-title">
                      <h4 className="card-title">System Information</h4>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <div className="d-inline-flex flex-wrap justify-content-start align-items-center w-100">
                      <Row>
                        <div className="col-7 mb-3">MDMID</div>
                        <div className="col-5 mb-3">{hcpDetail?.hcp_id}</div>
                        <div className="col-7 mb-3">DND</div>
                        <div className="col-5 mb-3">
                          {hcpDetail?.hcp_to_dnd?.length > 0
                            ? getDndChannelNames(hcpDetail?.hcp_to_dnd)
                            : "NA"}
                        </div>
                        <div className="col-7 mb-3">Registration Date</div>
                        <div className="col-4 mb-3">
                          {hcpDetail?.registration_date ?? "NA"}
                        </div>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>
              </Col>

              <Col>
                <Card className="h-100 bg-soft-warning">
                  <Card.Header className="d-flex justify-content-between">
                    <div className="header-title">
                      <h4 className="card-title">Campaign Information</h4>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <div className="d-inline-flex flex-wrap justify-content-start align-items-center w-100">
                      <Row>
                        <div className="col-7 mb-3">Utm Source</div>
                        <div className="col-5 mb-3">
                          {hcpDetail?.hcp_utmsource}
                        </div>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>
              </Col>

              {hcpDetail?.hcp_to_persona?.length > 0 && (
                <Col>
                  <Card>
                    <Card.Header className="d-flex justify-content-between">
                      <div className="header-title">
                        <h4 className="card-title">Doctor's Persona</h4>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <div className="d-flex flex-column gap-3">
                        {hcpDetail?.hcp_to_persona.map(
                          (data, key) =>
                            data?.all_persona?.persona_name && (
                              <div className="bg-soft-info p-3 rounded-3">
                                <h5 className="fw-semibold">
                                  {data?.all_persona?.persona_name}
                                </h5>
                              </div>
                            )
                        )}
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              )}
            </Masonry>

            <Col>
              <Card className="h-100">
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">Engagement Summary</h4>
                  </div>
                </Card.Header>
                <Card.Body>
                  {!isAllEngagementLoading &&
                  allEngagement?.rows?.length > 0 ? (
                    <ReactTable
                      tdClass={"threeColTable"}
                      thClass={"threeColTable"}
                      data={allEngagement.rows}
                      columns={engagementColumn}
                      recordsTotal={engagementViewCount}
                    />
                  ) : isAllEngagementLoading ? (
                    <>Loading...</>
                  ) : (
                    <>No Data Found</>
                  )}
                  {/* <ReactTable
                    data={nbaViewData.rows}
                    columns={nbaColumn}
                    tdClass={"threeColTable"}
                    thClass={"threeColTable"}
                    recordsTotal={nbaTotalViewCount}
                  /> */}
                </Card.Body>
              </Card>
            </Col>
            {/* <Col>
              <Card className="h-100">
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">Engagement Consent</h4>
                  </div>
                </Card.Header>
                <Card.Body>
                  <ReactTable
                    data={engagementConcentData}
                    columns={engagementConcentColumn}
                    recordsTotal={3}
                  />
                </Card.Body>
              </Card>
            </Col> */}
          </Card>
        </Col>
      </Masonry>
      <OnboardingLogModal show={showModal} modalClose={closeModal} />
      <CommonModal
        title={"Please Confirm"}
        body={`Are you sure want to ${hcpDetail?.hcp_to_speciality_eligiblity_status?.status_name} Speciality Dashboard status`}
        show={speciality_dashboard_modal}
        modalClose={onChangeSpecDashboardClick}
        onSubmit={onSubmitSpecialityStatus}
        isLoading={specDashboardUpdateLoading}
        loaderText={"Updating..."}
      />
      <RequestDataChnageModal
        show={request_data_change_modal}
        title={`Request Data Change For ${hcpDetail?.hcp_first_name}`}
        onClose={onRequestDataChnageClick}
        onSubmit={onRequestDataChnageClick}
        data={{ hcp_id: hcpId }}
      />
      <ChnageRecommendationModal
        show={change_recommendation_modal_status}
        title={`Change Recommendation Engine For ${hcpDetail?.hcp_first_name}`}
        onClose={onRecommendationDataChnageClick}
        onSubmit={onSubmitRecommendationStatus}
        data={{ hcp_id: hcpId, ...hcpDetail?.hcp_to_recommendation_status }}
      />
    </Fragment>
  );
};

export default HCP_ProfileDetails;
