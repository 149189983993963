export const HCP_LISTING = "/hcp/list";
export const HCP_DETAILS = "/hcp/detail";
export const HCP_ACTIVITY = "/hcp/activity"; //
export const HCP_ENGAGEMENT_METER_DATA = "/hcp/get_engagement_meter_data";
export const HCP_DAY_OF_WEEK = "/hcp/day_of_week";
export const HCP_NBA_VIEW = "/hcp/get_nba_view";
export const HCP_TIME_OF_DAY = "/hcp/time_of_day";
export const HCP_ACTIVITY_TIMELINE = "/hcp/activity_timeline";
export const HCP_ALL_ENGAGEMENT_LIST = "/hcp/all_engagement";
export const HCP_REQUEST_DATA_CHNAGE = "/hcp/request_data_change";
export const HCP_UPDATE_SPECIALITY_STATUS =
  "/hcp/update_speciality_dashboard_status";
export const HCP_UPDATE_RECOMMENDATION_STATUS =
  "/hcp/update_recommendation_dashboard_status";
