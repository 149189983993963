import React, { useEffect, useState } from "react";
import Card from "../../../../components/bootstrap/card";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import RaiseSupportLoader from "../../../../skeleton/helpsupport/raise-support-loader";
import { findIcon } from "../../../dashboard/icons";
// import FloatingButton from "../../../../components/form-components/floating-button";
import { getService } from "../../../../api/services/get-services";
import {
  GET_SUPPORT_DATA,
  SUPPORT_ADD,
  SUPPORT_FILE_UPLOAD,
} from "../../../../api/endpoints/help-and-support-endpoints";
import uploadService from "../../../../api/services/upload-service";
import postService from "../../../../api/services/post-service";
import toast from "react-hot-toast";
import useRedirect from "../../../../components/custom-hooks/use-redirect";

const RaiseSupportTicket = () => {
  const [ticketData, setTicketData] = useState({
    support_name: "",
    support_description: "",
    support_path: "",
    support_priority_id: "",
    support_module_id: "",
  });
  const [categoriesList, setCategoriesList] = useState([]);
  const [prioritiesList, setPrioritiesList] = useState([]);
  const [loading, setLoading] = useState(true);
  setTimeout(() => {
    setLoading(false);
  }, 3000);

  const { redirectTo } = useRedirect();
  useEffect(() => {
    getCategoryAndPriority();
  }, []);
  const getCategoryAndPriority = async () => {
    const response = await getService(GET_SUPPORT_DATA);
    console.log("getCategoryAndPriority", response.data.success);
    if (response.data.success) {
      setCategoriesList(response.data.data.category);
      setPrioritiesList(response.data.data.priroties);
    } else {
    }
  };
  const getTicketData = async (e) => {
    if (e.target.name === "support_path") {
      console.log("xxxxxxxxx", e.target.name);
      const { files } = e.target;
      console.log("FILES", files);
      // upload images and then setFieldValue of reference_files
      const fileName = await uploadFiles([...files]);
      console.log("FILE_NAME", fileName);
      if (fileName) {
        setTicketData({ ...ticketData, [e.target.name]: fileName });
      }
    } else {
      setTicketData({ ...ticketData, [e.target.name]: e.target.value });
    }
  };

  function uploadFiles(files) {
    return new Promise(async (resolve, reject) => {
      // Check if files array is empty
      if (files.length === 0) {
        reject("No files to upload.");
        return;
      }
      //console.log("uploading files", files);
      let formdata = new FormData();
      console.log("FORMDATA", files);
      files.forEach((file) => {
        formdata.append("files[]", file);
      });

      // 1. Upload files to server
      let response = await uploadService(SUPPORT_FILE_UPLOAD, formdata);
      if (response.data?.success) {
        // console.log("RESPONSE", response.data.data['0'].url);
        // 2. Simulating successful upload and generating URLs
        const name = response.data.data["0"].name;
        console.log("URLS if exist", response.data.data);
        console.log("URLS", name);
        resolve(name);
      } else {
        reject("Something went wrong");
      }
    });
  }
  const addTicketSubmit = async () => {
    let response = await postService(SUPPORT_ADD, ticketData);
    if (response.data.success) {
      toast.success(response.data.message);
      redirectTo("action/onboarding-task");
    } else {
      toast.error(response.data.message);
    }
  };
  const isSubmitDisabled =
    ticketData.support_name === "" ||
    ticketData.support_description === "" ||
    ticketData.support_path === "" ||
    ticketData.support_priority_id === "" ||
    ticketData.support_module_id === "";
  if (loading) {
    return <RaiseSupportLoader />;
  }

  return (
    <>
      {/* <FloatingButton /> */}
      <h3 className="mb-4">Raise Support Ticket</h3>
      <Card>
        <Card.Body>
          <Row>
            <Col lg="6">
              <h5 className="mb-3">Ticket Subject</h5>
              <div className="mb-4">
                <Form.Floating className="custom-form-floating">
                  <Form.Control
                    name="support_name"
                    type="text"
                    placeholder="Please enter a brief summary"
                    onChange={getTicketData}
                  />
                  <Form.Label>Please enter a brief summary</Form.Label>
                </Form.Floating>
              </div>
            </Col>
            <Col lg="6">
              <h5 className="mb-3">Select Priority</h5>
              <div className="mb-4">
                <Form.Floating className="custom-form-floating">
                  {/* <Form.Label>Priority Level</Form.Label> */}
                  <Form.Select
                    // type="text"
                    // placeholder="Enter Ticket Subject"
                    name="support_priority_id"
                    onChange={getTicketData}
                  >
                    <option value="">Select Priority</option>
                    {prioritiesList &&
                      prioritiesList.length > 0 &&
                      prioritiesList.map((_p) => (
                        <option value={_p.priority_id} key={_p.priority_id}>
                          {_p.priority_name}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Floating>
              </div>
            </Col>
            <Col lg="6">
              <h5 className="mb-3">Catagory</h5>
              <div className="mb-4">
                <Form.Floating className="custom-form-floating h-100">
                  <Form.Select
                    // type="text"
                    placeholder="Select Catagory"
                    name="support_module_id"
                    onChange={getTicketData}
                  >
                    {" "}
                    <option value="">Select Priority</option>
                    {categoriesList &&
                      categoriesList.length > 0 &&
                      categoriesList.map((_c) => (
                        <option value={_c.module_id} key={_c.module_id}>
                          {_c.module_title}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Floating>
              </div>
            </Col>
            <Col lg="6">
              <h5 className="mb-3">Attachment</h5>
              <div className="mb-4">
                <Form.Floating className="custom-form-floating">
                  <Form.Control
                    type="file"
                    // placeholder="Enter Ticket Subject"
                    name="support_path"
                    onChange={getTicketData}
                  />
                </Form.Floating>
              </div>
            </Col>
            <Col lg="12" className="mb-4 flex-grow-1">
              <h5 className="mb-3">Description</h5>
              <Form.Floating className="custom-form-floating h-100 mb-4">
                <textarea
                  name="support_description"
                  className="form-control h-100"
                  placeholder="Please provide a detailed description"
                  id="floatingTextarea2"
                  onChange={getTicketData}
                ></textarea>
                <Form.Label htmlFor="fname">
                  Please provide a detailed description
                </Form.Label>
              </Form.Floating>
            </Col>
          </Row>
          <div className="d-flex gap-2 gap-md-3 justify-content-end flex-wrap flex-md-nowrap mt-4">
            <Button
              type="btn"
              variant="btn btn-primary flex-md-grow-0 flex-grow-1 text-nowrap"
              onClick={addTicketSubmit}
              disabled={isSubmitDisabled}
            >
              Submit
            </Button>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default RaiseSupportTicket;
