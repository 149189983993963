export const PRINICIPLE_ENTITIES_GET = "contentstudio/principleentity/list";
export const FILE_TYPES_GET = "contentstudio/filetype/list";
export const CONTENT_STUDIO_LIST_POST = "contentstudio/list";
export const CONTENT_ADD_POST = "contentstudio/add";
export const CONTENT_CREATE_GET = "contentstudio/create";
export const CONTENT_TREE_GET = "contentlibrary/principle_entity/list";
export const CONTENT_FILE_LIST = "contentstudio/content_library_list";
export const CONTENT_STUDIO_DETAILS = "contentstudio/detail/";
export const CONTENT_STUDIO_FILE_DETAILS =
  "contentstudio/content_library_detail/";
export const CONTENT_STUDIO_UPDATE = "contentstudio/update/";

export const CONTENT_STUDIO_SPAM_CHECK =
  "https://asia-south1-clirnet-dev.cloudfunctions.net/email_wp_sms_spam_checker";

export const TARGET_SELF_TEST = "contentstudio/target_self_test";
export const RAISE_TICKET = "contentstudio/self_test_review";
