import React, {
  Fragment,
  useRef,
  useState,
  useCallback,
  useEffect,
} from "react";
import { Button, Card, Col, Form, Row, Modal, Badge } from "react-bootstrap";
import { findIcon } from "../../../../dashboard/icons";
import ReactTable from "../../../../../lib/table/react-table";
import postService from "../../../../../api/services/post-service";
import {
  ALL_SPECIALITIES,
  ADD_SPECIALITY,
  DELETE_SPECIALITY,
  UPDATE_SPECIALITY,
  GET_SPECIALITY_BY_ID,
} from "../../../../../api/endpoints/speciality-management-endpoints";
import ViewModal from "../../../../../components/partials/common/view-modal";
import DeleteModal from "../../../../../components/partials/common/delete-modal";
import { getService } from "../../../../../api/services/get-services";
import {
  changeTableStateToPayload,
  statusToBg,
} from "../../../../../common/utils/utils";
import * as yup from "yup";
import { useFormik } from "formik";
import ActionButtonBar from "../../../../../components/partials/common/action-button-bar";
import toast from "react-hot-toast";
import FilterBar from "../../../../../components/filter-bar";
import CommonTableLoader from "../../../../../skeleton/common-table-loader";
import PageNotFound from "../../../../PageNotFound";

const AllSpecialities = () => {
  const [createModalShow, setCreateModalShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [specialityList, setSpecialityList] = useState([]);
  const [viewModalShow, setViewModalShow] = useState(false);
  const [viewSpeciality, setViewSpeciality] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [selectedSpeciality, setSelectedSpeciality] = useState(null);
  const post_json = useRef({
    default: "all",
    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      speciality_id: "desc",
    },
  });

  const validationSchema = yup.object().shape({
    speciality_name: yup.string().required("Speciality Name is required"),
    //   .test("Unique", "Values need te be unique", (values) => {
    //     return duplicateNameCheck(specialityList, values);
    // }),
    speciality_description: yup
      .string()
      .required("Speciality Description is required"),
  });
  //formik
  const formik = useFormik({
    initialValues: {
      speciality_name: "",
      speciality_description: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("values===>", values);

      const postValues = {
        speciality_name: values.speciality_name.trim(),
        speciality_description: values.speciality_description.trim(),
      };

      try {
        // setLoading(true);
        const response = await postService(
          isEdit
            ? UPDATE_SPECIALITY + "/" + selectedSpeciality
            : ADD_SPECIALITY,
          {
            ...postValues,
            status: isEdit ? values.status : 1,
          }
        );
        if (response) {
          setLoading(false);
          setCreateModalShow(false);
          isEdit && setIsEdit(false);
          fetchSpecialities();
          isEdit
            ? toast.success("Speciality Updated Successfully")
            : toast.success("New Speciality Added Successfully");
        }
      } catch (error) {
        setLoading(false);
        console.log("SPECIALITY_SERVICE_ERROR", error);
      }
    },
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    resetForm,
  } = formik;
  //fetch specialities
  const fetchSpecialities = async () => {
    let data = post_json.current;
    try {
      // alert("fetching");
      // setLoading(true);
      const response = await postService(ALL_SPECIALITIES, data);
      console.log("SPECIALITY_SERVICE_RESPONSE", response.data.data.rows);
      console.log("COLUMNS", Object.keys(response.data.data.rows[0]));
      if (response.data.data.rows.length > 0) {
        setSpecialityList(response.data.data.rows);
        setTotalCount(response.data.data.totalCount);
        setLoading(false);
      } else {
        setLoading(false);
        setTotalCount(0);
        setSpecialityList([]);
      }
    } catch (error) {
      setLoading(false);
      console.log("SPECIALITY_SERVICE_ERROR", error);
    }
  };
  const viewModal = async (speciality) => {
    // alert(JSON.stringify(speciality, null, 2));
    const { speciality_id, speciality_name, speciality_description, status } =
      speciality;
    let dataJson = {
      "Speciality ID": speciality_id,
      "Speciality Name": speciality_name,
      "Speciality Status": status.value,
      "Speciality Description": speciality_description,
    };
    setViewSpeciality(dataJson);
    setViewModalShow(true);
  };
  const viewModalClose = () => {
    setViewModalShow(false);
  };
  const deleteSpeciality = async () => {
    try {
      // setLoading(true);
      const response = await getService(
        `${DELETE_SPECIALITY}/${selectedSpeciality}`
      );
      if (response) {
        setLoading(false);
        toast.success("Speciality Deleted Successfully");
        setDeleteModalShow(false);
        fetchSpecialities();
      }
    } catch (error) {
      setLoading(false);
      console.log("SPECIALITY_SERVICE_ERROR", error);
    }
  };
  useEffect(() => {
    fetchSpecialities();
  }, []);
  const onTableStateChange = useCallback(
    async (state) => {
      let payload = changeTableStateToPayload(state);
      post_json.current = payload;

      fetchSpecialities();
    },
    [specialityList, loading, post_json.current]
  );
  const onDeleteSpeciality = (speciality) => {
    // alert("deleting" + speciality.speciality_id);
    setSelectedSpeciality(speciality.speciality_id);
    setDeleteModalShow(true);
  };
  const columns = [
    {
      Header: "Speciality ID",
      accessor: "speciality_id",
    },
    {
      Header: "Speciality Name",
      accessor: "speciality_name",
    },
    {
      Header: "Speciality Description",
      accessor: "speciality_description",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => (
        <Badge bg={statusToBg(value.id)} className="me-2">
          <span className="btn-inner">{value.value}</span>
        </Badge>
      ),
    },
    {
      Header: "Created By",
      accessor: "created_by",
      Cell: ({ value }) => value.name,
    },
    {
      Header: "Action",
      accessor: "action",
      disableFilters: true,
      Cell: ({ row }) => (
        <ActionButtonBar
          row={row}
          onView={(data) => viewModal(data)}
          onEdit={(data) => onEditSpecialityClick(data)}
          onDelete={(data) => onDeleteSpeciality(data)}
        />
      ),
    },
  ];

  const handleCreate = () => {
    resetForm();
    setCreateModalShow(true);
  };
  const onEditSpecialityClick = (speciality) => {
    console.log("speciality===>", speciality);
    setIsEdit(true);
    setSelectedSpeciality(speciality.speciality_id);

    const editValues = {
      speciality_name: speciality?.speciality_name,
      speciality_description: speciality?.speciality_description,
      status: speciality?.status.id,
    };
    //set formik values
    setValues(editValues);
    setCreateModalShow(true);
  };

  const advanceData = [
    { value: "Speciality Name", label: "Speciality Name", type: "string" },
    {
      value: "Speciality Desc",
      label: "Speciality Desc",
      type: "string",
      // elements: [
      //   { value: "Content Studio 1", label: "Content Studio 1" },
      //   { value: "Content Studio 2", label: "Content Studio 2" },
      //   { value: "Content Studio 3", label: "Content Studio 3" },
      // ],
    },
    {
      value: "Status",
      label: "Status",
      type: "string",
      elements: [
        { value: "Disabled", label: "Disabled" },
        { value: "Enabled", label: "Enabled" },
      ],
    },
    {
      value: "Created By",
      label: "Created By",
      type: "string",
      // elements: [
      //   { value: "Message Distribution 1", label: "Message Distribution 1" },
      //   { value: "Message Distribution 2", label: "Message Distribution 2" },
      //   { value: "Message Distribution 3", label: "Message Distribution 3" },
      // ],
    },
  ];

  return (
    <Fragment>
      <Button
        variant="success"
        className="d-flex align-items-center justify-content-center add_floating__button position-fixed rounded-circle p-1 avatar-80 lh-1 border border-white border-2"
        onClick={() => handleCreate()}
      >
        {findIcon("Plus", "outline", "32")}
      </Button>
      <Row>
        <Col sm="12" className="p-0 p-md-2">
          <Card>
            <Card.Header className="d-flex flex-column flex-md-row flex-row flex-sm-row justify-content-between align-items-start align-items-md-center p-3 pb-0">
              <h4 className="card-title">All Specialities</h4>
              {/* <Button
                type="button"
                variant="primary"
                className="fw-bold rounded-3"
                onClick={() => handleCreate()}
              >
                {findIcon("Plus", "dual-tone", 32)} Add Speciality
              </Button> */}
              <FilterBar advanceData={advanceData} />
            </Card.Header>
            <Card.Body className="p-3">
              {!loading ? (
                specialityList.length > 0 ? (
                  <ReactTable
                    data={specialityList}
                    columns={columns}
                    recordsTotal={totalCount}
                    onTableStateChange={onTableStateChange}
                    initialState={post_json.current}
                    isRowSelectionAvailable={false}
                    recordsPerPage={10}
                  />
                ) : (
                  <PageNotFound />
                )
              ) : (
                <CommonTableLoader />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <DeleteModal
        show={deleteModalShow}
        modalClose={() => setDeleteModalShow(false)}
        onSubmit={deleteSpeciality}
      />
      <ViewModal
        title={"View Speciality Information"}
        show={viewModalShow}
        modalClose={viewModalClose}
        data={viewSpeciality}
      />
      {/* create a add Speciality modal with Speciality name and description */}
      <Modal
        show={createModalShow}
        onHide={() => {
          setCreateModalShow(false);
          setIsEdit(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? "Edit" : "Add"} Speciality</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                Speciality Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Speciality Name"
                name="speciality_name"
                onChange={handleChange}
                value={values.speciality_name}
                onBlur={handleBlur}
                isInvalid={touched.speciality_name && errors.speciality_name}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                Speciality Description <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Speciality Description"
                name="speciality_description"
                onChange={handleChange}
                value={values.speciality_description}
                onBlur={handleBlur}
                isInvalid={
                  touched.speciality_description &&
                  errors.speciality_description
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.speciality_description}
              </Form.Control.Feedback>
            </Form.Group>

            {isEdit && (
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  Speciality Status <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  name="status"
                  value={values?.status}
                  isInvalid={touched.status && errors.status}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="">Select Status</option>
                  <option value="1">Enabled</option>
                  <option value="2">Disabled</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors?.status}
                </Form.Control.Feedback>
              </Form.Group>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setCreateModalShow(false);
              setIsEdit(false);
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            {isEdit ? "Update Speciality" : "Add Speciality"}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default AllSpecialities;
