import React from "react";

// layout

import Default from "../../../../../layouts/dashboard/default";
import AllZone from "../pages/all-zone";
// pages

export const ZoneRouter = [
  {
    path: "/master-management/zone",
    element: <Default />,
    children: [
      {
        path: "all",
        element: <AllZone/>,
      },
    ],
  },
];
