import axios from "axios";
import app_config from "../../common/data/app_config";
import { getStorage } from "../../utilities/setting";

const axiosInstance = axios.create({
  baseURL: app_config.api_url,
});
axiosInstance.interceptors.request.use(
  async (req) => {
    let accessToken = ''
    if (!accessToken) {
      accessToken = getStorage("access_token")
        ? `Bearer ${getStorage("access_token")}`
        : "";
    }
    req.headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: accessToken,
    };

    return req;
  },
  async (error) => {
    return Promise.reject(error);
  }
);
export default axiosInstance;
