import axios from "axios";
import app_config from "../../common/data/app_config";
import { getStorage } from "../../utilities/setting";

const cmsAxiosInstance = axios.create({
  baseURL: app_config.cms_api_url,
});
cmsAxiosInstance.interceptors.request.use(
  async (req) => {
    let accessToken =
      "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2NpcGxhY21zZGV2YXBpLmNsaXJuZXQuY29tL2FwaS9sb2dpbiIsImlhdCI6MTY4NTk3NzY1NCwiZXhwIjoxOTk3MDE3NjU0LCJuYmYiOjE2ODU5Nzc2NTQsImp0aSI6Ik13UTlnbGNRU3pqZDFBUnkiLCJzdWIiOiIxIiwicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyJ9.dY3rD7bgo_2uRyHuRp2_igHrRC7BMO6A9f_3UhuUypQ";
    // if (!accessToken) {
    //   accessToken = getStorage("access_token")
    //     ? `Bearer ${getStorage("access_token")}`
    //     : "";
    // }
    req.headers = {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      Authorization: accessToken,
    };

    return req;
  },
  async (error) => {
    return Promise.reject(error);
  }
);
export default cmsAxiosInstance;
