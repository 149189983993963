import {
  memo,
  Fragment,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";

//react-bootstrap
import { Badge, Button, Card, Col, Row } from "react-bootstrap";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import DeleteModal from "../../../../components/partials/common/delete-modal";
import postService from "../../../../api/services/post-service";

import { findIcon } from "../../../dashboard/icons";
import ViewModal from "../../../../components/partials/common/view-modal";
import {
  DELETE_ROLE,
  ROLE_LIST_POST,
} from "../../../../api/endpoints/role-management-endpoints";
import {
  changeTableStateToPayload,
  statusToBg,
} from "../../../../common/utils/utils";
import ReactTable from "../../../../lib/table/react-table";
import FilterBar from "../../../../components/filter-bar";
import { getService } from "../../../../api/services/get-services";
import toast from "react-hot-toast";
import CommonTableLoader from "../../../../skeleton/common-table-loader";
import PageNotFound from "../../../PageNotFound";

const AllUser = memo(() => {
  const { redirectTo } = useRedirect();
  const [modalShow, setModalShow] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [viewModalShow, setViewModalShow] = useState(false);
  const [viewRole, setViewRole] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedRole, setSelectedRole] = useState("");
  const viewModal = async (data) => {
    // console.log("data==>", data);
    // setViewModalShow(true);
    let { created_by, description, id, name, status } = data;
    let dataObj = {
      Id: id ?? "",
      Name: name ?? "",
      "Created By": created_by?.name ?? "",
      Description: description ?? "",
      Status: status?.value ?? "",
    };
    setViewRole(dataObj);
    setViewModalShow(true);
  };
  const viewModalClose = () => {
    setViewModalShow(false);
  };

  const columns = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Name",
      accessor: "name",
      disableFilters: false,
      Cell: ({ value }) => {
        if (value?.length > 30) {
          return value?.substring(0, 30) + "...";
        } else {
          return value;
        }
      },
    },
    {
      Header: "Description",
      accessor: "description",
      disableFilters: false,
      Cell: ({ value }) => {
        if (value?.length > 30) {
          return value?.substring(0, 30) + "...";
        } else {
          return value;
        }
      },
    },
    {
      Header: "Created By",
      accessor: "created_by",
      Cell: ({ value }) => <span>{value ? value.name : "System"}</span>,
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => (
        <Badge bg={statusToBg(value.value)}>{value.value}</Badge>
      ),
    },
    {
      Header: "Action",
      accessor: "action",
      disableFilters: true,
      Cell: ({ row }) => (
        <div className="d-flex justify-content-left align-items-center gap-2">
          <Button
            className="btn-icon btn-sm rounded-pill"
            variant="info"
            to="#"
            role="button"
            onClick={() => viewModal(row.values)}
          >
            <span className="btn-inner">
              {findIcon("Eye", "dual-tone", 32)}
            </span>
          </Button>

          <Button
            className="btn-icon btn-sm rounded-pill"
            to="#"
            role="button"
            onClick={() => handleEdit(row.original.id)}
          >
            <span className="btn-inner">{findIcon("Pencil", "dual-tone")}</span>
          </Button>

          <Button
            className="btn-icon btn-sm rounded-pill"
            variant="danger"
            to="#"
            role="button"
            onClick={() => modalOpen(row.original.id)}
          >
            <span className="btn-inner">
              {findIcon("Trash", "dual-tone", 32)}
            </span>
          </Button>
        </div>
      ),
    },
  ];

  const post_json = useRef({
    // filter: {
    //   id: {
    //     type: "eq",
    //     value: 1,
    //   },
    //   name: {
    //     type: "like",
    //     value: "",
    //   },
    // },
    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      id: "asc",
    },
  });

  const modalClose = () => {
    setModalShow(false);
  };

  const modalOpen = (id) => {
    setSelectedRole(id);
    setModalShow(true);
  };

  const handleEdit = (id) => {
    redirectTo(`roles/edit/${id}`);
  };

  const fetchRoles = async (postJson) => {
    try {
      // setLoading(true);
      const response = await postService(ROLE_LIST_POST, postJson);
      if (response.data.data.rows.length > 0) {
        setRoleList(response.data.data.rows);
        setTotalCount(response.data.data.totalCount);
        setLoading(false);
      }
    } catch (error) {
      setRoleList([]);
      setTotalCount(0);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRoles(post_json.current);

    return () => {};
  }, []);

  const onTableStateChange = useCallback(
    async (state) => {
      let paylod = changeTableStateToPayload(state);
      fetchRoles(paylod);
    },
    [roleList, loading, post_json.current]
  );
  const handleCreate = () => {
    redirectTo("roles/create");
  };

  const advanceData = [
    {
      value: "Name",

      type: "string",

      label: "Name",
      // elements: [
      //   { value: "1", label: "Brand 1" },
      //   { value: "2", label: "Brand 2" },
      // ],

      placeholder: "Enter Name",
    },
    {
      value: "Descriotion",

      type: "string",

      label: "Descriotion",
      // elements: [
      //   { value: "1", label: "Brand 1" },
      //   { value: "2", label: "Brand 2" },
      // ],

      placeholder: "Enter Desc",
    },
    // {
    //   value: "Registration Date",
    //   label: "Registration Date",
    //   type: "string",
    //   elements: [
    //     {
    //       name: "start_date",
    //       type: "date",
    //       placeholder: "Enter Start Date",
    //       label: "Start Date",
    //     },
    //     {
    //       name: "end_date",
    //       type: "date",
    //       placeholder: "Enter End Date",
    //       label: "End Date",
    //     },
    //   ],
    //   placeholder: "",
    // },
    {
      value: "status",

      type: "string",

      label: "Status",
      elements: [
        { value: "1", label: "Enabled" },
        { value: "2", label: "Disabled" },
      ],
      placeholder: "publishing status",
    },
    {
      value: "Created By",

      type: "string",

      label: "Created By",
      elements: [
        { value: "1", label: "Creator 1" },
        { value: "2", label: "Creator 2" },
      ],
      placeholder: "Creator Name",
    },
  ];

  //For Delete data
  const deleteRole = async () => {
    try {
      setLoading(true);
      const response = await getService(`${DELETE_ROLE}/${selectedRole}`);
      console.log("delete role response", response);
      if (response) {
        setLoading(false);
        setModalShow(false);
        if (response.data.success) {
          toast.success(response.data.message);
        }
        if (response.isError) {
          toast.error(response?.error);
        }
      }

      fetchRoles();
    } catch (error) {
      setLoading(false);
      console.log("USER_SERVICE_ERROR", error);
    }
  };

  return (
    <Fragment>
      <Button
        variant="success"
        className="d-flex align-items-center justify-content-center add_floating__button position-fixed rounded-circle p-1 avatar-80 lh-1 border border-white border-2"
        onClick={() => handleCreate()}
      >
        {findIcon("Plus", "outline", "32")}
      </Button>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">
              <h4 className="card-title">All Roles</h4>
              {/* <Button
                type="button"
                variant="primary"
                className="fw-bold rounded-3"
                onClick={() => handleCreate()}
              >
                {findIcon("Plus", "dual-tone", 32)} Add Role
              </Button> */}
              <FilterBar advanceData={advanceData} pageType="All Roles" />
            </Card.Header>
            <Card.Body className="px-3">
              {!loading ? (
                roleList.length > 0 ? (
                  <div className="table-responsive">
                    <ReactTable
                      data={roleList}
                      columns={columns}
                      recordsTotal={totalCount}
                      onTableStateChange={onTableStateChange}
                    />
                  </div>
                ) : (
                  <PageNotFound />
                )
              ) : (
                <CommonTableLoader />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <DeleteModal
        show={modalShow}
        modalClose={modalClose}
        onSubmit={deleteRole}
      />
      <ViewModal
        title={"View Role Information"}
        show={viewModalShow}
        modalClose={viewModalClose}
        data={viewRole}
      />
    </Fragment>
  );
});

AllUser.displayName = "AllUser";
export default AllUser;
