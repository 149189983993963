import { useState, useEffect, memo } from "react";

//react-bootstrap
import {
  Navbar,
  Container,
  Nav,
  Dropdown,
  Form,
  Image,
  Button,
  Collapse,
} from "react-bootstrap";

//router
import { Link, useLocation } from "react-router-dom";

//component
import CustomToggle from "../../../dropdowns";
import Card from "../../../bootstrap/card";
import Modalpopup from "../../../../views/modules/auth/components/modal-popup";

//img
import img1 from "../../../../assets/images/shapes/01.png";
import img2 from "../../../../assets/images/shapes/02.png";
import img3 from "../../../../assets/images/shapes/03.png";
import img4 from "../../../../assets/images/shapes/04.png";

// logo
import Logo from "../../components/logo";

// Redux Selector / Action
import { useDispatch, useSelector } from "react-redux";

// Import selectors & action from setting store
import * as SettingSelector from "../../../../store/setting/selectors";

import RadioBtn from "../../../setting/elements/radio-btn";
import app_config from "../../../../common/data/app_config";

import postService from "../../../../api/services/post-service";
import { LOGOUT_POST } from "../../../../api/endpoints/auth-endpoints";
import toast from "react-hot-toast";
import useRedirect from "../../../custom-hooks/use-redirect";
import { getStorage, updateStorage } from "../../../../utilities/setting";
import { storages } from "../../../../common/utils/utils";
import { findIcon } from "../../../../views/dashboard/icons";
import { logoutSuccess } from "../../../../store/auth/actions";

const Headerpro = memo((props) => {
  const navbarHide = useSelector(SettingSelector.navbar_show); // array
  const themeFontSize = useSelector(SettingSelector.theme_font_size);
  const headerNavbar = useSelector(SettingSelector.header_navbar);
  useEffect(() => {
    if (headerNavbar === "navs-sticky" || headerNavbar === "nav-glass") {
      window.onscroll = () => {
        if (document.documentElement.scrollTop > 50) {
          document.getElementsByTagName("nav")[0].classList.add("menu-sticky");
        } else {
          document
            .getElementsByTagName("nav")[0]
            .classList.remove("menu-sticky");
        }
      };
    }

    document.getElementsByTagName("html")[0].classList.add(themeFontSize);

    //offcanvase code
    const result = window.matchMedia("(max-width: 1200px)");
    window.addEventListener("resize", () => {
      if (result.matches === true) {
        if (show1 === true) {
          document.documentElement.style.setProperty("overflow", "hidden");
        } else {
          document.documentElement.style.removeProperty("overflow");
        }
      } else {
        document.documentElement.style.removeProperty("overflow");
      }
    });
    if (window.innerWidth <= "1200") {
      if (show1 === true) {
        document.documentElement.style.setProperty("overflow", "hidden");
      } else {
        document.documentElement.style.removeProperty("overflow");
      }
    } else {
      document.documentElement.style.removeProperty("overflow");
    }
  });

  const [show, setShow] = useState(true);

  const [show1, setShow1] = useState(false);

  //fullscreen
  const fullscreen = () => {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  const minisidebar = () => {
    document.getElementsByTagName("ASIDE")[0].classList.toggle("sidebar-mini");
  };

  const dispatch = useDispatch();
  const { redirectTo } = useRedirect();

  const logoutHandler = async () => {
    let response = await postService(LOGOUT_POST, "");
    // console.log('logout data', response);
    if (response.isError) {
      toast.error(response?.error);
    } else {
      // console.log('reset pass data', response.data);
    }
    updateStorage(storages.local, "access_token", "");
    updateStorage(storages.local, "authState", "");
    dispatch(
      logoutSuccess({
        user: {},
        userRoles: [],
        token: "",
        isAuthenticated: false,
      })
    );
    redirectTo("/Login");
  };

  const redirectToProfile = () => {
    // alert("Hi");
    redirectTo("/user-profile");
  };

  return (
    <Navbar
      expand="xl"
      className={`nav iq-navbar header-hover-menu left-border ${headerNavbar} ${navbarHide.join(
        " "
      )}`}
    >
      <Container fluid className="navbar-inner">
        <Link to="/dashboard" className="navbar-brand">
          <Logo color={true} />
          {/* <h4 className="logo-title  ms-3 mb-0">{app_config.appName}</h4> */}
          <h4 className="logo-title">
            <svg viewBox="0 0 306.46 55">
              <path
                fill="#1b437a"
                stroke="0"
                d="m33.08,4.41V.61H0v54h33.08v-4.66H5.62V4.41h27.46ZM13.16,44.08v-11.85h17.61v-4.33H7.06v20.63h26.02v-4.44H13.16ZM33.08,11.15v-5.3H7.06v20.62h23.71v-4.39H13.16v-10.92h19.92Z"
              />
              <path
                fill="#1b437a"
                stroke="0"
                d="m52.13,7.72h-3.78v46.9h6.42V21.3l22,33.31h4.89L52.13,7.72ZM85.08.61v54h4.85V.61h-4.85Zm-8.31,0v33.47L54.77.61h-13.16v54h5.3V6.29h6l30.43,48.32h.3V.61h-6.88Z"
              />
              <path
                fill="#1b437a"
                stroke="0"
                d="m122.16,29.72v4.12h16.69c-1.03,3.08-2.66,5.44-4.89,7.08-2.23,1.64-4.96,2.46-8.19,2.46-4.72,0-8.42-1.4-11.12-4.2-2.69-2.79-4.04-6.68-4.04-11.65,0-4.67,1.28-8.4,3.85-11.19,2.56-2.79,5.97-4.2,10.23-4.2,2.46,0,4.6.47,6.42,1.43,1.82.95,3.21,2.32,4.2,4.11h6.74c-.25-.35-.5-.69-.75-1.03-5.48-7.16-13.77-10.63-21.12-8.84-1.1.26-2.21.65-3.29,1.13-.65.3-1.29.62-1.89.96-7.16,4.1-10.91,12.41-9.56,21.16,1.46,9.44,9.22,18.59,19.7,17.57,6.4-.62,14.78-3.21,18.53-11.99v-6.94h-21.51Zm0-5.57v4.13h22.94v8.66l-.05.14c-3.16,7.55-9.99,12.04-19.76,12.99-.59.06-1.17.08-1.74.09-10.43,0-18.03-9.3-19.51-18.87-1.44-9.34,2.59-18.22,10.25-22.62.64-.36,1.32-.71,2.01-1.02,1.17-.52,2.36-.94,3.54-1.23,7.89-1.92,16.76,1.76,22.59,9.37.4.52.79,1.05,1.16,1.58l.29.32h5.96c-1.44-5.54-4.37-9.87-8.81-13-4.43-3.12-9.88-4.69-16.35-4.69-5.34,0-10.08,1.17-14.23,3.49-4.15,2.34-7.4,5.59-9.73,9.77-2.34,4.18-3.5,8.93-3.5,14.27s1.17,10.08,3.5,14.23c2.33,4.15,5.58,7.39,9.76,9.73,4.18,2.33,8.93,3.5,14.27,3.5,4.51,0,8.56-.87,12.16-2.61,3.59-1.74,6.55-4.03,8.88-6.85,2.33-2.82,3.98-5.87,4.96-9.15v-12.23h-28.61Z"
              />
              <path
                fill="#1b437a"
                stroke="0"
                d="m183.73,6.68h-4.41l-11.3,31.73h27.11l-11.4-31.73Zm-2.12,8.48l6.69,19.77h-13.31l6.62-19.77Zm14.03,24.68h-28.13l-5.27,14.78h6.07l3.23-9.53h20.15l3.23,9.53h6.03l-5.31-14.78ZM189.31.61h-15.23l-19.53,54h6.18l17.6-49.37h6.42l17.74,49.37h6.37L189.31.61Z"
              />
              <path
                fill="#1b437a"
                stroke="0"
                d="m237.46,29.72v4.12h16.69c-1.02,3.08-2.66,5.44-4.88,7.08-2.23,1.64-4.97,2.46-8.2,2.46-4.71,0-8.42-1.4-11.11-4.2-2.7-2.79-4.04-6.68-4.04-11.65,0-4.67,1.29-8.4,3.85-11.19,2.56-2.79,5.97-4.2,10.23-4.2,2.46,0,4.6.47,6.42,1.43,1.83.95,3.22,2.32,4.2,4.11h6.63c-.25-.35-.5-.69-.76-1.03-5.48-7.16-13.77-10.63-21.12-8.84-1.1.26-2.21.65-3.29,1.13-.65.3-1.29.62-1.89.96-7.16,4.1-10.91,12.41-9.55,21.16,1.46,9.44,9.2,18.6,19.69,17.57,6.4-.62,14.78-3.21,18.53-11.99v-6.94h-21.4Zm0-5.57v4.13h22.83v8.66l-.05.14c-3.16,7.55-9.99,12.04-19.76,12.99-.59.06-1.17.08-1.73.09-10.44,0-18.04-9.3-19.52-18.87-1.44-9.34,2.59-18.22,10.25-22.62.64-.36,1.32-.71,2.01-1.02,1.17-.52,2.36-.94,3.54-1.23,7.89-1.92,16.76,1.76,22.6,9.37.4.52.78,1.05,1.15,1.58l.29.32h6.08c-1.43-5.54-4.37-9.87-8.81-13-4.43-3.12-9.88-4.69-16.35-4.69-5.33,0-10.08,1.17-14.23,3.49-4.15,2.34-7.39,5.59-9.73,9.77s-3.5,8.93-3.5,14.27,1.17,10.08,3.5,14.23c2.34,4.15,5.59,7.39,9.77,9.73,4.18,2.33,8.93,3.5,14.26,3.5,4.52,0,8.56-.87,12.16-2.61,3.59-1.74,6.55-4.03,8.88-6.85,2.34-2.82,3.98-5.87,4.96-9.15v-12.23h-28.61Z"
              />
              <path
                fill="#1b437a"
                stroke="0"
                d="m273.38.61v54h33.08v-4.66h-26.82V4.41h26.82V.61h-33.08Zm13.16,31.62h17.61v-4.33h-23.08v20.63h25.39v-4.44h-19.92v-11.85Zm-5.47-5.76h23.08v-4.39h-17.61v-10.92h19.92v-5.3h-25.39v20.62Z"
              />
            </svg>
          </h4>
        </Link>
        <div
          className="sidebar-toggle"
          data-toggle="sidebar"
          data-active="true"
          onClick={minisidebar}
        >
          <i className="icon d-flex">
            <svg width="20px" viewBox="0 0 24 24" className="icon-20">
              <path
                fill="currentColor"
                d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z"
              />
            </svg>
          </i>
        </div>
        <div className="d-flex align-items-center">
          <Button
            id="navbar-toggle"
            bsPrefix="navbar-toggler"
            type="button"
            aria-expanded={show1}
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            onClick={() => setShow1(!show1)}
          >
            <span className="navbar-toggler-icon">
              <span className="mt-1 navbar-toggler-bar bar1"></span>
              <span className="navbar-toggler-bar bar2"></span>
              <span className="navbar-toggler-bar bar3"></span>
            </span>
          </Button>
        </div>
        <div
          className={` navbar-collapse ${
            show1 === true ? "collapse show" : "collapse"
          }`}
          id="navbarSupportedContent"
        >
          <ul className="mb-2 navbar-nav ms-auto align-items-center navbar-list mb-lg-0">
            <Dropdown
              as="li"
              className="nav-item border-end pe-3 d-none d-xl-block"
            ></Dropdown>
            <li className="nav-item dropdown me-0 me-xl-3">
              <div
                className="d-flex align-items-center mr-2 iq-font-style"
                role="group"
                aria-label="First group"
              >
                <RadioBtn
                  btnName="theme_font_size"
                  labelclassName="  border-0 btn-icon btn-sm"
                  id="font-size-sm"
                  defaultChecked={themeFontSize}
                  value="theme-fs-sm"
                >
                  <span className="mb-0 h6" style={{ color: "inherit" }}>
                    A
                  </span>
                </RadioBtn>
                <RadioBtn
                  btnName="theme_font_size"
                  labelclassName="  border-0 btn-icon"
                  id="theme-fs-md"
                  defaultChecked={themeFontSize}
                  value="theme-fs-md"
                >
                  <span className="mb-0 h4" style={{ color: "inherit" }}>
                    A
                  </span>
                </RadioBtn>
                <RadioBtn
                  btnName="theme_font_size"
                  labelclassName="  border-0 btn-icon"
                  id="theme-fs-lg"
                  defaultChecked={themeFontSize}
                  value="theme-fs-lg"
                >
                  <span className="mb-0 h2" style={{ color: "inherit" }}>
                    A
                  </span>
                </RadioBtn>
              </div>
            </li>
            <Dropdown
              as="li"
              className="nav-item border-end pe-3 d-none d-xl-block"
            >
              <div className="form-group input-group mb-0 search-input">
                <Form.Control type="text" placeholder="Search..." />
                <span className="input-group-text">
                  <svg
                    width="20"
                    className="icon-20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="11.7669"
                      cy="11.7666"
                      r="8.98856"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinejoin="round"
                    ></circle>
                    <path
                      d="M18.0186 18.4851L21.5426 22"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </span>
              </div>
            </Dropdown>
            <Dropdown
              as="li"
              className="nav-item  iq-responsive-menu border-end d-block d-xl-none"
            >
              <Dropdown.Toggle as={CustomToggle} className="btn btn-sm bg-body">
                <svg
                  className="icon-20"
                  width="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="11.7669"
                    cy="11.7666"
                    r="8.98856"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></circle>
                  <path
                    d="M18.0186 18.4851L21.5426 22"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </Dropdown.Toggle>
              <Dropdown.Menu
                variant="end"
                aria-labelledby="navbarDropdown-search-11"
                style={{ width: "25rem" }}
              >
                <div className="px-3 py-0">
                  <div className="form-group input-group mb-0">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                    />
                    <span className="input-group-text">
                      <svg
                        className="icon-20"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="11.7669"
                          cy="11.7666"
                          r="8.98856"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></circle>
                        <path
                          d="M18.0186 18.4851L21.5426 22"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </span>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
            <Nav.Item
              className=" iq-full-screen d-none d-xl-block"
              onClick={() => setShow(!show)}
            >
              <Nav.Link id="btnFullscreen" onClick={fullscreen}>
                <div className="btn btn-primary btn-icon btn-sm rounded-pill">
                  <span className="btn-inner">
                    <svg
                      className={`normal-screen ${
                        show === true ? "" : "d-none"
                      } icon-24`}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.5528 5.99656L13.8595 10.8961"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M14.8016 5.97618L18.5524 5.99629L18.5176 9.96906"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M5.8574 18.896L10.5507 13.9964"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M9.60852 18.9164L5.85775 18.8963L5.89258 14.9235"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                    <svg
                      className={`full-normal-screen ${
                        show === false ? "" : "d-none"
                      } icon-24`}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.7542 10.1932L18.1867 5.79319"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M17.2976 10.212L13.7547 10.1934L13.7871 6.62518"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M10.4224 13.5726L5.82149 18.1398"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M6.74391 13.5535L10.4209 13.5723L10.3867 17.2755"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </span>
                </div>
              </Nav.Link>
            </Nav.Item>
            {/* NOTIFICATION */}
            <Button
              className="btn-icon btn-sm rounded-pill btn-action"
              onClick={() => redirectTo("notification/list")}
            >
              <span className="btn-inner">
                {findIcon("Bell", "dual-tone", "20")}
              </span>
              <span className="notification-alert"></span>
            </Button>
            <Dropdown as="li" className="nav-item iq-tour ps-3 ps-lg-0">
              <Dropdown.Toggle
                as={CustomToggle}
                variant="py-0  d-flex align-items-center nav-link"
              >
                <div className="btn btn-primary btn-icon btn-sm rounded-pill">
                  <span className="btn-inner">
                    <svg
                      width="32"
                      className="icon-32"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.997 15.1746C7.684 15.1746 4 15.8546 4 18.5746C4 21.2956 7.661 21.9996 11.997 21.9996C16.31 21.9996 19.994 21.3206 19.994 18.5996C19.994 15.8786 16.334 15.1746 11.997 15.1746Z"
                        fill="currentColor"
                      ></path>
                      <path
                        opacity="0.4"
                        d="M11.9971 12.5838C14.9351 12.5838 17.2891 10.2288 17.2891 7.29176C17.2891 4.35476 14.9351 1.99976 11.9971 1.99976C9.06008 1.99976 6.70508 4.35476 6.70508 7.29176C6.70508 10.2288 9.06008 12.5838 11.9971 12.5838Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </span>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu variant="end">
                <Dropdown.Item href="/profile">Profile</Dropdown.Item>
                <Dropdown.Item onClick={logoutHandler}>Logout</Dropdown.Item>
                {/* <Dropdown.Item href="/dashboard/app/user-privacy-setting">
                  Privacy Setting
                </Dropdown.Item>
                <hr className="dropdown-divider" />
                <Dropdown.Item
                  onClick={logoutHandler}
                  // href="/auth/sign-in"
                >
                  Logout
                </Dropdown.Item> */}
              </Dropdown.Menu>
            </Dropdown>
          </ul>
        </div>
      </Container>
    </Navbar>
  );
});

Headerpro.displayName = "Headerpro";
export default Headerpro;
