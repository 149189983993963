import React, { useRef, useState } from "react";
import { useDrop } from "react-dnd";
import { medicalreview } from "../../../views/modules/creative-library/components/data";

const KanbanReviewTag = ({ addReviewtoInput, children, reviewtitle }) => {
  const ref = useRef(null);
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: "Our First type",
    drop(item) {
      addReviewtoInput(item.id, reviewtitle);
    },
    canDrop: (item, monitor) => {
      const dropTragetIndex = medicalreview.findIndex(
        (re) => re.name === reviewtitle
      );
      // console.log(item, monitor, dropTragetIndex);
      return true;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  // const active = isOver && canDrop;
  // console.log(isOver, canDrop, active);

  const getBackgroundColor = () => {
    if (isOver) {
      if (canDrop) {
        return "rgb(188,251,255)";
      } else if (!canDrop) {
        return "rgb(255,188,188)";
      }
    } else {
      return "";
    }
  };
  return (
    <>
      <div
        ref={drop}
        style={{
          backgroundColor: getBackgroundColor(),
          borderRadius: "1.5rem",
        }}
      >
        {" "}
        {children}
      </div>
    </>
  );
};

export default KanbanReviewTag;
