import cmsAxiosInstance from "../instances/cms-api-instance";

const cmsPostService = async (endpoint, data) => {
  // console.log("POST_SERVICE", endpoint, data);
  try {
    let response = await cmsAxiosInstance({
      method: "POST",
      url: endpoint,
      data,
    });
    if (response) {
      // console.log("POST_SERVICE_RESPONSE", response);
      const responseData = response.data ?? [];
      switch (response.status) {
        case 200:
          return { data: responseData, isError: false, error: "" };
        case 201:
          return { data: responseData, isError: false, error: "" };
        case 203:
          return { data: responseData, isError: false, error: "" };
        case 204:
          return { data: responseData, isError: false, error: "" };

        default:
          return {
            data: null,
            isError: true,
            error: "Something went wrong. Please try again",
          };
      }
    }
  } catch (error) {
    switch (error.response.status) {
      case 400:
        return {
          data: null,
          isError: true,
          error: "Something went wrong. Please try again",
        };
      case 401:
        return {
          data: null,
          isError: true,
          error: "Please check your credentials and try again",
        };
      case 403:
        return {
          data: null,
          isError: true,
          error: "Something went wrong. Please try again",
        };
      case 404:
        return {
          data: null,
          isError: true,
          error: "Something went wrong. Please try again",
        };
      case 500:
        return {
          data: null,
          isError: true,
          error: "Something went wrong. Please try again",
        };

      default:
        return {
          data: null,
          isError: true,
          error: "Something went wrong. Please try again",
        };
    }
  }
};
export default cmsPostService;
