import React from "react";

// layout

import Default from "../../../../../layouts/dashboard/default";
import AllContentCategory from "../pages/all-content-category";
// pages

export const ContentCategoryRouter = [
  {
    path: "/master-management/content-category",
    element: <Default />,
    children: [
      {
        path: "all",
        element: <AllContentCategory />,
      },
    ],
  },
];
