import Skeleton from "../../components/skeleton";
import { Fragment } from "react";

//Componets

import { OverlayTrigger, Card, Tooltip, Row, Col } from "react-bootstrap";

const LibraryLoader = () => {
  let tableData = [...Array(8).keys()].map((_el) => _el + 1);
  return (
    <Fragment>
      {tableData?.map((_td, indx) => {
        return (
          <>
            <Col>
              <Card className="card-folder">
                <Card.Body>
                  <div className="d-flex justify-content-between">
                    {/* <span className="avatar-40 bg-light rounded-circle d-flex justify-content-center align-items-center"> */}
                    <Skeleton variant="circular" width={30} height={30} />
                    {/* </span> */}
                    <div className="d-flex align-items-center gap-1 flex-column">
                      <Skeleton variant="circular" width={7} height={7} />
                      <Skeleton variant="circular" width={7} height={7} />
                      <Skeleton variant="circular" width={7} height={7} />
                    </div>
                  </div>
                  <div className="mt-4">
                    <Skeleton variant="text" width="35%" height={20} />
                    <div className="d-flex align-items-center gap-2">
                      <Skeleton variant="text" width="8%" height={20} />
                      <Skeleton variant="text" width="20%" height={20} />
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </>
        );
      })}
    </Fragment>
  );
};

export default LibraryLoader;
