import { memo, Fragment, useState } from "react";

//react-bootstrap
import { Row, Col, Image, Form, Button, FormCheck } from "react-bootstrap";

//components
import Card from "../../../components/bootstrap/card";

//router
import { Link } from "react-router-dom";

// img
import avatars1 from "../../../assets/images/avatars/01.png";
import avatars2 from "../../../assets/images/avatars/avtar_1.png";
import avatars3 from "../../../assets/images/avatars/avtar_2.png";
import avatars4 from "../../../assets/images/avatars/avtar_3.png";
import avatars5 from "../../../assets/images/avatars/avtar_4.png";
import avatars6 from "../../../assets/images/avatars/avtar_5.png";
import {
  emailValidator,
  multiSelectOptHandler,
  nameValidator,
  phoneNumberValidator,
} from "../../../common/utils/utils";
// import Select from "react-select/dist/declarations/src/Select";
import Select from "react-select";
const UserAdd = memo(() => {
  const [employeeDetails, setEmployeeDetails] = useState({
    name: "",
    phNo: "",
    email: "",
    empCode: "",
    department: "",
    reportingMannager: "",
    principalEntiry: [],
    role: "",
  });
  const department_array = ["Marketing", "Medical", "Compliance"];
  const reporting_manager_array = [
    "Arnab Saha",
    "Ashu Kasera",
    "Sourav Kasera",
  ];
  const principalEntiryOptions = [
    { value: "Web Designer", label: "CLIRNET" },
    { value: "Web Developer", label: "CLIRNET -> Respiratory" },
    { value: "Tester", label: "CLIRNET -> Respiratory -> budecort" },
    { value: "Php Developer", label: "CLIRNET -> Montair FX" },
    { value: "Ios Developer", label: "CLIRNET -> Diabetes" },
    { value: "Ios Developer2", label: "CLIRNET -> Diabetes -> Glygard" },
  ];
  // FUNCTIONS
  const inputValueHandler = (e) => {
    setEmployeeDetails((prev) => {
      let { name, value } = e.target;
      return { ...prev, [name]: value };
    });
  };
  const selectInputHandler = (e) => {
    setEmployeeDetails({
      ...employeeDetails,
      principalEntiry: multiSelectOptHandler(e),
    });
  };

  const givenInputValidator = () => {
    console.log(
      "check name",
      nameValidator(employeeDetails.name),
      phoneNumberValidator(employeeDetails.phNo),
      emailValidator(employeeDetails.email),
      employeeDetails.principalEntiry
    );
  };

  return (
    <>
      <Fragment>
        <Row>
          <Col xl="12" lg="12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">Add New User</h4>
                </div>
              </Card.Header>
              <Card.Body>
                <div className="new-user-info">
                  <Form>
                    <div className="row shadow m-0 py-3 px-0 mb-5">
                      <h6 className="mb-3 fs-6 fw-400 gray-300">
                        Employee Details
                      </h6>
                      <Form.Floating className="col-md-6 custom-form-floating form-group mb-4">
                        <Form.Control
                          name="name"
                          type="name"
                          className=""
                          autoComplete="username name"
                          placeholder="Name"
                          onChange={(e) => inputValueHandler(e)}
                        />
                        <label htmlFor="floatingInput">Name</label>
                      </Form.Floating>
                      <Form.Floating className="col-md-6 custom-form-floating form-group mb-4">
                        <Form.Control
                          name="phNo"
                          type="text"
                          className=""
                          autoComplete="username number"
                          placeholder="Mobile Number"
                          onChange={(e) => inputValueHandler(e)}
                        />
                        <label htmlFor="floatingInput">Mobile Number</label>
                      </Form.Floating>
                      <Form.Floating className="col-md-6 custom-form-floating form-group mb-0">
                        <Form.Control
                          name="email"
                          type="email"
                          className=""
                          autoComplete="username email"
                          placeholder="Email"
                          onChange={(e) => inputValueHandler(e)}
                        />
                        <label htmlFor="floatingInput">Email</label>
                      </Form.Floating>
                      <Form.Floating className="col-md-6 custom-form-floating form-group mb-0">
                        <Form.Control
                          name="empCode"
                          type="text"
                          className=""
                          autoComplete="username code"
                          placeholder="Employee Code"
                          onChange={(e) => inputValueHandler(e)}
                        />
                        <label htmlFor="floatingInput">Employee Code</label>
                      </Form.Floating>
                    </div>
                    {/* <hr /> */}
                    <div className="row shadow m-0 py-3 px-0 mb-5">
                      <h6 className="mb-3 fs-6 fw-400 gray-300">
                        Department & Reporting
                      </h6>
                      <Form.Group className="col-md-6 form-group custom-form-select">
                        {/* <Form.Label className="fs-6">Department:</Form.Label> */}
                        <select
                          name="department"
                          className="selectpicker form-control"
                          data-style="py-0"
                          onChange={(e) => inputValueHandler(e)}
                        >
                          <option>Select Department</option>
                          {department_array.map((_op) => (
                            <option>{_op}</option>
                          ))}
                        </select>
                      </Form.Group>
                      <Form.Group className="col-md-6 form-group">
                        {/* <Form.Label>Reporting Manager:</Form.Label> */}
                        <select
                          name="reportingMannager"
                          className="selectpicker form-control"
                          data-style="py-0"
                          onChange={(e) => inputValueHandler(e)}
                        >
                          <option>Select Reporting Manager:</option>
                          {reporting_manager_array.map((_op) => (
                            <option>{_op}</option>
                          ))}
                        </select>
                      </Form.Group>
                    </div>
                    {/* <hr /> */}
                    {/* <h5 className="mb-3">Security</h5> */}
                    <div className="row shadow m-0 py-3 px-0 mb-5">
                      <h6 className="mb-3 fs-6 fw-400 gray-300">
                        Principle Entity
                      </h6>
                      <Form.Group className="col-md-6 form-group">
                        <Select
                          name="principalEntiry"
                          options={principalEntiryOptions}
                          placeholder="Select Principle Entity"
                          // className='selectpicker form-control'
                          // data-style='py-0'
                          isMulti
                          onChange={(e) => selectInputHandler(e)}
                        />
                      </Form.Group>
                      <div className="col-md-6">
                        {/* <Button
                          type='button'
                          variant='btn btn-secondary'
                          onClick={principalEntiryHandler}>
                          Add More
                        </Button> */}
                      </div>
                    </div>
                    <div className="row shadow m-0 py-3 px-0 mb-5">
                      <h6 className="mb-3 fs-6 fw-400 gray-300">
                        Add User Role
                      </h6>
                      <Form.Group className="col-md-12 form-group">
                        <Form.Check className="form-check-inline d-flex align-items-center">
                          <FormCheck.Input
                            name="role"
                            value="1st checkbox"
                            type="checkbox"
                            className="form-check-input"
                            id="customCheck5"
                            onChange={(e) => inputValueHandler(e)}
                          />
                          <FormCheck.Label
                            className="form-check-label pl-2 ms-3"
                            htmlFor="customCheck5"
                          >
                            Content Creator
                          </FormCheck.Label>
                          <span className="ms-4 fs-7 p-2 border border-1 rounded-3">
                            Will be able to add Content to Unity
                          </span>
                        </Form.Check>
                      </Form.Group>
                      <Form.Group className="col-md-12 form-group">
                        <Form.Check className="form-check-inline d-flex align-items-center">
                          <FormCheck.Input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheck5"
                          />
                          <FormCheck.Label
                            className="form-check-label pl-2 ms-3"
                            htmlFor="customCheck5"
                          >
                            Content Approver
                          </FormCheck.Label>
                          <span className="ms-4 fs-7 p-2 border border-1 rounded-3">
                            Will be able to Approve created Content
                          </span>
                        </Form.Check>
                      </Form.Group>
                      <Form.Group className="col-md-12 form-group">
                        <Form.Check className="form-check-inline d-flex align-items-center">
                          <FormCheck.Input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheck5"
                          />
                          <FormCheck.Label
                            className="form-check-label pl-2 ms-3"
                            htmlFor="customCheck5"
                          >
                            Content Requisitor
                          </FormCheck.Label>
                          <span className="ms-4 fs-7 p-2 border border-1 rounded-3">
                            Will be able to requisition for a Content
                          </span>
                        </Form.Check>
                      </Form.Group>
                      <Form.Group className="col-md-12 form-group">
                        <Form.Check className="form-check-inline d-flex align-items-center">
                          <FormCheck.Input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheck5"
                          />
                          <FormCheck.Label
                            className="form-check-label pl-2 ms-3"
                            htmlFor="customCheck5"
                          >
                            Content Searcher
                          </FormCheck.Label>
                          <span className="ms-4 fs-7 p-2 border border-1 rounded-3">
                            Will be able to search for all Content in the system
                          </span>
                        </Form.Check>
                      </Form.Group>
                      <Form.Group className="col-md-12 form-group">
                        <Form.Check className="form-check-inline d-flex align-items-center">
                          <FormCheck.Input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheck5"
                          />
                          <FormCheck.Label
                            className="form-check-label pl-2 ms-3"
                            htmlFor="customCheck5"
                          >
                            Content Analytics-
                          </FormCheck.Label>
                          <span className="ms-4 fs-7 p-2 border border-1 rounded-3">
                            Will be able to see Analytics of Content Usage
                          </span>
                        </Form.Check>
                      </Form.Group>
                    </div>
                    <Button
                      type="button"
                      variant="btn btn-primary"
                      onClick={givenInputValidator}
                    >
                      Add New User
                    </Button>
                  </Form>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Fragment>
    </>
  );
});

UserAdd.displayName = "UserAdd";
export default UserAdd;
