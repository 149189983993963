import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import ReactEmailEditor from "../../../../lib/email-editor/react-email-editor";
import sample from "../../../../lib/email-editor/sample.json";
import EmailGenaratorLoader from "../../../../skeleton/content-library/email-genarator-loader";

const AiEmailGenerator = () => {
  const [genarate, setGenerate] = useState(false);
  const [loading, setLoading] = useState(true);
  const categoryListDemo = [
    {
      category_id: 29,
      category_name: "System Generated Dynamic",
    },
    {
      category_id: 31,
      category_name: "Forgot Password",
    },
    {
      category_id: 32,
      category_name: "CLIRNET CRM",
    },
    {
      category_id: 33,
      category_name: "Hypertension",
    },
    {
      category_id: 34,
      category_name: "Session invitation",
    },
    {
      category_id: 35,
      category_name: "Fatty Liver",
    },
    {
      category_id: 36,
      category_name: "Live CME",
    },
    {
      category_id: 37,
      category_name: "Clinical Videos",
    },
    {
      category_id: 38,
      category_name: "Medwiki",
    },
    {
      category_id: 39,
      category_name: "Personalize Engagement - Reply",
    },
    {
      category_id: 40,
      category_name: "Personalize Engagement - Poke",
    },
    {
      category_id: 41,
      category_name: "Push Notifications",
    },
    {
      category_id: 42,
      category_name: "Category listing verificaton",
    },
  ];
  const emailEditorRef = useRef(null);

  const onLoad = () => {
    emailEditorRef.current?.editor?.loadDesign(sample);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const onReady = () => {
    emailEditorRef.current?.editor?.showPreview("desktop");
  };
  const showPreview = () => setGenerate(true);
  return (
    <>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <div className="d-flex flex-column">
          <h3 className="m-0">Ai Email Generator</h3>
        </div>
      </div>
      {!loading ? (
        <Card>
          <Card.Body>
            <Row>
              <Col md={6} className="mb-4">
                <Form.Floating className="custom-form-floating">
                  <Form.Select
                    id="category_id"
                    name="category_id"
                    // value={values?.category_id}
                    // isInvalid={touched.category_id && !!errors.category_id}
                    // onChange={handleChange}
                  >
                    <option value="">Select Category</option>
                    {categoryListDemo.map((_i, index) => (
                      <option value={_i.category_id} key={index + 1}>
                        {_i.category_name}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Label htmlFor="category_id">Category</Form.Label>
                  <Form.Control.Feedback type="invalid">
                    {/* {errors.category_id} */}
                  </Form.Control.Feedback>
                </Form.Floating>
              </Col>

              <Col md={6} className="mb-4">
                <Form.Floating className="custom-form-floating">
                  <Form.Control
                    type="text"
                    className=""
                    id="email_subject"
                    autoComplete="Subject"
                    placeholder="Subject"
                    name="email_subject"
                    // value={values.email_subject}
                    // isInvalid={touched.email_subject && !!errors.email_subject}
                    // onChange={handleChange}
                  />
                  <Form.Label htmlFor="email_subject">Subject</Form.Label>
                  {/* <Form.Control.Feedback type="invalid">
                    {errors.email_subject}
                  </Form.Control.Feedback> */}
                </Form.Floating>
              </Col>
              <Col md={6} className="mb-4">
                <Form.Floating className="custom-form-floating">
                  <Form.Control
                    type="text"
                    id="to_email"
                    autoComplete="To Email"
                    placeholder="To Email"
                    name="to_email"
                    // onChange={handleChange}
                    // value={values.from_email}
                    // isInvalid={touched.from_email && !!errors.from_email}
                  />
                  <Form.Label htmlFor="to_email">To Email</Form.Label>
                  {/* <Form.Control.Feedback type="invalid">
                    {errors.from_email}
                  </Form.Control.Feedback> */}
                </Form.Floating>
              </Col>
              <Col md={6} className="mb-4">
                <Form.Floating className="custom-form-floating">
                  <Form.Control
                    type="text"
                    className=""
                    id="from_name"
                    autoComplete="From Name"
                    placeholder="From Name"
                    name="from_name"
                    // value={values.from_name}
                    // isInvalid={touched.from_name && !!errors.from_name}
                    // onChange={handleChange}
                  />
                  <Form.Label htmlFor="from_name">From Name</Form.Label>
                  {/* <Form.Control.Feedback type="invalid">
                    {errors.from_name}
                  </Form.Control.Feedback> */}
                </Form.Floating>
              </Col>
              <Col md={12} className="mb-4">
                <Form.Floating className="custom-form-floating h-100">
                  <textarea
                    class="form-control h-100"
                    placeholder="Please enter your prompt...."
                    id="floatingTextarea2"
                  ></textarea>
                  <Form.Label htmlFor="fname">Prompt</Form.Label>
                </Form.Floating>
              </Col>
              <div className="d-flex justify-content-end">
                <Button variant="primary" onClick={showPreview}>
                  Generate
                </Button>
              </div>
            </Row>
            {genarate && (
              <Row>
                <h5 className="my-3">Content</h5>
                <div className="boredr-2 border border-dashed p-4 rounded-3">
                  <ReactEmailEditor
                    ref={emailEditorRef}
                    onReady={onReady}
                    onLoad={onLoad}
                  />
                </div>
              </Row>
            )}
          </Card.Body>
        </Card>
      ) : (
        <EmailGenaratorLoader />
      )}
    </>
  );
};

export default AiEmailGenerator;
