import React from 'react'


// pages
import FilemanagerLayout from '../layouts/creative-library'
import AllCreatives from '../pages/creative-approval-listing'
import CreativeDetails from '../pages/creative-details'
import CreativeApprove from '../pages/creative-approve'

export const CreativeApprovalRouter = [
  {
    path: "creative-approval",
    element: <FilemanagerLayout />,
    children: [
      {
        path: "listing",
        element: <AllCreatives />,
      },
      {
        path: "approve",
        element: <CreativeApprove />,
      },
      {
        path: "details/:id",
        element: <CreativeDetails />,
      },
    ],
  },
];