import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useCallback,
} from "react";
import { Button, Card, Col, Form, Row, Modal, Badge } from "react-bootstrap";
import {
  GET_ALL_MENU_ITEMS,
  UPDATE_MENU_POST,
} from "../../../../../api/endpoints/menu-endpoints";
import postService from "../../../../../api/services/post-service";
import ViewModal from "../../../../../components/partials/common/view-modal";
import { findIcon } from "../../../../dashboard/icons";
import ReactTable from "../../../../../lib/table/react-table";
import {
  changeTableStateToPayload,
  statusToBg,
} from "../../../../../common/utils/utils";
import * as yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import PageNotFound from "../../../../PageNotFound";
import CommonTableLoader from "../../../../../skeleton/common-table-loader";

const AllMenus = () => {
  const [menuList, setMenuList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [createModalShow, setCreateModalShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [viewModalShow, setViewModalShow] = useState(false);
  const [viewMenu, onEditMenuClick] = useState({});
  const [selectedMenu, setSelectedMenu] = useState(null);
  const post_json = useRef({
    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      menu_id: "desc",
    },
  });

  const columns = [
    {
      Header: "Menu ID",
      accessor: "menu_id",
    },
    {
      Header: "Menu Name",
      accessor: "menu_name",
      Cell: ({ row }) => {
        return row.values.menu_name ? row.values.menu_name : "";
      },
    },
    {
      Header: "Menu Description",
      accessor: "menu_description",
      Cell: ({ row }) => {
        return row.values.menu_description ? row.values.menu_description : "";
      },
    },
    // {
    //   Header: "Menu Order",
    //   accessor: "menu_order",
    //   Cell: ({ row }) => {
    //     return row.values.menu_order ? row.values.menu_order : "";
    //   }
    // },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => (
        // <span className="d-flex align-items-start justify-content-start">
        //   {row.values.status?.value}
        // </span>
        <Badge bg={statusToBg(value.id)} className="me-2">
          <span className="btn-inner">{value.value}</span>
        </Badge>
      ),
    },
    {
      Header: "Action",
      accessor: "action",
      disableFilters: true,
      Cell: ({ row }) => (
        <div className="d-flex justify-content-center align-items-center gap-2">
          <Button
            className="btn btn-icon btn-sm rounded-pill"
            to="#"
            role="button"
            onClick={() => viewModal(row.original)}
          >
            <span className="btn-inner">
              {findIcon("Eye", "dual-tone", 32)}
            </span>
          </Button>

          <Button
            className="btn-icon btn-sm rounded-pill"
            variant="danger"
            to="#"
            role="button"
            onClick={() => updateMenu(row.original)}
          >
            <span className="btn-inner">{findIcon("Pencil", "dual-tone")}</span>
          </Button>
        </div>
      ),
    },
  ];

  //fetch menu items
  const fetchMenuItems = async () => {
    try {
      // setLoading(true);
      const response = await postService(GET_ALL_MENU_ITEMS, post_json.current);
      if (response) {
        console.log("MENU_SERVICE_RESPONSE", response.data.data.rows);
        // console.log("COLUMNS", Object.keys(response.data.data.rows[0]));
        if (response.data.data.rows.length > 0) {
          setMenuList(response.data.data.rows);
          setTotalCount(response.data.data.totalCount);
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
      console.log("MENU_SERVICE_ERROR", error);
    }
  };
  useEffect(() => {
    fetchMenuItems();
  }, []);

  const updateMenu = (data) => {
    setIsEdit(true);
    setSelectedMenu(data.menu_id);

    const editValues = {
      menu_name: data?.menu_name,
      menu_description: data?.menu_description,
      // menu_order: data.menu_order ? data.menu_order : 0,
      status: data?.status?.id,
    };
    //set formik values
    setValues(editValues);
    setCreateModalShow(true);
  };

  const onTableStateChange = useCallback(
    async (state) => {
      let payload = changeTableStateToPayload(state);
      post_json.current = payload;
      fetchMenuItems(payload);
    },
    [menuList, loading, post_json.current]
  );

  const viewModalClose = () => {
    setViewModalShow(false);
  };

  const viewModal = async (data) => {
    // console.log("Menu===>", data);
    let dataJson = {
      "Menu Id": data.menu_id,
      "Menu Name": data.menu_name,
      "Menu Description": data.menu_description,
      // "Menu Order": data.menu_order ? data.menu_order : "",
      Status: data.status?.value,
    };
    onEditMenuClick(dataJson);
    setViewModalShow(true);
  };

  const validationSchema = yup.object().shape({
    menu_name: yup.string().required("Menu Name is required"),
    menu_description: yup.string().required("Menu Description is required"),
    // menu_order: yup.number().required("Menu Order is required"),
    status: yup.number(),
  });
  //formik
  const formik = useFormik({
    initialValues: {
      menu_name: "",
      menu_description: "",
      // menu_order: 0,
      status: 1,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("values===>", values);
      try {
        // setLoading(true);
        const response = await postService(
          isEdit && UPDATE_MENU_POST + "/" + selectedMenu,
          values
        );
        if (response) {
          console.log("Menu response", response);
          setLoading(false);
          setCreateModalShow(false);
          fetchMenuItems(post_json.current);
          toast.success(response?.data?.message);
        }
      } catch (error) {
        setLoading(false);
        console.log("MENU_SERVICE_ERROR", error);
      }
    },
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
  } = formik;
  console.log("formic test", values, errors);

  const handleVariableStatus = (e) => {
    setValues({ ...values, status: parseInt(e.target.value) });
  };

  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">
              <h4 className="card-title">All Menu</h4>
            </Card.Header>
            <Card.Body className="px-3">
              {!loading ? (
                menuList.length > 0 ? (
                  <ReactTable
                    data={menuList}
                    columns={columns}
                    recordsTotal={totalCount}
                    onTableStateChange={onTableStateChange}
                    initialState={post_json.current}
                    isRowSelectionAvailable={false}
                    recordsPerPage={10}
                  />
                ) : (
                  <PageNotFound />
                )
              ) : (
                <CommonTableLoader />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ViewModal
        title={"View Priority Name Information"}
        show={viewModalShow}
        modalClose={viewModalClose}
        data={viewMenu}
      />

      <Modal
        show={createModalShow}
        size="lg"
        onHide={() => {
          setCreateModalShow(false);
          setIsEdit(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? "Edit" : "Add"} Menu Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* Menu Name */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                Menu Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Menu Name"
                name="menu_name"
                onChange={handleChange}
                value={values.menu_name}
                onBlur={handleBlur}
                isInvalid={touched.menu_name && errors.menu_name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.menu_name}
              </Form.Control.Feedback>
            </Form.Group>

            {/* Menu Description */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                Menu Description <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Menu Name"
                name="menu_description"
                onChange={handleChange}
                value={values.menu_description}
                onBlur={handleBlur}
                isInvalid={touched.menu_description && errors.menu_description}
              />
              <Form.Control.Feedback type="invalid">
                {errors.menu_description}
              </Form.Control.Feedback>
            </Form.Group>

            {/* Menu Order */}
            {/* <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Menu Order</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Menu Order"
                name="menu_description"
                onChange={handleChange}
                value={values.menu_order}
                onBlur={handleBlur}
                isInvalid={touched.menu_order && errors.menu_order}
              />
              <Form.Control.Feedback type="invalid">
                {errors.menu_order}
              </Form.Control.Feedback>
            </Form.Group> */}

            {/* Menu status */}
            {isEdit && (
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Menu Status</Form.Label>
                <Form.Select
                  type="type"
                  name="status"
                  value={values.status}
                  // onChange={handleVariableStatus}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="1">Enable</option>
                  <option value="2">Disable</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.status}
                </Form.Control.Feedback>
              </Form.Group>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setCreateModalShow(false);
              setIsEdit(false);
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default AllMenus;
