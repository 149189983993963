import React from "react";
import Default from "../../../../layouts/dashboard/default";
import AddRole from "../pages/add-role";
import AllRoles from "../pages/all-roles";


export const RoleManagementRouter = [
  // Animated
  {
    path: "roles",
    element: <Default />,
    children: [
      {
        path: "listing",
        element: <AllRoles />,
      },
      {
        path: "create",
        element: <AddRole />,
      },
      {
        path: "edit/:id",
        element: <AddRole />,
      },

    ],
  },
];
