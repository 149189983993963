import { Fragment, useState, useEffect, useRef } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { isMobileOnly } from "react-device-detect";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import FilterBar from "../../../../components/filter-bar";
import ReactTable from "../../../../lib/table/react-table";
import postService from "../../../../api/services/post-service";
import { HCP_LISTING } from "../../../../api/endpoints/hcp-endpoints";
import ActionButtonBar from "../../../../components/partials/common/action-button-bar";
import { changeTableStateToPayload } from "../../../../common/utils/utils";
import * as Yup from "yup";
import { useFormik } from "formik";
import dayjs from "dayjs";
import { toast } from "react-hot-toast";
import { ONBOARDING_CREATE_TASK } from "../../../../api/endpoints/onboarding-endpoints";
import { useFetchOnboardingCreation } from "../../../../queries/query-hooks/onboarding-hook";
import { redirect, useParams } from "react-router-dom";
import useBackdrop from "../../../../components/custom-hooks/use-backdrop";

import { hcpApprovedDataUser } from "../data/hcp_approved_data";
import { hcpAssignedDataUser } from "../data/hcp_assigned_data";
import { hcpPendingDataUser } from "../data/hcp_pending_data";
import { hcpOverdueDataUser } from "../data/hcp_overdue_data";
import { hcpReviewDataUser } from "../data/hcp_in_review_data";

const inputFilters = [
  {
    name: "name",
    type: "text",
    placeholder: "Please Enter Name",
    label: "Name",
  },
  {
    name: "age",
    type: "text",
    placeholder: "Enter age",
    label: "Age",
  },
  {
    name: "degree",
    type: "select",
    placeholder: "Degree",
    label: "Degree",
    options: [
      { value: "1", label: "Degree 1" },
      { value: "2", label: "Degree 2" },
      { value: "3", label: "Degree 3" },
    ],
  },
  {
    name: "consent_status",
    type: "select",
    placeholder: "consent status",
    label: "Consent Status",
    options: [
      { value: "1", label: "Email" },
      { value: "2", label: "SMS" },
      { value: "3", label: "Whatsapp" },
    ],
  },
  {
    name: "dnd",
    type: "select",
    placeholder: "DND",
    label: "DND",
    options: [
      { value: "1", label: "Yes" },
      { value: "2", label: "No" },
    ],
  },
  {
    name: "state",
    type: "select",
    placeholder: "State",
    label: "State",
    options: [
      { value: "1", label: "West bengal" },
      { value: "2", label: "Delhi" },
      { value: "3", label: "Maharashtra" },
      { value: "4", label: "Kerala" },
    ],
  },
];

const hcpDataUserEngaged = [
  {
    hcp_id: 198,
    hcp_utmsource: "UTM15",
    hcp_first_name: "Pravat ",
    hcp_middle_name: "",
    hcp_last_name: "Mishra",
    hcp_age: "53",
    hcp_primary_speciality_id: 8,
    hcp_state_id: 10,
    hcp_city_id: 7,
    updated_at: "10 Sep 2023 00:20:00",
    created_by: {
      id: 1,
      name: "Super Admin",
    },
    modified_by: {
      id: 1,
      name: "Super Admin",
    },
    status: {
      id: 2,
      value: null,
    },
    hcp_to_primary_speciality: {
      speciality_id: 8,
      speciality_name: "General",
      created_by: {
        id: 1,
        name: "Super Admin",
      },
    },
    hcp_to_persona: [
      {
        id: 10,
        hcp_id: 150,
        persona_id: 5,
        all_persona: {
          persona_id: 5,
          persona_name: "Persona One",
          persona_icon:
            "https://storage.googleapis.com/cipla-cms-dev/icons/persona/avtar_5.png",
        },
      },
    ],
    hcp_to_city: {
      city_id: 10,
      city_name: "Krishna",
    },
    hcp_to_state: {
      state_id: 10,
      state_name: "West Bengal",
    },
  },
  {
    hcp_id: 1612,
    hcp_utmsource: "UTM19",
    hcp_first_name: "Madhusudan ",
    hcp_middle_name: "",
    hcp_last_name: "Panigrahi",
    hcp_age: "57",
    hcp_primary_speciality_id: 8,
    hcp_state_id: 10,
    hcp_city_id: 7,
    updated_at: "12 Sep 2023 00:20:00",
    created_by: {
      id: 1,
      name: "Super Admin",
    },
    modified_by: {
      id: 1,
      name: "Super Admin",
    },
    status: {
      id: 2,
      value: null,
    },
    hcp_to_primary_speciality: {
      speciality_id: 8,
      speciality_name: "Anaesthesiology",
      created_by: {
        id: 1,
        name: "Super Admin",
      },
    },
    hcp_to_persona: [
      {
        id: 10,
        hcp_id: 10,
        persona_id: 5,
        all_persona: {
          persona_id: 5,
          persona_name: "Persona Five",
          persona_icon:
            "https://storage.googleapis.com/cipla-cms-dev/icons/persona/avtar_5.png",
        },
      },
    ],
    hcp_to_city: {
      city_id: 10,
      city_name: "Krishna",
    },
    hcp_to_state: {
      state_id: 10,
      state_name: "Odisha",
    },
  },
  {
    hcp_id: 10,
    hcp_utmsource: "UTM109",
    hcp_first_name: "Anand ",
    hcp_middle_name: "",
    hcp_last_name: "Agarwal",
    hcp_age: "59",
    hcp_primary_speciality_id: 8,
    hcp_state_id: 10,
    hcp_city_id: 7,
    updated_at: "12 Sep 2023 00:20:00",
    created_by: {
      id: 1,
      name: "Super Admin",
    },
    modified_by: {
      id: 1,
      name: "Super Admin",
    },
    status: {
      id: 2,
      value: null,
    },
    hcp_to_primary_speciality: {
      speciality_id: 8,
      speciality_name: "Cardiothoracic Anesthesia",
      created_by: {
        id: 1,
        name: "Super Admin",
      },
    },
    hcp_to_persona: [
      {
        id: 10,
        hcp_id: 14,
        persona_id: 5,
        all_persona: {
          persona_id: 5,
          persona_name: "Persona Six",
          persona_icon:
            "https://storage.googleapis.com/cipla-cms-dev/icons/persona/avtar_5.png",
        },
      },
    ],
    hcp_to_city: {
      city_id: 10,
      city_name: "Krishna",
    },
    hcp_to_state: {
      state_id: 10,
      state_name: "Delhi",
    },
  },
  {
    hcp_id: 190,
    hcp_utmsource: "UTM140",
    hcp_first_name: "Krishnaveni ",
    hcp_middle_name: "",
    hcp_last_name: "Gomati",
    hcp_age: "60",
    hcp_primary_speciality_id: 8,
    hcp_state_id: 10,
    hcp_city_id: 7,
    updated_at: "12 Sep 2023 10:20:00",
    created_by: {
      id: 1,
      name: "Super Admin",
    },
    modified_by: {
      id: 1,
      name: "Super Admin",
    },
    status: {
      id: 2,
      value: null,
    },
    hcp_to_primary_speciality: {
      speciality_id: 8,
      speciality_name: "Surgery",
      created_by: {
        id: 1,
        name: "Super Admin",
      },
    },
    hcp_to_persona: [
      {
        id: 310,
        hcp_id: 10,
        persona_id: 5,
        all_persona: {
          persona_id: 5,
          persona_name: "Persona Ten",
          persona_icon:
            "https://storage.googleapis.com/cipla-cms-dev/icons/persona/avtar_5.png",
        },
      },
    ],
    hcp_to_city: {
      city_id: 10,
      city_name: "Krishna",
    },
    hcp_to_state: {
      state_id: 10,
      state_name: "Jharkhand",
    },
  },
  {
    hcp_id: 810,
    hcp_utmsource: "UTM1077",
    hcp_first_name: "Pulak ",
    hcp_middle_name: "",
    hcp_last_name: "Deka",
    hcp_age: "48",
    hcp_primary_speciality_id: 8,
    hcp_state_id: 10,
    hcp_city_id: 7,
    updated_at: "13 Sep 2023 14:20:00",
    created_by: {
      id: 1,
      name: "Super Admin",
    },
    modified_by: {
      id: 1,
      name: "Super Admin",
    },
    status: {
      id: 2,
      value: null,
    },
    hcp_to_primary_speciality: {
      speciality_id: 8,
      speciality_name: "Radiology",
      created_by: {
        id: 1,
        name: "Super Admin",
      },
    },
    hcp_to_persona: [
      {
        id: 140,
        hcp_id: 10,
        persona_id: 5,
        all_persona: {
          persona_id: 5,
          persona_name: "Persona Three",
          persona_icon:
            "https://storage.googleapis.com/cipla-cms-dev/icons/persona/avtar_5.png",
        },
      },
    ],
    hcp_to_city: {
      city_id: 10,
      city_name: "Krishna",
    },
    hcp_to_state: {
      state_id: 10,
      state_name: "Bihar",
    },
  },
  {
    hcp_id: 142,
    hcp_utmsource: "UTM104",
    hcp_first_name: "Dipak ",
    hcp_middle_name: "Suresh",
    hcp_last_name: "Bathe",
    hcp_age: "58",
    hcp_primary_speciality_id: 8,
    hcp_state_id: 10,
    hcp_city_id: 7,
    updated_at: "20 Sep 2023 15:20:00",
    created_by: {
      id: 1,
      name: "Super Admin",
    },
    modified_by: {
      id: 1,
      name: "Super Admin",
    },
    status: {
      id: 2,
      value: null,
    },
    hcp_to_primary_speciality: {
      speciality_id: 8,
      speciality_name: "Gastroenterology",
      created_by: {
        id: 1,
        name: "Super Admin",
      },
    },
    hcp_to_persona: [
      {
        id: 10,
        hcp_id: 10,
        persona_id: 5,
        all_persona: {
          persona_id: 5,
          persona_name: "Persona Four",
          persona_icon:
            "https://storage.googleapis.com/cipla-cms-dev/icons/persona/avtar_5.png",
        },
      },
    ],
    hcp_to_city: {
      city_id: 10,
      city_name: "Krishna",
    },
    hcp_to_state: {
      state_id: 10,
      state_name: "West Bengal",
    },
  },
];

const HCPProfileListWithType = () => {
  const { data: onboardingCreationData } = useFetchOnboardingCreation();
  const [selectedList, setSelectedList] = useState([]);
  const { type: filterType } = useParams();
  const { redirectTo } = useRedirect();
  const [loading, setLoading] = useState(false);
  const [toOnboardModal, setToOnboardModal] = useState(false);
  const [hcpList, setHcpList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const post_json = useRef({
    default: "all",

    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      hcp_id: "desc",
    },
  });

  const fetchHcps = async () => {
    // fetch hcp list
    let response = await postService(HCP_LISTING, post_json.current);
    if (response.isError) {
      // show error message
      return;
    } else {
      let isSuccess = response.data.success;
      if (isSuccess) {
        // set data
        let data = response.data.data;
        setTotalCount(data?.totalCount);
        // setHcpList(data.rows?.length > 0 ? data.rows : []);
        // setHcpList()
        console.log("COLUMNS", Object.keys(data.rows[0]));
      } else {
        // show error message
      }
    }
  };
  const COLUMNS = [
    {
      Header: "HCP ID",
      accessor: "hcp_id",
    },
    {
      Header: "UTM Source",
      accessor: "hcp_utmsource",
    },
    {
      Header: "First Name",
      accessor: "hcp_first_name",
    },
    {
      Header: "Middle Name",
      accessor: "hcp_middle_name",
    },
    {
      Header: "Last Name",
      accessor: "hcp_last_name",
    },
    {
      Header: "Age",
      accessor: "hcp_age",
    },
    {
      Header: "Primary Speciality",
      accessor: "hcp_to_primary_speciality",
      Cell: ({ value }) => value?.speciality_name,
    },
    {
      Header: "State",
      accessor: "hcp_to_state",
      Cell: ({ value }) => value?.state_name,
    },
    // {
    //   Header: "City",
    //   accessor: "hcp_to_city",
    //   Cell: ({ value }) => value?.city_name,
    // },
    {
      Header: "Persona",
      accessor: "hcp_to_persona",
      Cell: ({ value }) =>
        value?.map((item) => item?.all_persona?.persona_name).join(", "),
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => (
        <ActionButtonBar
          buttons={["view"]}
          row={row}
          onView={(data) => {
            redirectTo("hcp-profile/details/" + row.original.hcp_id);
          }}
          // onEdit={() => {
          //   redirectTo("hcp-profile/details/" + row.original.hcp_id);
          // }}
          // onDelete={() => {}}
        />
      ),
    },
  ];

  useEffect(() => {
    // fetchHcps();

    // setHcpList(hcpDataUserEngaged);
    // setTotalCount(hcpDataUserEngaged?.length);

    switch (filterType) {
      case "assigned":
        setHcpList(hcpApprovedDataUser);
        setTotalCount(hcpApprovedDataUser?.length);
        break;

      case "pending":
        setHcpList(hcpPendingDataUser);
        setTotalCount(hcpPendingDataUser?.length);
        break;
      case "approved":
        setHcpList(hcpApprovedDataUser);
        setTotalCount(hcpApprovedDataUser?.length);
        break;
      case "review":
        setHcpList(hcpReviewDataUser);
        setTotalCount(hcpReviewDataUser?.length);
        break;
      case "not_completed":
      case "overdue":
        setHcpList(hcpOverdueDataUser);
        setTotalCount(hcpOverdueDataUser?.length);
        break;
      // case "not_completed":
      //   setHcpList(hcpOverdueDataUser);
      //   setTotalCount(hcpOverdueDataUser?.length);
      //   break;
      default:
        setHcpList(hcpDataUserEngaged);
        setTotalCount(hcpDataUserEngaged?.length);
        break;
    }
  }, [filterType]);

  const onTableStateChange = (state) => {
    console.log("STATE", state);
    let payload = changeTableStateToPayload(state);
    console.log("PAYLOAD", payload);
    post_json.current = { ...post_json.current, ...payload };
    fetchHcps();
  };
  const onRowSelectionChange = (selectedList) => {
    let selectedHcpIds = [];
    for (let index in selectedList) {
      if (selectedList[index]) {
        selectedHcpIds.push(hcpList[index].hcp_id);
      }
    }
    setSelectedList(selectedHcpIds);
  };

  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <div className="d-flex flex-column">
          <h3 className="m-0">All HCPs</h3>
        </div>
        <div className="d-flex justify-content-between align-items-center rounded flex-column flex-md-row flex-wrap gap-3">
          {selectedList && selectedList.length > 0 && (
            <Button onClick={() => setToOnboardModal(true)}>
              Mark users to onboard
            </Button>
          )}
          <div className={`form-group mb-0 ${isMobileOnly ? "w-100" : ""}`}>
            <select className="form-select">
              <option defaultValue>Sort By </option>
              <option value="1">One </option>
              <option value="2">Two </option>
              <option value="3">Three </option>
            </select>
          </div>
          <FilterBar inputs={inputFilters} />
          {/* <div className="form-group mb-0 ">
            <Flatpickr
              options={{ mode: "range", minDate: "today" }}
              className="form-control range_flatpicker"
              placeholder="24 Jan 2022 to 23 Feb 2022"
            />
          </div> */}
        </div>
      </div>
      <Row>
        <Col lg="12">
          <Card>
            <Card.Body>
              <div className="fancy-table table-responsive rounded">
                <ReactTable
                  data={hcpList}
                  columns={COLUMNS}
                  onTableStateChange={onTableStateChange}
                  recordsTotal={totalCount}
                  initialState={post_json.current}
                  isRowSelectionAvailable={true}
                  onRowSelectionChange={(selectedList) =>
                    onRowSelectionChange(selectedList)
                  }
                  recordsPerPage={post_json.current.pagination.limit}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {onboardingCreationData && (
        <MarkToOnboardModal
          show={toOnboardModal}
          onHide={() => setToOnboardModal(false)}
          selectedList={selectedList}
          onboardingCreationData={onboardingCreationData}
        />
      )}
    </Fragment>
  );
};

export default HCPProfileListWithType;

function MarkToOnboardModal({
  show,
  onHide,
  selectedList,
  onboardingCreationData,
  ...props
}) {
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const initialValues = {
    onboarding_duedate: "",
    onboarding_priority_id: "",
    onboarding_executive_id: "",
  };
  const validationSchema = Yup.object().shape({
    onboarding_duedate: Yup.string().required("Required"),
    onboarding_priority_id: Yup.string().required("Required"),
    onboarding_executive_id: Yup.string().required("Required"),
  });
  const { redirectTo } = useRedirect();
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const {
        onboarding_duedate,
        onboarding_priority_id,
        onboarding_executive_id,
      } = values;
      let payload = {
        onboarding_id: selectedList,
        onboarding_duedate: dayjs(onboarding_duedate).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        onboarding_priority_id,
        onboarding_executive_id,
        // origin_associates,
      };
      showBackdrop();
      let response = await postService(ONBOARDING_CREATE_TASK, payload);
      console.log("RESPONSE", response);
      if (response.isError) {
        toast.error(response.error);
        return;
      } else {
        let isSuccess = response.data.success;
        if (isSuccess) {
          // set data
          let data = response.data;
          console.log("DATA", data);
          if (data?.success) {
            hideBackdrop();
            toast.success(data?.message);
            onHide();
            redirectTo("action/all-action");
          }
        } else {
          toast.error(response.error || "Something went wrong");
        }
      }
    },
  });

  const {
    values,
    touched,
    setTouched,
    errors,
    handleChange,
    setValues,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
  } = formik;
  const onSave = () => {
    handleSubmit();
  };
  // if (!onboardingCreationData) return null;
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Fill the details to onboard
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col lg="4">
            <Form.Floating className="custom-form-floating">
              <Form.Control
                type="datetime-local"
                id="exampleInputdatetime"
                placeholder="Enter Due Date"
                name="onboarding_duedate"
                value={values.onboarding_duedate}
                handleBlur={handleBlur}
                onChange={handleChange}
                min={dayjs().format("YYYY-MM-DD")}
                isInvalid={
                  touched.onboarding_duedate && !!errors.onboarding_duedate
                }
              />
              <Form.Label>Due date</Form.Label>
            </Form.Floating>
          </Col>
          <Col lg="4">
            <Form.Floating className="custom-form-floating">
              <Form.Select
                name="onboarding_priority_id"
                value={values.onboarding_priority_id}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={
                  touched.onboarding_priority_id &&
                  !!errors.onboarding_priority_id
                }
              >
                <option value="">Select Priority</option>
                {onboardingCreationData?.priority?.map((item) => (
                  <option key={item?.priority_id} value={item?.priority_id}>
                    {item?.priority_name}
                  </option>
                ))}
              </Form.Select>
              <Form.Label>Priority</Form.Label>
            </Form.Floating>
          </Col>
          <Col lg="4">
            <Form.Floating className="custom-form-floating">
              <Form.Select
                name="onboarding_executive_id"
                value={values.onboarding_executive_id}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={
                  touched.onboarding_executive_id &&
                  !!errors.onboarding_executive_id
                }
              >
                <option value="">Select Executive</option>
                {onboardingCreationData?.onboarding_executive?.map((item) => (
                  <option key={item?.id} value={item?.id}>
                    {item?.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Label>Select Executive</Form.Label>
            </Form.Floating>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onHide}>
          Close
        </Button>
        <Button variant="info" onClick={onSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
