import React from "react";
import Skeleton from "../../components/skeleton";

const AddContentTypeLoader = () => {
  let arr = Array.from({ length: 15 }, (x, i) => i);
  return (
    <>
      <div className="d-flex gap-2 mb-2 overflow-auto scroll-addcontent pb-2">
        {arr.map((_i, index) => (
          <Skeleton
            variant="rounded"
            width={100}
            height={60}
            // className="rounded"
            key={index + 1}
          />
        ))}
      </div>
    </>
  );
};

export default AddContentTypeLoader;
