import { storages } from "../../common/utils/utils";
import { updateStorage } from "../../utilities/setting";

export const persistAuthState = ({ auth, contentStudio }) => {
  try {
    updateStorage(storages.local, "authState", auth);
    updateStorage(storages.session, "contentStudioState", contentStudio);
  } catch (error) {
    // Handle error if unable to persist state
    console.error("Error persisting authentication state:", error);
  }
};
