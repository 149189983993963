import { Fragment, useState, useEffect, useRef } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { isMobileOnly } from "react-device-detect";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import FilterBar from "../../../../components/filter-bar";
import ReactTable from "../../../../lib/table/react-table";

import postService from "../../../../api/services/post-service";
import { HCP_LISTING } from "../../../../api/endpoints/hcp-endpoints";
import ActionButtonBar from "../../../../components/partials/common/action-button-bar";
import {
  changeTableStateToPayload,
  statusToBg,
} from "../../../../common/utils/utils";
import * as Yup from "yup";
import { useFormik } from "formik";
import dayjs from "dayjs";
import { toast } from "react-hot-toast";
import {
  ONBOARDING_CREATE_TASK,
  RE_ONBOARDING_CREATE_TASK,
} from "../../../../api/endpoints/onboarding-endpoints";
import { useFetchOnboardingCreation } from "../../../../queries/query-hooks/onboarding-hook";
import { redirect } from "react-router-dom";
import useBackdrop from "../../../../components/custom-hooks/use-backdrop";
import { extractNextDay } from "../../action/utils/utils";
import { ONBOARDING_STATUS_CONSTANTS } from "../constant/hcp-constatnt";
import CommonTableLoader from "../../../../skeleton/common-table-loader";
import PageNotFound from "../../../PageNotFound";

const inputFilters = [
  {
    name: "name",
    type: "text",
    placeholder: "Please Enter Name",
    label: "Name",
  },
  {
    name: "age",
    type: "text",
    placeholder: "Enter age",
    label: "Age",
  },
  {
    name: "degree",
    type: "select",
    placeholder: "Degree",
    label: "Degree",
    options: [
      { value: "1", label: "Degree 1" },
      { value: "2", label: "Degree 2" },
      { value: "3", label: "Degree 3" },
    ],
  },
  {
    name: "consent_status",
    type: "select",
    placeholder: "consent status",
    label: "Consent Status",
    options: [
      { value: "1", label: "Email" },
      { value: "2", label: "SMS" },
      { value: "3", label: "Whatsapp" },
    ],
  },
  {
    name: "dnd",
    type: "select",
    placeholder: "DND",
    label: "DND",
    options: [
      { value: "1", label: "Yes" },
      { value: "2", label: "No" },
    ],
  },
  {
    name: "state",
    type: "select",
    placeholder: "State",
    label: "State",
    options: [
      { value: "1", label: "West bengal" },
      { value: "2", label: "Delhi" },
      { value: "3", label: "Maharashtra" },
      { value: "4", label: "Kerala" },
    ],
  },
];

const ONBOARDING_TYPE = "Onboard";
const RE_ONBOARDING_TYPE = "Re-Onboard";
const HCP_ProfileListing = () => {
  const { data: onboardingCreationData } = useFetchOnboardingCreation();

  const { showBackdrop, hideBackdrop } = useBackdrop();
  const [selectedList, setSelectedList] = useState([]);
  const { redirectTo } = useRedirect();
  const [loading, setLoading] = useState(false);
  const [toOnboardModal, setToOnboardModal] = useState(false);
  const [hcpList, setHcpList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRowFor, setSelecteRowFor] = useState("");
  const [onboardingType, setOnboardingType] = useState(ONBOARDING_TYPE);
  const post_json = useRef({
    default: "all",
    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      hcp_id: "desc",
    },
    filter: {
      status: {
        type: "eq",
        value: 1,
      },
    },
  });

  const fetchHcps = async () => {
    // fetch hcp list
    console.log("fetch hcp");
    if (!isLoading) {
      showBackdrop();
    }
    let response = await postService(HCP_LISTING, post_json.current);
    if (response.isError) {
      if (!isLoading) {
        hideBackdrop();
      } else {
        setIsLoading(false);
      }
      toast.error("Something Went Wrong, No Data Found");
      return;
    } else {
      let isSuccess = response.data.success;
      if (isSuccess) {
        let data = response.data.data;

        setTotalCount(data?.totalCount);
        setHcpList(data.rows?.length > 0 ? data.rows : []);
        if (!isLoading) {
          hideBackdrop();
        } else {
          setIsLoading(false);
        }
        console.log("COLUMNS", Object.keys(data.rows[0]));
      } else {
        if (!isLoading) {
          hideBackdrop();
        } else {
          setIsLoading(false);
        }
        toast.error("Something Went Wrong, No Data Found");
      }
    }
    // on fetch success
    if (response && toOnboardModal) {
      setToOnboardModal(false);
    }
  };

  // NEW: 1,
  // ASSIGN: 2,
  // IN_PROGRESS: 3,
  // ONBOARDED: 4,
  // APPROVED: 5,
  //   REJECTED: 6,

  const onboardingStatusOptions = [
    {
      label: "New",
      value: 1,
    },
    {
      label: "Assign",
      value: 2,
    },
    {
      label: "In Progress",
      value: 3,
    },
    {
      label: "Onboarded",
      value: 4,
    },
    {
      label: "Approved/Completed",
      value: 5,
    },
    {
      label: "Reject",
      value: 6,
    },
  ];

  const statusOptions = [
    {
      label: "Enable",
      value: 1,
    },
    {
      label: "Disable",
      value: 2,
    },
  ];
  function DropDownFilter({ column, options = [] }) {
    const { filterValue, preFilteredRows, setFilter } = column;
    return (
      <Form.Select onChange={(e) => setFilter(e.target.value || undefined)}>
        <option value="">Select Filter</option>
        {options?.length > 0 &&
          options.map((item) => {
            const { label, value } = item;
            return <option value={value}>{label}</option>;
          })}
      </Form.Select>
    );
  }

  const COLUMNS = [
    {
      Header: "ID",
      accessor: "hcp_id",
    },
    {
      Header: "UTM",
      accessor: "hcp_utmsource",
    },
    {
      Header: "First Name",
      accessor: "hcp_first_name",
    },
    // {
    //   Header: "Middle Name",
    //   accessor: "hcp_middle_name",
    // },
    // {
    //   Header: "Last Name",
    //   accessor: "hcp_last_name",
    // },
    {
      Header: "Age",
      accessor: "hcp_age",
    },
    {
      Header: "Primary Speciality",
      accessor: "hcp_to_primary_speciality",
      Cell: ({ value }) => value?.speciality_name,
    },
    {
      Header: "Onboarding Status", //
      accessor: "hcp_onboarding_status",
      Filter: ({ column }) => (
        <DropDownFilter column={column} options={onboardingStatusOptions} />
      ),
      Cell: ({ value }) => value?.value ?? "--",
    },
    {
      Header: "status",
      accessor: "status",
      Filter: ({ column }) => (
        <DropDownFilter column={column} options={statusOptions} />
      ),
      Cell: ({ value }) => value?.value,
    },
    // {
    //   Header: "Persona",
    //   accessor: "hcp_to_persona",
    //   Cell: ({ value }) =>
    //     value?.map((item) => item?.all_persona?.persona_name).join(", "),
    // },
    {
      Header: "Action",
      accessor: "action",
      disableFilters: true,

      Cell: ({ row, value }) => (
        <ActionButtonBar
          buttons={["view"]}
          row={row}
          onView={(data) => {
            console.log("values", value);
            redirectTo(`hcp-profile/details/${row.original.hcp_id}`);
          }}
          // onEdit={() => {
          //   redirectTo("hcp-profile/details/" + row.original.hcp_id);
          // }}
          // onDelete={() => {}}
        />
      ),
    },
  ];

  useEffect(() => {
    fetchHcps();
    // const inputDate = "2023-12-06 16:30:28";
    // const nextDay = 10;
    // const result = calculateNextDay(inputDate, nextDay);
    // console.log("day result", result);
  }, []);
  const onTableStateChange = (state) => {
    console.log("STATE", state);
    let payload = changeTableStateToPayload(state);
    console.log("PAYLOAD", payload);
    post_json.current = { ...post_json.current, ...payload };
    fetchHcps();
  };

  function findItemById(hcpId, items) {
    console.log("find Item :", hcpId, "items", items);
    // console.log(items.find((item) => item.hcp_id === hcpId));
    return items.find((item) => item.hcp_id == hcpId);
  }
  const getSelectedData = (allList, selectedList) => {
    const selectedIndices = Object.keys(selectedList).map(Number);

    // Check if onboarding status is the same for all selected items
    const onboardingStatusIds = selectedIndices.map(
      (index) => allList[index].hcp_onboarding_status.id
    );
    const isOnboardingStatusDifferent = new Set(onboardingStatusIds).size > 1;

    // Return false if onboarding status is different, otherwise return selected hcp_id
    return isOnboardingStatusDifferent
      ? false
      : selectedIndices.map((index) => allList[index].hcp_id);
  };

  const onRowSelectionChange = (selectedList) => {
    console.group("rowStateChange");
    let selectedHcpIds = [];
    let selectedData =
      Object.keys(selectedList)?.length > 0
        ? getSelectedData(hcpList, selectedList)
        : false;

    console.log("selectedData", selectedData);
    if (
      Object.keys(selectedList)?.length > 0 &&
      (selectedData == false || selectedData?.length == 0)
    ) {
      toast.error("you can only select which onboarding satus is same");
    } else {
      setSelectedList(selectedData);
    }
    // if (Object.keys(selectedList)?.length > 0) {
    //   console.log("getSelectedData", getSelectedData(hcpList, selectedList));

    //   for (let index in selectedList) {
    //     if (selectedList[index]) {
    //       selectedHcpIds.push(hcpList[index].hcp_id);
    //     }
    //   }

    //   let selectedRow = hcpList.filter((_, index) =>
    //     Object.keys(selectedList)?.some((i) => i == index)
    //   );

    //   if (selectedHcpIds?.length >= 0 && selectedHcpIds?.length <= 1) {
    //     setSelectedList(selectedHcpIds);
    //   } else if (
    //     selectedHcpIds?.length > 1 &&
    //     findItemById(selectedHcpIds[0], hcpList)?.hcp_onboarding_status?.id !=
    //       selectedRow?.hcp_onboarding_status?.id
    //   ) {
    //     setSelectedList(selectedHcpIds);
    //   } else {
    //     toast.error("You can't select different type of user same time");
    //   }
    // } else {
    //   setSelectedList([]);
    // }
    console.groupEnd("rowStateChange");
  };

  const onReOnboardClick = () => {
    setOnboardingType("Re-Onboard");
    setToOnboardModal(true);
  };

  const onTaskCreateSuccess = () => {
    fetchHcps();
  };
  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <div className="d-flex flex-column">
          <h3 className="m-0">All HCP's</h3>
        </div>
        <div className="d-flex justify-content-between align-items-center rounded flex-column flex-md-row flex-wrap gap-3">
          {selectedList?.length > 0 &&
            findItemById(selectedList[0], hcpList)?.hcp_onboarding_status?.id ==
              ONBOARDING_STATUS_CONSTANTS.NEW &&
            findItemById(selectedList[0], hcpList)?.hcp_onboarding_status?.id !=
              ONBOARDING_STATUS_CONSTANTS.ASSIGN &&
            findItemById(selectedList[0], hcpList)?.hcp_onboarding_status?.id !=
              ONBOARDING_STATUS_CONSTANTS.IN_PROGRESS &&
            findItemById(selectedList[0], hcpList)?.hcp_onboarding_status?.id !=
              ONBOARDING_STATUS_CONSTANTS.ONBOARDED && (
              <Button onClick={() => setToOnboardModal(true)}>
                Create Task
              </Button>
            )}

          {selectedList?.length > 0 &&
            (findItemById(selectedList[0], hcpList)?.hcp_onboarding_status
              ?.id == ONBOARDING_STATUS_CONSTANTS.APPROVED ||
              findItemById(selectedList[0], hcpList)?.hcp_onboarding_status
                ?.id == ONBOARDING_STATUS_CONSTANTS.REJECTED) && (
              <Button onClick={onReOnboardClick}>Create Re-Onboard Task</Button>
            )}
          {/* <div className={`form-group mb-0 ${isMobileOnly ? "w-100" : ""}`}>
            <select className="form-select">
              <option defaultValue>Sort By </option>
              <option value="1">One </option>
              <option value="2">Two </option>
              <option value="3">Three </option>
            </select>
          </div> */}
          <FilterBar inputs={inputFilters} />
          {/* <div className="form-group mb-0 ">
            <Flatpickr
              options={{ mode: "range", minDate: "today" }}
              className="form-control range_flatpicker"
              placeholder="24 Jan 2022 to 23 Feb 2022"
            />  
          </div> */}
        </div>
      </div>
      <Row>
        <Col lg="12">
          <Card>
            <Card.Body>
              <div className="fancy-table table-responsive rounded">
                {!isLoading && hcpList?.length > 0 && totalCount > 0 ? (
                  <ReactTable
                    data={hcpList}
                    columns={COLUMNS}
                    onTableStateChange={onTableStateChange}
                    recordsTotal={totalCount}
                    initialState={post_json.current}
                    isRowSelectionAvailable={true}
                    onRowSelectionChange={(selectedList) =>
                      onRowSelectionChange(selectedList)
                    }
                    recordsPerPage={post_json.current.pagination.limit}
                  />
                ) : isLoading ? (
                  <CommonTableLoader />
                ) : (
                  <PageNotFound />
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {onboardingCreationData && (
        <MarkToOnboardModal
          show={toOnboardModal}
          onHide={() => setToOnboardModal(false)}
          selectedList={selectedList}
          onboardingCreationData={onboardingCreationData}
          onboardingType={onboardingType}
          onSuccess={onTaskCreateSuccess}
        />
      )}
    </Fragment>
  );
};

export default HCP_ProfileListing;

function MarkToOnboardModal({
  show,
  onHide,
  onSuccess,
  selectedList,
  onboardingCreationData,
  onboardingType,
  ...props
}) {
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const initialValues = {
    onboarding_duedate: "",
    onboarding_priority_id: "",
    onboarding_executive_id: "",
  };
  const validationSchema = Yup.object().shape({
    onboarding_duedate: Yup.string().required("Required"),
    onboarding_priority_id: Yup.string().required("Required"),
    onboarding_executive_id: Yup.string().required("Required"),
  });
  const { redirectTo } = useRedirect();
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const {
        onboarding_duedate,
        onboarding_priority_id,
        onboarding_executive_id,
      } = values;
      let payload = {
        onboarding_id: selectedList,
        onboarding_duedate: dayjs(onboarding_duedate).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        onboarding_priority_id,
        onboarding_executive_id,
        // origin_associates,
      };
      showBackdrop();
      let response = await postService(
        onboardingType == RE_ONBOARDING_TYPE
          ? RE_ONBOARDING_CREATE_TASK
          : ONBOARDING_CREATE_TASK,
        payload
      );
      if (response.isError) {
        toast.error(response.error);
        return;
      } else {
        let isSuccess = response.data.success;
        if (isSuccess) {
          // set data
          hideBackdrop();

          let data = response.data;
          console.log("DATA", data);
          if (data?.success) {
            toast.success(data?.message);
            onSuccess();
            // redirectTo("action/all-action");
          }
          resetForm();
        } else {
          toast.error(response.error || "Something went wrong");
        }
      }
    },
  });

  const {
    values,
    touched,
    setTouched,
    errors,
    handleChange,
    setValues,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = formik;
  const onSave = () => {
    handleSubmit();
  };

  const today = dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss");
  const nextAcceptableDay = 10;
  const dueDateOptions = extractNextDay(today, nextAcceptableDay);
  console.log("due date:", dueDateOptions);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Fill the details to {onboardingType}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col lg="4">
            <Form.Floating className="custom-form-floating">
              {/* <Form.Select
                type="datetime-local"
                id="exampleInputdatetime"
                placeholder="Enter Due Date"
                name="onboarding_duedate"
                value={values.onboarding_duedate}
                handleBlur={handleBlur}
                onChange={handleChange}
                min={dayjs().format("YYYY-MM-DD")}
                isInvalid={
                  touched.onboarding_duedate && !!errors.onboarding_duedate
                }
              >
                {/* <option value="">Select Due Date</option>
                {dueDateOptions.map((data, ind) => (
                  <option value={data?.date}>{data.dayOfWeek}</option>
                ))} 
              </Form.Select> */}

              <Form.Control
                type="datetime-local"
                id="exampleInputdatetime"
                placeholder="Enter Due Date"
                name="onboarding_duedate"
                value={values.onboarding_duedate}
                handleBlur={handleBlur}
                onChange={handleChange}
                min={dayjs().format("YYYY-MM-DD")}
                isInvalid={
                  touched.onboarding_duedate && !!errors.onboarding_duedate
                }
              />
              <Form.Label>Due date</Form.Label>
              {/* <Form.Label>Due date</Form.Label> */}
            </Form.Floating>
          </Col>
          <Col lg="4">
            <Form.Floating className="custom-form-floating">
              <Form.Select
                name="onboarding_priority_id"
                value={values.onboarding_priority_id}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={
                  touched.onboarding_priority_id &&
                  !!errors.onboarding_priority_id
                }
              >
                <option value="">Select Priority</option>
                {onboardingCreationData?.priority?.map((item) => (
                  <option key={item?.priority_id} value={item?.priority_id}>
                    {item?.priority_name}
                  </option>
                ))}
              </Form.Select>
              <Form.Label>Priority</Form.Label>
            </Form.Floating>
          </Col>
          <Col lg="4">
            <Form.Floating className="custom-form-floating">
              <Form.Select
                name="onboarding_executive_id"
                value={values.onboarding_executive_id}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={
                  touched.onboarding_executive_id &&
                  !!errors.onboarding_executive_id
                }
              >
                <option value="">Select Executive</option>
                {onboardingCreationData?.onboarding_executive?.map((item) => (
                  <option key={item?.id} value={item?.id}>
                    {item?.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Label>Select Executive</Form.Label>
            </Form.Floating>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onHide}>
          Close
        </Button>
        <Button variant="info" onClick={onSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
