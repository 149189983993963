import cx from "classnames";
import React, {
  memo,
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import TreeView, { flattenTree } from "react-accessible-treeview";
import {
  Button,
  Col,
  Dropdown,
  Form,
  FormCheck,
  Modal,
  Row,
} from "react-bootstrap";
import dayjs from "dayjs";
import Card from "../../../../components/bootstrap/card";
import { findIcon } from "../../../dashboard/icons";
import {
  CONTENT_FILE_LIST,
  CONTENT_STUDIO_FILE_DETAILS,
} from "../../../../api/endpoints/content-studio-endpoints";
import postService from "../../../../api/services/post-service";
import ContentCard from "../../content-library/components/content-card";
import {
  folder,
  file_type_data,
  principal_entity_data,
} from "../data/demo-data";
import CustomToggle from "../../../../components/dropdowns";
import {
  changeTableStateToPayload,
  getSubtext,
} from "../../../../common/utils/utils";
import ActionButtonBar from "../../../../components/partials/common/action-button-bar";
import { getService } from "../../../../api/services/get-services";
import { CONTENT_DETAILS_GET } from "../../../../api/endpoints/content-endpoints";
import toast from "react-hot-toast";
import ReactTable from "../../../../lib/table/react-table";
import PaginationBar from "../../../../lib/table/pagination-bar";
import FilePreviewModal from "../../../../components/partials/common/file-preview-modal";
import CommonTableLoader from "../../../../skeleton/common-table-loader";
import ContentCardLoader from "../../../../skeleton/creative-library/content-card-loader";
import useBackdrop from "../../../../components/custom-hooks/use-backdrop";
const pageSize = 10;
const CreateContentStudioModal = ({
  show,
  handleCreateModalClose,
  contentStudioDetails,
  getContentStudioName,
  selectTemplateForContent,
  stepToShow,
}) => {
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const [step, setStep] = useState(stepToShow ?? 1);
  const [selectedEntity, setSelectedEntity] = React.useState("");
  const [entities, setEntities] = React.useState(principal_entity_data); //state for principle entities
  const [fileType, setFileType] = React.useState(file_type_data); //state for file type
  const [selectedFileType, setSelectedFileType] = React.useState(null); //state for selected file type
  const [content, setContent] = React.useState(null); //state for content
  const [isListView, setIsListView] = React.useState(false); //state for list view
  const [totalCount, setTotalCount] = React.useState(0); //state for total count
  const [loading, setLoading] = React.useState(true); //state for loading
  const [tableState, setTableState] = React.useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [
      {
        id: "content_id",
        desc: true,
      },
    ],
    filters: [],
  }); //state for table state
  const initialFileState = {
    title: "",
    file_url: "",
    description: "",
    createdBy: "",
    brand: "",
    icon: "",
    type: "",
  };
  const [fileDetails, setFileDetails] = React.useState(initialFileState); //state for file details
  const [selectedEntityId, setSelectedEntityId] = React.useState(null); //state for selected entity id
  const [showFilePreview, setShowFilePreview] = React.useState(""); //state for show file preview
  const [filePreviewShowModal, setFilePreviewShowModal] = React.useState(false); //state for file preview show
  const initialJson = {
    default: "all",
    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      content_id: "desc",
    },
  };
  const post_json = useRef(initialJson);

  const ArrowIcon = ({ isOpen, className }) => {
    const baseClass = "arrow";
    const classes = cx(
      baseClass,
      { [`${baseClass}--closed`]: !isOpen },
      { [`${baseClass}--open`]: isOpen },
      className
    );
    return (
      <>
        <span className="folder-icon position-absolute iiii">
          {findIcon("Folder", "dual-tone", "18")}
        </span>
        <span className="down-arrow-icon position-absolute transition">
          {findIcon("Chevron Down", "outline", "18")}
        </span>
      </>
    );
  };
  const columns = useMemo(
    () => [
      {
        Header: "Content ID",
        accessor: "content_id",
      },
      {
        Header: "Content Title",
        accessor: "content_title",
        Cell: ({ value }) => (value ? getSubtext(value, 20) : "No Title"),
      },
      {
        Header: "Content Description",
        accessor: "content_description",
        Cell: ({ value }) => (value ? getSubtext(value, 20) : "No Description"),
      },
      {
        Header: "Content Publication Date",
        accessor: "content_publication_date",
        Cell: ({ value }) =>
          value ? dayjs(value).format("DD/MM/YYYY") : "No Date",
      },
      {
        Header: "Content Brand",
        accessor: "content_brand_id",
        Cell: ({ value }) => value?.principal_entity_name,
      },
      {
        Header: "Content File Type",
        accessor: "content_file_type_id",
        Cell: ({ value }) => value?.file_type_code,
      },
      // {
      //   Header: "Content Privacy Status",
      //   accessor: "content_privacy_status",
      //   Cell: ({ value }) => value?.value ?? "None",
      // },
      // {
      //   Header: "Content Deeplink",
      //   accessor: "content_deeplink",
      // },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => value?.value ?? "None",
      },
      {
        Header: "Action",
        accessor: "action",

        Cell: ({ row }) => (
          <ActionButtonBar
            row={row}
            buttons={["view", "select"]}
            onView={(data) => {
              onClickPreviewContent(data.content_id, false);
            }}
            onSelect={(data) => {
              onSelectContentForTemplate(data.content_id);
            }}
          />
        ),
      },
    ],
    []
  );

  const onEachNodeClick = (element) => {
    console.log("element", element);
    if (element.children.length === 0) {
      //fetch content
      console.log("fetch content");
      let id = entities?.find(
        (item) => item.principal_entity_name === element.name
      ).principal_entity_id;
      console.log("id", id);
      post_json.current = {
        ...post_json.current,
        filter: {
          ...post_json.current.filter,
          content_brand_id: {
            type: "eq",
            value: id,
          },
        },
      };
      console.log("post_json", post_json.current);
      // alert(id);
      setSelectedEntityId(id);
      setSelectedEntity(element.name);
      fetchFileListing();
    }
  };
  const handleFileTypeChange = (id) => {
    setSelectedFileType(id);
    post_json.current = {
      ...post_json.current,
      filter: {
        ...post_json.current.filter,
        content_file_type_id: {
          type: "eq",
          value: id,
        },
      },
    };

    fetchFileListing();
  };
  const onTableStateChange = useCallback(
    (state) => {
      console.log("TABLE_STATE", state);
      setTableState(state);
      let payload = changeTableStateToPayload(state);
      console.log("payload", payload);
      post_json.current = {
        ...payload,
        filter: {
          ...payload.filter,
          ...(selectedEntityId && {
            content_brand_id: {
              type: "eq",
              value: selectedEntityId,
            },
          }),
          ...(selectedFileType && {
            content_file_type_id: {
              type: "eq",
              value: selectedFileType,
            },
          }),
        },
      };
      console.log("post_json", post_json.current);
      fetchFileListing();
    },
    [selectedEntityId, selectedFileType, post_json.current, fetchFileListing]
  );
  const onPageChange = (pageIndex) => {
    console.log("pageIndex", pageIndex);
    setTableState((prevState) => ({
      ...prevState,
      pageIndex: pageIndex,
    }));
    post_json.current = {
      ...post_json.current,
      pagination: {
        ...post_json.current.pagination,
        offset: pageIndex * pageSize,
      },
      filter: {
        ...post_json.current.filter,
        ...(selectedEntityId && {
          content_brand_id: {
            type: "eq",
            value: selectedEntityId,
          },
        }),
        ...(selectedFileType && {
          content_file_type_id: {
            type: "eq",
            value: selectedFileType,
          },
        }),
      },
    };
    fetchFileListing();
  };

  const onClickPreviewContent = useCallback(
    async (id, isContentCard = true) => {
      showBackdrop();
      let response = await getService(CONTENT_DETAILS_GET + id);
      if (response.isError) {
        console.log(response);
      } else {
        let fileResponse = response.data.data.detail;
        let {
          content_to_file = {},
          content_to_av = {},
          content_to_text = {},
          content_brand_id = {},
          content_file_type_id = {},
          created_by = {},
          content_to_image = {},
        } = fileResponse;
        let content = content_to_file || content_to_av || content_to_text;
        let {
          title_html,
          description_html,
          storage_link = "",
          path = "",
        } = content;
        let file_url = storage_link || path || "";

        if (fileResponse) {
          hideBackdrop();
          setFileDetails((prev) => {
            return {
              ...prev,
              title: title_html,
              description: description_html,
              file_url: file_url,
              citation: content_to_text?.citation_html ?? "",
              createdBy: created_by?.name,
              coverImage: content_to_image?.content_image_id?.thumbnail,
              brand: content_brand_id?.principal_entity_name,
              icon: content_file_type_id?.file_type_icon,
              type: content_file_type_id?.file_type_code,
            };
          });
          isContentCard
            ? setShowFilePreview(id)
            : setFilePreviewShowModal(true);
        } else {
          toast.error("File not found");
        }
      }
    },
    [showFilePreview, fileDetails, filePreviewShowModal]
  );
  const onViewModalClose = useCallback(() => {
    setShowFilePreview("");
    setFileDetails(initialFileState);
  }, [showFilePreview, fileDetails]);

  async function fetchFileListing() {
    // setLoading(true);
    const response = await postService(CONTENT_FILE_LIST, post_json.current);
    console.log("response_entity", response.data?.data);
    setContent(response.data?.data?.rows);
    setTotalCount(response.data?.data?.totalCount);
    setLoading(false);
  }
  useEffect(() => {
    if (show && step === 2) {
      fetchFileListing();
    }
  }, [show, step]);
  const totalPage = useMemo(
    () => Math.ceil(totalCount / pageSize),
    [totalCount, pageSize]
  );
  const onSelectContentForTemplate = useCallback(
    async (id) => {
      showBackdrop();
      const response = await getService(CONTENT_STUDIO_FILE_DETAILS + id);
      if (response && response?.data?.success) {
        let fileResponse = response?.data?.data?.detail;
        console.log("fileResponse", fileResponse);
        let {
          content_to_file = {},
          content_to_av = {},
          content_to_text = {},
        } = fileResponse;
        let content = content_to_file || content_to_av || content_to_text;
        let {
          title_html,
          description_html,
          storage_link = "",
          path = "",
        } = content;
        let file_url = storage_link || path || "";
        selectTemplateForContent({
          id: id,
          fileName: fileResponse.content_title,
          fileTypeIcon: fileResponse.content_file_type_id.file_type_icon,
          fileDescription: fileResponse?.content_description,
          fileType: fileResponse.content_file_type_id.file_type_code,
          createdBy: fileResponse?.created_by?.name,
          fileLink: file_url,
          publishedDate: fileResponse?.content_publication_date,
        });
        hideBackdrop();
      }
    },
    [selectTemplateForContent]
  );

  return (
    <>
      <Modal
        show={show}
        className={`${step === 2 ? "find-file-modal" : ""}`}
        onHide={handleCreateModalClose}
        fullscreen={step === 2}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {step === 1 && (
          <>
            <Modal.Header closeButton>
              <Modal.Title>
                <h5 className="mb-0">Create New Content Studio</h5>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="position-relative d-flex flex-column gap-3">
                <Form.Floating className="custom-form-floating">
                  <Form.Control
                    type="text"
                    className=""
                    id="floatingInput1"
                    autoComplete="Enter Content studio Name"
                    placeholder="Enter Content studio Name"
                    onChange={(e) => getContentStudioName(e)}
                  />
                  <Form.Label htmlFor="floatingInput">
                    Enter Content studio Name
                  </Form.Label>
                </Form.Floating>
                <Button
                  className=""
                  variant="primary"
                  disabled={contentStudioDetails?.content_studio_name === ""}
                  onClick={() => setStep(2)}
                >
                  Submit & Select Content
                </Button>
              </div>
            </Modal.Body>
          </>
        )}
        {step === 2 && (
          <Modal.Body className="p-0">
            <div className="row m-0 myClass">
              <div className="col-12 col-md-3 position-relative overflow-scroll p-0">
                <Card className="h-100 m-0 rounded-0">
                  <Card.Body className="d-flex flex-column">
                    <h5 className="mb-3">Find in folder New</h5>
                    {folder && (
                      <TreeView
                        className="list-unstyled flex-grow-1 custom-TreeView overflow-scroll"
                        data={folder}
                        aria-label="Checkbox tree"
                        onExpand={(node) => {
                          console.log("on expand", node);
                        }}
                        nodeRenderer={({
                          element,
                          isBranch,
                          isExpanded,
                          getNodeProps,
                          level,
                          handleExpand,
                          className,
                        }) => {
                          return (
                            <div
                              {...getNodeProps({ onClick: handleExpand })}
                              style={{ marginLeft: 5 * (level - 1) }}
                            >
                              <div
                                className=""
                                onClick={() => onEachNodeClick(element)}
                              >
                                {isBranch ? (
                                  <ArrowIcon isOpen={isExpanded} />
                                ) : (
                                  <span className="folder-icon position-absolute jjj">
                                    {findIcon(
                                      "Chevron Arrow Right",
                                      "dual-tone",
                                      "16"
                                    )}
                                  </span>
                                )}
                                <span
                                  className={
                                    selectedEntity == element.name
                                      ? "text-primary"
                                      : ""
                                  }
                                >
                                  {element.name}
                                </span>
                              </div>
                            </div>
                          );
                        }}
                      />
                    )}
                  </Card.Body>
                </Card>
              </div>
              <div className="p-3 p-md-5 col-12 col-md-9 h-100 overflow-scroll">
                <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
                  <h5 className="m-0">{"All Contents"}</h5>
                  <div className="d-flex align-items-center gap-3">
                    <Dropdown>
                      <Dropdown.Toggle
                        as={CustomToggle}
                        variant="btn btn-sm btn-primary rounded-2 btn-icon"
                      >
                        <i className="btn-inner">
                          {findIcon("Adjustment", "dual-tone", "18")}
                        </i>
                      </Dropdown.Toggle>

                      <Dropdown.Menu variant="end p-0 sub-drop">
                        <Card className="m-0 shadow-none">
                          <Card.Body className="p-3 max-17 scroll-thin rounded-4">
                            {fileType &&
                              fileType.map((item, index) => {
                                return (
                                  <div className="mb-2" key={index + 1}>
                                    <FormCheck.Input
                                      className="me-2"
                                      type="radio"
                                      name="fileType"
                                      value={item.file_type_id}
                                      checked={
                                        selectedFileType == item.file_type_id
                                      }
                                      onChange={(e) =>
                                        handleFileTypeChange(e.target.value)
                                      }
                                    />
                                    <FormCheck.Label className="">
                                      {item.file_type_name}
                                    </FormCheck.Label>
                                  </div>
                                );
                              })}
                          </Card.Body>
                        </Card>
                      </Dropdown.Menu>
                    </Dropdown>

                    <ul
                      className="nav nav-pills mb-0 iq-product-filter d-flex bg-transparent align-items-center flex-nowrap"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li
                        className="nav-item ms-2"
                        role="presentation"
                        onClick={() => setIsListView(false)}
                      >
                        <Button
                          bsPrefix={
                            "nav-link btn-sm btn-icon rounded-pill" +
                            (isListView ? "" : " active")
                          }
                          id="grid-view-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-grid-view"
                          to="#"
                          role="tab"
                          aria-controls="pills-grid-view"
                          aria-selected="true"
                        >
                          <span className="btn-inner d-inline-flex">
                            {findIcon("View Grid", "dual-tone")}
                          </span>
                        </Button>
                      </li>
                      <li
                        className="nav-item ms-2"
                        role="presentation"
                        onClick={() => setIsListView(true)}
                      >
                        <Button
                          bsPrefix={
                            "nav-link btn-sm btn-icon rounded-pill" +
                            (isListView ? " active" : "")
                          }
                          id="list-view-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-list-view"
                          to="#"
                          role="tab"
                          aria-controls="pills-list-view"
                          aria-selected="false"
                        >
                          <span className="btn-inner d-inline-flex">
                            {findIcon("List", "dual-tone")}
                          </span>
                        </Button>
                      </li>
                    </ul>
                  </div>
                </div>
                {content && isListView && !loading && (
                  <ReactTable
                    data={content}
                    recordsTotal={totalCount}
                    columns={columns}
                    // isRowSelectionAvailable
                    onTableStateChange={onTableStateChange}
                    initialState={post_json.current}
                    isPagination={true}
                    recordsPerPage={10}
                  />
                )}
                {content && !isListView && !loading && (
                  <>
                    <Row className="row-cols-1 row-cols-md-2 row-cols-lg-4">
                      {content.map((item, index) => {
                        return (
                          <Col key={`content-${index}`}>
                            <ContentCard
                              key={`content-${index}`}
                              id={item.content_id}
                              src={item?.image?.thumbnail}
                              date={item.content_publication_date}
                              title={item.content_title}
                              citation={fileDetails?.citation}
                              titleHtml={fileDetails?.title}
                              coverImage={fileDetails?.coverImage}
                              descriptionHtml={fileDetails?.description}
                              description={fileDetails?.description}
                              brand={
                                item.content_brand_id?.principal_entity_name
                              }
                              icon={
                                item?.content_file_type_id
                                  ? item?.content_file_type_id?.file_type_icon
                                  : ""
                              }
                              onClickPreview={onClickPreviewContent}
                              file={fileDetails?.file_url}
                              filePreviewModal={
                                showFilePreview == item.content_id
                              }
                              type={
                                item?.content_file_type_id
                                  ? item?.content_file_type_id?.file_type_code
                                  : ""
                              }
                              onViewModalClose={onViewModalClose}
                              createdBy={item.created_by?.name}
                              onSelect={(id) => {
                                onSelectContentForTemplate(id);
                              }}
                            />
                          </Col>
                        );
                      })}
                    </Row>
                    <div className="mt-auto">
                      <PaginationBar
                        gotoPage={onPageChange}
                        onNextPage={() => {
                          onPageChange(tableState.pageIndex + 1);
                        }}
                        onPreviousPage={() => {
                          onPageChange(tableState.pageIndex - 1);
                        }}
                        totalPage={totalPage}
                        pageIndex={tableState.pageIndex}
                      />
                    </div>
                  </>
                )}
                {loading && isListView && <CommonTableLoader />}
                {loading && !isListView && (
                  <Row className="row-cols-1 row-cols-md-2 row-cols-lg-4">
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => {
                      return (
                        <Col key={`content-${index}`}>
                          <ContentCardLoader />
                        </Col>
                      );
                    })}
                  </Row>
                )}
              </div>
            </div>
          </Modal.Body>
        )}
      </Modal>
      {fileDetails?.file_url && filePreviewShowModal && (
        <FilePreviewModal
          mediaType={fileDetails?.type}
          show={filePreviewShowModal}
          viewModalClose={() => setFilePreviewShowModal(false)}
          title={fileDetails?.title}
          brand={fileDetails?.brand}
          createdBy={fileDetails?.createdBy}
          description={fileDetails?.description}
          icon={fileDetails?.icon}
          file_url={fileDetails?.file_url}
        />
      )}
    </>
  );
};

export default memo(CreateContentStudioModal);
