import Skeleton from "../../components/skeleton";
import { Fragment } from "react";

//Componets

import {
  OverlayTrigger,
  Card,
  Tooltip,
  Dropdown,
  Button,
  Col,
  Row,
  Accordion,
} from "react-bootstrap";

const FaqLoader = () => {
  return (
    <Fragment>
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <Skeleton variant="text" width={"20%"} height={20} />
          <Skeleton variant="rounded" width={"25%"} height={40} />
        </Card.Header>
        <Card.Body>
          <div className="">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <Skeleton variant="text" width="30%" height={20} />
                </Accordion.Header>
                <Accordion.Body>
                  <Skeleton
                    animation="wave"
                    variant="text"
                    width="100%"
                    height={20}
                  />
                  <Skeleton
                    animation="wave"
                    variant="text"
                    width="100%"
                    height={20}
                  />
                  <Skeleton
                    animation="wave"
                    variant="text"
                    width="100%"
                    height={20}
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <Skeleton variant="text" width="30%" height={20} />
                </Accordion.Header>
                <Accordion.Body>
                  <Skeleton
                    animation="wave"
                    variant="text"
                    width="100%"
                    height={20}
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <Skeleton variant="text" width="30%" height={20} />
                </Accordion.Header>
                <Accordion.Body>
                  <Skeleton
                    animation="wave"
                    variant="text"
                    width="100%"
                    height={20}
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <Skeleton variant="text" width="30%" height={20} />
                </Accordion.Header>
                <Accordion.Body>
                  <Skeleton
                    animation="wave"
                    variant="text"
                    width="100%"
                    height={20}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default FaqLoader;
