import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../api/instances/axiosInstance";
import {
  GET_ACTION_DETAILS,
  GET_ACTION_EDIT,
} from "../query-constant/action-constant";
import {
  ACTION_DETAILS_GET,
  EDIT_ACTION_GET,
} from "../../api/endpoints/action-endpoints";

const staleTime = 300000;
const cacheTime = 600000;
export const useFetchActionDetails = (id) => {
  return useQuery(
    [GET_ACTION_DETAILS, id],
    () => axiosInstance.get(ACTION_DETAILS_GET + id),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
    }
  );
};
export const useFetchActionEdit = (id) => {
  return useQuery(
    [GET_ACTION_EDIT, id],
    () => axiosInstance.get(EDIT_ACTION_GET + id),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
    }
  );
};
