import {
  memo,
  Fragment,
  useState,
  useRef,
  useEffect,
  useCallback,
} from "react";

//react-bootstrap
import { Button, Card, Col, Form, Row, Modal, Badge } from "react-bootstrap";
//components

import ReactTable from "../../../../../lib/table/react-table";
import { findIcon } from "../../../../dashboard/icons";
import TemplateFilter from "../../../../../components/common-components/template-filter";
import useRedirect from "../../../../../components/custom-hooks/use-redirect";
import postService from "../../../../../api/services/post-service";
import FilterBar from "../../../../../components/filter-bar";
import {
  EMAIL_TEMPLATE_DELETE,
  TEMPLATE_VARIABLE_ADD,
  TEMPLATE_VARIABLE_DELETE,
  TEMPLATE_VARIABLE_DETAILS_GET,
  TEMPLATE_VARIABLE_LIST_GET,
  TEMPLATE_VARIABLE_UPDATE,
} from "../../../../../api/endpoints/template-management-endpoints";
import ActionButtonBar from "../../../../../components/partials/common/action-button-bar";
import {
  changeTableStateToPayload,
  statusToBg,
} from "../../../../../common/utils/utils";
import DeleteModal from "../../../../../components/partials/common/delete-modal";
import { getService } from "../../../../../api/services/get-services";
import ViewModal from "../../../../../components/partials/common/view-modal";
import * as yup from "yup";
import { useFormik } from "formik";
// import { BasicQuill } from "../../../plugins/pages/quill-editor";
import toast from "react-hot-toast";
import ReactQuill from "../../../../../components/partials/common/react-quill";
import PaginationLoader from "../../../../../components/loader/pagination-loader";
import CommonTableLoader from "../../../../../skeleton/common-table-loader";
import PageNotFound from "../../../../PageNotFound";

const AllVariables = () => {
  const [modalShow, setModalShow] = useState(false);
  const [variableList, setVariableList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [viewVariableData, setViewVariableData] = useState({});
  const [viewVariableModalShow, setVariableViewModalShow] = useState(false);
  const [createModalShow, setCreateModalShow] = useState(false);
  const [myValidationSchema, setMyValidationSchema] = useState({});
  const [variableValue, setVariableValue] = useState("");
  const [UpdateVariableId, setUpdateUpdateVariableId] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const { redirectTo } = useRedirect();

  const columns = [
    { Header: "Id", accessor: "variable_id" },
    { Header: "Variable Name", accessor: "variable_name" },
    // {
    //   Header: "Variable Type",
    //   accessor: "variable_type",
    //   Cell: ({ row }) => {
    //     return row.values.variable_type
    //       ? row.values.variable_type.value
    //       : "N/A";
    //   },
    // },
    {
      Header: "Created By",
      accessor: "created_by",
      Cell: ({ row }) => {
        return row.values.created_by ? row.values.created_by.name : "N/A";
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => (
        <span className="d-flex align-items-start justify-content-start">
          {row.values.status.value}
        </span>
      ),
    },
    // { Header: "Variable Value", accessor: "variable_value" },
    {
      Header: "Action",
      accessor: "iconAction",
      disableFilters: true,
      Cell: ({ row }) => (
        <ActionButtonBar
          row={row}
          onView={() => {
            viewVariableModal(row?.original);
          }}
          onEdit={() => {
            onEditVariableClick(row?.original);
          }}
          onDelete={() => {
            setSelectedTemplate(row?.original.variable_id);
            modalOpen();
          }}
        />
      ),
    },
  ];
  const onEditVariableClick = (variable) => {
    console.log("variable", variable);
    setIsEdit(true);
    setUpdateUpdateVariableId(variable?.variable_id);
    //set formik values
    setValues({
      variable_name: variable.variable_name,
      variable_type: variable.variable_type?.value.toLowerCase(),
      variable_value: variable.variable_value,
      status: variable.status?.id,
    });
    setCreateModalShow(true);
  };
  const dropdownList = [
    "Title",
    "Approved By",
    "Content Name",
    "Brand",
    "Status",
    "Approved By",
    "Creation Date",
    "Action",
  ];
  const post_json = useRef({
    // default: "all",

    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      variable_id: "desc",
    },
  });

  const fetchVariableTemplate = async () => {
    try {
      // setLoading(true);
      const response = await postService(
        TEMPLATE_VARIABLE_LIST_GET,
        post_json.current
      );
      if (response.isError) {
        toast.error(response?.error);
        setLoading(false);
      } else {
        // console.log("Variable template service ", response.data.data.rows);
        // console.log("COLUMNS", Object.keys(response.data.data.rows[0]));
        if (response.data.data.rows.length > 0) {
          setVariableList(response.data.data.rows);
          setTotalCount(response.data.data.totalCount);
          setLoading(false);
        } else {
          setVariableList([]);
          setTotalCount(0);
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
      // console.log("USER_SERVICE_ERROR", error);
    }
  };
  useEffect(() => {
    fetchVariableTemplate();
  }, []);

  const onTableStateChange = useCallback(
    async (state) => {
      let paylod = changeTableStateToPayload(state);
      post_json.current = paylod;
      fetchVariableTemplate();
    },
    [variableList, loading, post_json.current]
  );
  const modalOpen = () => {
    setModalShow(true);
  };
  const modalClose = useCallback(() => {
    setSelectedTemplate(null);
    setModalShow(false);
  }, [modalShow]);

  const deleteVariableTemplate = async () => {
    try {
      setLoading(true);
      const response = await getService(
        `${TEMPLATE_VARIABLE_DELETE}/${selectedTemplate}`,
        {}
      );
      if (response) {
        setModalShow(false);
        fetchVariableTemplate();
      }
      if (response.isError) {
        toast.error(response?.error);
      }
    } catch (error) {
      // console.log("USER_SERVICE_ERROR", error);
    }
  };

  const onRefreshClick = () => {
    setVariableList([]);
    fetchVariableTemplate();
  };

  const viewVariableModal = async (data) => {
    console.log("data", data);
    let { variable_id } = data;
    console.log("variable_id", data.variable_id);
    let variableData = {};
    try {
      const response = await getService(
        `${TEMPLATE_VARIABLE_DETAILS_GET}/${data?.variable_id}`,
        {}
      );

      console.log("response details", response);
      if (response.isError) {
        toast.error(response?.error);
      }
      if (response.data.success) {
        let variable_name = data.variable_name.replace(/\b\w/g, (match) =>
          match.toUpperCase()
        );
        // console.log("variable_name", variable_name);
        let MyData = response.data?.data;
        // console.log("MyData", MyData);
        variableData = {
          "Variable Id": MyData.variable_id ?? "",
          "Variable Name": variable_name ?? "",
          "Created By": MyData.created_by?.name ?? "",
          "Variable Type": MyData.variable_type?.value ?? "",
          "Variable Value": MyData.variable_value ?? "",
          Status: MyData.status?.value ?? "",
        };
        console.log("variableData", variableData);
        setViewVariableData(variableData && variableData);
        setVariableViewModalShow(true);
      } else {
        // console.log("response details error", response);
      }
    } catch (error) {
      // console.log("USER_SERVICE_ERROR", error);
    }
  };

  const viewVariableModalClose = () => {
    setVariableViewModalShow(false);
  };

  let validationSchemaAll = {
    variable_name: yup.string().required("Variable Name is required"),
    variable_type: yup.string().required("Variable Type is required"),
    variable_value: yup.string().required("Variable Value is required"),
    status: yup.number(),
  };
  let validationSchemaDynamic = {
    variable_name: yup.string().required("Variable Name is required"),
    variable_type: yup.string().required("Variable Type is required"),
    variable_value: yup.string(),
    status: yup.number(),
  };
  //yup and formik for validation of form fields of priority_name
  const validationSchema = yup.object().shape(myValidationSchema);

  //formik
  const formik = useFormik({
    initialValues: {
      variable_name: "",
      variable_type: "database",
      variable_value: "",
      status: 1,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("values", values);
      if (!isEdit) {
        delete values.status;
      }
      try {
        const response = await postService(
          isEdit
            ? TEMPLATE_VARIABLE_UPDATE + `/${UpdateVariableId}`
            : TEMPLATE_VARIABLE_ADD,
          values
        );
        if (response.isError) {
          toast.error(response?.error);
        }
        if (response?.data?.success) {
          // console.log("response add template success", response);
          toast.success(response.data?.message);
          setCreateModalShow(false);
          setIsEdit(false);
          fetchVariableTemplate();
        } else {
          setCreateModalShow(false);
          setIsEdit(false);
        }
      } catch (error) {
        // console.log("ACTION_TYPE_SERVICE_ERROR", error);
      }
    },
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    resetForm,
    setErrors,
  } = formik;

  const handleCreate = () => {
    resetForm();
    setCreateModalShow(true);
  };

  const handleVariableStatus = (e) => {
    setValues({ ...values, status: parseInt(e.target.value) });
  };

  useEffect(() => {
    // console.log("values.variable_type", values.variable_type);
    // console.log("variableValue", values.variable_value);
    if (values.variable_type === "dynamic") {
      setMyValidationSchema(validationSchemaDynamic);
      setVariableValue(values.variable_value);
      setValues({ ...values, variable_value: "" });
    } else {
      setValues({
        ...values,
        variable_value: variableValue ? variableValue : "",
      });
      setMyValidationSchema(validationSchemaAll);
    }
  }, [values.variable_type]);
  const getQuillText = (data) => setValues({ ...values, variable_value: data });
  return (
    <Fragment>
      <Row>
        <Col sm="12" className="p-0 px-md-3">
          <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
            <div className="d-flex flex-column">
              <h3 className="m-0">Variable Templates</h3>
            </div>
            <FilterBar />
            <Button
              type="button"
              variant="success"
              className="d-flex align-items-center justify-content-center add_floating__button position-fixed rounded-circle p-1 avatar-80 lh-1 border border-white border-2"
              onClick={() => handleCreate()}
            >
              {findIcon("Plus", "outline", "32")}
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm="12" className="p-0 px-md-3">
          <Card>
            {/* <Card.Header className="d-flex justify-content-between align-items-center">
              <div className="header-title">
                <h4 className="card-title">Variable Templates</h4>
              </div>
              <Button
                type="button"
                variant="success"
                className="d-flex align-items-center justify-content-center add_floating__button position-fixed rounded-circle p-1 avatar-80 lh-1 border border-white border-2"
                onClick={() => handleCreate()}
              >
                {findIcon("Plus", "outline", "32")}
              </Button>
            </Card.Header> */}

            <Card.Body className="p-2 p-md-4">
              <div className="table-responsive">
                {variableList.length > 0 && !loading ? (
                  <ReactTable
                    data={variableList}
                    columns={columns}
                    recordsTotal={totalCount}
                    onTableStateChange={onTableStateChange}
                    initialState={post_json.current}
                  />
                ) : loading ? (
                  <CommonTableLoader />
                ) : (
                  <PageNotFound />
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ViewModal
        title={"View VARIABLE Template Information"}
        show={viewVariableModalShow}
        modalClose={viewVariableModalClose}
        data={viewVariableData}
      />
      <DeleteModal
        show={modalShow}
        modalClose={modalClose}
        onSubmit={deleteVariableTemplate}
      />
      <Modal
        show={createModalShow}
        onHide={() => {
          setCreateModalShow(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Variable</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* Variable Name */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Variable Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Variable Name"
                name="variable_name"
                onChange={handleChange}
                value={values.variable_name}
                onBlur={handleBlur}
                isInvalid={touched.variable_name && errors.variable_name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.variable_name}
              </Form.Control.Feedback>
            </Form.Group>
            {/* Variable Type */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Variable Type</Form.Label>
              <Form.Select
                type="type"
                name="variable_type"
                value={values.variable_type}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.variable_type && errors.variable_type}
              >
                <option value="database">Database</option>
                <option value="custom">Custom</option>
                <option value="dynamic">Dynamic</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.variable_type}
              </Form.Control.Feedback>
            </Form.Group>
            {/* Variable Value */}
            {values.variable_type != "dynamic" && (
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Variable Value</Form.Label>
                {values.variable_type === "database" ||
                  (values.variable_type === "custom" && (
                    <>
                      <Form.Control
                        type="text"
                        placeholder="Enter Variable Value"
                        name="variable_value"
                        onChange={handleChange}
                        value={values.variable_value}
                        onBlur={handleBlur}
                        isInvalid={
                          touched.variable_value && errors.variable_value
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.variable_value}
                      </Form.Control.Feedback>
                    </>
                  ))}
              </Form.Group>
            )}
            {/* Variable status */}
            {isEdit && (
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Variable Status</Form.Label>
                <Form.Select
                  type="type"
                  name="status"
                  value={values.status}
                  onChange={handleVariableStatus}
                >
                  <option value={1}>Enable</option>
                  <option value={2}>Disable</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.status}
                </Form.Control.Feedback>
              </Form.Group>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setCreateModalShow(false);
              setIsEdit(false);
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            {loading ? <PaginationLoader /> : "Save Changes"}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default AllVariables;
