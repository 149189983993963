import { useQuery, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "../../api/instances/axiosInstance";
import { GET_MENU_LIST } from "../query-constant/common-constant";
import { MENU_LIST } from "../../api/endpoints/common-endpoints";

const staleTime = 300000;
const cacheTime = 600000;

export const useGetMenuList = (enabled = true) => {
  return useQuery([GET_MENU_LIST], () => axiosInstance.get(MENU_LIST), {
    enabled: !!enabled,
    refetchOnWindowFocus: false,
    cacheTime,
    staleTime,
    select: (res) => res?.data?.data ?? [],
  });
};
