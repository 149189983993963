import React from "react";
import { useParams } from "react-router-dom";

const UserDashboard = () => {
  const { type } = useParams();
  console.log("type", type);
  return <div>User Dashboard {type}</div>;
};

export default UserDashboard;
