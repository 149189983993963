import React, {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button, Row, Form, Table, Col, Card } from "react-bootstrap";
import useRedirect from "../../../../../components/custom-hooks/use-redirect";
import postService from "../../../../../api/services/post-service";
import {
  TEMPLATE_BANK_DELETE_GET,
  TEMPLATE_BANK_LIST,
  TEMPLATE_CATEGORY_DELETE_GET,
} from "../../../../../api/endpoints/template-management-endpoints";
import { changeTableStateToPayload } from "../../../../../common/utils/utils";
import ActionButtonBar from "../../../../../components/partials/common/action-button-bar";
import toast from "react-hot-toast";
import { findIcon } from "../../../../dashboard/icons";
import ReactTable from "../../../../../lib/table/react-table";
import DeleteModal from "../../../../../components/partials/common/delete-modal";
import { getService } from "../../../../../api/services/get-services";
import CommonTableLoader from "../../../../../skeleton/common-table-loader";
import PageNotFound from "../../../../PageNotFound";
import Skeleton from "../../../../../components/skeleton";

const BankListing = () => {
  const { redirectTo } = useRedirect();
  const [bankList, setBankList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loader, showLoader] = useState(true);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [selectedBank, setSelectedBank] = useState(null);
  const [createModalShow, setCreateModalShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [UpdateCategoryId, setUpdateUpdateCategoryId] = useState("");

  const columns = [
    { Header: "Id", accessor: "bank_id" },
    { Header: "Name", accessor: "bank_name" },
    {
      Header: "Created By",
      accessor: "created_by",
      Cell: ({ row }) => {
        // console.log('row', row);
        return row.values?.created_by.name
          ? row.values?.created_by.name
          : "N/A";
      },
    },
    {
      Header: "Action",
      accessor: "iconAction",
      disableFilters: true,
      Cell: ({ row }) => (
        <ActionButtonBar
          row={row}
          onView={() => {
            {
              redirectTo(
                "template-management/bank/details/" + row.original.bank_id
              );
            }
          }}
          onEdit={() => {
            {
              redirectTo(
                "template-management/create-bank/" + row.original.bank_id
              );
            }
          }}
          onDelete={() => {
            setSelectedBank(row?.original.bank_id);
            deleteModalOpen();
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    fetchBankList();
  }, []);

  const post_json = useRef({
    // "default": "all",
    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      bank_id: "desc",
    },
  });

  const fetchBankList = async () => {
    const response = await postService(TEMPLATE_BANK_LIST, post_json.current);
    // showLoader(true);
    if (response.isError) {
      showLoader(false);
      toast(response.error);
    } else {
      // console.log('bank list response', response);
      if (response.data?.data?.rows.length > 0) {
        setBankList(response?.data?.data?.rows);
        setTotalCount(response?.data?.data?.totalCount);
        showLoader(false);
      } else {
        setBankList([]);
        setTotalCount(0);
        showLoader(false);
      }
    }
  };

  const onTableStateChange = useCallback(
    async (state) => {
      let paylod = changeTableStateToPayload(state);
      post_json.current = paylod;
      fetchBankList();
    },
    [bankList, loader, post_json.current]
  );

  const deleteModalOpen = () => {
    setDeleteModalShow(true);
  };

  const deleteModalClose = useCallback(() => {
    setSelectedBank(null);
    setDeleteModalShow(false);
  }, [deleteModalShow]);

  const deleteVariableTemplate = async () => {
    // console.log('selectedTemplate', selectedBank);
    try {
      showLoader(true);
      const response = await getService(
        `${TEMPLATE_BANK_DELETE_GET}/${selectedBank}`,
        {}
      );
      if (response) {
        // console.log('response', response);
        toast.success(response?.data?.message);
        showLoader(false);
        setDeleteModalShow(false);
        fetchBankList();
      }
      if (response.isError) {
        toast.error(response?.error);
      }
    } catch (error) {
      showLoader(false);
      // console.log("USER_SERVICE_ERROR", error);
    }
  };

  return (
    <Fragment>
      <Row>
        <Col sm="12" className="p-0 px-md-3">
          <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
            <div className="d-flex flex-column">
              <h4 className="m-0">All Templates</h4>
            </div>
            <Button
              type="button"
              variant="success"
              className="d-flex align-items-center justify-content-center add_floating__button position-fixed rounded-circle p-1 avatar-80 lh-1 border border-white border-2"
              onClick={() => redirectTo("template-management/create-bank")}
            >
              {findIcon("Plus", "outline", "32")}
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm="12" className="p-0 px-md-3">
          <Card>
            {/* <Card.Header className="d-flex justify-content-between align-items-center">
              <div className="header-title">
                <h4 className="card-title">All Templates</h4>
              </div>
              <Button
                type="button"
                variant="success"
                className="d-flex align-items-center justify-content-center add_floating__button position-fixed rounded-circle p-1 avatar-80 lh-1 border border-white border-2"
                onClick={() => redirectTo("template-management/create-bank")}
              >
                {findIcon("Plus", "outline", "32")}
              </Button>
            </Card.Header> */}
            <Card.Body className="p-2 p-md-4">
              <div className="table-responsive">
                {!loader ? (
                  bankList?.length > 0 ? (
                    <ReactTable
                      data={bankList}
                      columns={columns}
                      recordsTotal={totalCount}
                      onTableStateChange={onTableStateChange}
                      initialState={post_json.current}
                      recordsPerPage={10}
                      isRowSelectionAvailable={false}
                    />
                  ) : (
                    <PageNotFound />
                  )
                ) : (
                  <CommonTableLoader />
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <DeleteModal
        show={deleteModalShow}
        modalClose={deleteModalClose}
        onSubmit={deleteVariableTemplate}
      />
    </Fragment>
  );
};

export default BankListing;
