import React from "react";

// layout

import Default from "../../../../../layouts/dashboard/default";
import Channel from "../pages/all-channel";


export const ChannelRouter = [
  {
    path: "/master-management/channel",
    element: <Default />,
    children: [
      {
        path: "all",
        element: <Channel />,
      },
    ],
  },
];