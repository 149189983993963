import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button, Card, Col, Form, FormCheck, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import * as yup from "yup";
import {
  GET_SHORT_LINK_ADD,
  GET_SHORT_LINK_CREATE,
} from "../../../../api/endpoints/short-link-endpoints";
import { getShortLinkService } from "../../../../api/services/get-short-link-service";
import postShortLinkService from "../../../../api/services/post-short-link-service";
import useRedirect from "../../../../components/custom-hooks/use-redirect";

const ShortLinkCreate = () => {
  const [showPreviewContent, setShowPreviewContent] = useState(false);
  const [typeList, setTypeList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const { redirectTo } = useRedirect();
  useEffect(() => {
    getSlServiceAndType();
  }, []);

  const validationSchema = yup.object().shape({
    base_url_id: yup.string().required("Please Select Domain"),
    custom_url: yup.string().when("base_url_id", (base_url_id, schema) => {
      if (base_url_id == 1) {
        return schema.required("Custom Url is required");
      }
      return schema;
    }),
    link_type: yup.string().when("base_url_id", (base_url_id, schema) => {
      if (base_url_id != 1) {
        return schema.required("Please Select Link Type");
      }
      return schema;
    }), //share or autoauth
    content_category_id: yup
      .string()
      .when("base_url_id", (base_url_id, schema) => {
        if (base_url_id != 1) {
          return schema.required("Please Select Content Category");
        }
        return schema;
      }),
    content_id: yup
      .string()
      .when("base_url_id", (base_url_id, schema) => {
        if (base_url_id != 1) {
          return schema.required("Page Id is required");
        }
        return schema;
      })
      .matches(
        /^(\d+)?$/,
        "Please enter a valid page ID containing only numbers."
      ),
    campaign: yup.string().required("Campaign is required"),
    tag: yup.string().required("Tag is required"),
    webhook: yup.string().required("Webhook Url is required"),
    preview: 0,
    og_title: yup.string().when("preview", (preview, schema) => {
      if (preview == 1) {
        return schema.required("OG Title is required");
      }
      return schema;
    }),
    og_description: yup.string().when("preview", (preview, schema) => {
      if (preview == 1) {
        return schema.required("OG Description is required");
      }
      return schema;
    }),
    og_image: yup.string().when("preview", (preview, schema) => {
      if (preview == 1) {
        return schema.required("OG Image Link is required");
      }
      return schema;
    }),
    og_type: yup.string().when("preview", (preview, schema) => {
      if (preview == 1) {
        return schema.required("OG Type is required");
      }
      return schema;
    }), // article, website
  });
  //formik
  const formik = useFormik({
    initialValues: {
      base_url_id: "",
      link_type: "", //share or autoauth
      content_category_id: "",
      content_id: "",
      campaign: "",
      tag: "",
      preview: 0,
      og_title: "",
      og_description: "",
      og_image: "",
      og_type: "", // article, website
      webhook: "",
      custom_url: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let postData = {
        ...values,
        content_id: values.content_id == "" ? 0 : values.content_id,
        custom_url: values.base_url_id == 1 ? values.custom_url : "",
        link_type: values.base_url_id == 1 ? 0 : values.link_type,
        content_category_id:
          values.base_url_id == 1 ? 0 : values.content_category_id,
      };
      console.log("values===>", postData);
      try {
        let response = await postShortLinkService(GET_SHORT_LINK_ADD, postData);
        if (response?.data?.success) {
          toast.success(response?.data?.message);
          redirectTo("short-link/listing");
        } else {
          toast.error(response?.data?.message);
        }
      } catch (error) {
        console.log("SHORT_LINK_SERVICE_ERROR", error);
      }
    },
  });

  const { values, errors, handleSubmit, setValues } = formik;

  const changeSwichHandler = () => setShowPreviewContent(!showPreviewContent);
  const getSlServiceAndType = async () => {
    let response = await getShortLinkService(GET_SHORT_LINK_CREATE);
    if (response?.data?.success) {
      let getShortLink = response?.data?.data;
      setTypeList(getShortLink?.base_url);
      setCategoriesList(getShortLink?.content_category);
    }
  };
  const getShortLinkData = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const previewHandler = (e) => {
    changeSwichHandler();
    if (e.target.checked) {
      setValues({ ...values, [e.target.name]: 1 });
    } else {
      setValues({
        ...values,
        [e.target.name]: 0,
        og_title: "",
        og_description: "",
        og_image: "",
        og_type: "", // article, website
      });
    }
  };
  console.log("TYPE AND SERVICE", values);
  return (
    <>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <div className="d-flex flex-column">
          <h3 className="m-0">Create Short Link</h3>
        </div>
      </div>

      <Card>
        <Card.Body>
          <Row>
            <Col lg="6" className="mb-4">
              <h6 className="fs-6 fw-400 gray-300 mb-3">
                Domain<span className="text-danger">*</span>{" "}
              </h6>
              <Form.Floating className="custom-form-floating">
                <Form.Select
                  className=""
                  id="floatingInput1"
                  name="base_url_id"
                  value={values.base_url_id ?? ""}
                  onChange={(e) => getShortLinkData(e)}
                  isInvalid={errors.base_url_id}
                >
                  <option value="" selected hidden>
                    {" "}
                    Please Select Domain
                  </option>
                  {typeList &&
                    typeList?.length > 0 &&
                    typeList?.map((_t) => (
                      <option value={_t.id}>{_t.name}</option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.base_url_id}
                </Form.Control.Feedback>
              </Form.Floating>
            </Col>
            {values.base_url_id == 1 ? (
              <Col lg="6" className="mb-4">
                {" "}
                <h6 className="fs-6 fw-400 gray-300 mb-3">
                  Custom Url<span className="text-danger">*</span>{" "}
                </h6>
                <Form.Floating className="custom-form-floating">
                  <Form.Control
                    type="text"
                    className=""
                    value={values.custom_url ?? ""}
                    placeholder="Enter your Custom Url"
                    name="custom_url"
                    onChange={(e) => getShortLinkData(e)}
                    isInvalid={errors.custom_url}
                  />
                  <label htmlFor="floatingInput">Enter your Custom Url</label>
                  <Form.Control.Feedback type="invalid">
                    {errors.custom_url}
                  </Form.Control.Feedback>
                </Form.Floating>
              </Col>
            ) : (
              <>
                <Col lg="6" className="mb-4">
                  <h6 className="fs-6 fw-400 gray-300 mb-3">
                    Link Type<span className="text-danger">*</span>{" "}
                  </h6>
                  <Form.Floating className="custom-form-floating">
                    <Form.Select
                      className=""
                      id="floatingInput1"
                      name="link_type"
                      value={values.link_type ?? ""}
                      onChange={(e) => getShortLinkData(e)}
                      isInvalid={errors.link_type}
                    >
                      <option value="" selected hidden>
                        {" "}
                        Please Select Link Type
                      </option>
                      <option value="Auto Auth">Auto Auth</option>
                      <option value="Share">Share</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.link_type}
                    </Form.Control.Feedback>
                  </Form.Floating>
                </Col>
                <Col lg="6" className="mb-4">
                  <h6 className="fs-6 fw-400 gray-300 mb-3">
                    Content Category<span className="text-danger">*</span>{" "}
                  </h6>
                  <Form.Floating className="custom-form-floating">
                    <Form.Select
                      className=""
                      id="floatingInput1"
                      name="content_category_id"
                      value={values.content_category_id ?? ""}
                      isInvalid={errors.content_category_id}
                      onChange={(e) => getShortLinkData(e)}
                    >
                      <option value={0} selected hidden>
                        {" "}
                        Please Select Content Category
                      </option>
                      {categoriesList &&
                        categoriesList?.length > 0 &&
                        categoriesList?.map((_t) => (
                          <option value={_t?.content_category_id}>
                            {_t?.content_category_name}
                          </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.content_category_id}
                    </Form.Control.Feedback>
                  </Form.Floating>
                </Col>
                <Col lg="6" className="mb-4">
                  {" "}
                  <h6 className="fs-6 fw-400 gray-300 mb-3">
                    Page Id<span className="text-danger">*</span>{" "}
                  </h6>
                  <Form.Floating className="custom-form-floating">
                    <Form.Control
                      type="text"
                      className=""
                      // autoComplete="text"
                      placeholder="Enter your Page Id"
                      name="content_id"
                      value={values.content_id ?? ""}
                      onChange={(e) => getShortLinkData(e)}
                      isInvalid={errors.content_id}
                    />
                    <label htmlFor="floatingInput">Enter your Page Id</label>
                    <Form.Control.Feedback type="invalid">
                      {errors.content_id}
                    </Form.Control.Feedback>
                  </Form.Floating>
                </Col>
                {/* <Col lg="6" className="mb-4">
                  <h6 className="fs-6 fw-400 gray-300 mb-3">
                    User Name<span className="text-danger">*</span>{" "}
                  </h6>
                  <Form.Floating className="custom-form-floating">
                    <Form.Control
                      type="text"
                      className=""
                      value={shortLinkData.user_name ?? ""}
                      placeholder="Enter your user name"
                      name="user_name"
                      onChange={(e) => getShortLinkData(e)}
                    />
                    <label htmlFor="floatingInput">Enter your user name</label>
                    <Form.Control.Feedback type="invalid">
                    </Form.Control.Feedback>
                  </Form.Floating>
                </Col> */}
              </>
            )}
            <Col lg="6" className="mb-4">
              {" "}
              <h6 className="fs-6 fw-400 gray-300 mb-3">
                Campaign<span className="text-danger">*</span>{" "}
              </h6>
              <Form.Floating className="custom-form-floating">
                <Form.Control
                  type="text"
                  className=""
                  value={values.campaign ?? ""}
                  placeholder="Enter your Campaign Name"
                  name="campaign"
                  onChange={(e) => getShortLinkData(e)}
                  isInvalid={errors.campaign}
                />
                <label htmlFor="floatingInput">Enter your Campaign Name</label>
                <Form.Control.Feedback type="invalid">
                  {errors.campaign}
                </Form.Control.Feedback>
              </Form.Floating>
            </Col>

            <Col lg="6" className="mb-4">
              <h6 className="fs-6 fw-400 gray-300 mb-3">Tag</h6>
              <Form.Floating className="custom-form-floating">
                <Form.Control
                  type="text"
                  className=""
                  value={values.tag ?? ""}
                  placeholder="Enter your tags"
                  name="tag"
                  onChange={(e) => getShortLinkData(e)}
                  isInvalid={errors.tag}
                />
                <label htmlFor="floatingInput">Enter your tags</label>
                <Form.Control.Feedback type="invalid">
                  {errors.tag}
                </Form.Control.Feedback>
              </Form.Floating>
            </Col>
            <Col lg="6" className="mb-4">
              <h6 className="fs-6 fw-400 gray-300 mb-3">
                Webhook<span className="text-danger">*</span>{" "}
              </h6>
              <Form.Floating className="custom-form-floating">
                <Form.Control
                  type="text"
                  className=""
                  // autoComplete="password"
                  placeholder="Enter your webhook"
                  value={values.webhook ?? ""}
                  name="webhook"
                  onChange={(e) => getShortLinkData(e)}
                  isInvalid={errors.webhook}
                />
                <label htmlFor="floatingInput">Enter Your Webhook Url</label>
                <Form.Control.Feedback type="invalid">
                  {errors.webhook}
                </Form.Control.Feedback>
              </Form.Floating>
            </Col>
            <div className="d-flex justify-content-start align-items-center mb-4">
              <h6 className="m-0 me-3">Preview Content</h6>
              <Form.Check className="form-switch">
                <FormCheck.Input
                  className="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckChecked"
                  value={values.preview ?? 0}
                  checked={values.preview == 1 ? true : false}
                  name="preview"
                  onChange={(e) => previewHandler(e)}
                />
              </Form.Check>
            </div>
          </Row>

          {showPreviewContent && (
            <Row>
              <Col lg="6" className="mb-4">
                <h6 className="fs-6 fw-400 gray-300 mb-3">
                  OG Title<span className="text-danger">*</span>{" "}
                </h6>
                <Form.Floating className="custom-form-floating">
                  <Form.Control
                    type="text"
                    className=""
                    value={values.og_title ?? ""}
                    placeholder="Enter your OG Title"
                    name="og_title"
                    onChange={(e) => getShortLinkData(e)}
                    isInvalid={errors.og_title}
                  />
                  <label htmlFor="floatingInput">Enter your OG Title</label>
                  <Form.Control.Feedback type="invalid">
                    {errors.og_title}
                  </Form.Control.Feedback>
                </Form.Floating>
              </Col>
              <Col lg="6" className="mb-4">
                <h6 className="fs-6 fw-400 gray-300 mb-3">
                  OG Description<span className="text-danger">*</span>{" "}
                </h6>
                <Form.Floating className="custom-form-floating">
                  <Form.Control
                    type="text"
                    className=""
                    placeholder="Enter your OG Description"
                    value={values.og_description ?? ""}
                    name="og_description"
                    onChange={(e) => getShortLinkData(e)}
                    isInvalid={errors.og_description}
                  />
                  <label htmlFor="floatingInput">
                    Enter your OG description
                  </label>
                  <Form.Control.Feedback type="invalid">
                    {errors.og_description}
                  </Form.Control.Feedback>
                </Form.Floating>
              </Col>
              <Col lg="6" className="mb-4">
                <h6 className="fs-6 fw-400 gray-300 mb-3">
                  OG Image Link<span className="text-danger">*</span>{" "}
                </h6>
                <Form.Floating className="custom-form-floating">
                  <Form.Control
                    type="text"
                    className=""
                    // autoComplete="password"
                    placeholder="Enter your OG Image Link"
                    value={values.og_image ?? ""}
                    name="og_image"
                    onChange={(e) => getShortLinkData(e)}
                    isInvalid={errors.og_image}
                  />
                  <label htmlFor="floatingInput">
                    Enter your OG Image Link
                  </label>
                  <Form.Control.Feedback type="invalid">
                    {errors.og_image}
                  </Form.Control.Feedback>
                </Form.Floating>
              </Col>
              <Col lg="6" className="mb-4">
                <h6 className="fs-6 fw-400 gray-300 mb-3">
                  OG Type<span className="text-danger">*</span>{" "}
                </h6>
                <Form.Floating className="custom-form-floating">
                  <Form.Select
                    className=""
                    id="floatingInput1"
                    name="og_type"
                    value={values.og_type ?? ""}
                    onChange={(e) => getShortLinkData(e)}
                    isInvalid={errors.og_type}
                  >
                    <option value="" selected hidden>
                      {" "}
                      Please Select OG Type
                    </option>
                    <option value="article">Article</option>
                    <option value="website">Website</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.og_type}
                  </Form.Control.Feedback>
                </Form.Floating>
              </Col>
            </Row>
          )}
          <div className="d-flex justify-content-end">
            <Button
              // disabled={isButtonDisable}
              variant="primary"
              // onClick={getShortLinkSubmit}
              onClick={handleSubmit}
            >
              Get Short Link
            </Button>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default ShortLinkCreate;
