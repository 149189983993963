import { memo, Fragment, useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
// React-bootstrap
import { Row, Col, Form, Button, Container, Image } from "react-bootstrap";

//Components
import Autheffect from "../components/auth-effect";
import Card from "../../../../components/bootstrap/card";
import ciplaUnityLogo from "../../../../assets/images/client-logo/ciplaUnityLogo.svg";

import postService from "../../../../api/services/post-service";
import {
  CHANGE_PASSWORD_POST,
  REFRESH_TOKEN_GET,
  REFRESH_TOKEN_POST,
} from "../../../../api/endpoints/auth-endpoints";
import PaginationLoader from "../../../../components/loader/pagination-loader";
import { updateStorage } from "../../../../utilities/setting";
import { storages } from "../../../../common/utils/utils";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import toast from "react-hot-toast";
import { getService } from "../../../../api/services/get-services";
import { useFormik } from "formik";
import * as yup from "yup";

const ChangePassword = memo(() => {
  const [loader, showLoader] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [tokenValidate, setTokenValidate] = useState(false);
  const { redirectTo } = useRedirect();
  const params = useParams();
  const location = useLocation();

  const initialValues = {
    password: "",
    confirmPassword: "",
  };
  const schema = yup.object().shape({
    password: yup.string().min(6).required("Please enter you password"),
    confirmPassword: yup
      .string()
      .required("Please re-enter you password")
      .oneOf(
        [yup.ref("password"), null],
        "Confirm password not match with password"
      ),
  });
  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (value, action) => {
      changePassword(value, action);
    },
  });

  useEffect(() => {
    checkTokenValidation();
  }, []);

  const checkTokenValidation = async () => {
    let token = params?.token;
    let email = params?.email;
    let response = await postService(
      REFRESH_TOKEN_POST + "/" + token + "/mail/" + email
    );
    if (response.isError) {
      // console.log('here1');
      // setTokenValidate(false);
      showLoader(false);
      toast(response.error);
    } else {
      if (response.data?.success === true) {
        setTokenValidate(true);
      } else {
        setTokenValidate(false);
      }
      showLoader(false);

      //   toast(response.data?.message);
    }
  };

  const changePassword = async (value, action) => {
    let token = params?.token;
    let email = params?.email;

    showLoader(true);

    let formData = new FormData();
    formData.append("email", email);
    formData.append("password", value.password);
    formData.append("password_confirmation", value.confirmPassword);
    formData.append("token", token);

    let response = await postService(CHANGE_PASSWORD_POST, formData);
    if (response.isError) {
      showLoader(false);
      toast(response.error);
    } else {
      showLoader(false);
      toast(response.data?.message);
      if (response.data?.success == true) {
        action.resetForm();
        redirectTo();
      }
    }
  };

  return (
    <Fragment>
      <div className="iq-auth-page">
        <Autheffect />
        <Container>
          <Row className="d-flex align-items-center iq-auth-container justify-content-center">
            <Col lg="7" className="py-3 py-md-4 text-center text-lg-start">
              <Image
                src={ciplaUnityLogo}
                alt="CLIRNET Unity"
                className="ciplaLogo mb-2"
              />
              <h5 className="h5 lh-base">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s,
              </h5>
            </Col>

            {tokenValidate && (
              <Col lg="4">
                <Card>
                  <Card.Body>
                    <h4>Change Password</h4>
                    <p>
                      Enter your email address and we’ll send you an email with
                      instructions to change your password
                    </p>

                    <Form onSubmit={handleSubmit}>
                      <Form.Group>
                        <Form.Label>Enter password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Enter Password"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          isInvalid={!!errors.password}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>Re-Enter password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Re-Enter Password"
                          name="confirmPassword"
                          value={values.confirmPassword}
                          onChange={handleChange}
                          isInvalid={!!errors.confirmPassword}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.confirmPassword}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Button type="submit" className="mt-3">
                        {loader ? <PaginationLoader /> : "Change"}
                      </Button>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            )}
            {!tokenValidate && (
              <Col lg="4">
                <Card>
                  <Card.Body>
                    <h4>Token Validation Problem</h4>
                    <p>Your token got expired.</p>
                  </Card.Body>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </Fragment>
  );
});

ChangePassword.displayName = "ChangePassword";
export default ChangePassword;
