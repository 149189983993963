import React, { useEffect, useRef, useState, useCallback } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { findIcon } from "../../../dashboard/icons";
import Card from "../../../../components/bootstrap/card";
import ReactTable from "../../../../lib/table/react-table"; //
import postService from "../../../../api/services/post-service";
import { CONTENT_STUDIO_LIST_POST } from "../../../../api/endpoints/content-studio-endpoints";
import ViewModal from "../../../../components/partials/common/view-modal";
import CreateContentStudio from "../pages/create-content-studio";
import FilterBar from "../../../../components/filter-bar";
import CreateContentStudioModal from "../component/create-content-studio-modal";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import ActionButtonBar from "../../../../components/partials/common/action-button-bar";
import { changeTableStateToPayload } from "../../../../common/utils/utils";

const ContentStudioJourney = () => {
  const [viewModalShow, setViewModalShow] = useState(false);
  const [templateView, setTemplateView] = useState(false);
  const [step, setStep] = useState(1);
  const [viewContent, setViewContent] = useState({});
  const [templateDetails, setTemplateDetails] = useState(null);
  const [createNewModalShow, setCreateNewModalShow] = useState(false);
  const createNewModalClose = () => setCreateNewModalShow(false);
  const [totalCount, setTotalCount] = useState(0);
  const [contentList, setContentList] = useState([]);

  const { redirectTo } = useRedirect();

  const inputFilters = [
    {
      name: "content_studio_name",

      type: "text",
      placeholder: "Please Enter Content Studio Name",
      label: "Content Studio Name",
    },
    // {
    //   name: "content_name",

    //   type: "text",
    //   placeholder: "Please Enter Content Name",
    //   label: "Content Name",
    // },
    {
      name: "content_type",

      type: "text",
      placeholder: "Please Enter Content Type",
      label: "Content Type",
    },
    {
      name: "variation_no",

      type: "text",
      placeholder: "Number of Variations",
      label: "Number of Variations",
    },
    {
      name: "start_date",

      type: "date",
      placeholder: "Enter Start Date",
      label: "Start Date",
    },
    {
      name: "end_date",

      type: "date",
      placeholder: "Enter End Date",
      label: "End Date",
    },
    {
      name: "status",

      type: "select",
      placeholder: "Status",
      label: "Status",
      options: [
        { value: "1", label: "Active" },
        { value: "2", label: "Inactive" },
      ],
    },
    // {
    //   name: "status",
    //   type: "radio",
    //   placeholder: "Status",
    //   label: "Status",
    //   options: [
    //     { value: "1", label: "Active" },
    //     { value: "2", label: "Inactive" },
    //   ],
    // },
  ];

  const [contentStudioDetails, setContentStudioDetails] = useState({
    content_studio_name: "",
    fileName: "",
    fileImg: "",
    fileType: "",
  });
  const totalCountRef = useRef(0);
  const post_json = useRef({
    default: "all",

    pagination: {
      limit: 10,
      offset: 0,
    },
  });
  const nextStep = () => setStep(2);
  const prevStep = () => setStep(1);
  const templateViewClose = () => {
    setTemplateView(false);
  };
  const deleteContentStudio = () => {
    setStep(1);
  };
  const selectTemplateForContent = (item) => {
    nextStep();
    setContentStudioDetails({
      ...contentStudioDetails,
      fileName: item.fileName,
      fileImg: item.fileImg,
      fileDescription: item.fileDescription,
      fileType: item.fileType,
    });
    createNewModalClose();
    // }
  };
  // const contentList = [
  //   <ContentCard
  //     imageDate="Dec 13, 2020"
  //     media="pdf"
  //     imgName="Postmenopausal Osteoporosis.pdf"
  //     imgLink="just now"
  //     imgChange={pdf}
  //     imgMb="5mb"
  //     docType="PDF"
  //     docName="postmenopausal osteoporosis.pdf"
  //     docDescription="Risk factors associated with postmenopausal osteoporosis"
  //     selectFunction={selectTemplateForContent}
  //   />,
  //   <ContentCard
  //     imageDate="Dec 13, 2020"
  //     media="image"
  //     imgName="Ixekizumab"
  //     imgLink="just now"
  //     imgChange={img1}
  //     imgMb="5mb"
  //     docType="Image"
  //     docName="Ixekizumab"
  //     docDescription="Efficacy of Copellor®(Ixekizumab) for patients with moderate to severe plaque psoriasis"
  //     selectFunction={selectTemplateForContent}
  //   />,
  //   <ContentCard
  //     imageDate="Dec 13, 2020"
  //     media="video"
  //     imgName="POCT.mp4"
  //     imgLink="just now"
  //     imgChange={img1}
  //     imgMb="5mb"
  //     docType="Video"
  //     docName="POCT.mp4"
  //     docDescription="Clinical significance of point-of-care testing (POCT) in diagnostics"
  //     selectFunction={selectTemplateForContent}
  //   />,
  //   <ContentCard
  //     imageDate="Dec 13, 2020"
  //     media="pdf"
  //     imgName="Advance Gastric Cancer.xlsx"
  //     imgLink="2 days ago"
  //     imgChange={excel}
  //     imgMb="2mb"
  //     docType="XLSX"
  //     docName="Advance Gastric Cancer.xlsx"
  //     docDescription="Advance Gastric Cancer - New Updates on ESMO Gastric Guidelines."
  //     selectFunction={selectTemplateForContent}
  //   />,
  // ];
  const getContentStudioName = useCallback(
    (e) => {
      setContentStudioDetails({
        ...contentStudioDetails,
        content_studio_name: e.target.value,
      });
    },
    [contentStudioDetails]
  );
  const viewModal = async (content) => {
    console.log("content===>", content);
    let dataJson = {
      "Content Studio Name": content.content_studio_name,
      "Content Name": content.contentName,
      "Content Type": content.contentType,
      Templates: content.templates,
      "Created on": content.createdOn,
      "Created by": content.created_by,
      Status: content.status,
      // Description: content.content_studio_description,
      // Status: content.status.value,
      // "Created by": content.created_by.name,
    };
    setViewContent(dataJson);
    setViewModalShow(true);
  };
  const viewModalClose = () => {
    setViewModalShow(false);
  };
  const contentStudioListData = [
    {
      content_studio_name: "Content 1",
      contentName: "Infographic",
      contentType: "Image",
      templates: 6,
      createdOn: "2023-05-28",
      created_by: "Amit Joshi",
      status: "Active",
    },

    {
      content_studio_name: "Content 2",
      contentName: "Image",
      contentType: "Image",
      templates: 7,
      createdOn: "2023-05-26",
      created_by: "Indranil Kundu",
      status: "Active",
    },
    {
      content_studio_name: "Content 3",
      contentName: "RTM",
      contentType: "Video",
      templates: 2,
      createdOn: "2023-04-22",
      created_by: "Sumit Mandol",
      status: "Active",
    },
    {
      content_studio_name: "Content 4",
      contentName: "covid-19.pdf",
      contentType: "pdf",
      templates: 4,
      createdOn: "2023-04-20",
      created_by: "Swagata Das",
      status: "Active",
    },
  ];
  const contentStudioListColumns = [
    {
      Header: "Content Studio Name",
      accessor: "content_studio_name",
    },
    // {
    //   Header: "Content Name",
    //   accessor: "contentName",
    // },
    // {
    //   Header: "Content Type",
    //   accessor: "contentType",
    // },
    {
      Header: "Variations",
      accessor: "variations",
      Cell: ({ value }) => Object.keys(value)?.length,
    },
    {
      Header: "Created On",
      accessor: "created_at",
    },
    {
      Header: "Created By",
      accessor: "created_by",
      Cell: ({ value }) => value?.name ?? "None",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => value?.value ?? "None",
    },
    {
      Header: "Action",
      accessor: "Action",
      disableFilters: true,
      Cell: ({ row }) => (
        <ActionButtonBar
          buttons={["view", "edit"]}
          row={row}
          onView={(data) => {
            // setStep(2);
            // setTemplateView(true);
            console.log("row.original", row.original);
            // setTemplateDetails(row.original);
            redirectTo("content-studio/view/" + row.original.content_studio_id);
          }}
          onEdit={() => {
            redirectTo("content-studio/edit/" + row.original.content_studio_id);
          }}
          onDelete={() => {}}
        />
      ),
    },
  ];
  console.log("contentStudioDetails ", contentStudioDetails);
  useEffect(() => {
    fetchContentStudioListing(post_json.current);
  }, []);
  const fetchContentStudioListing = async (data) => {
    try {
      const response = await postService(CONTENT_STUDIO_LIST_POST, data);
      if (response.data.data.rows.length > 0) {
        setContentList(response.data.data.rows);
        setTotalCount(response.data.data.totalCount);
      }
    } catch (error) {
      console.log("ACTION_SERVICE_ERROR", error);
    }
  };
  const onTableStateChange = useCallback(
    async (state) => {
      let payload = changeTableStateToPayload(state);
      post_json.current = payload;
      fetchContentStudioListing(payload);
    },
    [contentList, post_json.current]
  );
  return (
    <>
      {step === 1 && (
        <>
          <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
            <div className="d-flex flex-column">
              <h3 className="m-0">Content Studio - Journey</h3>
            </div>
            <FilterBar inputs={inputFilters} />
            {/* <Button className="d-flex align-items-center gap-2 content-studio__create__button position-relative px-3">
              {findIcon("Plus", "outline", "20")} Create New
            </Button> */}
            <Button
              variant="success"
              className="d-flex align-items-center justify-content-center add_floating__button position-fixed rounded-circle p-1 avatar-80 lh-1 border border-white border-2"
              // onClick={handlecreateNewModalShow}
              onClick={() => {
                // setCreateModalShow(true)
                setCreateNewModalShow(true);
              }}
            >
              {findIcon("Plus", "outline", "32")}
            </Button>
          </div>
          <Row>
            <Col lg="12">
              <Card className="h-100">
                <Card.Body>
                  {contentList.length > 0 && (
                    <ReactTable
                      data={contentList}
                      columns={contentStudioListColumns}
                      recordsTotal={totalCount}
                      onTableStateChange={onTableStateChange}
                      initialState={post_json.current}
                      isRowSelectionAvailable={false}
                      recordsPerPage={10}
                    />
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
      {step === 2 && (
        <CreateContentStudio
          contentStudioDetails={contentStudioDetails}
          templateView={templateView}
          prevStep={prevStep}
          templateDetails={templateDetails}
          templateViewClose={templateViewClose}
          deleteContentStudio={deleteContentStudio}
        />
      )}
      <ViewModal
        title={"View Priority Name Information"}
        show={viewModalShow}
        modalClose={viewModalClose}
        data={viewContent}
      />
      <CreateContentStudioModal
        show={createNewModalShow}
        handleCreateModalClose={createNewModalClose}
        nextStep={nextStep}
        contentList={contentList}
        contentStudioDetails={contentStudioDetails}
        getContentStudioName={getContentStudioName}
      />
    </>
  );
};

export default ContentStudioJourney;
