import { useState } from "react";
import {
  Button,
  FloatingLabel,
  Form,
  FormCheck,
  Modal,
  Table,
} from "react-bootstrap";

const OnboardingModal = ({
  show,
  consentButtons,
  setIsConsentAreaOpen,
  modalClose,
  setConsentLogs,
  consentLogs,
}) => {
  const selectedBtn = consentButtons.find(
    (btn) => btn.modalShow && btn.modalShow == true
  );
  const [isChecked, setIsChecked] = useState(false);
  const [textArea, setTextArea] = useState("");
  const [active, setActive] = useState(false);

  const handleOnChange = (e) => {
    const checked = e.target.checked;
    if (checked) {
      setIsChecked(true);
      // setDisable(false);
      setActive(true);
    } else {
      // setDisable(true);
      setActive(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const today = new Date();
    const date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    const time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    if (isChecked) {
      setIsConsentAreaOpen(true);
      const updatedobject = {
        ...selectedBtn,
        id: consentLogs?.length + 1,
        btntext: selectedBtn.btnText,
        comment: textArea,
        createdAt: date + " " + time,
        commentsname: `Commentator ${consentLogs?.length + 1}`,
      };
      setConsentLogs((prev) => [...prev, updatedobject]);
    }
    setTimeout(() => {
      handleState();
    }, 500);
    modalClose();
  };

  const handleState = () => {
    setIsChecked(false);
    setTextArea("");
  };

  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Table>
          <tbody>
            <FormCheck.Input
              className="me-2"
              type="checkbox"
              name={selectedBtn?.btnText}
              value={selectedBtn?.value}
              onChange={handleOnChange}
            />
            <FormCheck.Label>{selectedBtn?.value}</FormCheck.Label>
            {active && (
              <FloatingLabel
                controlId="floatingTextarea1"
                label="Comments"
                className="w-100 mt-3"
              >
                <Form.Control
                  as="textarea"
                  placeholder="Leave a comment here"
                  style={{ height: "100px" }}
                  onChange={(e) => setTextArea(e.target.value)}
                />
              </FloatingLabel>
            )}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSubmit} disabled={!textArea ? true : false}>
          {selectedBtn?.submit}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OnboardingModal;
