import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "../../api/instances/axiosInstance";
import {
  HCP_ACTIVITY,
  HCP_ACTIVITY_TIMELINE,
  HCP_ALL_ENGAGEMENT_LIST,
  HCP_DAY_OF_WEEK,
  HCP_DETAILS,
  HCP_ENGAGEMENT_METER_DATA,
  HCP_NBA_VIEW,
  HCP_REQUEST_DATA_CHNAGE,
  HCP_TIME_OF_DAY,
  HCP_UPDATE_RECOMMENDATION_STATUS,
  HCP_UPDATE_SPECIALITY_STATUS,
} from "../../api/endpoints/hcp-endpoints";
import {
  GET_HCP_ACTIVITY_LIST,
  GET_HCP_DETAILS,
  GET_HCP_ENGAGEMENT_METER_DATA,
  POST_HCP_SPECIALITY_DASHBOARD_STATUS,
  GET_HCP_ACTIVITY_TIMELINE_DATA,
  POST_HCP_REQUEST_DATA_CHNAGE,
  POST_HCP_RECOMMENDATION_STATUS,
  GET_HCP_ALL_ENGAGEMENT_DATA,
  GET_HCP_DAY_OF_WEEK_DATA,
  GET_HCP_TIME_OF_DAY_DATA,
  GET_HCP_NBA_VIEW_DATA,
} from "../query-constant/hcp-constant";
import { GET_ACTION_DETAILS } from "../query-constant/action-constant";

const staleTime = 300000;
const cacheTime = 600000;

const fetchHcpDetails = ({ queryKey }) => {
  const id = queryKey[1];
  return axiosInstance.get(`${HCP_DETAILS}/${id}`);
};

export const useHcpDetails = (id, enabled, onSuccess) => {
  return useQuery([GET_HCP_DETAILS, id], fetchHcpDetails, {
    enabled: !!enabled,
    onSuccess,
    cacheTime,
    staleTime,
    refetchOnWindowFocus: false,
    select: (res) => res.data.data,
  });
};

const fetchHcpActivity = ({ queryKey }) => {
  const id = queryKey[1];
  return axiosInstance.get(`${HCP_ACTIVITY}/${id}`);
};

export const useHcpActivityList = (id, enabled, onSuccess) => {
  return useQuery([GET_HCP_ACTIVITY_LIST, id], fetchHcpActivity, {
    enabled: !!enabled,
    onSuccess,
    cacheTime,
    staleTime,
    refetchOnWindowFocus: false,
    select: (res) => res.data.data,
  });
};

const getEngagementMeterData = ({ queryKey }) => {
  const id = queryKey[1];
  return axiosInstance.get(`${HCP_ENGAGEMENT_METER_DATA}/${id}`);
};

export const useEngagementMeterData = (id, enabled) => {
  return useQuery([GET_HCP_ENGAGEMENT_METER_DATA, id], getEngagementMeterData, {
    enabled: !!enabled,
    cacheTime,
    staleTime,
    refetchOnWindowFocus: false,
    select: (res) => res.data.data,
  });
};
const updateSpecialityDashboardStatus = (payload) => {
  return axiosInstance.post(HCP_UPDATE_SPECIALITY_STATUS, payload);
};

export const useChangeSpecialityDashboardStatus = (id, onSuccess) => {
  const queryClient = useQueryClient();

  return useMutation(updateSpecialityDashboardStatus, {
    onMutate: async (obj) => {
      console.log("RQ MUTATION SAVELIKE", obj);
    },
    onError: (_err, _obj, context) => {
      console.log("error spec chnage ", _obj);
    },
    onSettled: (obj) => {
      console.log("settled MUTATION spec change");
    },
    onSuccess,
  });
};

const updateRequestDataChange = (payload) => {
  return axiosInstance.post(HCP_REQUEST_DATA_CHNAGE, payload);
};
export const useRequestDataChange = (id, onSuccess) => {
  const queryClient = useQueryClient();

  return useMutation(updateRequestDataChange, {
    onMutate: async (obj) => {
      console.log("RQ MUTATION SAVELIKE", obj);
    },
    onError: (_err, _obj, context) => {
      console.log("error spec chnage ", _obj);
    },
    onSettled: (obj) => {
      console.log("settled MUTATION spec change");
    },
    onSuccess,
  });
};

const updateRecommendationStatus = (payload) => {
  return axiosInstance.post(HCP_UPDATE_RECOMMENDATION_STATUS, payload);
};
export const useRecommendationStatus = (id, onSuccess) => {
  const queryClient = useQueryClient();

  return useMutation(updateRecommendationStatus, {
    onMutate: async (obj) => {
      console.log("RQ MUTATION SAVELIKE", obj);
    },
    onError: (_err, _obj, context) => {
      console.log("error spec chnage ", _obj);
    },
    onSettled: (obj) => {
      console.log("settled MUTATION spec change");
    },
    onSuccess,
  });
};

let engageData = {
  success: true,
  data: {
    rows: [
      {
        page_id: "200",
        service: "Medwiki",
        channel: "SMS",
        template: "Hype",
        short_link: "test link",
        sending_time: "2022-07-22 11:05:00",
        view_time: "2022-07-22 11:05:00",
        status: "2022-07-22 11:05:00",
      },
      {
        page_id: "200",
        service: "Medwiki",
        channel: "SMS",
        template: "Hype",
        short_link: "test link",
        sending_time: "2022-07-22 11:05:00",
        view_time: "2022-07-22 11:05:00",
        status: "2022-07-22 11:05:00",
      },
      {
        page_id: "200",
        service: "Medwiki",
        channel: "SMS",
        template: "Hype",
        short_link: "test link",
        sending_time: "2022-07-22 11:05:00",
        view_time: "2022-07-22 11:05:00",
        status: "2022-07-22 11:05:00",
      },
      {
        page_id: "200",
        service: "Medwiki",
        channel: "SMS",
        template: "Hype",
        short_link: "test link",
        sending_time: "2022-07-22 11:05:00",
        view_time: "2022-07-22 11:05:00",
        status: "2022-07-22 11:05:00",
      },
      {
        page_id: "200",
        service: "Medwiki",
        channel: "SMS",
        template: "Hype",
        short_link: "test link",
        sending_time: "2022-07-22 11:05:00",
        view_time: "2022-07-22 11:05:00",
        status: "2022-07-22 11:05:00",
      },
      {
        page_id: "200",
        service: "Medwiki",
        channel: "SMS",
        template: "Hype",
        short_link: "test link",
        sending_time: "2022-07-22 11:05:00",
        view_time: "2022-07-22 11:05:00",
        status: "2022-07-22 11:05:00",
      },
      {
        page_id: "200",
        service: "Medwiki",
        channel: "SMS",
        template: "Hype",
        short_link: "test link",
        sending_time: "2022-07-22 11:05:00",
        view_time: "2022-07-22 11:05:00",
        status: "2022-07-22 11:05:00",
      },
      {
        page_id: "200",
        service: "Medwiki",
        channel: "SMS",
        template: "Hype",
        short_link: "test link",
        sending_time: "2022-07-22 11:05:00",
        view_time: "2022-07-22 11:05:00",
        status: "2022-07-22 11:05:00",
      },
    ],
    totalCount: 418889,
  },
};
export const useGetAllEngagementData = (id, enabled, onSuccess) => {
  return useQuery(
    [GET_HCP_ALL_ENGAGEMENT_DATA, id],
    () => axiosInstance.get(`${HCP_ALL_ENGAGEMENT_LIST}/${id}`),
    {
      enabled,
      refetchOnWindowFocus: false,
      cacheTime,
      onSuccess,
      staleTime,
      select: (res) => engageData?.data, //res.data ?? [],
    }
  );
};

export const useGetActivityTimelineData = (id, onSuccess) => {
  return useQuery(
    [GET_HCP_ACTIVITY_TIMELINE_DATA, id],
    () => axiosInstance.get(`${HCP_ACTIVITY_TIMELINE}/${id}`),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      onSuccess,
      staleTime,
      select: (res) => res.data ?? [],
    }
  );
};

export const useGetDayOfWeekData = (id, enabled) => {
  return useQuery(
    [GET_HCP_DAY_OF_WEEK_DATA, id],
    () => axiosInstance.get(`${HCP_DAY_OF_WEEK}/${id}`),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      enabled,
      staleTime,
      select: (res) => res.data ?? [],
    }
  );
};
export const useGetTimeOfDayData = (id, onSuccess) => {
  return useQuery(
    [GET_HCP_TIME_OF_DAY_DATA, id],
    () => axiosInstance.get(`${HCP_TIME_OF_DAY}/${id}`),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      onSuccess,
      staleTime,
      select: (res) => res.data ?? [],
    }
  );
};

let nbaData = {
  success: true,
  data: {
    rows: [
      {
        content_id: "Sumit Dutta",
        content_type: "Medwiki",
        content_title: "You can use it with tabs, pills, and any other",
      },
      {
        content_id: "Sumit Dutta",
        content_type: "Medwiki",
        content_title: "You can use it with tabs, pills, and any other",
      },
      {
        content_id: "Sumit Dutta",
        content_type: "Medwiki",
        content_title: "You can use it with tabs, pills, and any other",
      },
      {
        content_id: "Sumit Dutta",
        content_type: "Medwiki",
        content_title: "You can use it with tabs, pills, and any other",
      },
      {
        content_id: "Sumit Dutta",
        content_type: "Medwiki",
        content_title: "You can use it with tabs, pills, and any other",
      },
    ],
    totalCount: 418889,
  },
};
export const useGetNbaViewData = (id, enabled, onSuccess) => {
  return useQuery(
    [GET_HCP_NBA_VIEW_DATA, id],
    () => axiosInstance.get(`${HCP_NBA_VIEW}/${id}`),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      enabled,
      staleTime,
      onSuccess,
      select: (res) => nbaData?.data, //res.data ?? [],
    }
  );
};
