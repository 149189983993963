import React, { useEffect, memo } from "react";
import { Col, Image, Modal, Row, Accordion, Button } from "react-bootstrap";
import article_1 from "../../../assets/modules/file-manager/images/article_1.jpg";
import parse from "html-react-parser";
import * as dayjs from "dayjs";
const fileTypes = [
  "pdf",
  "doc",
  "article",
  "audio",
  "zip",
  "html",
  "ppt",
  "email",
  "docx",
  "video",
];

const FilePreviewModal = ({
  id = "", //content library id
  mediaType = "",
  show,
  viewModalClose,
  title = "",
  brand = "",
  createdBy = "",
  createdOn = "",
  description = "",
  icon = "",
  file_url = "",
  titleHtml = "",
  coverImage = "",
  descriptionHtml = "",
  citation = "",
  onSelect,
}) => {
  useEffect(() => {
    console.log("INSIDE FILE PREVIEW MODAL", file_url);
  }, []);
  const HeroSection = () => {
    return (
      <div className="d-flex flex-column p-3 border border-1 rounded-3 w-100 mb-4 mb-md-0">
        <div className="d-flex align-items-end gap-3">
          <div
            className={`d-flex align-items-center justify-content-center avatar-50 rounded-3 p-2 flex-shrink-0 bg-soft-primary`}
          >
            <Image
              src={icon || article_1}
              className="mw-100 mx-100 h-auto icon-24"
              alt="icon"
            />
          </div>
          <div className="flex-grow-1 text-start fs-7">
            Created on{" "}
            <span className="d-block fs-6 mt-1">
              {dayjs(createdOn).format("DD MMM, YYYY")}
            </span>
          </div>
        </div>

        <div className="d-flex flex-column justify-content-start mt-4">
          <h5 className="mb-0 text-start">{parse(title)}</h5>

          <h6 className="mt-1 mb-3 fw-normal text-secondary text-start">
            <small>{brand}</small>
          </h6>
          <p className="mb-2 text-start">{parse(description)}</p>
          <div className="d-flex justify-content-start gap-3 text-start flex-column">
            <h5 className="fs-6">
              {" "}
              <span className="opacity-50">Created By</span>{" "}
              {createdBy ? createdBy : "Admin"}
            </h5>
          </div>
        </div>
        <div className="d-flex justify-content-end align-items-center gap-2">
          <Button
            variant="danger"
            onClick={viewModalClose}
            // className="w-auto ms-auto mt-4 d-md-block d-none"
          >
            Close
          </Button>
          {onSelect && typeof onSelect === "function" && (
            <Button
              variant="primary"
              onClick={selectContentHandler}
              // className="w-auto ms-auto mt-4 d-md-block d-none"
            >
              Select
            </Button>
          )}
        </div>
      </div>
    );
  };
  const getFileType = (mediaType) => {
    console.log("file type", mediaType);
    //get file type from fileTypes array
    switch (mediaType) {
      case "pdf":
        return <PdfSection url={file_url} />;
      case "doc":
      case "docx":
      case "ppt":
      case "excel":
        return <DocumentSection url={file_url} />;
      case "audio":
        return <AudioSection url={file_url} />;
      case "zip":
        return <ZipSection url={file_url} />;
      case "video":
        return <VideoSection url={file_url} />;
      case "image":
      case "infographic":
        return <ImageSection url={file_url} />;
      case "article":
      case "html":
      case "email":
        console.log("title html", titleHtml);
        return (
          <ArticleSection
            title={titleHtml}
            description={descriptionHtml}
            citation={citation}
            thumbnail={icon}
          />
        );

      default:
        return <></>;
    }
  };
  const ArticleSection = ({ title, description, citation, thumbnail }) => {
    return (
      <div className="d-flex flex-column justify-content-start mt-4 p-5">
        <h5 className="mb-0 text-start">{parse(title)}</h5>

        <p className="mb-2 text-start">{parse(description)}</p>

        <h6 className="mt-1 mb-3 fw-normal text-secondary text-start">
          {parse(citation)}
        </h6>
      </div>
    );
  };

  const PdfSection = ({ url }) => {
    return (
      <iframe
        src={url}
        title="iq-doc"
        loading="lazy"
        style={{ width: "69rem", height: "34rem" }}
      ></iframe>
    );
  };
  const DocumentSection = ({ url }) => {
    return (
      <iframe
        src={"https://view.officeapps.live.com/op/view.aspx?src=" + url}
        title="iq-doc"
        loading="lazy"
        style={{ width: "69rem", height: "34rem" }}
      ></iframe>
    );
  };

  const ImageSection = ({ url }) => {
    return <Image src={url} className="img-fluid" alt="" />;
  };
  const VideoSection = ({ url }) => {
    return (
      <video controls src={url} width="100%" autoPlay>
        Sorry, your browser doesn't support embedded videos, but don't worry,
        you can
        <a href={url} download>
          download it
        </a>
        and watch it with your favorite video player!
      </video>
    );
  };
  const AudioSection = ({ url }) => {
    //return a audio player
    return (
      <audio controls>
        <source src={url} type="audio/ogg" />
        <source src={url} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    );
  };
  const ZipSection = ({ url }) => {
    const downloadZip = () => {
      //download zip file
      window.open(url, "_blank");
    };

    //a image with zip icon and a download button
    return (
      <>
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-center align-items-center">
            {" "}
            <Image
              src={icon}
              alt=""
              className="w-auto"
              style={{
                height: "15rem",
              }}
            />
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <Button
              variant={"success"}
              className="d-flex align-items-center justify-content-center rounded-pill p-1 lh-1 mt-3 mx-auto w-25 py-3"
              onClick={downloadZip}
            >
              Download Zip
            </Button>
          </div>
        </div>
      </>
    );
  };
  const selectContentHandler = () => {
    onSelect(id);
    viewModalClose();
  };
  return (
    <Modal
      show={show}
      onHide={viewModalClose}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      fullscreen
      centered
      className="content-previewModal"
    >
      {/* <Modal.Header closeButton>
        <h3 className="mb-0">{title || ""}</h3>
      </Modal.Header> */}
      <Modal.Body className="text-center">
        {/* <div className="d-flex justify-content-end mb-3">
          <a href="" className="fs-7 d-inline-flex align-items-center">
            Show Content Details {findIcon("Chevron Down", "outline", "16")}
          </a>
        </div> */}

        <Row className="d-flex flex-column align-items-start flex-md-row-reverse h-100">
          <Col lg={3} md={4}>
            <HeroSection />
          </Col>
          <Col lg={9} md={8} className="h-100">
            <div className="position-relative px-3 pb-3 pt-3 h-100 rounded-4 overflow-hidden content-previewModal__left h-100">
              <div className="w-100 h-100 position-absolute top-0 start-0 content-previewModal__cover">
                <img
                  src="https://img.freepik.com/free-photo/medium-shot-people-making-podcast_23-2149455493.jpg?w=2000&t=st=1687265648~exp=1687266248~hmac=48d6803e8ee9f6ef2021420c525e0be2beb615ea8bad233edfef04c19bb03bc1"
                  className="object-cover w-100 h-100 float-start"
                  alt=""
                />
                <div className="overlay bg-black bg-opacity-50 w-100 h-100 position-absolute start-0 top-0"></div>
              </div>
              <div className="d-flex bg-white p-2 rounded-3 align-items-center position-relative content-previewModal__container h-100 overflow-hidden">
                {getFileType(mediaType)}
              </div>
            </div>
            <Button
              variant="danger"
              onClick={viewModalClose}
              className="w-auto ms-auto mt-4 d-block d-md-none"
            >
              Close
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default memo(FilePreviewModal);
