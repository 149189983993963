import React, {
  Fragment,
  useRef,
  useState,
  useCallback,
  useEffect,
} from "react";
import { Button, Card, Col, Form, Row, Modal, Badge } from "react-bootstrap";
import { findIcon } from "../../../../dashboard/icons";
import ReactTable from "../../../../../lib/table/react-table";
import postService from "../../../../../api/services/post-service";
import ViewModal from "../../../../../components/partials/common/view-modal";
import DeleteModal from "../../../../../components/partials/common/delete-modal";
import { getService } from "../../../../../api/services/get-services";
import {
  changeTableStateToPayload,
  statusToBg,
} from "../../../../../common/utils/utils";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  ADD_ZONE_POST,
  ALL_ZONE_POST,
  CREATE_ZONE_POST,
  DELETE_ZONE_POST,
  UPDATE_ZONE_POST,
} from "../../../../../api/endpoints/zone-endpoints";
import toast from "react-hot-toast";
import PageNotFound from "../../../../PageNotFound";
import CommonTableLoader from "../../../../../skeleton/common-table-loader";

const AllZone = () => {
  const [createModalShow, setCreateModalShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [zoneList, setZoneList] = useState([]);
  const [viewModalShow, setViewModalShow] = useState(false);
  const [viewZone, setViewZone] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [selectedZone, setSelectedZone] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const post_json = useRef({
    default: "all",

    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      zone_id: "desc",
    },
  });
  //yup and formik for validation of form fields of priority_name

  const validationSchema = yup.object().shape({
    zone_name: yup.string().required("Zone Name is required"),
    zone_country_id: yup.string().required("Zone Country Id is required"),
    // zone_id: yup.string().required("Zone Id is required"),
    // zone_code: yup.string().required("Zone Code is required"),
  });
  //formik
  const formik = useFormik({
    initialValues: {
      zone_name: "",
      zone_country_id: "",
      // zone_id: "",
      // zone_code: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const postValues = {
        ...values,
        zone_name: values.zone_name.trim(),
      };

      try {
        // setLoading(true);
        const response = await postService(
          isEdit ? UPDATE_ZONE_POST + "/" + selectedZone : ADD_ZONE_POST,
          {
            ...postValues,
            // zone_active: 1,
            status: isEdit ? values.status : 1,
          }
        );
        if (response) {
          setLoading(false);
          setCreateModalShow(false);
          fetchZone(post_json.current);
          isEdit && setIsEdit(false);
          toast.success(
            isEdit ? "Zone Updated Successfully" : "Zone Added Successfully"
          );
        }
      } catch (error) {
        setLoading(false);
        console.log("ACTION_TYPE_SERVICE_ERROR", error);
      }
    },
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    resetForm,
  } = formik;
  console.log("formic test", values, errors);
  //fetch countries
  const fetchZone = async (data) => {
    try {
      // alert("fetching");
      // setLoading(true);
      const response = await postService(ALL_ZONE_POST, data);
      const response_country_list = await getService(CREATE_ZONE_POST, data);
      console.log("COUNTRY_LIST", response_country_list?.data?.data?.country);
      console.log("ZONE_SERVICE_RESPONSE", response.data.data.rows);
      console.log("COLUMNS", Object.keys(response.data.data.rows[0]));
      if (response_country_list?.data?.data?.country?.length > 0) {
        setCountryList(response_country_list?.data?.data?.country);
      }
      if (response.data.data.rows.length > 0) {
        setZoneList(response.data.data.rows);
        setTotalCount(response.data.data.totalCount);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("ZONE_SERVICE_ERROR", error);
    }
  };
  const viewModal = async (zone) => {
    console.log("zone===>", zone);
    let dataJson = {
      // "Zone ID": zone.zone_id,
      Country: zone.zone_country_id.country_name,
      "Zone Name": zone.zone_name,

      // "Zone Code": zone.zone_code,
    };
    setViewZone(dataJson);
    setViewModalShow(true);
  };
  const viewModalClose = () => {
    setViewModalShow(false);
  };
  const deleteZone = async () => {
    try {
      // setLoading(true);
      const response = await getService(`${DELETE_ZONE_POST}/${selectedZone}`);
      if (response) {
        setLoading(false);
        setDeleteModalShow(false);
        fetchZone(post_json.current);
        toast.success("Zone Deleted Successfully");
      }
    } catch (error) {
      setLoading(false);
      console.log("ZONE_SERVICE_ERROR", error);
    }
  };
  useEffect(() => {
    fetchZone(post_json.current);
  }, []);
  const onTableStateChange = useCallback(
    async (state) => {
      let payload = changeTableStateToPayload(state);
      post_json.current = payload;
      fetchZone(payload);
    },
    [zoneList, loading, post_json.current]
  );
  const onDeleteZone = (zone) => {
    setSelectedZone(zone.zone_id);
    setDeleteModalShow(true);
  };
  const columns = [
    {
      Header: "Zone ID",
      accessor: "zone_id",
    },
    {
      Header: "Zone Name",
      accessor: "zone_name",
    },
    // {
    //   Header: "Zone Code",
    //   accessor: "zone_code",
    // },
    {
      Header: "Zone Country ID",
      accessor: "zone_country_id",
      Cell: ({ row }) => row.original.zone_country_id.country_name,
    },
    {
      Header: "Created by",
      accessor: "created_by",
      Cell: ({ row }) => row.original.created_by.name,
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => (
        // row.original.status.value
        <Badge bg={statusToBg(value.id)} className="me-2">
          <span className="btn-inner">{value.value}</span>
        </Badge>
      ),
    },
    {
      Header: "Action",
      accessor: "action",
      disableFilters: true,
      Cell: ({ row }) => (
        <div className="d-flex justify-content-center align-items-center gap-2">
          <Button
            className="btn-icon btn-sm rounded-pill"
            variant="info"
            to="#"
            role="button"
            onClick={() => viewModal(row.original)}
          >
            <span className="btn-inner">
              {findIcon("Eye", "dual-tone", 32)}
            </span>
          </Button>

          <Button
            className="btn-icon btn-sm rounded-pill"
            to="#"
            role="button"
            onClick={() => onEditZoneClick(row.original)}
          >
            <span className="btn-inner">{findIcon("Pencil", "dual-tone")}</span>
          </Button>

          <Button
            className="btn-icon btn-sm rounded-pill"
            variant="danger"
            to="#"
            role="button"
            onClick={() => onDeleteZone(row.original)}
          >
            <span className="btn-inner">
              {findIcon("Trash", "dual-tone", 32)}
            </span>
          </Button>
        </div>
      ),
    },
  ];

  const handleCreate = () => {
    resetForm();
    // setCountryList([])
    setCreateModalShow(true);
  };
  const onEditZoneClick = (zone) => {
    console.log("zone===>", zone);
    setIsEdit(true);
    setSelectedZone(zone.zone_id);

    const editValues = {
      zone_name: zone?.zone_name,
      zone_country_id: zone?.zone_country_id?.country_id,
      status: zone?.status?.id,
    };
    //set formik values
    setValues(editValues);
    setCreateModalShow(true);
  };

  return (
    <Fragment>
      <Button
        variant="success"
        className="d-flex align-items-center justify-content-center add_floating__button position-fixed rounded-circle p-1 avatar-80 lh-1 border border-white border-2"
        onClick={() => handleCreate()}
      >
        {findIcon("Plus", "outline", "32")}
      </Button>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">
              <h4 className="card-title">All Zone Names</h4>
              {/* <Button
                type="button"
                variant="primary"
                className="fw-bold rounded-3"
                onClick={() => handleCreate()}
              >
                {findIcon("Plus", "dual-tone", 32)} Add Zone Name
              </Button> */}
            </Card.Header>
            <Card.Body className="px-3">
              {!loading ? (
                zoneList.length > 0 ? (
                  <ReactTable
                    data={zoneList}
                    columns={columns}
                    recordsTotal={totalCount}
                    onTableStateChange={onTableStateChange}
                    initialState={post_json.current}
                    isRowSelectionAvailable={false}
                    recordsPerPage={10}
                  />
                ) : (
                  <PageNotFound />
                )
              ) : (
                <CommonTableLoader />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <DeleteModal
        show={deleteModalShow}
        modalClose={() => setDeleteModalShow(false)}
        onSubmit={deleteZone}
      />
      <ViewModal
        title={"View Priority Name Information"}
        show={viewModalShow}
        modalClose={viewModalClose}
        data={viewZone}
      />
      {/* create a add Priority Name modal with Priority Name name and description */}
      <Modal
        show={createModalShow}
        onHide={() => {
          setCreateModalShow(false);
          setIsEdit(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? "Edit" : "Add"} Zone Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* ZONE COUNTRY */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                Country <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                // type="text"
                // placeholder="Enter Zone Country"
                name="zone_country_id"
                onChange={handleChange}
                value={values.zone_country_id}
                onBlur={handleBlur}
                isInvalid={touched.zone_country_id && errors.zone_country_id}
              >
                <option value="">Select Country</option>
                {countryList.length > 0 &&
                  countryList.map((_c) => (
                    <option value={_c?.country_id}>{_c?.country_name}</option>
                  ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.zone_country_id}
              </Form.Control.Feedback>
            </Form.Group>
            {/* ZONE NAME */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                Zone Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Zone Name"
                name="zone_name"
                onChange={handleChange}
                value={values.zone_name}
                onBlur={handleBlur}
                isInvalid={touched.zone_name && errors.zone_name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.zone_name}
              </Form.Control.Feedback>
            </Form.Group>
            {isEdit && (
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  Status <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  name="status"
                  value={values?.status}
                  isInvalid={touched.status && errors.status}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="">Select Status</option>
                  <option value="1">Enabled</option>
                  <option value="2">Disabled</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors?.status}
                </Form.Control.Feedback>
              </Form.Group>
            )}

            {/* <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Zone ID</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Zone ID"
                name="zone_id"
                onChange={handleChange}
                value={values.zone_id}
                onBlur={handleBlur}
                isInvalid={touched.zone_id && errors.zone_id}
              />
              <Form.Control.Feedback type="invalid">
                {errors.zone_id}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Zone Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Zone Code"
                name="zone_code"
                onChange={handleChange}
                value={values.zone_code}
                onBlur={handleBlur}
                isInvalid={touched.zone_code && errors.zone_code}
              />
              <Form.Control.Feedback type="invalid">
                {errors.zone_code}
              </Form.Control.Feedback>
            </Form.Group> */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setCreateModalShow(false);
              setIsEdit(false);
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default AllZone;
