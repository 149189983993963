import { Fragment, memo, useCallback, useEffect, useState } from "react";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import Card from "../../../../components/bootstrap/card";
import { findIcon } from "../../../dashboard/icons";
import { Link } from "react-router-dom";

// Componet

//Chat
import Chart from "react-apexcharts";
import CustomToggle from "../../../../components/dropdowns";
// import CreateContentStudioModal from "../../../../components/partials/common/create-content-studio-modal";
import CreateContentStudio from "../pages/create-content-studio";
import CreateContentStudioModal from "../component/create-content-studio-modal";
import { useDispatch } from "react-redux";
import { getContentStudio } from "../../../../store/content-studio/content-studio.action";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import withAuthorization from "../../../../components/hoc/with-authorization";
import FilePreviewModal from "../../../../components/partials/common/file-preview-modal";

const ContentStudio = () => {
  const [mediaType, setMediaType] = useState("");
  const [show, setShow] = useState(false);
  const getVariableColor = () => {
    let prefix =
      getComputedStyle(document.body).getPropertyValue("--prefix") || "bs-";
    if (prefix) {
      prefix = prefix.trim();
    }
    const color1 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}primary`
    );
    const color2 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}info`
    );
    const color3 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}info-tint-20`
    );
    const color4 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}primary-tint-20`
    );
    const color5 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}primary-shade-20`
    );
    const color6 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}info-shade-20`
    );
    return {
      primary: color1.trim(),
      info: color2.trim(),
      info_dark: color6.trim(),
      primary_dark: color5.trim(),
      info_light: color3.trim(),
      primary_light: color4.trim(),
    };
  };

  const variableColors = getVariableColor();

  const colors = [
    variableColors.primary,
    variableColors.info_light,
    variableColors.primary_dark,
    variableColors.info,
    variableColors.primary_light,
    variableColors.info_dark,
  ];
  const ChartColor = [variableColors.info];
  const iqcolor = { colors, ChartColor };
  useEffect(() => {
    return () => iqcolor;
  });
  const chart3 = {
    options: {
      chart: {
        height: 250,
        type: "radialBar",
      },
      colors: colors,
      plotOptions: {
        radialBar: {
          dataLabels: {
            total: {
              show: true,
              label: "TOTAL",
            },
          },
        },
      },
      labels: ["Infographic", "Article", "Image", "PDF"],
    },
    series: [67, 84, 97, 61],
  };
  const chart4 = {
    options: {
      chart: {
        type: "pie",
        height: 250,
      },
      colors: colors,
      labels: ["Infographic", "Article", "Image", "PDF"],
      legend: {
        position: "bottom",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
    series: [44, 55, 13, 43],
  };

  const [contentStudioDetails, setContentStudioDetails] = useState({});
  const [step, setStep] = useState(1);
  const [createModalShow, setCreatemodalShow] = useState(false);
  const dispatch = useDispatch();
  const { redirectTo } = useRedirect();
  const handleCreateModalClose = useCallback(
    () => setCreatemodalShow(false),
    [createModalShow]
  );

  const selectTemplateForContent = useCallback(
    (item) => {
      dispatch(
        getContentStudio({
          ...contentStudioDetails,
          content_studio_content_id: item?.id,
          content_studio_content_name: item?.fileName,
          content_studio_content_icon: item?.fileTypeIcon,
          content_studio_content_publish_date: item?.publicationDate,
          content_studio_content_description: item?.fileDescription,
          content_studio_content_type: item?.fileType,
          content_studio_content_created_by: item?.createdBy,
          content_studio_content_url: item.fileLink,
          variations: [
            {
              id: 0,
              variation_name: "variation 1",
              templates: {
                whatsapp: {
                  id: "",
                  name: "",
                  content: "",
                  approve: "",
                },
                email: {
                  id: "",
                  name: "",
                  content: "",
                  approve: "",
                },
                sms: {
                  id: "",
                  name: "",
                  content: "",
                  approve: "",
                },
              },
              spam_status: 0,
              is_checkSpam_disable: false,
              is_variation_name_edit: false,
            },
          ],
          is_content_name_edit: false,
          channelStatus: { whatsapp: true, email: true, sms: true },
        })
      );
      handleCreateModalClose();
      redirectTo("content-studio/create");
    },
    [contentStudioDetails]
  );
  const getContentStudioName = useCallback(
    (e) =>
      setContentStudioDetails({
        ...contentStudioDetails,
        content_studio_name: e.target.value,
      }),
    [contentStudioDetails]
  );
  console.log("nameeeee", contentStudioDetails);
  const table = [
    {
      file: findIcon("Photograph", "solid", "18"),
      name: "Marcus Family.jpg",
      date: "16 Oct,11:23m",
      size: "Brand A",
      view: findIcon("Eye", "solid", "24"),
    },
    {
      file: findIcon("Start", "solid", "18"),
      name: "Work.Doc",
      date: "20 Nov,12:40pm",
      size: "Brand B",
      view: findIcon("Eye", "solid", "24"),
    },
    {
      file: findIcon("Document", "solid", "18"),
      name: "Marcus Family.jpg",
      date: "16 Oct,11:23m",
      size: "Brand C",
      view: findIcon("Eye", "solid", "24"),
    },
    {
      file: findIcon("Document", "solid", "18"),
      name: "Apha.mkv",
      date: "08 Oct,05:45pm",
      size: "Brand D",
      view: findIcon("Eye", "solid", "24"),
    },
  ];
  const createContenStudioModalOpen = () => setCreatemodalShow(true);
  const viewModalClose = () => {
    setShow(false);
  };

  return (
    <Fragment>
      {/* {step === 1 && (
        <> */}
      <Button
        variant="success"
        className="d-flex align-items-center justify-content-center add_floating__button position-fixed rounded-circle p-1 avatar-80 lh-1 border border-white border-2"
        onClick={createContenStudioModalOpen}
      >
        {findIcon("Plus", "outline", "32")}
      </Button>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <div className="d-flex flex-column">
          <h3 className="m-0">Content Studio - Dashboard</h3>
        </div>
      </div>
      <Row className="row-gap-3">
        <Col lg="8" className="d-flex">
          <Card className="flex-grow-1">
            <Card.Header className="d-flex justify-content-between">
              <Card.Header.Title>
                <h5 className="mb-0">Recently added Content</h5>
              </Card.Header.Title>
              <Dropdown>
                <Dropdown.Toggle
                  as={CustomToggle}
                  href="#"
                  variant="text-secondary dropdown-toggle"
                  size="sm"
                  id="dropdownMenuButton1"
                >
                  Last 7 days
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#">Last 7 days</Dropdown.Item>
                  <Dropdown.Item href="#">Last 14 days</Dropdown.Item>
                  <Dropdown.Item href="#">Last 30 days</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Card.Header>
            <Card.Body className="flex-grow-1">
              <div className="table-responsive">
                <table className="table table-bordered mb-0">
                  <thead>
                    <tr className="border-bottom bg-transparent text-dark">
                      <th scope="col">Files</th>
                      <th scope="col">Created On</th>
                      <th scope="col">Brand</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {table.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <div className="d-flex align-items-center gap-3">
                              <span className="avatar-40 bg-soft-primary rounded-circle">
                                {item.file}
                              </span>
                              <h6 className="mb-0">{item.name}</h6>
                            </div>
                          </td>
                          <td>
                            <small className="text-muted">{item.date}</small>
                          </td>
                          <td>
                            <small className="text-primary">{item.size}</small>
                          </td>
                          <td>
                            <Link className="d-flex align-items-center justify-content-center text-danger">
                              <span className="btn-inner">{item.view}</span>
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="4" className="d-flex">
          <Card className="flex-grow-1">
            <Card.Header className="d-flex justify-content-between">
              <Card.Header.Title>
                <h5 className="mb-0">Recently added Content</h5>
              </Card.Header.Title>

              <Dropdown>
                <Dropdown.Toggle
                  as={CustomToggle}
                  href="#"
                  variant="text-secondary dropdown-toggle"
                  size="sm"
                  id="dropdownMenuButton1"
                >
                  Last 7 days
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#">Last 7 days</Dropdown.Item>
                  <Dropdown.Item href="#">Last 14 days</Dropdown.Item>
                  <Dropdown.Item href="#">Last 30 days</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Card.Header>
            <Card.Body className="text-center  flex-grow-1 d-flex align-items-center justify-content-center">
              <Chart
                options={chart3.options}
                series={chart3.series}
                type="radialBar"
                height="340"
              />
            </Card.Body>
          </Card>
        </Col>
        <Col lg="4" className="d-flex">
          <Card className="flex-grow-1">
            <Card.Header className="d-flex justify-content-between">
              <Card.Header.Title>
                <h5 className="mb-0">Most Used Content</h5>
              </Card.Header.Title>
              <Dropdown>
                <Dropdown.Toggle
                  as={CustomToggle}
                  href="#"
                  variant="text-secondary dropdown-toggle"
                  size="sm"
                  id="dropdownMenuButton1"
                >
                  Last 7 days
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#">Last 7 days</Dropdown.Item>
                  <Dropdown.Item href="#">Last 14 days</Dropdown.Item>
                  <Dropdown.Item href="#">Last 30 days</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Card.Header>
            <Card.Body className="text-center flex-grow-1 d-flex align-items-center justify-content-center">
              <Chart
                options={chart4.options}
                series={chart4.series}
                type="pie"
                height="340"
              />
            </Card.Body>
          </Card>
        </Col>
        <Col lg="8" className="d-flex">
          <Card className="flex-grow-1">
            <Card.Header className="d-flex justify-content-between">
              <Card.Header.Title>
                <h5 className="mb-0">Popular Content</h5>
              </Card.Header.Title>
              <div className="d-flex">
                <Dropdown>
                  <Dropdown.Toggle
                    as={CustomToggle}
                    href="#"
                    variant="text-secondary dropdown-toggle"
                    size="sm"
                    id="dropdownMenuButton1"
                  >
                    Last 7 days
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="#">Last 7 days</Dropdown.Item>
                    <Dropdown.Item href="#">Last 14 days</Dropdown.Item>
                    <Dropdown.Item href="#">Last 30 days</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <Dropdown>
                  <Dropdown.Toggle
                    as={CustomToggle}
                    href="#"
                    variant="text-secondary dropdown-toggle"
                    size="sm"
                    id="dropdownMenuButton1"
                  >
                    Popular Content
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="#">By Type</Dropdown.Item>
                    <Dropdown.Item href="#">By Brand</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
              </div>
            </Card.Header>
            <Card.Body className="flex-grow-1">
              <div className="table-responsive">
                <table className="table table-bordered mb-0">
                  <thead>
                    <tr className="border-bottom bg-transparent text-dark">
                      <th scope="col">Files</th>
                      <th scope="col">Created On</th>
                      <th scope="col">Brand</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {table.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <div className="d-flex align-items-center gap-3">
                              <span className="avatar-40 bg-soft-primary rounded-circle">
                                {item.file}
                              </span>
                              <h6 className="mb-0">{item.name}</h6>
                            </div>
                          </td>
                          <td>
                            <small className="text-muted">{item.date}</small>
                          </td>
                          <td>
                            <small className="text-primary">{item.size}</small>
                          </td>
                          <td>
                            <Link
                              className="d-flex align-items-center justify-content-center text-danger"
                              onClick={() => {
                                setMediaType("image");
                                setShow(true);
                              }}
                            >
                              <span className="btn-inner">{item.view}</span>
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="6" className="d-flex">
          <Card className="flex-grow-1">
            <Card.Header className="d-flex justify-content-between">
              <Card.Header.Title>
                <h5 className="mb-0">Recently added Content</h5>
              </Card.Header.Title>

              <Dropdown>
                <Dropdown.Toggle
                  as={CustomToggle}
                  href="#"
                  variant="text-secondary dropdown-toggle"
                  size="sm"
                  id="dropdownMenuButton1"
                >
                  Last 7 days
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#">Last 7 days</Dropdown.Item>
                  <Dropdown.Item href="#">Last 14 days</Dropdown.Item>
                  <Dropdown.Item href="#">Last 30 days</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Card.Header>
            <Card.Body className="flex-grow-1 d-flex align-items-center justify-content-center">
              <div className="table-responsive">
                <table className="table table-bordered mb-0">
                  <thead>
                    <tr className="border-bottom bg-transparent text-dark">
                      <th scope="col">Files</th>
                      <th scope="col">Created On</th>
                      <th scope="col">Brand</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {table.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <div className="d-flex align-items-center gap-3">
                              <span className="avatar-40 bg-soft-primary rounded-circle">
                                {item.file}
                              </span>
                              <h6 className="mb-0">{item.name}</h6>
                            </div>
                          </td>
                          <td>
                            <small className="text-muted">{item.date}</small>
                          </td>
                          <td>
                            <small className="text-primary">{item.size}</small>
                          </td>
                          <td>
                            <Link className="d-flex align-items-center justify-content-center text-danger">
                              <span className="btn-inner">{item.view}</span>
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="6" className="d-flex">
          <Card className="flex-grow-1">
            <Card.Header className="d-flex justify-content-between">
              <Card.Header.Title>
                <h5 className="mb-0">Popular Content by Brand</h5>
              </Card.Header.Title>
              <Dropdown>
                <Dropdown.Toggle
                  as={CustomToggle}
                  href="#"
                  variant="text-secondary dropdown-toggle"
                  size="sm"
                  id="dropdownMenuButton1"
                >
                  Last 7 days
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#">Last 7 days</Dropdown.Item>
                  <Dropdown.Item href="#">Last 14 days</Dropdown.Item>
                  <Dropdown.Item href="#">Last 30 days</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Card.Header>
            <Card.Body className="text-center flex-grow-1 d-flex align-items-center justify-content-center">
              <Chart
                options={chart4.options}
                series={chart4.series}
                type="pie"
                height="340"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <CreateContentStudioModal
        show={createModalShow}
        handleCreateModalClose={handleCreateModalClose}
        contentStudioDetails={contentStudioDetails}
        getContentStudioName={getContentStudioName}
        selectTemplateForContent={selectTemplateForContent}
      />
      <FilePreviewModal
        show={show}
        mediaType={"image"}
        file_url="https://visme.co/blog/wp-content/uploads/2020/02/What-is-an-infographic.jpg"
        viewModalClose={viewModalClose}
        createdOn="12 August 2023"
      />
    </Fragment>
  );
};

export default memo(ContentStudio);
