import React, { useState } from "react";
import {
  Button,
  Col,
  FloatingLabel,
  Form,
  Image,
  ListGroup,
  ListGroupItem,
  Modal,
  Nav,
  Row,
  Tab,
  Table,
} from "react-bootstrap";
import CountUp from "react-countup";
import Card from "../../../../components/bootstrap/card";
import avatars11 from "../../../../assets/images/avatars/01.png";
import toast from "react-hot-toast";
import ReactTable from "../../../../lib/table/react-table";
import { findIcon } from "../../../dashboard/icons";
import Progress from "../../../../components/progress";
import Chart from "react-apexcharts";

import mobile from "../../../../assets/images/mobile-bg.png";
import mobileWhatsapp from "../../../../assets/images/mobileWhatsapp.png";

const AbTestApprovalDetails = () => {
  const [modalShow, setModalShow] = useState(false);
  const [viewModalShow, setViewModalShow] = useState(false);
  const [datashow, setDataShow] = useState(false);
  const [comment, setComment] = useState("");

  const [results, setResults] = useState([]);
  console.log(comment, results);

  const modalClose = () => {
    setViewModalShow(false);
  };

  // const modalOpen = () => {
  //   setModalShow(true);
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    setViewModalShow(false);
    if (comment !== "") {
      setDataShow(true);
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      const time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const data = {
        id: results?.length + 1,
        comment: comment,
        createdAt: date + " " + time,
      };

      setResults((prev) => [...prev, data]);
    } else {
      setDataShow(false);
      toast.error("No comments to show");
    }
  };

  const columns = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Comments",
      accessor: "comment",
    },
    {
      Header: "Added On",
      accessor: "createdAt",
    },
  ];

  return (
    <>
      <Row className="m-0">
        <Col lg="12" className="bg-white rounded-3 pb-3">
          <div className="col-lg-12 p-3 my-2 d-flex flex-column justify-content-start align-items-start">
            <div className="d-block fw-normal text-secondary fs-4">
              Approval to Roll Out A/B Test - SMS
            </div>
            <div className="fs-7 w-100 d-flex flex-row justify-content-between align-items-center gap-3 py-1">
              <div className="d-flex flex-row gap-3">
                <span>
                  Created By: <strong>Swagata Goswami</strong>
                </span>
                <span>
                  Date: <strong>24 Mar 2023</strong>
                </span>
              </div>
            </div>
          </div>
          <div className="w-100 p-3 rounded-3 border border-primary border-0 d-flex flex-column align-items-center justify-content-start shadow-lg">
            <Row className="w-100">
              <div className="col-4 p-0 d-flex flex-column">
                <div className="p-3 mb-3 d-flex flex-column rounded-4 text-black lh-base border border-primary border-2 border-dashed">
                  <p className="m-0 fs-7">Segment Details:</p>
                  <p className="m-0 fw-normal">Nephrology Doctors in Kolkata</p>
                </div>
                <div className="bg-soft-success flex-fill rounded-4 flex-fill">
                  <div className="row row-cols-1 m-0 my-3">
                    <Col>
                      <Card className="mb-4">
                        <Card.Body className="p-0 d-flex flex-column align-items-start justify-content-start">
                          <div className="p-2 pb-0 border-bottom w-100">
                            <h6 className="mb-2 w-100 p-2">Variant A</h6>
                          </div>
                          <div className="px-3 py-3 d-flex flex-row align-items-start justify-content-start gap-3 w-100 align-items-center">
                            <div className="d-inline-block avatar-40 rounded-3 flex-shrink-0 text-info">
                              {findIcon("View Grid", "dual-tone", "30")}
                            </div>
                            <div className="text-start d-flex flex-grow-1">
                              <h5 className="m-0 fs-6">Segment Size</h5>
                              <h6 className="m-0 ms-auto fw-normal text-info fs-5">
                                <CountUp start={3} end={852} duration={3} />
                              </h6>
                            </div>
                          </div>
                          {/* <div className="p-2 pb-3 mt-2 w-100">
                            <Progress
                              softcolors="info"
                              color="info"
                              className="shadow-none w-100"
                              value={89}
                              minvalue={0}
                              maxvalue={100}
                              style={{ height: "6px" }}
                            />
                          </div> */}
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card className="mb-4">
                        <Card.Body className="p-0 d-flex flex-column align-items-start justify-content-start">
                          <div className="p-2 pb-0 border-bottom w-100">
                            <h6 className="mb-2 w-100 p-2">Variant B</h6>
                          </div>
                          <div className="px-3 py-3 d-flex flex-row align-items-start justify-content-start gap-3 w-100 align-items-center">
                            <div className="d-inline-block avatar-40 rounded-3 flex-shrink-0 text-warning">
                              {findIcon("View Grid", "dual-tone", "30")}
                            </div>
                            <div className="text-start d-flex flex-grow-1">
                              <h5 className="m-0 fs-6">Segment Size</h5>
                              <h6 className="m-0 ms-auto fw-normal text-warning fs-5">
                                <CountUp start={3} end={855} duration={3} />
                              </h6>
                            </div>
                          </div>
                          {/* <div className="p-2 pb-3 mt-2 w-100">
                            <Progress
                              softcolors="warning"
                              color="warning"
                              className="shadow-none w-100"
                              value={72}
                              minvalue={0}
                              maxvalue={100}
                              style={{ height: "6px" }}
                            />
                          </div> */}
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card className="mb-4">
                        <Card.Body className="p-0 d-flex flex-column align-items-start justify-content-start">
                          <div className="p-2 pb-0 border-bottom w-100">
                            <h6 className="mb-2 w-100 p-2">Variant C</h6>
                          </div>
                          <div className="px-3 py-3 d-flex flex-row align-items-start justify-content-start gap-3 w-100 align-items-center">
                            <div className="d-inline-block avatar-40 rounded-3 flex-shrink-0 text-success">
                              {findIcon("View Grid", "dual-tone", "30")}
                            </div>
                            <div className="text-start d-flex flex-grow-1">
                              <h5 className="m-0 fs-6">Segment Size</h5>
                              <h6 className="m-0 ms-auto fw-normal text-success fs-5">
                                <CountUp start={3} end={850} duration={3} />
                              </h6>
                            </div>
                          </div>
                          {/* <div className="p-2 pt-3 mt-2 w-100">
                            <Progress
                              softcolors="success"
                              color="success"
                              className="shadow-none w-100"
                              value={82}
                              minvalue={0}
                              maxvalue={100}
                              style={{ height: "6px" }}
                            />
                          </div> */}
                        </Card.Body>
                      </Card>
                    </Col>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="bg-soft-primary text-muted h-100 p-3 rounded-4 d-flex flex-column">
                  <Tab.Container defaultActiveKey="first">
                    <Nav
                      variant="tabs"
                      data-toggle="slider-tab"
                      role="tablist"
                      className="w-100 bg-transparent d-flex flex-row gap-2 mb-3"
                    >
                      <Nav.Item role="presentation" className="flex-grow-1">
                        <Nav.Link
                          eventKey="first"
                          data-bs-toggle="tab"
                          data-bs-target="#pills-home1"
                          type="button"
                          role="tab"
                          aria-controls="home"
                          aria-selected="true"
                          className="d-flex align-items-center justify-content-center border border-primary border-1 border-dashed px-2 fs-7"
                        >
                          Variant A
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item role="presentation" className="flex-grow-1">
                        <Nav.Link
                          eventKey="second"
                          data-bs-toggle="tab"
                          data-bs-target="#pills-profile1"
                          type="button"
                          role="tab"
                          aria-controls="profile"
                          aria-selected="false"
                          className="d-flex align-items-center justify-content-center border border-primary border-1 border-dashed px-2 fs-7"
                        >
                          Variant B
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item role="presentation" className="flex-grow-1">
                        <Nav.Link
                          eventKey="third"
                          id="contact-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#pills-contact1"
                          type="button"
                          role="tab"
                          aria-controls="contact"
                          aria-selected="false"
                          className="d-flex align-items-center justify-content-center border border-primary border-1 border-dashed px-2 fs-7"
                        >
                          Variant C
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content className="flex-fill">
                      <Tab.Pane
                        eventKey="first"
                        variant=" fade show active"
                        id="pills-home1"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab1"
                      >
                        <div className="position-relative flex-fill">
                          <img
                            src={mobile}
                            alt="mobile"
                            className="h-auto w-100 d-block position-relative z-0"
                          />
                          <div className="position-absolute top-0 w-100 h-auto p-2 z-1">
                            <div className="h-100 rounded-5 p-4 mobile-preview position-relative">
                              <span className="d-block position-relative px-3 py-2 rounded-3 mt-4 text-dark fs-7 lh-base shadow-lg shadow bg-white">
                                Dear NAME, We are excited to announce the launch
                                of our new digital glucose monitoring tool. As a
                                valued client, we wanted to ensure you are aware
                                of this innovative product that can greatly
                                improve your health management. Don't miss out
                                on this opportunity to take control of your
                                glucose levels.LINK
                              </span>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane
                        eventKey="second"
                        className="fade"
                        id="pills-profile1"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab1"
                      >
                        <div className="position-relative flex-fill">
                          <img
                            src={mobileWhatsapp}
                            alt="mobile whatsapp"
                            className="h-auto w-100 d-block position-relative z-0"
                          />
                          <div className="position-absolute top-0 w-100 h-auto p-2 z-1">
                            <div className="h-100 rounded-5 p-4 whatsapp-preview position-relative">
                              <span className="d-block position-relative px-3 py-2 rounded-3 mt-4 text-secondary fs-7 lh-base">
                                Hello NAME, We are thrilled to introduce our
                                latest product, a digital glucose monitoring
                                tool. As a TITLE at our esteemed organization,
                                we believe this tool can greatly benefit you in
                                managing your glucose levels effectively. Don't
                                miss out on this game-changing technology. LINK
                              </span>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane
                        eventKey="third"
                        className="fade h-100"
                        id="pills-contact1"
                        role="tabpanel"
                        aria-labelledby="pills-contact-tab1"
                      >
                        <div className="position-relative h-100 overflow-scroll bg-white border border-1 border-dotted rounded-3 p-3 border-secondary text-center">
                          Hello NAME, We are thrilled to introduce our latest
                          product, a digital glucose monitoring tool. As a TITLE
                          at our esteemed organization, we believe this tool can
                          greatly benefit you in managing your glucose levels
                          effectively. Don't miss out on this game-changing
                          technology. LINK
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
              <div className="col-4 p-0 d-flex flex-column">
                <div className="text-muted h-100 p-3 rounded-4 d-flex flex-column border border-1">
                  <div className="bg-soft-warning p-3 mb-3 d-flex flex-column rounded-4 lh-base">
                    <p className="m-0 mb-2">
                      Rollout this A/B Test Campaign for SMS Channel.
                    </p>
                    <div className="m-0 fw-normal"></div>
                  </div>
                  <div>
                    <Form.Floating className="custom-form-floating position-relative d-flex flex-column">
                      <textarea
                        className="form-control"
                        aria-label="With textarea"
                      ></textarea>
                      <Form.Label htmlFor="floatingInput">Comment</Form.Label>
                    </Form.Floating>
                  </div>
                  <div className="d-flex gap-3 mt-3">
                    <Button
                      variant="danger"
                      className="flex-fill d-flex align-items-center justify-content-center fw-semibold rounded-3 px-4 py-2"
                      type="submit"
                    >
                      <span className="">Approve</span>
                    </Button>
                    <Button
                      variant="soft-secondary"
                      className="flex-fill d-flex align-items-center justify-content-center fw-normal rounded-3 px-4 py-2 text-secondary"
                      type="submit"
                    >
                      <span className="">Rejected</span>
                    </Button>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default AbTestApprovalDetails;
